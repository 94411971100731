import React from 'react';
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import API from '../../../API';
import ChatScreen from '../../../Components/ChatScreen';
import { useQuery } from 'react-query';
import useIsDesktop from '../../../Hooks/useIsDesktop';

const ChatModal = ({ open, onClose, recipientId }) => {
  const isDesktop = useIsDesktop();

  const {data, isLoading, error} = useQuery({
    queryKey:['conversations', recipientId],
    queryFn: ()=>API.conversations.checkUserToUser({ recipient_id: recipientId }),
    refetchOnWindowFocus:false,
    enabled: !!open && !!recipientId
  })

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{sx:{minWidth:'40vw', maxWidth:{xs:'none', md:'40vw'}, maxHeight:'100vh'}}} fullScreen={!isDesktop}>
      <DialogTitle sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography variant="h6">
          {!!data ? `Conversation #${data.conversation_id}` : 'Starting Chat...'}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{padding:0, paddingBlockEnd:1, flex:{xs:1, md:'none'}}}>
      {error && <Alert sx={{marginInline:2}}>{error?.response?.data?.error || error.message}</Alert>}
      <ChatScreen showWarning={false} conversationId={data?.conversation_id} sx={{height:'100%', minHeight:'70vh', maxHeight:{xs: '100%', md:'70vh'}}}></ChatScreen>
      </DialogContent>
    </Dialog>
  );
};

export default ChatModal;