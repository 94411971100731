/*************************************************************************
 *  src/components/NavBar/Navbar.js
 ************************************************************************/
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  IconButton,
  styled,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
  Avatar,
  Button,
  Snackbar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
  CircularProgress
} from "@mui/material";

import {
  CreditCardOutlined,
  HomeOutlined,
  KeyboardArrowLeftSharp,
  KeyboardArrowRightSharp,
  LiveHelpOutlined,
  PeopleOutline,
  StorefrontOutlined,
  ChatBubbleOutlineOutlined,
  NotificationsNone as NotificationsNoneIcon,
  InsertDriveFileOutlined  // <-- New icon for Files

} from "@mui/icons-material";

import WhiteListButton from "../WhiteListButton.js";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HandshakeOutlined from "@mui/icons-material/HandshakeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import useAuth from "../../Hooks/use-auth";
import routes from "../../Config/routes.js";
import client from "../../API"; // <-- Contains client.invoices.incomingPending() & more
import styles from "./NavBar.module.css";
import logo from "../globalAssets/logo_placeholder.png";
import ListItemLink from "../ListItemLink.js";
import { drawerWidth } from "../../Utils/constants.js";

/*------------------------------------------------------------------
  1) Styled Components / MUI Helpers
-------------------------------------------------------------------*/
const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: "#000",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.04)",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} - 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} - 1px)`,
  },
});

const IconDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "extended",
})(({ theme, extended }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(extended && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!extended && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/** Helper: Combine a ListItemLink with a tooltip + (optionally) a badge. */
const ListItemLinkWithTooltip = ({
  to,
  primary,
  icon,
  tooltip,
  isOpen = true,
  count,
}) => (
  <Tooltip title={tooltip} placement="right">
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemLink
        to={to}
        primary={primary}
        icon={
          count !== undefined && count > 0 ? (
            <Badge badgeContent={count} color="secondary">
              {icon}
            </Badge>
          ) : (
            icon
          )
        }
        isOpen={isOpen}
      />
    </ListItem>
  </Tooltip>
);

/*------------------------------------------------------------------
  2) Survey Popup Component (optional)
-------------------------------------------------------------------*/
function SurveyPopup({ open, survey, userId, onClose }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});

  const createResponseMutation = useMutation((payload) =>
    client.surveys.createResponse(payload)
  );

  useEffect(() => {
    if (survey) setAnswers({});
  }, [survey]);

  const handleAnswerChange = (index, value) => {
    setAnswers((prev) => ({ ...prev, [index]: value }));
  };

  const handleSubmit = async () => {
    try {
      await createResponseMutation.mutateAsync({
        survey_id: survey.id,
        user_id: userId,
        answers,
      });
      if (survey.action) {
        navigate(survey.action);
      }
      onClose(true);
    } catch (err) {
      console.error("Error submitting survey response:", err);
      alert("Error submitting survey response.");
      onClose(false);
    }
  };

  if (!survey) return null;

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle>{survey.title || "Survey"}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {survey.description}
        </Typography>
        {survey.questions?.map((q, index) => (
          <Box key={index} mb={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              {q.question}
            </Typography>
            <TextField
              label="Your Answer"
              variant="outlined"
              fullWidth
              value={answers[index] || ""}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={createResponseMutation.isLoading}
        >
          {createResponseMutation.isLoading ? "Submitting..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/*------------------------------------------------------------------
  3) The Main Navbar Component
-------------------------------------------------------------------*/
const Navbar = ({ isDesktop = true, isOpen = true, onOpen, onClose }) => {
  const { getCurrrentUser, isAuthorized, unauthorize } = useAuth();
  const userInfo = getCurrrentUser();
  const userId = userInfo?.id;
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  // For controlling "extended" vs. collapsed drawer
  const isExtended = !isDesktop || isOpen;

  // Handle logout
  const logout = () => {
    unauthorize();
  };

  // ------------------- 1) Fetch Company Data ---------------------
  const [companyData, setCompanyData] = useState({
    balance: "Loading...",
    credit_line: "Loading...",
    phphref: "",
    account_status: "Loading...",
  });

  useQuery(
    ["company", userInfo?.company_id],
    () => client.companies.listFetch({ id: userInfo?.company_id }),
    {
      onSuccess: (data) => {
        if (data?.balance !== undefined && data?.credit_line !== undefined) {
          setCompanyData({
            balance: `$${parseFloat(data.balance).toFixed(2)}`,
            credit_line: data.credit_line
              ? `$${parseFloat(data.credit_line).toFixed(2)}`
              : "No Credit Line Established",
            phphref: data.phphref || "",
            account_status: data.account_status || "",
          });
        } else {
          console.error("Unexpected company data format:", data);
          setCompanyData({
            balance: "Error loading balance",
            credit_line: "Error loading credit line",
            phphref: "",
            account_status: "Error loading account status",
          });
        }
      },
      onError: (error) => {
        console.error("Error fetching company data:", error);
        setCompanyData({
          balance: "Error loading balance",
          credit_line: "Error loading credit line",
          phphref: "",
          account_status: "Error loading account status",
        });
      },
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
      enabled: !!userInfo,
    }
  );

  // ------------------- 2) Fetch Unread Conversations ---------------------
  const { data: dashboardData, isLoading: dashboardIsLoading } = useQuery({
    queryKey: ["user", "dashboard"],
    queryFn: () => client.users.dashboard(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
    enabled: !!userInfo,
  });
  const unreadConversationCount = dashboardIsLoading
    ? undefined
    : dashboardData?.unread_conversations_count ?? 0;

  // ------------------- 3) Unread Collaboration Notifications -------------
  const {
    data: unreadCollabCountData,
    isLoading: isLoadingUnreadCollabs,
    refetch: refetchUnreadCollabs,
  } = useQuery(
    ["notifications", "unread_collab_count"],
    () => client.notifications.getUnreadCollabCount(),
    {
      enabled: !!userId,
      refetchInterval: 60000,
    }
  );
  const unreadCollabNotificationsCount =
    unreadCollabCountData?.unread_collab_count || 0;

  // ------------------- 4) Notification (Bell) Logic ----------------------
  const {
    data: unreadNotifCountData,
    isLoading: isLoadingUnread,
    refetch: refetchUnreadNotif,
  } = useQuery(
    ["notifications", "unread_count"],
    () => client.notifications.getUnreadCount(),
    {
      enabled: !!userId,
      refetchInterval: 60000,
    }
  );
  const unreadNotificationCount = unreadNotifCountData?.unread_count || 0;

  const {
    data: notificationsData,
    refetch: refetchNotifications,
  } = useQuery(
    ["notifications", "list_all"],
    () => client.notifications.listAll(false),
    {
      enabled: false, // we'll manually refetch on open
    }
  );

  const markNotificationReadMutation = useMutation(
    (notificationId) => client.notifications.markAsRead(notificationId),
    {
      onSuccess: () => {
        refetchNotifications();
        refetchUnreadNotif();
        refetchUnreadCollabs();
      },
      onError: (err) => {
        console.error("Error marking notification as read:", err);
      },
    }
  );

  const [anchorNotif, setAnchorNotif] = useState(null);
  const notifMenuOpen = Boolean(anchorNotif);

  const handleOpenNotifications = (e) => {
    setAnchorNotif(e.currentTarget);
    refetchNotifications();
  };
  const handleCloseNotifications = () => {
    setAnchorNotif(null);
  };

  const handleNotificationClick = (notif) => {
    markNotificationReadMutation.mutate(notif.id);
    // Only navigate if notif.url is a non-empty string.
    if (notif.url && notif.url.trim()) {
      navigate(notif.url);
    }
    setAnchorNotif(null);
  };

  // ------------------- 5) Financial Menu & Credit Request ----------------
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleAddBalance = () => {
    setSnackbarOpen(true);
    handleCloseMenu();
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // **Credit dialog**:
  const [creditDialogOpen, setCreditDialogOpen] = useState(false);
  const [requestedCreditLine, setRequestedCreditLine] = useState("");
  const [requestedInterestRate, setRequestedInterestRate] = useState("");
  const [reason, setReason] = useState("");

  const handleOpenCreditDialog = () => {
    if (
      companyData.credit_line &&
      companyData.credit_line !== "No Credit Line Established"
    ) {
      // Strip out the "$" if present
      const numericValue = parseFloat(companyData.credit_line.replace("$", ""));
      setRequestedCreditLine(numericValue || "");
    }
    setRequestedInterestRate("");
    setReason("");
    setCreditDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseCreditDialog = () => {
    setCreditDialogOpen(false);
    setReason("");
  };

  const postCreditRequestMutation = useMutation(
    (payload) => client.creditLedger.postRequest(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["creditLedger", userInfo?.company_id]);
        setCreditDialogOpen(false);
        setReason("");
      },
      onError: (error) => {
        console.error("Error requesting credit change:", error);
      },
    }
  );

  const handleRequestCreditChange = () => {
    if (!userInfo?.company_id) return;
    postCreditRequestMutation.mutate({
      company_id: userInfo.company_id,
      requested_credit_line: Number(requestedCreditLine || 0),
      requested_interest_rate: Number(requestedInterestRate || 0),
      reason: reason || "User request",
    });
  };

  // ------------------- 6) Pending Incoming Invoices (NEW) ----------------
  //    We fetch the *pending* incoming invoices, sum their amounts,
  //    then display that total as the badge on the BlitzPay nav item.
  const {
    data: incomingPendingInvoices,
    isLoading: isLoadingIncoming,
  } = useQuery(
    ["invoices", "incoming_pending", userId],
    () => client.invoices.pendingInvoices(userId),
    { enabled: !!userId, refetchOnWindowFocus: false }
  );
  

  // Sum up the amounts
  const invoicesArray = incomingPendingInvoices?.data || [];
  const totalIncomingPending = Array.isArray(incomingPendingInvoices)
  ? incomingPendingInvoices.reduce((acc, inv) => acc + parseFloat(inv.amount || 0), 0)
  : 0;


  // ------------------- 7) Fetch Pending Surveys & Possibly Popup ---------
  const { data: pendingSurveys } = useQuery(
    ["pendingSurveys", userId],
    () => client.surveys.listPending(userId),
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
    }
  );
  const [activeSurvey, setActiveSurvey] = useState(null);

  useEffect(() => {
    if (!pendingSurveys) return;
    const match = pendingSurveys.find(
      (s) => s.appears_on === location.pathname
    );
    setActiveSurvey(match || null);
  }, [pendingSurveys, location.pathname]);

  const handleCloseSurvey = (didComplete) => {
    setActiveSurvey(null);
    if (didComplete) {
      queryClient.invalidateQueries(["pendingSurveys", userId]);
    }
  };

  // ------------------- 8) Build the main nav items -----------------------
  const listItems = [
    {
      to: routes.dashboard,
      primary: "Dashboard",
      icon: <HomeOutlined />,
      tooltip: "View and manage your dashboard",
    },
    {
      to: routes.search,
      primary: "Search",
      icon: <StorefrontOutlined />,
      tooltip: "Explore our creator selection and make projects from there",
    },
    {
      to: routes.collaborations,
      primary: "Collaborations",
      icon: <HandshakeOutlined />,
      tooltip: "Manage collaborations and projects",
      count: isLoadingUnreadCollabs ? undefined : unreadCollabNotificationsCount,
    },
    {
      to: routes.blitzpay,
      primary: "BlitzPay",
      icon: <CreditCardOutlined />,
      tooltip: "Make instant payments through BlitzPay",
      // We show the sum of all *pending* incoming invoices as an integer.
      count: isLoadingIncoming
        ? undefined
        : totalIncomingPending > 0
        ? Number(totalIncomingPending).toFixed(0)
        : 0,
    },
    {
      to: routes.conversationshub,
      primary: "Chat",
      icon: <ChatBubbleOutlineOutlined />,
      tooltip: "Chat with your connections",
      count: unreadConversationCount,
    },
    {
      to: routes.files,  // <-- New Files route
      primary: "Files",
      icon: <InsertDriveFileOutlined />,
      tooltip: "Access and manage your files",
    },
  ];

  // If user is "Agency" or "AlphaFree", show "My Agency" nav item
  if (
    companyData.account_status === "Agency" ||
    companyData.account_status === "AlphaFree"
  ) {
    listItems.push({
      to: routes.agency,
      primary: "My Agency",
      icon: <PeopleOutline />,
      tooltip: "Manage your agency's roster, campaigns, and more",
    });
  }

  /*------------------------------------------------------------------
     RENDER
  ------------------------------------------------------------------*/
  return (
    <>
      <IconDrawer
        id="NavbarContainer"
        variant={isDesktop ? "permanent" : "temporary"}
        sx={{
          color: "#000 !important",
          "& .MuiDrawer-paper": {
            color: "#000 !important",
            boxSizing: "border-box",
            backgroundColor: "#FFF !important",
            backgroundImage: "none !important",
            overflowY: { xs: "auto", md: "hidden" },
            scrollbarWidth: "auto",
            scrollbarColor: "rgba(0, 0, 0, 0.6) rgba(0, 0, 0, 0.24)",
            scrollbarWidth: "thin",
            maxHeight: "100vh",
          },
        }}
        open={isDesktop || isOpen}
        extended={isExtended}
        onClose={onClose}
      >
        {/* -- HEADER / LOGO -- */}
        <Box
          className={styles.navbarHeader}
          sx={{
            marginInline: 2,
            marginBlockStart: 2,
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            alignItems: "center",
            justifyContent: isExtended ? "space-between" : "center",
          }}
        >
          <Box className={styles.logo}>
            {isExtended && (
              <RouterLink to={isAuthorized ? routes.dashboard : routes.home}>
                <img src={logo} alt="Logo" />
              </RouterLink>
            )}
          </Box>
          <WhiteIconButton onClick={() => (isOpen ? onClose() : onOpen())}>
            {isExtended ? <KeyboardArrowLeftSharp /> : <KeyboardArrowRightSharp />}
          </WhiteIconButton>
        </Box>

        {isDesktop && <Divider sx={{ marginBlockStart: 2, marginBlockEnd: 1 }} />}

        {/* -- USER INFO SECTION -- */}
        {isExtended && (
          <Box className={styles.user}>
            <Box
              className={styles.userTab}
              sx={{ marginBlockStart: { xs: 2, md: 0 } }}
            >
              <Box className={styles.userInfo}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {userInfo?.phphref && (
                      <RouterLink to={`/user/${userInfo.id}`}>
                        <Avatar
                          src={userInfo.phphref}
                          sx={{
                            width: 40,
                            height: 40,
                            border: "2px solid rgba(0, 0, 0, 0.12)",
                          }}
                        />
                      </RouterLink>
                    )}
                    <Box sx={{ textAlign: "left" }}>
                      <Typography variant="subtitle1">
                        <b>Hey, {userInfo?.first_name ?? ""}!</b>
                      </Typography>
                      <Typography variant="subtitle1">
                        <b>{userInfo?.company_name}</b>
                      </Typography>
                    </Box>
                  </Box>
                  {/* NOTIFICATIONS + LOGOUT */}
                  <Box>
                    <Tooltip title="Notifications">
                      <IconButton onClick={handleOpenNotifications} sx={{ mr: 1 }}>
                        <Badge
                          badgeContent={isLoadingUnread ? 0 : unreadNotificationCount}
                          color="secondary"
                        >
                          <NotificationsNoneIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorNotif}
                      open={notifMenuOpen}
                      onClose={handleCloseNotifications}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      {!notificationsData?.length && (
                        <MenuItem>No notifications</MenuItem>
                      )}
                      {notificationsData?.map((notif) => {
                        const isUnread = !notif.read_at;
                        return (
                          <MenuItem
                            key={notif.id}
                            onClick={() => handleNotificationClick(notif)}
                            sx={{
                              backgroundColor: isUnread ? "#f2f2f2" : "#fff",
                            }}
                          >
                            <ListItemText
                              primary={notif.purpose}
                              secondary={notif.message}
                            />
                          </MenuItem>
                        );
                      })}
                    </Menu>
                    <Tooltip title="Log out" placement="right">
                      <WhiteIconButton onClick={logout}>
                        <LogoutIcon />
                      </WhiteIconButton>
                    </Tooltip>
                  </Box>
                </Box>
                {/* BALANCE / CREDIT LINE */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mt: 1,
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.6)">
                      Balance: {companyData.balance}
                    </Typography>
                    <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.6)">
                      Credit Line: {companyData.credit_line}
                    </Typography>
                  </Box>
                  {/* SETTINGS & FINANCIAL MENU */}
                  <Box>
                    <Tooltip title="Modify your account settings" placement="bottom">
                      <WhiteIconButton onClick={() => navigate(routes.user)}>
                        <SettingsOutlinedIcon />
                      </WhiteIconButton>
                    </Tooltip>
                    <Tooltip title="Financial Options" placement="bottom">
                      <WhiteIconButton onClick={handleOpenMenu}>
                        <AttachMoneyIcon />
                      </WhiteIconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleCloseMenu}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <MenuItem onClick={handleAddBalance}>
                        <ListItemIcon>
                          <MonetizationOnIcon />
                        </ListItemIcon>
                        Add a Balance
                      </MenuItem>
                      <MenuItem onClick={handleOpenCreditDialog}>
                        <ListItemIcon>
                          <AccountBalanceIcon />
                        </ListItemIcon>
                        Request Credit
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* COLLAPSED VIEW (when not extended) */}
        {!isExtended && (
          <List disablePadding>
            <ListItem
              disablePadding
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <RouterLink
                to={`/user/${userInfo?.id}`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    my: 2,
                  }}
                >
                  <Tooltip title="Profile" placement="right">
                    <Avatar
                      src={userInfo?.phphref}
                      sx={{
                        width: 36,
                        height: 36,
                        border: "2px solid rgba(0, 0, 0, 0.12)",
                      }}
                    />
                  </Tooltip>
                </ListItemIcon>
              </RouterLink>
            </ListItem>
            {/* NEW: Notification bell in collapsed navbar */}
            <ListItem
              disablePadding
              sx={{ display: "flex", justifyContent: "center", mb: 1 }}
            >
              <Tooltip title="Notifications" placement="right">
                <IconButton onClick={handleOpenNotifications}>
                  <Badge
                    badgeContent={isLoadingUnread ? 0 : unreadNotificationCount}
                    color="secondary"
                  >
                    <NotificationsNoneIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </ListItem>
            <Divider />
          </List>
        )}

        {isDesktop && isExtended && <Divider />}

        {/* MAIN NAV ITEMS */}
        <Box
          id="Icon-List"
          sx={{
            flex: 1,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            id="Scrollable-Icon-List"
            sx={{
              flexGrow: 1,
              flexShrink: { xs: 0, md: 1 },
              minHeight: 0,
              overflowY: { xs: "visible", md: isExtended ? "auto" : "clip" },
              overflowX: "clip",
              scrollbarWidth: "auto",
              scrollbarColor: "rgba(0, 0, 0, 0.24) transparent",
              scrollbarWidth: "thin",
            }}
          >
            <Box sx={{ maxHeight: "max-content", minHeight: "max-content" }}>
              <List disablePadding>
                {listItems.map((item) => (
                  <ListItemLinkWithTooltip
                    key={item.to}
                    {...item}
                    isOpen={isExtended}
                  />
                ))}
              </List>
            </Box>
          </Box>

          {/* BOTTOM HELP LINK */}
          <Box>
            <Divider />
            <ListItem disablePadding component={"div"}>
              <WhiteListButton
                onClick={() => navigate("/help")}
                sx={{
                  justifyContent: isExtended ? "initial" : "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                    minWidth: "36px",
                    marginInlineEnd: isOpen ? 0 : "auto",
                    justifyContent: isExtended ? "initial" : "center",
                  }}
                >
                  <LiveHelpOutlined />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "1.2rem" }}
                  sx={{ opacity: isExtended ? 1 : 0 }}
                  primary="Help"
                />
              </WhiteListButton>
            </ListItem>
          </Box>
        </Box>

        {/* SNACKBAR (ADD BALANCE) */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          message="Contact admin to upload a balance!"
        />

        {/* CREDIT REQUEST DIALOG */}
        <Dialog
          open={creditDialogOpen}
          onClose={handleCloseCreditDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Request Credit / Adjust Credit Line</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Enter the desired credit line, interest rate, and any reasons.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Requested Credit Line"
                  type="number"
                  fullWidth
                  value={requestedCreditLine}
                  onChange={(e) => setRequestedCreditLine(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Requested Interest Rate"
                  type="number"
                  fullWidth
                  value={requestedInterestRate}
                  onChange={(e) => setRequestedInterestRate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Reason for Change"
                  fullWidth
                  multiline
                  rows={2}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreditDialog} disabled={postCreditRequestMutation.isLoading}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleRequestCreditChange}
              disabled={postCreditRequestMutation.isLoading}
            >
              {postCreditRequestMutation.isLoading ? (
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              ) : (
                "Submit Request"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </IconDrawer>

      {/* SURVEY POPUP */}
      {activeSurvey && (
        <SurveyPopup
          open={Boolean(activeSurvey)}
          survey={activeSurvey}
          userId={userId}
          onClose={handleCloseSurvey}
        />
      )}
    </>
  );
};

export default Navbar;
