import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async'; // Added for dynamic meta tags
import { CreatorAuthProvider } from './Hooks/creator-use-auth.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useAuth from './Hooks/use-auth.js';
import client from './API';
import routes from './Config/routes.js';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import HelpIcon from '@mui/icons-material/Help';
import PageTitle from './Components/PageTitle.js';
import Main from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Register from './Pages/Login/Register';
import Dashboard from './Pages/Dashboard/dashboard/dashboard.js';
import User from './Pages/Dashboard/user.js';
import Invoicing from './Pages/Dashboard/invoicing/invoicing.js';
import Search from './Pages/Dashboard/Search/search';
import CreatorSignup from './Pages/Home/auxLinks/creatorSignup.js';
import BlitzPay from './Pages/BlitzPay/blitzpay.js';
import AddCreators from './Pages/Dashboard/Campaigns/addCreators.js';
import PrivateRoute from './Lib/private-route.js';
import CreatorRoute from './Lib/creator-route.js';
import CompanyList from './Pages/Dashboard/Company/index.js';
import CampaignDetailsPage from './Pages/Dashboard/Campaigns/individualcampaigns/campaignDetailsPage.js';
import AgencyDetailsPage from './Pages/Dashboard/Creators/agency/agencyDetail.js';
import CreatorConnect from './CreatorUser/CreatorPages/creatorConnect.js';
import CreatorStart from './CreatorUser/CreatorPages/Dashboard/start.js';
import CreatorStripeId from './CreatorUser/CreatorPages/Dashboard/stripe.js';
import Commissions from './Pages/Misc/commisisons/commisions.js';
import CreatorInvoicePage from './CreatorUser/CreatorPages/Dashboard/misc/creatorinvoicepage.js';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
import InvoicePage from './Pages/Dashboard/invoicing/invoicepage.js';
import CreatorCRMViewPublic from './Pages/Misc/creatorCRM/creatorCRM.js';
import HelpDialog from './helpdialog.js';
import InternalLogin from './CreatorUser/internalLogin.js';
import RequestAccess from './Pages/Home/auxLinks/requestAccess.js';
import SimilarCreators from './Pages/Dashboard/Creators/similarcreators.js';
import DemoRegister from './Pages/Home/auxLinks/demoRegister.js';
import BlitzSummary from './Pages/Home/blitzsummary.js';
import CreatorCampaignTimelines from './Pages/Dashboard/Campaigns/individualcampaigns/creatorcampaigntimelines.js';
import CreatorDeliverables from './Pages/Dashboard/Campaigns/projectcomponents/deliverablepage.js';
import BlitzRoute from './Components/BlitzRoute.js';
import TempCreatorPage from './Pages/Dashboard/Creators/tempcreatorpage.js';
import HelpPage from './Pages/Misc/helppage.js';
import AssetsManager from './Pages/Misc/assetsDemo.js';
import EditCampaignPage from './Pages/Dashboard/Campaigns/campaignsDialog/editCampaignPage.js';
import { getTheme } from './Utils/baseTheme.js';
import PayPage from './Pages/BlitzPay/paypage.js';
import PartnershipsPage from './Pages/Dashboard/Campaigns/projectcomponents/partnershipspage.js';
import CreatorConnectReatuh from './CreatorUser/CreatorPages/creatorConnectReauth.js';
import Solutions from './Pages/Home/brandbenefits/solutions.js';
import ForCreators from './Pages/Home/forcreators/forcreators.js';
import CreatorCampaigns from './CreatorUser/CreatorPages/Dashboard/creatorCampaigns.js';
import PayoutsInvoicing from './CreatorUser/CreatorPages/Dashboard/payoutsinvoicing.js';
import Pitch from './CreatorUser/CreatorPages/Dashboard/pitch.js';
import EditCreator from './CreatorUser/CreatorPages/Dashboard/userInfo.js';
import BrandEmails from './CreatorUser/CreatorPages/Dashboard/brandEmails.js';
import CreatorMore from './CreatorUser/CreatorPages/Dashboard/misc/creatorMore.js';
import CreatorConnectRoute from './Components/CreatorConnectRoute.js';
import AgencyStart from './Pages/Agency/agencyStart.js';
import CreatorSpecificCampaign from './CreatorUser/CreatorPages/Dashboard/creatorcomponents/creatorspecificcampaign.js';
import WhyPartner from './Pages/Home/forcreators/whypartner.js';
import Trackers from './Pages/Trackers/trackers.js';
import CreatorSpecificPartnership from './CreatorUser/CreatorPages/Dashboard/partnershipcomps/creatorspecificpartnerships.js';
import PitchFromZap from './CreatorUser/CreatorPages/Dashboard/zapfeed/pitchtozapfeed.js';
import PartnerCreatorRoster from './Pages/Misc/partnerspage.js';
import CampaignCreatorSpecific from './Pages/Dashboard/Campaigns/campaignsDialog/conversations/campaigncreatorspec.js';
import PrivacyPolicy from './Pages/Home/privacypolicy.js';
import AgencyRoot from './Pages/Agency/agencyRoot.js';
import AgencyCreators from './Pages/Agency/agencyCreators.js';
import AgencyCreator from './Pages/Agency/agencyCreator.js';
import OptOutCampaign from './Pages/Dashboard/Campaigns/individualcampaigns/optoutcampaign.js';
import CreatorCommissions from './CreatorUser/CreatorPages/creatorcommissions.js';
import CastingCalls from './Pages/Dashboard/Campaigns/castingcalls/castingcalls.js';
import Integrations from './Integrations/integrations.js';
import CreatorCastingCalls from './CreatorUser/CreatorPages/Dashboard/castingcalls/castingcalls.js';
import MediaKit from './Pages/MediaKit/mediaKit.js';
import CreatorRedirect from './CreatorUser/redirect.js';
import Collaborations from './Pages/Dashboard/Campaigns/collabs.js';
import CreatorCollaborations from './CreatorUser/CreatorPages/Dashboard/creatorcollabs.js';
import Articles from './Pages/Home/articles/article.js';
import HottestNewCreatorSoftware from './Pages/Home/articles/1.js';
import FutureOfPayments from './Pages/Home/articles/2.js';
import StreamlineCampaigns from './Pages/Home/articles/3.js';
import Agencies from './Pages/Home/brandbenefits/agencies.js';
import DealIOs from './Pages/DealsIO/dealsDash.js';
import DealIODetail from './Pages/DealsIO/dealsSpec.js';
import PayoutPage from './Pages/BlitzPay/blitzpayspec.js';
import DealIOPublic from './Pages/DealsIO/dealIOPublic.js';
import AllOnboard from './Pages/Misc/AllOnboard.js';
import CreatorDeals from './CreatorUser/CreatorPages/creatorDeal.js';
import CreatorDealSpec from './Pages/DealsIO/dealSpecCreator.js';
import DocsRoute from './Pages/Documentation/DocsRoute.js';
import DocsHomepage from './Pages/Documentation/Homepage.js';
import DocsServices from './Pages/Documentation/DocsServices.js';
import DocsApikeys from './Pages/Documentation/DocsApikeys.js';
import DocsCreators from './Pages/Documentation/DocsCreator.js';
import DocsBasics from './Pages/Documentation/DocsBasics.js';
import BlitzReleaseNotes from './Pages/Misc/ReleaseNotes/BlitzReleaseNotes.js';
import BlitzAuth from './CreatorUser/blitzauth.js';
import DocsOAuth from './Pages/Documentation/DocsOAuth.js';
import Apps from './CreatorUser/CreatorPages/Dashboard/apps.js';
import AppSpec from './CreatorUser/CreatorPages/Dashboard/appSpec.js';
import CreatorOnboardApp from './CreatorUser/creatorOnboardApp.js';
import { useQuery } from 'react-query';
import ForgotPassword from './Pages/PasswordForgot/forgotPassword.js';
import RestorePassword from './Pages/PasswordForgot/restorePassword.js';
import CampaignOfferPage from './Pages/Dashboard/Campaigns/individualcampaigns/offerpagecreator.js';
import ContractDetailsPage from './Pages/Misc/contracts/contractSpec.js';
import UserProfile from './Pages/Dashboard/Users/userprofile.js';
import ConversationsHub from './Pages/Dashboard/Users/conversationhub.js';
import PublicUserProfile from './Pages/Dashboard/Users/pubUser.js';
import CreatorInvoicingPage from './CreatorUser/CreatorPages/Dashboard/creatorinvoicing.js';
import ClientInvoicingPage from './Pages/BlitzPay/createinvoicepage.js';
import InternalBookingPage from './Pages/Dashboard/Search/steps/partnershipsbooking.js';
import RedirectWithQuery from './Components/RedirectWithQuery.js';
import AgencyCreatorEdit from './Pages/Agency/agencyCreatorTabs/agencyCreatorEdit.js';
import AgencyCreatorConversations from './Pages/Agency/agencyCreatorTabs/agencyCreatorConversations.js';
import AgencyCreatorPayouts from './Pages/Agency/agencyCreatorTabs/agencyPayoutCreator.js';
import AgencyCreatorCampaign from './Pages/Agency/agencyCreatorTabs/agencyCreatorCampaign.js';
import AgencyPartnershipDialog from './Pages/Agency/agencyCreatorTabs/agencyCampaignPartnershipDialog.js';
import AgencyCampaignConversation from './Pages/Agency/agencyCreatorTabs/agencyCampaignConversation.js';
import TermsOfServiceBusinessEntities from './Pages/Home/forcreators/agencyterms.js';
import VendorSignupPage from './Pages/Home/auxLinks/vendorSIgnup.js';
import AgencyCreatorCollaborations from './Pages/Agency/agencyCreatorTabs/agencyCollaborations.js';
import InternalLoginUser from './CreatorUser/internalLoginUser.js';
import BlitzBoltAchievement from './Pages/Dashboard/dashboard/blitzboltSpec.js';
import CreatorConversationsHub from './CreatorUser/CreatorPages/Dashboard/creatorconvos.js';
import UnifiedLogin from './Pages/Login/UnitedLogin.js';
import FileViewEdit from './Pages/Files/fileviewedit.js';
import CampaignReport from './Pages/Report/CampaignReport.js';
import BioPage from './Bio/creatorbio.js';
import CreatorBio from './CreatorUser/CreatorPages/Dashboard/Bio/creatorbio.js';

const Home = React.lazy(() => import('./Pages/Home/Home'));
const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider> {/* Wrap the app with HelmetProvider */}
      <QueryClientProvider client={queryClient}>
        <AppContent />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

function AppContent() {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const { isAuthorized } = useAuth();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { data: userSettings } = useQuery(
    ['userSettings'],
    client.users.fetchUserSettings,
    { enabled: isAuthorized, staleTime: 1000 * 60 * 60 * 12 }
  );

  // Theme configuration
  const theme = useMemo(() => {
    const effectiveDarkMode = userSettings?.dark_mode ?? prefersDarkMode;
    const fontPreference = userSettings?.font_preference || 'default';

    const fontFamily = {
      'roboto': '"Roboto", "Helvetica", "Arial", sans-serif',
      'open-sans': '"Open Sans", sans-serif',
      'montserrat': '"Montserrat", sans-serif',
      'default': 'Inter, Arial, sans-serif'
    }[fontPreference];

    return getTheme({
      darkMode: effectiveDarkMode,
      customColors: userSettings?.theme_colors,
      density: userSettings?.ui_density,
      fontFamily: fontFamily
    });
  }, [userSettings, prefersDarkMode, isAuthorized]);

  // Apply font preference
  useEffect(() => {
    if (userSettings?.font_preference) {
      const fontFamily = {
        'roboto': '"Roboto", "Helvetica", "Arial", sans-serif',
        'open-sans': '"Open Sans", sans-serif',
        'montserrat': '"Montserrat", sans-serif'
      }[userSettings.font_preference];
      if (fontFamily) document.body.style.fontFamily = fontFamily;
    }
  }, [userSettings?.font_preference]);

  const toggleHelpDialog = () => setHelpDialogOpen(!helpDialogOpen);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {false && <Fab
        variant="contained"
        size='large'
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(2),
          left: theme.spacing(2),
          backgroundColor: theme.palette.mode == 'dark' ? "#222" : "#eee",
          color: theme.palette.mode == 'dark' ? "#eee" : "#222",
          zIndex: theme.zIndex.drawer + 1,
        })}
        onClick={() => { setIsDark(!isDark); }}
      >
        {!isDark && <LightModeIcon></LightModeIcon>}
        {isDark && <DarkModeIcon></DarkModeIcon>}
      </Fab>}
      {false && <Fab
        variant="contained"
        size='large'
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(2),
          left: theme.spacing(2), // Adjust to place it next to the light/dark mode button
          zIndex: theme.zIndex.drawer + 1,
        })}
        onClick={toggleHelpDialog}
      >
        <HelpIcon />
      </Fab>}
      <CreatorAuthProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <HelpDialog open={helpDialogOpen} onClose={toggleHelpDialog} />
            <Routes>
              {/* Creator Connect Routes */}
              <Route element={<CreatorConnectRoute onHelp={toggleHelpDialog} />}>
                <Route path={routes.creatorConnectOauth} element={<CreatorRoute><PageTitle title="Authorize - Blitz" /><BlitzAuth /></CreatorRoute>} />
                <Route path={routes.creatorConnectStart} element={<GoogleOAuthProvider clientId={googleClientId}><CreatorRoute><PageTitle title="Creator Start - Blitz" /><CreatorStart /></CreatorRoute></GoogleOAuthProvider>} />
                <Route path={routes.creatorConnectCampaigns} element={<CreatorRoute><PageTitle title="Creator Campaigns - Blitz" /><CreatorCampaigns /></CreatorRoute>} />
                <Route path={routes.creatorConnectPayoutsInvoicing} element={<CreatorRoute><PageTitle title="Payouts & Invoicing - Blitz" /><PayoutsInvoicing /></CreatorRoute>} />
                <Route path={routes.creatorConnectPitch} element={<CreatorRoute><PageTitle title="Outbound - Blitz" /><Pitch /></CreatorRoute>} />
                <Route path={routes.creatorConnectEdit} element={<GoogleOAuthProvider clientId={googleClientId}><CreatorRoute><PageTitle title="Edit Creator Account - Blitz" /><EditCreator /></CreatorRoute></GoogleOAuthProvider>} />
                <Route path={routes.creatorConnectDealsEmails} element={<CreatorRoute><PageTitle title="Inbound - Blitz" /><BrandEmails /></CreatorRoute>} />
                <Route path={routes.creatorConnectMore} element={<CreatorRoute><PageTitle title="More - Blitz" /><CreatorMore /></CreatorRoute>} />
                <Route path={routes.creatorCampaigns} element={<CreatorRoute><PageTitle title="Manage Campaign - Blitz" /><CreatorSpecificCampaign /></CreatorRoute>} />
                <Route path={routes.creaotrParntershipsSpecific} element={<CreatorRoute><PageTitle title="Manage Partnership - Blitz" /><CreatorSpecificPartnership /></CreatorRoute>} />
                <Route path={routes.pitchFromZap} element={<CreatorRoute><PageTitle title="Pitch to a Project - Blitz" /><PitchFromZap /></CreatorRoute>} />
                <Route path={routes.creatorConnectCommissions} element={<CreatorRoute><PageTitle title="Your Commissions - Blitz" /><CreatorCommissions /></CreatorRoute>} />
                <Route path={routes.creatorCastingCalls} element={<CreatorRoute><PageTitle title="Creator Casting Calls - Blitz" /><CreatorCastingCalls /></CreatorRoute>} />
                <Route path={routes.creatorCollabs} element={<CreatorRoute><PageTitle title="Your Collaborations - Blitz" /><CreatorCollaborations /></CreatorRoute>} />
                <Route path={routes.creatorDeals} element={<CreatorRoute><PageTitle title="Your Deal Factors - Blitz" /><CreatorDeals /></CreatorRoute>} />
                <Route path={routes.creatorDealIOSpec} element={<CreatorRoute><PageTitle title="Manage Deal - Blitz" /><CreatorDealSpec /></CreatorRoute>} />
                <Route path={routes.creatorConnectApps} element={<CreatorRoute><PageTitle title="Apps - Blitz" /><Apps /></CreatorRoute>} />
                <Route path={routes.creatorConnectAppsSpec} element={<CreatorRoute><PageTitle title="App - Blitz" /><AppSpec /></CreatorRoute>} />
                <Route path={routes.creatorInvoicing} element={<CreatorRoute><PageTitle title="Send a Blitz Invoice" /><CreatorInvoicingPage /></CreatorRoute>} />
                <Route path={routes.creatorConvos} element={<CreatorRoute><PageTitle title="Creator Convos - Blitz" /><CreatorConversationsHub /></CreatorRoute>} />
                <Route path={routes.creatorbio} element={<CreatorRoute><PageTitle title="Creator Bio  - Blitz" /><CreatorBio /></CreatorRoute>} />

              </Route>

              {/* Private Routes with Header */}
              <Route element={<BlitzRoute onHelp={toggleHelpDialog} />}>
                <Route path={routes.dashboard} element={<PrivateRoute><PageTitle title="Dashboard - Blitz" /><Dashboard /></PrivateRoute>} />
                <Route path={routes.user} element={<GoogleOAuthProvider clientId={googleClientId}><PrivateRoute><PageTitle title="User Management - Blitz" /><User /></PrivateRoute></GoogleOAuthProvider>} />
                <Route path={routes.invoicing} element={<PrivateRoute><PageTitle title="Invoicing - Blitz" /><Invoicing /></PrivateRoute>} />
                <Route path={routes.campaigns} element={<PrivateRoute><PageTitle title="Campaigns - Blitz" /><Navigate to={routes.collaborations} replace /></PrivateRoute>} />
                <Route path={routes.search} element={<PrivateRoute><PageTitle title="Search - Blitz" /><Search /></PrivateRoute>} />
                <Route path={routes.blitzpay} element={<PrivateRoute><PageTitle title="BlitzPay" /><BlitzPay /></PrivateRoute>} />
                <Route path={routes.addCreators} element={<PrivateRoute><PageTitle title="Add Creators - Blitz" /><AddCreators /></PrivateRoute>} />
                <Route path={routes.company} element={<PrivateRoute><PageTitle title="Company List - Blitz" /><CompanyList /></PrivateRoute>} />
                <Route path={routes.commisions} element={<PrivateRoute><PageTitle title="Your Commissions - Blitz" /><Commissions /></PrivateRoute>} />
                <Route path={routes.creatorCRMPublic} element={<><PageTitle title="Creator CRM - Blitz" /><CreatorCRMViewPublic /></>} />
                <Route path={routes.files} element={<PrivateRoute><PageTitle title="Your Files - Blitz" /><AssetsManager /></PrivateRoute>} />
                <Route path={routes.editCampaign} element={<PrivateRoute><PageTitle title="Edit Campaign - Blitz" /><EditCampaignPage /></PrivateRoute>} />
                <Route path={routes.paypage} element={<PrivateRoute><PageTitle title="BlitzPay" /><PayPage /></PrivateRoute>} />
                <Route path={routes.partnershipsPage} element={<PrivateRoute><PageTitle title="Partnerships - Blitz" /><PartnershipsPage /></PrivateRoute>} />
                <Route path={routes.trackers} element={<PrivateRoute><PageTitle title="Trackers - Blitz" /><Trackers /></PrivateRoute>} />
                <Route path={routes.campaignconvospec} element={<PrivateRoute><PageTitle title="Chat with Creator - Blitz" /><CampaignCreatorSpecific /></PrivateRoute>} />
                <Route path={routes.partnerships} element={<PrivateRoute><PageTitle title="Partnerships - Blitz" /><Navigate to={routes.collaborations} replace /></PrivateRoute>} />
                <Route path={routes.castingCalls} element={<PrivateRoute><PageTitle title="Casting Calls - Blitz" /><CastingCalls /></PrivateRoute>} />
                <Route path={routes.integrations} element={<PrivateRoute><PageTitle title="Integrations - Blitz" /><Integrations /></PrivateRoute>} />
                <Route path={routes.collaborations} element={<PrivateRoute><PageTitle title="Collaborations - Blitz" /><Collaborations /></PrivateRoute>} />
                <Route path={routes.dealsIO} element={<PrivateRoute><PageTitle title="Deal Factoring - Blitz" /><DealIOs /></PrivateRoute>} />
                <Route path={routes.dealsIOSpec} element={<PrivateRoute><PageTitle title="Manage Deal - Blitz" /><DealIODetail /></PrivateRoute>} />
                <Route path={routes.contractSpec} element={<PrivateRoute><PageTitle title="Manage Contract - Blitz" /><ContractDetailsPage /></PrivateRoute>} />
                <Route path={routes.userProfile} element={<PrivateRoute><PageTitle title="View User Profile - Blitz" /><UserProfile /></PrivateRoute>} />
                <Route path={routes.conversationshub} element={<PrivateRoute><PageTitle title="Conversations - Blitz" /><ConversationsHub /></PrivateRoute>} />
                <Route path={routes.clientInvoicing} element={<PrivateRoute><PageTitle title="Create a Blitz Invoice" /><ClientInvoicingPage /></PrivateRoute>} />
                <Route path={routes.creatorBooking} element={<PrivateRoute><PageTitle title="Creator Booking" /><InternalBookingPage /></PrivateRoute>} />

                <Route element={<AgencyRoot />}>
                  <Route path={routes.agency} element={<PrivateRoute><PageTitle title="Agency - Blitz" /><AgencyStart /></PrivateRoute>} />
                  <Route path={routes.agencyCreators} element={<PrivateRoute><PageTitle title="My Agency Creators - Blitz" /><AgencyCreators /></PrivateRoute>}>
                    <Route path={routes.agencyCreator} element={<AgencyCreator />}>
                      <Route index element={<AgencyCreatorEdit />} />
                      <Route path={routes.agencyCreatorCollaborations} element={<AgencyCreatorCollaborations />}>
                        <Route path={routes.agencyCreatorPartnership} element={<AgencyPartnershipDialog />} />
                        <Route path={routes.agencyCreatorCampaign} element={<AgencyCreatorCampaign />} />
                      </Route>
                      <Route path={routes.agencyCreatorConversations} element={<AgencyCreatorConversations />}>
                        <Route path={routes.agencyCreatorConversation} element={<AgencyCampaignConversation />} />
                      </Route>
                      <Route path={routes.agencyCreatorPayouts} element={<AgencyCreatorPayouts />} />
                    </Route>
                  </Route>
                </Route>
              </Route>

              {/* Docs Pages */}
              <Route element={<DocsRoute />}>
                <Route path={routes.docsHome} element={<><PageTitle title="Blitz API Documentation" /><DocsHomepage /></>} />
                <Route path={routes.docsService} element={<><PageTitle title="1) Services - Blitz API Documentation" /><DocsServices /></>} />
                <Route path={routes.docsKeys} element={<><PageTitle title="2) API keys - Blitz API Documentation" /><DocsApikeys /></>} />
                <Route path={routes.docsOauth} element={<><PageTitle title="3) Login with Blitz - Blitz API Documentation" /><DocsOAuth /></>} />
                <Route path={routes.docsEndpoints} element={<><PageTitle title="Basic API Usage - Blitz API Documentation" /><DocsBasics /></>} />
                <Route path={routes.docsEndCreators} element={<><PageTitle title="Creator - Blitz API Documentation" /><DocsCreators /></>} />
              </Route>

              {/* Public Pages */}
              <Route path={routes.base} element={<Navigate to={routes.home} />} />
              <Route path={routes.home} element={<><PageTitle title="Blitz - Pay, Create, Collaborate" /><Main><Home /></Main></>} />
              <Route path={routes.tempCreator} element={<><PageTitle title="Your Temp page - Blitz" /><TempCreatorPage /></>} />
              <Route path={routes.campaignReport} element={<><PageTitle title="Campaign Details - Blitz" /><CampaignReport></CampaignReport></>} />
              <Route path={routes.creatorMediaKit} element={<><PageTitle title="Creator Media Kit - Blitz" /><MediaKit /></>} />
              <Route path={`${routes.creatorMediaKit}/promotional/:discount`} element={<MediaKit />} />
              <Route path={routes.roster} element={<AgencyDetailsPage />} />
              <Route path={`${routes.creatorSignup}/:manager`} element={<><PageTitle title="Creator Signup - Blitz" /><CreatorSignup /></>} />
              <Route path={`${routes.creatorMediaKit}/similar`} element={<><PageTitle title="Similar Creators - Blitz" /><SimilarCreators /></>} />
              <Route path={`${routes.demoRegister}/:ref`} element={<><PageTitle title="Register Your Free Account - Blitz" /><DemoRegister /></>} />
              <Route path={routes.about} element={<><PageTitle title="About - Blitz" /><BlitzSummary /></>} />
              <Route path={routes.creatorCampaignTimeline} element={<><PageTitle title="Creator Campaign Timelines - Blitz" /><CreatorCampaignTimelines /></>} />
              <Route path={routes.deliverablePage} element={<><PageTitle title="Deliverables - Blitz" /><CreatorDeliverables /></>} />
              <Route path={`${routes.invoicing}/:invoiceId`} element={<><PageTitle title="Invoice - Blitz" /><InvoicePage /></>} />
              <Route path={`${routes.creatorPayoutInvoices}/:id`} element={<><PageTitle title="Creator Invoice - Blitz" /><CreatorInvoicePage /></>} />
              <Route path={routes.help} element={<><PageTitle title="Help - Blitz" /><HelpPage /></>} />
              <Route path={routes.solutions} element={<><PageTitle title="Solutions - Blitz" /><Solutions /></>} />
              <Route path={routes.solutionsAgencies} element={<><PageTitle title="For Agencies - Blitz" /><Agencies /></>} />
              <Route path={routes.forcreators} element={<><PageTitle title="For Creators - Blitz" /><ForCreators /></>} />
              <Route path={routes.whypartner} element={<><PageTitle title="Why Partner? - Blitz" /><WhyPartner /></>} />
              <Route path={routes.parntersListL} element={<><PageTitle title="Our Creator Partners - Blitz" /><PartnerCreatorRoster /></>} />
              <Route path={routes.privacyPolicy} element={<><PageTitle title="Privacy Policy - Blitz" /><PrivacyPolicy /></>} />
              <Route path={routes.campaignOptOut} element={<><PageTitle title="Archive Campaign Proposal - Blitz" /><OptOutCampaign /></>} />
              <Route path={`${routes.payoutSpec}/:payoutId`} element={<><PageTitle title="Payout - Blitz" /><PayoutPage /></>} />
              <Route path={routes.manageDealsIO} element={<><PageTitle title="View Deal - Blitz" /><DealIOPublic /></>} />
              <Route path={routes.releasteNotes} element={<><PageTitle title="Blitz Release Notes" /><BlitzReleaseNotes /></>} />
              <Route path={routes.campaignOffer} element={<><PageTitle title="Campaign Offer - Blitz" /><CampaignOfferPage /></>} />
              <Route path={routes.PublicUserProfile} element={<><PageTitle title="View Public Profile - Blitz" /><PublicUserProfile /></>} />
              <Route path={routes.agencyTerms} element={<><PageTitle title="Blitz Terms of Service" /><TermsOfServiceBusinessEntities /></>} />
              <Route path="/blitzbolts/:userId/:attainmentId" element={<BlitzBoltAchievement />} />
              <Route path={routes.campaignPublic} element={<><PageTitle title="Blitz Campaign" /><CampaignDetailsPage /></>} />
              {/* Sign Ups and Registers */}
              <Route path={routes.login} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Login - Blitz" /><UnifiedLogin /></GoogleOAuthProvider>} />
              <Route path={routes.accessfile} element={<><PageTitle title="Your File" /><FileViewEdit /></>} />
              <Route path={routes.oldlogin} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Login - Blitz" /><Login /></GoogleOAuthProvider>} />

              <Route path={routes.register} element={<><PageTitle title="Register - Blitz" /><Register /></>} />
              <Route path={routes.passwordForgot} element={<><PageTitle title="Forgot my Password - Blitz" /><ForgotPassword /></>} />
              <Route path={routes.passwordRenew} element={<><PageTitle title="Forgot my Password - Blitz" /><RestorePassword /></>} />
              <Route path={`${routes.register}/:company`} element={<><PageTitle title="Register - Blitz" /><Register /></>} />
              <Route path={routes.requestAccess} element={<><PageTitle title="Request Access - Blitz" /><RequestAccess /></>} />
              <Route path={`${routes.requestAccess}/:ref`} element={<><PageTitle title="Request Access - Blitz" /><RequestAccess /></>} />
              <Route path={routes.creatorSignup} element={<><PageTitle title="Creator Signup - Blitz" /><CreatorSignup /></>} />
              <Route path={`${routes.vendorSignup}/:company`} element={<><PageTitle title="Vendor Signup - Blitz" /><VendorSignupPage /></>} />
              <Route path={routes.articles} element={<><PageTitle title="Articles - Blitz" /><Articles /></>} />
              <Route path="/articles/streamline-campaigns" element={<><PageTitle title="Streamline Your Influencer Campaigns - Blitz" /><StreamlineCampaigns /></>} />
              <Route path="/articles/future-payments" element={<><PageTitle title="Future of Creator and Vendor Payments - Blitz" /><FutureOfPayments /></>} />
              <Route path="/articles/hottest-software" element={<><PageTitle title="The Hottest New Free Creator Software - Blitz" /><HottestNewCreatorSoftware /></>} />
              <Route path={routes.onboard} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Onboard - Blitz" /><AllOnboard /></GoogleOAuthProvider>} />
              <Route path={routes.bioPublic} element={<><PageTitle title="Bio - Blitz" /><BioPage /></>} />

              <Route path={routes.creatorConnectStripe} element={<CreatorRoute><PageTitle title="Creator Start - Blitz" /><CreatorStripeId /></CreatorRoute>} />
              <Route path={routes.creatorConnectGoogle} element={<><PageTitle title="Redirect - Blitz" /><CreatorRedirect /></>} />
              <Route path={routes.creatorConnectOnboard} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Onboard - Blitz" /><AllOnboard /></GoogleOAuthProvider>} />
              <Route path={routes.creatorConnectOnboardApp} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Onboard with App - Blitz" /><CreatorOnboardApp /></GoogleOAuthProvider>} />
              <Route path={routes.creatorConnectReauth} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Reauthenticate your Creator Connect Account - Blitz" /><CreatorConnectReatuh /></GoogleOAuthProvider>} />
              <Route path={routes.creatorConnect} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Creator Connect - Blitz" /><CreatorConnect /></GoogleOAuthProvider>} />
              <Route path={routes.creatorLogin} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Creator Login - Blitz" /><RedirectWithQuery to={routes.login} newQuery={{ tab: 'creator' }} /></GoogleOAuthProvider>} />
              {/* Internal Login for Testing */}
              {process.env.NODE_ENV === 'development' && <Route path={routes.internalLogin} element={<><PageTitle title="Creator Login - Blitz" /><InternalLogin /></>} />}
              {process.env.NODE_ENV === 'development' && <Route path={routes.internalUserLogin} element={<><PageTitle title="Login - Blitz" /><InternalLoginUser /></>} />}
              <Route path="*" element={<><PageTitle title="404 Not Found - Blitz" /><Navigate to={routes.home} replace /></>} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </CreatorAuthProvider>
    </ThemeProvider>
  );
}

export default App;