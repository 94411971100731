import React, { useEffect, useState } from "react";
import {
  Dialog,
  Container,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import client from "../../../API";
import MuiPhoneInput from "../../../Components/MuiPhoneInput";
import { useQueryClient } from "react-query";
import { niches, parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import { platformSelection } from "../../../Components/CreatorData/creatorValidation";
import CreatorPlatforms from "../../../Components/CreatorData/creatorPlatforms";

const formatNumber = (value) => {
  const number = parseInt(value, 10);
  return isNaN(number) ? "0" : number.toString();
}

const CreatorDialog = ({ open, onClose, creatorInfo, mode }) => {
  const queryClient = useQueryClient();
  const [contactEmail, setContactEmail] = useState(creatorInfo?.email);
  const [paymentEmail, setPaymentEmail] = useState(creatorInfo?.payout_email || creatorInfo?.email);
  const [phoneNumber, setPhoneNumber] = useState(creatorInfo?.phone_number);
  const [emailSame, setEmailSame] = useState(false);
  const [creatorName, setCreatorName] = useState(
    creatorInfo ? creatorInfo.creator : "",
  );
  // Note: useEffect below will update these when creatorInfo changes.
  const [avgViews, setAvgViews] = useState(creatorInfo?.avg_views || "");
  const [status, setStatus] = useState(creatorInfo?.status || "");
  const [manager, setManager] = useState(creatorInfo?.manager || "");
  const [supervisor, setSupervisor] = useState(creatorInfo?.superviser || "");

  const [pfphref, setPfphref] = useState(creatorInfo?.pfphref || ""); // Profile photo URL

  const [creatorRegion, setCreatorRegion] = useState(
    creatorInfo ? creatorInfo.region : "",
  );
  const [platforms, setPlatforms] = useState(platformSelection);
  const [primaryMarket, setPrimaryMarket] = useState(
    creatorInfo ? creatorInfo.primary_markets : [],
  );
  const [notesContentStyle, setNotesContentStyle] = useState(
    creatorInfo ? creatorInfo.notes_content_style : "",
  );

  const [race, setRace] = useState(creatorInfo?.ethnicity || "");
  const [gender, setGender] = useState(creatorInfo?.gender || "");
  const [location, setLocation] = useState(creatorInfo?.geolocation || "");

  // Instagram
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");
  const [instagramFeedRate, setInstagramFeedRate] = useState("");

  // TikTok
  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");

  // YouTube
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtubeIntegrationRate, setYoutubeIntegrationRate] = useState("");
  const [youtubeShortsRate, setYoutubeShortsRate] = useState("");
  const [youtubeDedicatedRate, setYoutubeDedicatedRate] = useState("");

  const [xLink, setXLink] = useState('');
  const [xFollowerCount, setXFollowerCount] = useState('');
  const [xFeedPost, setXFeedPost] = useState('');
  const [xRepost, setXRepost] = useState('');

  const [podcastLink, setPodcastLink] = useState('');
  const [podcastFollowerCount, setPodcastFollowerCount] = useState('');
  const [podcastPreroll, setPodcastPreroll] = useState('');
  const [podcastMidroll, setPodcastMidroll] = useState('');
  const [podcastPostroll, setPodcastPostroll] = useState('');
  const [podcastFull, setPodcastFull] = useState('');

  const [kickLink, setKickLink] = useState('');
  const [kickFollowerCount, setKickFollowerCount] = useState('');
  const [kickPlacement, setKickPlacement] = useState('');

  const [twitchLink, setTwitchLink] = useState('');
  const [twitchFollowerCount, setTwitchFollowerCount] = useState('');
  const [twitchPlacement, setTwitchPlacement] = useState('');

  const [isVendor, setIsVendor] = useState(false);
  const [invalid_geogenderrace, setInvalid_geogenderrace] = useState(false);
  
  // NEW: Marketplace permission state (defaults to true)
  const [marketplacePermission, setMarketplacePermission] = useState(
    creatorInfo?.marketplace_permission !== undefined ? creatorInfo.marketplace_permission : true
  );

  useEffect(() => {
    if (!creatorInfo)
      return;

    console.log("Loading creator", creatorInfo);

    const creator = creatorInfo;
    setContactEmail(creator.email);
    console.log(`${creator.email} is equal to ${creator.payout_email}? ${(creator.email === creator.payout_email)}`)
    setEmailSame(creator.email === creator.payout_email);
    setPaymentEmail(creator.payout_email || '');
    setPhoneNumber(creator.phone_number);
    setCreatorName(creator.creator);
    setCreatorRegion(creator.region);
    setPlatforms({
      TikTok: creator.tiktok_link !== null,
      Instagram: creator.instagram_link !== null,
      YouTube: creator.youtube_link !== null,
      X: creator.x_link !== null,
      Podcast: creator.podcast_link !== null,
      Kick: creator.kick_link !== null,
      Twitch: creator.twitch_link !== null,
    });
    setPrimaryMarket(creator.primary_markets);
    setNotesContentStyle(creator.notes_content_style);

    setRace(creator.ethnicity || '');
    setGender(creator.gender || '');
    setLocation(creator.geolocation || '');

    //Instagram
    setInstagramLink(creator.instagram_link ? creator.instagram_link.replace("https://www.instagram.com/", "") : "");
    setInstagramFollowerCount(creator.instagram ? formatNumber(creator.instagram) : "");

    setInstagramBrandRate(creator.rate_ig_reelbrand?.toFixed(2) || "");
    setInstagramSongRate(creator.rate_ig_reelsound?.toFixed(2) || "");
    setInstagramFeedRate(creator.rate_ig_feedpost?.toFixed(2) || "");
    setInstagramStoryRate(creator.rate_ig_story?.toFixed(2) || "");

    //TikTok
    setTikTokLink(creator.tiktok_link ? creator.tiktok_link.replace("https://www.tiktok.com/@", "") : "");
    setTikTokFollowerCount(creator.tiktok ? formatNumber(creator.tiktok) : "");

    setTikTokBrandRate(creator.rate_tt_brand?.toFixed(2) || "");
    setTikTokSongRate(creator.rate_tt_sound?.toFixed(2) || "");

    //Youtube
    setYoutubeLink(creator.youtube_link ? creator.youtube_link.replace("https://www.youtube.com/", "") : "");
    setYoutubeFollowerCount(creator.youtube ? formatNumber(creator.youtube) : "");

    setYoutubeIntegrationRate(creator.rate_yt_integ?.toFixed(2) || "");
    setYoutubeShortsRate(creator.rate_yt_short?.toFixed(2) || "");
    setYoutubeDedicatedRate(creator.rate_yt_dedicated?.toFixed(2) || "");

    //X
    setXLink(creator.x_link ? creator.x_link.replace("https://www.x.com/", "") : "");
    setXFollowerCount(creator.follow_x ? formatNumber(creator.follow_x.toString()) : "");

    setXFeedPost(creator.rate_x_feedpost?.toFixed(2) || "");
    setXRepost(creator.rate_x_repost?.toFixed(2) || "");

    //Kick
    setKickLink(creator.kick_link ? creator.kick_link.replace("https://www.kick.com/", "") : "");
    setKickFollowerCount(creator.follow_kick ? formatNumber(creator.follow_kick.toString()) : "");

    setKickPlacement(creator.rate_kick_product?.toFixed(2) || "");

    //Twitch
    setTwitchLink(creator.twitch_link ? creator.twitch_link.replace("https://www.twitch.tv/", "") : "");
    setTwitchFollowerCount(creator.twitch ? formatNumber(creator.twitch) : "");
    setTwitchPlacement(creator.rate_twitch_product?.toFixed(2) || "");

    //Podcast
    setPodcastLink(creator.podcast_link || "");
    setPodcastFollowerCount(creator.follow_podcast ? formatNumber(creator.follow_podcast) : "");
    setPodcastPreroll(creator.rate_podcast_preroll?.toFixed(2) || "");
    setPodcastMidroll(creator.rate_podcast_midroll?.toFixed(2) || "");
    setPodcastPostroll(creator.rate_podcast_postroll?.toFixed(2) || "");
    setPodcastFull(creator.rate_podcast_full?.toFixed(2) || "");

    setIsVendor(creator.is_vendor);
    setInvalid_geogenderrace(creator.invalid_geogenderrace);
    
    // NEW: Set marketplace permission from creatorInfo
    if (creator.marketplace_permission !== undefined) {
      setMarketplacePermission(creator.marketplace_permission);
    }
  }, [creatorInfo]);

  const handlePlatformChange = (event) => {
    setPlatforms({ ...platforms, [event.target.name]: event.target.checked });
  };

  const handleEmailSameChange = (event) => {
    setEmailSame(event.target.checked);
    if (event.target.checked) {
      setPaymentEmail(contactEmail);
    } else {
      setPaymentEmail("");
    }
  };

  const handleIsVendorChange = (event) => {
    setIsVendor(event.target.checked);
  };

  const handleInvalidGeoChange = (event) => {
    setInvalid_geogenderrace(event.target.checked);
  };

  const handleFollowerCountChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleUpdateCreator = async () => {
    let payload = null;

    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;
    const formattedXLink = xLink ? `https://www.x.com/${xLink}` : null;
    const formattedKickLink = kickLink ? `https://www.kick.com/${kickLink}` : null;
    const formattedTwitchLink = twitchLink ? `https://www.twitch.tv/${twitchLink}` : null;

    try {
      payload = {
        creator_id: creatorInfo.creator,
        creator: creatorName,
        geolocation: location,
        gender: gender,
        ethnicity: race,
        primary_markets: primaryMarket || creatorInfo.primary_markets,
        region: creatorRegion || creatorInfo.region,
        notes_content_style: notesContentStyle || creatorInfo.notes_content_style,

        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,

        twitch: parseLocalInt(twitchFollowerCount) || null,
        twitch_link: formattedTwitchLink || null,

        follow_x: parseLocalInt(xFollowerCount) || null,
        x_link: formattedXLink || null,

        follow_podcast: parseLocalInt(podcastFollowerCount) || null,
        podcast_link: podcastLink || null,

        follow_kick: parseLocalInt(kickFollowerCount) || null,
        kick_link: formattedKickLink || null,

        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),

        rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_ig_story: parseLocalFloat(instagramStoryRate),

        rate_yt_integ: parseLocalFloat(youtubeIntegrationRate),
        rate_yt_short: parseLocalFloat(youtubeShortsRate),
        rate_yt_dedicated: parseLocalFloat(youtubeDedicatedRate),

        rate_podcast_preroll: parseLocalFloat(podcastPreroll),
        rate_podcast_midroll: parseLocalFloat(podcastMidroll),
        rate_podcast_postroll: parseLocalFloat(podcastPostroll),
        rate_podcast_full: parseLocalFloat(podcastFull),

        rate_twitch_product: parseLocalFloat(twitchPlacement),
        rate_kick_product: parseLocalFloat(kickPlacement),
        rate_x_feedpost: parseLocalFloat(xFeedPost),
        rate_x_repost: parseLocalFloat(xRepost),

        phone_number: phoneNumber || creatorInfo.phone_number,
        email: contactEmail || creatorInfo.email,
        payout_email: emailSame ? contactEmail : paymentEmail,
        pfphref: pfphref || creatorInfo.pfphref,
        manager: manager || creatorInfo.manager,
        superviser: supervisor || creatorInfo.superviser,
        status: status || creatorInfo.status,
        avg_views: avgViews || creatorInfo.avg_views,
        is_vendor: isVendor,
        invalid_geogenderrace: invalid_geogenderrace,
        // NEW: Include marketplace_permission in the update payload
        marketplace_permission: marketplacePermission
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }

    try {
      const data = await client.creators.update(payload)
      alert("Update successful");
      queryClient.invalidateQueries(['creators']);
      console.log("Update successful", data);
    } catch (error) {
      alert(`Error: ${error?.response?.data?.error ? error.response.data.error : error.message}`);
      console.error("Error updating creator", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Container maxWidth="xl">
        <h1>Creator Intake Form</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdateCreator();
          }}
        >
          <TextField
            margin="dense"
            label="Creator Name"
            type="text"
            fullWidth
            value={creatorName}
            onChange={(e) => setCreatorName(e.target.value)}
            disabled
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Checkbox checked={invalid_geogenderrace} onChange={handleInvalidGeoChange} />
            }
            label="Invalid GeoData? (Uncheck this after fixing)"
          />
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Creator Region</InputLabel>
            <Select
              label="Creator Region"
              value={creatorRegion}
              onChange={(e) => setCreatorRegion(e.target.value)}
            >
              <MenuItem value="NACAUKAUS">USA, Canada, UK, Australia</MenuItem>
              <MenuItem value="Europe">Europe</MenuItem>
              <MenuItem value="Asia">Asia</MenuItem>
              <MenuItem value="LATAM">LATAM</MenuItem>
              <MenuItem value="Africa">Africa</MenuItem>
            </Select>
          </FormControl>
          <CreatorPlatforms
            platforms={platforms} setPlatforms={setPlatforms}
            instagramLink={instagramLink} setInstagramLink={setInstagramLink}
            instagramFollowerCount={instagramFollowerCount} setInstagramFollowerCount={setInstagramFollowerCount}
            instagramBrandRate={instagramBrandRate} setInstagramBrandRate={setInstagramBrandRate}
            instagramSongRate={instagramSongRate} setInstagramSongRate={setInstagramSongRate}
            instagramFeedRate={instagramFeedRate} setInstagramFeedRate={setInstagramFeedRate}
            instagramStoryRate={instagramStoryRate} setInstagramStoryRate={setInstagramStoryRate}
            tikTokLink={tikTokLink} setTikTokLink={setTikTokLink}
            tikTokFollowerCount={tikTokFollowerCount} setTikTokFollowerCount={setTikTokFollowerCount}
            tikTokBrandRate={tikTokBrandRate} setTikTokBrandRate={setTikTokBrandRate}
            tikTokSongRate={tikTokSongRate} setTikTokSongRate={setTikTokSongRate}
            youtubeLink={youtubeLink} setYoutubeLink={setYoutubeLink}
            youtubeFollowerCount={youtubeFollowerCount} setYoutubeFollowerCount={setYoutubeFollowerCount}
            youtubeIntegrationRate={youtubeIntegrationRate} setYoutubeIntegrationRate={setYoutubeIntegrationRate}
            youtubeShortsRate={youtubeShortsRate} setYoutubeShortsRate={setYoutubeShortsRate}
            youtubeDedicatedRate={youtubeDedicatedRate} setYoutubeDedicatedRate={setYoutubeDedicatedRate}
            xLink={xLink} setXLink={setXLink}
            xFollowerCount={xFollowerCount} setXFollowerCount={setXFollowerCount}
            xFeedPost={xFeedPost} setXFeedPost={setXFeedPost}
            xRepost={xRepost} setXRepost={setXRepost}
            podcastLink={podcastLink} setPodcastLink={setPodcastLink}
            podcastFollowerCount={podcastFollowerCount} setPodcastFollowerCount={setPodcastFollowerCount}
            podcastPreroll={podcastPreroll} setPodcastPreroll={setPodcastPreroll}
            podcastMidroll={podcastMidroll} setPodcastMidroll={setPodcastMidroll}
            podcastPostroll={podcastPostroll} setPodcastPostroll={setPodcastPostroll}
            podcastFull={podcastFull} setPodcastFull={setPodcastFull}
            kickLink={kickLink} setKickLink={setKickLink}
            kickFollowerCount={kickFollowerCount} setKickFollowerCount={setKickFollowerCount}
            kickPlacement={kickPlacement} setKickPlacement={setKickPlacement}
            twitchLink={twitchLink} setTwitchLink={setTwitchLink}
            twitchFollowerCount={twitchFollowerCount} setTwitchFollowerCount={setTwitchFollowerCount}
            twitchPlacement={twitchPlacement} setTwitchPlacement={setTwitchPlacement}
          ></CreatorPlatforms>

          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="race-select-label">Race (optional)</InputLabel>
            <Select
              labelId="race-select-label"
              value={race}
              onChange={(e) => setRace(e.target.value)}
              label="Race (optional)"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Asian">Asian</MenuItem>
              <MenuItem value="Black">Black</MenuItem>
              <MenuItem value="Hispanic">Hispanic</MenuItem>
              <MenuItem value="White">White</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="gender-select-label">Gender</InputLabel>
            <Select
              labelId="gender-select-label"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              label="Gender"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Non-binary">Non-binary</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Location"
            type="text"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Old geolocation field"
            type="text"
            fullWidth
            disabled
            value={creatorInfo ? creatorInfo.geolocation_gender_ethnicity : ""}
            variant="outlined"
          />
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="primary-market-label">Primary Market</InputLabel>
            <Select
              labelId="primary-market-label"
              value={primaryMarket}
              onChange={(e) => setPrimaryMarket(e.target.value)}
              label="Primary Market"
            >
              {niches.map((market) => (
                <MenuItem key={market} value={market}>
                  {market}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Bio"
            type="text"
            fullWidth
            multiline
            rows={4}
            placeholder="tell us about yourself"
            variant="outlined"
            value={notesContentStyle}
            onChange={(e) => setNotesContentStyle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Contact Email"
            type="email"
            fullWidth
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Paypal Payment Email"
            type="email"
            fullWidth
            disabled={emailSame}
            value={paymentEmail}
            onChange={(e) => setPaymentEmail(e.target.value)}
            variant="outlined"
          />
          <MuiPhoneInput
            country={'us'}
            preferredCountries={['us']}
            value={phoneNumber}
            onChange={setPhoneNumber}
            isValid={(value) => value.length >= 10}
            required
          />
          <TextField
            margin="dense"
            label="Profile Photo URL"
            type="text"
            fullWidth
            value={pfphref}
            onChange={(e) => setPfphref(e.target.value)}
            variant="outlined"
            placeholder="Enter URL for profile photo"
          />
          <TextField
            margin="dense"
            label="Manager"
            type="text"
            fullWidth
            value={manager}
            onChange={(e) => setManager(e.target.value)}
            variant="outlined"
            placeholder="Manager"
          />
          <TextField
            margin="dense"
            label="Supervisor"
            type="text"
            fullWidth
            value={supervisor}
            onChange={(e) => setSupervisor(e.target.value)}
            variant="outlined"
            placeholder="Supervisor"
          />
          <TextField
            margin="dense"
            label="Status"
            type="text"
            fullWidth
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            variant="outlined"
            placeholder="Status"
          />
          <FormControlLabel
            control={<Checkbox checked={emailSame} onChange={handleEmailSameChange} />}
            label="Payment and contact email are the same"
          />
          <FormControlLabel
            control={<Checkbox checked={isVendor} onChange={handleIsVendorChange} />}
            label="Is vendor?"
          />
          {/* NEW: Checkbox for Marketplace Permission */}
          <FormControlLabel
            control={<Checkbox checked={marketplacePermission} onChange={(e) => setMarketplacePermission(e.target.checked)} />}
            label="Marketplace Permission"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            Submit Information
          </Button>
        </form>
      </Container>
    </Dialog>
  );
};

export default CreatorDialog;
