import React from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

// Icons
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EditIcon from "@mui/icons-material/Edit";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"; // a "cool" invoice icon

import BaseBubble from "./BaseBubble";
import { getFileType, isMessageMine } from "../../Utils/constants";
import { styled } from "@mui/material/styles";

// Helper for styling address lines
const AddressLine = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
  color: theme.palette.text.secondary,
  fontSize: "0.9rem",
}));

/**
 * Returns an icon based on the attachmentType (and optionally fileData).
 */
const GetIcon = (attachmentType, fileData) => {
  switch (attachmentType) {
    case "invoice":
      return <ReceiptLongIcon fontSize="small" />;
    case "file":
      if (fileData?.content_type?.startsWith("image/")) {
        return <ImageOutlinedIcon fontSize="small" />;
      }
      return <InsertDriveFileOutlinedIcon fontSize="small" />;
    case "campaign":
      return <CampaignOutlinedIcon fontSize="small" />;
    case "roster":
      return <GroupOutlinedIcon fontSize="small" />;
    case "mediakit":
      return <DescriptionOutlinedIcon fontSize="small" />;
    case "creator":
      return <PersonOutlinedIcon fontSize="small" />;
    case "address":
      return <LocalShippingIcon fontSize="small" />;
    default:
      return <InsertDriveFileOutlinedIcon fontSize="small" />;
  }
};

// Helper to detect if a file is an image.
function isImageFile(fileData) {
  if (!fileData) return false;
  return getFileType(fileData.file_url) === "image";
}

/**
 * Renders the "body" of each attachment in a specialized way.
 */
function RenderAttachment({ att, onEditFile }) {
  const attachmentType = att.attachment_type;
  const fileData = att.file;

  // For non-invoice attachments, show a loading indicator if no data is present.
  if (
    attachmentType !== "invoice" &&
    !att.file &&
    !att.creator &&
    !att.roster &&
    !att.campaign &&
    !att.data
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <CircularProgress size={20} />
        <Typography variant="body2">Loading attachment...</Typography>
      </Box>
    );
  }

  // 1) Invoice attachment
  if (attachmentType === "invoice") {
    const invoice = att.data?.invoice_data;
    if (!invoice) {
      return <Typography color="error">Invalid invoice data.</Typography>;
    }
    return (
      <Card variant="outlined" sx={{ maxWidth: 320 }}>
        <CardHeader
          avatar={<ReceiptLongIcon color="primary" />}
          title={`Invoice #${invoice.id || "----"}`}
          subheader={`PO: ${invoice.po_number || "N/A"}`}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Amount: <strong>${invoice.amount}</strong>
          </Typography>
          <Typography variant="body2">
            Client: <strong>{invoice.client_name}</strong>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "0.85rem", opacity: 0.8, mt: 1 }}>
            Status: {invoice.invoice_status || "pending"}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          {/* Link to the invoice page */}
          <Button
            size="small"
            component={RouterLink}
            to={`/creatorinvoice/${invoice.id}`}
            variant="outlined"
          >
            View
          </Button>
          {/* A "share" icon linking to the invoice */}
          <Tooltip title="Share this invoice">
            <IconButton component={RouterLink} to={`/creatorinvoice/${invoice.id}`}>
              <ShareOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  }

  // 2) File attachments (documents or images)
  if (attachmentType === "file") {
    if (!fileData) {
      return <Typography variant="body2">No file data found.</Typography>;
    }
    const image = isImageFile(fileData);
    if (image) {
      return (
        <Box sx={{ position: "relative", display: "inline-block" }}>
          <Link
            href={fileData.file_url}
            download={fileData.filename}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src={fileData.file_url}
              alt="Attachment"
              sx={{
                maxWidth: 200,
                maxHeight: 200,
                marginInline: "auto",
                borderRadius: 1,
                cursor: "pointer",
                transition: "0.3s",
                "&:hover": {
                  boxShadow: 3,
                  transform: "scale(1.02)",
                },
              }}
            />
          </Link>
          {fileData.id && (
            <Tooltip title="Edit file">
              <IconButton
                onClick={() => onEditFile && onEditFile(fileData)}
                sx={{ position: "absolute", top: 4, right: 4, bgcolor: "white" }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    } else {
      // Non-image file
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <IconButton component={RouterLink} to={`/files/${fileData.id}`} color="primary">
            <ShareOutlinedIcon />
          </IconButton>
          <Typography variant="body2">{fileData.filename}</Typography>
          <Chip
            label={(fileData.content_type?.split("/")[1] || "file").toUpperCase()}
            size="small"
            sx={{ ml: 1 }}
            color="secondary"
          />
          {fileData.id && (
            <Tooltip title="Edit file">
              <IconButton onClick={() => onEditFile && onEditFile(fileData)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    }
  }

  // 3) Campaign attachments
  if (attachmentType === "campaign") {
    return (
      <Link
        component={RouterLink}
        to={`/campaigns/${att.campaign?.id || ""}`}
        style={{ textDecoration: "none", color: "inherit", display: "inline-block" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.5,
            cursor: "pointer",
            transition: "0.2s",
            "&:hover": { opacity: 0.8, transform: "translateX(2px)" }
          }}
        >
          <CampaignOutlinedIcon color="primary" />
          <Typography variant="body2">{att.campaign?.name || "Unnamed Campaign"}</Typography>
          <Chip label="Campaign" size="small" color="secondary" />
        </Box>
      </Link>
    );
  }

  // 4) Creator attachments
  if (attachmentType === "creator") {
    return (
      <Link
        component={RouterLink}
        to={`/creators/${att.creator?.creator || ""}`}
        style={{ textDecoration: "none", color: "inherit", display: "inline-block" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.5,
            cursor: "pointer",
            transition: "0.2s",
            "&:hover": { opacity: 0.8, transform: "translateX(2px)" }
          }}
        >
          <Avatar src={att.creator?.pfphref} sx={{ width: 24, height: 24 }} />
          <Typography variant="body2">{att.creator?.creator || "Unnamed Creator"}</Typography>
          <Chip label="Creator" size="small" color="secondary" />
        </Box>
      </Link>
    );
  }

  // 5) Address attachments
  if (attachmentType === "address") {
    const addressData = att.data?.data;
    if (!addressData) {
      return <Typography color="error">Invalid address data.</Typography>;
    }
    return (
      <Card variant="outlined">
        <CardHeader avatar={<LocalShippingIcon color="primary" />} title="Delivery Address" />
        <Divider />
        <CardContent>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {addressData.recipient}
          </Typography>
          <AddressLine variant="body2">{addressData.address}</AddressLine>
          <AddressLine variant="body2">
            {addressData.city}, {addressData.state} {addressData.zip}
          </AddressLine>
          <AddressLine variant="body2">{addressData.country}</AddressLine>
          {addressData.instructions && (
            <Alert severity="warning" sx={{ mt: 1 }}>
              {addressData.instructions}
            </Alert>
          )}
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "space-between", padding: 1.5 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PhoneIcon color="primary" sx={{ fontSize: "0.9rem", mr: 0.5 }} />
            <Typography variant="body2" color="text.secondary">
              {addressData.phone}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    );
  }

  // Fallback: Unknown attachment type.
  return (
    <Typography variant="body2" color="text.secondary">
      Unknown attachment type: {attachmentType}
    </Typography>
  );
}

/**
 * Primary bubble component that displays an attachment with an icon.
 */
export default function AttachmentBubble({
  msg,
  userId,
  isTcc,
  handleDeleteMessage,
  deleteMessageLoading,
  onEditFile
}) {
  const isUserMessage = isMessageMine(userId, msg);
  const att = msg.attachments;
  if (!att) return null; // No attachments to show

  const attachmentType = att.attachment_type || "unknown";

  return (
    <Tooltip title={`${attachmentType.charAt(0).toUpperCase() + attachmentType.slice(1)} Attachment`}>
      <BaseBubble
        isUserMessage={isUserMessage}
        isTcc={isTcc}
        handleDeleteMessage={() => handleDeleteMessage(msg.id)}
        deleteMessageLoading={deleteMessageLoading}
        customColors={true}
        padding={attachmentType === "address" ? 0 : 2}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
          {GetIcon(attachmentType, att.file)}
          <RenderAttachment att={att} onEditFile={onEditFile} />
        </Box>
      </BaseBubble>
    </Tooltip>
  );
}
