import React, { useState } from 'react'
import { convertToBriefText, parseCampaignBriefFromText } from '../../../../Utils/constants';
import { Box, Card, CardContent, FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import StructuredBriefEditor from '../../../../Components/StructuredBriefEditor';

const ActionCard = ({ title, value, color }) => (
  <Card
    sx={{
      minWidth: 200,
      flex: 1,
      border: `2px solid ${color}`,
      backgroundColor: "transparent",
    }}
  >
    <CardContent>
      <Typography variant="subtitle2" gutterBottom color="text.primary">
        {title}
      </Typography>
      <Typography variant="h6" color="text.primary">
        {value}
      </Typography>
    </CardContent>
  </Card>
);

export default function CampaignActionCards({
    campaignName,
    brief,
    totalSpend,
    totalViews,
    totalEngagement,
    creatorCount,
    isEditable,
    isEditingBrief,
    editedBrief,
    setEditedBrief,
    handleSaveCampaignDetails,
    setEditingBrief,
}) {
    const [editMode, setEditMode] = useState("simple");

    return (
        <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
                <Card sx={{ height: "100%", position: "relative" }}>
                    <CardContent>
                        <Box display="flex" alignItems="center" gap={1} mb={2}>
                            <Typography variant="subtitle2">Campaign Brief</Typography>
                            <Box sx={{ ml: "auto", display: "flex", gap: 1 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={editMode === "structured"}
                                            onChange={(e) => {
                                                const newMode = e.target.checked
                                                    ? "structured"
                                                    : "simple";
                                                if (newMode === "structured") {
                                                    // Switch from raw to structured
                                                    setEditedBrief(
                                                        convertToBriefText(parseCampaignBriefFromText(editedBrief))
                                                    );
                                                } else {
                                                    // Switch from structured to raw
                                                    setEditedBrief(editedBrief);
                                                }
                                                setEditMode(newMode);
                                            }}
                                            color="primary"
                                            sx={{
                                                "& .MuiSwitch-thumb": {
                                                    backgroundColor:
                                                        editMode === "structured" ? "#1976d2" : "#fff",
                                                },
                                                "& .Mui-checked": {
                                                    transform: "translateX(20px)",
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color:
                                                        editMode === "structured"
                                                            ? "primary.main"
                                                            : "text.secondary",
                                                }}
                                            >
                                                {editMode === "structured"
                                                    ? "Structured Mode"
                                                    : "Simple Mode"}
                                            </Typography>
                                        </Box>
                                    }
                                    sx={{ mr: 0 }}
                                />
                                {isEditable && (
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            isEditingBrief
                                                ? handleSaveCampaignDetails()
                                                : setEditingBrief(true)
                                        }
                                    >
                                        {isEditingBrief ? <CheckIcon /> : <EditIcon />}
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                        {isEditingBrief ? (
                            editMode === "structured" ? (
                                <StructuredBriefEditor
                                    brief={editedBrief}
                                    onBriefChange={setEditedBrief}
                                />
                            ) : (
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={6}
                                    value={editedBrief}
                                    onChange={(e) => setEditedBrief(e.target.value)}
                                    helperText="Edit raw brief content directly"
                                />
                            )
                        ) : (
                            <Typography variant="body2" whiteSpace="pre-wrap">
                                {brief || "No brief available"}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ActionCard title="Total Spend" value={`$${totalSpend}`} color="#4CAF50" />
                    </Grid>
                    <Grid item xs={6}>
                        <ActionCard title="Total Views" value={totalViews} color="#FFEB3B" />
                    </Grid>
                    <Grid item xs={6}>
                        <ActionCard
                            title="Total Engagement"
                            value={totalEngagement}
                            color="#2196F3"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ActionCard title="Creators" value={creatorCount} color="#E91E63" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
