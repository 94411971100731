import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  Button,
  styled,
  alpha,
  LinearProgress,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Globe from "./globe";
import useAuth from "../../../Hooks/use-auth";
import API from "../../../API";
import { useQuery } from "react-query";
import BlitzBoltsShowcase from "./blitzbolts";
import UnifiedUserFeed from "./unifeed";

const platformTypes = ["TikTok", "Instagram", "Youtube", "Podcasts", "Twitch", "Kick", "X"];
const nicheOptions = ["Beauty", "Fashion", "Gaming", "Lifestyle", "Music", "Sports", "Travel", "Food", "Fitness", "Comedy", "Tech"];
const priceRanges = ["< $500", "$500 - $1k", "$1k - $2.5k", "$2.5k - $5k", "$5k+"];

/** --------------- Styled Components --------------- **/
const ReferralCard = styled(Card)(({ theme }) => ({
  background: `linear-gradient(135deg, ${alpha(theme.palette.success.main, 0.15)}, ${alpha(
    theme.palette.success.light,
    0.1
  )})`,
  border: `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
  borderRadius: "24px",
  position: "relative",
  overflow: "hidden",
  "&:before": {
    content: '""',
    position: "absolute",
    top: "-50%",
    right: "-50%",
    width: "200%",
    height: "200%",
    background: `radial-gradient(circle, ${alpha(theme.palette.success.main, 0.1)} 0%, transparent 70%)`,
  },
}));

/** --- Globe Placement as in the Old Dashboard --- **/
const FloatingGlobeContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  width: "450px",
  height: "450px",
  zIndex: 0,
  opacity: 0.5,
  overflow: "hidden",
  // Hide on small screens
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const FloatingGlobe = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "-40px",
  top: "-40px",
}));

/** ------------------- Component ------------------- **/
const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();

  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedNiches, setSelectedNiches] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);

  const { data: dashboardData } = useQuery({
    queryKey: ["user", "dashboard"],
    queryFn: API.users.dashboard,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (dashboardData) {
      const hasNoCampaigns = !dashboardData.campaigns || dashboardData.campaigns.length === 0;
      const hasNoPartnerships = !dashboardData.partnerships || dashboardData.partnerships.length === 0;
      if (hasNoCampaigns && hasNoPartnerships) {
        setShowPreferencesModal(true);
      }
    }
  }, [dashboardData]);

  const referralsCount = dashboardData?.referralsCount || 0;
  const referralProgress = (referralsCount / 5) * 100;
  const totalPayout = (dashboardData?.payouts || []).reduce((sum, p) => sum + (p.amount || 0), 0);

  const handleChipClick = (value, selectedArray, setSelected) => {
    if (selectedArray.includes(value)) {
      setSelected(selectedArray.filter((item) => item !== value));
    } else {
      setSelected([...selectedArray, value]);
    }
  };

  const handleSavePreferences = () => {
    console.log("Platforms:", selectedPlatforms);
    console.log("Niches:", selectedNiches);
    console.log("Prices:", selectedPrices);
    setShowPreferencesModal(false);
  };

  return (
    <Box sx={{ p: 2, position: "relative", minHeight: "100vh", width:'100%' }}>
      {/* The Globe in the old (top-right, large, semi-transparent) style */}
      <FloatingGlobeContainer>
        <FloatingGlobe>
          <Globe size={450} />
        </FloatingGlobe>
      </FloatingGlobeContainer>

      <Grid container spacing={4} sx={{ pt: 8 }}>
        {/* Header Section */}
        <Grid item xs={12} md={8}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 800,
              background: "linear-gradient(45deg, #4F46E5 30%, #EC4899 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              mb: 4,
            }}
          >
            Welcome back, {userInfo?.first_name}!
          </Typography>
        </Grid>

        {/* Blitz Bolts Section */}
        <Grid item xs={12}>
          <BlitzBoltsShowcase userId={userInfo?.id} limit={4} />
        </Grid>

        {/* Main Content Area */}
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {/* Left Column - User Feed */}
            <Grid item xs={12} md={8}>
              {<UnifiedUserFeed />}
            </Grid>

            {/* Right Column - Referral Card */}
            <Grid item xs={12} md={4}>
              <ReferralCard sx={{ p: 3, mb: 4 }}>
                <Box sx={{ position: "relative", zIndex: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
                    <Typography variant="h4" sx={{ color: theme.palette.success.main }}>
                      🎁
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.success.dark }}>
                      Earn Up To $1000
                    </Typography>
                  </Box>
                  <Typography sx={{ mb: 2 }} color="text.secondary">
                    Refer 5 friends and unlock your $1000 reward! You&apos;ve referred{" "}
                    <strong>{referralsCount}/5</strong> users.
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={referralProgress}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      mb: 3,
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                      "& .MuiLinearProgress-bar": {
                        borderRadius: 4,
                        backgroundColor: theme.palette.success.main,
                      },
                    }}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={() => navigate(`/demoregister/${userInfo.id}`)}
                    sx={{
                      borderRadius: 3,
                      fontWeight: 700,
                      boxShadow: `0 4px 12px ${alpha(theme.palette.success.main, 0.3)}`,
                      "&:hover": {
                        transform: "translateY(-2px)",
                        transition: "transform 0.2s",
                      },
                    }}
                  >
                    Get Referral Link →
                  </Button>
                </Box>
              </ReferralCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      
    </Box>
  );
};

export default Dashboard;
