import React, { useState } from 'react';
import { Box, Typography, Toolbar, Button, TextField } from '@mui/material';


import { useNavigate } from 'react-router-dom';
import routes from '../Config/routes';

import AlertDialog from '../Components/AlertDialog';
import useAlertDialog from '../Components/useAlertDialog';
import BlitzHeader from '../Components/BlitzHeader';
import API from '../API';
import { useMutation } from 'react-query';
import useAuth from '../Hooks/use-auth';

const InternalLoginUser = () => {
    const navigate = useNavigate();
    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const [sub, setSub] = useState('');
    const [pass, setPass] = useState('');
      const { authorize, setCurrentUser } = useAuth();

    const { mutate: login, isLoading } = useMutation(API.users.internalLogin, {
        onSuccess: (data) => {
            authorize(data.token);
            setCurrentUser(data.user);
            navigate(routes.dashboard);
        },
        onError: (error) => {
            console.error("An error occurred during login: ", error);
            let errorMessage = "An error occurred, please try again.";
            if (error.code === "ERR_NETWORK") {
                errorMessage = "Network busy. Try again in a few seconds.";
            } else {
                if (error.response && error.response.data) {
                    errorMessage = error?.response?.data?.error || errorMessage;
                }
            }
            openDialog('Error', errorMessage)
        },
    });

    const handleLogin = () => {
        login({
            id: sub, cron: pass
        });
    };

    return (
        <>
            <BlitzHeader>
            </BlitzHeader>
            <Toolbar></Toolbar>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5rem' }}>
                <AlertDialog alertState={dialogState}></AlertDialog>
                <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center' }}>Log in to Blitz to see your campaigns</Typography>
                <Box style={{ width: '80%', maxWidth: '300px' }}>
                    <TextField
                        name='sub'
                        value={sub}
                        onChange={(e) => { setSub(e.target.value) }}
                    ></TextField>
                    <TextField
                        name='cron'
                        value={pass}
                        onChange={(e) => { setPass(e.target.value) }}
                    ></TextField>
                    <Button onClick={handleLogin}>Log</Button>
                </Box>
            </Box>
        </>
    );
};

export default InternalLoginUser;
