import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";
import { StyledDivider as Divider } from "../../../Components/LightDivider";

const TermsOfServiceBusinessEntities = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        {/* Heading */}
        <Box sx={{ mb: 4 }}>
          {/* Updated Title */}
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3 }}>
            Terms of Service for Agencies, Brands, Media Companies, and Enterprises
          </Typography>

          <Typography variant="body1" paragraph>
            Welcome to Blitz! These Terms of Service (“Terms”) govern your (“you,”
            “Agency,” or “your”) access to and use of the services, websites, and tools
            provided by Blitz Systems Corp (“Blitz,” “we,” “us,” or “our”), including
            but not limited to Blitzpay and any related features or services (collectively,
            the “Services”). By accessing or using our Services, you agree to be bound
            by these Terms and all applicable laws.
          </Typography>

          <Typography variant="body1" paragraph>
            {/* Explain that “Agency” includes all business types */}
            These Terms apply to marketing agencies, brands, media companies, enterprises,
            and other similar business entities (collectively referred to in these Terms as
            “Agencies”). If you do not agree with these Terms, you must not use our Services.
          </Typography>
        </Box>

        <Divider />

        {/* 2. Acceptance of Terms */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            2. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>2.1 Binding Agreement</strong> <br />
            You acknowledge that you have read, understood, and agree to be bound by
            these Terms. These Terms are legally binding and govern your use of Blitz’s
            Services.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>2.2 Modifications</strong> <br />
            Blitz reserves the right to update or modify these Terms at any time. Any
            changes will be posted on our website or communicated through other channels.
            Continued use of the Services after such updates constitutes your acceptance
            of the modified Terms.
          </Typography>
        </Box>

        <Divider />

        {/* 3. Eligibility and Agency Accounts */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            3. Eligibility and Agency Accounts
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>3.1 Eligibility</strong> <br />
            You must be at least 18 years of age and have the authority to enter into
            legally binding agreements to access and use our Services on behalf of your
            organization.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>3.2 Account Registration</strong> <br />
            To utilize certain features, including Blitzpay, you may be required to create
            an account (“Account”). You agree to:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 3 }}>
            <li>Provide accurate and complete information.</li>
            <li>Keep your login credentials confidential.</li>
            <li>Notify us immediately of any unauthorized access to your Account.</li>
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>3.3 Account Responsibility</strong> <br />
            You are responsible for all activity that occurs under your Account. Blitz is
            not liable for losses or damages caused by unauthorized access to your Account.
          </Typography>
        </Box>

        <Divider />

        {/* 4. Overview of Services */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            4. Overview of Services
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>4.1 Blitz Platform</strong> <br />
            Blitz offers Agencies a set of tools to manage campaigns, collaborate with
            creators, and facilitate faster payouts through Blitzpay. Our Services help
            streamline campaign execution and handle financial operations efficiently.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>4.2 Free Access</strong> <br />
            Blitz is completely free for Agencies to use, with no subscription fees for
            accessing core platform features. The only fees that apply relate to processed
            payouts through Blitzpay.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>4.3 Blitzpay Service</strong>
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 3 }}>
            <li>
              <strong>Early Payments:</strong> Blitzpay allows Agencies to receive or
              provide accelerated payments, typically within 24 hours, for an additional
              factoring fee.
            </li>
            <li>
              <strong>Credit Line Approval:</strong> Access to Blitzpay and accelerated
              payments may require approval of a credit line, based on a review of your
              financial information (see Section 5 below).
            </li>
            <li>
              <strong>Blitzpay Fee:</strong> The factoring fee for Blitzpay will generally
              range between 5% and 10% of the amount advanced, depending on your Financial
              Health Score and other risk factors.
            </li>
          </Typography>
        </Box>

        <Divider />

        {/* 5. Credit Line Application for Blitzpay */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            5. Credit Line Application for Blitzpay
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>5.1 Application Process</strong> <br />
            Agencies who wish to utilize Blitzpay must submit a credit line application.
            This may include providing financial statements, banking information, and any
            other data required for credit assessment.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>5.2 Credit Line Approval</strong> <br />
            Blitz will review the submitted documents and determine the appropriate credit
            limit at our sole discretion. Approval for a specific credit line does not
            guarantee future extensions or modifications.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>5.3 Factoring Fee and Terms</strong> <br />
            <em>Fee Range:</em> The factoring fee varies between 5% and 10% of the Blitzpay
            amount, based on risk and your Financial Health Score (see Section 6). <br />
            <em>Agreement:</em> The specific fee and payment deadline will be stated in a
            separate Blitzpay agreement for each transaction.
          </Typography>
        </Box>

        <Divider />

        {/* 6. Financial Health Score */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            6. Financial Health Score
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>6.1 Definition</strong> <br />
            Blitz assigns each Agency a Financial Health Score that reflects your payment
            history and overall financial behavior on the platform. This score helps
            determine your factoring fee and credit line for Blitzpay.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>6.2 Maintenance of Score</strong>
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 3 }}>
            <li>
              <strong>On-Time Payments:</strong> Timely payments maintain a higher Financial
              Health Score, resulting in lower factoring fees (5%–7%) and favorable credit
              terms.
            </li>
            <li>
              <strong>Delayed Payments:</strong> Late or missed payments negatively impact
              your score, increasing future factoring fees (up to 10%) and potentially
              reducing your available credit line.
            </li>
            <li>
              <strong>Severe Delays:</strong> Multiple or severe payment delays can lead to
              suspension or termination of Blitzpay services or your entire account.
            </li>
          </Typography>
        </Box>

        <Divider />

        {/* 7. Blitzpay Payment Terms */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            7. Blitzpay Payment Terms
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>7.1 Payment Obligations</strong> <br />
            When you use Blitzpay, you must repay the full invoiced amount, including the
            agreed factoring fee, by the specified due date.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>7.2 Late Payment Fee</strong> <br />
            If payment is not received by the due date, Blitz reserves the right to charge
            an additional 0.5% per day on the outstanding balance for each day the payment
            is overdue.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>7.3 Payment Methods</strong> <br />
            <em>Bank Transfer:</em> You may remit payment via direct bank transfer to Blitz. <br />
            <em>Stripe:</em> Payments through Stripe may incur additional platform processing
            fees. <br />
            <em>PayPal:</em> Use of PayPal for certain transactions may incur currency
            conversion fees, processing fees, or other charges as required by PayPal. By
            using PayPal, you agree to be bound by PayPal’s User Agreement and any
            applicable PayPal terms and conditions.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>7.4 Collection Agencies and Additional Recourse</strong> <br />
            If you fail to meet your payment obligations, including any obligations
            regarding Blitzpay or factored amounts, Blitz reserves the right to engage
            third-party collection agencies or pursue other legal methods to recoup
            outstanding funds. These actions may include reporting to credit bureaus,
            initiating legal proceedings, or any other remedies permitted by law.
          </Typography>
        </Box>

        <Divider />

        {/* 8. Financial Processing Fees and Currency Conversions */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            8. Financial Processing Fees and Currency Conversions
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>8.1 Third-Party Fees</strong> <br />
            When using external payment processors (e.g., Stripe, PayPal), you may be
            subject to additional fees for currency conversions, transactions, or
            processing costs, which may vary by region and currency.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>8.2 Responsibility for Fees</strong> <br />
            You agree that you are solely responsible for any fees or surcharges imposed
            by third-party payment processors. Blitz does not control or determine such
            fees.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>8.3 PayPal Terms</strong> <br />
            If you use PayPal for cross-border transactions, currency conversions, or
            other financial services, you agree to PayPal’s applicable terms and acknowledge
            that any associated fees are your responsibility.
          </Typography>
        </Box>

        <Divider />

        {/* 9. Data Ownership and Use */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            9. Data Ownership and Use
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>9.1 Blitz Systems Corp Rights</strong> <br />
            You acknowledge and agree that all data collected, stored, or processed through
            your use of the Services is deemed the property of Blitz Systems Corp. This
            includes, but is not limited to, data related to campaigns, transactions,
            agency activities, user interactions, performance metrics, and other analytics.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>9.2 License to Agency</strong> <br />
            Blitz grants you a limited, non-exclusive, non-transferable license to access
            and use any data and analytics provided to you through our Services for your
            internal business operations only.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>9.3 Privacy and Confidentiality</strong> <br />
            We will handle your confidential information in accordance with our Privacy
            Policy. You are responsible for complying with all applicable data protection
            laws and regulations when handling personal information obtained through our
            Services.
          </Typography>
        </Box>

        <Divider />

        {/* 10. Intellectual Property Rights */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            10. Intellectual Property Rights
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>10.1 Blitz IP</strong> <br />
            Blitz and its licensors retain all right, title, and interest in and to the
            Services, including but not limited to patents, trademarks, service marks,
            logos, designs, copyrights, trade secrets, and any other intellectual property
            rights associated with the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>10.2 Restrictions</strong> <br />
            You shall not, nor permit others to:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 3 }}>
            <li>
              Reverse engineer, decompile, or otherwise attempt to derive the source code
              of the Services.
            </li>
            <li>
              Reproduce, modify, create derivative works from, or distribute any part of
              the Services without our prior written consent.
            </li>
            <li>
              Use the Services in any way that infringes upon the intellectual property
              rights of Blitz or any third party.
            </li>
          </Typography>
        </Box>

        <Divider />

        {/* 11. Agency Responsibilities */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            11. Agency Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>11.1 Accurate Information</strong> <br />
            You must provide current, accurate, and complete details about campaigns,
            financials, and payments.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>11.2 Compliance with Laws</strong> <br />
            You are responsible for ensuring compliance with all applicable federal,
            state, local, and international laws, regulations, and industry standards
            related to your use of the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>11.3 Prohibited Conduct</strong> <br />
            You agree not to engage in any activity that could disrupt or damage the
            Services, such as introducing malware, hacking, or other unauthorized uses.
          </Typography>
        </Box>

        <Divider />

        {/* 12. Confidentiality */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            12. Confidentiality
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>12.1 Obligations</strong> <br />
            Both parties agree to maintain the confidentiality of any proprietary or
            confidential information of the other party. You will not disclose Blitz’s
            proprietary information or any information designated as confidential without
            prior written consent.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>12.2 Exceptions</strong> <br />
            Confidential information does not include information that is or becomes
            publicly available without breach of these obligations, or was independently
            developed without using or referencing confidential information.
          </Typography>
        </Box>

        <Divider />

        {/* 13. Warranties and Disclaimers */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            13. Warranties and Disclaimers
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>13.1 No Warranty</strong> <br />
            Blitz provides the Services on an “as is” and “as available” basis. To the
            maximum extent permitted by law, Blitz disclaims all warranties, express or
            implied, regarding the Services, including but not limited to warranties of
            merchantability, fitness for a particular purpose, and non-infringement.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>13.2 No Guarantee</strong> <br />
            We do not guarantee that the Services will be uninterrupted, secure, or
            error-free, or that any specific results can be achieved.
          </Typography>
        </Box>

        <Divider />

        {/* 14. Limitation of Liability */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            14. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>14.1 Exclusion of Damages</strong> <br />
            To the fullest extent permitted by law, Blitz shall not be liable for any
            indirect, incidental, consequential, special, or punitive damages, including
            but not limited to loss of profits, loss of data, or business interruption.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>14.2 Aggregate Liability</strong> <br />
            In no event shall Blitz’s total liability for all claims arising under or
            relating to these Terms exceed the total fees paid by you to Blitz in the
            twelve (12) months preceding the event giving rise to liability, or one hundred
            dollars (USD $100), whichever is greater.
          </Typography>
        </Box>

        <Divider />

        {/* 15. Indemnification */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            15. Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>15.1 Agency Obligations</strong> <br />
            You agree to indemnify, defend, and hold harmless Blitz, its affiliates,
            directors, officers, employees, and agents from and against any third-party
            claims, losses, damages, liabilities, and expenses (including reasonable
            attorneys’ fees) arising out of or related to:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 3 }}>
            <li>Your breach of these Terms.</li>
            <li>Your violation of any law or regulation.</li>
            <li>Your infringement or violation of any third-party intellectual property
              or other rights.</li>
          </Typography>
        </Box>

        <Divider />

        {/* 16. Termination and Suspension */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            16. Termination and Suspension
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>16.1 Right to Suspend or Terminate</strong> <br />
            Blitz reserves the right to suspend or terminate your access to the Services
            at any time, without notice, for any reason, including but not limited to:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 3 }}>
            <li>Breach of these Terms.</li>
            <li>Late payments or repeated delays in payment.</li>
            <li>Misuse or abuse of the platform or Services.</li>
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>16.2 Effect of Termination</strong> <br />
            Upon termination, all rights granted to you under these Terms immediately
            cease. You remain responsible for any outstanding obligations, including any
            fees, charges, or late fees accrued prior to termination.
          </Typography>
        </Box>

        <Divider />

        {/* 17. Governing Law and Dispute Resolution */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            17. Governing Law and Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>17.1 Governing Law</strong> <br />
            These Terms and any disputes arising from or related to these Terms shall be
            governed by the laws of the State of Texas, United States, without regard to
            its conflict of law principles.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>17.2 Venue</strong> <br />
            Any disputes shall be exclusively resolved in the state or federal courts
            located in Houston, Texas. You waive any objection to jurisdiction or venue in
            such courts.
          </Typography>
        </Box>

        <Divider />

        {/* 18. General Provisions */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            18. General Provisions
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>18.1 Entire Agreement</strong> <br />
            These Terms, along with our Privacy Policy and any additional agreements you
            execute with Blitz (e.g., Blitzpay agreements), constitute the entire agreement
            between the parties regarding the subject matter herein and supersede all prior
            agreements or understandings.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>18.2 Severability</strong> <br />
            If any provision of these Terms is deemed invalid or unenforceable, that
            provision shall be severed, and the remaining provisions shall remain in full
            force and effect.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>18.3 Waiver</strong> <br />
            No waiver of any term or condition of these Terms shall be deemed a further
            or continuing waiver of the same term or any other term or condition.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>18.4 Assignment</strong> <br />
            You may not assign, transfer, or delegate any rights or obligations under
            these Terms without our prior written consent. We may freely assign or delegate
            all rights and obligations under these Terms in whole or in part without
            notice.
          </Typography>
        </Box>

        <Divider />

        {/* 19. Contact Us */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            19. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about these Terms, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Blitz Systems Corp</strong> <br />
            win@useblitz.co<br />
            1239 Cedar Post Line
          </Typography>
        </Box>

        <Divider />

        {/* Acceptance of Terms */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By using Blitz’s Services, you acknowledge and agree that you have read,
            understood, and will comply with these Terms of Service in their entirety.
            If you do not agree to these Terms, you must cease use of our Services
            immediately.
          </Typography>
          <Typography variant="body1" paragraph>
            <em>Last Updated: February 17th, 2025</em>
          </Typography>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default TermsOfServiceBusinessEntities;
