import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  useTheme,
  IconButton,
  Collapse,
  Typography,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../../../API";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const offerTypeOptions = [
  {
    label: "Offer",
    value: "offer",
    tooltip: "A fixed, firm offer with clear deliverable terms. Includes a to do list with payments per milestone."
  },
  {
    label: "Bonus",
    value: "bonus",
    tooltip: "An additional extra incentive offered separately. Does not include milestones."
  }
];

const SendOfferDialog = ({ open, onClose, partnershipId, creatorId, conversationId, onOfferSent }) => {
  const theme = useTheme();
  const [deliverableType, setDeliverableType] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  // For bonus type, use due date input directly.
  const [dueDate, setDueDate] = useState("");
  // For offer type, milestones are used.
  const [milestones, setMilestones] = useState([]);
  const [milestoneDesc, setMilestoneDesc] = useState("");
  const [milestoneDueDate, setMilestoneDueDate] = useState("");
  const [milestoneLink, setMilestoneLink] = useState("");
  const [milestoneAmount, setMilestoneAmount] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [revision, setRevision] = useState("");
  const [script, setScript] = useState("");
  // Platforms is now a multi-select; we store an array.
  const [platforms, setPlatforms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const createDeliverableMutation = useMutation(client.deliverables.create, {
    onSuccess: () => {
      setIsLoading(false);
      onClose();
      onOfferSent();
    },
    onError: (error) => {
      setIsLoading(false);
      console.error("Error creating deliverable:", error);
    },
  });

  const handleAddMilestone = () => {
    // For Offer type, require description, due date, and milestone amount.
    if (deliverableType === "offer" && (!milestoneDesc || !milestoneDueDate || !milestoneAmount)) return;
    const newMilestone = {
      description: milestoneDesc,
      due_date: milestoneDueDate,
      link: milestoneLink || null,
      amount: parseFloat(milestoneAmount),
    };
    setMilestones([...milestones, newMilestone]);
    setMilestoneDesc("");
    setMilestoneDueDate("");
    setMilestoneLink("");
    setMilestoneAmount("");
  };

  const handleSubmit = () => {
    setIsLoading(true);
    // For Offer, if milestones exist, compute the latest milestone due date.
    // For Bonus, use the manually entered dueDate.
    let computedDueDate = dueDate;
    if (deliverableType === "offer" && milestones.length > 0) {
      computedDueDate = milestones.reduce((latest, milestone) => {
        return new Date(milestone.due_date) > new Date(latest)
          ? milestone.due_date
          : latest;
      }, milestones[0].due_date);
    }
    createDeliverableMutation.mutate({
      partnership_id: partnershipId,
      creator_id: creatorId,
      conversation_id: conversationId,
      deliverable_type: deliverableType,
      amount: parseFloat(amount),
      status: "sent",
      notes,
      due_date: computedDueDate,
      // Only include milestones for Offer type.
      milestones: deliverableType === "offer" ? milestones : [],
      revision: revision || null,
      script: script || null,
      platforms: platforms.length > 0 ? platforms : null,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: 'background.paper',
          color: 'text.primary',
          borderBottom: `1px solid ${theme.palette.divider}`,
          px: { xs: 2, md: 3 },
          py: { xs: 1.5, md: 2 },
        }}
      >
        Send Offer
      </DialogTitle>

      <DialogContent
        sx={{
          bgcolor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          px: { xs: 2, md: 3 },
          py: { xs: 2, md: 3 },
          maxHeight: { xs: 400, md: 600 },
          overflowY: 'auto',
        }}
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel>Deliverable Type</InputLabel>
          <Select
            value={deliverableType}
            onChange={(e) => {
              setDeliverableType(e.target.value);
              // Clear milestones if switching from offer to bonus.
              if (e.target.value !== "offer") setMilestones([]);
            }}
            label="Deliverable Type"
          >
            {offerTypeOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <Tooltip title={item.tooltip} placement="right" arrow>
                  <span>{item.label}</span>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          variant="outlined"
          label="Amount (USD)"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: { min: 0, step: "0.01" }
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Brief (Notes)"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={3}
        />

        {/* For Bonus type, require a due date */}
        {deliverableType === "bonus" && (
          <TextField
            fullWidth
            variant="outlined"
            label="Due Date"
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        )}

        {/* Platforms multi-select */}
        <FormControl fullWidth variant="outlined">
          <InputLabel>Platforms</InputLabel>
          <Select
            multiple
            value={platforms}
            onChange={(e) => setPlatforms(e.target.value)}
            label="Platforms"
          >
            <MenuItem value="TikTok">TikTok</MenuItem>
            <MenuItem value="Instagram">Instagram</MenuItem>
            <MenuItem value="Youtube">Youtube</MenuItem>
          </Select>
        </FormControl>

        {/* Only render milestones section if type is Offer */}
        {deliverableType === "offer" && (
          <Box
            sx={{
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              To Do List
            </Typography>
            {milestones.map((m, idx) => (
              <Box
                key={idx}
                sx={{
                  mt: 1,
                  p: 1,
                  border: "1px solid",
                  borderColor: "grey.300",
                  borderRadius: 1,
                }}
              >
                <Typography variant="body2">
                  <strong>Description:</strong> {m.description}
                </Typography>
                <Typography variant="body2">
                  <strong>Due Date:</strong> {new Date(m.due_date).toLocaleDateString()}
                </Typography>
                {m.link && (
                  <Typography variant="body2">
                    <strong>Link:</strong> {m.link}
                  </Typography>
                )}
                {m.amount != null && (
                  <Typography variant="body2">
                    <strong>Milestone Amount:</strong> ${m.amount.toFixed(2)}
                  </Typography>
                )}
              </Box>
            ))}

            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                alignItems: "center",
              }}
            >
              <TextField
                variant="outlined"
                label="Milestone Description"
                value={milestoneDesc}
                onChange={(e) => setMilestoneDesc(e.target.value)}
                sx={{ flex: { xs: "100%", md: "45%" } }}
              />
              <TextField
                variant="outlined"
                label="Due Date"
                type="date"
                value={milestoneDueDate}
                onChange={(e) => setMilestoneDueDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ flex: { xs: "100%", md: "45%" } }}
              />
              <TextField
                variant="outlined"
                label="Optional Link"
                value={milestoneLink}
                onChange={(e) => setMilestoneLink(e.target.value)}
                sx={{ flex: { xs: "100%", md: "45%" } }}
              />
              <TextField
                variant="outlined"
                label="Milestone Amount (USD)"
                value={milestoneAmount}
                onChange={(e) => setMilestoneAmount(e.target.value)}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: { min: 0, step: "0.01" }
                }}
                sx={{ flex: { xs: "100%", md: "45%" } }}
              />
              <Button variant="contained" onClick={handleAddMilestone} sx={{ mt: { xs: 1, md: 0 } }}>
                Add Milestone
              </Button>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            userSelect: "none",
          }}
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          <Typography variant="subtitle2" sx={{ mr: 0.5 }}>
            Advanced Options
          </Typography>
          <IconButton
            size="small"
            sx={{
              transition: "transform 0.3s",
              transform: showAdvanced ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Revision"
              value={revision}
              onChange={(e) => setRevision(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Script"
              value={script}
              onChange={(e) => setScript(e.target.value)}
            />
          </Box>
        </Collapse>
      </DialogContent>

      <DialogActions
        sx={{
          bgcolor: 'background.paper',
          px: { xs: 2, md: 3 },
          py: { xs: 1.5, md: 2 },
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button onClick={onClose} variant="outlined" sx={{ px: 3 }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            isLoading ||
            !deliverableType ||
            !amount ||
            !notes ||
            // For Offer, require at least one milestone; for Bonus, require a due date.
            (deliverableType === "offer" ? milestones.length === 0 : !dueDate)
          }
          sx={{ px: 3, ml: 2 }}
        >
          {isLoading ? "Sending..." : "Send Offer"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendOfferDialog;
