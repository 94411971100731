import React from 'react'
import { Slide } from '../ReportComponents'
import { Box, styled, Typography } from '@mui/material';
import { Bar, BarChart, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// Styled components
const ChartsList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%'
}));

const ChartCard = styled(Box)(({ theme }) => ({
    background: 'rgba(255,255,255,0.1)',
    color: 'white',
    borderRadius: '10px',
    paddingBlock: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'transform 0.3s ease',
}));

const countryReachData = [
    { code: 'US', value: 0.65 },
    { code: 'UK', value: 0.15 },
    { code: 'DE', value: 0.10 },
    { code: 'FR', value: 0.10 },
]

const ageGroupData = [
    { name: '18-24', percent: 0.25 },
    { name: '25-34', percent: 0.35 },
    { name: '35-44', percent: 0.20 },
    { name: '45+', percent: 0.20 },
]

const genderGroupData = [
    { name: 'female', value: 0.55 },
    { name: 'male', value: 0.35 },

]

const formatLabel = (value) => {
    return value.toFixed(2); // Rounds to two decimal places
  };


export default function ReachSlide({ index, currentSlide, report }) {


    return (
        <Slide
            id='slide-reach'
            index={index}
            currentIndex={currentSlide}
            sx={theme => ({
                backgroundImage: 'linear-gradient(135deg, #450AF5, #8E8E8E)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                padding: theme.spacing(4),
                paddingBlockEnd: theme.spacing(8),
                height: 'fit-content',
                minHeight: '100%'
            })}
        >
            <Typography component={'div'} variant='h5'>Audience Reach (Est.)</Typography>
            <ChartsList>
                <ChartCard>
                    <Typography component={'div'} variant='h6'>Top Countries</Typography>
                    <ResponsiveContainer width="100%" aspect={2.77}>
                        <BarChart fill='#FFF' data={report?.report.all_average_country.slice(0,4) || []} layout="vertical" margin={{ left: 0, top: 0, bottom: 5, right: 40 }}>
                            <XAxis type="number" stroke='#FFF' />
                            <YAxis dataKey="code" type="category" stroke='#FFF' />
                            <Bar
                                layout="vertical"
                                dataKey="value"
                                label={{ position: 'right', fill: '#FFF', formatter: formatLabel }}
                                fill='#1DB954'
                                radius={[20, 20, 20, 20]}
                                background={{ fill: "rgba(255, 255, 255, 0.2)" }}
                            >
                            </Bar>
                            <Tooltip labelStyle={{color:'#000', fontWeight:700}} formatter={formatLabel} itemStyle={{color:'#777'}}/>
                        </BarChart>

                    </ResponsiveContainer>
                </ChartCard>
                <ChartCard>
                    <Typography component={'div'} variant='h6'>Age Group Reach</Typography>
                    <ResponsiveContainer width="100%" aspect={2.77}>
                        <BarChart fill='#FFF' data={report?.report.all_average_age.slice(0,4) || []} layout="vertical" margin={{ left: 0, top: 0, bottom: 5, right: 40 }}>
                            <XAxis type="number" stroke='#FFF' />
                            <YAxis dataKey="age_range" type="category" stroke='#FFF' />
                            <Bar
                                layout="vertical"
                                dataKey="value"
                                label={{ position: 'right', fill: '#FFF', formatter: formatLabel }}
                                fill='#1DB954'
                                radius={[20, 20, 20, 20]}
                                background={{ fill: "rgba(255, 255, 255, 0.2)" }}
                            >
                            </Bar>
                            <Tooltip labelStyle={{color:'#000', fontWeight:700}}  formatter={formatLabel} itemStyle={{color:'#777'}}/>
                        </BarChart>

                    </ResponsiveContainer>
                </ChartCard>
                <ChartCard>
                    <Typography component={'div'} variant='h6'>Gender Group Reach</Typography>
                    <ResponsiveContainer width="100%" aspect={2.66}>
                        <PieChart>
                            <Pie
                                data={report?.report.all_average_gender  || []}
                                cx="50%"
                                cy="50%"
                                dataKey="value"
                                nameKey="gender"
                                outerRadius="70%"
                                label
                            >
                                {[0, 1].map((entry, index) => (
                                    <Cell key={index} fill={["#DDD", "#AAA"][index]} />
                                ))}
                            </Pie>
                            <Tooltip labelStyle={{color:'#000', fontWeight:700}} />
                        </PieChart>
                    </ResponsiveContainer>
                </ChartCard>
            </ChartsList>
            <Typography component={'div'} variant='subtitle2' color='text.secondary' >{report?.name}</Typography>
        </Slide>
    )
}
