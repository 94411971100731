import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Paper,
} from "@mui/material";
import OfferIcon from "@mui/icons-material/LocalOffer";
import PayoutIcon from "@mui/icons-material/MonetizationOn";
import MenuIcon from "@mui/icons-material/Menu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "../../../../Hooks/use-auth";
import client from "../../../../API";
import SendOfferDialog from "./smallercomps/sendoffer";
import PayoutDialog from "./smallercomps/payout";
import OfferDialog from "./smallercomps/offerdialog";
import PartnershipsDetailView from "./detailview";
import {
  PersonAddAlt1Outlined as PersonAddAlt1OutlinedIcon,
} from "@mui/icons-material";
import AlertDialog from "../../../../Components/AlertDialog";
import useAlertDialog from "../../../../Components/useAlertDialog";
import ChatScreen from "../../../../Components/ChatScreen";
import { globalStyles } from "../../../../Utils/Styles";

const PartnershipsPage = () => {
  const { partnershipId } = useParams();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [sendOfferOpen, setSendOfferOpen] = useState(false);
  const [sendPayoutOpen, setSendPayoutOpen] = useState(false);
  const [viewOfferOpen, setViewOfferOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareEmails, setShareEmails] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [offerSent, setOfferSent] = useState(false);
  // NEW STATE FOR DETECTING PENDING STATUS
  const [pendingDialogOpen, setPendingDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // Fetch partnership
  const {
    data: partnershipFetch,
    isLoading: loading,
    error: partnershipError,
    refetch: partnershipRefetch,
  } = useQuery({
    queryKey: ["partnerships", partnershipId],
    queryFn: () => client.partnerships.fetchPartnership(partnershipId),
    refetchOnWindowFocus: false,
  });

  // Handle fetch errors
  useEffect(() => {
    if (partnershipError) {
      console.error("Error fetching partnership details:", partnershipError);
      if (partnershipError.response && partnershipError.response.status === 403) {
        alert("You may not view this partnership!");
      } else {
        alert(
          `Error while fetching partnership: ${partnershipError.response?.data?.error || partnershipError.message
          }`
        );
      }
    }
  }, [partnershipError]);

  // Partnership data
  const partnershipData = !!partnershipFetch
    ? partnershipFetch.partnership
    : { shared_with: [] };
  const partnership = !!partnershipFetch ? partnershipData.name : "N/A";
  const creator = !!partnershipFetch
    ? {
      name: partnershipData.creator,
      pfphref: partnershipData.pfphref || "/placeholder.png",
    }
    : { name: "Unknown", pfphref: "/placeholder.png" };

  // CHECK FOR "pending" STATUS AFTER PARTNERSHIP LOADS
  useEffect(() => {
    if (!loading && partnershipData && partnershipData.status) {
      // If partnershipData.status is "pending" (case-insensitive), open the dialog
      if (partnershipData.status.toLowerCase() === "pending") {
        setPendingDialogOpen(true);
      }
    }
  }, [loading, partnershipData]);

  // If there's a conversation already, it will be on the partnership object
  const conversationId = !!partnershipFetch ? partnershipData.conversation_id : null;

  // Create conversation if none
  const createConversation = useMutation(client.conversations.createPartnership, {
    onSuccess: () => {
      partnershipRefetch();
    },
    onError: (error) => {
      console.error("Error creating conversation:", error);
    },
  });

  // Create conversation if it doesn't exist
  useEffect(() => {
    if (!!partnershipFetch) {
      if (!conversationId)
        createConversation.mutate({
          partnership_id: partnershipId,
        });
    }
  }, [partnershipFetch]);

  // Update partnership to "Completed" or another status
  const { mutate: updatePartnershipStatus } = useMutation(
    (status) => client.partnerships.updateStatus(partnershipId, { status }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("partnerships");
        setConfirmDialogOpen(false);
      },
      onError: (error) => {
        console.error("Error updating partnership status:", error);
      },
    }
  );

  const handleCompletePartnershipClick = () => {
    setConfirmDialogOpen(true);
    handleMenuClose();
  };

  const handleConfirmCompletion = () => {
    updatePartnershipStatus("Completed");
  };

  // Menu handling
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Offer & Payout dialogs
  const handleSendOfferClick = () => {
    setSendOfferOpen(true);
  };
  const handleSendPayoutClick = () => {
    setSendPayoutOpen(true);
  };
  const handleViewOfferClick = (offer) => {
    setSelectedOffer(offer);
    setViewOfferOpen(true);
  };
  const handleViewDetailsClick = () => {
    setViewDetailsOpen(true);
    handleMenuClose();
  };

  // Called after an offer is sent
  const handleOfferSent = () => {
    sendMessageMutation.mutate({
      id: conversationId,
      input: { message: "An offer was made, see above." },
    });
    setOfferSent(true);
  };

  // Sharing the partnership with colleagues
  const sharePartnershipMutation = useMutation(
    (sharedWith) => client.partnerships.share(partnershipId, { shared_with: sharedWith }),
    {
      onSuccess: () => {
        setShareDialogOpen(false);
        setShareEmails("");
        alert("Partnership shared successfully");
        // Re-fetch partnership data to update shared_with
        queryClient.invalidateQueries(["partnership", partnershipId]);
        partnershipRefetch();
      },
      onError: (error) => {
        console.error("Error sharing partnership:", error);
        alert(
          `Error sharing partnership: ${error.response?.data?.error || error.message
          }`
        );
      },
    }
  );

  const handleShareClick = () => {
    setShareDialogOpen(true);
  };
  const handleShareSubmit = () => {
    const emails = shareEmails
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email !== "");
    if (emails.length === 0) {
      alert("Please enter at least one email address");
      return;
    }
    sharePartnershipMutation.mutate(emails);
  };

  // Loading screen
  if (loading) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading partnership details...
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        ...globalStyles.heightMinusToolbar,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "0px",
        overflowY: 'clip',
      }}
    >
      <AlertDialog alertState={dialogState} />

      {creator.name && (
        <>
          <Paper
            sx={{
              padding: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              maxHeight:'15vh',
              overflowY:'hidden',
              overflowX:'clip',
              width:'100%',
              maxWidth:'100%',
              minWidth:'0px'
            }}
            elevation={4}
            square
          >

            <Box display="flex" alignItems="center">
              <Avatar src={creator.pfphref} alt={creator.name} />
              <Typography variant="h6" sx={{ marginLeft: 2, wordWrap:'break-word' }} >
                Partnership with @{creator.name} - {partnership}
              </Typography>
              <Typography variant="body1" sx={{ marginLeft: 2, wordBreak:'break-all' }}>
                {partnershipData.description}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleShareClick} color="inherit">
                <PersonAddAlt1OutlinedIcon />
              </IconButton>
              <IconButton onClick={handleMenuOpen} color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleViewDetailsClick}>
                View Partnership Details
              </MenuItem>
              {partnershipData.status === "Active" && (
                <MenuItem onClick={handleCompletePartnershipClick}>
                  Complete Partnership
                </MenuItem>
              )}
            </Menu>
          </Paper>

          <ChatScreen conversationId={conversationId} onDeliverableClick={handleViewOfferClick} sx={{ flex: 1, minHeight: '0px', }}>

             {/* Offer dialogs
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Button
                variant="contained"
                startIcon={<OfferIcon />}
                onClick={handleSendOfferClick}
                sx={{ mr: 2 }}
              >
                Send Offer
              </Button>
              <Button
                variant="contained"
                startIcon={<PayoutIcon />}
                onClick={handleSendPayoutClick}
              >
                Send Payout
              </Button>
            </Box>
             */}
          </ChatScreen>


          {/* Offer dialogs */}
          <SendOfferDialog
            open={sendOfferOpen}
            onClose={() => setSendOfferOpen(false)}
            partnershipId={partnershipId}
            creatorId={creator.name}
            conversationId={conversationId}
            onOfferSent={handleOfferSent}
          />
          <PayoutDialog
            open={sendPayoutOpen}
            onClose={() => setSendPayoutOpen(false)}
            partnershipId={partnershipId}
            creatorId={creator.name}
            conversationId={conversationId}
          />
          {selectedOffer && (
            <OfferDialog
              open={viewOfferOpen}
              onClose={() => setViewOfferOpen(false)}
              offer={selectedOffer}
            />
          )}
          <PartnershipsDetailView
            open={viewDetailsOpen}
            onClose={() => setViewDetailsOpen(false)}
            partnershipId={partnershipId}
            partnershipData={partnershipData}
            creator={{ name: creator.name }}
          />

          {/* Share Partnership Dialog */}
          <Dialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
            <DialogTitle>Share Partnership</DialogTitle>
            <DialogContent>
              {partnershipData.shared_with && partnershipData.shared_with.length > 0 ? (
                <>
                  <Typography variant="subtitle1">Already shared with:</Typography>
                  <ul>
                    {partnershipData.shared_with.map((email) => (
                      <li key={email}>{email}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <Typography variant="subtitle1">
                  Nobody has been shared this yet! Invite your first colleague today.
                </Typography>
              )}
              <DialogContentText sx={{ mt: 2 }}>
                Enter the email addresses of the users you want to share this partnership
                with. Separate multiple emails with commas.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="emails"
                label="Email Addresses"
                type="email"
                fullWidth
                variant="standard"
                value={shareEmails}
                onChange={(e) => setShareEmails(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShareDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleShareSubmit} disabled={sharePartnershipMutation.isLoading}>
                {sharePartnershipMutation.isLoading ? <CircularProgress size={24} /> : "Share"}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirm completion Dialog */}
          <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
          >
            <DialogTitle>Confirm Completion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to mark this partnership as completed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleConfirmCompletion} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* NEW DIALOG: SHOW IF PARTNERSHIP STATUS IS "pending" */}
          <Dialog
            open={pendingDialogOpen}
            onClose={() => setPendingDialogOpen(false)}
          >
            <DialogTitle>Your Partnership is still pending</DialogTitle>
            <DialogContent>
              <DialogContentText>
                It looks like this partnership is still in a pending status. Wait for your partner
                to accept your formal offer and get started.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPendingDialogOpen(false)} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default PartnershipsPage;