import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  InputAdornment,
  Snackbar,
  Typography,
} from '@mui/material';
import { useCreatorAuth } from '../../../../Hooks/creator-use-auth'; // adjust path as needed
import API from '../../../../API';
import { useNavigate } from 'react-router-dom';

function CreatorInvoiceDialog({ open, onClose, onInvoiceCreated }) {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const navigate = useNavigate();

  // State declarations
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [poNumber, setPoNumber] = useState('');

  // For fee calculations
  const [interestRate, setInterestRate] = useState(0);
  const [blitzFee, setBlitzFee] = useState(0);
  const [netToReceive, setNetToReceive] = useState(0);

  // Snackbar for notifications
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Function to generate a PO number
  const handleGeneratePO = () => {
    setPoNumber(`TCC-${Math.floor(Math.random() * 1000000)}`);
  };

  // When the dialog opens, generate a PO number
  useEffect(() => {
    if (open) {
      handleGeneratePO();
    }
  }, [open]);

  // Recalculate fee & net amount when amount or interest rate changes
  useEffect(() => {
    const amt = parseFloat(amount) || 0;
    const rate = parseFloat(interestRate) || 0;
    const fee = (amt * rate) / 100;
    setBlitzFee(fee);
    setNetToReceive(amt - fee);
  }, [amount, interestRate]);

  // Close the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // Attempt to fetch user info to get interest rate, etc.
  const handleCheckEmail = async () => {
    if (!clientEmail) return;
    try {
      const response = await API.creatorConnect.fetchUserInfo(clientEmail);
      if (response?.found) {
        const userData = response.user;
        setClientName(`${userData.first_name} ${userData.last_name}`);
        setInterestRate(userData.company_interest_rate ?? 0);
        setSnackbarMessage('User found. Company interest rate applied.');
        setSnackbarOpen(true);
      } else {
        setInterestRate(10); // default interest rate
        setSnackbarMessage('User not found. Default interest rate applied.');
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.error('Error fetching user info:', err);
      setSnackbarMessage(`Error fetching user info: ${err.message}`);
      setSnackbarOpen(true);
    }
  };

  // Actually create the invoice on the server
  const handleInvoice = async () => {
    try {
      if (!username) {
        setSnackbarMessage('User not authenticated');
        setSnackbarOpen(true);
        return;
      }
      const parsedAmount = parseFloat(amount) || 0;
      const invoiceRes = await API.creatorConnect.postInvoice({
        user_id: username,
        creator_id: username,
        po_number: poNumber,
        amount: parsedAmount,
        payout_date: new Date().toISOString(),
        status: 'Pending',
        blitzpay: true,
        unique_code: poNumber,
        interest_rate: interestRate,
        client_name: clientName,
        client_email: clientEmail,
        notes,
      });
      setSnackbarMessage('Invoice created successfully!');
      setSnackbarOpen(true);

      // Reset the form fields
      setClientName('');
      setClientEmail('');
      setAmount('');
      setNotes('');
      setInterestRate(0);
      setBlitzFee(0);
      setNetToReceive(0);
      handleGeneratePO();

      // If your backend returns { invoice: <the object> }, then:
      if (invoiceRes?.invoice) {
        onInvoiceCreated(invoiceRes.invoice); 
      } else {
        // fallback: if it only returns { invoice_id: ... } we can either re-fetch or do something else
        onInvoiceCreated({
          id: invoiceRes.invoice_id,
          po_number: poNumber,
          amount: parsedAmount,
          client_name: clientName,
          invoice_status: 'pending'
        });
      }
      onClose();
    } catch (error) {
      console.error('Error sending invoice:', error);
      setSnackbarMessage(`Error sending invoice: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Request Money / Create Invoice</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Client Email (Required)"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              onBlur={handleCheckEmail}
            />
            <TextField
              label="Client Name"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
            <TextField
              type="number"
              label="Amount (USD, Required)"
              value={amount}
              onChange={(e) => {
                const regex = /^[0-9]*\.?[0-9]*$/;
                if (e.target.value === '' || regex.test(e.target.value)) {
                  setAmount(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              multiline
              label="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <Typography variant="body2">
              Purchase Order (PO) Number: {poNumber}
            </Typography>
            <Typography variant="body2">
              Interest Rate: {interestRate}%
            </Typography>
            <Typography variant="body2">
              Blitz Fee: ${blitzFee.toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              You will receive ${netToReceive.toFixed(2)} (within 24 hrs)
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={handleInvoice}>
            Create Invoice
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      />
    </>
  );
}

export default CreatorInvoiceDialog;
