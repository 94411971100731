import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  ThemeProvider,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
  Link,
  Autocomplete,
  styled,
  Modal
} from '@mui/material';

import { useNavigate, useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import HomeHeader from '../../Components/HomeHeader';
import HomeFooter from '../../Components/HomeFooter';
import CreatorOnboard from '../../CreatorUser/creatorOnboard'; 
import { validateEmail, validatePassword, capitalizeFirstLetter } from '../../Utils';
import { lightTheme } from '../../Utils/baseTheme';

import routes from '../../Config/routes';
import client from '../../API';

const FullScreenOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1300,
  backdropFilter: 'blur(5px)',
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #6366f1 30%, #8b5cf6 90%)',
  color: 'white',
  fontWeight: 'bold',
  padding: '12px 24px',
  borderRadius: '12px',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 24px rgba(99,102,241,0.4)',
    background: 'linear-gradient(45deg, #6366f1 30%, #8b5cf6 90%)',
  },
}));

const ModernTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.3s ease',
    '&:hover fieldset': {
      borderColor: '#818cf8',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6366f1',
      boxShadow: '0 0 0 2px rgba(99,102,241,0.2)',
    },
  },
}));

const AllOnboard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const convParam = searchParams.get('conv');
  const emailParam = searchParams.get('email');
  const tabParam = searchParams.get('tab');
  const usernameParam = searchParams.get('username') || '';

  // Local state for which tab is active
  const [tabValue, setTabValue] = useState(
    tabParam === 'creator' ? 'creator' : 'brand'
  );

  // If tabParam is invalid or missing, we want to show a dialog/overlay
  // for user to pick "brand" or "creator"
  const isTabParamValid = tabParam === 'creator' || tabParam === 'brand';
  const [showRoleDialog, setShowRoleDialog] = useState(!isTabParamValid);

  useEffect(() => {
    // If the user has set a valid tab param, update local state
    if (tabParam === 'creator' || tabParam === 'brand') {
      setTabValue(tabParam);
      setShowRoleDialog(false);
    }
  }, [tabParam]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Also update the URL to reflect the chosen tab
    navigate({
      pathname: window.location.pathname,
      search: `?tab=${newValue}${convParam ? `&conv=${convParam}` : ''}${emailParam ? `&email=${emailParam}` : ''}`
    });
  };

  // Handler for the overlay role selection
  const handleChooseRole = (role) => {
    // Once chosen, set the URL param
    navigate({
      pathname: window.location.pathname,
      search: `?tab=${role}${convParam ? `&conv=${convParam}` : ''}${emailParam ? `&email=${emailParam}` : ''}`
    });
  };

  

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <HomeHeader />
        <Toolbar />

        {/* Full Screen Role Selection Overlay */}
        <Modal open={showRoleDialog} onClose={() => setShowRoleDialog(false)}>
          <FullScreenOverlay>
            <Box sx={{
              background: 'white',
              borderRadius: '24px',
              padding: '40px',
              textAlign: 'center',
              maxWidth: '500px',
              boxShadow: '0 24px 48px rgba(0,0,0,0.2)',
            }}>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 800 }}>
                Welcome to Blitz! 🚀
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
                Let's get started by selecting your role
              </Typography>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <GradientButton
                  onClick={() => handleChooseRole('brand')}
                  sx={{ py: 3 }}
                >
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="subtitle1">Brand Account</Typography>
                    <Typography variant="body2" sx={{ opacity: 0.9 }}>
                      Hire creators & manage campaigns
                    </Typography>
                  </Box>
                </GradientButton>

                <Button
                  variant="outlined"
                  onClick={() => handleChooseRole('creator')}
                  sx={{
                    py: 3,
                    borderRadius: '12px',
                    borderColor: '#e2e8f0',
                    '&:hover': { borderColor: '#6366f1' }
                  }}
                >
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="subtitle1">Creator Account</Typography>
                    <Typography variant="body2" sx={{ opacity: 0.9 }}>
                      Get hired & grow your business
                    </Typography>
                  </Box>
                </Button>
              </Box>

              <Typography variant="body2" sx={{ mt: 4, color: 'text.secondary' }}>
                Already have an account?{' '}
                <Link component={RouterLink} to={routes.login} sx={{ fontWeight: 600 }}>
                  Sign in here
                </Link>
              </Typography>
            </Box>
          </FullScreenOverlay>
        </Modal>

        <Box sx={{ flexGrow: 1, py: 8, background: '#f8fafc' }}>
          <Box maxWidth="md" sx={{ mx: 'auto', px: { xs: 2, sm: 0 } }}>
            {/* Tabs and content remain the same but with updated styling */}
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              centered
              sx={{
                '& .MuiTabs-indicator': {
                  height: '4px',
                  borderRadius: '2px',
                }
              }}
            >
              <Tab label="Brand Sign Up" value="brand" />
              <Tab label="Creator Sign Up" value="creator" />
            </Tabs>

            <Box sx={{ 
              mt: 4, 
              p: { xs: 2, sm: 4 },
              background: 'white',
              borderRadius: '24px',
              boxShadow: '0 8px 32px rgba(0,0,0,0.05)'
            }}>
              {tabValue === 'brand' &&  <BrandRegisterForm
              conversationIdParam={convParam}
              inviteEmailParam={emailParam}
            />}
              {tabValue === 'creator' &&  <CreatorOnboard
                  conversationIdParam={convParam}
                  inviteEmailParam={emailParam}
                  prefilledUsername={usernameParam}
                />}
            </Box>
          </Box>
        </Box>

        <HomeFooter sx={{ mt: 'auto', py: 4, background: 'white' }} />
      </Box>
    </ThemeProvider>
  );
};

const BrandRegisterForm = ({ conversationIdParam, inviteEmailParam }) => {
  const navigate = useNavigate();
  const { ref } = useParams();

  const referringUserId = ref && !isNaN(Number(ref)) ? Number(ref) : null;
  const referralSource = referringUserId === null ? ref : '';

  const [conversationId] = useState(conversationIdParam || null);

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: inviteEmailParam || '',
    password: '',
    confirmPassword: '',
    companyName: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    phoneNumber: '',
    industry: '',
    bio: '',
    businessType: '',
    isAgency: '',
    monthlySpend: '',
    teamAccounts: '',
    paymentMethod: '',
    referralSource: referralSource,
    referringUserId: referringUserId,

    // NEW: Terms acceptance checkbox
    termsAccepted: false,
  });

  const [openDialog, setOpenDialog] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Personal Information', 'Company Information', 'Business Details'];

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Query to fetch possible existing companies (for Autocomplete)
  const {
    data: companiesData = [],
    isLoading: isCompaniesLoading,
    isError: isCompaniesError
  } = useQuery(['companiesList'], client.companies.list);

  // Mutation for registering brand
  const { mutate: register, isLoading } = useMutation(client.companies.demoRegister, {
    onSuccess: () => {
      navigate(routes.login);
    },
    onError: (error) => {
      console.error('Registration error:', error);
      let errorMessage = 'An error occurred, please try again.';
      if (error.code === 'ERR_NETWORK') {
        errorMessage = 'Network is disconnected!';
      } else if (error.response && error.response.data) {
        errorMessage = error.response.data.error || errorMessage;
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  });

  const handleNext = () => {
    const requiredFields = getRequiredFieldsForStep(activeStep);
    let valid = true;

    requiredFields.forEach((field) => {
      if (valid && (!userInfo[field] || userInfo[field].trim() === '')) {
        setSnackbarMessage(`${capitalizeFirstLetter(field)} field is required!`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        valid = false;
      }
    });

    if (!valid) return;

    // Step-specific validations
    if (activeStep === 0) {
      if (!validateEmail(userInfo.email)) {
        setSnackbarMessage('Invalid email address!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      if (!validatePassword(userInfo.password)) {
        setSnackbarMessage('Password should have 6 or more characters!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      if (userInfo.password !== userInfo.confirmPassword) {
        setSnackbarMessage('Passwords should match!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
    }

    // If we are on the last step, attempt final registration
    if (activeStep === steps.length - 1) {
      handleRegister();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleRegister = () => {
    // Final check for required fields
    const requiredFields = Object.keys(userInfo).filter(
      (f) => f !== 'referralSource' && f !== 'referringUserId'
    );
    let valid = true;

    requiredFields.forEach((field) => {
      if (valid && (!userInfo[field] || userInfo[field].toString().trim() === '')) {
        // Skip termsAccepted check in this loop, handle below
        if (field === 'termsAccepted') return;
        setSnackbarMessage(`${capitalizeFirstLetter(field)} field is required!`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        valid = false;
      }
    });

    if (!valid) return;

    // Check if Terms & Conditions were accepted
    if (!userInfo.termsAccepted) {
      setSnackbarMessage('Please accept the terms and conditions of Blitz to continue.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const params = { ...userInfo };
    register(params);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const getRequiredFieldsForStep = (step) => {
    switch (step) {
      case 0:
        return ['firstName', 'lastName', 'email', 'password', 'confirmPassword', 'phoneNumber'];
      case 1:
        return ['companyName', 'streetAddress', 'city', 'state', 'country', 'industry', 'bio']; // Added 'bio'
      case 2:
        return ['businessType', 'isAgency', 'monthlySpend', 'teamAccounts', 'paymentMethod'];
      default:
        return [];
    }
  };

  const stepsLabels = ['Personal Info', 'Company Info', 'Business Details'];
  const industryOptions = [
    'Fashion',
    'Tech',
    'Food',
    'Beauty',
    'Sports',
    'Travel',
    'Finance',
    'Health',
    'Education',
    'Entertainment',
    'Automotive',
    'Real Estate',
    'Hospitality',
    'Retail',
    'Manufacturing',
    'Telecommunications',
    'Marketing Agency'
  ];
  const countryOptions = [
    'United States',
    'China',
    'Canada',
    'United Kingdom',
    'Australia',
    'Germany',
    'France',
    'Italy',
    'Spain',
    'Japan',
    'India',
    'Brazil',
    'Mexico',
    'Russia',
    'South Korea',
    'South Africa'
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <TextField
              disabled={isLoading}
              label="First Name"
              name="firstName"
              variant="outlined"
              value={userInfo.firstName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Last Name"
              name="lastName"
              variant="outlined"
              value={userInfo.lastName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Email"
              name="email"
              variant="outlined"
              value={userInfo.email}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Password"
              name="password"
              variant="outlined"
              type="password"
              value={userInfo.password}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Re-enter Password"
              name="confirmPassword"
              variant="outlined"
              type="password"
              value={userInfo.confirmPassword}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Phone Number"
              name="phoneNumber"
              value={userInfo.phoneNumber}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Autocomplete
              freeSolo
              loading={isCompaniesLoading}
              options={companiesData.map((company) => company.name)}
              value={userInfo.companyName}
              onChange={(event, newValue) => {
                setUserInfo((prev) => ({ ...prev, companyName: newValue || '' }));
              }}
              onInputChange={(event, newInputValue) => {
                setUserInfo((prev) => ({ ...prev, companyName: newInputValue || '' }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company Name"
                  variant="outlined"
                  margin="dense"
                  required
                  disabled={isLoading}
                  fullWidth
                />
              )}
            />

            <TextField
              disabled={isLoading}
              label="Street Address"
              name="streetAddress"
              variant="outlined"
              value={userInfo.streetAddress}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="City"
              name="city"
              variant="outlined"
              value={userInfo.city}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="State"
              name="state"
              variant="outlined"
              value={userInfo.state}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="country"
                value={userInfo.country}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select your country
                </MenuItem>
                {countryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="industry"
                value={userInfo.industry}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select your industry
                </MenuItem>
                {industryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              disabled={isLoading}
              label="Tell us about your company"
              name="bio"
              variant="outlined"
              multiline
              rows={4}
              value={userInfo.bio}
              onChange={handleChange}
              margin="dense"
              fullWidth
              required // Added required prop here
            />
          </Box>
        );
      case 2:
        return (
          <Box>
            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="businessType"
                value={userInfo.businessType}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Is your business all under one company, or is it separate?
                </MenuItem>
                <MenuItem value="One Company">One Company</MenuItem>
                <MenuItem value="Separate">Separate</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="isAgency"
                value={userInfo.isAgency}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Are you an agency?
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              disabled={isLoading}
              label="How much do you expect to spend monthly?"
              name="monthlySpend"
              variant="outlined"
              value={userInfo.monthlySpend}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="How many accounts do you need (for your team)?"
              name="teamAccounts"
              variant="outlined"
              value={userInfo.teamAccounts}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="paymentMethod"
                value={userInfo.paymentMethod}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  What is your main method of payment?
                </MenuItem>
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                <MenuItem value="PayPal">PayPal</MenuItem>
              </Select>
            </FormControl>

            <TextField
              disabled={isLoading}
              label="How did you hear about us?"
              name="referralSource"
              variant="outlined"
              value={userInfo.referralSource}
              onChange={handleChange}
              margin="dense"
              fullWidth
            />

            {/* NEW: Required T&C checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  name="termsAccepted"
                  color="primary"
                  checked={userInfo.termsAccepted}
                  onChange={handleChange}
                  disabled={isLoading}
                />
              }
              label={
                <>
                  I agree to the{' '}
                  <Link
                    component={RouterLink}
                    to="/terms"
                    target="_blank"
                    rel="noopener"
                  >
                    terms and conditions of Blitz
                  </Link>
                </>
              }
              sx={{ mt: 2 }}
            />
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  const StyledStepIcon = styled('div')(({ theme, ownerState }) => ({
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ownerState.completed ? '#6366f1' : '#e2e8f0',
    color: ownerState.completed ? 'white' : 'text.secondary',
    fontWeight: 600,
  }));

  return (
    <>
      <Box sx={{ width: '100%', maxWidth: 700, mx: 'auto' }}>
        <Stepper 
          activeStep={activeStep}
          sx={{ 
            mb: 6,
            '& .MuiStepLabel-label': {
              fontWeight: 600,
            }
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={({ active, completed, className }) => (
                  <StyledStepIcon ownerState={{ completed }}>
                    {completed ? '✓' : String(label)}
                  </StyledStepIcon>
                )}
              >
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ 
          p: { xs: 2, sm: 4 },
          borderRadius: '16px',
          border: '1px solid #f1f5f9'
        }}>
          {getStepContent(activeStep)}

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            mt: 4,
            pt: 3,
            borderTop: '1px solid #f1f5f9'
          }}>
            <Button 
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ borderRadius: '8px' }}
            >
              Back
            </Button>
            <GradientButton
              onClick={handleNext}
              disabled={isLoading}
            >
              {activeStep === steps.length - 1 ? 'Create Account' : 'Continue'}
              {isLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
            </GradientButton>
          </Box>
        </Box>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AllOnboard;