import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel
} from '@mui/material';
import useAuth from '../../../../Hooks/use-auth';
import client from '../../../../API';

const usageDurations = [
  { value: '30', label: '30 days' },
  { value: '60', label: '60 days' },
  { value: '90', label: '90 days' },
  { value: '180', label: '180 days' },
  { value: '360', label: '360 days' }
];

const CampaignDetails = ({ onNext, onBack, setCampaignData }) => {
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const userCompany = currentUser?.company_name || '';

  const [campaignName, setCampaignName] = useState('');
  const [emailRecipient, setEmailRecipient] = useState('');
  const [managers, setManagers] = useState([]);
  const [currentManager, setCurrentManager] = useState('0');
  const [editMode, setEditMode] = useState('structured');
  const [requirements, setRequirements] = useState({
    creativeBrief: '',
    usage: ''
  });
  const [showUsageCrosspostingAlert, setShowUsageCrosspostingAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.companies.listUsers();
        setManagers(data);
      } catch (error) {
        setManagers([]);
      }
    };
    fetchData();
  }, []);

  const handleUsageChange = (event) => {
    setRequirements(prev => ({
      ...prev,
      usage: event.target.value
    }));
    setShowUsageCrosspostingAlert(true);
  };

  const handleCreativeChange = (event) => {
    setRequirements(prev => ({
      ...prev,
      creativeBrief: event.target.value
    }));
  };

  const convertToText = () => {
    return `# Creative\n${requirements.creativeBrief}\n\n# Additional Requirements\nUsage: ${requirements.usage} days`;
  };

  const handleNext = () => {
    const managerIndex = Number.parseInt(currentManager);
    const campaignData = {
      campaignName,
      campaignBrief: editMode === 'structured' ? convertToText() : '',
      emailRecipient,
      manager: managers[managerIndex]
        ? {
            name: `${managers[managerIndex].first_name} ${managers[managerIndex].last_name}`,
            email: managers[managerIndex].email,
          }
        : null,
    };
    setCampaignData(campaignData);
    onNext();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
      <Typography variant="h5" gutterBottom>Campaign Details</Typography>
      
      <Box sx={{ width: '100%', maxWidth: 800 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Campaign Name"
              variant="outlined"
              fullWidth
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Campaign Brief</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editMode === 'structured'}
                        onChange={(e) => setEditMode(e.target.checked ? 'structured' : 'simple')}
                        color="primary"
                      />
                    }
                    label="Structured Editor"
                  />
                </Box>

                {editMode === 'structured' ? (
                  <>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      label="Creative Brief"
                      value={requirements.creativeBrief}
                      onChange={handleCreativeChange}
                      sx={{ mb: 3 }}
                    />

                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel>Usage Duration</InputLabel>
                      <Select
                        value={requirements.usage}
                        onChange={handleUsageChange}
                        label="Usage Duration"
                      >
                        {usageDurations.map((duration) => (
                          <MenuItem key={duration.value} value={duration.value}>
                            {duration.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <TextField
                    label="Campaign Brief"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={6}
                    value={convertToText()}
                    onChange={() => {}}
                    helperText="Switch to structured editor to make changes"
                    disabled
                  />
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email Recipient"
              variant="outlined"
              fullWidth
              value={emailRecipient}
              onChange={(e) => setEmailRecipient(e.target.value)}
              required
            />
          </Grid>

          {userCompany === 'TCC' && managers?.length > 0 && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Campaign Manager</InputLabel>
                <Select
                  value={currentManager}
                  onChange={(e) => setCurrentManager(e.target.value)}
                  label="Campaign Manager"
                >
                  {managers.map((manager, index) => (
                    <MenuItem value={index} key={index}>
                      {manager.first_name} {manager.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="outlined" onClick={onBack}>Back</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!campaignName}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={showUsageCrosspostingAlert}
        onClose={() => setShowUsageCrosspostingAlert(false)}
      >
        <DialogTitle>Important Reminder</DialogTitle>
        <DialogContent>
          <Typography>
            Make sure your offer to each creator reflects usage and crossposting. If it doesn't, the creator may try to negotiate or decline your offer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUsageCrosspostingAlert(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CampaignDetails;