import React, { useEffect, useState } from 'react';
import { Background, BackgroundPattern, Dot, NavDots, PortraitBox, Slide } from './ReportComponents';
import { Alert, Box, CircularProgress, styled } from '@mui/material';
import OpeningSlide from './Slides/OpeningSlide';
import InfluencersSlide from './Slides/InfluencersSlide';
import VideosSlide from './Slides/VideosSlide';
import ViewsChartSlide from './Slides/ViewsChartSlide';
import ReachSlide from './Slides/ReachSlide';
import TimelineSlide from './Slides/TimelineSlide';
import { useQuery } from 'react-query';
import API from '../../API';
import { useParams } from 'react-router-dom';

export default function CampaignReport(props) {
    const { campaignId } = useParams();

    const [currentSlide, setCurrentSlide] = useState(0);

    const { data, isLoading, error } = useQuery({
        queryKey: ['campaign', campaignId, 'report'],
        queryFn: () => API.campaigns.fetchReport(campaignId),
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (data) {
            console.log(data);
        }
    }, [data]);

    return (
        <>
            <Background id='Background-Layer-1'>
                <BackgroundPattern id='Background-Layer-2'>
                    <Box
                        id='Loading-Overlay'
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            zIndex: 100,
                            transition: 'transform 1s ease-in-out',
                            transform: isLoading ? 'translateX(0%)' : 'translateX(100%)'
                        }}>
                        <CircularProgress sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            color: '#DDD',
                            margin: '-20px 0 0 -20px',
                        }}
                        ></CircularProgress>
                    </Box>
                    {!!error&&<Alert severity="error" sx={{
                        position: 'absolute',
                        width: '90%',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        {error?.response?.data?.error || error.message}
                    </Alert>}
                    <PortraitBox id='Portrait-Box' isLoaded={!isLoading && !!data}>
                        <OpeningSlide
                            index={0}
                            currentSlide={currentSlide}
                            report={data}
                            >
                        </OpeningSlide>
                        <InfluencersSlide
                            index={1}
                            currentSlide={currentSlide}
                            report={data}
                        >
                        </InfluencersSlide>
                        <VideosSlide
                            index={2}
                            currentSlide={currentSlide}
                            report={data}
                        >
                        </VideosSlide>
                        <ViewsChartSlide
                            index={3}
                            currentSlide={currentSlide}
                            report={data}
                        >
                        </ViewsChartSlide>
                        <ReachSlide
                            index={4}
                            currentSlide={currentSlide}
                            report={data}
                            >
                        </ReachSlide>
                        <TimelineSlide
                            index={5}
                            currentSlide={currentSlide}
                            report={data}
                            >
                        </TimelineSlide>
                        <NavDots>
                            {[0, 1, 2, 3, 4, 5].map((index) => (
                                <Dot
                                    key={index}
                                    active={currentSlide === index}
                                    onClick={() => setCurrentSlide(index)}
                                />
                            ))}
                        </NavDots>
                    </PortraitBox>
                </BackgroundPattern>
            </Background>
        </>
    );
}
