const routes = {
  base: "/",
  home: "/home",
  login: "/login",
  unilogin: "/unilogin",
  oldlogin: "/old_login",

  internalUserLogin: "/internal_login",
  passwordForgot: "/forgot",
  passwordRenew: "/passwordRestore/:requestId",
  register: "/register",
  dashboard: "/dashboard",
  user: "/user",
  zapFeed: "/zap-feed",
  invoicing: "/invoicing",
  campaigns: "/campaigns",
  search: "/search",
  trackers: "/search/trackers",
  blitzpay: "/blitzpay",
  addCreators: "/add-creators/:campaignId",
  resources: "/resources",
  about: "/about",
  creatorSignup: "/creatorsignup",
  company: "/company",
  campaignPublic: "/campaigns/:campaignId",
  campaignReport: "/campaigns/report/:campaignId",

  campaignTimeline: "/campaigns/:campaignId/timelines",
  creatorCampaignTimeline: "/campaigns/:campaignId/creator/:creatorId/timelines", // Add this line for the new route
  creatorMediaKit: "/creators/:creatorId",
  roster: "/roster/:manager", // New route for the AgencyCreatorRoster component
  tempRoster: "/temp_roster/:manager", // New route for the AgencyCreatorRoster component
  creatorConnectReauth: "/creatorconnect/reauth/:creator",
  creatorConnect: "/creatorconnect/:creator",
  creatorLogin: "/creatorconnect/login",
  internalLogin: "/creatorconnect/internal_login",
  creatorConnectCampaigns: "/creatorconnect/campaigns",
  creatorConnectStart: "/creatorconnect/start",
  creatorConnectStripe: "/creatorconnect/start/stripe",
  creatorConnectOnboard: "/creatorconnect/onboard",
  creatorConnectOnboardApp: "/creatorconnect/onboardoauth",
  commisions: "/commissions",
  creatorPayoutInvoices: "/creatorinvoice",
  creatorCRMPublic: "/creatorCRM",
  requestAccess: "/requestaccess",
  similarCreators: "/creators/:creatorId/similar",
  demoRegister: "/demoregister",
  about: "/about",
  deliverablePage: "/partnerships/:partnershipId/creator/:creatorId/deliverables", // Add this line for the new route
  tempCreator: "/tempCreator/:creatorId",
  help: "/help",
  files: "/files",
  editCampaign: "/editcampaign/:campaignId",
  paypage: "/pay",
  partnershipsPage: "/partnerships/:partnershipId",
  solutions: "/solutions",
  solutionsAgencies: "/agency_solutions",
  forcreators: "/forcreators",
  
  creatorConnectOauth: "/creatorconnect/oauth",
  creatorConnectDashboard: "/creatorconnect/dashboard",
  creatorConnectGoogle: "/creatorconnect/redirect",
  creatorConnectCampaigns: "/creatorconnect/campaigns",
  creatorConnectPayoutsInvoicing: "/creatorconnect/payouts-invoicing",
  creatorConnectPitch: "/creatorconnect/pitch",
  creatorConnectPartnerships: "/creatorconnect/partnerships",
  creatorConnectEdit: "/creatorconnect/edit",
  creatorConnectDealsEmails: "/creatorconnect/deals-emails",
  creatorConnectMore: "/creatorconnect/more",
  creatorConnectCommissions: "/creatorconnect/commissions",
  creatorConnectApps: "/creatorconnect/apps",
  creatorConnectAppsSpec: "/creatorconnect/apps/:appId",

  creatorCampaigns: "/creatorconnect/campaigns/:campaignId",
  whypartner: "/whypartner",
  trackers: "/trackers",
  creaotrParntershipsSpecific: "/creatorconnect/partnerships/:partnershipId",
  pitchFromZap: "/creatorconnect/pitch/:type/:id",
  parntersListL: "/partners",
  campaignconvospec: "/campaigns/convo/:campaignId/:creatorName",
  privacyPolicy: "/privacypolicy",
  agency: "/agency/",
  agencyCreators: "/agency/creators",
  agencyCreatorCollaborations: "/agency/creators/:creatorId/collaborations",
  agencyCreatorPartnership: "/agency/creators/:creatorId/collaborations/partnerships/:partnershipId",
  agencyCreatorCampaign: "/agency/creators/:creatorId/collaborations/campaigns/:campaignId",
  
  agencyCreator: "/agency/creators/:creatorId",
  
  agencyCreatorConversations: "/agency/creators/:creatorId/conversations",
  agencyCreatorConversation: "/agency/creators/:creatorId/conversations/:conversationId",
  agencyCreatorPayouts: "/agency/creators/:creatorId/payouts",
  agencyCreatorPayout: "/agency/creators/:creatorId/payouts/:payoutId",
  campaignOptOut: "/campaigns/optout/:campaignId",
  partnerships: "/partnerships",
  castingCalls: "/casting",
  integrations: "/integrations",
  creatorCastingCalls: "/creatorconnect/casting",
  collaborations: "/collabs",
  creatorCollabs: "/creatorconnect/collabs",
  articles: "/articles",
  dealsIO: "/deals_factor",
  dealsIOSpec: "/deals/:dealId",
  manageDealsIO: "/deals/public/:dealId",
  payoutSpec: "/payouts",
  onboard: "/onboard",
  aiTest: "/testing",
  creatorDeals: "/creatorconnect/deals",
  creatorDealIOSpec: "/creatorconnect/deals/:dealId",

  docsHome: "/docs",
  docsService: "/docs/services",
  docsKeys: "/docs/apikeys",
  docsOauth: "/docs/oauth",
  docsEndpoints: "/docs/endpoints",
  docsEndCreators: "/docs/endpoints/creators",
  releasteNotes: "/releases",
  campaignOffer: "/offer/:creator/:campaignId",
  contractSpec: "/contract/:id",
  userProfile: "/user/:userId",
  userProfilePublic: "/publicuser/:userId",
  conversationshub: "/convos",
  creatorInvoicing: "/creatorinvoicing",
  clientInvoicing: "/client_invoice",
  creatorBooking: "/search/creator_booking",
  agencyTerms: "/terms",
  vendorSignup: "/vendor_signup",
  creatorConvos: "/creatorconnect/convos",
  accessfile: "/files/:fileId",
  bioPublic: "/b/:slug", // Public bio page URL, e.g. "/@mycreatorpage"
  creatorbio: "/creatorconnect/bio", // Public bio page URL, e.g. "/@mycreatorpage"
};

export default routes;