import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Backdrop,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Collapse,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useQuery, useMutation } from 'react-query';
import client from '../../../../API';

const PAGE_OPTIONS = [
  '/dashboard',
  '/search',
  '/agency',
  '/creatorconnect/start',
  '/creatorconnect/deals',
];

const SurveyDetailsView = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newSurveyTitle, setNewSurveyTitle] = useState('');
  const [newSurveyDescription, setNewSurveyDescription] = useState('');
  const [newSurveyAppearsOn, setNewSurveyAppearsOn] = useState('');
  const [newSurveyAction, setNewSurveyAction] = useState('');
  const [questions, setQuestions] = useState([{ questionText: '', options: [''] }]);
  const [expandedSurvey, setExpandedSurvey] = useState(null);

  // Fetch Surveys
  const { data: surveys, isLoading: isSurveysLoading, refetch: refetchSurveys } = useQuery(
    'listSurveys',
    () => client.surveys.list(),
    { refetchOnWindowFocus: false }
  );

  // Fetch Survey Responses with User Info
  const { data: surveyResponses, isLoading: isResponsesLoading } = useQuery(
    ['surveyResponses', expandedSurvey],
    () => client.surveys.getResponses(expandedSurvey),
    { enabled: !!expandedSurvey, refetchOnWindowFocus: false }
  );

  // Create Survey Mutation
  const createSurveyMutation = useMutation(
    (input) => client.surveys.create(input),
    {
      onSuccess: () => refetchSurveys(),
      onError: (error) => {
        console.error('Error creating survey:', error);
        alert('Failed to create survey');
      },
    }
  );

  const handleCreateSurvey = () => {
    const questionsPayload = questions.map((q) => ({
      question: q.questionText,
      options: q.options,
    }));
    const surveyData = {
      title: newSurveyTitle,
      description: newSurveyDescription,
      appears_on: newSurveyAppearsOn,
      action: newSurveyAction,
      questions: questionsPayload,
    };
    createSurveyMutation.mutate(surveyData);
    setOpenDialog(false);
    setNewSurveyTitle('');
    setNewSurveyDescription('');
    setNewSurveyAppearsOn('');
    setNewSurveyAction('');
    setQuestions([{ questionText: '', options: [''] }]);
  };

  const handleExpandSurvey = (surveyId) => {
    setExpandedSurvey(expandedSurvey === surveyId ? null : surveyId);
  };

  // Question Handlers
  const handleAddQuestion = () => setQuestions((prev) => [...prev, { questionText: '', options: [''] }]);
  const handleRemoveQuestion = (index) => setQuestions((prev) => prev.filter((_, i) => i !== index));
  const handleQuestionTextChange = (index, value) =>
    setQuestions((prev) => prev.map((q, i) => (i === index ? { ...q, questionText: value } : q)));
  const handleAddOption = (qIndex) =>
    setQuestions((prev) => prev.map((q, i) => (i === qIndex ? { ...q, options: [...q.options, ''] } : q)));
  const handleRemoveOption = (qIndex, optIndex) =>
    setQuestions((prev) =>
      prev.map((q, i) => (i === qIndex ? { ...q, options: q.options.filter((_, oI) => oI !== optIndex) } : q))
    );
  const handleOptionChange = (qIndex, optIndex, value) =>
    setQuestions((prev) =>
      prev.map((q, i) =>
        i === qIndex ? { ...q, options: q.options.map((opt, oI) => (oI === optIndex ? value : opt)) } : q
      )
    );

  return (
    <Box sx={{ padding: 3, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Backdrop
        open={isSurveysLoading || isResponsesLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" color="primary">
          Surveys Dashboard
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} startIcon={<AddIcon />}>
          Add Survey
        </Button>
      </Box>

      <TableContainer component={Paper} elevation={3} sx={{ borderRadius: 2 }}>
        <Table sx={{ '&:hover': { boxShadow: '0 4px 12px rgba(0,0,0,0.1)' } }}>
          <TableHead sx={{ bgcolor: '#1976d2' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} />
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>ID</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Appears On</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Action</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Questions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys &&
              surveys.map((survey) => (
                <React.Fragment key={survey.id}>
                  <TableRow
                    onClick={() => handleExpandSurvey(survey.id)}
                    sx={{ '&:hover': { bgcolor: '#e3f2fd' }, cursor: 'pointer' }}
                  >
                    <TableCell>
                      <IconButton size="small">
                        <ExpandMoreIcon
                          sx={{
                            transform: expandedSurvey === survey.id ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                            color: '#1976d2',
                          }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell>{survey.id}</TableCell>
                    <TableCell>{survey.title}</TableCell>
                    <TableCell>{survey.description}</TableCell>
                    <TableCell>{survey.appears_on}</TableCell>
                    <TableCell>{survey.action}</TableCell>
                    <TableCell sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
                      {JSON.stringify(survey.questions, null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                      <Collapse in={expandedSurvey === survey.id} timeout="auto" unmountOnExit>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom color="textSecondary">
                            Responses
                          </Typography>
                          {surveyResponses && surveyResponses.length > 0 ? (
                            surveyResponses.map((response) => {
                              console.log('Response:', response); // Debug log to inspect data
                              return (
                                <Card
                                  key={response.id}
                                  sx={{
                                    mb: 2,
                                    borderRadius: 2,
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                    bgcolor: '#fff',
                                  }}
                                >
                                  <CardContent>
                                    <Box display="flex" justifyContent="space-between" mb={1}>
                                      <Typography variant="subtitle1" fontWeight="bold">
                                        Response #{response.id}
                                      </Typography>
                                      <Typography variant="body2" color="textSecondary">
                                        {new Date(response.created_at).toLocaleString()}
                                      </Typography>
                                    </Box>
                                    <Box mb={1}>
                                      <Typography variant="body2">
                                        <strong>User:</strong> {response.user?.first_name || 'Unknown'}{' '}
                                        {response.user?.last_name || ''} (ID: {response.user_id || 'N/A'})
                                      </Typography>
                                      <Typography variant="body2">
                                        <strong>Company:</strong> {response.user?.company_name || 'N/A'}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontFamily: 'monospace',
                                        whiteSpace: 'pre-wrap',
                                        bgcolor: '#f9f9f9',
                                        p: 1,
                                        borderRadius: 1,
                                      }}
                                    >
                                      <strong>Answers:</strong>
                                      <br />
                                      {JSON.stringify(response.answers, null, 2)}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              );
                            })
                          ) : (
                            <Typography variant="body1" color="textSecondary" sx={{ p: 2 }}>
                              No responses yet.
                            </Typography>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Create New Survey</DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Title"
            fullWidth
            variant="outlined"
            margin="normal"
            value={newSurveyTitle}
            onChange={(e) => setNewSurveyTitle(e.target.value)}
          />
          <TextField
            label="Description"
            fullWidth
            variant="outlined"
            margin="normal"
            value={newSurveyDescription}
            onChange={(e) => setNewSurveyDescription(e.target.value)}
          />
          <Box mt={2}>
            <Typography variant="body2" gutterBottom>
              Appears On
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              value={newSurveyAppearsOn}
              onChange={(e) => setNewSurveyAppearsOn(e.target.value)}
            >
              <MenuItem value="">(None)</MenuItem>
              {PAGE_OPTIONS.map((path) => (
                <MenuItem key={path} value={path}>
                  {path}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" gutterBottom>
              Action
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              value={newSurveyAction}
              onChange={(e) => setNewSurveyAction(e.target.value)}
            >
              <MenuItem value="">(None)</MenuItem>
              {PAGE_OPTIONS.map((path) => (
                <MenuItem key={path} value={path}>
                  {path}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Questions
            </Typography>
            {questions.map((q, qIndex) => (
              <Box
                key={qIndex}
                sx={{ border: '1px solid #e0e0e0', borderRadius: 2, p: 2, mb: 2, position: 'relative', bgcolor: '#fff' }}
              >
                <IconButton
                  onClick={() => handleRemoveQuestion(qIndex)}
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                  <DeleteIcon />
                </IconButton>
                <TextField
                  label={`Question #${qIndex + 1}`}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={q.questionText}
                  onChange={(e) => handleQuestionTextChange(qIndex, e.target.value)}
                />
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    Options
                  </Typography>
                  {q.options.map((opt, optIndex) => (
                    <Box key={optIndex} display="flex" alignItems="center" mt={1}>
                      <TextField
                        label={`Option #${optIndex + 1}`}
                        variant="outlined"
                        value={opt}
                        onChange={(e) => handleOptionChange(qIndex, optIndex, e.target.value)}
                        sx={{ mr: 1, flex: 1 }}
                      />
                      <IconButton
                        onClick={() => handleRemoveOption(qIndex, optIndex)}
                        disabled={q.options.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => handleAddOption(qIndex)}
                    sx={{ mt: 1 }}
                  >
                    Add Option
                  </Button>
                </Box>
              </Box>
            ))}
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddQuestion}>
              Add Question
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCreateSurvey}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SurveyDetailsView;