import { Box, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'

export default function HomeFooter({sx={}}) {

    return (
        <Box
            sx={{
                ...sx,
                paddingBlockStart: {xs:6, md: 10},
                paddingBlockEnd: 3,
                paddingInline:1,
                textAlign: "center",
                backgroundColor: '#111111',
                color:'#FFF'
            }}>
            <Typography
                variant="body2"
                color="inherit"
                sx={{ mt: 4, textAlign: 'center' }}
            >
                © 2024 Pangea, Inc. All Rights Reserved.{' '}
                <Link component={RouterLink} to="/privacypolicy">
                    <b>Privacy Policy</b>
                </Link>
            </Typography>

        </Box >
    )
}
