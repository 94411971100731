// CreatorSelectDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MiniCreatorSearch from '../miniCreatorSearch';

const EMPTYSET = new Set([]);

const SelectCreatorDialog = ({ open, onClose, onCreatorSelect }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: "white" }}>
        Select Creator
      </DialogTitle>
      <DialogContent dividers sx={{ height: { xs: '75vh', md: '70vh' }, minHeight: '0', padding:0 }}>
        <MiniCreatorSearch
          selectedCreators={EMPTYSET}
          disabledCreators={EMPTYSET}
          onCreatorPick={onCreatorSelect}
        ></MiniCreatorSearch>
      </DialogContent>
    </Dialog>
  );
};

export default SelectCreatorDialog;