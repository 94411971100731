import React from 'react'
import { Dialog, DialogContent, DialogTitle, Paper } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import { useCreator } from '../../../Hooks/agencySelectedCreatorContext';
import ChatScreen from '../../../Components/ChatScreen';
import useIsDesktop from '../../../Hooks/useIsDesktop';

export default function AgencyCampaignConversation({ }) {
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const { conversationId } = useParams();
    const { creatorData } = useCreator();
    const creatorUsername = creatorData?.creator.creator

    const onClose = () => {
        navigate('..');
    }

    return (
        <Dialog open={true} onClose={onClose} fullScreen={!isDesktop}>
            <DialogTitle>
                Conversation
            </DialogTitle>
            <Paper square elevation={0}>
                <DialogContent dividers>
                    <ChatScreen
                        conversationId={conversationId}
                        sx={{ minHeight: '480px', maxHeight: '720px' }}
                        specificReaderId={creatorUsername}
                        margin={0}
                        showWarning={false}>
                    </ChatScreen>
                </DialogContent>
            </Paper>
        </Dialog>
    )
}
