import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  Alert,
  Box,
  Divider,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../../../API";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const OfferDialog = ({ open, onClose, offer, conversationId, isCreator }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [daysSinceSent, setDaysSinceSent] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Local state for status updates and negotiation details
  const [status, setStatus] = useState(offer?.status?.toLowerCase() || "");
  const [amount, setAmount] = useState(offer?.amount || "");
  const [revision, setRevision] = useState(offer?.revision || 0);
  const [initialStatus, setInitialStatus] = useState(offer?.status?.toLowerCase() || "");

  // Determine if we are negotiating (counter offer selected)
  const isNegotiating = status === "counter-negotiate";

  // Allow status update controls only when offer is not finalized
  const canToggleStatus = !["accept", "decline", "completed"].includes(
    offer?.status?.toLowerCase() || ""
  );
  const shouldShowStatusControls = isCreator || (!isCreator && offer?.status?.toLowerCase() === "counter-negotiate");
  const canComplete = offer?.status?.toLowerCase() === "accept";
  const hasStatusChanged = status !== initialStatus;

  useEffect(() => {
    if (offer && offer.created_at) {
      const sentDate = new Date(offer.created_at);
      const currentDate = new Date();
      const differenceInDays = (currentDate - sentDate) / (1000 * 3600 * 24);
      setDaysSinceSent(Math.round(differenceInDays));
    }
  }, [offer]);

  useEffect(() => {
    setInitialStatus(offer?.status?.toLowerCase() || "");
    setStatus(offer?.status?.toLowerCase() || "");
    setAmount(offer?.amount || "");
    setRevision(offer?.revision || 0);
  }, [offer]);

  const sendMessageMutation = useMutation(
    ({ conversationId, message }) =>
      client.conversations.sendMessage(conversationId, { message }),
    {
      onError: (error) => {
        console.error("Error sending message:", error);
      },
    }
  );

  const updateStatusMutation = useMutation(
    async ({ deliverableId, updateData }) => {
      const res = await client.deliverables.update(deliverableId, updateData);
      return res;
    },
    {
      onMutate: () => setLoading(true),
      onSuccess: (data, variables) => {
        setShowSuccess(true);
        setLoading(false);

        let chatMsg = "";
        const newStat = variables.updateData.status;
        if (newStat === "accept") {
          chatMsg = `We have accepted your offer.`;
        } else if (newStat === "decline") {
          chatMsg = `We have declined the offer.`;
        } else if (newStat === "counter-negotiate") {
          chatMsg = `We've proposed a counter at $${variables.updateData.amount} with ${variables.updateData.revision} revisions.`;
        }

        if (conversationId && chatMsg) {
          sendMessageMutation.mutate({
            conversationId,
            message: chatMsg,
          });
        }
        onClose();
      },
      onError: (error) => {
        console.error("Error updating offer:", error);
        setShowError(true);
        setErrorMessage(
          `Error updating offer: ${error?.response?.data?.error || error.message}`
        );
        setLoading(false);
      },
    }
  );

  const completeOfferMutation = useMutation(
    () => client.deliverables.update(offer.id, { status: "completed" }),
    {
      onMutate: () => setLoading(true),
      onSuccess: () => {
        setShowSuccess(true);
        setLoading(false);

        if (conversationId) {
          sendMessageMutation.mutate({
            conversationId,
            message:
              "We have marked the deliverable as completed! Your payout has also been processed",
          });
        }
        onClose();
      },
      onError: (error) => {
        console.error("Error completing offer:", error);
        setShowError(true);
        setErrorMessage(
          `Error completing offer: ${error?.response?.data?.error || error.message}`
        );
        setLoading(false);
      },
    }
  );

  const handleChangeStatus = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  };

  // Handle amount input - only allow numbers and decimal points
  const handleAmountChange = (e) => {
    const value = e.target.value;
    // Allow empty string, integers, or decimals with up to 2 places
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      setAmount(value);
    }
  };

  // Handle revision input - only allow positive integers
  const handleRevisionChange = (e) => {
    const value = e.target.value;
    // Allow empty string or positive integers only
    if (value === "" || /^\d+$/.test(value)) {
      setRevision(value);
    }
  };

  const handleSubmitStatus = async () => {
    const updateData = { status };
    if (status === "counter-negotiate") {
      if (!amount || !revision) {
        setShowError(true);
        setErrorMessage("Please enter both amount and revisions for counter offer");
        return;
      }
      updateData.amount = parseFloat(amount);
      updateData.revision = parseInt(revision, 10);
    }
    updateStatusMutation.mutate({
      deliverableId: offer.id,
      updateData,
    });
  };

  const handleCompleteOffer = () => {
    completeOfferMutation.mutate();
  };

  const details = [
    { label: "Notes:", value: offer?.notes || "N/A" },
    { label: "Type:", value: offer?.deliverable_type || "N/A" },
    {
      label: "Amount:",
      value: `$${offer?.amount ? parseFloat(offer.amount).toFixed(2) : "0.00"}`,
    },
    {
      label: "Due Date:",
      value: offer?.due_date
        ? new Date(offer.due_date).toLocaleDateString()
        : "N/A",
    },
    {
      label: "Revision:",
      value: offer?.revision != null ? offer.revision : "N/A",
    },
    { label: "Script:", value: offer?.script || "N/A" },
    {
      label: "Platforms:",
      value: offer?.platforms
        ? Array.isArray(offer.platforms)
          ? offer.platforms.join(", ")
          : offer.platforms
        : "N/A",
    },
    { label: "Days Since Sent:", value: daysSinceSent },
  ];

  const renderMilestones = () => {
    if (!offer.milestones || offer.milestones.length === 0) {
      return <Typography variant="body2">No milestones defined.</Typography>;
    }
    return offer.milestones.map((m, idx) => (
      <Box
        key={idx}
        sx={{
          p: 1,
          mb: 1,
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 1,
        }}
      >
        <Typography variant="body2">
          <strong>Description:</strong> {m.description}
        </Typography>
        <Typography variant="body2">
          <strong>Due Date:</strong> {new Date(m.due_date).toLocaleDateString()}
        </Typography>
        {m.amount != null && (
          <Typography variant="body2">
            <strong>Amount:</strong> ${parseFloat(m.amount).toFixed(2)}
          </Typography>
        )}
        {m.link && (
          <Typography variant="body2">
            <strong>Link:</strong>{" "}
            <a href={m.link} target="_blank" rel="noopener noreferrer">
              {m.link}
            </a>
          </Typography>
        )}
      </Box>
    ));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { overflow: "hidden" } }}
    >
      <DialogTitle
        sx={{
          bgcolor: "background.paper",
          color: "text.primary",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 2,
          px: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" component="div">
          Offer Details
        </Typography>
        {offer?.status?.toLowerCase() === "completed" && (
          <Box display="flex" alignItems="center" color="success.main" sx={{ gap: 1 }}>
            <CheckCircleOutlineIcon fontSize="small" />
            <Typography variant="body2">Completed</Typography>
          </Box>
        )}
      </DialogTitle>

      <DialogContent
        sx={{
          bgcolor: "background.default",
          px: 3,
          py: 3,
          height: 600,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box display="flex" alignItems="center" sx={{ gap: 1.5 }}>
            <InfoOutlinedIcon color="info" fontSize="small" />
            <Typography variant="subtitle1">Offer Summary</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          {details.map((item, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              sx={{ flexDirection: isMobile ? "column" : "row", gap: 0.5 }}
            >
              <Typography variant="body2" color="text.secondary">
                {item.label}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.primary", fontWeight: 500 }}>
                {item.value}
              </Typography>
            </Box>
          ))}
          {offer?.milestones && offer.milestones.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Milestones:
              </Typography>
              {renderMilestones()}
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          bgcolor: "background.paper",
          px: 3,
          py: 2,
          borderTop: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {shouldShowStatusControls ? (
          <>
            <Typography variant="subtitle2" sx={{ alignSelf: "flex-start" }}>
              Update Offer Status
            </Typography>
            <ToggleButtonGroup
              value={status}
              exclusive
              onChange={handleChangeStatus}
              sx={{ alignSelf: "flex-start" }}
            >
              {isCreator && (
                <>
                  <ToggleButton
                    value="accept"
                    sx={{
                      "&.Mui-selected": { backgroundColor: "green", color: "white" },
                    }}
                  >
                    Accept
                  </ToggleButton>
                  <ToggleButton
                    value="decline"
                    sx={{
                      "&.Mui-selected": { backgroundColor: "red", color: "white" },
                    }}
                  >
                    Decline
                  </ToggleButton>
                </>
              )}
              {(isCreator || (!isCreator && offer?.status?.toLowerCase() === "counter-negotiate")) && (
                <ToggleButton
                  value="counter-negotiate"
                  sx={{
                    "&.Mui-selected": { backgroundColor: "yellow", color: "black" },
                  }}
                >
                  Counter
                </ToggleButton>
              )}
            </ToggleButtonGroup>

            {isNegotiating && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                }}
              >
                <TextField
                  label="Proposed Amount"
                  type="text"  // Changed from "number" to "text" for more control
                  value={amount}
                  onChange={handleAmountChange}
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "[0-9]*\.?[0-9]{0,2}",
                  }}
                  helperText="Enter a number (e.g., 100.00)"
                />
                <TextField
                  label="Revisions"
                  type="text"  // Changed from "number" to "text" for more control
                  value={revision}
                  onChange={handleRevisionChange}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  helperText="Enter a whole number"
                />
                <Typography variant="caption" color="textSecondary">
                  * A 5% fee is applied on all payments.
                </Typography>
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              {hasStatusChanged ? (
                <Button variant="contained" disabled={loading} onClick={handleSubmitStatus}>
                  {loading ? <CircularProgress size={20} /> : "Update Status"}
                </Button>
              ) : (
                <Button onClick={onClose} variant="outlined" sx={{ px: 3 }}>
                  Close
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button onClick={onClose} variant="outlined" sx={{ px: 3 }}>
              Close
            </Button>
          </Box>
        )}

        {canComplete && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button onClick={handleCompleteOffer} variant="contained" disabled={loading} sx={{ px: 3 }}>
              {loading ? (
                <Box display="flex" alignItems="center" gap={1}>
                  <CircularProgress size={20} />
                  Processing...
                </Box>
              ) : (
                "Mark as Completed"
              )}
            </Button>
          </Box>
        )}
      </DialogActions>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="success"
          sx={{ width: "100%", bgcolor: "success.light", color: "success.contrastText" }}
        >
          Offer updated successfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          sx={{ width: "100%", bgcolor: "error.light", color: "error.contrastText" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default OfferDialog;