import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';
import { useCreator } from '../../../Hooks/agencySelectedCreatorContext';
import AgencyCampaignDialog from './agencyCampaignDialog';
import AgencyCampaignPitchDialog from './agencyCampaignPitchDialog';
import API from '../../../API';
import PageTitle from '../../../Components/PageTitle';

export default function AgencyCreatorCampaign({}) {
  const { campaignId } = useParams(); // Get campaign ID from the route
  const { creatorData } = useCreator();
  const navigate = useNavigate();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const { data: campaign, isLoading, error } = useQuery({
    queryKey: ['campaigns', campaignId],
    queryFn: () => API.campaigns.fetch(campaignId),
    enabled: !!campaignId && !!creatorData,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (error) {
      openDialog(
        'Error',
        <>Error fetching campaign: <br />{error?.response?.data?.error || error.message}</>,
        handleClose,
        handleClose,
        'Ok',
        null
      );
      console.error('Network error:', error);
    }
  }, [error]);

  const isValid = useMemo(() => {
    if (!!campaign) {
      const username = creatorData.creator.creator;
      const creator = campaign.creators.find((c) => c.id === username);
      return !!creator;
    }
    return false;
  }, [campaign]);

  useEffect(() => {
    if (campaign && !isValid) {
      openDialog(
        'Error',
        <>Error fetching campaign: <br />Creator {creatorData.creator.creator} is not part of this campaign</>,
        handleClose,
        handleClose,
        'Ok',
        null
      );
      console.error('Creator not in campaign');
    }
  }, [campaign]);

  // Updated logic to determine if the pitch dialog should show
  const shouldShowPitchDialog = useMemo(() => {
    if (!!campaign) {
      const username = creatorData.creator.creator;
      const creator = campaign.creators.find((c) => c.id === username);
      // Show pitch dialog if status is "Pitched", undefined, or "Counter"
      return (
        creator &&
        (creator.status === undefined || creator.status === 'Pitched' || creator.status === 'Counter') &&
        campaign.campaign_status === 'Launched'
      );
    }
    return false;
  }, [campaign]);

  const handleClose = () => {
    navigate('..'); // Navigate back to the collabs page
  };

  return (
    <>
      <PageTitle title={`Campaign: ${campaign?.name || '...'} - Agency - Blitz`} />
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AgencyCampaignDialog
        campaign={isValid ? campaign : null}
        openDialog={!!campaign && isValid && !shouldShowPitchDialog}
        username={creatorData?.creator.creator}
        handleCloseDialog={handleClose}
      />
      <AgencyCampaignPitchDialog
        campaign={isValid ? campaign : null}
        openDialog={!!campaign && isValid && shouldShowPitchDialog}
        username={creatorData?.creator.creator}
        handleCloseDialog={handleClose}
      />
    </>
  );
}