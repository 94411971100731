import { API_ENDPOINTS } from "./endpoints";
import { HttpClient } from "./http-client";

class Client {
  users = {
    publicList: (input) => HttpClient.get(API_ENDPOINTS.USERS_LIST, input),
    login: (input) => HttpClient.post(API_ENDPOINTS.USERS_LOGIN, input),
    internalLogin: (input) => HttpClient.post(API_ENDPOINTS.USERS_INTERNAL_LOGIN, input),
    updateGoogle: (input) => HttpClient.post(API_ENDPOINTS.USERS_UPDATE_GOOGLE, input, { ignoreAuthError: true }),
    dashboard: () => HttpClient.get(API_ENDPOINTS.USERS_DASHBOARD),
    register: (input) => HttpClient.post(API_ENDPOINTS.USERS_REGISTER, input),
    fetchUser: () => HttpClient.get(API_ENDPOINTS.FETCH_USER),
    userKPI: () => HttpClient.get(API_ENDPOINTS.USERS_KPI),
    userDetails: () => HttpClient.get(API_ENDPOINTS.USERS_DETAILS),
    access: (input) => HttpClient.post(API_ENDPOINTS.REQUEST_ACCESS_CRM, input),
    userAdminFetch: () => HttpClient.get(API_ENDPOINTS.USER_ADMIN_FETCH),
    creatorUserAdminFetch: () => HttpClient.get(API_ENDPOINTS.CREATOR_USER_ADMIN_FETCH),

    editUserWithCredits: (input) => HttpClient.post(API_ENDPOINTS.USER_ADMIN_EDIT, input),
    editCreatorWithCredits: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_CREDITS_ADMIN_EDIT, input),

    fetchSharedWith: () => HttpClient.get(API_ENDPOINTS.SHARED_WITH_ADMIN),
    postClient: (input) => HttpClient.post(API_ENDPOINTS.CLIENTS_ADD, input),
    fetchClient: () => HttpClient.get(API_ENDPOINTS.CLIENTS),
    fetchZapFeed: (input) => HttpClient.get(API_ENDPOINTS.USERS_ZAPFEED, input),
    updateClient: (id, input) => HttpClient.put(API_ENDPOINTS.UPDATE_CLIENT(id), input),
    fetchUserSettings: () => HttpClient.get(API_ENDPOINTS.USER_SETTINGS, null, { accept404: true }),
    createUserSettings: (input) => HttpClient.post(API_ENDPOINTS.USER_SETTINGS, input),
    updateUserSettings: (input) => HttpClient.put(API_ENDPOINTS.USER_SETTINGS, input),

    forgotRequest: (input) => HttpClient.post(API_ENDPOINTS.USERS_FORGOT, input),
    forgotAction: (input) => HttpClient.post(API_ENDPOINTS.USERS_FORGOT_ACTION, input),
    updateUser: (userData) => HttpClient.put(API_ENDPOINTS.USERS_DETAILS, userData),
    fetchPublicDetails: (userId) => HttpClient.get(API_ENDPOINTS.FETCH_SPEC_USER(userId)),
    topUsers: () => HttpClient.get(API_ENDPOINTS.USERS_TOP_USERS),
    unilogin: (input) => HttpClient.post(API_ENDPOINTS.UNI_LOGIN, input, { ignoreAuthError: true }),
    fetchMyTeam: () => HttpClient.get(API_ENDPOINTS.USERS_MYTEAM),

  }

  campaigns = {
    launch: (input) => HttpClient.post(API_ENDPOINTS.LAUNCH_CAMPAIGN, input),

    list: () => {
      console.log(API_ENDPOINTS.CAMPAIGNS);
      return HttpClient.get(API_ENDPOINTS.CAMPAIGNS)
    },
    details: (id) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_DETAILS(id)),
    share: (id, input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_SHARE(id), input),
    timeline_edit: (id, input) => HttpClient.put(API_ENDPOINTS.CAMPAIGNS_TIMELINE(id), input),
    timeline_by_creator: (id, creatorId) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_TIMELINE_BY_CREATOR(id, creatorId)),
    listAdmin: () => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_ADMIN),
    listKPI: () => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_ADMIN_KPI),

    create: (input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS, input),
    copy: (input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_COPY, input),
    fetch: (input) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_GET(input)),
    fetchReport: (input) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_REPORT_GET(input)),
    update: (input) => HttpClient.post(API_ENDPOINTS.UPDATE_CAMPAIGN, input),
    updateCreatorList: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_CREATOR_LIST, input),
    updateCreatorStats: (input) =>
      HttpClient.post(API_ENDPOINTS.CAMPAIGN_UPDATE_CREATOR_STATS, input),
    updateManager: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_MANAGER, input),
    complete: (input) =>
      HttpClient.post(
        `${API_ENDPOINTS.COMPLETE_CAMPAIGN}/${input.index}`,
        input.params
      ),
    completeCreator: (campaignId, input) =>
      HttpClient.post(API_ENDPOINTS.COMPLETE_CREATOR(campaignId),
        input
      ),
    completeVideo: (campaignId, input) =>
      HttpClient.post(API_ENDPOINTS.COMPLETE_VIDEO(campaignId),
        input
      ),
    delete: (input) => HttpClient.post(API_ENDPOINTS.DELETE_CAMPAIGN, input),
    editAdmin: (input) =>
      HttpClient.post(API_ENDPOINTS.EDIT_CAMPAIGN_ADMIN, input),
    deleteCampaignAdmin: (input) =>
      HttpClient.post(API_ENDPOINTS.DELETE_CAMPAIGN_ADMIN, input),
    fetchAdmin: (input) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_GET(input)),
    updateCreatorStats: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_CREATORS_STATS, input),
    exportSheet: (input) => HttpClient.post(API_ENDPOINTS.EXPORT_SHEET, input),
    exportSheetCreators: (input) => HttpClient.post(API_ENDPOINTS.EXPORT_SHEET_CREATORS, input),

    updateCampaignCreatorSpecs: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_CREATOR_CAMPAIGN_SPEC, input),
    updateMetaData: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_META_DATA, input),
    createSimilar: (input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_SIMILAR, input),
    addBonus: (id, input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_ADDBONUS(id), input),
    history: (campaignId) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_HISTORY(campaignId)),
    client: (campaignId, data) =>
      HttpClient.post(API_ENDPOINTS.CAMPAIGNS_CLIENT(campaignId), data),

    // Fetch clients assigned to a campaign
    clientFetch: (campaignId) =>
      HttpClient.get(API_ENDPOINTS.CAMPAIGNS_CLIENT_FETCH(campaignId)),
    optout: (campaignId) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_OPTOUT(campaignId)),
    getCreatorSpecs: ({ creator, campaignId }) =>
      HttpClient.get(API_ENDPOINTS.CAMPAIGN_HANDLER_SPECS, {
        creator,
        campaignId
      }),
    handleNegotiationResponse: (campaignId, payload) =>
      HttpClient.post(
        API_ENDPOINTS.CREATOR_NEGOTIATION(campaignId),
        payload
      ),

    resendOffer: (campaignId, creatorName) =>
      HttpClient.post(API_ENDPOINTS.RESEND_OFFER(campaignId), { creatorName }),
    creatorHistory: (campaignId, creatorId) =>
      HttpClient.get(API_ENDPOINTS.CAMPAIGNS_CREATOR_HISTORY(campaignId, creatorId)),
  };

  payouts = {
    list: () => HttpClient.get(API_ENDPOINTS.PAYOUTS),
    create: (input) => HttpClient.post(API_ENDPOINTS.PAYOUTS, input),
    delete: (input) => HttpClient.post(API_ENDPOINTS.PAYOUT_DELETE, input),
    listAdmin: async () => {
      const response = await HttpClient.get(API_ENDPOINTS.PAYOUTS_ADMIN);
      return response.payouts.map((payout) => ({
        ...payout,
        payout_date: payout.payout_date ? new Date(payout.payout_date) : null
      }))
    },
    deleteAdmin: (input) => HttpClient.post(API_ENDPOINTS.DELETE_PAYOUTS_ADMIN, input),
    editAdmin: (input) => HttpClient.post(API_ENDPOINTS.PAYOUTS_EDIT_ADMIN, input),
    createBatch: (input) => HttpClient.post(API_ENDPOINTS.CREATE_BATCH_PAYOUT, input),
    get: (payoutId) => HttpClient.get(`${API_ENDPOINTS.PAYOUTS}/${payoutId}`),
  };

  creators = {
    list: (params = null) => HttpClient.get(API_ENDPOINTS.CREATORS, params),
    listPartners: (params = null) => HttpClient.get(API_ENDPOINTS.CREATORS_PARTNERS, params),
    emails: () => HttpClient.get(API_ENDPOINTS.CREATOR_EMAILS),
    listKPI: () => HttpClient.get(API_ENDPOINTS.CREATORS_KPI),
    listManager: (agencyid) => HttpClient.get(API_ENDPOINTS.CREATORS_MANAGER(agencyid)),
    add: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_ADD, input),
    signup: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_SIGNUP, input),
    update: (input) => HttpClient.put(API_ENDPOINTS.CREATOR_UPDATE, input),
    delete: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_DELETE, input),
    softDelete: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_SOFT_DELETE, input),
    adminFetch: () => HttpClient.get(API_ENDPOINTS.CREATOR_ADMIN_FETCH),
    adminEmails: () => HttpClient.get(API_ENDPOINTS.ADMIN_CREATOR_EMAILS),

    adminBrandEmailsList: async (query) => {
      const data = await HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ADMIN_EMAIL, query);
      return {
        ...data,
        emails: data.emails.map((email) => ({ ...email, date_received: new Date(email.date_received) })),
      }
    },
    adminBrandEmail: async (email) => {
      const data = await HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ADMIN_EMAIL_GET(email));
      return {
        ...data,
        message: { ...data.message, date_received: new Date(data.message.date_received) },
      }
    },
    adminBrandKeywords: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ADMIN_EMAIL_KEYWORDS),

    payout: (input) =>
      HttpClient.get(API_ENDPOINTS.CREATOR_PAYOUT_READ, { sheetId: input }),
    data: () => HttpClient.get(API_ENDPOINTS.CREATOR_DATA_READ),
    fetchDetails: (creatorId) => HttpClient.get(API_ENDPOINTS.CREATORS_SPEC(creatorId)),
    fetchSimilar: (creatorId) => HttpClient.get(`${API_ENDPOINTS.CREATORS_SPEC(creatorId)}/similar`),
    crmUpdate: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE, input),
    fetchAverageReview: (creatorId) => HttpClient.get(API_ENDPOINTS.CREATOR_AVERAGE_REVIEW(creatorId)),
    postReview: (creatorId, input) => HttpClient.post(API_ENDPOINTS.CREATOR_REVIEW(creatorId), input),
    search: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_SEARCH, input),

  };

  crm = {
    update: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE, input),
    update_contacts: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE_CONTACTS, input),
    update_contact_status: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE_CONTACT_STATUS, input),
    creator_update_status: (input) => HttpClient.post(API_ENDPOINTS.CRM_CREATOR_UPDATE_STATUS, input),
    add: (input) => HttpClient.post(API_ENDPOINTS.CRM_ADD, input),
    creator_creators: () => HttpClient.get(API_ENDPOINTS.CRM_CREATOR_CREATORS),
    creator_fetch: (creatorId) => HttpClient.get(API_ENDPOINTS.CRM_CREATOR_SPEC(creatorId)),
    igdm_creators: () => HttpClient.get(API_ENDPOINTS.CRM_IGDM_CREATORS),
    contacts: () => HttpClient.get(API_ENDPOINTS.CRM_CONTACTS),
  };

  credx = {
    credits: (input) => HttpClient.get(API_ENDPOINTS.CREDX_CREDITS, input),
    subtract_credx: (input) => HttpClient.post(API_ENDPOINTS.SUBTRACT_CREDITS, input),
  };

  twilio = {
    sendCreatorSms: (input) => HttpClient.post(API_ENDPOINTS.TWILIO_SEND_CREATOR_SMS, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.FETCH_MESSAGES),
  };

  creatorConnect = {
    login: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_LOGIN, input, { ignoreAuthError: true }),
    dashboard: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_DASHBOARD),
    verify: (creatorName) => HttpClient.get(`${API_ENDPOINTS.CREATORCONNECT_VERIFY}?creator=${creatorName}`),
    internallogin: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_INTERNALLOGIN, input, { ignoreAuthError: true }),
    connect: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_ADD, input),
    reauth: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_REAUTH, input),
    getReauth: (creator) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_REAUTH, { username: creator }),
    campaigns: () => HttpClient.get(API_ENDPOINTS.creatorConnectCampaigns),
    campaignUpdateStatus: (campaignId, input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_STATUS_CAMPAIGN(campaignId), input),
    payouts: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_PAYOUTS),
    campaignUpdateTimeline: (id, creatorId, input) => HttpClient.put(API_ENDPOINTS.CAMPAIGNS_TIMELINE_BY_CREATOR(id, creatorId), input),
    editCreator: (input) => HttpClient.put(API_ENDPOINTS.CREATORCONNECT_EDIT, input),
    onboard: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_ONBOARD, input),
    onboardWithService: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_ONBOARD_SERVICE, input),
    editCreatorStripe: (input) => HttpClient.put(API_ENDPOINTS.CREATORCONNECT_EDIT_STRIPE, input),
    postGoogle: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_GOOGLE, input),
    getCreator: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_GET),
    getEmails: (query) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_EMAILS, query),
    getInvoice: (id) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_INVOICE(id)),
    getPartnerships: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_PARTNERSHIPS),
    getPartnershipSingle: (partnershipId) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_PARTNERSHIPS_SINGLE(partnershipId)),
    getContacts: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_CONTACTS),
    getUsersByCompany: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_USERS_BY_COMPANY),
    getCredxCredits: (input) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_CREDX_CREDITS, input),
    postCredxPitch: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_CREDX_PITCH, input),
    postCredxSubstraction: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_CREDX_SUBSTRACT, input),
    getZapFeed: (input) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ZAPFEED, input),
    commissions: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_COMMISSIONS),
    getFinancials: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_FINANCIALS),
    getMisc: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_MISC),
    postInvoice: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_INVOICE_CREATE, input),
    updateCreatorFields: (campaignId, input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_UPDATE_FIELDS(campaignId), input),
    confirmPayment: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_CONFIRM_PAYOUT, input),
    getAuthorizedSenders: () => HttpClient.get(API_ENDPOINTS.CREATOR_AUTHORIZED),
    sendBulkEmail: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_AUTHOR_SEND, input),
    fetchEmailBlasts: () => HttpClient.get(API_ENDPOINTS.CREATOR_AUTHOR_SEND_FETCH),
    campaignNegotiate: (campaignId, input) =>
      HttpClient.post(API_ENDPOINTS.CREATORCONNECT_NEGOTIATE_CAMPAIGN(campaignId), input),
    fetchUserInfo: (email) => HttpClient.get(`/creatorUsers/fetch_user_info?email=${email}`),

  }

  invoices = {
    list: () => HttpClient.get(API_ENDPOINTS.INVOICES),
    fetch: (invoiceId) => HttpClient.get(API_ENDPOINTS.INVOICE_FETCH(invoiceId)),
    listKPI: () => HttpClient.get(API_ENDPOINTS.INVOICE_KPI),

    delete: (input) => HttpClient.post(API_ENDPOINTS.DELETE_INVOICE, input),
    listAdmin: async () => {
      const response = await HttpClient.get(API_ENDPOINTS.INVOICES_ADMIN);
      return response.normal_invoices.map((invoice) => ({
        ...invoice,
        created_at: invoice.created_at ? new Date(invoice.created_at) : null
      }))
    },
    editAdmin: (input) =>
      HttpClient.post(API_ENDPOINTS.EDIT_INVOICES_ADMIN, input),
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_INVOICE, input),
    creatorList: () => HttpClient.get(API_ENDPOINTS.CREATOR_INVOICES),
    createPaypalPayment: (input) => HttpClient.post(API_ENDPOINTS.CREATE_PAYPAL, input),
    action: ({ invoiceId, action }) =>
      HttpClient.post(API_ENDPOINTS.INVOICE_ACTION(invoiceId), { action }),
    clientAction: ({ invoiceId, action }) =>
      HttpClient.post(API_ENDPOINTS.CLIENT_INVOICE_ACTION(invoiceId), { action }),
    editInvoice: (input) => HttpClient.put(API_ENDPOINTS.EDIT_INVOICE, input),
    confirmInvoice: (payload) => HttpClient.put(API_ENDPOINTS.INVOICE_CONFIRM, payload),
    listCreatorAll: async () => {
      const response = await HttpClient.get(API_ENDPOINTS.CREATOR_INVOICES_ADMIN);
      return response.invoices.map((invoice) => ({
        ...invoice,
        invoice_created_at: invoice.invoice_created_at ? new Date(invoice.invoice_created_at) : null
      }));
    },
    pendingInvoices: (userId) => HttpClient.get(API_ENDPOINTS.PENDING_INVOICES(userId))
  };

  companies = {
    list: () => HttpClient.get(API_ENDPOINTS.COMPANY),
    create: (input) => HttpClient.post(API_ENDPOINTS.COMPANY, input),
    delete: (input) => HttpClient.post(API_ENDPOINTS.DELETE_COMPANY, input),
    edit: (input) =>
      HttpClient.post(
        `${API_ENDPOINTS.COMPANY}/${input.companyId}`,
        input.params
      ),
    listFetch: () => HttpClient.get(API_ENDPOINTS.FETCH_COMPANY_DATA),
    listUsers: () => HttpClient.get(API_ENDPOINTS.USERS_COMPANY),
    demoRegister: (input) => HttpClient.post(API_ENDPOINTS.DEMO_REGISTER, input),
    invoice: (companyId, statusFilter) => {
      // If statusFilter is provided, append ?status=XYZ
      let url = API_ENDPOINTS.COMPANY_INVOICES(companyId);
      if (statusFilter) {
        url += `?status=${statusFilter}`;
      }

      return HttpClient.get(url);
    },
  };

  partnerships = {
    list: () => HttpClient.get(API_ENDPOINTS.PARTNERSHIPS),
    fetchPartnership: async (id) => {
      const data = await HttpClient.get(`${API_ENDPOINTS.PARTNERSHIPS_FETCH}/${id}`);
      return {
        ...data,
        partnership: {
          ...data.partnership,
          finish_date: data.partnership.finish_date ? new Date(data.partnership.finish_date) : null,
          paid_date: data.partnership.paid_date ? new Date(data.partnership.paid_date) : null,
          proposal_date: data.partnership.proposal_date ? new Date(data.partnership.proposal_date) : null,
          invoice_date: data.partnership.invoice_date ? new Date(data.partnership.invoice_date) : null,
        }
      }

    },
    updateStatus: (id, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_PARTNERSHIP_STATUS}/${id}/status`, input),
    delete: (id) => HttpClient.delete(`${API_ENDPOINTS.DELETE_PARTNERSHIP}/${id}`),
    getAdminDetails: () => HttpClient.get(API_ENDPOINTS.ADMIN_PARTNERSHIPS),
    getDeliverables: (id, creatorId) => HttpClient.get(API_ENDPOINTS.PARTNERSHIP_DELIVERABLES(id, creatorId)),
    book: (input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIPS_PUBLIC, input),
    share: (id, input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIPS_SHARE(id), input),
  };

  conversations = {
    createCampaign: (input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS_CREATE_CAMPAIGN, input),
    createPartnership: (input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS_CREATE_PARTNERSHIP, input),
    checkCampaign: (input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS_CHECK_CAMPAIGN, input),
    checkUserToUser: (input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS_CHECK_USER_TO_USER, input),
    checkUserToCreator: (input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS_CHECK_USER_TO_CREATOR, input),

    getMessages: (conversationId, input) => HttpClient.get(API_ENDPOINTS.MESSAGES(conversationId), input),
    sendMessage: (id, input) => HttpClient.post(API_ENDPOINTS.MESSAGES(id), input),
    deleteMessage: (id) => HttpClient.delete(API_ENDPOINTS.DELETE_MESSAGES(id)),
    fetchUserToUser: () => HttpClient.get(API_ENDPOINTS.CONVERSATIONS_GET_USER_TO_USER),
    share: (id, input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS_SHARE_USER_TO_USER(id), input),
    inviteByEmail: (id, input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS_SHARE_BY_EMAIL_USER_TO_USER(id), input),
    fetchCreatorConversations: () =>
      HttpClient.get(API_ENDPOINTS.FETCH_CREATOR_CONVERSATIONS), // your new route
    getUnreadCountForCampaignCreator: (campaignId, creatorId) =>
      HttpClient.get(
        API_ENDPOINTS.CONVERSATIONS_UNREAD_COUNT_FOR_CAMPAIGN_CREATOR(
          campaignId,
          creatorId
        )
      ),
    updateConversationDisplayName: (conversationId, displayName) =>
      HttpClient.post(
        API_ENDPOINTS.CONVERSATIONS_UPDATE_NAME_USER_TO_USER(conversationId),
        { display_name: displayName }
      ),
  };

  commissions = {
    list: () => HttpClient.get(API_ENDPOINTS.COMMISSIONS),
    getAll: () => HttpClient.get(API_ENDPOINTS.COMMISSIONS_All),
  };

  deliverables = {
    list: () => HttpClient.get(API_ENDPOINTS.DELIVERABLES),
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_DELIVERABLES, input),
    update: (id, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_DELIVERABLES}/${id}`, input), // Updated
    getByPartnership: (partnershipId) => HttpClient.get(`${API_ENDPOINTS.GET_DELIVERABLE_PARTNERSHIPS}/${partnershipId}`, undefined, { accept404: true }),
  };

  miscProjects = {
    list: () => HttpClient.get(API_ENDPOINTS.MISC_PROJECTS),
    create: (input) => HttpClient.post(API_ENDPOINTS.MISC_PROJECTS_CREATE, input),
    // fetch: (id) => HttpClient.get(API_ENDPOINTS.PARTNERSHIPS_FETCH(id)),
    // updateStatus: (id, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_PARTNERSHIP_STATUS}/${id}/status`, input),
    // delete: (id) => HttpClient.delete(`${API_ENDPOINTS.DELETE_PARTNERSHIP}/${id}`),
  };

  files = {
    post: (input) => HttpClient.post(API_ENDPOINTS.POST_FILES, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.FETCH_FILES),
    fetchContracts: () => HttpClient.get(API_ENDPOINTS.FETCH_CONTRACTS),

    createAsset: (input) => HttpClient.post(API_ENDPOINTS.CREATE_ASSET, input),
    post_creator_pfp: (input) => HttpClient.post(API_ENDPOINTS.post_creator_pfp, input),
    postCreatorFile: (input) => HttpClient.post(API_ENDPOINTS.POST_FILES_CREATOR, input),
    createAsset: (input) => HttpClient.post(API_ENDPOINTS.CREATE_ASSET, input),
    createContract: (input) => HttpClient.post(API_ENDPOINTS.CREATE_CONTRACT, input), // New method
    getContract: (id) => HttpClient.get(API_ENDPOINTS.GET_CONTRACT.SPEC_CONTRACT(id)),
    updateContract: (id, data) => HttpClient.put(
      API_ENDPOINTS.SPEC_CONTRACT(id),
      data
    ),
    getFile: (id) => HttpClient.get(API_ENDPOINTS.SPEC_FILE(id)),

    updateFile: (id, data) => HttpClient.post(API_ENDPOINTS.SPEC_FILE_UPDATE(id), data),
  };

  trackers = {
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_TRACKERS, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.FETCH_TRACKERS),
    update: (trackerId, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_TRACKERS}/${trackerId}`, input),
    exportSheet: (input) => HttpClient.post(API_ENDPOINTS.EXPORT_SHEET_TRACKER, input),

  };

  agency = {
    getCreator: async (creatorId) => {
      const data = await HttpClient.get(API_ENDPOINTS.AGENCY_CREATOR(creatorId));
      return {
        ...data,
        campaigns: data.campaigns.map((campaign) => ({
          ...campaign,
          ideal_duedate: new Date(campaign.ideal_duedate),
        })),
        conversations: data.conversations.map((conversation) => ({
          ...conversation,
          created_at: new Date(conversation.created_at),
        })),
        partnerships: data.partnerships.map((partnership) => ({
          ...partnership,
          finish_date: partnership.finish_date ? new Date(partnership.finish_date) : null,
          paid_date: partnership.paid_date ? new Date(partnership.paid_date) : null,
          proposal_date: partnership.proposal_date ? new Date(partnership.proposal_date) : null,
          invoice_date: partnership.invoice_date ? new Date(partnership.invoice_date) : null,
        })),
        payouts: data.payouts.map((payout) => ({
          ...payout,
          payout_date: new Date(payout.payout_date),
        })),
      };
    },

    getZapfeed: async (input) => {
      const data = await HttpClient.get(API_ENDPOINTS.AGENCY_ZAPFEED, input);
      return {
        ...data,
        zapfeed: data.zapfeed.map((entry) => ({
          ...entry,
          activity_date: new Date(entry.activity_date),
        })),
      };
    },

    editCreator: (creatorId, input) => HttpClient.put(API_ENDPOINTS.AGENCY_CREATOR(creatorId), input),
    payouts: () => HttpClient.get(API_ENDPOINTS.AGENCY_PAYOUTS),
    dashboard: () => HttpClient.get(API_ENDPOINTS.AGENCY_DASHBOARD),

    campaignAccept: (campaignId, input) => HttpClient.post(API_ENDPOINTS.AGENCY_CAMPAIGN_CREATOR_ACCEPT(campaignId), input),

    campaignUpdateFields: (campaignId, input) => HttpClient.post(API_ENDPOINTS.AGENCY_CAMPAIGN_CREATOR_UPDATE_FIELDS(campaignId), input),

    approvePayment: (payoutId, input) => HttpClient.post(API_ENDPOINTS.AGENCY_PAYOUT_APPROVE(payoutId), input),

    getRosterSettings: (agencyName) => HttpClient.get(API_ENDPOINTS.AGENCY_ROSTER_SETTINGS(agencyName)),

    createRosterSettings: (agencyName, data) => HttpClient.post(API_ENDPOINTS.AGENCY_ROSTER_SETTINGS(agencyName), data),

    updateRosterSettings: (agencyName, data) => HttpClient.put(API_ENDPOINTS.AGENCY_ROSTER_SETTINGS(agencyName), data),

    getPublicRosterSettings: (agencyName) => HttpClient.get(API_ENDPOINTS.PUBLIC_AGENCY_ROSTER_SETTINGS(agencyName)),

    campaignNegotiate: (campaignId, input) => HttpClient.post(API_ENDPOINTS.AGENCY_CAMPAIGN_NEGOTIATE(campaignId), input),
  };



  social = {
    follow: (data) => HttpClient.post(API_ENDPOINTS.SOCIAL_FOLLOW, data),
    unfollow: (username) => HttpClient.delete(`${API_ENDPOINTS.SOCIAL_UNFOLLOW}/${username}`),
    getFollowers: (username) => HttpClient.get(`${API_ENDPOINTS.FETCH_FOLLOWERS}/${username}`),
    getFollowing: (username) => HttpClient.get(`${API_ENDPOINTS.FETCH_FOLLOWING}/${username}`),
    isFollowing: (username) => HttpClient.get(`${API_ENDPOINTS.SOCIAL_IS_FOLLOWING}/${username}`),
  };

  emailCampaigns = {
    create: (input) => HttpClient.post(API_ENDPOINTS.INTERNAL_EMAIL_CAMPAIGNS_CREATE, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.INTERNAL_EMAIL_CAMPAIGNS),
  };

  castingCalls = {
    list: async (input) => {
      const response = await HttpClient.get(API_ENDPOINTS.CASTING_CALLS_LIST, input)
      return ({
        ...response,
        casting_calls: response.casting_calls.map(call => ({
          ...call,
          date_created: call.date_created ? new Date(call.date_created) : null,
          date_updated: call.date_updated ? new Date(call.date_updated) : null
        }))
      });
    },
    updateStatus: (id, status) => HttpClient.put(`${API_ENDPOINTS.CASTING_CALLS_UPDATE_STATUS}/${id}`, { status }),
    invite: (id, input) => HttpClient.post(API_ENDPOINTS.CASTING_CALL_INVITE(id), input),
    postNew: (input) => HttpClient.post(API_ENDPOINTS.CASTING_CALLS_POST_NEW, input),
    creatorFetch: async (input) => {
      const response = await HttpClient.get(API_ENDPOINTS.creatorCastingCalls, input)
      return ({
        ...response,
        casting_calls: response.casting_calls.map(call => ({
          ...call,
          date_created: call.date_created ? new Date(call.date_created) : null,
          date_updated: call.date_updated ? new Date(call.date_updated) : null
        }))
      });
    },
    creatorApply: (id) => HttpClient.post(`${API_ENDPOINTS.CASTING_CREATOR_APPLY}/${id}`),
    getById: (id) => HttpClient.get(API_ENDPOINTS.CASTING_CALL_SPEC(id)),

  };

  blocklist = {
    list: () => HttpClient.get(API_ENDPOINTS.BLOCKLIST),
    create: (input) => HttpClient.post(API_ENDPOINTS.BLOCKLIST, input),
    delete: (mail) => HttpClient.delete(API_ENDPOINTS.BLOCKED_CONTACT(mail)),
  };

  collections = {
    list: () => HttpClient.get(API_ENDPOINTS.collections),
    create: (input) => HttpClient.post(API_ENDPOINTS.collections_post, input),
    delete: (input) => HttpClient.delete(API_ENDPOINTS.collections_delete(input)),
    popular: () => HttpClient.get(API_ENDPOINTS.collectionsPopular),

  };

  dealsIO = {
    create: (input) => HttpClient.post(API_ENDPOINTS.DEAL_IOS, input),
    list: () => HttpClient.get(API_ENDPOINTS.DEAL_IOS),
    fetch: (dealIOId) => HttpClient.get(API_ENDPOINTS.DEAL_IO_DETAIL(dealIOId)),
    update: (dealIOId, input) => HttpClient.put(API_ENDPOINTS.DEAL_IO_DETAIL(dealIOId), input),
    delete: (dealIOId) => HttpClient.delete(API_ENDPOINTS.DEAL_IO_DETAIL(dealIOId)),
    copy: (dealIOId) => HttpClient.post(API_ENDPOINTS.DEAL_IO_COPY(dealIOId)),
    share: (dealIOId, input) => HttpClient.post(API_ENDPOINTS.DEAL_IO_SHARE(dealIOId), input), // New share functionality
    creatorFetch: () => HttpClient.get(API_ENDPOINTS.CREATOR_DEAL_FETCH),
    creatorSpecfetch: (dealIOId) => HttpClient.get(API_ENDPOINTS.CREATOR_DEAL_IO_DETAIL(dealIOId)),
    creatorUpdate: (dealIOId, input) => HttpClient.put(API_ENDPOINTS.CREATOR_DEAL_IO_DETAIL(dealIOId), input),
  };

  withdrawals = {
    adminList: async (input) => {
      const response = await HttpClient.get(API_ENDPOINTS.WITHDRAWALS_ADMIN, input)
      return ({
        ...response,
        withdrawals: response.withdrawals.map(wdw => ({
          ...wdw,
          created_at: wdw.created_at ? new Date(wdw.created_at) : null,
          approved_at: wdw.approved_at ? new Date(wdw.approved_at) : null,
          payed_at: wdw.payed_at ? new Date(wdw.payed_at) : null
        }))
      });
    },
    adminGet: async (id) => {
      const wdw = await HttpClient.get(API_ENDPOINTS.WITHDRAWALS_SPEC_ADMIN(id))
      return ({
        ...wdw,
        created_at: wdw.created_at ? new Date(wdw.created_at) : null,
        approved_at: wdw.approved_at ? new Date(wdw.approved_at) : null,
        payed_at: wdw.payed_at ? new Date(wdw.payed_at) : null
      });
    },
    adminUpdate: (id, input) => HttpClient.put(API_ENDPOINTS.WITHDRAWALS_SPEC_ADMIN(id), input),
  };

  developer = {
    serviceList: async (parameters = null) => {
      const response = await HttpClient.get(API_ENDPOINTS.DEVELOPER_SERVICE, parameters)
      return response.map(service => ({
        ...service,
        date_created: service.date_created ? new Date(service.date_created) : null,
        date_updated: service.date_updated ? new Date(service.date_updated) : null,
        date_approved: service.date_approved ? new Date(service.date_approved) : null,
        date_approval_updated: service.date_approval_updated ? new Date(service.date_approval_updated) : null
      }));
    },
    apikeyList: async () => {
      const response = await HttpClient.get(API_ENDPOINTS.DEVELOPER_KEY)
      return response.map(service => ({
        ...service,
        date_created: service.date_created ? new Date(service.date_created) : null,
      }));
    },
    apikeyCreate: (input) => HttpClient.post(API_ENDPOINTS.DEVELOPER_KEY, input),
    apikeyDelete: (id) => HttpClient.delete(API_ENDPOINTS.DEVELOPER_KEY_SPEC(id)),
    serviceCreate: (input) => HttpClient.post(API_ENDPOINTS.DEVELOPER_SERVICE, input),
    serviceUpdate: (id, input) => HttpClient.put(API_ENDPOINTS.DEVELOPER_SERVICE_SPEC(id), input),
  };

  developerPublic = {
    subscriptionGet: (id) => HttpClient.get(API_ENDPOINTS.DEVELOPER_SERVICE_SUBBED(id), null, { accept404: true }),
    subscriptionPost: (id) => HttpClient.post(API_ENDPOINTS.DEVELOPER_SERVICE_SUBBED(id)),
    subscriptionLog: (id) => HttpClient.post(API_ENDPOINTS.DEVELOPER_SERVICE_LOG(id)),
    subscriptionUpdate: (id, input) => HttpClient.post(API_ENDPOINTS.DEVELOPER_SERVICE_UPDATE(id), input),
    serviceGet: (id) => HttpClient.get(API_ENDPOINTS.DEVELOPER_SERVICE_PUBLIC_SPEC(id)),
    serviceGetAll: (input) => HttpClient.get(API_ENDPOINTS.DEVELOPER_SERVICE_PUBLIC, input),
  };

  bank = {
    // -------------------------------
    // PLAID (Existing)
    // -------------------------------
    /**
     * GET /plaid/create_link_token/creator
     * Retrieve a link_token for the front end to open Plaid Link (Creator).
     */
    getLinkTokenCreator: (username) =>
      HttpClient.get(
        `${API_ENDPOINTS.PLAID_CREATE_LINK_TOKEN_CREATOR}?username=${encodeURIComponent(username)}`
      ),

    /**
     * GET /plaid/create_link_token/user
     * Retrieve a link_token for a user (not a creator).
     */
    getLinkTokenUser: () => HttpClient.get(API_ENDPOINTS.PLAID_CREATE_LINK_TOKEN_USER),

    /**
     * POST /plaid/link/creator
     * Exchange a public_token for an access_token and link to a creator.
     * input: { public_token, entity_type, entity_id }
     */
    creatorLink: (input) => HttpClient.post(API_ENDPOINTS.PLAID_LINK_CREATOR, input),

    /**
     * POST /plaid/link/user
     * Exchange a public_token for an access_token and link to a user.
     */
    userLink: (input) => HttpClient.post(API_ENDPOINTS.PLAID_LINK_USER, input),

    /**
     * GET /plaid/transactions
     * Fetch transactions for a given bank_account_id (and optional date range).
     */
    getTransactions: (params) =>
      HttpClient.get(API_ENDPOINTS.PLAID_TRANSACTIONS, { params }),

    /**
     * POST /plaid/send_transfer
     * Example of sending a transfer using Plaid's Transfer product.
     * input: { bank_account_id, amount, description? }
     */
    sendTransfer: (input) => HttpClient.post(API_ENDPOINTS.PLAID_SEND_TRANSFER, input),

    // -------------------------------
    // STRIPE (NEW)
    // -------------------------------
    /**
     * GET /creator/stripe/bank_accounts
     * Retrieve already-linked Stripe payment methods for a given entity.
     * input: { entity_type, entity_id }
     */
    getStripeBankAccounts: ({ entity_type, entity_id }) =>
      HttpClient.get(
        `${API_ENDPOINTS.STRIPE_GET_BANK_ACCOUNTS}?entity_type=${entity_type}&entity_id=${entity_id}`
      ),

    /**
     * POST /creator/stripe/link
     * Attach a new Stripe PaymentMethod to a user/creator, store it in DB.
     * input: { entity_type, entity_id, stripe_customer_id, payment_method_id }
     */
    linkStripeBankAccount: (payload) =>
      HttpClient.post(API_ENDPOINTS.STRIPE_LINK, payload),
    createStripeSetupIntent: (stripe_customer_id) =>
      HttpClient.post(API_ENDPOINTS.STRIPE_CREATOR_SETUP_INTENT, { stripe_customer_id }),

  };

  creditLedger = {
    fetch: (companyId) => HttpClient.get(API_ENDPOINTS.CREDIT_FETCH(companyId)),
    approve: (input) => HttpClient.post(API_ENDPOINTS.BANK_CREDIT_REQUEST_APPROVE, input),
    postRequest: (input) => HttpClient.post(API_ENDPOINTS.BANK_CREDIT_REQUEST, input),
    systemLiquidity: {
      get: () => HttpClient.get(API_ENDPOINTS.BANK_SYSTEM_LIQUID),
      update: (input) => HttpClient.post(API_ENDPOINTS.BANK_SYSTEM_LIQUID, input),
    },
  };

  // Add this to your index file where you have other API clients
  surveys = {
    list: () => HttpClient.get(API_ENDPOINTS.LIST_SURVEYS),
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_SURVEY, input),
    listPending: (userId) => HttpClient.get(API_ENDPOINTS.LIST_PENDING_SURVEY(userId)),
    createResponse: (input) => HttpClient.post(API_ENDPOINTS.CREATE_SURVEY_RESPONSE, input),
    update: (surveyId, input) => HttpClient.put(API_ENDPOINTS.UPDATE_SURVEY(surveyId), input),
    delete: (surveyId) => HttpClient.delete(API_ENDPOINTS.DELETE_SURVEY(surveyId)),
    getResponses: (surveyId) => HttpClient.get(API_ENDPOINTS.GET_SURVEY_RESPONSES(surveyId)), // New method

  };

  blitzbolts = {
    list: () => HttpClient.get(API_ENDPOINTS.LIST_BLITZBOLTS),
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_BLITZBOLT, input),
    listForUser: (userId) => HttpClient.get(API_ENDPOINTS.LIST_BLITZBOLTS_FOR_USER(userId)), // New method
    getAchievement: (userId, attainmentId) =>
      HttpClient.get(API_ENDPOINTS.GET_BLITZBOLT_ACHIEVEMENT(userId, attainmentId)),
    shareUrl: (userId, attainmentId) =>
      `${window.location.origin}${API_ENDPOINTS.SHARE_BLITZBOLT(userId, attainmentId)}`
  };
  notifications = {
    getUnreadCount: () => HttpClient.get(API_ENDPOINTS.NOTIFICATIONS_UNREAD_COUNT),
    listAll: (unreadOnly = false) =>
      HttpClient.get(API_ENDPOINTS.NOTIFICATIONS, { unread: unreadOnly ? 1 : 0 }),
    markAsRead: (notificationId) =>
      HttpClient.put(`${API_ENDPOINTS.NOTIFICATIONS}/${notificationId}/read`),
    createNotification: (payload) =>
      HttpClient.post(API_ENDPOINTS.POST_ALL_NOTIFICATIONS, payload),
    getUnreadCollabCount: () =>
      HttpClient.get(API_ENDPOINTS.NOTIFICATIONS_UNREAD_COLLAB_COUNT), // New function
  };

   bio = {
    // Get all bio pages for the authenticated creator
    list: () => HttpClient.get(API_ENDPOINTS.BIO),
  
    // Create a new bio page. Expects a JSON object with fields like slug, title, description, theme, and optionally items.
    create: (input) => HttpClient.post(API_ENDPOINTS.BIO, input),
  
    // Update an existing bio page using its slug. For example, update bio page with slug 'mybio'
    update: (slug, input) => HttpClient.put(`${API_ENDPOINTS.BIO}${slug}`, input),
  
    // Public fetch of a bio page. Uses the "@" prefix. For example, if slug is 'mybio', this hits GET /@mybio.
    getPublic: (slug) =>
      HttpClient.get(`${API_ENDPOINTS.PUBLIC_BIO}/${slug}`, undefined, { accept404: true }),
  };

}

export default new Client();
