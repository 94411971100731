// ParticipantsPanel.js
import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import API from '../../../API';

// Helper to format the "last_online" field into a friendly string
function formatLastOnline(lastOnline) {
  if (!lastOnline) return 'Never logged in';
  const now = new Date();
  const dt = new Date(lastOnline);
  const diffMs = now - dt;

  if (diffMs < 0) return 'Unknown'; // In case the time is in the future

  // Convert ms to minutes
  const diffMins = Math.floor(diffMs / 60000);
  if (diffMins < 1) return 'Online now';
  if (diffMins < 60) return `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;

  const diffHours = Math.floor(diffMins / 60);
  if (diffHours < 24) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;

  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 7) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;

  // If older than a week, just return the date
  return dt.toLocaleDateString();
}

export default function ParticipantsPanel({ conversationId, onClose }) {
  const queryClient = useQueryClient();
  const [inviteEmails, setInviteEmails] = useState('');

  // 1) Fetch conversation data – shape: { participants: [...], messages: [...] }
  //    Each participant now has: { id, username, pfp, last_online, is_creator }
  const {
    data: conversationData,
    isLoading,
    isError,
    error,
  } = useQuery(
    ['messages', conversationId],
    () => API.conversations.getMessages(conversationId),
    {
      enabled: !!conversationId
    }
  );

  // Extract brand participants & creator from conversationData
  const participants = conversationData?.participants || [];
  const creator = participants.find((p) => p.is_creator);
  const brandUsers = participants.filter((p) => !p.is_creator);

  // Gather attachments from messages (if each msg.attachments is a single object, not an array)
  const allAttachments = useMemo(() => {
    if (!conversationData?.messages) return [];
    const attachments = [];
    conversationData.messages.forEach((msg) => {
      if (msg.attachments) attachments.push(msg.attachments);
    });
    return attachments;
  }, [conversationData]);

  // Gather deliverables from messages
  const deliverables = useMemo(() => {
    if (!conversationData?.messages) return [];
    return conversationData.messages
      .filter((msg) => !!msg.deliverable)
      .map((msg) => msg.deliverable);
  }, [conversationData]);

  // 3) “Invite by email” mutation
  const inviteByEmailMutation = useMutation(
    (payload) => API.conversations.inviteByEmail(conversationId, payload),
    {
      onSuccess: () => {
        // Refresh data so new participants appear
        queryClient.invalidateQueries(['messages', conversationId]);
        setInviteEmails('');
      },
      onError: (err) => {
        console.error('Error inviting participants:', err);
      }
    }
  );

  // Helper to invite from comma-separated string
  const handleInviteClick = () => {
    const trimmed = inviteEmails.trim();
    if (!trimmed) return;
    const emailsArray = trimmed
      .split(',')
      .map((email) => email.trim())
      .filter(Boolean);

    // e.g. { emails: [...], etc. }
    inviteByEmailMutation.mutate({ emails: emailsArray });
  };

  return (
    <Box
      sx={{
        width: { xs: '80vw', sm: '400px' },
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Conversation Details</Typography>
        <Button variant="contained" onClick={onClose}>Close</Button>
      </Box>

      {/* Loading / Error states */}
      {isLoading && <CircularProgress />}
      {isError && (
        <Typography color="error">
          Error: {error?.message || 'Failed to load conversation details'}
        </Typography>
      )}

      {/* Main scrollable area */}
      <Box sx={{ flex: 1, overflowY: 'auto', mb: 2 }}>
        {/* Section: Creator */}
        {creator && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
              Creator
            </Typography>
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar src={creator.pfp || ''} alt={creator.username?.charAt(0) || 'C'} />
              </ListItemAvatar>
              <ListItemText
                primary={creator.username || 'No Name'}
                secondary={
                  'Last online: ' + formatLastOnline(creator.last_online)
                }
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
          </>
        )}

        {/* Section: Brand Participants */}
        <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
          Brand Participants
        </Typography>
        <List disablePadding>
          {brandUsers.map((p) => (
            <React.Fragment key={p.id}>
              <ListItem disableGutters>
                <ListItemAvatar>
                  <Avatar src={p.pfp || ''} alt={p.username?.charAt(0) || ''} />
                </ListItemAvatar>
                <ListItemText
                  primary={p.username || 'No Name'}
                  secondary={
                    'Last online: ' + formatLastOnline(p.last_online)
                  }
                />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>

        {/* Section: Attachments Gallery */}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Attachments ({allAttachments.length})
        </Typography>
        {allAttachments.length === 0 && (
          <Typography variant="body2" color="text.secondary">
            No attachments
          </Typography>
        )}
        {allAttachments.length > 0 && (
          <ImageList cols={2} gap={8} sx={{ mt: 1, mb: 2 }}>
            {allAttachments.map((attach, index) => {
              const isImage = attach.file_type === 'image'
                || (attach.filename || '').match(/\.(png|jpe?g|gif)$/i);
              return (
                <ImageListItem key={index}>
                  {isImage ? (
                    <img
                      src={attach.file_url}
                      alt={attach.filename || 'Attachment'}
                      loading="lazy"
                      style={{ maxHeight: 180, objectFit: 'cover' }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: '#f5f5f5',
                        border: '1px dashed #ccc',
                      }}
                    >
                      <Typography variant="caption">
                        {attach.filename || 'Non-image attachment'}
                      </Typography>
                    </Box>
                  )}
                  <ImageListItemBar
                    title={attach.filename || attach.attachment_type}
                    subtitle={attach.file_type}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        )}

        {/* Section: Deliverables Summary */}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Deliverables ({deliverables.length})
        </Typography>
        {deliverables.length === 0 && (
          <Typography variant="body2" color="text.secondary">
            No deliverables yet
          </Typography>
        )}
        {deliverables.length > 0 && (
          <List>
            {deliverables.map((deliv, idx) => (
              <React.Fragment key={deliv.id || idx}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={deliv.name || `Deliverable #${deliv.id || idx}`}
                    secondary={`Status: ${deliv.status || 'unknown'}`}
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>

      {/* Invite new participants (same as before) */}
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Invite by email (comma-separated):
      </Typography>
      <TextField
        label="Enter emails"
        variant="outlined"
        fullWidth
        value={inviteEmails}
        onChange={(e) => setInviteEmails(e.target.value)}
        placeholder="john@example.com, jane@example.com"
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleInviteClick}
        disabled={inviteByEmailMutation.isLoading || !inviteEmails.trim()}
      >
        {inviteByEmailMutation.isLoading ? 'Inviting...' : 'Invite'}
      </Button>
    </Box>
  );
}
