import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  IconButton,
  styled,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ListItemButton,
  Toolbar,
  Badge
} from "@mui/material";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WorkIcon from "@mui/icons-material/Work";
import SendIcon from "@mui/icons-material/Send";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
// NEW: Cool personable icon for Bio
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useCreatorAuth } from "../Hooks/creator-use-auth.js";
import ListItemLink from "./ListItemLink.js";
import routes from "../Config/routes.js";
import API from "../API"; // <-- Make sure this points to your API directory
import { drawerWidth } from "../Utils/constants.js";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} - 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} - 1px)`,
  },
});

const IconDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "extended",
})(({ theme, extended }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(extended && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!extended && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function CreatorNavbar({ isDesktop = true, isOpen = true, onClose }) {
  const { creatorToken } = useCreatorAuth();
  const navigate = useNavigate();

  // 1) Fetch dashboard data to get the count of unread messages
  const {
    data: dashboard,
    isLoading: isDashboardLoading,
    error: dashboardError,
  } = useQuery(
    ["creator", creatorToken?.creator_user?.username, "dashboard"],
    API.creatorConnect.dashboard,
    {
      enabled: !!creatorToken,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
    }
  );

  const unreadCount = dashboard?.unreadMessages?.length || 0;

  // Agency logic (if needed)
  const agency = creatorToken?.creator_user?.agency;
  const isAgencyRestricted = (agency) => {
    if (!agency || agency === "TCC") {
      return false;
    }
    return true;
  };

  // 2) Build the links array, injecting the unread count on the Chat icon.
  // Added the new Bio entry below.
  const links = [
    { text: "Dashboard", route: routes.creatorConnectStart, icon: <DashboardIcon /> },
    { text: "Money", route: routes.creatorConnectPayoutsInvoicing, icon: <AttachMoneyIcon /> },
    { text: "Deals", route: routes.creatorCollabs, icon: <WorkIcon /> },
    {
      text: "Chat",
      route: routes.creatorConvos,
      icon: (
        <Badge badgeContent={unreadCount} color="primary">
          <ChatBubbleOutlineIcon />
        </Badge>
      ),
    },
    { text: "Apps", route: routes.creatorConnectApps, icon: <AppsIcon /> },
    { text: "Sales", route: routes.creatorConnectPitch, icon: <SendIcon /> },
    // NEW: The Bio entry with a personable icon.
    { text: "Bio", route: routes.creatorbio, icon: <AccountCircleIcon /> },
  ];

  // 3) If agency is restricted, filter out some items.
  const filteredLinks = links.filter((item) => {
    if ((item.text === "Deals" || item.text === "Sales") && isAgencyRestricted(agency)) {
      return false;
    }
    return true;
  });

  const isExtended = !isDesktop || isOpen;

  return (
    <IconDrawer
      id="NavbarContainer"
      variant={isDesktop ? "permanent" : "temporary"}
      sx={{
        position: "relative",
        zIndex: (theme) => (isDesktop ? theme.zIndex.appBar - 1 : theme.zIndex.drawer),
        color: "#000 !important",
        "& .MuiDrawer-paper": {
          color: "#000 !important",
          backgroundColor: "#FFF !important",
          overflowY: { xs: "auto", md: "hidden" },
        },
      }}
      open={isDesktop || isOpen}
      extended={isExtended}
      onClose={onClose}
    >
      {isDesktop && <Toolbar></Toolbar>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "stretch",
          color: "inherit",
          cursor: "auto",
          minHeight: "48px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginBlockStart: { xs: 2, md: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              marginInline: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: isExtended ? "space-between" : "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  maxWidth: isExtended ? "auto" : 0,
                  overflowX: "clip",
                }}
              >
                <Typography variant="subtitle1">
                  <b>Hey, {creatorToken?.creator_user.username}!</b>
                </Typography>
              </Box>
              <Tooltip title={"Modify your account settings"} placement="right">
                <IconButton onClick={() => navigate(routes.creatorConnectEdit)} color="inherit">
                  <SettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box
        id="Icon-List"
        sx={{
          flex: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          id="Scrollable-Icon-List"
          sx={{
            flexGrow: 1,
            flexShrink: { xs: 0, md: 1 },
            minHeight: 0,
            overflowY: { xs: "visible", md: isExtended ? "auto" : "clip" },
            overflowX: "clip",
          }}
        >
          <Box sx={{ maxHeight: "max-content", minHeight: "max-content" }}>
            <List>
              {filteredLinks.map((item) => (
                <ListItem key={item.route} disablePadding sx={{ display: "block" }}>
                  <ListItemLink to={item.route} primary={item.text} icon={item.icon} isOpen={isOpen} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box>
          <Divider />
          <ListItem disablePadding component={"div"}>
            <ListItemButton onClick={() => navigate("/help")}>
              <ListItemIcon sx={{ minWidth: "36px", color: "inherit" }}>
                <LiveHelpOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.2rem" }}
                sx={{ opacity: isExtended ? 1 : 0 }}
                primary="Help"
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </Box>
    </IconDrawer>
  );
}
