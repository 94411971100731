import React from "react";
import { Avatar, Card, CardContent, Grid, Typography, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import client from "../../../API"; // Assumes you have a users.fetchMyTeam method

const MyTeam = () => {
  // Fetch the team members (all users belonging to the same company)
  const { data: teamData, isLoading, error } = useQuery("team", client.users.fetchMyTeam);

  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Typography color="error">Error loading team data</Typography>;
  }

  // Assuming all team members are from the same company, extract the company name from the first member
  const companyName = teamData && teamData.length > 0 ? teamData[0].company_name : "";

  return (
    <Grid container spacing={2}>
      {teamData &&
        teamData.map((member) => (
          <Grid item xs={12} sm={6} md={4} key={member.id}>
            <Link to={`/user/${member.id}`} style={{ textDecoration: "none", color: "inherit" }}>
              <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Avatar src={member.phphref} sx={{ width: 80, height: 80, mb: 2 }} />
                  <Typography variant="h6">
                    {member.first_name} {member.last_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {member.company_name}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      {/* Add the plus card to register a new team member */}
      <Grid item xs={12} sm={6} md={4}>
        <Link
          to={`/register/${companyName}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card sx={{ borderRadius: 3, boxShadow: 3, height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <AddIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="body1">Add Team Member</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </Grid>
  );
};

export default MyTeam;
