// CreatorBio.js
import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Divider,
  IconButton,
  List,
  ListItem,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import API from '../../../../API'; // Adjust path to your API index with "bio" endpoints
import AlertDialog from '../../../../Components/AlertDialog';
import useAlertDialog from '../../../../Components/useAlertDialog';
import { BioPageContainer } from './previewhelper';
import ColorPickerGrid from './colorpickergrid'; // Import the new component

export default function CreatorBio() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // 1. Fetch existing pages
  const {
    data: pages,
    isLoading: isPagesLoading,
    error: pagesError,
  } = useQuery(['creatorBioPages'], API.bio.list, {
    refetchOnWindowFocus: false,
  });

  // 2. Local state for "selected" page data
  const [isNewPage, setIsNewPage] = useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = useState(-1);
  const [slug, setSlug] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [theme, setTheme] = useState({
    backgroundColor: '',
    foregroundColor: '',
  });
  const [items, setItems] = useState([]);

  // 3. Mutations for create/update
  const createMutation = useMutation((newPageData) => API.bio.create(newPageData), {
    onSuccess: () => {
      openDialog('Success', 'Bio page created!', () => {
        closeDialog();
        queryClient.invalidateQueries(['creatorBioPages']);
      });
    },
    onError: (error) => {
      openDialog('Error', error?.response?.data?.error || error.message, closeDialog, closeDialog);
    },
  });

  const updateMutation = useMutation(({ oldSlug, updated }) => API.bio.update(oldSlug, updated), {
    onSuccess: () => {
      openDialog('Success', 'Bio page updated!', () => {
        closeDialog();
        queryClient.invalidateQueries(['creatorBioPages']);
      });
    },
    onError: (error) => {
      openDialog('Error', error?.response?.data?.error || error.message, closeDialog, closeDialog);
    },
  });

  // 4. When user selects a page from the list, load its data into state
  const handleSelectPage = (index) => {
    if (index === -1) {
      setIsNewPage(true);
      setSelectedPageIndex(-1);
      setSlug('');
      setTitle('');
      setDescription('');
      setIsPublic(true);
      setTheme({ backgroundColor: '', foregroundColor: '' });
      setItems([]);
    } else {
      setIsNewPage(false);
      setSelectedPageIndex(index);
      const page = pages[index];
      setSlug(page.slug);
      setTitle(page.title || '');
      setDescription(page.description || '');
      setIsPublic(page.is_public);
      setTheme(page.theme || { backgroundColor: '', foregroundColor: '' });
      setItems(page.items || []);
    }
  };

  // 5. Add or remove item
  const handleAddItem = () => {
    const newItem = {
      component_type: 'LINK',
      label: 'New Link',
      url: 'https://example.com',
      order_index: items.length,
      is_enabled: true,
    };
    setItems([...items, newItem]);
  };

  const handleRemoveItem = (idx) => {
    const updated = [...items];
    updated.splice(idx, 1);
    setItems(updated);
  };

  const handleUpdateItemField = (idx, field, value) => {
    const updated = [...items];
    updated[idx] = { ...updated[idx], [field]: value };
    setItems(updated);
  };

  // 6. Save changes
  const handleSave = () => {
    if (!slug) {
      openDialog('Validation Error', 'A slug is required.', closeDialog, closeDialog);
      return;
    }
    const payload = {
      slug,
      title,
      description,
      is_public: isPublic,
      theme,
      items,
    };
    if (isNewPage) {
      createMutation.mutate(payload);
    } else {
      const oldSlug = pages[selectedPageIndex].slug;
      updateMutation.mutate({ oldSlug, updated: payload });
    }
  };

  // 7. Live preview
  const renderPreviewItem = (item, idx) => {
    switch (item.component_type) {
      case 'LINK':
        return (
          <Paper key={idx} sx={{ p: 2, mb: 1, width: '100%' }}>
            <Typography variant="h6">{item.label}</Typography>
            <Button variant="contained" onClick={() => window.open(item.url, '_blank')}>
              Visit
            </Button>
          </Paper>
        );
      default:
        return (
          <Paper key={idx} sx={{ p: 2, mb: 1, width: '100%' }}>
            <Typography variant="h6">{item.label}</Typography>
            {item.url && (
              <Button variant="contained" onClick={() => window.open(item.url, '_blank')}>
                View
              </Button>
            )}
          </Paper>
        );
    }
  };

  if (isPagesLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <Typography>Loading your bio pages...</Typography>
      </Box>
    );
  }

  if (pagesError) {
    return (
      <Box p={4}>
        <Typography color="error">
          Error loading bio pages: {pagesError?.response?.data?.error || pagesError.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <AlertDialog alertState={dialogState} />

      <Typography variant="h4" sx={{ mb: 2 }}>
        Manage Your Bio Pages
      </Typography>

      {/* Page selection buttons */}
      <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap' }}>
        {pages?.map((p, i) => (
          <Button
            key={p.id}
            variant={selectedPageIndex === i ? 'contained' : 'outlined'}
            onClick={() => handleSelectPage(i)}
          >
            {p.slug}
          </Button>
        ))}
        <Button
          variant={selectedPageIndex === -1 ? 'contained' : 'outlined'}
          onClick={() => handleSelectPage(-1)}
        >
          + Create New
        </Button>
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
        {/* LEFT: Form inputs */}
        <Paper sx={{ p: 2, width: '400px', maxWidth: '100%' }} elevation={3}>
          <Typography variant="h6" gutterBottom>
            {isNewPage ? 'Create New Bio Page' : 'Edit Bio Page'}
          </Typography>

          <TextField
            label="Slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            fullWidth
            margin="normal"
            disabled={!isNewPage}
          />

          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            minRows={3}
            margin="normal"
          />

          <FormControlLabel
            control={<Switch checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />}
            label="Is Public?"
          />

          <Typography variant="body1" sx={{ mt: 2 }}>
            Theme Settings
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              label="Background Color"
              placeholder="#FFFFFF"
              value={theme.backgroundColor}
              onChange={(e) => setTheme({ ...theme, backgroundColor: e.target.value })}
              fullWidth
            />
            <TextField
              label="Foreground Color"
              placeholder="#000000"
              value={theme.foregroundColor}
              onChange={(e) => setTheme({ ...theme, foregroundColor: e.target.value })}
              fullWidth
            />
          </Box>

          {/* Color picking grids */}
          <Typography variant="body2" sx={{ mt: 2 }}>
            Pick Background Color
          </Typography>
          <ColorPickerGrid
            selectedColor={theme.backgroundColor}
            onColorSelect={(color) => setTheme({ ...theme, backgroundColor: color })}
          />

          <Typography variant="body2" sx={{ mt: 2 }}>
            Pick Foreground Color
          </Typography>
          <ColorPickerGrid
            selectedColor={theme.foregroundColor}
            onColorSelect={(color) => setTheme({ ...theme, foregroundColor: color })}
          />

          <Typography variant="body1" sx={{ mt: 2 }}>
            Items
          </Typography>
          <List>
            {items.map((it, idx) => (
              <ListItem key={idx} disableGutters>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <TextField
                    sx={{ mb: 1 }}
                    label="Label"
                    size="small"
                    value={it.label}
                    onChange={(e) => handleUpdateItemField(idx, 'label', e.target.value)}
                  />
                  <TextField
                    label="URL"
                    size="small"
                    value={it.url || ''}
                    onChange={(e) => handleUpdateItemField(idx, 'url', e.target.value)}
                  />
                </Box>
                <IconButton
                  onClick={() => handleRemoveItem(idx)}
                  sx={{ color: 'red', ml: 1, alignSelf: 'flex-start' }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <Button variant="outlined" onClick={handleAddItem} sx={{ mt: 1 }}>
            + Add Item
          </Button>

          <Button variant="contained" onClick={handleSave} sx={{ mt: 3 }}>
            Save
          </Button>
        </Paper>

        {/* RIGHT: Live Preview */}
        <Paper sx={{ p: 2, flex: 1 }} elevation={1}>
          <Typography variant="h6" gutterBottom>
            Live Preview
          </Typography>
          <BioPageContainer customtheme={theme}>
            <Box
              sx={{
                p: 2,
                backgroundColor: 'transparent',
                borderRadius: '8px',
                width: '100%',
              }}
            >
              <Typography variant="h4" textAlign="center" gutterBottom>
                {title || slug || 'Untitled'}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {description}
              </Typography>
            </Box>
            <Divider sx={{ my: 2, width: '100%' }} />
            <Box sx={{ width: '100%', maxWidth: 600 }}>
              {items.map((it, idx) => renderPreviewItem(it, idx))}
            </Box>
          </BioPageContainer>
        </Paper>
      </Box>
    </Box>
  );
}
