import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  IconButton,
  Drawer,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import API from "../../../../API";
import CreatorConversation from "./pconvo"; // New conversation component
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { globalStyles } from "../../../../Utils/Styles";
import routes from "../../../../Config/routes";

const CreatorSpecificPartnership = () => {
  const { partnershipId } = useParams();
  const { creatorToken } = useCreatorAuth();
  const navigate = useNavigate();

  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const toggleSidePanel = () => setSidePanelOpen((prev) => !prev);

  // Fetch partnership details
  const {
    isLoading,
    error,
    data: partnership,
    refetch,
  } = useQuery(
    ["creator", "partnership", partnershipId],
    () => {
      if (creatorToken.creator_user.username) {
        return API.creatorConnect.getPartnershipSingle(
          partnershipId,
          creatorToken.creator_user.username
        );
      }
      return null;
    },
    { enabled: !!partnershipId }
  );

  useEffect(() => {
    if (partnershipId) {
      refetch();
    }
  }, [partnershipId, refetch]);

  // Loading
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Error
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography color="error">
          Error fetching partnership: {error.message}
        </Typography>
      </Box>
    );
  }

  // No partnership found
  if (!partnership) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography variant="h6">No partnership found.</Typography>
      </Box>
    );
  }

  // Main render
  return (
    <Box
      sx={{
        ...globalStyles.wholeHeightMinusTop,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: 'clip',
        backgroundColor: "#fafafa",
      }}
    >
      {/* Top Bar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px",
          backgroundColor: "#424242",
          color: "#ffffff",
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(routes.creatorCollabs)}
          sx={{ color: "#ffffff" }}
        >
          Go Back
        </Button>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
          {partnership?.partnership?.name}
        </Typography>
        <IconButton onClick={toggleSidePanel} sx={{ color: "#ffffff" }}>
          <InfoIcon />
        </IconButton>
      </Box>

      <CreatorConversation partnershipId={partnershipId} />

      {/* Right-Side Drawer (minimized by default) */}
      <Drawer
        anchor="right"
        open={sidePanelOpen}
        onClose={toggleSidePanel}
        variant="temporary"
        sx={{
          "& .MuiDrawer-paper": {
            width: "320px",
            padding: "16px",
          },
        }}
      >
        <Typography variant="h6" gutterBottom>
          Partnership Details
        </Typography>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">Status:</Typography>
          <Typography variant="body2">
            {partnership.partnership.status}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">Description:</Typography>
          <Typography variant="body2">
            {partnership.partnership.description}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">Creator:</Typography>
          <Typography variant="body2">{partnership.partnership.creator}</Typography>
        </Box>

        {/* Add any other fields you'd like to show */}
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">Proposal Date:</Typography>
          <Typography variant="body2">
            {partnership.partnership.proposal_date}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">Invoice Date:</Typography>
          <Typography variant="body2">
            {partnership.partnership.invoice_date}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">Paid Date:</Typography>
          <Typography variant="body2">
            {partnership.partnership.paid_date}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">Total Sum:</Typography>
          <Typography variant="body2">
            {partnership.partnership.total_sum}
          </Typography>
        </Box>
        {/* ...and so forth for all partnership data you want displayed... */}
      </Drawer>
    </Box>
  );
};

export default CreatorSpecificPartnership;
