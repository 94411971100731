import React, { useState, useEffect, useMemo } from 'react';
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  Paper,
  Skeleton,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import { SearchRounded, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Link, useOutlet, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../API';
import useIsDesktop from '../../Hooks/useIsDesktop';

export const styles = {
  wholeHeightMinusTop: {
    '@media (min-width:0px)': {
      height: { xs: 'calc(100vh - 56px - 48px)', md: 'calc(100vh - 48px)' },
      '@media (orientation: landscape)': {
        height: { xs: 'calc(100vh - 48px - 48px)', md: 'calc(100vh - 48px)' },
      },
    },
    '@media (min-width:600px)': {
      height: { xs: 'calc(100vh - 64px - 48px)', md: 'calc(100vh - 48px)' }
    }
  }
};

function CreatorEntry({ creator, currentCreator }) {
    return (
        <ListItemButton
            component={Link}
            to={creator.creator}
            selected={creator.creator === currentCreator}
        >
            <ListItemAvatar>
                <Avatar src={creator.pfphref || undefined}>
                    {!creator.pfphref && creator.creator.charAt(0).toUpperCase()}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={creator.creator}
                secondary={creator.notes_content_style || '\u00A0'}
                secondaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'clip', textOverflow: 'ellipsis' } }}
            />
        </ListItemButton>
    );
}

function EmptyCreator() {
    return (
        <ListItemButton>
            <ListItemAvatar>
                <Skeleton variant="circular">
                    <Avatar />
                </Skeleton>
            </ListItemAvatar>
            <ListItemText
                primary={<Skeleton animation='wave' />}
                secondary={<Skeleton animation='wave' />}
                secondaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'clip', textOverflow: 'ellipsis' } }}
            />
        </ListItemButton>
    );
}

export default function AgencyCreators() {
  const isDesktop = useIsDesktop();
  const { creatorId } = useParams();
  const outlet = useOutlet();
  const [page, setPage] = useState(1); // Pagination starts at 1
  const itemsPerPage = 20;
  const [currentSearch, setCurrentSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [isMinimized, setIsMinimized] = useState(false);

  const { data: creators, isLoading } = useQuery({
    queryKey: ['agency', 'creators'],
    queryFn: () => API.creators.list({ only_agency: true }),
    refetchOnWindowFocus: false
  });

  // Debounce search input
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setCurrentSearch(searchInput);
      setPage(1); // Reset to first page on new search
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchInput]);

  const filteredCreators = useMemo(
    () =>
      !creators
        ? []
        : creators.filter((creator) =>
            currentSearch === "" ||
            Object.values(creator).some(
              (val) =>
                typeof val === "string" &&
                val.toLowerCase().includes(currentSearch.toLowerCase())
            )
          ),
    [currentSearch, creators]
  );

  // Calculate summary data
  const summaryData = useMemo(() => {
    if (!creators) return { total: 0, active: 0, pending: 0 };
    const total = creators.length;
    const active = creators.filter(c => c.status === 'active').length; // Adjust based on your data structure
    const pending = creators.filter(c => c.status === 'pending').length; // Adjust based on your data structure
    return { total, active, pending };
  }, [creators]);

  return (
    <>
      {(!isDesktop && outlet) || (
        <Box
          id="creators-page-root"
          sx={{
            ...styles.wholeHeightMinusTop,
            paddingInline: { xs: 0, md: 2 },
            paddingBlockStart: { xs: 0, md: 2 },
            paddingBlockEnd: { xs: 0, md: 1 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 2, minHeight: '0px' }}>
            {/* Creators List Panel (Desktop only) */}
            {isDesktop && !isMinimized && (
              <Paper
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  width: { xs: '100%', md: '22.5rem' },
                }}
                elevation={1}
                square={!isDesktop}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1 }}>
                  <Typography variant="h6">Creators</Typography>
                  <IconButton onClick={() => setIsMinimized(true)}>
                    <ChevronLeft />
                  </IconButton>
                </Box>
                {/* Search Bar */}
                <Box sx={{ padding: 2 }}>
                  <TextField
                    value={searchInput}
                    onChange={(event) => setSearchInput(event.target.value)}
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search creators..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchRounded />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Divider />
                <Box sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                  <List>
                    {!isLoading && filteredCreators.length === 0 && (
                      <ListItem>
                        <ListItemText primary="No creators found." />
                      </ListItem>
                    )}
                    {filteredCreators.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((creator) => (
                      <CreatorEntry
                        key={creator.creator}
                        creator={creator}
                        currentCreator={creatorId}
                      />
                    ))}
                    {isLoading && [0, 1, 2, 3, 4].map((index) => (
                      <EmptyCreator key={index} />
                    ))}
                  </List>
                </Box>
                <Divider />
                <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    siblingCount={1}
                    count={Math.ceil(filteredCreators.length / itemsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    color="primary"
                  />
                </Box>
              </Paper>
            )}

            {/* Detail Panel */}
            <Paper
              sx={{ maxHeight: '100%', overflowY: 'auto', flex: 1, position: 'relative' }}
              elevation={1}
            >
              {/* If minimized, show a toggle button to expand the creators list */}
              {isDesktop && isMinimized && (
                <Box sx={{ position: 'absolute', top: 8, left: 8, zIndex: 10 }}>
                  <IconButton onClick={() => setIsMinimized(false)}>
                    <ChevronRight />
                  </IconButton>
                </Box>
              )}
              {outlet || (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <Typography variant="h4" color="text.secondary">
                    Select a Creator
                  </Typography>
                </Box>
              )}
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
}
