import React from "react";
import {
  Typography,
  List,
  ListItem,
  Box,
  Chip,
  ListItemAvatar,
  Avatar,
  Grid,
  Button,
  Card,
  CardMedia,
  CardContent,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PeopleIcon from "@mui/icons-material/People";
import SendIcon from "@mui/icons-material/Send";
import AssignmentIcon from "@mui/icons-material/Assignment";
import profilePhoto from "../../../../../Components/globalAssets/ppfLogo.png";
import { getFollowersByVideos, getTotalFollowersInDict } from "../../../../../Utils/constants";

// Status constants
export const CreatorCampaignStatus = [
  "Pitched",
  "Approved",
  "Counter",
  "Dropped",
  "Completed",
];

// Status colors (we'll use these for our outline)
const STATUS_COLORS = {
  Approved: "#c8e6c9",  // light green
  Counter: "#ffecb3",   // light amber
  Dropped: "#ffcdd2",   // light red
  Completed: "#bbdefb", // light blue
};

// Helper to format large numbers with commas
const formatNumber = (num) => Number(num || 0).toLocaleString();

/**
 * Returns border style for each status.
 * We set backgroundColor to 'transparent' since we only want an outline.
 */
const getStatusOutline = (status) => ({
  border: `2px solid ${STATUS_COLORS[status] || "transparent"}`,
  backgroundColor: "transparent",
});

// === Styles ===
const CreatorCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  padding: theme.spacing(2),
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[4],
  },
}));

const DetailGrid = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  marginTop: theme.spacing(0.5),
  flexWrap: "wrap",
}));

// For the dashboard grid card
const StyledCard = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  height: "100%",
  boxShadow: theme.shadows[2],
  // We'll override background to 'transparent' in inline sx,
  // but you can keep basic styling here if needed.
}));

/** ========== Individual Dashboard Card ========== **/
const CreatorProfileCard = ({
  creator,
  onStatusChange,
  editable,
  onNegotiateClick,
}) => {
  const handleStatusButtonClick = (newStatus) => {
    onStatusChange(creator.id, newStatus);
  };

  return (
    <StyledCard sx={{ ...getStatusOutline(creator.status) }}>
      <CardMedia
        component="img"
        height="180"
        image={creator.pfphref || profilePhoto}
        alt={creator.name}
        sx={{ objectFit: "cover" }}
      />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6" fontWeight={600}>
          <Link
            href={`https://www.useblitz.co/creators/${creator.name}`}
            target="_blank"
            rel="noopener noreferrer"
            color="text.primary"
            underline="hover"
          >
            @{creator.name}
          </Link>
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <PeopleIcon fontSize="small" />
          <Typography variant="body2" color="text.secondary">
           { `Followers: ${(getTotalFollowersInDict(getFollowersByVideos(creator.videos)))?.toLocaleString() || "N/A"}`}
          </Typography>
          {creator.platformLink && (
            <Link
              href={creator.platformLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="body2"
              sx={{ mt: 1 }}
            >
              View on {creator.promotionPlatform}
            </Link>
          )}
        </Box>

        <DetailGrid>
          <Chip
            label={`$${formatNumber(creator.price)}`}
            color="primary"
            size="small"
            variant="outlined"
          />
          <Chip
            icon={<SendIcon fontSize="small" />}
            label={creator.offerSent ? "Offer Sent" : "Pending Offer"}
            size="small"
            variant="outlined"
          />
          <Chip
            icon={<AssignmentIcon fontSize="small" />}
            label={creator.assetsSent ? "Assets Sent" : "Assets Pending"}
            size="small"
            variant="outlined"
          />
        </DetailGrid>

        {editable && (
          <Box sx={{ display: "flex", gap: 1.5, mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => handleStatusButtonClick("Dropped")}
            >
              Drop
            </Button>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={() => handleStatusButtonClick("Approved")}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={() => onNegotiateClick(creator)}
            >
              Negotiate
            </Button>
          </Box>
        )}
      </CardContent>
    </StyledCard>
  );
};

/** ========== Main Panel ========== **/
const CampaignDetailsPanel = ({
  campaignDetails,
  isCampaignActive,
  handleStatusChange,
  onNegotiateClick,
  viewMode = "list", // "list" or "dashboard"
}) => {
  // Renders the "List" mode
  const renderListMode = () => (
    <List dense sx={{ py: 0 }}>
      {campaignDetails.creators.map((creator, index) => (
        <CreatorCard
          key={creator.id || index}
          // Outline instead of background fill:
          sx={getStatusOutline(creator.status)}
        >
          <ListItem disablePadding>
            <ListItemAvatar>
              <Avatar
                src={creator.pfphref || profilePhoto}
                sx={{
                  width: 56,
                  height: 56,
                  boxShadow: 3,
                  border: "2px solid",
                  borderColor: "background.paper",
                }}
              />
            </ListItemAvatar>

            <Box
              sx={{
                flexGrow: 1,
                ml: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link
                href={`https://www.useblitz.co/creators/${creator.name}`}
                target="_blank"
                rel="noopener noreferrer"
                color="text.primary"
                variant="h6"
                fontWeight={600}
                underline="hover"
              >
                @{creator.name}
              </Link>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mt: 0.5,
                }}
              >
                <PeopleIcon fontSize="small" sx={{ color: "text.secondary" }} />
                <Typography variant="body2" color="text.secondary">
                  {`Followers: ${(getTotalFollowersInDict(getFollowersByVideos(creator.videos)))?.toLocaleString() || "N/A"}`}
                </Typography>
              </Box>
              {creator.platformLink && (
                <Link
                  href={creator.platformLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body2"
                >
                  View on {creator.promotionPlatform}
                </Link>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 1,
              }}
            >
              <DetailGrid>
                <Chip
                  label={`$${formatNumber(creator.price)}`}
                  color="primary"
                  size="small"
                  variant="outlined"
                />
                <Chip
                  icon={<SendIcon fontSize="small" />}
                  label={creator.offerSent ? "Offer Sent" : "Pending Offer"}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  icon={<AssignmentIcon fontSize="small" />}
                  label={creator.assetsSent ? "Assets Sent" : "Assets Pending"}
                  size="small"
                  variant="outlined"
                />
              </DetailGrid>

              {isCampaignActive && (
                <Box sx={{ display: "flex", gap: 1.5, mt: 1 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleStatusChange(creator.id, "Dropped")}
                  >
                    Drop
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleStatusChange(creator.id, "Approved")}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => onNegotiateClick(creator)}
                  >
                    Negotiate
                  </Button>
                </Box>
              )}
            </Box>
          </ListItem>
        </CreatorCard>
      ))}
    </List>
  );

  // Renders the "Dashboard" (grid) mode
  const renderGridMode = () => (
    <Grid container spacing={2}>
      {campaignDetails.creators.map((creator) => (
        <Grid item xs={12} sm={6} md={4} key={creator.id}>
          <CreatorProfileCard
            creator={creator}
            onStatusChange={handleStatusChange}
            editable={isCampaignActive}
            onNegotiateClick={onNegotiateClick}
          />
        </Grid>
      ))}
    </Grid>
  );

  // Decide which layout to render
  return viewMode === "list" ? renderListMode() : renderGridMode();
};

export default CampaignDetailsPanel;
