// src/Pages/SomePage/ChatModalCreator.jsx
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from 'react-query';
import useIsDesktop from '../../Hooks/useIsDesktop'; // or wherever you keep it
import API from '../../API';                         // your central API wrapper
import ChatScreen from '../ChatScreen';

const ChatModalCreator = ({ open, onClose, creatorId }) => {
  const isDesktop = useIsDesktop();

  const { data, isLoading, error } = useQuery({
    queryKey: ['conversationCreator', creatorId],
    queryFn: () => API.conversations.checkUserToCreator({ recipient_creator_id: creatorId }),
    enabled: !!open && !!creatorId,
    refetchOnWindowFocus: false,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // For smaller screens, you might want fullScreen
      PaperProps={{
        sx: {
          minWidth: '40vw',
          maxWidth: { xs: 'none', md: '40vw' },
          maxHeight: '100vh',
        },
      }}
      fullScreen={!isDesktop}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">
          {data ? `Conversation #${data.conversation_id}` : 'Starting Chat...'}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 0, pb: 1 }}>
        {error && (
          <Alert severity="error" sx={{ m: 2 }}>
            {error?.response?.data?.error || error.message}
          </Alert>
        )}
        <ChatScreen
          showWarning={false}
          conversationId={data?.conversation_id}
          sx={{ height: '100%', minHeight: '70vh' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChatModalCreator;
