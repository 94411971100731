import React, { useCallback } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { RateField } from '../../../../Components/RateField';
import {
  platformRatesListFromCreator,
  platformRatesListFromSelectedKeys,
  getRelatedPromotions,
  promotionLabelsFromList,
} from '../../../../Utils/constants';

// Usage rights options
const USAGE_RIGHTS_OPTIONS = {
  '30_days': { label: '30 Days', recommendedPercentage: 0.10 },
  '60_days': { label: '60 Days', recommendedPercentage: 0.20 },
  '90_days': { label: '90 Days', recommendedPercentage: 0.30 },
};

function calculateBasePrice(creator, videos) {
  return videos.reduce((accum, video) => {
    const promoCost = creator[video.rateKey] || 0;
    const crossCost = Array.from(video.crossPostingKeys || []).reduce(
      (sum, crossKey) => sum + Math.max((creator[crossKey] || 0) / 4, 0),
      0
    );
    return accum + promoCost + crossCost;
  }, 0);
}

function calculateUsageRightsCost(basePrice, usageRightsKey, usageRightsOverride) {
  if (!usageRightsKey) return 0;
  const recommended = basePrice * USAGE_RIGHTS_OPTIONS[usageRightsKey].recommendedPercentage;
  return usageRightsOverride !== undefined ? usageRightsOverride : recommended;
}

function VideoDetails({
  creator,
  video,
  index,
  videoIndex,
  promotions,
  handleOptionChange,
  handleRemoveVideo,
  videoAmount,
  triedNext,
}) {
  const crossPostOptions = platformRatesListFromSelectedKeys(
    creator,
    getRelatedPromotions(video.rateKey)
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          minWidth: '0px',
          maxWidth: '100%',
        }}
      >
        <IconButton
          sx={{ mx: 2 }}
          disabled={videoAmount <= 1}
          onClick={() => handleRemoveVideo(index, videoIndex)}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>

        <Box sx={{ flex: 1, minWidth: '0px' }}>
          <TextField
            value={video.rateKey}
            onChange={(e) =>
              handleOptionChange(index, 'videos', e.target.value, 'rateKey', videoIndex)
            }
            label="Promotion Type"
            select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 1 }}
            error={triedNext && !video.rateKey}
            helperText={
              triedNext && !video.rateKey
                ? 'Select a promotion for this video'
                : ''
            }
          >
            {promotions.map((promo) => (
              <MenuItem
                key={`${creator.creator}-${video.id}-${promo.rateKey}`}
                value={promo.rateKey}
              >
                {promo.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Crossposting"
            select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 1 }}
            SelectProps={{
              multiple: true,
              renderValue: (selected) => promotionLabelsFromList(selected).join(', '),
            }}
            value={[...video.crossPostingKeys]}
            onChange={(e) =>
              handleOptionChange(
                index,
                'videos',
                e.target.value,
                'crossPostingKeys',
                videoIndex
              )
            }
          >
            {crossPostOptions.map((promo) => (
              <MenuItem
                key={`${creator.creator}-${video.id}-cross-${promo.rateKey}`}
                value={promo.rateKey}
              >
                <Checkbox checked={video.crossPostingKeys.has(promo.rateKey)} />
                <ListItemText
                  primaryTypographyProps={{ sx: { fontWeight: 'normal !important' } }}
                  primary={promo.label}
                />
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Divider variant="inset" flexItem sx={{ mb: 2 }} />
    </>
  );
}

const hasInvalidRateKey = (videos) =>
  videos.some((video) => !video.rateKey || video.rateKey.trim() === '');

export default function CreatorDetailsCard({
  creator,
  index,
  handlePriceChange,
  handleOptionChange,
  onRemove,
  triedNext,
  handleAddVideo,
  handleRemoveVideo,
  setCreatorsData,
}) {
  const promotions = platformRatesListFromCreator(creator);
  const hasEmptyKey = hasInvalidRateKey(creator.videos);
  const minAllowed = (creator.originalBasePrice || 0) * 0.7;
  const isBelowMin = creator.basePrice < minAllowed;

  const handleRateEdit = useCallback(
    (rateKey, newVal, isCrosspost = false) => {
      const numericCost = Math.max(0, parseFloat(newVal) || 0);
      setCreatorsData(prev => {
        const next = [...prev];
        const c = { ...next[index] };
        
        if (isCrosspost) {
          c[rateKey] = numericCost * 4;
        } else {
          c[rateKey] = numericCost;
        }
        
        const newBasePrice = calculateBasePrice(c, c.videos);
        c.basePrice = newBasePrice;
        c.adjustedPrice = newBasePrice + calculateUsageRightsCost(
          newBasePrice,
          c.usageRightsKey,
          c.usageRightsOverride
        );
        
        next[index] = c;
        return next;
      });
    },
    [index, setCreatorsData]
  );

  const handleUsageRightsChange = (key) => {
    setCreatorsData(prev => {
      const next = [...prev];
      const c = { ...next[index] };
      c.usageRightsKey = key;
      c.usageRightsOverride = undefined;
      c.adjustedPrice = c.basePrice + calculateUsageRightsCost(c.basePrice, key);
      next[index] = c;
      return next;
    });
  };

  const handleUsageRightsOverride = (value) => {
    const numericValue = Math.max(0, parseFloat(value) || 0);
    setCreatorsData(prev => {
      const next = [...prev];
      const c = { ...next[index] };
      c.usageRightsOverride = numericValue;
      c.adjustedPrice = c.basePrice + numericValue;
      next[index] = c;
      return next;
    });
  };

  const handleBasePriceOverride = (value) => {
    const numericPrice = Math.max(0, parseFloat(value) || 0);
    setCreatorsData(prev => {
      const next = [...prev];
      const c = { ...next[index] };
      c.basePrice = numericPrice;
      
      if (c.videos.length > 0 && c.videos[0].rateKey) {
        const firstVideoCost = numericPrice - calculateBasePrice(c, c.videos.slice(1));
        c[c.videos[0].rateKey] = Math.max(0, firstVideoCost);
      }
      
      c.adjustedPrice = numericPrice + calculateUsageRightsCost(
        numericPrice,
        c.usageRightsKey,
        c.usageRightsOverride
      );
      next[index] = c;
      return next;
    });
  };

  const mainItems = creator.videos.map((video) => ({
    rateKey: video.rateKey,
    label: video.rateKey,
    cost: creator[video.rateKey] || 0,
    editable: video === creator.videos[0] ? false : true
  }));

  const crossItems = [];
  creator.videos.forEach((video) => {
    video.crossPostingKeys.forEach((crossKey) => {
      const totalCrossCost = creator[crossKey] || 0;
      crossItems.push({
        crossKey,
        label: crossKey,
        cost: totalCrossCost / 4,
      });
    });
  });

  const usageRightsCost = calculateUsageRightsCost(
    creator.basePrice,
    creator.usageRightsKey,
    creator.usageRightsOverride
  );
  const recommendedUsageCost = creator.usageRightsKey 
    ? creator.basePrice * USAGE_RIGHTS_OPTIONS[creator.usageRightsKey].recommendedPercentage 
    : 0;
  const isUsageBelowRecommended = usageRightsCost < recommendedUsageCost * 0.7;

  return (
    <Card sx={{ overflow: 'clip' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ paddingInline: 3, paddingBlock: 3, flex: 1, minWidth: '0px', display: 'flex', flexDirection: 'row', gap: 2 }}>
          <Box sx={{ width: '33.33%', display: { xs: 'none', md: 'block' } }}>
            <CardMedia
              component="img"
              image={creator.pfphref || '/default_profile.png'}
              alt={creator.creator}
              sx={{
                width: '100%',
                aspectRatio: '1/1',
                objectFit: 'cover',
                borderRadius: (theme) => `${theme.shape.borderRadius}px`,
              }}
            />
          </Box>

          <Box sx={{ width: { xs: '100%', md: '60%' } }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
              <Box
                sx={{
                  width: '30%',
                  display: { xs: 'block', md: 'none' },
                  marginInlineEnd: 1,
                }}
              >
                <CardMedia
                  component="img"
                  image={creator.pfphref || '/default_profile.png'}
                  alt={creator.creator}
                  sx={{
                    width: '100%',
                    aspectRatio: '1/1',
                    objectFit: 'cover',
                    borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                  }}
                />
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                >
                  {creator.creator}
                </Typography>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    size="small"
                    disabled
                    fullWidth
                    sx={{ mb: 2 }}
                    value={`${creator.videos.length || 0} Video${creator.videos.length === 1 ? '' : 's'}`}
                  />
                  <Tooltip
                    title={
                      hasEmptyKey
                        ? 'Finish filling the current video(s) first'
                        : 'Add another video'
                    }
                  >
                    <span>
                      <IconButton
                        disabled={hasEmptyKey}
                        onClick={() => !hasEmptyKey && handleAddVideo(index)}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            {creator.videos.map((video, videoIndex) => (
              <VideoDetails
                key={`${creator.creator}-videos-${video.id}`}
                video={video}
                creator={creator}
                promotions={promotions}
                handleOptionChange={handleOptionChange}
                handleRemoveVideo={handleRemoveVideo}
                triedNext={triedNext}
                index={index}
                videoIndex={videoIndex}
                videoAmount={creator.videos.length}
              />
            ))}

            <RateField
              error={triedNext && (!creator.basePrice || Number(creator.basePrice) === 0)}
              helperText={
                isBelowMin ? (
                  <Typography color="error">
                    Warning: Below recommended minimum of ${minAllowed.toFixed(2)} (70% of original)
                  </Typography>
                ) : triedNext && (!creator.basePrice || Number(creator.basePrice) === 0) ? (
                  'Enter a rate for this creator'
                ) : (
                  ''
                )
              }
              label="Base Price (override)"
              variant="outlined"
              fullWidth
              size="small"
              value={creator.basePrice}
              onChange={handleBasePriceOverride}
              sx={{ mb: 2 }}
            />

            <Box sx={{ border: '1px solid #ddd', borderRadius: 1, p: 2, mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>Itemized Costs:</Typography>
              
              {mainItems.map((item, idx) => item.rateKey && (
                <Box key={`mainpromo-${idx}`} sx={{ display: 'flex', mb: 1 }}>
                  <Typography variant="body2" sx={{ width: '50%', alignSelf: 'center' }}>
                    {item.label}:
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    sx={{ width: '40%' }}
                    value={item.cost.toFixed(2)}
                    onChange={(e) => handleRateEdit(item.rateKey, e.target.value, false)}
                    inputProps={{ min: 0 }}
                    disabled={!item.editable}
                  />
                </Box>
              ))}

              {crossItems.length > 0 && <Divider sx={{ my: 1 }} />}
              {crossItems.map((x, i) => (
                <Box key={`crossitem-${i}`} sx={{ display: 'flex', mb: 1 }}>
                  <Typography variant="body2" sx={{ width: '50%', alignSelf: 'center' }}>
                    Crosspost [{x.label}]:
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    sx={{ width: '40%' }}
                    value={x.cost.toFixed(2)}
                    onChange={(e) => handleRateEdit(x.crossKey, e.target.value, true)}
                    inputProps={{ min: 0 }}
                  />
                </Box>
              ))}

              <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                <Typography variant="body2" sx={{ width: '30%' }}>
                  Usage Rights:
                </Typography>
                <Select
                  value={creator.usageRightsKey || ''}
                  onChange={(e) => handleUsageRightsChange(e.target.value)}
                  sx={{ width: '30%', mr: 1 }}
                  size="small"
                >
                  <MenuItem value="">None</MenuItem>
                  {Object.entries(USAGE_RIGHTS_OPTIONS).map(([key, { label }]) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {creator.usageRightsKey && (
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    sx={{ width: '30%' }}
                    value={usageRightsCost.toFixed(2)}
                    onChange={(e) => handleUsageRightsOverride(e.target.value)}
                    inputProps={{ min: 0 }}
                    error={isUsageBelowRecommended}
                    helperText={isUsageBelowRecommended ? `Recommended: $${recommendedUsageCost.toFixed(2)}` : ''}
                  />
                )}
              </Box>

              <Divider sx={{ my: 1 }} />
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Subtotal (itemized): ${(creator.basePrice + usageRightsCost).toFixed(2)}
              </Typography>
            </Box>

            <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mb: 1 }}>
              Adjusted Price: ${creator.adjustedPrice.toFixed(2)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Divider orientation="vertical" variant="middle" flexItem />
          <IconButton size="small" sx={{ marginInline: 1 }} onClick={() => onRemove(creator.creator)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
}