import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  CircularProgress,
  List,
  ListItem, // Added import for ListItem
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Badge,
  ListItemButton,
  Drawer,
  TextField,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import API from '../../../API';
import ChatScreen from '../../../Components/ChatScreen';
import useIsDesktop from '../../../Hooks/useIsDesktop';
import useAuth from '../../../Hooks/use-auth';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';

/* Helper function to format "last_online" */
function formatLastOnline(lastOnline) {
  if (!lastOnline) return 'Never logged in';
  const now = new Date();
  const dt = new Date(lastOnline);
  const diffMs = now - dt;
  if (diffMs < 0) return 'Unknown'; // if time is in the future
  const diffMins = Math.floor(diffMs / 60000);
  if (diffMins < 1) return 'Online now';
  if (diffMins < 60) return `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
  const diffHours = Math.floor(diffMins / 60);
  if (diffHours < 24) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 7) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  return dt.toLocaleDateString();
}

/* ParticipantsPanel Component (integrated code) */
function ParticipantsPanel({ conversationId, onClose }) {
  const queryClient = useQueryClient();
  const [inviteEmails, setInviteEmails] = useState('');

  // Fetch conversation details (participants, messages, etc.)
  const {
    data: conversationData,
    isLoading,
    isError,
    error,
  } = useQuery(
    ['messages', conversationId],
    () => API.conversations.getMessages(conversationId),
    { enabled: !!conversationId }
  );

  const participants = conversationData?.participants || [];
  const creator = participants.find((p) => p.is_creator);
  const brandUsers = participants.filter((p) => !p.is_creator);

  // Extract attachments from messages (assumes msg.attachments is a single object)
  const allAttachments = useMemo(() => {
    if (!conversationData?.messages) return [];
    const attachments = [];
    conversationData.messages.forEach((msg) => {
      if (msg.attachments) attachments.push(msg.attachments);
    });
    return attachments;
  }, [conversationData]);

  // Extract deliverables from messages
  const deliverables = useMemo(() => {
    if (!conversationData?.messages) return [];
    return conversationData.messages
      .filter((msg) => !!msg.deliverable)
      .map((msg) => msg.deliverable);
  }, [conversationData]);

  // Mutation to invite participants by email
  const inviteByEmailMutation = useMutation(
    (payload) => API.conversations.inviteByEmail(conversationId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['messages', conversationId]);
        setInviteEmails('');
      },
      onError: (err) => {
        console.error('Error inviting participants:', err);
      },
    }
  );

  const handleInviteClick = () => {
    const trimmed = inviteEmails.trim();
    if (!trimmed) return;
    const emailsArray = trimmed
      .split(',')
      .map((email) => email.trim())
      .filter(Boolean);
    inviteByEmailMutation.mutate({ emails: emailsArray });
  };

  return (
    <Box
      sx={{
        width: { xs: '80vw', sm: '400px' },
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6">Conversation Details</Typography>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </Box>

      {isLoading && <CircularProgress />}
      {isError && (
        <Typography color="error">
          Error: {error?.message || 'Failed to load conversation details'}
        </Typography>
      )}

      <Box sx={{ flex: 1, overflowY: 'auto', mb: 2 }}>
        {/* Creator Section */}
        {creator && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
              Creator
            </Typography>
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar src={creator.pfp || ''} alt={creator.username?.charAt(0) || 'C'} />
              </ListItemAvatar>
              <ListItemText
                primary={creator.username || 'No Name'}
                secondary={`Last online: ${formatLastOnline(creator.last_online)}`}
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
          </>
        )}

        {/* Brand Participants Section */}
        <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
          Brand Participants
        </Typography>
        <List disablePadding>
          {brandUsers.map((p) => (
            <React.Fragment key={p.id}>
              <ListItem disableGutters>
                <ListItemAvatar>
                  <Avatar src={p.pfp || ''} alt={p.username?.charAt(0) || ''} />
                </ListItemAvatar>
                <ListItemText
                  primary={p.username || 'No Name'}
                  secondary={`Last online: ${formatLastOnline(p.last_online)}`}
                />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>

        {/* Attachments Gallery Section */}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Attachments ({allAttachments.length})
        </Typography>
        {allAttachments.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No attachments
          </Typography>
        ) : (
          <ImageList cols={2} gap={8} sx={{ mt: 1, mb: 2 }}>
            {allAttachments.map((attach, index) => {
              const isImage =
                attach.file_type === 'image' ||
                (attach.filename || '').match(/\.(png|jpe?g|gif)$/i);
              return (
                <ImageListItem key={index}>
                  {isImage ? (
                    <img
                      src={attach.file_url}
                      alt={attach.filename || 'Attachment'}
                      loading="lazy"
                      style={{ maxHeight: 180, objectFit: 'cover' }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: '#f5f5f5',
                        border: '1px dashed #ccc',
                      }}
                    >
                      <Typography variant="caption">
                        {attach.filename || 'Non-image attachment'}
                      </Typography>
                    </Box>
                  )}
                  <ImageListItemBar
                    title={attach.filename || attach.attachment_type}
                    subtitle={attach.file_type}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        )}

        {/* Deliverables Section */}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Deliverables ({deliverables.length})
        </Typography>
        {deliverables.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No deliverables yet
          </Typography>
        ) : (
          <List>
            {deliverables.map((deliv, idx) => (
              <React.Fragment key={deliv.id || idx}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={deliv.name || `Deliverable #${deliv.id || idx}`}
                    secondary={`Status: ${deliv.status || 'unknown'}`}
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>

      {/* Invite Participants Section */}
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Invite by email (comma-separated):
      </Typography>
      <TextField
        label="Enter emails"
        variant="outlined"
        fullWidth
        value={inviteEmails}
        onChange={(e) => setInviteEmails(e.target.value)}
        placeholder="john@example.com, jane@example.com"
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleInviteClick}
        disabled={inviteByEmailMutation.isLoading || !inviteEmails.trim()}
      >
        {inviteByEmailMutation.isLoading ? 'Inviting...' : 'Invite'}
      </Button>
    </Box>
  );
}

/* CreatorConversationsHub Component */
export default function CreatorConversationsHub() {
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [isLeftPanelCollapsed, setIsLeftPanelCollapsed] = useState(false);
  const [participantsOpen, setParticipantsOpen] = useState(false);

  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();
  const { creatorToken } = useCreatorAuth();
  const userId = userInfo?.id || creatorToken?.creator_user?.username || null;
  const isMobile = !useIsDesktop();

  // Fetch creator conversations
  const {
    data: conversations = [],
    isLoading,
    error,
  } = useQuery(['creatorConversations'], () =>
    API.conversations.fetchCreatorConversations()
  );

  // Toggle left panel (desktop)
  const toggleLeftPanel = () => {
    setIsLeftPanelCollapsed(!isLeftPanelCollapsed);
  };

  // Handle conversation selection
  const handleSelectConversation = (convoId) => {
    setSelectedConversationId(convoId);
    if (isMobile) {
      setIsLeftPanelCollapsed(true);
    }
  };

  // Handle mobile "back" to conversation list
  const handleBackToList = () => {
    setSelectedConversationId(null);
    if (isLeftPanelCollapsed) {
      setIsLeftPanelCollapsed(false);
    }
  };

  // Toggle the participants drawer
  const handleToggleParticipants = () => {
    setParticipantsOpen((prev) => !prev);
  };

  // Helper to determine conversation display name
  const getConvoName = (convo) => {
    if (convo.display_name) return convo.display_name;
    if (convo.campaign_name) return convo.campaign_name;
    if (convo.partnership_name) return convo.partnership_name;
    const otherUser = convo.participants?.find((p) => p.id !== userId);
    if (otherUser) return `${otherUser.first_name} ${otherUser.last_name}`;
    return `Conversation #${convo.id}`;
  };

  // Find the selected conversation
  const selectedConversation = conversations.find(
    (c) => c.id === selectedConversationId
  );

  if (isLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2, color: 'red' }}>
        Error: {error.message}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 80px)' }}>
      {/* Left Panel: Conversation List */}
      <Card
        sx={{
          m: 1,
          width: isLeftPanelCollapsed ? 0 : { xs: '100%', md: 400 },
          minWidth: isLeftPanelCollapsed ? 0 : { xs: '100%', md: 400 },
          height: '100%',
          transition: 'all 0.3s ease',
          overflow: 'hidden',
          position: { xs: 'absolute', md: 'relative' },
          zIndex: 1,
          left: isLeftPanelCollapsed ? '-100%' : 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Conversations</Typography>
          {!isMobile && (
            <IconButton onClick={toggleLeftPanel}>
              <ChevronRightIcon />
            </IconButton>
          )}
        </Box>
        <Divider />
        <Box sx={{ flex: 1, minWidth: '0px', overflowY: 'auto' }}>
          <List>
            {conversations.map((convo) => (
              <ListItemButton
                key={convo.id}
                onClick={() => handleSelectConversation(convo.id)}
                selected={convo.id === selectedConversationId}
              >
                <ListItemAvatar>
                  <Avatar src={convo.participants.find((p) => p.id !== userId)?.phphref} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography variant="subtitle1" noWrap>
                        {getConvoName(convo)}
                      </Typography>
                      <Badge
                        badgeContent={convo.unread_count}
                        color="primary"
                        max={999}
                        sx={{
                          '& .MuiBadge-badge': {
                            fontSize: '0.7rem',
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}
                      >
                        <ChatBubbleOutlineIcon fontSize="small" />
                      </Badge>
                    </Box>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {convo.message || 'No messages yet'}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Card>

      {/* Right Panel: Chat Area */}
      <Card
        sx={{
          flex: 1,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          m: 1,
        }}
      >
        {/* Chat Header */}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {!isMobile && (
              <IconButton onClick={toggleLeftPanel}>
                {isLeftPanelCollapsed ? <MenuIcon /> : <ChevronRightIcon />}
              </IconButton>
            )}
            {isMobile && selectedConversationId && (
              <IconButton onClick={handleBackToList}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap>
              {selectedConversation
                ? selectedConversation.display_name || getConvoName(selectedConversation)
                : selectedConversationId
                ? 'Conversation'
                : 'Select a Conversation'}
            </Typography>
          </Box>
          {/* Button to open Participants Drawer */}
          {selectedConversationId && (
            <IconButton onClick={handleToggleParticipants}>
              <PersonAddIcon />
            </IconButton>
          )}
        </Box>
        <Divider />
        {/* Chat Screen */}
        {selectedConversationId ? (
          <ChatScreen conversationId={selectedConversationId} sx={{ flex: 1, minHeight: 0 }} />
        ) : (
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              Select a conversation
            </Typography>
          </Box>
        )}
      </Card>

      {/* Participants Drawer */}
      <Drawer anchor="right" open={participantsOpen} onClose={handleToggleParticipants}>
        <ParticipantsPanel
          conversationId={selectedConversationId}
          onClose={handleToggleParticipants}
        />
      </Drawer>
    </Box>
  );
}
