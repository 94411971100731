import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Backdrop,
  CircularProgress,
  CssBaseline,
  Paper,
  Grid,
  Typography,
  Avatar,
  Divider,
  Box,
  styled,
  Button,
  useTheme,
  Chip
} from '@mui/material';
import API from '../../../API';
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';
import PageTitle from '../../../Components/PageTitle';
import ChatModal from './chatmodal';
import PlaceIcon from '@mui/icons-material/Place';
import WorkIcon from '@mui/icons-material/Work';
import CampaignIcon from '@mui/icons-material/Campaign';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Styled Components
const ProfileHeaderBackdrop = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 64,
  width: '100%',
  background: 'linear-gradient(45deg, #646AFF, #7B2A2A)',
  zIndex: 0,
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  minHeight: 300,
}));

const ProfileContainer = styled(Paper)(({ theme }) => ({
  maxWidth: 1200,
  margin: '-10px auto 2rem',
  padding: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: theme.shadows[10],
  position: 'relative',
  background: theme.palette.background.paper,
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 200,
  height: 200,
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[6],
  marginBottom: theme.spacing(2),
}));

const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '12px',
  background: `linear-gradient(145deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
  color: theme.palette.common.white,
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #646AFF, #7B2A2A)',
  color: '#FFF',
  borderRadius: '25px',
  padding: '12px 32px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4],
  },
}));

const ProjectCard = styled(Paper)(({ theme, type }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  height: '100%',
  transition: 'transform 0.3s ease',
  borderLeft: `5px solid ${
    type === 'campaign' ? theme.palette.primary.main :
    type === 'partnership' ? theme.palette.success.main :
    theme.palette.warning.main
  }`,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

// Component Definitions
const InfoItem = ({ label, value, icon }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        sx={{
          p: 2,
          height: '100%',
          borderRadius: '12px',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: 3,
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Box sx={{
            background: theme.palette.primary.main,
            borderRadius: '50%',
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }}>
            {icon}
          </Box>
          <Box>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              {label}
            </Typography>
            <Typography variant="h6" fontWeight="600">
              {value || 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const TotalProjectsCard = ({ count }) => (
  <Grid item xs={12} sm={6} md={4}>
    <StatsCard>
      <Box display="flex" alignItems="center" gap={2}>
        <CampaignIcon sx={{ fontSize: 40 }} />
        <Box>
          <Typography variant="subtitle2">Total Projects</Typography>
          <Typography variant="h3">{count}</Typography>
        </Box>
      </Box>
    </StatsCard>
  </Grid>
);

const ProjectItem = ({ project }) => {
  const theme = useTheme();
  const getIcon = (type) => {
    switch(type) {
      case 'campaign': return <CampaignIcon />;
      case 'partnership': return <HandshakeIcon />;
      case 'payout': return <AttachMoneyIcon />;
      default: return <CampaignIcon />;
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <ProjectCard type={project.type}>
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Box sx={{
            color: 
              project.type === 'campaign' ? theme.palette.primary.main :
              project.type === 'partnership' ? theme.palette.success.main :
              theme.palette.warning.main,
          }}>
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {project.name || `Payout #${project.id}`}
          </Typography>
        </Box>


        <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
          <Chip 
            label={project.status} 
            size="small"
            color={
              project.status === 'active' || project.status === 'approved' ? 'success' :
              project.status === 'pending' ? 'warning' : 'default'
            }
          />
          {project.campaign_type && (
            <Chip label={project.campaign_type} size="small" color="primary" />
          )}
          {project.role && (
            <Chip label={project.role} size="small" color="info" />
          )}
        </Box>

        {project.payout_date && (
          <Typography variant="body2" color="textSecondary">
            Date: {new Date(project.payout_date).toLocaleDateString()}
          </Typography>
        )}
      </ProjectCard>
    </Grid>
  );
};

// Main Component
export default function UserProfile() {
  const { userId } = useParams();
  const { dialogState, openDialog } = useAlertDialog();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [targetUserId, setTargetUserId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await API.users.fetchPublicDetails(userId);
        setUser(response);
      } catch (err) {
        setError(err.message);
        openDialog('Error', `Failed to load user: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId, openDialog]);

  if (loading) {
    return (
      <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!user) {
    return (
      <ProfileContainer>
        <Typography variant="h6">No user data found.</Typography>
      </ProfileContainer>
    );
  }

  const { 
    username, 
    first_name, 
    last_name, 
    email, 
    company, 
    campaigns = [], 
    partnerships = [], 
    payouts = [], 
    total_projects = 0 
  } = user;

  // Filter out partnerships with DELETED status
  const activePartnerships = partnerships.filter(
    partnership => partnership.status.toUpperCase() !== 'DELETED'
  );
  
  // Combine only active projects
  const allProjects = [...campaigns, ...activePartnerships, ...payouts];

  return (
    <>
      <CssBaseline />
      <PageTitle title={`${first_name ?? ''} ${last_name ?? ''} - Profile`} />
      <AlertDialog alertState={dialogState} />

      <ProfileHeaderBackdrop>
        <ProfileContainer>
          <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
            <UserAvatar
              src={user.phphref || '/default-avatar.png'}
              alt={`${first_name ?? ''} ${last_name ?? ''}`}
            />
            <Typography variant="h2" gutterBottom sx={{ fontWeight: 700, letterSpacing: 1 }}>
              {first_name} {last_name}
            </Typography>
            
            <Box display="flex" justifyContent="center" gap={2} mb={2}>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <PlaceIcon fontSize="inherit" sx={{ mr: 1 }} />
                {company?.location || 'Global'}
              </Typography>
              {company?.industry && (
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  <WorkIcon fontSize="inherit" sx={{ mr: 1 }} />
                  {company.industry}
                </Typography>
              )}
            </Box>

            <ActionButton
              onClick={() => {
                setTargetUserId(userId);
                setChatOpen(true);
              }}
            >
              Chat With User
            </ActionButton>
          </Box>

          <Divider sx={{ my: 4, borderWidth: 2, borderColor: 'divider' }} />

          <Grid container spacing={3} sx={{ mb: 4 }}>
            <TotalProjectsCard count={total_projects} />
            <InfoItem 
              label="Company" 
              value={company?.name}
              icon={<WorkIcon />}
            />
            <InfoItem 
              label="Member Since" 
              value={new Date(user.created_at).toLocaleDateString()}
              icon={<WorkIcon />}
            />
          </Grid>

          <Divider sx={{ my: 4, borderWidth: 2, borderColor: 'divider' }} />

          <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
            Projects
          </Typography>
          {allProjects.length > 0 ? (
            <Grid container spacing={3}>
              {allProjects.map((project) => (
                <ProjectItem 
                  key={`${project.type}-${project.id}`} 
                  project={project} 
                />
              ))}
            </Grid>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No projects currently available.
            </Typography>
          )}
        </ProfileContainer>
      </ProfileHeaderBackdrop>

      <ChatModal
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        recipientId={targetUserId}
      />
    </>
  );
}