// RightPanel.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  IconButton,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // <-- Add this import
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // (already imported)

import ChatScreen from '../../../Components/ChatScreen';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../../API';

import ParticipantsPanel from './participantspanel';

export default function RightPanel({
  isMobile,
  isLeftPanelCollapsed,
  selectedConversation,
  selectedConversationId,
  toggleLeftPanel,
  onBackToList // <-- Accept this new prop
}) {
  const queryClient = useQueryClient();

  // State for the rename convo dialog
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [editNameValue, setEditNameValue] = useState('');

  // State for the participants drawer
  const [participantsOpen, setParticipantsOpen] = useState(false);

  // If the user selects a new conversation, reset the input to that conversation’s display_name
  useEffect(() => {
    if (selectedConversation) {
      setEditNameValue(selectedConversation.display_name || '');
    }
  }, [selectedConversation]);

  // Mutation for updating the conversation name on the server
  const updateConversationNameMutation = useMutation(
    async ({ conversationId, display_name }) => {
      return API.conversations.updateConversationDisplayName(conversationId, display_name);
    },
    {
      onSuccess: () => {
        // Re-fetch conversation lists & details to update the UI
        queryClient.invalidateQueries(['messages', 'userSentConversations']);
        if (selectedConversationId) {
          queryClient.invalidateQueries(['messages', selectedConversationId]);
        }
        setEditNameDialogOpen(false);
      },
      onError: (err) => {
        console.error('Error updating conversation name:', err);
      }
    }
  );

  // Handlers for rename dialog
  const handleOpenEditDialog = () => setEditNameDialogOpen(true);
  const handleCloseEditDialog = () => setEditNameDialogOpen(false);

  const handleSaveName = () => {
    if (!selectedConversationId || !editNameValue.trim()) return;
    updateConversationNameMutation.mutate({
      conversationId: selectedConversationId,
      display_name: editNameValue.trim()
    });
  };

  // Toggle the participants panel (drawer)
  const handleToggleParticipants = () => {
    setParticipantsOpen((prev) => !prev);
  };

  return (
    <Card sx={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* Show a "Back" arrow on mobile if a conversation is selected */}
          {isMobile && selectedConversationId && (
            <IconButton onClick={onBackToList}>
              <ChevronLeftIcon />
            </IconButton>
          )}

          {/* Desktop toggle button (hidden on mobile) */}
          {!isMobile && (
            <IconButton onClick={toggleLeftPanel}>
              {isLeftPanelCollapsed ? <MenuIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}

          <Typography variant="h6" noWrap>
            {selectedConversation
              ? selectedConversation.display_name || `Conversation #${selectedConversationId}`
              : 'Select a conversation'}
          </Typography>

          {selectedConversationId && (
            <IconButton onClick={handleOpenEditDialog} size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Box>

        {/* PersonAddIcon to open participants drawer */}
        {selectedConversationId && (
          <IconButton onClick={handleToggleParticipants}>
            <PersonAddIcon />
          </IconButton>
        )}
      </Box>

      <Divider />

      {/* Main chat area */}
      {selectedConversationId ? (
        <ChatScreen
          conversationId={selectedConversationId}
          sx={{ flex: 1, minHeight: '0px' }}
        />
      ) : (
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h6" color="text.secondary">
            Select a conversation
          </Typography>
        </Box>
      )}

      {/* Rename Conversation Dialog */}
      <Dialog open={editNameDialogOpen} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
        <DialogTitle>Rename Conversation</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            label="Display Name"
            value={editNameValue}
            onChange={(e) => setEditNameValue(e.target.value)}
            fullWidth
            placeholder="e.g. 'Project Alpha Updates'"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSaveName}
            disabled={updateConversationNameMutation.isLoading}
          >
            {updateConversationNameMutation.isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Participants Drawer */}
      <Drawer anchor="right" open={participantsOpen} onClose={handleToggleParticipants}>
        <ParticipantsPanel conversationId={selectedConversationId} onClose={handleToggleParticipants} />
      </Drawer>
    </Card>
  );
}
