import React, { useState, useCallback, useEffect, useRef } from "react";
import client from "../../../../API";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Container,
  Stepper,
  Step,
  StepLabel,
  Divider,
  TextField,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DownloadIcon from "@mui/icons-material/Download";
import GoogleIcon from "@mui/icons-material/Google";
import { Share } from "@mui/icons-material";

import TabPanel from "../../../../Components/TabPanel.js";
import CampaignProjectionsPanel from "../individualcampaigns/campaignTabs/campaignProjections.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import useAuth from "../../../../Hooks/use-auth.js";
import SelectAssetDialog from "../../../../Components/tools/selectfile";
import Tools from "./tools.js";
import TimelineTab from "./timeline";
import PageTitle from "../../../../Components/PageTitle";
import {
  getFollowersByVideos,
  getTotalFollowersInDict,
  parseLocalFloat,
  parseLocalInt,
} from "../../../../Utils/constants.js";
import { TimelineStatus } from "../../../../Utils/constants";
import BrandNegotiationDialog from "./brandnegotiatedialog.js";
import ShareCampaignDialog from "./share.js";
import CreatorRow from "./creatorRow.js";
import CampaignActionCards from "./campaignActionCards.js";
import useAlertDialog from "../../../../Components/useAlertDialog.js";
import AlertDialog from "../../../../Components/AlertDialog.js";

const RainbowButton = styled(Button)(({ theme }) => ({
  background:
    "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)",
  backgroundSize: "400% 400%",
  animation: "rainbow 5s ease infinite",
  color: "white",
  "@keyframes rainbow": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  "&:hover": {
    backgroundColor: "darkgreen",
  },
}));


const ActionsButton = ({
  isDraft,
  isLaunched,
  handleAddCreatorsClick,
  handleConfirmCompleteCampaign,
  handleDownloadCSV,
  handleExportToGoogleSheets,
  isTcc,
  isLoading,
  isEditable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="flex-end" gap={2}>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Button onClick={handleMoreActionsClick} startIcon={<MoreVertIcon />}>
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMoreActionsClose}
          >
            {isDraft && (
              <MenuItem onClick={handleAddCreatorsClick}>
                <ListItemIcon>
                  <AddCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add Creators</ListItemText>
              </MenuItem>
            )}
            {isLaunched && isTcc && (
              <MenuItem onClick={handleConfirmCompleteCampaign}>
                <ListItemIcon>
                  <CheckIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Complete Campaign</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={handleDownloadCSV}>
              <ListItemIcon>
                <DownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download as CSV</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleExportToGoogleSheets}>
              <ListItemIcon>
                <GoogleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export to Google Sheets</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

const EditCampaignPage = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isDesktop = useIsDesktop();
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const isTcc = currentUser?.company_name === "TCC";
  const {dialogState, openDialog, closeDialog} = useAlertDialog();

  const [campaignDetails, setCampaignDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isEditingName, setEditingName] = useState(false);
  const [isEditingBrief, setEditingBrief] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedBrief, setEditedBrief] = useState("");
  const [creatorsToRemove, setCreatorsToRemove] = useState([]);
  const [showLaunchConfirmDialog, setShowLaunchConfirmDialog] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [expandedCreator, setExpandedCreator] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const changedEvents = useRef(false);

  // State for assets functionality
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [currentCreatorIndex, setCurrentCreatorIndex] = useState(null);
  const [negotiationDialogOpen, setNegotiationDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isNegotiating, setIsNegotiating] = useState(false);

  // For "Drop Creator" confirmation
  const [dropCreatorDialogOpen, setDropCreatorDialogOpen] = useState(false);
  const [creatorToDrop, setCreatorToDrop] = useState(null);

  const { isFetching, data, refetch: refetchCampaign } = useQuery(
    ["campaign", campaignId],
    () => client.campaigns.details(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: files, refetch: refetchFiles } = useQuery("files", () =>
    client.files.fetch(),
    { refetchOnWindowFocus: false }
  );

  const { data: projectionsData } = useQuery(
    ["campaignProjections", campaignId],
    () => client.campaigns.fetch(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const InvalidateCampaign = () => {
    queryClient.invalidateQueries(["campaign", campaignId]);
  };

  useEffect(() => {
    if (data) {
      setCampaignDetails({
        ...data,
        creators: data.creators.map((creator) => ({
          ...creator,
          price: typeof creator?.price === "number" ? creator.price.toFixed(2) : "",
          agencyRate:
            typeof creator?.agencyRate === "number"
              ? creator.agencyRate.toFixed(2)
              : "",
        })),
      });
      setEditedName(data.name || "");
      setEditedBrief(data.brief || "");
      setUnsavedChanges(false);
      setTimelineEvents(
        data.timelineEvents.map((event) => ({
          ...event,
          projected_date: new Date(event.projected_date),
          will_delete: false,
        }))
      );
    }
  }, [data]);

  const AddTimelineEvent = (creator) => {
    changedEvents.current = true;
    setTimelineEvents((prev) => [
      ...prev,
      {
        campaign_id: campaignId,
        id: null,
        last_updated: null,
        manager_completed: false,
        objective: "",
        projected_date: new Date(Date.now()),
        status: TimelineStatus[0],
        notes: "",
        manager_notes: "",
        username: creator,
        will_delete: false,
      },
    ]);
  };

  const OnEditTimelineField = (targetIndex, target, value) => {
    changedEvents.current = true;
    setTimelineEvents(
      timelineEvents.map((event, index) => {
        if (index === targetIndex) {
          return { ...event, [target]: value };
        } else return event;
      })
    );
  };

  const { mutateAsync: updateTimeline, isLoading: isUpdatingTimeline } =
    useMutation(({ id, input }) => client.campaigns.timeline_edit(id, input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignDetails.id]);
        alert("Timeline updated successfully.");
      },
      onError: (error) => {
        if (error.response && error.response.data && error.response.data.error)
          alert(`Error: ${error.response.data.error}`);
        else alert(`Error: ${error.message}`);
      },
    });

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFieldChange = (creatorId, field, value) => {
    const updatedCreators = campaignDetails.creators.map((creator) =>
      creator.id === creatorId ? { ...creator, [field]: value } : creator
    );
    setCampaignDetails((prevState) => ({
      ...prevState,
      creators: updatedCreators,
    }));
    setUnsavedChanges(true);
  };

  // If assets are approved, we treat status as 'Approved' in final saving.
  const getStatusForCreator = (creator) => {
    if (creator.assetsApproved) {
      return "Approved";
    }
    return creator.status;
  };

  const saveChanges = () => {
    // If timeline was changed
    if (changedEvents.current === true) {
      updateTimeline({
        id: campaignDetails.id,
        input: timelineEvents
          .filter((event) => event.will_delete === false)
          .map(({ will_delete, ...event }) => ({
            ...event,
            projected_date: event.projected_date.toUTCString(),
          })),
      });
      changedEvents.current = false;
    }

    const payload = {
      campaignId,
      creators: campaignDetails.creators.map((creator) => ({
        id: creator.id,
        status: creatorsToRemove.includes(creator.id)
          ? "Dropped"
          : getStatusForCreator(creator),
        price: parseLocalFloat(creator.price),
        agencyRate: parseLocalFloat(creator.agencyRate),
        assetsSent: creator.assetsSent,
        assetsApproved: creator.assetsApproved,
        skipPayout: creator.skipPayout,
        creatorBrief: creator.creatorBrief,
        postingInstructions: creator.postingInstructions,
        boostCode: creator.boostCode,
      })),
    };

    updateCampaignCreatorSpecs(payload);
    setUnsavedChanges(false);
  };

  const saveMetaData = () => {
    const payload = {
      id: campaignId,
      name: editedName,
      brief: editedBrief,
      campaign_sum: totalCampaignSum,
    };
    updateMetaData(payload);
    setUnsavedChanges(false);
  };

  const handleSaveCampaignDetails = () => {
    saveMetaData();
    setEditingName(false);
    setEditingBrief(false);
  };

  const totalCampaignSum = campaignDetails?.creators.reduce((acc, creator) => {
    const price = parseFloat((creator.price || "0").toString().replace(/[^\d.]/g, ""));
    return acc + price;
  }, 0);

  const showSkipPayment = campaignDetails?.creators.some(
    (creator) => creator.skipPayout === true
  );

  const totalCreatorSum = campaignDetails?.creators.reduce((acc, creator) => {
    const cost = parseFloat(
      (creator.agencyRate || "0").toString().replace(/[^\d.]/g, "")
    );
    return acc + cost;
  }, 0);

  const {
    mutate: updateCampaignCreatorSpecs,
    mutateAsync: updateCreatorSpecsAsync,
    isLoading: isLoadingUpateSpecs,
  } = useMutation((payload) => client.campaigns.updateCampaignCreatorSpecs(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["campaign", campaignId]);
      refetchCampaign();
      alert("Creator details updated successfully!");
    },
    onError: (error) => {
      alert(error.response?.data?.error || error.message);
    },
  });

  const { mutate: updateMetaData, isLoading: isLoadingUpdateMetadata } = useMutation(
    (payload) => client.campaigns.updateMetaData(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign metadata updated successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const { mutate: launchCampaign, isLoading: isLoadingLaunch } = useMutation(
    (payload) => client.campaigns.launch(payload),
    {
      onSuccess: () => {
        alert("Campaign launched successfully!");
        queryClient.invalidateQueries(["campaign", campaignId]);
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const handleLaunchCampaign = () => {
    setShowLaunchConfirmDialog(true);
  };

  const handleConfirmLaunch = async () => {
    setIsLaunching(true);
    setShowLaunchConfirmDialog(false);

    const creatorNames = campaignDetails?.creators?.map((c) => c.name) || [];
    const creatorPromotionTypes =
      campaignDetails?.creators?.map((c) => ({
        name: c.name,
        promotionType: c.promotionType,
      })) || [];
    const creatorPrices =
      campaignDetails?.creators?.reduce((acc, c) => {
        acc[c.name] = parseLocalFloat(c.price);
        return acc;
      }, {}) || {};

    const agencyRates =
      campaignDetails?.creators?.reduce((acc, c) => {
        acc[c.name] = parseLocalFloat(c.agencyRate || "0");
        return acc;
      }, {}) || {};

    const payload = {
      campaignId: campaignDetails?.id,
      campaignName: campaignDetails?.name,
      campaignBrief: campaignDetails?.brief,
      creatorNames,
      creatorPromotionTypes,
      creatorPrices,
      agencyRates,
      blitzautocampaign: true,
      idealduedate: campaignDetails?.ideal_duedate,
      userEmail: campaignDetails?.user_email,
      sharedWithEmail: campaignDetails?.shared_with_email,
    };

    try {
      launchCampaign(payload);
    } finally {
      setIsLaunching(false);
    }
  };

  const handleCancelLaunch = () => {
    setShowLaunchConfirmDialog(false);
  };

  const { mutate: completeCampaign, isLoading: isLoadingComplete } = useMutation(
    (input) => client.campaigns.complete(input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const { mutate: completeCreator, isLoading: isLoadingCompleteCreator } =
    useMutation((input) => client.campaigns.completeCreator(campaignId, input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Creator completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const { mutate: completeVideo, isLoading: isLoadingCompleteVideo } =
    useMutation((input) => client.campaigns.completeVideo(campaignId, input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Creator completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const handleConfirmCompleteCampaign = async () => {
    completeCampaign({ index: campaignId, params: {} });
  };

  const handleCompleteVideo = (creatorId, videoId) => {
    const creatorInfo = campaignDetails.creators.find((creator) => creator.id === creatorId);
    const parentVideos = creatorInfo.videos.filter((video) => !video.parentId);
    const parentVideoCount = parentVideos.length;

    console.log("parentVideos", parentVideos, "Creator id", creatorId, "Video id", videoId);

    const payment_per_video = (creatorInfo.agencyRate || creatorInfo.price) / parentVideoCount;

    /*
    
    */

    openDialog("Confirm", <>Are you sure you want to generate a payout for this video?<br/>The creator will be payed {payment_per_video.toLocaleString()}</>, () => {
      closeDialog();
      completeVideo({ creator: creatorId, video: videoId });
    }, closeDialog, "Yes", "Cancel");
  }

  const downloadCSV = () => {
    const csvContent = convertToCSV(campaignDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `campaign_details_${campaignId}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    try {
      const response = await client.campaigns.exportSheet({
        campaignId,
        campaignDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      } else {
        console.error("Error exporting to Google Sheets:", response.error);
      }
    } catch (error) {
      console.error(
        "Failed to export to Google Sheets:",
        error.response?.data?.error || error.message
      );
    }
  };

  const convertToCSV = (data) => {
    if (!data || !Array.isArray(data.creators)) return "";

    const headers = [
      "Name",
      "Following",
      "Status",
      "Price",
      "Offer Sent",
      "Assets Sent",
      "Assets Approved",
      "Promotion Type",
      "Likes",
      "Comments",
      "Total Views",
      "Post Date",
      "Platform Link",
      "Live Link",
    ];

    const rows = data.creators.flatMap((creator) => [
      ...creator.videos.map(video => [creator.name ?? "",
      getTotalFollowersInDict(getFollowersByVideos(creator.videos)) ?? "",
      creator.status ?? "",
      creator.price ?? "",
      creator.offerSent ? "Yes" : "No",
      creator.assetsSent ? "Yes" : "No",
      creator.assetsApproved ? "Yes" : "No",
      video.promotionPlatform + " " + video.promotionType ?? "",
      video.likes ?? 0,
      video.comments ?? 0,
      video.totalViews ?? 0,
      video.postDate ?? "",
      video.platformLink ?? "",
      video.liveLink ?? "",])
    ]);

    console.log(rows);

    const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
    return csvContent;
  };

  const handleExpandClick = (creatorId) => {
    setExpandedCreator(expandedCreator === creatorId ? null : creatorId);
  };

  // "Check or create conversation" logic
  const checkConversation = useMutation(client.conversations.checkCampaign, {
    onSuccess: (data, variables) => {
      if (data.exists) {
        navigateToConversation(variables.creatorName);
      } else {
        createConversation.mutate(variables);
      }
    },
    onError: (error) => {
      console.error("Check response error:", error);
    },
  });

  const createConversation = useMutation(client.conversations.createCampaign, {
    onSuccess: (data, variables) => {
      navigateToConversation(variables.creatorName);
    },
    onError: (error) => {
      console.error("Create response error:", error);
    },
  });

  const startConversation = (creatorId, creatorName) => {
    const payload = {
      campaign_id: campaignId,
      creator_id: creatorId,
      creatorName: creatorName,
    };
    checkConversation.mutate(payload);
  };

  const navigateToConversation = (creatorName) => {
    navigate(`/campaigns/convo/${campaignId}/${creatorName}`);
  };

  // Stub for "Resend Offer" - add your actual mutation or logic here
  const { mutate: resendOfferMutation, isLoading: isResendingOffer } = useMutation(
    ({ campaignId, creatorName }) =>
      client.campaigns.resendOffer(campaignId, creatorName),
    {
      onSuccess: data => alert(data.message),
      onError: err => alert(err.response?.data?.error || err.message)
    }
  );

  const handleResendOfferClick = (creatorName) => {
    resendOfferMutation({ campaignId, creatorName });
  };


  const { mutate: handleNegotiationResponse } = useMutation(
    (payload) => client.campaigns.handleNegotiationResponse(campaignId, payload),
    {
      onMutate: () => setIsNegotiating(true),
      onSuccess: () => {
        refetchCampaign();
        setNegotiationDialogOpen(false);
        alert("Response submitted successfully");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
      onSettled: () => setIsNegotiating(false),
    }
  );

  // Asset insertion logic
  const handleDialogOpen = (index) => {
    setCurrentCreatorIndex(index);
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed) => {
    if (confirmed && selectedAsset) {
      const updatedCreators = campaignDetails.creators.map((creator, idx) => {
        if (idx === currentCreatorIndex) {
          return {
            ...creator,
            creatorBrief: `${creator.creatorBrief}\n${selectedAsset.file_url}`,
          };
        }
        return creator;
      });

      const updatedDetails = { ...campaignDetails, creators: updatedCreators };
      setCampaignDetails(updatedDetails);

      const payload = {
        campaignId: updatedDetails.id,
        creators: updatedDetails.creators.map((creator) => ({
          id: creator.id,
          creatorBrief: creator.creatorBrief,
        })),
      };

      updateCampaignCreatorSpecs(payload);
    }
    setDialogOpen(false);
    setSelectedAsset(null);
  };

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
  };

  // "Drop Creator" confirm
  const confirmDropCreator = (creatorId) => {
    setCreatorToDrop(creatorId);
    setDropCreatorDialogOpen(true);
  };

  const handleDropCreatorConfirm = () => {
    if (creatorToDrop) {
      // Mark as dropped
      handleFieldChange(creatorToDrop, "status", "Dropped");
    }
    setDropCreatorDialogOpen(false);
    setCreatorToDrop(null);
  };

  const handleDropCreatorCancel = () => {
    setDropCreatorDialogOpen(false);
    setCreatorToDrop(null);
  };

  if (!campaignDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading campaign details...
      </Typography>
    );
  }

  function parseSafeNumber(value) {
    return typeof value === 'number' ? value : parseLocalInt(value) || 0;
  }

  const totalSpend = campaignDetails.creators
    .reduce((acc, creator) => acc + parseFloat(creator.price || 0), 0)
    .toFixed(2);

  const totalViews = campaignDetails.creators.reduce(
    (acc, creator) => acc + (creator.videos.reduce((vidAdd, video) => parseSafeNumber(video.totalViews), 0) || 0),
    0
  );

  const totalEngagement = campaignDetails.creators.reduce(
    (acc, creator) => acc + (creator.videos.reduce((vidAdd, video) => parseSafeNumber(video.likes), 0) || 0) + (creator.videos.reduce((vidAdd, video) => parseSafeNumber(video.comments), 0) || 0),
    0
  );

  const creatorCount = campaignDetails.creators.length;

  const isDraft = campaignDetails?.campaign_status === "Draft";
  const isLaunched = campaignDetails?.campaign_status === "Launched";
  const isEditable = isTcc || (isDraft && isDesktop);

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={
          isFetching ||
          isLaunching ||
          isLoadingComplete ||
          isLoadingCompleteCreator ||
          isLoadingLaunch ||
          isLoadingUpateSpecs ||
          isLoadingUpdateMetadata ||
          isLoadingCompleteVideo
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          centered
          sx={{ width: "100%" }}
        >
          <Tab label="Overview" />
          <Tab label="To Do" />
          <Tab label="Projections" />
          <Tab label="Tools" />
        </Tabs>
      </AppBar>

      <Container maxWidth="xl">
        <Box sx={{ margin: 2 }}>
          <Stepper
            activeStep={
              campaignDetails.campaign_status === "Completed"
                ? 2
                : campaignDetails.campaign_status === "Launched"
                  ? 1
                  : 0
            }
          >
            <Step>
              <StepLabel>Draft</StepLabel>
            </Step>
            <Step>
              <StepLabel>Launched</StepLabel>
            </Step>
            <Step>
              <StepLabel>Completed</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <PageTitle title={`Edit Campaign - ${campaignDetails.name} - Blitz`} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 3,
            px: 2,
          }}
        >
          <Typography variant="h4">
            {isEditingName ? (
              <TextField
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                variant="outlined"
                size="small"
              />
            ) : (
              campaignDetails.name
            )}
          </Typography>
          {isEditable && (
            <IconButton
              onClick={() =>
                isEditingName ? handleSaveCampaignDetails() : setEditingName(true)
              }
            >
              {isEditingName ? <CheckIcon /> : <EditIcon />}
            </IconButton>
          )}
        </Box>
        <CampaignActionCards
          campaignName={campaignDetails.name}
          brief={editedBrief}
          totalSpend={totalSpend}
          totalViews={totalViews}
          totalEngagement={totalEngagement}
          creatorCount={creatorCount}
          isEditable={isEditable}
          isEditingBrief={isEditingBrief}
          editedBrief={editedBrief}
          setEditedBrief={setEditedBrief}
          handleSaveCampaignDetails={handleSaveCampaignDetails}
          setEditingBrief={setEditingBrief}
        />
        <Box
          sx={{
            px: 2,
            mb: 4,
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
            rowGap: 2,
          }}
        >
          {/* Campaign Status Buttons */}
          {isDraft && (
            <RainbowButton variant="contained" onClick={handleLaunchCampaign}>
              Launch Campaign
            </RainbowButton>
          )}

          {/* Action Buttons */}
          <ActionsButton
            isDraft={isDraft}
            isLaunched={campaignDetails.campaign_status === "Launched"}
            handleAddCreatorsClick={() => navigate(`/add-creators/${campaignId}`)}
            handleConfirmCompleteCampaign={handleConfirmCompleteCampaign}
            handleDownloadCSV={downloadCSV}
            handleExportToGoogleSheets={exportToSheets}
            isTcc={isTcc}
            isLoading={isLaunching}
            isEditable={isEditable}
          />

          {/* Save Button */}
          {isEditable && (
            <Button variant="contained" color="primary" onClick={saveChanges}>
              Save Changes
            </Button>
          )}

          {/* Share Buttons */}
          <Button
            variant="contained"
            color="info"
            onClick={() => setShareDialogOpen(true)}
            startIcon={<Share />}
          >
            Share Campaign
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/campaigns/report/${campaignId}`)}
          >
            Blitz Report
          </Button>

          {/* Deliverables Drive Button */}
          {campaignDetails.drive_link ? (
            <GreenButton
              variant="contained"
              href={campaignDetails.drive_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Deliverables Drive
            </GreenButton>
          ) : (
            <Typography variant="body2" color="text.secondary">
              Deliverables Drive: Not Launched
            </Typography>
          )}

          {/* Unsaved Changes Warning */}
          {unsavedChanges && (
            <Typography variant="subtitle2" color="error">
              You have unsaved changes
            </Typography>
          )}
        </Box>

        <Divider sx={{ marginBlockStart: 2 }} />
        <Box
          sx={{
            paddingInline: { xs: 1, md: 4 },
            marginBlockStart: { xs: 1, md: 2 },
          }}
        >
          <TabPanel value={selectedTab} index={0}>
            <Typography variant="h6" gutterBottom>
              Creators
            </Typography>
            <List sx={{ width: "100%" }}>
              {campaignDetails.creators.map((creator, index) => (
                <CreatorRow
                  key={creator.id}
                  creator={creator}
                  campaignDetails={campaignDetails}
                  index={index}
                  expandedCreator={expandedCreator}
                  isLaunched={isLaunched}
                  isTcc={isTcc}
                  showSkipPayment={showSkipPayment}
                  handleFieldChange={handleFieldChange}
                  handleExpandClick={handleExpandClick}
                  handleDialogOpen={handleDialogOpen}
                  handleResendOfferClick={handleResendOfferClick}
                  confirmDropCreator={confirmDropCreator}
                  startConversation={startConversation}
                  completeCreator={completeCreator}
                  completeVideo={handleCompleteVideo}
                  setSelectedCreator={setSelectedCreator}
                  setNegotiationDialogOpen={setNegotiationDialogOpen}
                />
              ))}
            </List>
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <TimelineTab
              campaignDetails={campaignDetails}
              timelineEvents={timelineEvents}
              onEditField={OnEditTimelineField}
              onCreateRow={AddTimelineEvent}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {projectionsData ? (
              <CampaignProjectionsPanel campaignDetails={projectionsData} />
            ) : (
              <Typography>Loading projections...</Typography>
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Tools campaignDetails={campaignDetails} onUpdate={InvalidateCampaign} />
          </TabPanel>
        </Box>
      </Container>

      {/* Launch Confirm Dialog */}
      <Dialog open={showLaunchConfirmDialog} onClose={handleCancelLaunch}>
        <DialogTitle>Confirm Launch</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to launch this campaign? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLaunch} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLaunch} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Select Asset Dialog */}
      <SelectAssetDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        files={files}
        selectedAsset={selectedAsset}
        onAssetClick={handleAssetClick}
        refetchFiles={refetchFiles}
      />

      {/* Negotiation Dialog */}
      <BrandNegotiationDialog
        open={negotiationDialogOpen}
        handleClose={() => setNegotiationDialogOpen(false)}
        creator={selectedCreator}
        currentOffer={selectedCreator?.originalCounterOffer || 0}
        onRespond={handleNegotiationResponse}
        isSubmitting={isNegotiating}
      />

      {/* Share Campaign Dialog */}
      <ShareCampaignDialog
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setEmail("");
        }}
        campaignDetails={campaignDetails}
        email={email}
        setEmail={setEmail}
      />

      {/* Drop Creator Confirm Dialog */}
      <Dialog open={dropCreatorDialogOpen} onClose={handleDropCreatorCancel}>
        <DialogTitle>Confirm Drop</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to drop this creator? This cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDropCreatorCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDropCreatorConfirm} color="error">
            Drop
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCampaignPage;
