import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  IconButton,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  TableBody,
  CircularProgress,
  CardActions,
  Pagination,
  Paper,
  Chip,
  Avatar,
  Stack,
  InputAdornment,
  Tooltip,
  ButtonGroup,
  TableCell,
  Badge
} from "@mui/material";
import {
  Delete as DeleteIcon,
  ContentCopy as ContentCopyIcon,
  Share as ShareIcon,
  Search as SearchIcon,
  GridView as GridViewIcon,
  TableRows as TableRowsIcon,
  Person as PersonIcon,
  AttachMoney as AttachMoneyIcon
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import client from "../../../API";
import profilePhoto from "../../../Components/globalAssets/ppfLogo.png";
import useAuth from "../../../Hooks/use-auth";
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';
import useIsDesktop from '../../../Hooks/useIsDesktop';

function formatCampaignSum(sum) {
  const numericSum =
    typeof sum === "number" ? sum : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const statusColorMap = {
  Active: 'success',
  active: 'success',
  Launched: 'success',
  Pending: 'warning',
  Draft: 'warning',
  Completed: 'info',
  Archived: 'default',
  DELETED: 'error',
  deleted: 'error'
};

const Collaborations = () => {
  const isDesktop = useIsDesktop();

  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState('All');
  const [isPrettyView, setIsPrettyView] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [actionStatus, setActionStatus] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [currentManager, setCurrentManager] = useState('');
  const navigate = useNavigate();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const currentUserId = currentUser.id;
  const userCompany = currentUser.company_name;
  const isTcc = userCompany === "TCC";

  const [page, setPage] = useState(0);
  const itemsPerPage = 20;

  // Fetch managers if TCC
  const {
    data: managers,
    isLoading: isLoadingManagers,
    refetch: fetchManagers
  } = useQuery('managers', client.companies.listUsers, {
    refetchOnWindowFocus: false,
    initialData: [],
    enabled: isTcc
  });

  // Fetch Campaigns (these should now include 'unread_notifs' for the current user)
  const {
    data: campaignsData,
    isLoading: isLoadingCampaigns,
    refetch: fetchCampaigns
  } = useQuery('campaigns', client.campaigns.list, {
    refetchOnWindowFocus: false
  });

  // Fetch Partnerships (now ideally returning 'unread_notifs' from backend)
  const {
    data: partnershipsData,
    isLoading: isLoadingPartnerships,
    refetch: fetchPartnerships
  } = useQuery('partnerships', client.partnerships.list, {
    refetchOnWindowFocus: false
  });

  // Reset page on filter changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery, searchStatus, currentManager, tabValue]);

  // Combine & filter campaigns/partnerships
  const collaborations = useMemo(() => {
    if (!campaignsData || !partnershipsData) return [];

    // Each campaign might have "unread_notifs"
    const campaignsWithType = campaignsData.map((campaign) => ({
      ...campaign,
      type: 'campaign',
      unread_notifs: campaign.unread_notifs || 0,
      pfphref: campaign.creators?.[0]?.pfphref || profilePhoto
    }));

    // Partnerships might also have "unread_notifs" from backend; default to 0 if missing
    const partnershipsWithType = partnershipsData
      .filter((partnership) => partnership.status !== 'DELETED')
      .map((partnership) => ({
        ...partnership,
        type: 'partnership',
        unread_notifs: partnership.unread_notifs || 0,
        pfphref: partnership.pfphref || profilePhoto
      }));

    return [...campaignsWithType, ...partnershipsWithType]
      .sort((a, b) => {
        const dateA = getSortDate(a);
        const dateB = getSortDate(b);
        if (dateB - dateA !== 0) {
          // Sort by proposal_date desc
          return dateB - dateA;
        } else {
          // Then by finish_date asc
          const finishDateA = a.finish_date ? new Date(a.finish_date) : null;
          const finishDateB = b.finish_date ? new Date(b.finish_date) : null;
          if (finishDateA && finishDateB) {
            return finishDateA - finishDateB;
          } else {
            return 0;
          }
        }
      })
      .filter((item) => {
        // 1) Filter archived/deleted if not in Archived tab
        if (tabValue !== 'Archived') {
          if (
            (item.type === 'campaign' && item.campaign_status === 'Archived') ||
            (item.type === 'partnership' && item.status === 'DELETED')
          ) {
            return false;
          }
        }

        // 2) Filter by tab value
        let tabMatch = true;
        if (tabValue === 'Campaigns') {
          tabMatch = item.type === 'campaign';
        } else if (tabValue === 'Partnerships') {
          tabMatch = item.type === 'partnership';
        } else if (tabValue === 'Archived') {
          if (item.type === 'campaign') {
            tabMatch = item.campaign_status === 'Archived';
          } else if (item.type === 'partnership') {
            tabMatch =
              item.status === 'DELETED' || item.status === 'Archived';
          }
        }

        // 3) Filter by status
        let statusMatch = true;
        if (searchStatus !== '') {
          switch (searchStatus) {
            case 'active':
              if (item.type === 'campaign') {
                statusMatch = item.campaign_status === 'Launched';
              } else if (item.type === 'partnership') {
                statusMatch = item.status === 'Active';
              }
              break;
            case 'pending':
              if (item.type === 'campaign') {
                statusMatch = item.campaign_status === 'Draft';
              } else if (item.type === 'partnership') {
                statusMatch = item.status === 'pending';
              }
              break;
            case 'completed':
              if (item.type === 'campaign') {
                statusMatch = item.campaign_status === 'Completed';
              } else if (item.type === 'partnership') {
                statusMatch = item.status === 'completed';
              }
              break;
            default:
              break;
          }
        }

        // 4) Filter by search query (name)
        const searchMatch = item.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        // 5) Filter by manager/supervisor if TCC
        let managerMatch = true;
        if (isTcc && currentManager !== '') {
          if (item.type === 'campaign') {
            managerMatch =
              item.campaign_manager &&
              item.campaign_manager.email === currentManager;
          } else {
            managerMatch =
              item.partnership_supervisor &&
              item.partnership_supervisor.email === currentManager;
          }
        }

        return tabMatch && searchMatch && managerMatch && statusMatch;
      });
  }, [
    campaignsData,
    partnershipsData,
    searchQuery,
    tabValue,
    currentManager,
    searchStatus,
    isTcc
  ]);

  const loading = isLoadingCampaigns || isLoadingPartnerships;

  // Helper: get the date for sorting
  function getSortDate(item) {
    const proposalDate = new Date(item.proposal_date);
    const finishDate = item.finish_date ? new Date(item.finish_date) : null;
    if (!isNaN(proposalDate.getTime())) {
      return proposalDate;
    } else if (finishDate && !isNaN(finishDate.getTime())) {
      return finishDate;
    } else {
      return new Date(0);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Campaign / Partnership actions
  const { mutate: deleteCampaign } = useMutation(client.campaigns.delete, {
    onSuccess: () => {
      openDialog('Success', "Campaign deleted successfully!", closeDialog, closeDialog, 'Ok');
      fetchCampaigns();
    },
    onError: (error) => {
      console.error("Error deleting campaign:", error);
      openDialog(
        'Error',
        <>Error deleting campaign:<br />{error?.response?.data?.error || error.message}</>,
        closeDialog,
        closeDialog,
        'Ok'
      );
    }
  });

  const { mutate: updateCampaignStatus } = useMutation(client.campaigns.update, {
    onSuccess: () => {
      openDialog('Success', "Campaign status updated successfully!", closeDialog, closeDialog, 'Ok');
      fetchCampaigns();
    },
    onError: (error) => {
      console.error("Error updating campaign:", error);
      openDialog(
        'Error',
        <>Error updating status:<br />{error?.response?.data?.error || error.message}</>,
        closeDialog,
        closeDialog,
        'Ok'
      );
    }
  });

  const { mutate: updatePartnershipStatus } = useMutation(
    ({ id, status }) => client.partnerships.updateStatus(id, { status }),
    {
      onSuccess: () => {
        openDialog('Success', "Partnership status updated successfully!", closeDialog, closeDialog, 'Ok');
        fetchPartnerships();
      },
      onError: (error) => {
        console.error("Error updating partnership status:", error);
        openDialog(
          'Error',
          <>Error updating status:<br />{error?.response?.data?.error || error.message}</>,
          closeDialog,
          closeDialog,
          'Ok'
        );
      }
    }
  );

  const applyAction = () => {
    if (selectedIds.length === 0) {
      openDialog("Info", "Please select items to apply action.", closeDialog, closeDialog, 'Ok');
      return;
    }
    if (actionStatus.length === 0) {
      openDialog("Info", "Please choose an action.", closeDialog, closeDialog, 'Ok');
      return;
    }

    selectedIds.forEach((id) => {
      const [type, itemId] = id.split('-');
      if (type === 'campaign') {
        if (actionStatus === 'delete' || actionStatus === 'DELETED') {
          deleteCampaign({ id: [itemId] });
        } else {
          const status = actionStatus;
          updateCampaignStatus({ campaignIds: [parseInt(itemId)], status });
        }
      } else if (type === 'partnership') {
        if (actionStatus === 'delete' || actionStatus === 'DELETED') {
          updatePartnershipStatus({ id: itemId, status: 'DELETED' });
        } else {
          updatePartnershipStatus({ id: itemId, status: actionStatus });
        }
      }
    });

    setSelectedIds([]);
  };

  const deleteOnConfirm = (item) => {
    if (item.type === 'campaign') {
      deleteCampaign({ id: [item.id] });
    } else if (item.type === 'partnership') {
      updatePartnershipStatus({ id: item.id, status: 'DELETED' });
    }
  };

  const handleDelete = (item) => {
    openDialog(
      'Confirm',
      <>Are you sure you want to delete "{item.name}"?</>,
      () => deleteOnConfirm(item),
      closeDialog,
      'Yes',
      'No'
    );
  };

  const handleItemClick = (item) => {
    if (item.type === 'campaign') {
      navigate(`/editcampaign/${item.id}`);
    } else if (item.type === 'partnership') {
      navigate(`/partnerships/${item.id}`);
    }
  };

  const handleSelectChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const { mutate: copyCampaign } = useMutation(client.campaigns.copy, {
    onSuccess: (data) => {
      openDialog("Success", data.message, closeDialog, closeDialog, 'Ok');
      fetchCampaigns();
    },
    onError: (error) => {
      console.error("Failed to copy campaign:", error);
      openDialog(
        "Error",
        `Error copying campaign:\n${error?.response?.data?.error || error.message}`,
        closeDialog,
        closeDialog,
        'Ok'
      );
    }
  });

  const handleShareCampaign = useCallback(
    (campaignId) => {
      const url = `${window.location.origin}/campaigns/${campaignId}`;
      navigator.clipboard.writeText(url);
      openDialog("Info", "Share link copied to clipboard!", closeDialog, closeDialog, 'Ok');
    },
    [closeDialog, openDialog]
  );

  const handleCopyCampaign = useCallback(
    async (campaignId) => {
      copyCampaign({ campaignId });
    },
    [copyCampaign]
  );

  const getStatusChip = (item) => {
    const status = item.type === 'campaign' ? item.campaign_status : item.status;
    const label = status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A';
    const color = statusColorMap[status] || 'default';
    return <Chip label={label} color={color} size="small" />;
  };

  return (
    <>
      <AlertDialog alertState={dialogState} />

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper variant='outlined' square sx={{ mb: 3 }}>
            <Box sx={{ paddingBlockStart: 3, paddingInline: 3 }}>
              <Typography
                variant="h5"
                component="h1"
                sx={{
                  mb: 2,
                  fontWeight: 700,
                  color: 'text.primary',
                  letterSpacing: '-0.5px'
                }}
              >
                Collaborations
                <Chip
                  label={`${collaborations.length} items`}
                  size="small"
                  sx={{ ml: 1.5, bgcolor: 'action.selected' }}
                />
              </Typography>

              {/* Control Bar */}
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  mb: 1
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ flex: '1 1 300px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" color="action" />
                      </InputAdornment>
                    )
                  }}
                />

                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  <FormControl size="small" sx={{ minWidth: 180 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={searchStatus}
                      onChange={(e) => setSearchStatus(e.target.value)}
                      label="Status"
                    >
                      <MenuItem value="">All Statuses</MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="completed">Completed</MenuItem>
                    </Select>
                  </FormControl>

                  {isTcc && (
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                      <InputLabel>Manager</InputLabel>
                      <Select
                        value={currentManager}
                        onChange={(e) => setCurrentManager(e.target.value)}
                        label="Manager"
                      >
                        <MenuItem value="">All Managers</MenuItem>
                        {managers.map((manager) => (
                          <MenuItem key={manager.email} value={manager.email}>
                            {manager.first_name} {manager.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <ButtonGroup variant="contained" size="small">
                    <Button
                      onClick={() => setIsPrettyView(true)}
                      color={isPrettyView ? 'primary' : 'inherit'}
                    >
                      <GridViewIcon />
                    </Button>
                    <Button
                      onClick={() => setIsPrettyView(false)}
                      color={!isPrettyView ? 'primary' : 'inherit'}
                    >
                      <TableRowsIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>

              {/* Tabs */}
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  '& .MuiTabs-indicator': {
                    height: 3
                  }
                }}
              >
                {['All', 'Campaigns', 'Partnerships', 'Archived'].map((tab) => (
                  <Tab
                    key={tab}
                    label={tab}
                    value={tab}
                    sx={{ textTransform: 'none' }}
                  />
                ))}
              </Tabs>
            </Box>
          </Paper>

          {/* Content Area */}
          <Box sx={{ marginInline: 2 }}>
            {isPrettyView ? (
              <Grid container spacing={2}>
                {collaborations
                  .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
                  .map((item) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      lg={3}
                      xl={3}
                      key={`${item.type}-${item.id}`}
                    >
                      <Card
                        elevation={2}
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: 3
                          }
                        }}
                      >
                        {/* 
                          CardMedia with top-right badge for unread notifications
                        */}
                        <Box sx={{ position: 'relative' }}>
                          <CardMedia
                            component="img"
                            height="250"
                            image={item.pfphref || profilePhoto}
                            alt={item.name}
                            onClick={() => handleItemClick(item)}
                            sx={{
                              cursor: 'pointer',
                              objectFit: 'cover',
                              borderBottom: '1px solid',
                              borderColor: 'divider'
                            }}
                          />
                          {/* Show a floating badge if unread_notifs > 0 */}
                          {item.unread_notifs > 0 && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8
                              }}
                            >
                              <Chip
                                label={`${item.unread_notifs} new`}
                                color="error"
                                size="small"
                                sx={{ fontWeight: 600 }}
                              />
                            </Box>
                          )}
                        </Box>

                        <CardContent sx={{ flexGrow: 1 }}>
                          {/* Status/Type row */}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1.5,
                              gap: 1
                            }}
                          >
                            <Chip
                              label={item.type}
                              size="small"
                              color={item.type === 'campaign' ? 'secondary' : 'primary'}
                              sx={{ textTransform: 'capitalize' }}
                            />
                            {getStatusChip(item)}
                          </Box>

                          <Typography
                            variant="subtitle1"
                            onClick={() => handleItemClick(item)}
                            sx={{
                              cursor: 'pointer',
                              fontWeight: 600,
                              mb: 1,
                              '&:hover': { textDecoration: 'underline' }
                            }}
                          >
                            {item.name}
                          </Typography>

                          <Stack
                            spacing={0.5}
                            sx={{ color: 'text.secondary', fontSize: '0.875rem' }}
                          >
                            {item.type === 'campaign' ? (
                              <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <PersonIcon
                                    fontSize="small"
                                    sx={{ mr: 1, opacity: 0.6 }}
                                  />
                                  {item.user?.username}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <AttachMoneyIcon
                                    fontSize="small"
                                    sx={{ mr: 1, opacity: 0.6 }}
                                  />
                                  {formatCampaignSum(item.campaign_sum)}
                                </Box>
                                {isTcc && (
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    Manager:{' '}
                                    {item.campaign_manager?.name || 'No manager'}
                                  </Box>
                                )}
                              </>
                            ) : (
                              <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <PersonIcon
                                    fontSize="small"
                                    sx={{ mr: 1, opacity: 0.6 }}
                                  />
                                  {item.user?.email || item.email}
                                </Box>
                                {item.total_sum && (
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <AttachMoneyIcon
                                      fontSize="small"
                                      sx={{ mr: 1, opacity: 0.6 }}
                                    />
                                    ${parseFloat(item.total_sum).toFixed(2)}
                                  </Box>
                                )}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  With: {item.creator || 'Unknown creator'}
                                </Box>
                                {isTcc && (
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    Supervisor:{' '}
                                    {item.partnership_supervisor?.name || 'No supervisor'}
                                  </Box>
                                )}
                              </>
                            )}
                          </Stack>
                        </CardContent>

                        <CardActions
                          sx={{
                            justifyContent: 'space-between',
                            borderTop: '1px solid',
                            borderColor: 'divider'
                          }}
                        >
                          <Checkbox
                            checked={selectedIds.includes(`${item.type}-${item.id}`)}
                            onChange={() =>
                              handleSelectChange(`${item.type}-${item.id}`)
                            }
                            size="small"
                          />

                          <Box>
                            {item.type === 'campaign' && (
                              <>
                                <Tooltip title="Share">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleShareCampaign(item.id)}
                                  >
                                    <ShareIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Duplicate">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleCopyCampaign(item.id)}
                                  >
                                    <ContentCopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                onClick={() => handleDelete(item)}
                                color="error"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Paper variant='outlined'>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={
                              selectedIds.length > 0 &&
                              selectedIds.length < collaborations.length
                            }
                            checked={selectedIds.length === collaborations.length}
                            onChange={(e) =>
                              e.target.checked
                                ? setSelectedIds(
                                    collaborations.map((i) => `${i.type}-${i.id}`)
                                  )
                                : setSelectedIds([])
                            }
                          />
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {collaborations
                        .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
                        .map((item) => (
                          <TableRow
                            key={`${item.type}-${item.id}`}
                            hover
                            sx={{ '&:last-child td': { border: 0 } }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedIds.includes(`${item.type}-${item.id}`)}
                                onChange={() =>
                                  handleSelectChange(`${item.type}-${item.id}`)
                                }
                              />
                            </TableCell>

                            <TableCell>
                              {/* 
                                Wrap the avatar in a Badge if there are unread notifs 
                              */}
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Badge
                                  color="error"
                                  // Only show the badge if unread_notifs > 0
                                  badgeContent={item.unread_notifs}
                                  invisible={!(item.unread_notifs > 0)}
                                  overlap="circular"
                                >
                                  <Avatar
                                    src={item.pfphref}
                                    sx={{
                                      width: 32,
                                      height: 32,
                                      mr: 2,
                                      bgcolor:
                                        item.type === 'campaign'
                                          ? 'secondary.light'
                                          : 'primary.light'
                                    }}
                                  >
                                    {item.name[0]}
                                  </Avatar>
                                </Badge>

                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 500, cursor: 'pointer' }}
                                  onClick={() => handleItemClick(item)}
                                >
                                  {item.name}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Chip
                                label={item.type}
                                size="small"
                                color={item.type === 'campaign' ? 'secondary' : 'primary'}
                              />
                            </TableCell>

                            <TableCell>
                              {getStatusChip(item)}
                            </TableCell>

                            <TableCell>
                              <Typography variant="body2">
                                {item.type === 'campaign'
                                  ? item.user?.username
                                  : item.email}
                              </Typography>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton
                                size="small"
                                onClick={() => handleDelete(item)}
                                color="error"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Box>

          {/* Pagination */}
          {collaborations.length > itemsPerPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <Pagination
                count={Math.ceil(collaborations.length / itemsPerPage)}
                page={page + 1}
                onChange={(e, value) => setPage(value - 1)}
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Collaborations;
