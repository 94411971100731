// src/Pages/SomePage/creatorcontainersearch.jsx
// (or wherever your "CreatorContainers" component lives)

import React, { memo } from 'react';
import {
  Card,
  Typography,
  Link,
  CardMedia,
  Grid,
  Box,
  Rating,
  styled,
  useTheme,
  IconButton,
  Button,
} from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import profilePhoto from '../../../Components/globalAssets/ppfLogo.png';
import {
  calculateAverageCreatorRate,
  calculateTotalFollowing,
  getAllPlatformLinks,
  platformToFollowing,
  platPromoToKey,
} from '../../../Utils/constants';

import TikTokIcon from '../../../Components/Icons/TikTok';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import TwitchIcon from '../../../Components/Icons/Twitch';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CastIcon from '@mui/icons-material/Cast';
import LinkIcon from '@mui/icons-material/Link';

const socialIcons = {
  TikTok: <TikTokIcon />,
  Instagram: <InstagramIcon />,
  Youtube: <YouTubeIcon />,
  X: <TwitterIcon />,
  Twitch: <TwitchIcon />,
  Podcasts: <PodcastsIcon />,
  Kick: <CastIcon />,
};

// Define subtle border colors for each platform
const platformBorderColors = {
  Youtube: 'rgba(255, 0, 0, 0.3)',
  Instagram: 'rgba(59, 89, 152, 0.3)',
  TikTok: 'rgba(255, 99, 132, 0.3)',
  Podcasts: 'rgba(128, 0, 128, 0.3)',
  Twitch: 'rgba(100, 65, 165, 0.3)',
  Kick: 'rgba(0, 191, 255, 0.3)',
  X: 'rgba(29, 161, 242, 0.3)',
};

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.primary.main,
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.primary.light,
  },
}));

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'platform',
})(({ theme, platform }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 0,
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  outline: `2px solid ${platformBorderColors[platform] || theme.palette.divider}`,
  outlineOffset: '-2px',
  borderRadius: theme.shape.borderRadius * 1.5,
  transition: 'transform 0.3s, box-shadow 0.3s',
  backgroundColor: theme.palette.background.paper,
  height: 'auto',
  minHeight: '12rem',
  maxHeight: '16rem',
  width: '100%',
  aspectRatio: '12/7',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
}));

/**
 * CreatorContainers
 *
 * PROPS:
 *  - creators (array)
 *  - platform (string)
 *  - promotion (string)
 *  - onAddToCampaign (function) => "Book Creator"
 *  - onChatWithCreator (function) => optional, "Chat with Creator"
 *  - onInviteToPartnership (function) => optional
 *  - selectedItems (Set) => which creators are selected
 */
function CreatorContainers({
  creators,
  platform,
  promotion,
  onAddToCampaign,
  onChatWithCreator,
  selectedItems = new Set(),
  onInviteToPartnership = null,
}) {
  const theme = useTheme();

  const parseNumber = (numStr) => {
    if (numStr === null || numStr === undefined || numStr === 'N/A') {
      return 0;
    }
    if (Number.isFinite(numStr)) return numStr;
    return parseInt(numStr.toString().replace(/,/g, ''), 10);
  };

  return (
    <Grid container spacing={2}>
      {creators.map((creator) => {
        const followerCount = !!platform
          ? parseNumber(creator[platformToFollowing[platform]] || '0')
          : calculateTotalFollowing(creator);

        const avgReview = creator.average_rating || 0;
        const stars = Math.round(avgReview);

        const rate = (platform && promotion)
          ? creator[platPromoToKey[platform]?.[promotion]]
          : calculateAverageCreatorRate(creator);

        const creatorPlatforms = getAllPlatformLinks(creator);

        return (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={creator.creator}>
            <StyledCard
              platform={platform}
              sx={{
                backgroundColor: selectedItems.has(creator.creator)
                  ? theme.palette.action.focus
                  : theme.palette.background.paper,
              }}
            >
              {/* Left: Profile Image and Creator Info */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '40%',
                  overflow: 'visible',
                  justifyContent: 'flex-start',
                }}
              >
                <CardMedia
                  component="img"
                  image={creator.pfphref || profilePhoto}
                  alt={creator.creator}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '60%',
                    aspectRatio: '1/1',
                    objectFit: 'cover',
                    borderRadius: 1,
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
                <Box sx={{ textAlign: 'left' }}>
                  <Link
                    variant="h6"
                    color="text.primary"
                    underline="hover"
                    href={`https://www.useblitz.co/creators/${creator.creator}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      overflow: 'visible',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    @{creator.creator}
                  </Link>
                </Box>
              </Box>

              {/* Right: Rating, Followers, and Actions */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  overflow: 'visible',
                  gap: 1,
                  width: '60%',
                  paddingInlineStart: 1,
                }}
              >
                {/* Creator Stats (Rating, Followers, etc.) */}
                <Box sx={{ textAlign: 'right' }}>
                  {avgReview > 0 ? (
                    <StyledRating value={stars} readOnly />
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      No reviews yet.
                    </Typography>
                  )}

                  <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                    {platform ? 'Followers' : 'Total Followers'}:{' '}
                    {followerCount.toLocaleString()}
                  </Typography>

                  {platform ? (
                    <Link
                      href={
                        creator[`${platform.toLowerCase()}_link`] ||
                        `https://www.useblitz.co/creators/${creator.creator}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="body2"
                      sx={{
                        overflow: 'visible',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: 'text.secondary',
                      }}
                    >
                      View {platform}
                    </Link>
                  ) : (
                    <>
                      {creatorPlatforms.map((p) => (
                        <IconButton
                          color="primary"
                          key={p.link}
                          component="a"
                          href={p.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={p.platform}
                          size="small"
                        >
                          {socialIcons[p.platform] || <LinkIcon />}
                        </IconButton>
                      ))}
                    </>
                  )}

                  <Typography
                    variant="body2"
                    color="text.primary"
                    sx={{
                      overflow: 'visible',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      mb: 0,
                    }}
                  >
                    <i>
                      Suggested offer: <br />${rate?.toLocaleString()}
                    </i>
                  </Typography>
                </Box>

                {/* Action buttons: Book + (optional) Chat */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onAddToCampaign(creator.creator)}
                  >
                    Book
                  </Button>
                  
                  {onChatWithCreator && (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ minWidth: 'auto' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChatWithCreator(creator.creator);
                      }}
                    >
                      <ChatBubbleOutlineIcon fontSize="small" />
                    </Button>
                  )}
                </Box>
              </Box>
            </StyledCard>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default memo(CreatorContainers);
