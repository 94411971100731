import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Badge,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Fade,
  Divider,
  Avatar,
  Stack
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CreatorCampaignPitchedDialog from './creatorcomponents/creatorCampaignPitchedDialog';
import { getOfferFromCampaign, getHeadersFromKeys } from '../../../Utils/constants';

const CheckIsPitched = (campaign, username) => {
  const creator = campaign.creators.find(c => c.id === username);
  return (
    (creator?.status === undefined || 
     creator?.status === 'Pitched' || 
     creator?.status === 'Counter') && // Add Counter status
    campaign.campaign_status === 'Launched'
  );
};

const CreatorCollaborations = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const token = creatorToken?.token;

  const [collaborations, setCollaborations] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [currentCampaign, setCurrentCampaign] = useState(-1);
  const [currentPartnership, setCurrentPartnership] = useState(null);
  const [openPitch, setOpenPitch] = useState(false);
  const [openPartnershipDialog, setOpenPartnershipDialog] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get('tab') || 'All';

  const navigate = useNavigate();

  const fetchCampaigns = async () => {
    if (!token) return [];
    const response = await API.creatorConnect.campaigns(token);
    if (Array.isArray(response)) {
      return response;
    } else if (response && Array.isArray(response.data)) {
      return response.data;
    } else {
      console.error('Unexpected campaigns response:', response);
      return [];
    }
  };

  const fetchPartnerships = async () => {
    const response = await API.creatorConnect.getPartnerships();
    if (Array.isArray(response)) {
      return response;
    } else if (response && Array.isArray(response.data)) {
      return response.data;
    } else {
      console.error('Unexpected partnerships response:', response);
      return [];
    }
  };

  const fetchCastingCalls = async () => {
    const response = await API.castingCalls.creatorFetch();
    if (Array.isArray(response)) {
      return response;
    } else if (response && Array.isArray(response.casting_calls)) {
      return response.casting_calls;
    } else {
      console.error('Unexpected casting calls response:', response);
      return [];
    }
  };

  const fetchDeals = async () => {
    if (!token) return [];
    try {
      const response = await API.dealsIO.creatorFetch();
      return Array.isArray(response) ? response : [response];
    } catch (error) {
      console.error("Error fetching deals:", error);
      return [];
    }
  };

  const {
    isLoading: campaignsLoading,
    error: campaignsError,
    data: campaigns,
    refetch: refetchCampaigns,
  } = useQuery(['campaigns', 'creator', username], fetchCampaigns, {
    initialData: [],
    keepPreviousData: false,
  });

  const {
    isLoading: partnershipsLoading,
    error: partnershipsError,
    data: partnerships,
    refetch: refetchPartnerships,
  } = useQuery(['creator', 'partnerships'], fetchPartnerships, {
    initialData: [],
    keepPreviousData: false,
  });

  const {
    isLoading: castingCallsLoading,
    error: castingCallsError,
    data: castingCalls,
    refetch: refetchCastingCalls,
  } = useQuery(['castingCalls', 'creator'], fetchCastingCalls, {
    initialData: [],
    keepPreviousData: false,
  });

  const {
    isLoading: dealsLoading,
    error: dealsError,
    data: deals,
    refetch: refetchDeals,
  } = useQuery(['deals', 'creator'], fetchDeals, {
    initialData: [],
    keepPreviousData: false,
  });

  useEffect(() => {
    if (
      Array.isArray(campaigns) &&
      Array.isArray(partnerships) &&
      Array.isArray(castingCalls) &&
      Array.isArray(deals)
    ) {
      const campaignsWithType = campaigns.map(campaign => ({
        ...campaign,
        type: 'campaign',
      }));

      const partnershipsWithType = partnerships.map(partnership => ({
        ...partnership,
        type: 'partnership',
      }));

      const castingCallsWithType = castingCalls.map(castingCall => ({
        ...castingCall,
        type: 'casting_call',
        isApplied: castingCall.status === 'applied',
      }));

      const dealsWithType = deals.map(deal => ({
        ...deal,
        type: 'deal',
      }));

      const combinedCollaborations = [
        ...campaignsWithType,
        ...partnershipsWithType,
        ...castingCallsWithType,
        ...dealsWithType,
      ];

      setCollaborations(combinedCollaborations);
    } else {
      console.error('One of the data sets is not an array:', {
        campaigns: Array.isArray(campaigns),
        partnerships: Array.isArray(partnerships),
        castingCalls: Array.isArray(castingCalls),
        deals: Array.isArray(deals),
      });
    }
  }, [campaigns, partnerships, castingCalls, deals]);

  useEffect(() => {
    if (!openPitch) {
      refetchCampaigns();
    }
  }, [openPitch, refetchCampaigns]);

  useEffect(() => {
    if (!openPartnershipDialog) {
      refetchPartnerships();
    }
  }, [openPartnershipDialog, refetchPartnerships]);

  useEffect(() => {
    refetchCastingCalls();
  }, [refetchCastingCalls]);

  useEffect(() => {
    refetchDeals();
  }, [refetchDeals]);

  const loading = campaignsLoading || partnershipsLoading || castingCallsLoading || dealsLoading;
  const error = campaignsError || partnershipsError || castingCallsError || dealsError;

  const getDealStatus = (deal) => {
    if (deal.amount_due === 0) return 'Completed';
    if (deal.amount_paid === 0 && deal.amount_due > 0) return 'Offered';
    if (deal.amount_paid > 0 && deal.amount_due > 0) return 'Current';
    return 'All';
  };
  useEffect(() => {
    const currentTab = searchParams.get('tab') || 'All';
    if (currentTab !== currentFilter) {
      setCurrentFilter(currentTab);
    }
  }, [searchParams]);
  const handleTabChange = (event, newValue) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('tab', newValue);
    setSearchParams(newParams);
    setCurrentFilter(newValue);
  };

  const filteredCollaborations = useMemo(() => {
    return collaborations.filter(item => {
      let isValidSelection = true;

      if (item.type === 'campaign') {
        const creator = item.creators.find(c => c.id === username);
        let isAcceptedCreator =
          creator?.status !== undefined &&
          creator.status !== 'Declined' &&
          creator.status !== 'Dropped' &&
          creator.status !== 'Pitched';

        switch (currentFilter) {
          case 'All':
            isValidSelection =
              item.campaign_status !== 'Archived' &&
              item.campaign_status !== 'Draft' &&
              item.campaign_status !== 'DELETED';
            break;
          case 'Current':
            isValidSelection =
              isAcceptedCreator && item.campaign_status === 'Launched';
            break;
          // In the filteredCollaborations switch case for 'Offered'
            case 'Offered':
              isValidSelection =
                (creator?.status === undefined || 
                creator?.status === 'Pitched' || 
                creator?.status === 'Counter') && // Add Counter status
                item.campaign_status === 'Launched';
              break;
          case 'Completed':
            isValidSelection =
              isAcceptedCreator && item.campaign_status === 'Completed';
            break;
          default:
            isValidSelection = true;
        }
      } else if (item.type === 'partnership') {
        switch (currentFilter) {
          case 'All':
            isValidSelection =
              item.status !== 'Archived' &&
              item.status !== 'Draft' &&
              item.status !== 'DELETED';
            break;
          case 'Current':
            isValidSelection = item.status === 'Active';
            break;
          case 'Offered':
            isValidSelection = item.status === 'pending' || item.status === 'Draft';
            break;
          case 'Completed':
            isValidSelection = item.status === 'Completed';
            break;
          default:
            isValidSelection = true;
        }
      } else if (item.type === 'casting_call') {
        switch (currentFilter) {
          case 'All':
            isValidSelection = item.status !== 'closed';
            break;
          case 'Current':
            isValidSelection = item.status === 'active' || item.isApplied;
            break;
          case 'Offered':
            isValidSelection = item.status === 'open';
            break;
          case 'Completed':
            isValidSelection = item.status === 'completed';
            break;
          default:
            isValidSelection = true;
        }
      } else if (item.type === 'deal') {
        const dealStatus = getDealStatus(item);
        switch (currentFilter) {
          case 'All':
            isValidSelection = true;
            break;
          case 'Current':
            isValidSelection = dealStatus === 'Current';
            break;
          case 'Offered':
            isValidSelection = dealStatus === 'Offered';
            break;
          case 'Completed':
            isValidSelection = dealStatus === 'Completed';
            break;
          default:
            isValidSelection = true;
        }
      }

      return isValidSelection;
    });
  }, [collaborations, currentFilter, username]);

  const sortedCollaborations = useMemo(() => {
    return filteredCollaborations.slice().sort((a, b) => {
      const getPriority = item => {
        if (item.type === 'partnership') {
          if (item.status === 'pending' || item.status === 'Draft') return 1;
          return 3;
        }
        if (item.type === 'deal') {
          const ds = getDealStatus(item);
          if (ds === 'Offered') return 1.5;
          if (ds === 'Current') return 2.5;
          if (ds === 'Completed') return 4.5;
          return 5;
        }
        if (item.type === 'casting_call') {
          if (item.isApplied) return 2;
          if (item.status === 'open') return 4;
          return 5;
        }
        if (item.type === 'campaign') {
          return 6;
        }
        return 7;
      };
      const priorityA = getPriority(a);
      const priorityB = getPriority(b);
      return priorityA - priorityB;
    });
  }, [filteredCollaborations]);

  const offeredCount = useMemo(() => {
    return collaborations.reduce((count, item) => {
      if (item.type === 'campaign') {
        const creator = item.creators.find(c => c.id === username);
        // In the offeredCount reducer for campaigns
      if (
        (creator?.status === undefined || 
        creator?.status === 'Pitched' || 
        creator?.status === 'Counter') && // Add Counter status
        item.campaign_status === 'Launched'
      ) {
        count += 1;
      }
      } else if (item.type === 'partnership') {
        if (item.status === 'pending' || item.status === 'Draft') {
          count += 1;
        }
      } else if (item.type === 'casting_call') {
        if (item.status === 'open') {
          count += 1;
        }
      } else if (item.type === 'deal') {
        const ds = getDealStatus(item);
        if (ds === 'Offered') {
          count += 1;
        }
      }
      return count;
    }, 0);
  }, [collaborations, username]);

  const handleItemClick = (item) => {
    if (item.type === 'campaign') {
      const isPitch = CheckIsPitched(item, username);
      if (isPitch) {
        setCurrentCampaign(item.id);
        setOpenPitch(true);
      } else {
        navigate(`/creatorconnect/campaigns/${item.id}`);
      }
    } else if (item.type === 'partnership') {
      navigate(`/creatorconnect/partnerships/${item.id}`);
    } else if (item.type === 'casting_call') {
      if (item.isApplied) {
        navigate(`/creatorconnect/casting-calls/${item.id}/applied`);
      } else {
        navigate(`/creatorconnect/casting-calls/${item.id}`);
      }
    } else if (item.type === 'deal') {
      navigate(`/creatorconnect/deals/${item.id}`);
    }
  };

  const handleClosePitch = () => {
    setOpenPitch(false);
    setCurrentCampaign(-1);
  };

  const handleClosePartnershipDialog = () => {
    setOpenPartnershipDialog(false);
    setCurrentPartnership(null);
  };

  const getButtonLabel = item => {
    if (item.type === 'campaign') {
      return currentFilter !== 'Offered' ? 'Manage Campaign' : 'See Deal';
    } else if (item.type === 'partnership') {
      return 'Manage Partnership';
    } else if (item.type === 'casting_call') {
      return item.isApplied ? 'View Application' : 'View Details';
    } else if (item.type === 'deal') {
      return 'Manage Deal';
    }
    return 'Action';
  };

  const handleShare = (item) => {
    alert(`Sharing ${item.type} - ${item.name || item.id}`);
  };

  const activeCampaignCount = useMemo(() => {
    return campaigns?.filter(c => c.campaign_status === 'Launched').length || 0;
  }, [campaigns]);

  const activePartnershipCount = useMemo(() => {
    return partnerships?.filter(p => p.status === 'Active').length || 0;
  }, [partnerships]);

  const totalDealAmount = useMemo(() => {
    return deals?.reduce((sum, deal) => sum + (deal.amount_paid || 0) + (deal.amount_due || 0), 0) || 0;
  }, [deals]);

  if (loading)
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Typography sx={{ color: 'error', p: 2 }}>
        Error fetching collaborations: {error.message}
      </Typography>
    );

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 3 }}>
        <Typography variant="h4" gutterBottom>Collaborations Dashboard</Typography>
        <Typography variant="subtitle1" gutterBottom>
          A quick glance at your current collaborations and deals.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={2} sx={{ p: 2, borderRadius: 2, textAlign: 'center' }}>
              <Typography variant="h6">Active Campaigns</Typography>
              <Typography variant="h3" color="primary">{activeCampaignCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={2} sx={{ p: 2, borderRadius: 2, textAlign: 'center' }}>
              <Typography variant="h6">Active Partnerships</Typography>
              <Typography variant="h3" color="secondary">{activePartnershipCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={2} sx={{ p: 2, borderRadius: 2, textAlign: 'center' }}>
              <Typography variant="h6">Total Deal Amount</Typography>
              <Typography variant="h3" color="success.main">${totalDealAmount.toLocaleString()}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} square sx={{ mb: 3, borderRadius: 3 }}>
      <Tabs
    value={currentFilter}
    onChange={handleTabChange}
    variant="fullWidth"
    textColor="primary"
    indicatorColor="primary"
  >
          <Tab label="All" value="All" />
          <Tab label="Current" value="Current" />
          <Tab
            label={
              <Badge
                badgeContent={offeredCount}
                color="secondary"
                overlap="rectangular"
                sx={{ padding: 1 }}
              >
                Offered
              </Badge>
            }
            value="Offered"
          />
          <Tab label="Completed" value="Completed" />
        </Tabs>
      </Paper>

      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Your {currentFilter === 'All' ? '' : `${currentFilter} `}Collaborations
      </Typography>

      <Grid container spacing={3}>
        {sortedCollaborations && sortedCollaborations.length > 0 ? (
          sortedCollaborations.map((item, index) => {
            const isCounter = item.type === 'campaign' && 
              item.creators?.find(c => c.id === username)?.status === 'Counter';

            return (
              <Fade in key={`${item.type}-${item.id}`} style={{ transitionDelay: `${index * 100}ms` }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      borderRadius: 3,
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease-in-out',
                      border: isCounter ? '2px solid #ffeb3b' : 'none',
                      '&:hover': {
                        transform: 'scale(1.02)'
                      }
                    }}
                    onClick={() => handleItemClick(item)}
                  >
                    <CardHeader
                      avatar={
                        item.type === 'deal' || item.type === 'casting_call' ? (
                          <Avatar>
                            {item.type.charAt(0).toUpperCase()}
                          </Avatar>
                        ) : (
                          <Avatar src={item.company_pfphref || '/placeholder.png'}>
                            {(!item.company_pfphref || item.company_pfphref === '/placeholder.png')
                              ? item.type.charAt(0).toUpperCase()
                              : null}
                          </Avatar>
                        )
                      }
                      title={
                        item.type === 'casting_call'
                          ? `Casting Call #${item.id}`
                          : item.type === 'campaign' || item.type === 'partnership'
                            ? item.name || `#${item.id}`
                            : item.type === 'deal'
                              ? item.name || `Deal #${item.id}`
                              : `#${item.id}`
                      }
                      titleTypographyProps={{ sx: { textTransform: 'capitalize', fontWeight: 'bold' } }}
                      subheader={
                        item.type === 'campaign'
                          ? 'Campaign'
                          : item.type === 'partnership'
                            ? 'Partnership'
                            : item.type === 'casting_call'
                              ? 'Casting Call'
                              : 'Deal'
                      }
                    />
                    <CardContent>
                      {item.type === 'campaign' && (
                        <Stack spacing={0.5}>
                          <Typography variant="body2">
                            Offer: {getOfferFromCampaign(item, username)}
                          </Typography>
                          <Typography variant="body2">Status: {item.campaign_status}</Typography>
                          {isCounter && (
                            <Typography variant="body2" sx={{ color: 'warning.main', fontStyle: 'italic' }}>
                              This Deal is Pending Approval from Client
                            </Typography>
                          )}
                        </Stack>
                      )}
                      {item.type === 'partnership' && (
                        <Stack spacing={0.5}>
                          <Typography color="textSecondary">{item.description}</Typography>
                          <Typography color="textSecondary">Status: {item.status}</Typography>
                        </Stack>
                      )}
                      {item.type === 'casting_call' && (
                        <Stack spacing={0.5}>
                          <Typography variant="body2">
                            Budget: ${item.budget_min} - ${item.budget_max}
                          </Typography>
                          <Typography variant="body2">
                            Platforms: {item.platforms?.join(', ') || 'N/A'}
                          </Typography>
                          <Typography variant="body2">
                            Promotion Types:{' '}
                            {item.promotion_types
                              ? getHeadersFromKeys(item.promotion_types).join(', ')
                              : 'N/A'}
                          </Typography>
                          <Typography variant="body2">
                            Status: {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                          </Typography>
                        </Stack>
                      )}
                      {item.type === 'deal' && (
                        <Stack spacing={0.5}>
                          <Typography variant="body2">
                            Amount Due: ${item.amount_due}
                          </Typography>
                          <Typography variant="body2">
                            Amount Paid: ${item.amount_paid}
                          </Typography>
                          <Typography variant="body2">
                            Months: {item.amount_of_months}
                          </Typography>
                          <Typography variant="body2">
                            Vendor: {item.creator_vendor_id}
                          </Typography>
                        </Stack>
                      )}
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleItemClick(item);
                        }}
                        sx={{ borderRadius: 2 }}
                      >
                        {getButtonLabel(item)}
                      </Button>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShare(item);
                        }}
                      >
                        <ShareIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              </Fade>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Typography align="center" variant="subtitle1" color="textSecondary">
              No collaborations available.
            </Typography>
          </Grid>
        )}
      </Grid>

      <CreatorCampaignPitchedDialog
        openDialog={openPitch}
        handleCloseDialog={handleClosePitch}
        campaignId={currentCampaign}
      />
    </Box>
  );
};

export default CreatorCollaborations;