import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Container,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../API";
import { parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import {
  platformSelection,
  validatePlatformSelection,
} from "../../../Components/CreatorData/creatorValidation";
import CreatorPlatforms from "../../../Components/CreatorData/creatorPlatforms";
import CreatorInfo from "../../../Components/CreatorData/creatorInfo";
import Papa from "papaparse"; // For CSV parsing

const CreatorDialog = ({ open, onClose, onSubmit, defaultManager = "" }) => {
  // TAB State
  const [activeTab, setActiveTab] = useState("manual");

  // Existing state variables
  const [contactEmail, setContactEmail] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailSame, setEmailSame] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [creatorRegion, setCreatorRegion] = useState("");
  const [platforms, setPlatforms] = useState(platformSelection);
  const [primaryMarket, setPrimaryMarket] = useState([]);
  const [notesContentStyle, setNotesContentStyle] = useState("tell us about yourself");
  const [manager, setManager] = useState(defaultManager);
  const [status, setStatus] = useState("");
  const [race, setRace] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [pfphref, setPfphref] = useState("");

  // Platform-specific state
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");
  const [instagramFeedRate, setInstagramFeedRate] = useState("");
  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtubeIntegrationRate, setYoutubeIntegrationRate] = useState("");
  const [youtubeShortsRate, setYoutubeShortsRate] = useState("");
  const [youtubeDedicatedRate, setYoutubeDedicatedRate] = useState("");
  const [xLink, setXLink] = useState("");
  const [xFollowerCount, setXFollowerCount] = useState("");
  const [xFeedPost, setXFeedPost] = useState("");
  const [xRepost, setXRepost] = useState("");
  const [podcastLink, setPodcastLink] = useState("");
  const [podcastFollowerCount, setPodcastFollowerCount] = useState("");
  const [podcastPreroll, setPodcastPreroll] = useState("");
  const [podcastMidroll, setPodcastMidroll] = useState("");
  const [podcastPostroll, setPodcastPostroll] = useState("");
  const [podcastFull, setPodcastFull] = useState("");
  const [kickLink, setKickLink] = useState("");
  const [kickFollowerCount, setKickFollowerCount] = useState("");
  const [kickPlacement, setKickPlacement] = useState("");
  const [twitchLink, setTwitchLink] = useState("");
  const [twitchFollowerCount, setTwitchFollowerCount] = useState("");
  const [twitchPlacement, setTwitchPlacement] = useState("");

  // New state for file upload
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  // React-query mutation
  const { mutate: addNewCreator, isLoading } = useMutation(client.creators.add, {
    onSuccess: () => {
      alert("Creator added successfully");
      onClose();
    },
    onError: (error) => {
      if (error.response && error.response.data)
        alert(`Error adding creator: ${error.response.data.error}`);
      else alert(`Error adding creator: ${error.message}`);
    },
  });

  // Handle single creator save
  const handleSaveCreator = async () => {
    if (phoneNumber.length < 11) {
      alert("This Phone number is not valid");
      return;
    }

    if (
      !validatePlatformSelection(
        platforms,
        (message) => alert(message),
        tikTokBrandRate,
        tikTokSongRate,
        instagramBrandRate,
        instagramSongRate,
        instagramFeedRate,
        instagramStoryRate,
        youtubeIntegrationRate,
        youtubeShortsRate,
        youtubeDedicatedRate,
        xFeedPost,
        xRepost,
        kickPlacement,
        twitchPlacement,
        podcastPreroll,
        podcastMidroll,
        podcastPostroll,
        podcastFull
      )
    ) {
      return;
    }

    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;
    const formattedXLink = xLink ? `https://www.x.com/${xLink}` : null;
    const formattedKickLink = kickLink ? `https://www.kick.com/${kickLink}` : null;
    const formattedTwitchLink = twitchLink ? `https://www.twitch.tv/${twitchLink}` : null;

    let payload = null;

    try {
      payload = {
        creator: creatorName,
        geolocation: location,
        gender,
        ethnicity: race,
        primary_markets: primaryMarket,
        region: creatorRegion,
        notescontent_style: notesContentStyle || null,
        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,
        twitch: parseLocalInt(twitchFollowerCount) || null,
        twitch_link: formattedTwitchLink || null,
        follow_x: parseLocalInt(xFollowerCount) || null,
        x_link: formattedXLink || null,
        follow_podcast: parseLocalInt(podcastFollowerCount) || null,
        podcast_link: podcastLink || null,
        follow_kick: parseLocalInt(kickFollowerCount) || null,
        kick_link: formattedKickLink || null,
        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),
        rate_ig_feedpost: parseLocalFloat(instagramFeedRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_ig_story: parseLocalFloat(instagramStoryRate),
        rate_yt_integ: parseLocalFloat(youtubeIntegrationRate),
        rate_yt_short: parseLocalFloat(youtubeShortsRate),
        rate_yt_dedicated: parseLocalFloat(youtubeDedicatedRate),
        rate_podcast_preroll: parseLocalFloat(podcastPreroll),
        rate_podcast_midroll: parseLocalFloat(podcastMidroll),
        rate_podcast_postroll: parseLocalFloat(podcastPostroll),
        rate_podcast_full: parseLocalFloat(podcastFull),
        rate_twitch_product: parseLocalFloat(twitchPlacement),
        rate_kick_product: parseLocalFloat(kickPlacement),
        rate_x_feedpost: parseLocalFloat(xFeedPost),
        rate_x_repost: parseLocalFloat(xRepost),
        contactEmail,
        paymentEmail,
        phone_number: phoneNumber,
        pfphref: pfphref || null,
        manager,
        status: status || "Associate",
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }

    addNewCreator(payload);
  };

  // Handle file upload input change
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {
      Papa.parse(uploadedFile, {
        header: true, // Expects the first row to contain headers
        skipEmptyLines: true,
        complete: (result) => {
          handleBulkUpload(result.data);
        },
        error: (error) => {
          setUploadStatus(`Error parsing file: ${error.message}`);
        },
      });
    }
  };

  // Bulk upload creators
  const handleBulkUpload = async (creators) => {
    setUploadStatus("Processing...");
    const payloads = [];
    const errors = [];

    // Prepare payloads
    for (const creator of creators) {
      const formattedTikTokLink = creator.tikTokLink
        ? `https://www.tiktok.com/@${creator.tikTokLink}`
        : null;
      const formattedInstagramLink = creator.instagramLink
        ? `https://www.instagram.com/${creator.instagramLink}`
        : null;
      const formattedYouTubeLink = creator.youtubeLink
        ? `https://www.youtube.com/${creator.youtubeLink}`
        : null;
      const formattedXLink = creator.xLink ? `https://www.x.com/${creator.xLink}` : null;
      const formattedKickLink = creator.kickLink ? `https://www.kick.com/${creator.kickLink}` : null;
      const formattedTwitchLink = creator.twitchLink
        ? `https://www.twitch.tv/${creator.twitchLink}`
        : null;

      const payload = {
        creator: creator.creatorName || "",
        geolocation: creator.location || "",
        gender: creator.gender || "",
        ethnicity: creator.race || "",
        primary_markets: creator.primaryMarket ? creator.primaryMarket.split(",") : [],
        region: creator.creatorRegion || "",
        notescontent_style: creator.notesContentStyle || null,
        tiktok: parseLocalInt(creator.tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(creator.instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(creator.youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,
        twitch: parseLocalInt(creator.twitchFollowerCount) || null,
        twitch_link: formattedTwitchLink || null,
        follow_x: parseLocalInt(creator.xFollowerCount) || null,
        x_link: formattedXLink || null,
        follow_podcast: parseLocalInt(creator.podcastFollowerCount) || null,
        podcast_link: creator.podcastLink || null,
        follow_kick: parseLocalInt(creator.kickFollowerCount) || null,
        kick_link: formattedKickLink || null,
        rate_tt_sound: parseLocalFloat(creator.tikTokSongRate) || null,
        rate_tt_brand: parseLocalFloat(creator.tikTokBrandRate) || null,
        rate_ig_feedpost: parseLocalFloat(creator.instagramFeedRate) || null,
        rate_ig_reelsound: parseLocalFloat(creator.instagramSongRate) || null,
        rate_ig_reelbrand: parseLocalFloat(creator.instagramBrandRate) || null,
        rate_ig_story: parseLocalFloat(creator.instagramStoryRate) || null,
        rate_yt_integ: parseLocalFloat(creator.youtubeIntegrationRate) || null,
        rate_yt_short: parseLocalFloat(creator.youtubeShortsRate) || null,
        rate_yt_dedicated: parseLocalFloat(creator.youtubeDedicatedRate) || null,
        rate_podcast_preroll: parseLocalFloat(creator.podcastPreroll) || null,
        rate_podcast_midroll: parseLocalFloat(creator.podcastMidroll) || null,
        rate_podcast_postroll: parseLocalFloat(creator.podcastPostroll) || null,
        rate_podcast_full: parseLocalFloat(creator.podcastFull) || null,
        rate_twitch_product: parseLocalFloat(creator.twitchPlacement) || null,
        rate_kick_product: parseLocalFloat(creator.kickPlacement) || null,
        rate_x_feedpost: parseLocalFloat(creator.xFeedPost) || null,
        rate_x_repost: parseLocalFloat(creator.xRepost) || null,
        contactEmail: creator.contactEmail || "",
        paymentEmail: creator.paymentEmail || "",
        phone_number: creator.phoneNumber || "",
        pfphref: creator.pfphref || null,
        manager: creator.manager || "",
        status: creator.status || "Associate",
      };

      if (creator.phoneNumber && creator.phoneNumber.length < 11) {
        errors.push(`Invalid phone number for ${creator.creatorName}`);
      } else {
        payloads.push(payload);
      }
    }

    // Batch processing (example: 10 creators at a time)
    const batchSize = 10;
    let successfulCount = 0;

    for (let i = 0; i < payloads.length; i += batchSize) {
      const batch = payloads.slice(i, i + batchSize);
      try {
        // For each payload, call addNewCreator
        await Promise.all(batch.map((payload) => addNewCreator(payload)));
        successfulCount += batch.length;
        setUploadStatus(`Processed ${successfulCount} of ${payloads.length} creators...`);
      } catch (error) {
        errors.push(`Error in batch ${i / batchSize + 1}: ${error.message}`);
      }
    }

    if (errors.length > 0) {
      setUploadStatus(
        `Completed with errors: ${successfulCount} creators added.\nErrors:\n${errors.join("\n")}`
      );
    } else {
      setUploadStatus(`Successfully added ${successfulCount} creators`);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogContent>
        <Container>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              Creator Intake Form
            </Typography>
          </Box>

          {/* Tab Navigation */}
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Manual" value="manual" />
            <Tab label="Bulk" value="bulk" />
          </Tabs>

          {/* MANUAL Tab */}
          {activeTab === "manual" && (
            <Box mt={2}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSaveCreator();
                }}
              >
                {/* Creator Details & Contact Info */}
                <CreatorInfo
                  creatorName={creatorName}
                  setCreatorName={setCreatorName}
                  creatorRegion={creatorRegion}
                  setCreatorRegion={setCreatorRegion}
                  race={race}
                  setRace={setRace}
                  gender={gender}
                  setGender={setGender}
                  location={location}
                  setLocation={setLocation}
                  primaryMarket={primaryMarket}
                  setPrimaryMarket={setPrimaryMarket}
                  notesContentStyle={notesContentStyle}
                  setNotesContentStyle={setNotesContentStyle}
                  contactEmail={contactEmail}
                  setContactEmail={setContactEmail}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  validationError={""}
                  paymentEmail={paymentEmail}
                  setPaymentEmail={setPaymentEmail}
                  emailSame={emailSame}
                  setEmailSame={setEmailSame}
                  pfphref={pfphref}
                  setPfphref={setPfphref}
                />

                {/* Platforms & Rates */}
                <Typography variant="h6" sx={{ marginBlock: 2 }}>
                  Platform and Rates
                </Typography>
                <CreatorPlatforms
                  platforms={platforms}
                  setPlatforms={setPlatforms}
                  instagramLink={instagramLink}
                  setInstagramLink={setInstagramLink}
                  instagramFollowerCount={instagramFollowerCount}
                  setInstagramFollowerCount={setInstagramFollowerCount}
                  instagramBrandRate={instagramBrandRate}
                  setInstagramBrandRate={setInstagramBrandRate}
                  instagramSongRate={instagramSongRate}
                  setInstagramSongRate={setInstagramSongRate}
                  instagramFeedRate={instagramFeedRate}
                  setInstagramFeedRate={setInstagramFeedRate}
                  instagramStoryRate={instagramStoryRate}
                  setInstagramStoryRate={setInstagramStoryRate}
                  tikTokLink={tikTokLink}
                  setTikTokLink={setTikTokLink}
                  tikTokFollowerCount={tikTokFollowerCount}
                  setTikTokFollowerCount={setTikTokFollowerCount}
                  tikTokBrandRate={tikTokBrandRate}
                  setTikTokBrandRate={setTikTokBrandRate}
                  tikTokSongRate={tikTokSongRate}
                  setTikTokSongRate={setTikTokSongRate}
                  youtubeLink={youtubeLink}
                  setYoutubeLink={setYoutubeLink}
                  youtubeFollowerCount={youtubeFollowerCount}
                  setYoutubeFollowerCount={setYoutubeFollowerCount}
                  youtubeIntegrationRate={youtubeIntegrationRate}
                  setYoutubeIntegrationRate={setYoutubeIntegrationRate}
                  youtubeShortsRate={youtubeShortsRate}
                  setYoutubeShortsRate={setYoutubeShortsRate}
                  youtubeDedicatedRate={youtubeDedicatedRate}
                  setYoutubeDedicatedRate={setYoutubeDedicatedRate}
                  xLink={xLink}
                  setXLink={setXLink}
                  xFollowerCount={xFollowerCount}
                  setXFollowerCount={setXFollowerCount}
                  xFeedPost={xFeedPost}
                  setXFeedPost={setXFeedPost}
                  xRepost={xRepost}
                  setXRepost={setXRepost}
                  podcastLink={podcastLink}
                  setPodcastLink={setPodcastLink}
                  podcastFollowerCount={podcastFollowerCount}
                  setPodcastFollowerCount={setPodcastFollowerCount}
                  podcastPreroll={podcastPreroll}
                  setPodcastPreroll={setPodcastPreroll}
                  podcastMidroll={podcastMidroll}
                  setPodcastMidroll={setPodcastMidroll}
                  podcastPostroll={podcastPostroll}
                  setPodcastPostroll={setPodcastPostroll}
                  podcastFull={podcastFull}
                  setPodcastFull={setPodcastFull}
                  kickLink={kickLink}
                  setKickLink={setKickLink}
                  kickFollowerCount={kickFollowerCount}
                  setKickFollowerCount={setKickFollowerCount}
                  kickPlacement={kickPlacement}
                  setKickPlacement={setKickPlacement}
                  twitchLink={twitchLink}
                  setTwitchLink={setTwitchLink}
                  twitchFollowerCount={twitchFollowerCount}
                  setTwitchFollowerCount={setTwitchFollowerCount}
                  twitchPlacement={twitchPlacement}
                  setTwitchPlacement={setTwitchPlacement}
                />

                {/* Manager & Status */}
                <TextField
                  margin="dense"
                  label="Manager"
                  type="text"
                  fullWidth
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                  variant="outlined"
                />
                <FormControl variant="outlined" fullWidth margin="dense">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Status"
                  >
                    <MenuItem value="Partner">Partner</MenuItem>
                    <MenuItem value="Associate">Associate</MenuItem>
                    <MenuItem value="Agency">Agency</MenuItem>
                  </Select>
                </FormControl>

                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Submit Information
                </Button>
              </form>
            </Box>
          )}

          {/* BULK Tab */}
          {activeTab === "bulk" && (
            <Box mt={2}>
              <Typography variant="body1" gutterBottom>
                If you have multiple creators, you can upload them via CSV. 
              </Typography>
              <Typography variant="body2" gutterBottom>
                Please follow our CSV template:{" "}. Make sure under "manager" you include "{defaultManager}" so your creators populate properly 
                <a
                  href="https://docs.google.com/spreadsheets/d/1ofQC_Ar3Hl3JCWy4Q3hkPMfdnEumfq98RGJ5GhDIUvY/edit?gid=0#gid=0"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  View our CSV template here
                </a>
              </Typography>

              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ marginTop: "10px" }}
              />
              {uploadStatus && (
                <Typography
                  variant="body2"
                  color={uploadStatus.includes("Error") ? "error" : "success"}
                  sx={{ mt: 1 }}
                >
                  {uploadStatus}
                </Typography>
              )}
            </Box>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default CreatorDialog;
