import React, { useState, useRef } from "react";
import {
  Box,
  TextField,
  Toolbar,
  Typography,
  Card,
  CircularProgress,
  Backdrop,
  ThemeProvider,
  Alert,
  AlertTitle,
  CssBaseline,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { useQuery } from "react-query";

import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";
import AlertDialog from "../../Components/AlertDialog.js";
import useAlertDialog from "../../Components/useAlertDialog.js";
import API from "../../API/index.js";
import { lightTheme } from "../../Utils/baseTheme.js";
import routes from "../../Config/routes.js";

const CreatorConnect = () => {
  const navigate = useNavigate();
  const { creator } = useParams();
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // Basic state for creator name and loading status
  const [creatorName, setCreatorName] = useState(
    sessionStorage.getItem("creatorName") || creator || ""
  );
  const [loading, setLoading] = useState(false);

  // Check if user is already in our DB & if they are verified
  const { data: alreadyRegistered, isLoading: isVerifying } = useQuery({
    queryKey: ["check_registration", creatorName],
    queryFn: () => API.creatorConnect.verify(creatorName),
    enabled: !!creatorName,
  });

  // Google login success handler
  const handleCredentialResponse = (response) => {
    handleConnectSubmit(response.credential);
  };

  // Google login error handler
  const handleLoginFailure = (error) => {
    console.error("Failed to login with Google:", error);
    setLoading(false);
    openDialog(
      "Error",
      <>Google login failed, please try again.<br />Error: {error}</>,
      closeDialog,
      closeDialog,
      "Ok",
      null
    );
  };

  // Submit form data to our backend
  const handleConnectSubmit = async (googleToken) => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }
    setLoading(true);

    try {
      const payload = {
        username: creatorName,
        google_token: googleToken,
        status: "Associate", // Always "Associate" since the Partner step is removed
      };

      await API.creatorConnect.connect(payload);
      openDialog(
        "Success",
        "You have successfully claimed your account!",
        () => navigate(routes.creatorLogin),
        () => navigate(routes.creatorLogin),
        "Go to Creator Login",
        null
      );
    } catch (error) {
      console.error("Error during form submission:", error);
      openDialog(
        "Error",
        `There was an error: ${error.response?.data?.error || error.message}`,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
      setLoading(false);
    }
  };

  // Standard form submit event
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleConnectSubmit(null);
  };

  // Some fancy gradient border styling (still included if needed)
  const moveGradient = {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  };

  const rainbowBorder = {
    position: "relative",
    padding: "1em",
    marginBlock: 2,
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    zIndex: 0,
    "&::before": {
      content: '""',
      position: "absolute",
      top: "-2px",
      left: "-2px",
      right: "-2px",
      bottom: "-2px",
      background:
        "linear-gradient(90deg, red, orange, yellow, green, cyan, blue, violet)",
      zIndex: -2,
      animation: "3s linear infinite",
      backgroundSize: "200% 200%",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "4px",
      left: "4px",
      right: "4px",
      bottom: "4px",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      zIndex: -1,
    },
  };

  // Disable form if the creator is already registered or not found
  const formIsDisabled =
    alreadyRegistered?.registered || alreadyRegistered?.existsInCreatorDB === false;

  return (
    <>
      <AlertDialog alertState={dialogState} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || isVerifying}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HomeHeader />
          <Toolbar />
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Box sx={{ paddingInline: 2, maxWidth: "md", width: "100%" }}>
              {/* Error states */}
              {alreadyRegistered?.registered && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  <AlertTitle>Already Claimed</AlertTitle>
                  This Creator User is already verified. If this is a mistake,
                  please contact the Blitz team.
                </Alert>
              )}
              {alreadyRegistered?.existsInCreatorDB === false && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  <AlertTitle>Not Found</AlertTitle>
                  We couldn’t find this Creator in our database. If this is a
                  mistake, please contact the Blitz team.
                </Alert>
              )}

              {/* Main Form */}
              {!alreadyRegistered?.registered &&
                alreadyRegistered?.existsInCreatorDB && (
                  <form
                    id="creatorForm"
                    onSubmit={handleFormSubmit}
                    ref={formRef}
                    noValidate
                  >
                    <Box
                      sx={{
                        marginInline: "auto",
                        marginBlockStart: 4,
                        marginBlockEnd: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        maxWidth: "30em",
                      }}
                    >
                      {/* Title */}
                      <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h3" color="textPrimary">
                          Claim Your Blitz Account
                        </Typography>
                        <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                          Welcome! We’ve located your creator profile; just confirm details
                          and connect your Google to complete setup.
                        </Typography>
                      </Box>

                      {/* Step 1: Confirm Name */}
                      <Box>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          1. Confirm Your Creator Name
                        </Typography>
                        <TextField
                          label="Creator Name"
                          name="creator-name"
                          required
                          disabled
                          value={creatorName}
                          onChange={(e) => setCreatorName(e.target.value)}
                          inputRef={inputRef}
                          fullWidth
                        />
                      </Box>

                      {/* Step 2: Connect with Google */}
                      <Box>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          2. Connect Your Google Account
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                          This verifies you’re the true owner and grants you access to advanced
                          integrations.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                          <GoogleLogin
                            size="large"
                            onSuccess={handleCredentialResponse}
                            onError={handleLoginFailure}
                            text="continue_with"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </form>
                )}
            </Box>
          </Box>
          <HomeFooter />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default CreatorConnect;
