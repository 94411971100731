import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import client from "../../../API";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Alert,
  TextField,
  Snackbar,
} from "@mui/material";
import html2canvas from "html2canvas";
import StarIcon from "@mui/icons-material/Star";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

// Upgraded icon map with styled icons
const iconMap = {
  Star: (
    <StarIcon
      fontSize="large"
      sx={{
        color: "#FFD700",
        filter: "drop-shadow(0 0 8px rgba(255,215,0,0.5))",
      }}
    />
  ),
  EmojiEvents: (
    <EmojiEventsIcon
      fontSize="large"
      sx={{
        color: "#FFD700",
        filter: "drop-shadow(0 0 8px rgba(255,215,0,0.5))",
      }}
    />
  ),
  MilitaryTech: (
    <MilitaryTechIcon
      fontSize="large"
      sx={{
        color: "#FFD700",
        filter: "drop-shadow(0 0 8px rgba(255,215,0,0.5))",
      }}
    />
  ),
};

const Ribbon = ({ achieved }) => (
  <Box
    sx={{
      position: "absolute",
      top: -10,
      right: -10,
      width: 100,
      height: 100,
      overflow: "hidden",
      "&:before": {
        content: '""',
        position: "absolute",
        width: 140,
        height: 30,
        backgroundColor: achieved ? "#ffd700" : "#666",
        transform: "rotate(45deg)",
        top: 35,
        right: -35,
        boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        width: 140,
        height: 30,
        backgroundColor: achieved ? "#ffd700" : "#666",
        transform: "rotate(-45deg)",
        top: 35,
        left: -35,
        boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
      },
    }}
  >
    <Typography
      variant="caption"
      sx={{
        position: "absolute",
        zIndex: 1,
        color: achieved ? "#000" : "#fff",
        fontWeight: "bold",
        top: 22,
        left: "50%",
        transform: "translateX(-50%)",
        textShadow: achieved ? "none" : "0 1px 2px rgba(0,0,0,0.3)",
      }}
    >
      {achieved ? "ACHIEVED" : "IN PROGRESS"}
    </Typography>
  </Box>
);

// Helper function to convert base64 to blob
const dataURLtoBlob = (dataURL) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const BlitzBoltAttainment = () => {
  const { userId, attainmentId } = useParams();
  const [achievement, setAchievement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [caption, setCaption] = useState("");
  const [sharing, setSharing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const attainmentRef = useRef(null);

  // Fetch achievement data
  useEffect(() => {
    const fetchAchievement = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await client.blitzbolts.getAchievement(
          userId,
          attainmentId
        );
        if (!response || Object.keys(response).length === 0) {
          throw new Error("No achievement data returned from the server");
        }
        setAchievement(response);
      } catch (err) {
        setError(
          err.response?.data?.error ||
            err.message ||
            "Failed to fetch achievement data"
        );
      } finally {
        setLoading(false);
      }
    };
    fetchAchievement();
  }, [userId, attainmentId]);

  // Capture a PNG preview of the achievement card
  useEffect(() => {
    if (achievement && attainmentRef.current && !capturedImage) {
      const captureImage = async () => {
        try {
          const canvas = await html2canvas(attainmentRef.current, {
            backgroundColor: null,
          });
          setCapturedImage(canvas.toDataURL("image/png"));
        } catch (err) {
          console.error("Error capturing image:", err);
        }
      };
      captureImage();
    }
  }, [achievement, capturedImage]);

  // Updated LinkedIn sharing function with image upload
  const handleShareLinkedIn = async () => {
    if (!achievement || !capturedImage) {
      setSnackbar({ open: true, message: "Missing required data for sharing" });
      return;
    }

    setSharing(true);
    try {
      // Fallback to simple URL sharing if LinkedIn API endpoints aren't available
      const shareUrl = client.blitzbolts.shareUrl(userId, attainmentId);
      
      // Check if LinkedIn API endpoints exist
      if (client.blitzbolts.getLinkedInUploadUrl && client.blitzbolts.shareToLinkedIn) {
        // Step 1: Get upload URL
        const uploadResponse = await client.blitzbolts.getLinkedInUploadUrl(userId);
        const { uploadUrl, assetId } = uploadResponse;

        // Step 2: Upload the image
        const blob = dataURLtoBlob(capturedImage);
        await fetch(uploadUrl, {
          method: "PUT",
          body: blob,
          headers: {
            "Content-Type": "image/png",
          },
        });

        // Step 3: Create the share with the uploaded image
        const shareContent = {
          content: {
            contentEntities: [
              {
                entityLocation: shareUrl,
                thumbnails: [{ resolvedUrl: assetId }],
              },
            ],
            title: achievement.title,
          },
          owner: `urn:li:person:${userId}`,
          text: {
            text: `${caption}\n\n${shareUrl}`,
          },
        };

        await client.blitzbolts.shareToLinkedIn(shareContent);
        setSnackbar({
          open: true,
          message: "Successfully shared to LinkedIn with image!",
        });
      } else {
        // Fallback to basic URL sharing
        const captionText = encodeURIComponent(`${caption}\n\n${shareUrl}`);
        const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          shareUrl
        )}&title=${encodeURIComponent(
          achievement.title
        )}&summary=${captionText}`;
        window.open(linkedInShareUrl, "_blank");
        setSnackbar({
          open: true,
          message: "Shared to LinkedIn (URL only)",
        });
      }
    } catch (err) {
      console.error("Error sharing to LinkedIn:", err);
      setSnackbar({
        open: true,
        message: "Failed to share to LinkedIn: " + err.message,
      });
    } finally {
      setSharing(false);
    }
  };

  const handleDownloadPNG = () => {
    if (!capturedImage) return;
    const link = document.createElement("a");
    link.download = `${achievement?.title || "blitzbolt"}.png`;
    link.href = capturedImage;
    link.click();
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: "600px", mx: "auto", mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!achievement) {
    return (
      <Box sx={{ maxWidth: "600px", mx: "auto", mt: 4 }}>
        <Typography variant="h6" color="text.secondary">
          No achievement data available
        </Typography>
      </Box>
    );
  }

  const achieved = achievement.attainment?.achieved;
  const backgroundGradient = achieved
    ? "linear-gradient(45deg, #6a11cb, #2575fc)"
    : "linear-gradient(45deg, #616161, #424242)";

  const currentUrl = `${window.location.origin}/blitzbolts/${userId}/${attainmentId}`;

  return (
    <Box sx={{ maxWidth: "600px", mx: "auto", my: 4 }}>
      <Helmet>
        <title>{`${achievement.title || "Achievement"} - Blitz Beta`}</title>
        <meta
          name="description"
          content={
            achievement.description ||
            "Check out this achievement on Blitz Beta!"
          }
        />
        <meta
          property="og:title"
          content={`${achievement.title || "Untitled Achievement"} - Blitz Beta`}
        />
        <meta
          property="og:description"
          content={
            achievement.description
              ? `${achievement.description} Earned ${
                  achievement.points || 0
                } points!`
              : `Earned ${achievement.points || 0} points on Blitz Beta!`
          }
        />
        <meta
          property="og:image"
          content={
            capturedImage || `${window.location.origin}/thumbnail.png`
          }
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Blitz Beta" />
      </Helmet>

      {/* Hidden div for capturing the PNG */}
      <Box
        ref={attainmentRef}
        sx={{
          background: `
            ${backgroundGradient},
            linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)
          `,
          color: "#fff",
          p: 4,
          textAlign: "center",
          borderRadius: 4,
          position: "absolute",
          left: "-9999px",
          width: 600,
          minHeight: 400,
          boxShadow: "0 20px 40px rgba(0,0,0,0.3)",
          overflow: "hidden",
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(45deg, transparent 40%, rgba(255,255,255,0.1) 50%, transparent 60%)",
            opacity: achieved ? 0.3 : 0,
            animation: "shimmer 3s infinite linear",
          },
          "@keyframes shimmer": {
            "0%": { transform: "translateX(-100%)" },
            "100%": { transform: "translateX(100%)" },
          },
        }}
      >
        <Ribbon achieved={achieved} />
        <Box
          sx={{
            mb: 3,
            position: "relative",
            "& svg": {
              fontSize: "4rem",
              filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.3))",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.2) 0%, transparent 70%)",
              borderRadius: "50%",
              p: 1,
            },
          }}
        >
          {achievement.icon_name
            ? iconMap[achievement.icon_name]
            : <StarIcon fontSize="large" />}
        </Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "900",
            textShadow: "0 4px 8px rgba(0,0,0,0.3)",
            letterSpacing: "1.5px",
            mb: 3,
            position: "relative",
            "&:after": {
              content: '""',
              display: "block",
              width: "60px",
              height: "3px",
              background: "#fff",
              mx: "auto",
              mt: 2,
              borderRadius: 2,
            },
          }}
        >
          {achievement.title || "Untitled Achievement"}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 4,
            fontSize: "1.1rem",
            maxWidth: "80%",
            mx: "auto",
            lineHeight: 1.6,
            textShadow: "0 2px 4px rgba(0,0,0,0.2)",
          }}
        >
          {achievement.description || "No description available"}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 4, mb: 3 }}>
          <Box
            sx={{
              background: "rgba(0,0,0,0.2)",
              borderRadius: 2,
              p: 1.5,
              minWidth: 100,
              border: "2px solid rgba(255,255,255,0.1)",
            }}
          >
            <Typography variant="overline" sx={{ display: "block", opacity: 0.8 }}>
              Points
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {achievement.points || 0}
            </Typography>
          </Box>
          {achievement.attainment?.progress && achievement.criteria && (
            <Box
              sx={{
                background: "rgba(0,0,0,0.2)",
                borderRadius: 2,
                p: 1.5,
                minWidth: 100,
                border: "2px solid rgba(255,255,255,0.1)",
              }}
            >
              <Typography variant="overline" sx={{ display: "block", opacity: 0.8 }}>
                Progress
              </Typography>
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  mx: "auto",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={Math.round(
                    (Object.keys(achievement.criteria).reduce((acc, key) => {
                      const required = achievement.criteria[key];
                      const achievedVal =
                        achievement.attainment.progress[key] || 0;
                      return acc + (achievedVal >= required ? 1 : 0);
                    }, 0) /
                      Object.keys(achievement.criteria).length) *
                      100
                  )}
                  thickness={4}
                  sx={{
                    position: "absolute",
                    color: achieved ? "#FFD700" : "#fff",
                  }}
                />
                <Typography variant="body2">
                  {Math.round(
                    (Object.keys(achievement.criteria).reduce((acc, key) => {
                      const required = achievement.criteria[key];
                      const achievedVal =
                        achievement.attainment.progress[key] || 0;
                      return acc + (achievedVal >= required ? 1 : 0);
                    }, 0) /
                      Object.keys(achievement.criteria).length) *
                      100
                  )}
                  %
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {achieved && achievement.attainment?.achieved_at && (
          <Typography
            variant="caption"
            sx={{
              display: "block",
              mt: 2,
              opacity: 0.8,
              textShadow: "0 1px 2px rgba(0,0,0,0.2)",
            }}
          >
            Awarded on{" "}
            {new Date(achievement.attainment.achieved_at).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
          </Typography>
        )}
      </Box>

      {capturedImage && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Box
            component="img"
            src={capturedImage}
            alt="Achievement Preview"
            sx={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: 1,
              mb: 2,
            }}
          />
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
        <Button
          variant="outlined"
          onClick={handleDownloadPNG}
          disabled={!capturedImage}
        >
          Download PNG
        </Button>
        <Button
          variant="contained"
          onClick={handleShareLinkedIn}
          disabled={!capturedImage || !achievement.attainment?.id || sharing}
        >
          {sharing ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Share to LinkedIn"
          )}
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="Add a caption"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          disabled={sharing}
        />
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default BlitzBoltAttainment;