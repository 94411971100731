import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Grid,
  Stack,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import HomeHeader from '../../../Components/HomeHeader';
import HomeFooter from '../../../Components/HomeFooter';
import { StyledDivider as Divider } from '../../../Components/LightDivider';
import API from '../../../API';
import { lightTheme } from '../../../Utils/baseTheme';
import { useQuery } from 'react-query';
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';
import { CheckCircle, Download, Payment } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';

const InvoicePage = () => {
  const { invoiceId } = useParams();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const {
    data: invoiceData,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => API.invoices.fetch(invoiceId),
    refetchOnWindowFocus: false,
    enabled: !isNaN(invoiceId),
  });

  const invoice = invoiceData?.invoice;
  const user = invoiceData?.user;
  const company = invoiceData?.company;
  const payouts = invoiceData?.payouts || [];

  useEffect(() => {
    if (error) {
      openDialog(
        'Error',
        <>
          Error fetching invoice:
          <br />
          {error?.response?.data?.error || error.message}
        </>,
        closeDialog,
        closeDialog,
        'Ok',
        null
      );
    }
  }, [error, openDialog, closeDialog]);

  const handleDownloadPDF = async () => {
    const invoiceContent = document.getElementById('invoice-content');

    // Temporarily force a fixed A4 width for capturing
    const originalWidth = invoiceContent.style.width;
    invoiceContent.style.width = '210mm';

    const canvas = await html2canvas(invoiceContent, {
      scale: 2, 
      useCORS: true,
      logging: false,
      letterRendering: true,
      allowTaint: true,
      onClone: (clonedDoc) => {
        // Hide any interactive elements like buttons
        const elementsToHide = [
          clonedDoc.getElementById('download-pdf-button'),
          clonedDoc.querySelector('button[href]'),
        ];
        elementsToHide.forEach((el) => el && (el.style.display = 'none'));
      },
    });

    // Restore original width
    invoiceContent.style.width = originalWidth;

    // Convert canvas to image for PDF
    const imgData = canvas.toDataURL('image/jpeg', 0.85);

    // Initialize jsPDF in points (pt) for better scaling control
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const canvasAspectRatio = canvasHeight / canvasWidth;

    let displayWidth = pageWidth; 
    let displayHeight = displayWidth * canvasAspectRatio;

    // If it's still too tall, limit by page height
    if (displayHeight > pageHeight) {
      displayHeight = pageHeight;
      displayWidth = displayHeight / canvasAspectRatio;
    }

    const marginX = (pageWidth - displayWidth) / 2;
    const marginY = (pageHeight - displayHeight) / 2;

    pdf.addImage(imgData, 'JPEG', marginX, marginY, displayWidth, displayHeight);
    pdf.save(`invoice_${invoice?.id}.pdf`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AlertDialog alertState={dialogState} />
        <CircularProgress />
      </Box>
    );
  }

  if (!invoice) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AlertDialog alertState={dialogState} />
        <Typography>Invoice not found.</Typography>
      </Box>
    );
  }

  // Helper functions
  const formattedDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };
  const addDays = (dateStr, days) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    date.setDate(date.getDate() + days);
    return date.toISOString(); // or .toLocaleDateString()
  };
  const safeAmount = (amt) => amt || 0;

  // Pull out custom_details if available
  const { custom_details } = invoice;
  const billedTo = custom_details?.billed_to;
  const dateOfIssue = custom_details?.date_of_issue;
  const dueDateOverride = custom_details?.due_date_override;
  const customInvoiceNumber = custom_details?.invoice_number;

  // We can override some fields if custom_details is present:
  //  - date for "Invoice #"
  //  - "Date of issue"
  //  - "Billed to"
  //  - "Due date"
  const effectiveDateOfIssue = dateOfIssue
    ? formattedDate(dateOfIssue)
    : formattedDate(invoice.created_at);

  // If there's a custom due_date_override, use that; otherwise fallback to
  // invoice.created_at + invoice.payment_term
  const effectiveDueDate = dueDateOverride
    ? formattedDate(dueDateOverride)
    : formattedDate(addDays(invoice.created_at, invoice.payment_term));

  // For an Invoice #, maybe prefer customInvoiceNumber, fallback to invoice.po_number
  const invoiceNumber = customInvoiceNumber || invoice.po_number;

  // For "Billed To," prefer custom_details.billed_to, else invoice.client_name / user fallback
  const effectiveBilledTo =
    billedTo ||
    invoice.client_name ||
    (user ? `${user.first_name} ${user.last_name}` : '');

  // Summaries
  const subtotal = payouts.reduce((acc, payout) => acc + safeAmount(payout.amount), 0);
  const amountDue = safeAmount(invoice.amount_due);
  const amountPaid = safeAmount(invoice.amount_paid);
  const platformFee = amountDue - subtotal;
  const amountDueAfterPayment = amountDue - amountPaid;

  return (
    <ThemeProvider theme={lightTheme}>
      <AlertDialog alertState={dialogState} />
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HomeHeader />
        <Toolbar />
        <Box sx={{ py: 2, flex: 1, maxWidth: '100vw', overflowX: 'auto' }}>
          <Button
            disableRipple
            id="download-pdf-button"
            variant="contained"
            startIcon={<Download />}
            onClick={handleDownloadPDF}
            sx={{
              mb: 2,
              marginInlineStart: 2,
              background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
              '&:hover': { opacity: 0.9 },
            }}
          >
            Download PDF
          </Button>
          <Box id="invoice-content" sx={{ mb: 4 }}>
            <Paper
              sx={{
                p: 6,
                width: '210mm',
                minHeight: '297mm',
                margin: '0 auto',
                fontFamily: 'Arial, sans-serif',
                background: 'white',
                boxShadow: 'none',
                borderRadius: 0,
              }}
            >
              {/* Header Section */}
              <Grid container spacing={4} sx={{ mb: 6 }}>
                <Grid item xs={6}>
                  <Box sx={{ mb: 4 }}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 'bold',
                        color: '#4a4e69',
                        mb: 1,
                      }}
                    >
                      INVOICE
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        color: '#4a4e69',
                        fontWeight: 'normal',
                        mb: 3,
                      }}
                    >
                      Invoice from Blitz Systems Corp
                    </Typography>
                    {company?.phphref && (
                      <img
                        src={company.phphref}
                        alt={`${company.name} Logo`}
                        style={{ maxWidth: '180px', maxHeight: '80px' }}
                      />
                    )}
                  </Box>
                  <Stack spacing={1.5}>
                    {/* Display custom date_of_issue if we have it */}
                    <Stack direction="row" spacing={2}>
                      <Typography variant="body2" color="textSecondary">
                        Date:
                      </Typography>
                      <Typography variant="body2">
                        {effectiveDateOfIssue}
                      </Typography>
                    </Stack>
                    {/* Display custom invoice_number if we have it */}
                    <Stack direction="row" spacing={2}>
                      <Typography variant="body2" color="textSecondary">
                        Invoice #:
                      </Typography>
                      <Typography variant="body2">
                        {invoiceNumber}
                      </Typography>
                    </Stack>
                    {/* If there's a due_date_override, show that */}
                    <Stack direction="row" spacing={2}>
                      <Typography variant="body2" color="textSecondary">
                        Due Date:
                      </Typography>
                      <Typography variant="body2">
                        {effectiveDueDate}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: 'right' }}
                >
                  <Paper
                    variant='outlined'
                    sx={{
                      p: 3,
                      background: '#f8f9fa',
                      textAlign: 'left',
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Total Amount Due
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      ${amountDueAfterPayment.toFixed(2)}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              {/* Billed To Section */}
              <Grid container spacing={4} sx={{ mb: 6 }}>
                <Grid item xs={6} >
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Billed To
                  </Typography>
                  <Paper variant='outlined' sx={{ p: 3, background: '#f8f9fa' }}>
                    {/* Use custom_details if available, else fallback */}
                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                      {effectiveBilledTo}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {invoice.client_email || user?.email}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              {/* Items (Transactions) Table */}
              <TableContainer
                component={Paper}
                sx={{
                  border: '1px solid #e9ecef',
                  overflow: 'hidden',
                  mb: 6,
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                    }}
                  >
                    <TableRow>
                      <TableCell sx={{ color: 'white' }}>
                        Item
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        Description
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        Rate
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        Qty
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payouts.map((payout) => (
                      <TableRow
                        key={payout.id}
                        sx={{ '&:last-child td': { borderBottom: 0 } }}
                      >
                        <TableCell>{payout.creator_id}</TableCell>
                        <TableCell>
                          {payout.notes || `PO Number ${payout.po_number}`}
                        </TableCell>
                        <TableCell>
                          ${safeAmount(payout.amount).toFixed(2)}
                        </TableCell>
                        <TableCell>1</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          ${safeAmount(payout.amount).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                    {invoice.invoice_items && invoice.invoice_items.length > 0 &&
                      invoice.invoice_items.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>Payment to {company.name}</TableCell>
                          <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                            {item.description}
                          </TableCell>
                          <TableCell>${item.amount.toFixed(2)}</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>${item.amount.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    {payouts.length === 0 &&
                      (!invoice.invoice_items || invoice.invoice_items.length === 0) && (
                      <TableRow>
                        <TableCell>{''}</TableCell>
                        <TableCell>
                          Payment to {company.name}
                        </TableCell>
                        <TableCell>${safeAmount(invoice.amount_due).toFixed(2)}</TableCell>
                        <TableCell>1</TableCell>
                        <TableCell>${safeAmount(invoice.amount_due).toFixed(2)}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Summary Section */}
              <Grid container justifyContent="flex-end" sx={{ mb: 6 }}>
                <Grid item xs={6}>
                  <Paper
                    variant='outlined'
                    sx={{
                      p: 3,
                      background: '#f8f9fa',
                    }}
                  >
                    <Stack spacing={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Subtotal:</Typography>
                        <Typography variant="body2">
                          ${subtotal.toFixed(2)}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Platform Fee:</Typography>
                        <Typography variant="body2">
                          ${platformFee.toFixed(2)}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Amount Paid:</Typography>
                        <Typography variant="body2">
                          ${amountPaid.toFixed(2)}
                        </Typography>
                      </Stack>
                      <Divider sx={{ my: 1 }} />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 'bold' }}
                        >
                          Total Due:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 'bold' }}
                        >
                          ${amountDueAfterPayment.toFixed(2)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              {/* Banking Information Section */}
              <Paper
                variant='outlined'
                sx={{
                  mb: 6,
                  p: 4,
                  backgroundColor: '#f8f9fa',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    mb: 3,
                    color: '#2b2d42',
                  }}
                >
                  Banking Information
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Stack spacing={1.5}>
                      <Typography variant="body2">
                        <strong>Bank Name:</strong> Prosperity Bank
                      </Typography>
                      <Typography variant="body2">
                        <strong>Account Holder:</strong> Pangea
                      </Typography>
                      <Typography variant="body2">
                        <strong>Account Number:</strong> 217841714
                      </Typography>
                      <Typography variant="body2">
                        <strong>Routing Number:</strong> 113122655
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1.5}>
                      <Typography variant="body2">
                        <strong>SWIFT Code:</strong> PROYUS44
                      </Typography>
                      <Typography variant="body2">
                        <strong>Bank Address:</strong> 2117 N. Fry Road, Katy, TX 77449
                      </Typography>
                      <Typography variant="body2">
                        <strong>Beneficiary Address:</strong> 8724 Cavell Lane, Houston, TX 77055
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              {/* Payment Section */}
              <Paper
                variant='outlined'
                sx={{
                  p: 4,
                  background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                <CheckCircle sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Secure Payment Options
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  Pay with Credit Card, PayPal, or Bank Transfer
                </Typography>
                {invoice.invoice_link && (
                  <Button
                    variant="contained"
                    color="secondary"
                    href={invoice.invoice_link}
                    startIcon={<Payment />}
                    sx={{
                      px: 6,
                      fontWeight: 'bold',
                      background: 'white',
                      color: '#2b2d42',
                      '&:hover': { background: '#f8f9fa' },
                    }}
                  >
                    Pay Now
                  </Button>
                )}
              </Paper>
            </Paper>
          </Box>
        </Box>
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default InvoicePage;
