import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Avatar,
  CircularProgress,
  Box,
  Stack 
} from "@mui/material";
import { useQuery } from "react-query";
import client from "../../API";
import profilePhoto from "../../Components/globalAssets/ppfLogo.png";

const formatCampaignSum = (sum) => {
  const numericSum = typeof sum === "number" ? sum : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

const SelectCampaignDialog = ({ open, onClose, onCampaignSelect }) => {
  const { data: campaigns, isLoading } = useQuery(
    'campaigns',
    client.campaigns.list,
    { 
      refetchOnWindowFocus: false,
      enabled:open
    }
  );

  const statusColorMap = {
    Active: 'success',
    Launched: 'success',
    Pending: 'warning',
    Draft: 'warning',
    Completed: 'info',
    Archived: 'default'
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: "white" }}>
        Select Campaign
        <Chip 
          label={`${campaigns?.length || 0} items`} 
          size="small"
          sx={{ ml: 2, color: "white", bgcolor: 'primary.dark' }}
        />
      </DialogTitle>
      
      <DialogContent sx={{ p: 3, bgcolor: "#f5f5f5" }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            {campaigns?.map((campaign) => (
              <Grid item xs={12} sm={6} md={4} key={campaign.id}>
                <Card
                  onClick={() => onCampaignSelect(campaign)}
                  sx={{
                    cursor: "pointer",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: 4,
                    },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                      <Chip
                        label="Campaign"
                        size="small"
                        color="secondary"
                        sx={{ mr: 1 }}
                      />
                      <Chip
                        label={campaign.campaign_status}
                        size="small"
                        color={statusColorMap[campaign.campaign_status] || 'default'}
                      />
                    </Box>

                    <Typography 
                      variant="subtitle1" 
                      sx={{
                        fontWeight: 600,
                        mb: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical"
                      }}
                    >
                      {campaign.name}
                    </Typography>

                    <Stack spacing={0.5} sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar 
                          src={campaign.creators?.[0]?.pfphref || profilePhoto}
                          sx={{ width: 24, height: 24, mr: 1 }}
                        />
                      </Box>
    
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button 
          variant="outlined" 
          onClick={onClose}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectCampaignDialog;