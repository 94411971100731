import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, Popover } from '@mui/material';
import React, { useState } from 'react'

export default function SelectPopup({ children, label, value, onClosePopup, inputStyle, onOpenPopup, size='medium', ...otherProps }) {
    const [popperAnchor, setPopperAnchor] = useState(null);
    const handleClick = (event) => {
        setPopperAnchor(event.currentTarget);
        onOpenPopup?.();
    };

    const handleClose = () => {
        setPopperAnchor(null);
        onClosePopup?.();
    };

    return (
        <FormControl {...otherProps} focused={!!popperAnchor} sx={inputStyle} size={size}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput readOnly label={label} onClick={handleClick} value={value} sx={{...(size=='small' && {paddingInlineEnd:'6px'})}}
                endAdornment={
                    <InputAdornment position="end">
                        {!popperAnchor ? <ArrowDropDown></ArrowDropDown> : <ArrowDropUp></ArrowDropUp>}
                    </InputAdornment>
                }
            >
            </OutlinedInput>
            <Popover
                open={!!popperAnchor}
                anchorEl={popperAnchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </FormControl>
    )
}
