import React from 'react'
import { Slide } from '../ReportComponents'
import { Box, Typography } from '@mui/material'
import { Timeline, TimelineConnector, TimelineContent, timelineContentClasses, TimelineDot, TimelineItem, TimelineOppositeContent, timelineOppositeContentClasses, TimelineSeparator } from '@mui/lab'

import DrawIcon from '@mui/icons-material/Draw';
import LaunchIcon from '@mui/icons-material/RocketLaunch';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MovieIcon from '@mui/icons-material/Movie';
import PaidIcon from '@mui/icons-material/Paid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function TimelineSlide({ index, currentSlide, report }) {

    const creatorsPaidDate = new Date(report?.finish_date);
    creatorsPaidDate.setDate(creatorsPaidDate.getDate() - 2);

    const launchDate = new Date(report?.proposal_date);
    launchDate.setDate(launchDate.getDate() + 3);

    const creatorsAcceptDate = new Date(report?.proposal_date);
    creatorsAcceptDate.setDate(creatorsAcceptDate.getDate() + 3);

    const contentDeliveredDate = new Date(report?.finish_date);
    contentDeliveredDate.setDate(contentDeliveredDate.getDate() - 14);

    return (
        <Slide
            id='slide-timeline'
            index={index}
            currentIndex={currentSlide}
            sx={theme => ({
                backgroundImage: 'linear-gradient(135deg, #d91f76, #fa7db1)',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                padding: theme.spacing(4),
                paddingBlockEnd: theme.spacing(8),
            })}>
            <Typography component={'div'} variant='h5'>Your Timeline</Typography>
            <Timeline
                sx={{
                    flexGrow: 0,
                    padding: 0,
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.4,
                        paddingInlineStart: 0,
                        py: '16px'
                    },
                    [`& .${timelineContentClasses.root}`]: {
                        py: '16px'
                    },
                }}
            >
                <TimelineItem>
                    <TimelineOppositeContent
                        color="textSecondary">
                        {new Date(report?.proposal_date).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot>
                            <DrawIcon></DrawIcon>
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                    >Campaign Drafted
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                        {launchDate?.toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color='success'>
                            <LaunchIcon></LaunchIcon>
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Campaign Launched</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                    {creatorsAcceptDate?.toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color='secondary'>
                            <DoneAllIcon></DoneAllIcon>
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Creators Accepted</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                        {contentDeliveredDate?.toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color='primary'>
                            <MovieIcon></MovieIcon>
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Content Delivered</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                        {creatorsPaidDate?.toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color='secondary'>
                            <PaidIcon></PaidIcon>
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Creators Paid</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                        {new Date(report?.finish_date).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color='success'>
                            <CheckCircleIcon></CheckCircleIcon>
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>Campaign Completed!</TimelineContent>
                </TimelineItem>
            </Timeline>
            <Typography component={'div'} variant='subtitle2' color='text.secondary' >{report?.name}</Typography>
        </Slide>
    )
}
