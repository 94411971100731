import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  DialogContentText,
  InputAdornment,  // added for the dollar sign adornment
} from '@mui/material';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import { useNavigate } from 'react-router-dom';

const CreatorInvoicingPage = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const navigate = useNavigate();

  // Invoice states
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [poNumber, setPoNumber] = useState('');

  // For interest rate + fee calculations
  const [interestRate, setInterestRate] = useState(0); // e.g., 10 means 10%
  const [blitzFee, setBlitzFee] = useState(0);
  const [netToReceive, setNetToReceive] = useState(0);

  // Snackbar for success/error messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Generate a random PO number upon page load or whenever needed
  const handleGeneratePO = () => {
    setPoNumber(`TCC-${Math.floor(Math.random() * 1000000)}`);
  };

  useEffect(() => {
    // Generate a PO number as soon as the page loads
    handleGeneratePO();
  }, []);

  // Whenever "amount" or "interestRate" changes, recalculate fee & net
  useEffect(() => {
    const amt = parseFloat(amount) || 0;
    const rate = parseFloat(interestRate) || 0;
    const fee = (amt * rate) / 100;
    setBlitzFee(fee);
    setNetToReceive(amt - fee);
  }, [amount, interestRate]);

  // Close snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // Check if user is in the system (by email)
  const handleCheckEmail = async () => {
    if (!clientEmail) return;

    try {
      const response = await API.creatorConnect.fetchUserInfo(clientEmail);

      if (response?.found) {
        // If user is found in the system, prefill name & interest rate
        const userData = response.user;
        setClientName(`${userData.first_name} ${userData.last_name}`);
        setInterestRate(userData.company_interest_rate ?? 0);
        setSnackbarMessage('User found in the system. Company interest rate applied.');
        setSnackbarOpen(true);
      } else {
        // Not found: still let them proceed, maybe use a default interest rate
        setInterestRate(10); 
        setSnackbarMessage(
          'User not found. We will email them instructions to sign up. You can still proceed.'
        );
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.error('Error fetching user info:', err);
      setSnackbarMessage(`Error fetching user info: ${err.message}`);
      setSnackbarOpen(true);
    }
  };

  // Main invoice creation function
  const handleInvoice = async () => {
    try {
      if (!username) {
        setSnackbarMessage('User not authenticated');
        setSnackbarOpen(true);
        return;
      }

      const parsedAmount = parseFloat(amount) || 0;

      await API.creatorConnect.postInvoice({
        user_id: username,
        creator_id: username,
        po_number: poNumber,
        amount: parsedAmount,
        payout_date: new Date().toISOString(),
        status: 'Pending',
        blitzpay: true,
        unique_code: poNumber,
        interest_rate: interestRate, 
        client_name: clientName,
        client_email: clientEmail,
        notes,
      });

      // Show user success message
      setSnackbarMessage('Invoice sent successfully!');
      setSnackbarOpen(true);

      // Optionally reset form
      setClientName('');
      setClientEmail('');
      setAmount('');
      setNotes('');
      setInterestRate(0);
      setBlitzFee(0);
      setNetToReceive(0);
      handleGeneratePO();

      // Redirect after success
      setTimeout(() => {
        navigate('/creatorconnect/payouts-invoicing');
      }, 1000);

    } catch (error) {
      console.error('Error sending invoice:', error);
      setSnackbarMessage(`Error sending invoice: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 600, margin: '0 auto' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Create a New Invoice
      </Typography>

      <DialogContentText sx={{ mb: 2 }}>
        Please enter the details for the client you want to invoice.
      </DialogContentText>

      <TextField
        label="Client Email"
        variant="outlined"
        fullWidth
        value={clientEmail}
        onChange={(e) => setClientEmail(e.target.value)}
        onBlur={handleCheckEmail}
        sx={{ mb: 2 }}
      />

      <TextField
        label="Client Name"
        variant="outlined"
        fullWidth
        value={clientName}
        onChange={(e) => setClientName(e.target.value)}
        sx={{ mb: 2 }}
      />

      <TextField
        label="Amount"
        variant="outlined"
        fullWidth
        type="number"
        value={amount}
        onChange={(e) => {
          // Allow only numbers and a single decimal point
          const regex = /^[0-9]*\.?[0-9]*$/;
          if (e.target.value === '' || regex.test(e.target.value)) {
            setAmount(e.target.value);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        sx={{ mb: 2 }}
      />

      <TextField
        label="Notes"
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        sx={{ mb: 2 }}
      />

      <Typography variant="body2" sx={{ mb: 1 }}>
        Purchase Order (PO) Number: {poNumber}
      </Typography>

      {/* Show interest rate & calculations */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2">
          Interest Rate: {interestRate}%
        </Typography>
        <Typography variant="body2">
          Blitz Fee: ${blitzFee.toFixed(2)}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          You will receive ${netToReceive.toFixed(2)} (within 24 hrs)
        </Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={handleInvoice} sx={{ mt: 2 }}>
        Send Invoice
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      />
    </Box>
  );
};

export default CreatorInvoicingPage;
