// ConversationsHub.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// Replace with your actual file paths
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';

import API from '../../../API';
import useIsDesktop from '../../../Hooks/useIsDesktop';
import useAuth from '../../../Hooks/use-auth';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';

export default function ConversationsHub() {
  const queryClient = useQueryClient();
  const isMobile = !useIsDesktop();

  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [isLeftPanelCollapsed, setIsLeftPanelCollapsed] = useState(false);

  // For the "Add Participant" dialog
  const [addParticipantOpen, setAddParticipantOpen] = useState(false);
  const [inviteEmails, setInviteEmails] = useState('');

  // State for "Create New Chat"
  const [newChatOpen, setNewChatOpen] = useState(false);
  const [selectedNewChatUsers, setSelectedNewChatUsers] = useState([]);

  // Hooks for user info
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();
  const { creatorToken } = useCreatorAuth();

  const userId = userInfo?.id || creatorToken?.creator_user?.username || null;

  // ===============================
  // =========== QUERIES ===========
  // ===============================
  // Fetch existing conversations
  const {
    data: conversations,
    isLoading: isLoadingConversations
  } = useQuery(
    ['messages', 'userSentConversations'],
    () => API.conversations.fetchUserToUser(),
    {
      initialData: []
    }
  );

  // Fetch user list (for adding participants, etc.)
  const {
    data: usersList,
    isLoading: isLoadingUsers
  } = useQuery(
    ['users'],
    () => API.users.publicList(),
    {
      refetchOnWindowFocus: false,
      initialData: []
    }
  );

  // ===============================
  // ====== MUTATIONS: CREATE ======
  // ===============================
  // Create or find conversation (1-on-1 or group)
  const createNewChatMutation = useMutation(
    (params) => API.conversations.checkUserToUser(params),
    {
      onSuccess: (res) => {
        // Once created, re-fetch
        queryClient.invalidateQueries(['userSentConversations']);
        handleCloseNewChat();
        setSelectedConversationId(res.conversation_id); // Jump right into it
      },
      onError: (err) => {
        console.error('Error creating new conversation:', err);
        openDialog(
          'Error',
          `Error creating conversation: ${err?.response?.data?.error || err.message}`
        );
      }
    }
  );

  // Invite participants by email
  const inviteByEmailMutation = useMutation(
    (emailsArray) => API.conversations.inviteByEmail(selectedConversationId, emailsArray),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userSentConversations']);
        queryClient.invalidateQueries(['messages', selectedConversationId]);
        handleCloseAddParticipant();
      },
      onError: (err) => {
        console.error('Error inviting by email:', err);
        openDialog(
          'Error',
          `Error inviting to conversation: ${err?.response?.data?.error || err.message}`
        );
      }
    }
  );

  // ===============================
  // =========== HANDLERS ==========
  // ===============================
  const toggleLeftPanel = () => {
    setIsLeftPanelCollapsed(!isLeftPanelCollapsed);
  };

  // Add participant button
  const handleClickAddParticipant = () => {
    setAddParticipantOpen(true);
  };
  const handleCloseAddParticipant = () => {
    setAddParticipantOpen(false);
    setInviteEmails('');
  };

  // Create new chat dialog
  const handleOpenNewChat = () => {
    setNewChatOpen(true);
  };
  const handleCloseNewChat = () => {
    setNewChatOpen(false);
    setSelectedNewChatUsers([]);
  };

  // Create the conversation from selected new chat users
  const handleCreateOrFindChat = () => {
    if (!selectedNewChatUsers || !selectedNewChatUsers.length) return;
    const recipientIds = selectedNewChatUsers.map((u) => u.value);
    const params =
      recipientIds.length === 1
        ? { recipient_id: recipientIds[0] }
        : { recipient_ids: recipientIds };
    createNewChatMutation.mutate(params);
  };

  // Parse user data for Autocomplete
  const adminUserOptions = usersList.map((u) => ({
    label: `${u.first_name} ${u.last_name}`,
    value: u.id
  }));

  // Grab the currently selected conversation object
  const selectedConversation = conversations.find(
    (c) => c.id === selectedConversationId
  );

  // Invite participants by email
  const handleInviteByEmail = () => {
    const trimmed = inviteEmails.trim();
    if (!trimmed) return;
    const emailsArray = trimmed
      .split(',')
      .map((e) => e.trim())
      .filter(Boolean);
    inviteByEmailMutation.mutate({ emails: emailsArray });
  };

  const handleBackToList = () => {
      setSelectedConversationId(null);
  };
    

  // ===============================
  // =========== RENDER ============
  // ===============================
  return (
    <Box
      sx={{
        p: 3,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default'
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
        Conversations Hub
      </Typography>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          gap: 2,
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        {/* LEFT PANEL */}
        <LeftPanel
          isLoadingConversations={isLoadingConversations}
          conversations={conversations}
          selectedConversationId={selectedConversationId}
          isMobile={isMobile}
          isLeftPanelCollapsed={isLeftPanelCollapsed}
          handleSelectConversation={setSelectedConversationId}
          toggleLeftPanel={toggleLeftPanel}
          onCreateNewChatClicked={handleOpenNewChat}
          userId={userId}
        />

        {/* RIGHT PANEL */}
        <RightPanel
          isMobile={isMobile}
          isLeftPanelCollapsed={isLeftPanelCollapsed}
          selectedConversation={selectedConversation}
          selectedConversationId={selectedConversationId}
          toggleLeftPanel={toggleLeftPanel}
          handleClickAddParticipant={handleClickAddParticipant}
          onBackToList={handleBackToList}

        />
      </Box>

      {/* ADD PARTICIPANT DIALOG */}
      <Dialog open={addParticipantOpen} onClose={handleCloseAddParticipant}>
        <DialogTitle>Add Participant</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 3, minWidth: '350px' }}
        >
          {isLoadingUsers ? (
            <CircularProgress />
          ) : (
            <>
              {/* Option: Add a user by email directly */}
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Invite by email (comma-separated for multiple):
                </Typography>
                <TextField
                  label="Email(s)"
                  variant="outlined"
                  fullWidth
                  value={inviteEmails}
                  onChange={(e) => setInviteEmails(e.target.value)}
                  placeholder="john@example.com, jane@example.com"
                />
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  color="secondary"
                  onClick={handleInviteByEmail}
                  disabled={inviteByEmailMutation.isLoading}
                >
                  {inviteByEmailMutation.isLoading ? 'Inviting...' : 'Invite by Email'}
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddParticipant}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* CREATE NEW CHAT DIALOG */}
      <Dialog
        open={newChatOpen}
        onClose={handleCloseNewChat}
        fullWidth
        maxWidth="xl"
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            maxWidth: '90vw',
            height: '500px',
            maxHeight: '80vh'
          }
        }}
      >
        <DialogTitle>Create New Chat</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {isLoadingUsers ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Autocomplete
              multiple
              options={adminUserOptions}
              value={selectedNewChatUsers}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, test) => option.value === test.value}
              onChange={(event, newValue) => setSelectedNewChatUsers(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user(s)"
                  variant="outlined"
                  placeholder="Search..."
                />
              )}
              sx={{ width: '100%', mt: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewChat}>Close</Button>
          <Button
            variant="contained"
            onClick={handleCreateOrFindChat}
            disabled={createNewChatMutation.isLoading || !selectedNewChatUsers.length}
          >
            {createNewChatMutation.isLoading ? 'Creating...' : 'Create Chat'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* GLOBAL ALERT DIALOG (if you use it in your app) */}
      <AlertDialog alertState={dialogState} />
    </Box>
  );
}
