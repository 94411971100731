import React from "react";
import { Card, CardContent, CardHeader, Typography, Box, Button } from "@mui/material";

const DeliverableCard = ({ deliverable, onViewOffer, isUserMessage }) => {
  // Helper functions for date formatting
  const formatDate = (dateStr) =>
    dateStr ? new Date(dateStr).toLocaleDateString() : "N/A";
  const formatDateTime = (dateStr) =>
    dateStr ? new Date(dateStr).toLocaleString() : "N/A";

  // Render milestones if available
  const renderMilestones = () => {
    if (!deliverable.milestones || deliverable.milestones.length === 0) {
      return <Typography variant="body2">No milestones defined.</Typography>;
    }
    return deliverable.milestones.map((milestone, idx) => (
      <Box
        key={idx}
        sx={{
          mt: 1,
          p: 1,
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 1,
          backgroundColor: "grey.100",
        }}
      >
        <Typography variant="body2">
          <strong>Description:</strong> {milestone.description}
        </Typography>
        <Typography variant="body2">
          <strong>Due Date:</strong> {formatDate(milestone.due_date)}
        </Typography>
        {milestone.amount != null && (
          <Typography variant="body2">
            <strong>Amount:</strong> ${parseFloat(milestone.amount).toFixed(2)}
          </Typography>
        )}
        {milestone.link && (
          <Typography variant="body2">
            <strong>Link:</strong>{" "}
            <a href={milestone.link} target="_blank" rel="noopener noreferrer">
              {milestone.link}
            </a>
          </Typography>
        )}
      </Box>
    ));
  };

  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        minHeight: 300,
        maxWidth: 350,
        boxShadow: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.02)",
          cursor: "pointer",
        },
        margin: "auto",
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h6"
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {deliverable.deliverable_type}
          </Typography>
        }
        sx={{ color: "text.primary", p: 0, pb: 1 }}
      />
      <CardContent sx={{ flexGrow: 1, p: 0 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <strong>Amount:</strong>{" "}
          ${deliverable.amount ? parseFloat(deliverable.amount).toFixed(2) : "N/A"}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <strong>Status:</strong> {deliverable.status}
        </Typography>
        {deliverable.due_date && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Due Date:</strong> {formatDate(deliverable.due_date)}
          </Typography>
        )}
        {deliverable.platforms && deliverable.platforms.length > 0 && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Platforms:</strong>{" "}
            {Array.isArray(deliverable.platforms)
              ? deliverable.platforms.join(", ")
              : deliverable.platforms}
          </Typography>
        )}
        {deliverable.notes && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Notes:</strong> {deliverable.notes}
          </Typography>
        )}
        {deliverable.revision != null && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Revision:</strong> {deliverable.revision}
          </Typography>
        )}
        {deliverable.script && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Script:</strong> {deliverable.script}
          </Typography>
        )}
        {deliverable.milestones && deliverable.milestones.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: "medium" }}>
              <strong>Milestones:</strong>
            </Typography>
            {renderMilestones()}
          </Box>
        )}
      </CardContent>
      <Box sx={{ pt: 2 }}>
        <Button
          onClick={() => onViewOffer && onViewOffer(deliverable)}
          color="primary"
          variant="contained"
          fullWidth
          sx={{
            textTransform: "none",
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          View Offer
        </Button>
      </Box>
    </Card>
  );
};

export default DeliverableCard;
