import React from 'react';
import { Box, Typography, Link as MuiLink, Avatar } from '@mui/material';
import BaseBubble from './BaseBubble';
import { formatLinksInMessage, getFirstReaderDate, isMessageMine } from '../../Utils/constants';

export default function ChatBubble({ 
  msg, 
  userId, 
  isTcc, 
  handleDeleteMessage, 
  deleteMessageLoading, 
  handleReadReceipts,
  participants = []
}) {
  // Determine if the message is from the current user
  const isUserMessage = isMessageMine(userId, msg);
  
  // Get the sender's ID (either sender_id or creator_id)
  const senderId = `${msg.sender_id || msg.creator_id}`;
  
  // Calculate the read receipt date
  const readOnDate = getFirstReaderDate(msg.read_receipts, senderId);
  
  // Construct the sender's name
  let senderName = '';
  if (msg.sender_id) {
    senderName = `${msg.sender?.first_name || 'N/A'} ${msg.sender?.last_name || 'N/A'}`;
  } else if (msg.creator_id) {
    senderName = msg.creator_id;
  } else {
    senderName = 'Blitz System';
  }
  
  // Determine the avatar URL with fallback to participants list
  let avatarUrl = 
    msg.sender?.pfphref   // Creator's message avatar
    || msg.sender?.phphref // User's message avatar
    || msg.creator?.pfphref
    || '';
  
  if (!avatarUrl && participants.length > 0) {
    const participant = participants.find(p => p.id === senderId);
    avatarUrl = participant?.pfp || '';
  }
  
  // Generate the profile link based on sender ID type
  const isNumeric = /^\d+$/.test(senderId);
  const profileLink = isNumeric ? `/user/${senderId}` : `/creators/${senderId}`;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUserMessage ? 'flex-end' : 'flex-start',
        mb: 1.5  // Tighter spacing between messages
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isUserMessage ? 'row-reverse' : 'row',
          alignItems: 'center',  // Center alignment for avatar and bubble
          gap: 2,  // Space between avatar and bubble
          maxWidth: '85%'  // Limit bubble width
        }}
      >
        <Avatar 
          src={avatarUrl || '/default-avatar.png'}  // Default avatar if none found
          alt={senderName}
          sx={{ 
            width: 40, 
            height: 40,
            alignSelf: 'flex-start'  // Align avatar to top for multi-line messages
          }}
        />
        
        <BaseBubble
          isUserMessage={isUserMessage}
          isTcc={isTcc}
          handleDeleteMessage={() => handleDeleteMessage(msg.id)}
          deleteMessageLoading={deleteMessageLoading}
          sx={{
            minWidth: '120px',  // Minimum bubble width
            maxWidth: '100%'    // Prevent overflow
          }}
        >
          {/* Sender name for non-user messages */}
          {!isUserMessage && (
            <Typography 
              variant="subtitle2" 
              sx={{ 
                fontWeight: 'bold',
                mb: 0.5  // Space below sender name
              }}
            >
              <MuiLink 
                href={profileLink} 
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {senderName}
              </MuiLink>
            </Typography>
          )}
          
          {/* Message content */}
          <Typography
            variant="body1"
            sx={{ 
              textAlign: 'left', 
              overflowWrap: 'break-word', 
              whiteSpace: 'pre-wrap',
              lineHeight: 1.4,  // Improved readability
              mb: 0.5   // Space below message
            }}
          >
            {formatLinksInMessage(msg.message)}
          </Typography>
          
          {/* Timestamp and read receipt */}
          <Typography 
            variant="caption" 
            sx={{ 
              display: 'block',
              lineHeight: 1.2,
              color: isUserMessage ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.6)'
            }}
          >
            Sent on {new Date(msg.created_at).toLocaleString(undefined, {
              dateStyle: 'short',
              timeStyle: 'short',
              hour12: true
            })}
            <br />
            <MuiLink
              color={isUserMessage ? 'rgba(255,255,255,0.7)' : '#333'}
              underline="hover"
              component="button"
              onClick={() => handleReadReceipts(msg)}
              sx={{
                fontSize: '0.75rem',
                '&:hover': {
                  color: isUserMessage ? 'rgba(255,255,255,0.9)' : '#000'
                }
              }}
            >
              {!!readOnDate
                ? `Read on ${readOnDate.toLocaleString(undefined, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                    hour12: true
                  })}`
                : 'Not read yet'}
            </MuiLink>
          </Typography>
        </BaseBubble>
      </Box>
    </Box>
  );
}