import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Typography,
  Box,
  Paper,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../API";
import {
  dateToDatetimeLocal,
  invoiceStatuses,
  parseLocalFloat,
  parseLocalInt,
} from "../../../Utils/constants";
// ^ (Adjust imports if your constants or helper functions have a different path)

const InvoiceDialog = ({ open, onClose, invoiceInfo = undefined }) => {
  // --------------------------------------------
  // State variables from your original code
  // --------------------------------------------
  const [poNumber, setPONumber] = useState("");
  const [amountDue, setAmountDue] = useState("0.00");
  const [status, setStatus] = useState("");
  const [feePercentage, setFeePercentage] = useState("0");
  const [paymentTerm, setPaymentTerm] = useState(30);
  const [datePaid, setDatePaid] = useState(null);

  // If you still want platformFee in the UI, you can keep it.
  // It's commented out in your code, but if you need it, just re-add it:
  // const [platformFee, setPlatformFee] = useState("");

  // --------------------------------------------
  // On mount (or invoiceInfo change), populate
  // --------------------------------------------
  useEffect(() => {
    if (invoiceInfo) {
      setPONumber(invoiceInfo.po_number || "");
      setAmountDue(invoiceInfo.amount_due?.toFixed(2) || "0.00");
      setFeePercentage(invoiceInfo.fee_percentage?.toFixed(0) || "0");
      setStatus(invoiceInfo.status || "");
      setPaymentTerm(invoiceInfo.payment_term?.toString() || "30");

      if (invoiceInfo.date_paid) {
        setDatePaid(new Date(invoiceInfo.date_paid));
      } else {
        setDatePaid(null);
      }
    }
  }, [invoiceInfo]);

  // --------------------------------------------
  // Re-use your existing function to compute net
  // --------------------------------------------
  const calculateAmountReceive = () => {
    try {
      const feeFloat = parseLocalFloat(feePercentage) || 0;
      const amtFloat = parseLocalFloat(amountDue) || 0;
      const net = amtFloat * (1 - feeFloat / 100);
      return net.toFixed(2);
    } catch {
      return "";
    }
  };
  const amountReceive = calculateAmountReceive();

  // --------------------------------------------
  // Let admin pick a date/time to set "date_paid"
  // --------------------------------------------
  const handleDatePaidChange = (newVal) => {
    if (!newVal) {
      setDatePaid(null);
      return;
    }
    setDatePaid(new Date(newVal));
  };

  // --------------------------------------------
  // Build your payload & call mutation
  // --------------------------------------------
  const { mutate: editInvoiceAdmin, isLoading } = useMutation(
    client.invoices.editAdmin,
    {
      onSuccess: () => onClose(true),
      onError: (error) => {
        console.error("Error submitting invoice:", error);
        alert(
          error?.response?.data?.error ||
            error?.response?.data?.message ||
            error.message
        );
      },
    }
  );

  const handleSubmit = () => {
    const submissionData = {
      id: invoiceInfo?.id,
      po_number: poNumber,
      amount_due: parseLocalFloat(amountDue),
      fee_percentage: parseLocalFloat(feePercentage),
      status: status,
      payment_term: parseLocalInt(paymentTerm),
      date_paid: datePaid, // If null => cleared
    };

    editInvoiceAdmin(submissionData);
  };

  // --------------------------------------------
  // Derived for preview
  // --------------------------------------------
  const displayPO = poNumber || "N/A";
  const displayAmountDue = amountDue || "0.00";
  const displayFeePercent = feePercentage || "0";
  const displayPaymentTerm = paymentTerm || "30";
  const displayStatus = status || "N/A";
  const displayDatePaid = datePaid ? datePaid.toLocaleString() : "None";

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="invoice-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="invoice-dialog-title">Edit Invoice (Admin)</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 4, alignItems: "flex-start" }}>
          {/* -------------------------------------------------
              LEFT SIDE: Form Fields
          ------------------------------------------------- */}
          <Box sx={{ flex: 1, minWidth: 300 }}>
            <TextField
              label="PO Number"
              fullWidth
              margin="dense"
              value={poNumber}
              onChange={(e) => setPONumber(e.target.value)}
              required
            />

            <TextField
              label="Amount Due"
              type="number"
              fullWidth
              margin="dense"
              value={amountDue}
              onChange={(e) => setAmountDue(e.target.value)}
              required
            />

            <TextField
              label="Fee Percentage"
              type="number"
              fullWidth
              margin="dense"
              value={feePercentage}
              onChange={(e) => setFeePercentage(e.target.value)}
            />

            {/* Net (user receives or net to be paid) */}
            <TextField
              label={
                invoiceInfo?.invoice_type === "client"
                  ? "Amount user will receive"
                  : "Amount to collect"
              }
              type="number"
              fullWidth
              margin="dense"
              value={amountReceive}
              disabled
            />

            <TextField
              label="Status"
              select
              fullWidth
              margin="dense"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
            >
              {invoiceStatuses.map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Payment Term (days)"
              type="number"
              fullWidth
              margin="dense"
              value={paymentTerm}
              onChange={(e) => setPaymentTerm(e.target.value)}
              required
            />

            {/* NEW: Date Paid Field */}
            <TextField
              label="Date Paid"
              type="datetime-local"
              fullWidth
              margin="dense"
              value={dateToDatetimeLocal(datePaid)}
              onChange={(e) => handleDatePaidChange(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          {/* -------------------------------------------------
              RIGHT SIDE: Live Preview
          ------------------------------------------------- */}
          <Box
            sx={{
              flex: 1,
              borderLeft: "1px solid #ccc",
              pl: 3,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Invoice Preview
            </Typography>

            {/* The "invoice" style preview in a Paper */}
            <Paper
              elevation={3}
              sx={{
                p: 4,
                maxWidth: "700px",
                background: "white",
                margin: "0 auto",
                fontFamily: "Arial, sans-serif",
              }}
            >
              {/* Header */}
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={6}>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", color: "#4a4e69", mb: 1 }}
                  >
                    INVOICE
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#4a4e69" }}>
                    (Admin Preview)
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 2,
                      background: "#f8f9fa",
                      display: "inline-block",
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Status
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {displayStatus}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              {/* Body / Info */}
              <Stack spacing={1.5} sx={{ mb: 2 }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" color="textSecondary">
                    PO #:
                  </Typography>
                  <Typography variant="body2">{displayPO}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" color="textSecondary">
                    Amount Due:
                  </Typography>
                  <Typography variant="body2">${displayAmountDue}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" color="textSecondary">
                    Fee (%):
                  </Typography>
                  <Typography variant="body2">{displayFeePercent}%</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" color="textSecondary">
                    Payment Term (days):
                  </Typography>
                  <Typography variant="body2">{displayPaymentTerm}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" color="textSecondary">
                    Date Paid:
                  </Typography>
                  <Typography variant="body2">{displayDatePaid}</Typography>
                </Stack>
              </Stack>

              <Divider sx={{ my: 2 }} />

              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Amount Received / Net:</strong> ${amountReceive}
              </Typography>

              <Divider sx={{ my: 2 }} />

              {/* Additional placeholders for a real invoice layout */}
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                (Placeholder) Payment Options
              </Typography>
              <Typography variant="body2">
                In a real invoice, we'd show “Pay Now” buttons, bank info, etc.
              </Typography>
            </Paper>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDialog;
