// VendorSignupPage.js
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "./../../../API";
import { parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import MuiPhoneInput from "../../../Components/MuiPhoneInput";

const VendorSignupPage = () => {
  // Extract the company name from the URL parameters
  const { company } = useParams();
  const navigate = useNavigate();

  // Pre-fill vendor name with the company param if available
  const [vendorName, setVendorName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailSame, setEmailSame] = useState(false);
  const [vendorRegion, setVendorRegion] = useState("");
  const [notesContentStyle, setNotesContentStyle] = useState("tell us about yourself");

  // (Optional) Additional states if needed for more details:
  const [primaryMarket, setPrimaryMarket] = useState([]);
  const [race, setRace] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [pfphref, setPfphref] = useState("");

  // Social media states (if applicable)
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");

  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");

  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtubeShortsBrandRate, setYoutubeShortsBrandRate] = useState("");

  // When the checkbox is toggled, automatically fill/clear the payment email field
  const handleEmailSameChange = (event) => {
    setEmailSame(event.target.checked);
    if (event.target.checked) {
      setPaymentEmail(contactEmail);
    } else {
      setPaymentEmail("");
    }
  };

  // Set up the mutation to submit the vendor signup
  const { mutate: addNewVendor, isLoading } = useMutation(client.creators.add, {
    onSuccess: (data) => {
      alert("Vendor added successfully");
      navigate(`/vendors/${vendorName}`);
    },
    onError: (error) => {
      alert("Error saving vendor data: " + error);
    },
  });

  // Optional: helper functions to format numbers/rates (if needed)
  const formatNumber = (value) => {
    const number = parseInt(value, 10);
    return isNaN(number) ? "" : number.toLocaleString();
  };
  const formatRate = (value) => {
    const number = parseInt(value, 10);
    return isNaN(number) ? "" : `$${number.toLocaleString()}`;
  };

  // Build and submit the payload when the form is submitted
  const handleSaveVendor = () => {
    if (phoneNumber.length < 11) {
      alert("This phone number is not valid");
      return;
    }

    // Format social media links if provided
    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;

    let payload = null;
    try {
      payload = {
        // Vendor name (passed in as "creator" so that the same API can be used)
        creator: vendorName,
        // Social media numbers/links
        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,
        // Optional geographic/ethnicity info
        geolocationgenderethnicity: `${location} / ${gender} / ${race}` || null,
        primary_markets: primaryMarket,
        // Basic vendor info
        region: vendorRegion,
        notescontent_style: notesContentStyle || null,
        // Social media rates (if applicable)
        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),
        rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_yt_short: parseLocalFloat(youtubeShortsBrandRate),
        // Contact info
        contactEmail: contactEmail,
        paymentEmail: paymentEmail,
        phone_number: phoneNumber,
        pfphref: pfphref || null,
        // Mark this record as a vendor
        is_vendor: true,
        // Include the company name from the URL params
        company: company || null,
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }

    addNewVendor(payload);
  };

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Typography variant="h4" gutterBottom>
          Vendor Signup Form {company ? `for ${company}` : ""}
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveVendor();
          }}
        >
          <TextField
            margin="dense"
            label="Vendor Name"
            type="text"
            fullWidth
            value={vendorName}
            onChange={(e) => setVendorName(e.target.value)}
            variant="outlined"
          />
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Vendor Region</InputLabel>
            <Select
              label="Vendor Region"
              value={vendorRegion}
              onChange={(e) => setVendorRegion(e.target.value)}
            >
              <MenuItem value="NACAUKAUS">USA, Canada, UK, Australia</MenuItem>
              <MenuItem value="Europe">Europe</MenuItem>
              <MenuItem value="Asia">Asia</MenuItem>
              <MenuItem value="LATAM">LATAM</MenuItem>
              <MenuItem value="Africa">Africa</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Bio"
            type="text"
            fullWidth
            multiline
            rows={4}
            placeholder="Tell us about yourself"
            variant="outlined"
            value={notesContentStyle}
            onChange={(e) => setNotesContentStyle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Contact Email"
            type="email"
            fullWidth
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Paypal Payment Email"
            type="email"
            fullWidth
            disabled={emailSame}
            value={paymentEmail}
            onChange={(e) => setPaymentEmail(e.target.value)}
            variant="outlined"
          />
          <MuiPhoneInput
            country="us"
            preferredCountries={["us"]}
            value={phoneNumber}
            onChange={setPhoneNumber}
            isValid={(value) => value.length >= 11}
            required
          />
          <FormControlLabel
            control={<Checkbox checked={emailSame} onChange={handleEmailSameChange} />}
            label="Payment and contact email are the same"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 2 }}
            disabled={isLoading}
          >
            Submit Information
          </Button>
        </form>
      </Container>
    </>
  );
};

export default VendorSignupPage;
