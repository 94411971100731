import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Avatar,
  Paper,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "../../../../../API";
import useAlertDialog from "../../../../../Components/useAlertDialog";
import AlertDialog from "../../../../../Components/AlertDialog";
import ChatScreen from "../../../../../Components/ChatScreen";

// TODO -> CHANGE THE LOGIC OF MESSAGE FETCHING. USE A useQuery TO FETCH MESSAGES IF THEY EXIST, 
//         TRIGGER CREATION OF THE CONVERSATION ON SENDING A MESSAGE
const CampaignCreatorSpecific = () => {
  // ----------------------------
  // Hooks & Route Params
  // ----------------------------
  const queryClient = useQueryClient(); // for invalidating/refetching queries after mutations
  const { campaignId, creatorName } = useParams();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // ----------------------------
  // Local State
  // ----------------------------
  // For "Add Participant" dialog
  const [addParticipantOpen, setAddParticipantOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [inviteEmails, setInviteEmails] = useState("");

  // ----------------------------
  // Campaign & Creator Queries
  // ----------------------------
  const {
    data: campaignData,
    isLoading: campaignLoading,
    error: campaignError,
  } = useQuery(["campaign", campaignId], () => client.campaigns.details(campaignId), {
    enabled: !!campaignId,
  });

  const creatorCampaignData = useMemo(() => {
    if (!campaignData) return undefined;
    const creators = campaignData.creators;
    return creators.find((creator) => creator.id === creatorName);
  }, [campaignData]);

  const {
    data: creatorData,
    isLoading: creatorLoading,
    error: creatorError,
  } = useQuery(["creator", creatorName], () => client.creators.fetchDetails(creatorName), {
    enabled: !!creatorName && !!creatorCampaignData,
  });

  // ----------------------------
  // Check if conversation already exists for this campaign-creator
  // ----------------------------
  const {
    data: dataCheck,
    refetch: refetchDataCheck,
  } = useQuery(
    ["checkConversation", campaignId, creatorData?.creator?.creator],
    () =>
      client.conversations.checkCampaign({
        campaign_id: campaignId,
        creator_id: creatorData.creator.creator,
      }),
    {
      enabled: !!campaignData && !!creatorData,
    }
  );

  const conversationId = dataCheck?.conversation_id;

  // ----------------------------
  // Create conversation (if none exists)
  // ----------------------------
  const {
    mutate: createConversation,
    isLoading: createConversationLoading,
  } = useMutation(client.conversations.createCampaign, {
    onSuccess: () => {
      // re-check conversation so we get the new conversation_id
      refetchDataCheck();
    },
    onError: (error) => {
      openDialog(
        "Error",
        `Error creating conversation: ${
          error?.response?.data?.error || error.message
        }`
      );
    },
  });

  useEffect(() => {
    if (!!dataCheck) {
      if (!conversationId && !createConversationLoading) {
        createConversation({
          campaign_id: campaignId,
          creator_id: creatorData.creator.creator,
        });
      }
    }
  }, [dataCheck, createConversationLoading]);

  // ----------------------------
  // (2) Mutation: share conversation with selected participant
  // ----------------------------
  const shareConversationMutation = useMutation(
    ({ conversationId, participantIds }) =>
      client.conversations.share(conversationId, participantIds),
    {
      onSuccess: () => {
        // re-check or invalidate query so participants get updated
        queryClient.invalidateQueries([
          "checkConversation",
          campaignId,
          creatorData?.creator?.creator,
        ]);
        handleCloseAddParticipant();
      },
      onError: (error) => {
        openDialog(
          "Error",
          `Error adding participant: ${
            error?.response?.data?.error || error.message
          }`
        );
      },
    }
  );

  // ----------------------------
  // (3) Mutation: invite by email(s)
  // ----------------------------
  const inviteByEmailMutation = useMutation(
    (emailsArray) => client.conversations.inviteByEmail(conversationId, emailsArray),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "checkConversation",
          campaignId,
          creatorData?.creator?.creator,
        ]);
        handleCloseAddParticipant();
      },
      onError: (error) => {
        openDialog(
          "Error",
          `Error inviting by email: ${
            error?.response?.data?.error || error.message
          }`
        );
      },
    }
  );

  // ----------------------------
  // Dialog Helpers
  // ----------------------------
  const handleOpenAddParticipant = () => {
    setAddParticipantOpen(true);
  };

  const handleCloseAddParticipant = () => {
    setAddParticipantOpen(false);
    setSelectedParticipant(null);
    setInviteEmails("");
  };

  const handleConfirmAddParticipant = () => {
    if (!selectedParticipant) return;
    shareConversationMutation.mutate({
      conversationId,
      participantIds: [selectedParticipant.value],
    });
  };

  const handleInviteByEmail = () => {
    const trimmed = inviteEmails.trim();
    if (!trimmed) return;
    // Support multiple emails, comma-separated
    const emailsArray = trimmed.split(",").map((e) => e.trim()).filter(Boolean);
    inviteByEmailMutation.mutate({ emails: emailsArray });
  };

  // ----------------------------
  // Loading / Error States
  // ----------------------------
  if (campaignLoading || creatorLoading) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </Typography>
    );
  }

  if (campaignError) {
    return (
      <Typography>
        Error loading campaign data:{" "}
        {campaignError?.response?.data?.error || campaignError.message}
      </Typography>
    );
  }

  if (creatorError) {
    return (
      <Typography>
        Error loading creator data:{" "}
        {creatorError?.response?.data?.error || creatorError.message}
      </Typography>
    );
  }

  // ----------------------------
  // Derived Values
  // ----------------------------
  const videoList =
    creatorCampaignData?.videos
      .filter((video) => video.parentId == null)
      .map((video) => ({ ...video })) || [];

  const crosspostList =
    creatorCampaignData?.videos
      .filter((video) => video.parentId != null)
      .map((video) => ({ ...video })) || [];

  // For admin user options (if fetching admin users is not implemented yet)
  const adminUserOptions = [];

  // ----------------------------
  // Render
  // ----------------------------
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <AlertDialog alertState={dialogState}></AlertDialog>

      {creatorData && campaignData && (
        <>
          <Paper
            sx={{ position: "sticky", top: 0, zIndex: 2, padding: 2 }}
            elevation={4}
            square
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  src={creatorData.creator.pfphref}
                  alt={creatorData.creator.creator}
                />
                <Typography variant="h6" sx={{ marginLeft: 2 }}>
                  Campaign with @{creatorData.creator.creator} - {campaignData.name}
                </Typography>
              </Box>

              {conversationId && (
                <Button variant="outlined" onClick={handleOpenAddParticipant}>
                  Add Participant
                </Button>
              )}
            </Box>

            <Typography variant="body1" sx={{ marginLeft: 2 }}>
              Campaign Description: {campaignData.description}
              <br />
              Creator Brief: {creatorCampaignData.brief}
            </Typography>
          </Paper>

          <ChatScreen
            conversationId={conversationId}
            creatorId={creatorData.creator.creator} // Pass the creator ID here

            sx={{ flex: 1, minHeight: "0px" }}
          >
            <Box display="flex" alignItems="center" gap={1} sx={{ mb: 2 }}>
              <Chip
                color="primary"
                label={
                  <>
                    Offer:{" "}
                    {creatorCampaignData.price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </>
                }
              />

              <Typography variant="subtitle2" textAlign="center">
                Promotions (x{videoList.length} video
                {videoList.length === 1 ? "" : "s"}):
              </Typography>
              {videoList.map((video) => (
                <Chip
                  key={`video-${video.id}`}
                  color="primary"
                  label={`${video.promotionPlatform} - ${video.promotionType}`}
                />
              ))}

              <Typography variant="subtitle2">Crossposts:</Typography>
              {crosspostList.map((video) => (
                <Chip
                  key={`cross-${video.id}`}
                  color="secondary"
                  label={`${video.promotionPlatform}`}
                />
              ))}
            </Box>
          </ChatScreen>
        </>
      )}

      <Dialog
        open={addParticipantOpen}
        onClose={handleCloseAddParticipant}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add Participant</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 3, minWidth: "350px" }}
        >
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Select existing user:
            </Typography>
            <Autocomplete
              options={adminUserOptions}
              value={selectedParticipant}
              onChange={(event, newValue) => setSelectedParticipant(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Search user" variant="outlined" />
              )}
              sx={{ width: "100%" }}
            />
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              onClick={handleConfirmAddParticipant}
              disabled={!selectedParticipant || shareConversationMutation.isLoading}
            >
              {shareConversationMutation.isLoading ? "Adding..." : "Add Selected User"}
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Or invite by email (comma-separated for multiple):
            </Typography>
            <TextField
              label="Email(s)"
              variant="outlined"
              fullWidth
              value={inviteEmails}
              onChange={(e) => setInviteEmails(e.target.value)}
              placeholder="john@example.com, jane@example.com"
            />
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              color="secondary"
              onClick={handleInviteByEmail}
              disabled={inviteByEmailMutation.isLoading}
            >
              {inviteByEmailMutation.isLoading ? "Inviting..." : "Invite by Email"}
            </Button>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseAddParticipant}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CampaignCreatorSpecific;
