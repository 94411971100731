// CreatorUser/CreatorPages/Bio/PreviewHelpers.js
import { styled } from '@mui/system';

/**
 * A styled container that uses the theme from the bio page.
 * If you don't want a separate file, you can inline this in your code.
 */
export const BioPageContainer = styled('div')(({ theme, customtheme }) => ({
  backgroundColor: customtheme?.backgroundColor || theme.palette.background.default,
  color: customtheme?.foregroundColor || theme.palette.text.primary,
  minHeight: '400px',
  width: '100%',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
