import React from 'react'
import { Slide } from '../ReportComponents'
import { styled, Box, Typography, Avatar } from '@mui/material'
import { YouTube } from '@mui/icons-material'

import TikTokIcon from '../../../Components/Icons/TikTok';
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import TwitchIcon from '../../../Components/Icons/Twitch';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CastIcon from '@mui/icons-material/Cast';
import LinkIcon from '@mui/icons-material/Link';
import { X } from '@mui/icons-material';

const socialIcons = {
    TikTok: <TikTokIcon fontSize='large' />,
    Instagram: <InstagramIcon fontSize='large' />,
    Youtube: <YouTubeIcon fontSize='large' />,
    X: <TwitterIcon fontSize='large' />,
    Twitch: <TwitchIcon fontSize='large'></TwitchIcon>,
    Podcasts: <PodcastsIcon fontSize='large'></PodcastsIcon>,
    Kick: <CastIcon fontSize='large'></CastIcon>,
};

// Define subtle border colors for each platform
const platformBorderColors = {
    Youtube: 'rgba(255, 0, 0, 0.3)',         // Subtle red for YouTube
    Instagram: 'rgba(59, 89, 152, 0.3)',       // Subtle blue for Instagram
    TikTok: 'rgba(255, 99, 132, 0.3)',         // Subtle light red for TikTok
    Podcasts: 'rgba(128, 0, 128, 0.3)',        // Subtle purple for Podcasts
    Twitch: 'rgba(100, 65, 165, 0.3)',         // Subtle violet for Twitch
    Kick: 'rgba(0, 191, 255, 0.3)',            // Subtle sky blue for Kick
    X: 'rgba(29, 161, 242, 0.3)',              // Subtle blue for X
};

// Styled components using MUI's styled API
const VideoHighlight = styled(Box)({
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    marginBottom: '15px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    transition: 'transform 0.3s ease',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        transform: 'scale(1.05)',
    },
})

const VideoAvatar = styled(Avatar)(({ theme }) => ({
    width: '80px',
    height: '80px',
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
}));

const VideoDetails = styled(Box)({
    textAlign: 'left',
    flexGrow: 1,
})

const VideoTitle = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '5px',
})

const VideoMetrics = styled(Typography)({
    color: '#b3b3b3'
})

const CampaignMetrics = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    padding: '15px',
    marginTop: '20px',
});

const Metric = styled(Box)({
    textAlign: 'center',
});

const MetricValue = styled(Typography)({
    fontSize: '20px',
    fontWeight: '700',
    background: 'linear-gradient(45deg, #1DB954, #00ff80)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const MetricLabel = styled(Typography)({
    fontSize: '12px',
    color: '#b3b3b3',
    marginTop: '5px',
});

const kNumberOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    style: 'decimal',
    notation: 'compact',
    compactDisplay: 'short',
}

function VideoRow({ video }) {

    return (
        <VideoHighlight component={'a'} href={video.liveLink} target='_blank' rel='noopener noreferrer'>
            <VideoAvatar
                variant='rounded'
                sx={{
                    color: '#FFF',
                    backgroundColor: platformBorderColors[video.promotionPlatform] || '#222'
                }}
            >
                {socialIcons[video.promotionPlatform] || <LinkIcon fontSize='large' />}
            </VideoAvatar>
            <VideoDetails>
                <VideoTitle component='div' variant='h4'>{video.title || `${video.promotionPlatform} - ${video.promotionType}`}</VideoTitle>
                <VideoMetrics component='div' variant='subtitle2'>{video.totalViews.toLocaleString(
                    undefined, kNumberOptions
                )} Views • {video.likes.toLocaleString(
                    undefined, kNumberOptions)} Likes</VideoMetrics>
                <VideoMetrics component='div' variant='subtitle2'>by {video.creator}</VideoMetrics>
            </VideoDetails>
        </VideoHighlight>
    )
}


export default function VideosSlide({ index, currentSlide, report }) {
    return (
        <Slide
            id='slide-videos'
            index={index}
            currentIndex={currentSlide}
            sx={theme => ({
                backgroundImage: 'linear-gradient(135deg, #1e3264, #5073b8)',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                padding: theme.spacing(4),
                paddingBlockEnd: theme.spacing(8),
            })}
        >
            <Typography component={'div'} variant='h5'>Video Highlights</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                {report?.report.video_data.map((video, index) => (
                    <VideoRow key={index} video={video} />
                ))}
            </Box>
            <CampaignMetrics>
                <Metric>
                    <MetricLabel component='div' variant='subtitle2'>{report?.report.total_video_views.toLocaleString(
                        undefined, kNumberOptions
                    )}</MetricLabel>
                    <MetricValue component='div'>Total Views</MetricValue>
                </Metric>
                <Metric>
                    <MetricLabel component='div' variant='subtitle2'>{report?.report.total_video_likes.toLocaleString(
                        undefined, kNumberOptions
                    )}</MetricLabel>
                    <MetricValue component='div'>Total Likes</MetricValue>
                </Metric>
                <Metric>
                    <MetricLabel component='div' variant='subtitle2'>{report?.report.total_video_comments.toLocaleString(
                        undefined, kNumberOptions
                    )}</MetricLabel>
                    <MetricValue component='div'>Comments</MetricValue>
                </Metric>
            </CampaignMetrics>
            <Typography component={'div'} variant='subtitle2' color='text.secondary' >{report?.name}</Typography>
        </Slide>
    )
}
