import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Grid,
  Typography,
  Paper,
  Tabs,
  Tab,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMutation, useQuery } from "react-query";
import client from "../../../../API";
import { StyledTableRow, StyledTableCell } from "../../../../Utils/styledcell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useAlertDialog from "../../../../Components/useAlertDialog";
import AlertDialog from "../../../../Components/AlertDialog";
import CompanyDialog from "../info";
import {
  StyleButtonContainter,
  StyleContentWithNavBar,
} from "../../../../Utils/ThemedStyles";

const CompanyDetailsView = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [isPayCreatorDialogOpen, setPayCreatorDialogOpen] = useState(false);
  const [viewMode, setViewMode] = useState("grid"); // 'grid' or 'list'
  const [searchTerm, setSearchTerm] = useState("");

  // NEW: Sorting & Filtering controls
  const [creditSort, setCreditSort] = useState(""); // "asc", "desc", or ""
  const [vendorFilter, setVendorFilter] = useState("all"); // "all", "true", "false"

  const { dialogState, openDialog } = useAlertDialog();

  // ===========================
  // FETCH COMPANIES
  // ===========================
  const {
    data: companies,
    refetch: fetchCompanies,
    error: companiesError,
  } = useQuery({
    queryKey: ["companies"],
    queryFn: client.companies.list,
    refetchOnWindowFocus: false,
  });

  // ===========================
  // FETCH INVOICES
  // ===========================
  const {
    data: invoices,
    error: invoicesError,
    refetch: fetchInvoices,
  } = useQuery({
    queryKey: ["invoices"],
    queryFn: client.invoices.listAdmin,
    refetchOnWindowFocus: false,
  });

  // ===========================
  // FETCH SYSTEM LIQUIDITY
  // ===========================
  const {
    data: liquidityData,
    refetch: fetchLiquidity,
  } = useQuery({
    queryKey: ["systemLiquidity"],
    queryFn: client.creditLedger.systemLiquidity.get,
    refetchOnWindowFocus: false,
  });

  // ===========================
  // MUTATION: UPDATE SYSTEM LIQUIDITY
  // ===========================
  const { mutate: updateSystemLiquidity } = useMutation(
    (newLiquidityValue) =>
      client.creditLedger.systemLiquidity.update({ total_liquidity: newLiquidityValue }),
    {
      onSuccess: () => {
        fetchLiquidity();
        setLiquidityDialogOpen(false);
        setNewLiquidity("");
      },
      onError: (error) => {
        openDialog("Error", error?.response?.data?.error || error.message);
      },
    }
  );

  // Local state for Liquidity Dialog
  const [liquidityDialogOpen, setLiquidityDialogOpen] = useState(false);
  const [newLiquidity, setNewLiquidity] = useState("");

  // Debug logging
  useEffect(() => {
    if (invoices) {
      console.log("Fetched Invoices:", invoices);
    }
  }, [invoices]);

  // Handle errors
  useEffect(() => {
    if (companiesError) {
      const msg = companiesError?.response?.data?.error || companiesError.message;
      openDialog("Error", msg);
    }
    if (invoicesError) {
      console.error("Error fetching invoices:", invoicesError);
    }
  }, [companiesError, invoicesError, openDialog]);

  // Clear selection whenever companies changes
  useEffect(() => {
    if (companies) {
      setSelectedIds([]);
    }
  }, [companies]);

  // Delete mutation
  const { mutate: deleteCompanies } = useMutation(client.companies.delete, {
    onSuccess: async () => {
      fetchCompanies();
    },
    onError: (error) => {
      console.error("Error deleting companies:", error);
    },
  });

  // Event handlers
  const onDeleteCompanies = () => {
    if (!selectedIds.length) return;
    deleteCompanies({ companyIds: selectedIds });
  };

  const onCompany = () => {
    setPayCreatorDialogOpen(true);
  };

  const handleDialogClose = (bRefresh) => {
    setPayCreatorDialogOpen(false);
    bRefresh && fetchCompanies();
  };

  const handleSelectItem = (companyId) => {
    if (selectedIds.includes(companyId)) {
      setSelectedIds(selectedIds.filter((item) => item !== companyId));
    } else {
      setSelectedIds([...selectedIds, companyId]);
    }
  };

  const handleShareLink = (companyName) => {
    const url = `https://www.useblitz.co/register/${companyName}`;
    navigator.clipboard.writeText(url).then(() => {
      window.open(url, "_blank");
    });
  };

  const formatAmount = (amount) => {
    if (amount === null || amount === undefined) return "N/A";
    return `$${parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  // ===========================
  // COMPUTE SUMMARY STATS
  // ===========================
  const totalCompanies = companies ? companies.length : 0;
  const hardCodedCreditAvailable = 92000;

  // Exclude TCC from totalCreditAllocated
  const totalCreditAllocated = companies
    ? companies
        .filter((company) => company.name !== "TCC")
        .reduce((acc, c) => acc + parseFloat(c.credit_line || 0), 0)
    : 0;

  // Invoices
  const pendingInvoicesCount = invoices
    ? invoices.filter((inv) => inv.status === "pending").length
    : 0;
  const pendingInvoicesSum = invoices
    ? invoices
        .filter((inv) => inv.status === "pending")
        .reduce((acc, inv) => acc + parseFloat(inv.amount_due || 0), 0)
    : 0;

  // Total Liquidity from fetched data (default to 0 until loaded)
  const totalLiquidity = liquidityData ? parseFloat(liquidityData.total_liquidity) : 0;
  const overleveraged = totalLiquidity - totalCreditAllocated;
  const overleveragedIsNegative = overleveraged < 0;

  // ===========================
  // FILTER & SORT COMPANIES
  // ===========================
  let displayedCompanies = companies || [];

  // 1) Text search
  if (searchTerm.trim()) {
    displayedCompanies = displayedCompanies.filter((cmp) =>
      cmp.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  // 2) Vendor system filter
  if (vendorFilter === "true") {
    displayedCompanies = displayedCompanies.filter(
      (cmp) => cmp.vendor_system === true
    );
  } else if (vendorFilter === "false") {
    displayedCompanies = displayedCompanies.filter(
      (cmp) => cmp.vendor_system === false
    );
  }

  // 3) Sort by credit line (desc or asc)
  if (creditSort === "desc") {
    displayedCompanies.sort(
      (a, b) => parseFloat(b.credit_line || 0) - parseFloat(a.credit_line || 0)
    );
  } else if (creditSort === "asc") {
    displayedCompanies.sort(
      (a, b) => parseFloat(a.credit_line || 0) - parseFloat(b.credit_line || 0)
    );
  }

  const handleOpenDialog = (company) => {
    setSelectedCompany(company);
    setDialogOpen(true);
  };

  const handleCloseDialog = (refresh = false) => {
    setDialogOpen(false);
    setSelectedCompany(null);
    refresh && fetchCompanies();
  };

  const handleLiquidityUpdate = () => {
    const parsedLiquidity = parseFloat(newLiquidity);
    if (isNaN(parsedLiquidity)) {
      openDialog("Error", "Please enter a valid number for liquidity.");
      return;
    }
    updateSystemLiquidity(parsedLiquidity);
  };

  // Local state for company dialog (if needed)
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box sx={StyleContentWithNavBar}>
      <AlertDialog alertState={dialogState} />

      {/* ACTION CARDS */}
      <Box sx={{ display: "flex", gap: 2, mb: 2, flexWrap: "wrap" }}>
        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography variant="h6">Total Companies</Typography>
            <Typography variant="h5">{totalCompanies}</Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography variant="h6">Credit Available to Distribute</Typography>
            <Typography variant="h5">
              {formatAmount(hardCodedCreditAvailable)}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography variant="h6">Total Credit Allocated</Typography>
            <Typography variant="h5">
              {formatAmount(totalCreditAllocated)}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography variant="h6">Pending Invoices</Typography>
            <Typography variant="h5">{pendingInvoicesCount}</Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography variant="h6">Accounts Receivable</Typography>
            <Typography variant="h5">{formatAmount(pendingInvoicesSum)}</Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography variant="h6">Total Liquidity</Typography>
            <Typography variant="h5">
              {liquidityData ? formatAmount(totalLiquidity) : "Loading..."}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setLiquidityDialogOpen(true)}
              sx={{ mt: 1 }}
            >
              Update Liquidity
            </Button>
          </CardContent>
        </Card>

        <Card
          sx={{
            minWidth: 200,
            border: overleveragedIsNegative ? "2px solid red" : "2px solid green",
          }}
        >
          <CardContent>
            <Typography variant="h6">Total Overleveraged</Typography>
            <Typography variant="h5">{formatAmount(overleveraged)}</Typography>
          </CardContent>
        </Card>
      </Box>

      {/* BUTTONS + TAB FOR GRID/LIST */}
      <Box sx={StyleButtonContainter}>
        {/* Add / Edit / Delete Company */}
        <Button variant="contained" color="secondary" onClick={onCompany}>
          Add Company
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedIds.length !== 1}
          onClick={onCompany}
        >
          Edit Company
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedIds.length}
          onClick={onDeleteCompanies}
        >
          Delete Company(s)
        </Button>

        {/* SEARCH BAR */}
        <TextField
          variant="outlined"
          size="small"
          label="Search by Company Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ ml: 2 }}
        />

        {/* NEW: Filter by Vendor System */}
        <FormControl sx={{ ml: 2, minWidth: 150 }}>
          <InputLabel>Vendor System</InputLabel>
          <Select
            label="Vendor System"
            value={vendorFilter}
            onChange={(e) => setVendorFilter(e.target.value)}
            size="small"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="true">Vendor System = True</MenuItem>
            <MenuItem value="false">Vendor System = False</MenuItem>
          </Select>
        </FormControl>

        {/* NEW: Sort by Credit Line */}
        <FormControl sx={{ ml: 2, minWidth: 150 }}>
          <InputLabel>Sort Credit</InputLabel>
          <Select
            label="Sort Credit"
            value={creditSort}
            onChange={(e) => setCreditSort(e.target.value)}
            size="small"
          >
            <MenuItem value="">No Sort</MenuItem>
            <MenuItem value="desc">High to Low</MenuItem>
            <MenuItem value="asc">Low to High</MenuItem>
          </Select>
        </FormControl>

        {/* Tabs for switching Grid/List */}
        <Tabs
          value={viewMode}
          onChange={(e, newValue) => setViewMode(newValue)}
          textColor="primary"
          indicatorColor="primary"
          sx={{ ml: 2 }}
        >
          <Tab label="Grid" icon={<GridViewIcon />} value="grid" />
          <Tab label="List" icon={<ListIcon />} value="list" />
        </Tabs>
      </Box>

      {/* CONDITIONAL RENDERING: GRID or LIST */}
      {viewMode === "grid" ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {displayedCompanies.map((company) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
              <Card
                sx={{
                  cursor: "pointer",
                  "&:hover": { boxShadow: 6 },
                  border: selectedIds.includes(company.id) && "2px solid #1976d2",
                }}
                onClick={() => handleOpenDialog(company)}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      src={company.phphref || ""}
                      alt={company.name}
                      sx={{ bgcolor: "#1976d2" }}
                    >
                      {company.name?.[0] || "C"}
                    </Avatar>
                  }
                  title={company.name}
                  subheader={`Status: ${company.account_status}`}
                  action={
                    <Checkbox
                      color="primary"
                      checked={selectedIds.includes(company.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectItem(company.id);
                      }}
                    />
                  }
                />
                <CardContent>
                  <Typography variant="body2">
                    <strong>Balance:</strong> {formatAmount(company.balance)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Credit Line:</strong> {formatAmount(company.credit_line)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Seats:</strong>{" "}
                    {company.seats ? company.seats.join(", ") : "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Nominal Interest:</strong>{" "}
                    {company.nominal_interest_rate}%
                  </Typography>
                  <Typography variant="body2">
                    <strong>Vendor System?:</strong>{" "}
                    {company.vendor_system ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Vendor System Name:</strong>{" "}
                    {company.vendor_system_name || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Payment Term:</strong>{" "}
                    {company.vendor_payment_term
                      ? `${company.vendor_payment_term} days`
                      : "N/A"}
                  </Typography>
                  <Tooltip title="Share Onboard Link">
                    <IconButton
                      color="primary"
                      onClick={() => handleShareLink(company.name)}
                      size="small"
                    >
                      <ContentCopyIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 700 }} aria-label="company-list">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">ID</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Balance</StyledTableCell>
                <StyledTableCell align="center">Credit Line</StyledTableCell>
                <StyledTableCell align="center">Seats</StyledTableCell>
                <StyledTableCell align="center">Vendor System?</StyledTableCell>
                <StyledTableCell align="center">
                  Vendor System Name
                </StyledTableCell>
                <StyledTableCell align="center">Payment Term</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                <StyledTableCell align="center">
                  Nominal Interest Rate
                </StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedCompanies.map((company) => (
                <StyledTableRow
                  key={company.id}
                  sx={{
                    backgroundColor: selectedIds.includes(company.id)
                      ? "rgba(25, 118, 210, 0.1)"
                      : "inherit",
                  }}
                >
                  <StyledTableCell align="center" style={{ width: 50 }}>
                    <Checkbox
                      color="primary"
                      checked={selectedIds.includes(company.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectItem(company.id);
                      }}
                      inputProps={{
                        "aria-labelledby": `enhanced-table-checkbox_${company.id}`,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">{company.id}</StyledTableCell>
                  <StyledTableCell align="center">{company.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    {formatAmount(company.balance)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {formatAmount(company.credit_line)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {company.seats ? company.seats.join(", ") : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {company.vendor_system ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {company.vendor_system_name || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {company.vendor_payment_term
                      ? `${company.vendor_payment_term} days`
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {company.account_status}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(company.account_created).toLocaleDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(company.account_updated).toLocaleDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {company.nominal_interest_rate}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="Share Onboard Link">
                      <IconButton
                        color="primary"
                        onClick={() => handleShareLink(company.name)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* COMPANY DIALOG */}
      <CompanyDialog
        open={isPayCreatorDialogOpen}
        info={
          selectedIds.length > 0
            ? companies?.find((item) => item.id === selectedIds[0])
            : undefined
        }
        onClose={handleDialogClose}
      />

      {/* LIQUIDITY UPDATE DIALOG */}
      <Dialog open={liquidityDialogOpen} onClose={() => setLiquidityDialogOpen(false)}>
        <DialogTitle>Update System Liquidity</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Total Liquidity"
            type="number"
            fullWidth
            variant="outlined"
            value={newLiquidity}
            onChange={(e) => setNewLiquidity(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLiquidityDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleLiquidityUpdate} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CompanyDetailsView;
