import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import client from "../../../API"; // Adjust to your API client location
import useAlertDialog from "../../../Components/useAlertDialog"; // Adjust if you have a custom alert dialog
import AlertDialog from "../../../Components/AlertDialog";

// Helper to parse seat strings like "1,2,3" -> ["1","2","3"]
function parseSeatsString(seatsStr) {
  if (!seatsStr.trim()) return [];
  return seatsStr
    .split(",")
    .map((s) => s.trim())
    .filter((s) => s.length > 0);
}

// Helper to format numbers if needed
function formatNumber(n) {
  if (n === null || n === undefined) return "";
  return n.toString();
}

export default function CompanyDialog({ open, onClose, info }) {
  const { dialogState, openDialog } = useAlertDialog();

  // ---------------------------
  // Tab handling
  // ---------------------------
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // ---------------------------
  // State for the Edit tab
  // ---------------------------
  const [companyName, setCompanyName] = useState("");
  const [balance, setBalance] = useState("");
  const [accountStatus, setAccountStatus] = useState("Free");
  const [seats, setSeats] = useState("");
  const [phphref, setPhphref] = useState("");
  const [nominalInterestRate, setNominalInterestRate] = useState("");
  const [vendorSystem, setVendorSystem] = useState(false);
  const [vendorSystemName, setVendorSystemName] = useState("");
  const [vendorPaymentTerm, setVendorPaymentTerm] = useState("30");
  const [blitzVendorId, setBlitzVendorId] = useState("");

  // On open or when `info` changes, pre-fill fields if editing an existing company
  useEffect(() => {
    if (open && info) {
      // Editing an existing company
      setCompanyName(info.name || "");
      setBalance(info.balance != null ? formatNumber(info.balance) : "");
      setAccountStatus(info.account_status || "Free");
      setSeats(
        info.seats && Array.isArray(info.seats) ? info.seats.join(",") : ""
      );
      setPhphref(info.phphref || "");
      setNominalInterestRate(
        info.nominal_interest_rate != null
          ? formatNumber(info.nominal_interest_rate)
          : "10"
      );
      setVendorSystem(!!info.vendor_system);
      setVendorSystemName(info.vendor_system_name || "");
      setVendorPaymentTerm(
        info.vendor_payment_term != null
          ? String(info.vendor_payment_term)
          : "30"
      );
      setBlitzVendorId(info.blitz_vendor_id || "");
    } else if (open && !info) {
      // Creating a new company
      setCompanyName("");
      setBalance("");
      setAccountStatus("Free");
      setSeats("");
      setPhphref("");
      setNominalInterestRate("10");
      setVendorSystem(false);
      setVendorSystemName("");
      setVendorPaymentTerm("30");
      setBlitzVendorId("");
    }
  }, [open, info]);

  // ---------------------------
  // Mutations for create/edit
  // ---------------------------
  const { mutate: createCompany, isLoading: createLoading } = useMutation(
    client.companies.create,
    {
      onSuccess: () => {
        onClose(true); // Close dialog and refresh
      },
      onError: (error) => {
        const msg = error?.response?.data?.error || error.message;
        openDialog("Error", msg);
      },
    }
  );

  const { mutate: editCompany, isLoading: editLoading } = useMutation(
    client.companies.edit,
    {
      onSuccess: () => {
        onClose(true); // Close dialog and refresh
      },
      onError: (error) => {
        const msg = error?.response?.data?.error || error.message;
        openDialog("Error", msg);
      },
    }
  );

  const handleSubmitEdit = () => {
    if (!companyName.trim()) {
      openDialog("Error", "Company name is required.");
      return;
    }
    const data = {
      company_name: companyName.trim(),
      balance: parseFloat(balance) || 0,
      account_status: accountStatus,
      seats: parseSeatsString(seats),
      phphref,
      nominal_interest_rate: parseFloat(nominalInterestRate) || 0,
      vendor_system: vendorSystem,
      vendor_system_name: vendorSystemName.trim(),
      vendor_payment_term: parseInt(vendorPaymentTerm, 10) || 30,
      blitz_vendor_id: blitzVendorId.trim() || null,
    };
    if (info && info.id) {
      // Edit existing
      editCompany({ companyId: info.id, params: data });
    } else {
      // Create new
      createCompany(data);
    }
  };

  // ---------------------------
  // Invoices tab
  // ---------------------------
  const [invoiceStatusFilter, setInvoiceStatusFilter] = useState("");
  const {
    data: invoiceData = [],
    isLoading: invoicesLoading,
    refetch: refetchInvoices,
  } = useQuery(
    ["companyInvoices", info?.id, invoiceStatusFilter],
    () => client.companies.invoice(info.id, invoiceStatusFilter),
    {
      enabled: tabIndex === 1 && !!info?.id, // only fetch if tab == 1 and we have a real company ID
    }
  );

  const handleInvoiceStatusChange = (e) => {
    setInvoiceStatusFilter(e.target.value);
    refetchInvoices();
  };

  // ---------------------------
  // Credit History tab
  // ---------------------------
  const {
    data: creditLedgerData = [],
    isLoading: ledgerLoading,
    refetch: refetchLedger,
  } = useQuery(
    ["companyCreditLedger", info?.id],
    // IMPORTANT: do NOT do .then(res => res.data) if your client already returns the array
    () => client.creditLedger.fetch(info.id),
    {
      enabled: tabIndex === 2 && !!info?.id,
    }
  );

  // Create a new ledger entry from the admin side
  const [operationType, setOperationType] = useState("init");
  const [requestedCreditLine, setRequestedCreditLine] = useState("");
  const [reason, setReason] = useState("");

  const { mutate: createCreditOperation, isLoading: creatingCreditOp } =
    useMutation(
      (payload) => client.creditLedger.postRequest(payload),
      {
        onSuccess: () => {
          refetchLedger();
          openDialog("Success", "Operation created successfully.");
          setOperationType("init");
          setRequestedCreditLine("");
          setReason("");
        },
        onError: (error) => {
          const msg = error?.response?.data?.error || error.message;
          openDialog("Error", msg);
        },
      }
    );

  const handleCreateOperation = () => {
    if (!info || !info.id) {
      openDialog("Error", "No company selected. Cannot create ledger entry.");
      return;
    }
    const newLine = parseFloat(requestedCreditLine);
    if (Number.isNaN(newLine)) {
      openDialog("Error", "Please enter a valid number for credit line.");
      return;
    }
    createCreditOperation({
      company_id: info.id,
      operation_type: operationType,
      requested_credit_line: newLine,
      requested_interest_rate: 0, // or provide a separate field if needed
      reason: reason.trim() || "Manual ledger operation by admin",
    });
  };

  // Approve / Deny requests
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [overrideCreditLine, setOverrideCreditLine] = useState("");
  const [overrideInterestRate, setOverrideInterestRate] = useState("");
  const [adminReason, setAdminReason] = useState("");

  // Must be POST per your Flask route
  const { mutate: approveOrDeny, isLoading: isApproving } = useMutation(
    (payload) => client.creditLedger.approve(payload),
    {
      onSuccess: () => {
        refetchLedger();
        openDialog("Success", "Request processed successfully.");
      },
      onError: (error) => {
        const msg = error?.response?.data?.error || error.message;
        openDialog("Error", msg);
      },
    }
  );

  const handleOpenApproveDialog = (req) => {
    setSelectedRequest(req);
    setOverrideCreditLine(req.new_credit_line); // pre-fill from request
    setOverrideInterestRate(req.new_interest_rate); // pre-fill from request
    setAdminReason("");
    setApproveDialogOpen(true);
  };

  const handleCloseApproveDialog = () => {
    setApproveDialogOpen(false);
    setSelectedRequest(null);
  };

  const handleApprove = () => {
    if (!selectedRequest) return;
    approveOrDeny({
      request_id: selectedRequest.id,
      approve: true,
      new_credit_line: parseFloat(overrideCreditLine),
      new_interest_rate: parseFloat(overrideInterestRate),
      reason: adminReason || "Approved by admin",
    });
    handleCloseApproveDialog();
  };

  const handleDeny = (req) => {
    approveOrDeny({
      request_id: req.id,
      approve: false,
      reason: "Denied by admin",
    });
  };

  // ---------------------------
  // RENDER
  // ---------------------------
  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="xl" fullWidth>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <DialogTitle>
        {info && info.id ? "Edit Company" : "Create Company"}
      </DialogTitle>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Edit" />
        <Tab label="Invoices" />
        <Tab label="Credit History" />
      </Tabs>

      {/* TAB 0: EDIT COMPANY */}
      {tabIndex === 0 && (
        <DialogContent dividers>
          <TextField
            label="Company Name"
            fullWidth
            margin="normal"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextField
            label="Balance"
            type="number"
            fullWidth
            margin="normal"
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Account Status</InputLabel>
            <Select
              label="Account Status"
              value={accountStatus}
              onChange={(e) => setAccountStatus(e.target.value)}
            >
              <MenuItem value="Free">Free</MenuItem>
              <MenuItem value="Agency">Agency</MenuItem>
              <MenuItem value="AlphaFree">AlphaFree</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Seats (comma-separated)"
            fullWidth
            margin="normal"
            value={seats}
            onChange={(e) => setSeats(e.target.value)}
          />
          <TextField
            label="PHP href"
            fullWidth
            margin="normal"
            value={phphref}
            onChange={(e) => setPhphref(e.target.value)}
          />
          <TextField
            label="Nominal Interest Rate (%)"
            type="number"
            fullWidth
            margin="normal"
            value={nominalInterestRate}
            onChange={(e) => setNominalInterestRate(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={vendorSystem}
                onChange={(e) => setVendorSystem(e.target.checked)}
              />
            }
            label="Vendor System?"
          />
          {vendorSystem && (
            <TextField
              label="Vendor System Name"
              fullWidth
              margin="normal"
              value={vendorSystemName}
              onChange={(e) => setVendorSystemName(e.target.value)}
            />
          )}
          <TextField
            label="Vendor Payment Term (days)"
            type="number"
            fullWidth
            margin="normal"
            value={vendorPaymentTerm}
            onChange={(e) => setVendorPaymentTerm(e.target.value)}
          />
          <TextField
            label="Blitz Vendor ID"
            fullWidth
            margin="normal"
            value={blitzVendorId}
            onChange={(e) => setBlitzVendorId(e.target.value)}
          />
        </DialogContent>
      )}

      {/* TAB 1: INVOICES */}
      {tabIndex === 1 && (
        <DialogContent dividers>
          {info && info.id ? (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography sx={{ mr: 2 }}>Filter by Status:</Typography>
                <Select
                  size="small"
                  value={invoiceStatusFilter}
                  onChange={handleInvoiceStatusChange}
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
                {invoicesLoading && (
                  <CircularProgress size={24} sx={{ ml: 2 }} />
                )}
              </Box>
              {invoiceData.length === 0 && !invoicesLoading && (
                <Typography>No invoices found.</Typography>
              )}
              {invoiceData.map((inv) => (
                <Box
                  key={inv.id}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: 2,
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Typography>
                    <strong>Invoice ID:</strong> {inv.id}
                  </Typography>
                  <Typography>
                    <strong>PO #:</strong> {inv.po_number}
                  </Typography>
                  <Typography>
                    <strong>Status:</strong> {inv.status}
                  </Typography>
                  <Typography>
                    <strong>Amount Due:</strong> {inv.amount_due}
                  </Typography>
                  <Typography>
                    <strong>Days Late:</strong> {inv.days_late}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>
              You need to create/save the company first to view invoices.
            </Typography>
          )}
        </DialogContent>
      )}

      {/* TAB 2: CREDIT HISTORY */}
      {tabIndex === 2 && (
        <DialogContent dividers>
          {info && info.id ? (
            <Box>
              {ledgerLoading && <CircularProgress size={24} />}
              {!ledgerLoading && creditLedgerData.length === 0 && (
                <Typography>No credit ledger entries found.</Typography>
              )}
              {creditLedgerData.map((entry) => (
                <Box
                  key={entry.id}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: 2,
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Typography>
                    <strong>Operation:</strong> {entry.operation_type}
                  </Typography>
                  <Typography>
                    <strong>Change Amount:</strong> {entry.change_amount}
                  </Typography>
                  <Typography>
                    <strong>New Credit Line:</strong> {entry.new_credit_line}
                  </Typography>
                  <Typography>
                    <strong>Interest Rate:</strong> {entry.new_interest_rate}
                  </Typography>
                  <Typography>
                    <strong>Reason:</strong> {entry.reason}
                  </Typography>
                  <Typography>
                    <strong>Created At:</strong>{" "}
                    {new Date(entry.created_at).toLocaleString()}
                  </Typography>
                  {/* If this ledger entry is a 'request', show Approve/Deny buttons */}
                  {entry.operation_type === "request" && (
                    <Box sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ mr: 2 }}
                        disabled={isApproving}
                        onClick={() => handleOpenApproveDialog(entry)}
                      >
                        Approve/Override
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        disabled={isApproving}
                        onClick={() => handleDeny(entry)}
                      >
                        Deny
                      </Button>
                    </Box>
                  )}
                </Box>
              ))}

              {/* Create new credit operation (init, override, etc) */}
              <Box sx={{ border: "1px dashed #aaa", p: 2, mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Create New Credit Operation
                </Typography>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Operation Type</InputLabel>
                  <Select
                    value={operationType}
                    label="Operation Type"
                    onChange={(e) => setOperationType(e.target.value)}
                  >
                    <MenuItem value="init">init</MenuItem>
                    <MenuItem value="increase">increase</MenuItem>
                    <MenuItem value="decrease">decrease</MenuItem>
                    <MenuItem value="override">override</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Requested Credit Line"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={requestedCreditLine}
                  onChange={(e) => setRequestedCreditLine(e.target.value)}
                />
                <TextField
                  label="Reason (optional)"
                  multiline
                  fullWidth
                  margin="normal"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={handleCreateOperation}
                  disabled={creatingCreditOp}
                >
                  {creatingCreditOp ? (
                    <CircularProgress size={22} />
                  ) : (
                    "Submit Operation"
                  )}
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography>
              You need to create/save the company first to view credit history.
            </Typography>
          )}
        </DialogContent>
      )}

      {/* FOOTER ACTIONS */}
      <DialogActions>
        <Button onClick={() => onClose(false)}>Close</Button>
        {tabIndex === 0 && (
          <Button
            onClick={handleSubmitEdit}
            variant="contained"
            disabled={createLoading || editLoading}
          >
            {createLoading || editLoading ? (
              <CircularProgress size={22} />
            ) : (
              "Submit"
            )}
          </Button>
        )}
      </DialogActions>

      {/* Approve/Override Dialog */}
      <Dialog open={approveDialogOpen} onClose={handleCloseApproveDialog}>
        <DialogTitle>Approve or Override Request</DialogTitle>
        <DialogContent>
          <TextField
            label="New Credit Line"
            type="number"
            value={overrideCreditLine}
            onChange={(e) => setOverrideCreditLine(e.target.value)}
            fullWidth
            sx={{ my: 2 }}
          />
          <TextField
            label="New Interest Rate"
            type="number"
            value={overrideInterestRate}
            onChange={(e) => setOverrideInterestRate(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Admin Reason"
            value={adminReason}
            onChange={(e) => setAdminReason(e.target.value)}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApproveDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleApprove} disabled={isApproving}>
            {isApproving ? <CircularProgress size={20} /> : "Approve"}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}
