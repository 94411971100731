import React, { useState, useMemo } from 'react';
import {
    Backdrop, Box, Button, CircularProgress, CssBaseline, Divider, Grid,
    Paper, styled, ThemeProvider,
    Toolbar, Typography, Link, Fade
} from '@mui/material';
import { lightTheme } from '../../../Utils/baseTheme';
import HomeHeader from '../../../Components/HomeHeader';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../API';
import PageTitle from '../../../Components/PageTitle';
import ppfLogo from '../../../Components/globalAssets/ppfLogo.png';

const pageTheme = {
    ...lightTheme,
    typography: {
        fontFamily: "'Inter', sans-serif",
    },
};

const GradientButton = styled(Button)(({ theme }) => ({
    width: '5.5em',
    background: 'linear-gradient(45deg, #6366F1 0%, #8B5CF6 100%)',
    color: '#FFF',
    borderRadius: '12px',
    fontWeight: 600,
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 10px 20px rgba(99, 102, 241, 0.25)',
    },
}));

const BookButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, #EC4899 0%, #8B5CF6 100%)',
    color: '#FFF',
    borderRadius: '50px',
    fontWeight: 700,
    height: '56px',
    padding: '0 40px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '-100%',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
        transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 8px 24px rgba(139, 92, 246, 0.4)',
        '&:before': {
            left: '100%',
        },
    },
}));

function RateCard({ rate, label }) {
    return (
        <Box
            sx={{
                position: 'relative',
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '16px',
                padding: '24px',
                textAlign: 'center',
                width: '100%',
                height: '100%',
                transition: 'all 0.3s ease',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 8px 32px rgba(99, 102, 241, 0.2)',
                },
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(45deg, rgba(99, 102, 241, 0.1) 0%, rgba(139, 92, 246, 0.05) 100%)',
                    borderRadius: '16px',
                    zIndex: -1,
                }}
            />
            <Typography variant="h6" sx={{ fontWeight: 600, letterSpacing: '-0.5px', mb: 1 }}>
                {label}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700, color: '#6366F1' }}>
                ${rate.toLocaleString()}
            </Typography>
        </Box>
    );
}

export default function TempCreatorPage() {
    const { creatorId, discount } = useParams();
    const navigate = useNavigate();

    const { data: creatorDetails, error, isLoading } = useQuery({
        queryKey: ['creatorCrm', creatorId],
        queryFn: () => API.crm.creator_fetch(creatorId),
        enabled: !!creatorId,
    });

    const rates = useMemo(() => {
        if (!creatorDetails) return [];

        const data = creatorDetails;

        return [
            { platform: 'Instagram', rate: data.following * 0.01 },
            { platform: 'TikTok', rate: data.following * 0.008 },
            { platform: 'YouTube', rate: data.following * 0.012 },
        ];
    }, [creatorDetails]);

    if (isLoading) return 'Loading creator details';
    if (error || !creatorDetails) return `No creator details found: ${error?.response?.data?.error || error?.message || 'Unknown error'}`;

    const totalFollowers = creatorDetails.following;

    const handleOnboardClick = () => {
        if (!creatorDetails || !creatorDetails.username) return;
        // Add ?tab=creator&username=someUser to the query
        navigate(`/onboard?tab=creator&username=${encodeURIComponent(creatorDetails.username)}`);
      };

    return (
        <ThemeProvider theme={pageTheme}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <PageTitle title={`Temporary Mediakit for @${creatorId} - Blitz`} />
            <CssBaseline />
            <HomeHeader />
            <Toolbar />

            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    minHeight: { xs: 'auto', md: '70vh' },
                    background: 'linear-gradient(135deg, #6366F1 0%, #8B5CF6 100%)',
                    pb: { xs: 4, md: 0 },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: 0,
                        background: 'url(https://www.transparenttextures.com/patterns/inspiration-geometry.png)',
                        opacity: 0.05,
                    },
                }}
            >
                <Box
                    sx={{
                        maxWidth: 1280,
                        mx: 'auto',
                        px: { xs: 2, md: 4 },
                        pt: { xs: 4, md: 8 },
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Fade in timeout={500}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        borderRadius: '16px',
                                        overflow: 'hidden',
                                        aspectRatio: '1/1',
                                        bgcolor: 'background.paper',
                                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                                        '&:hover img': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                >
                                    <img
                                        src={ppfLogo}
                                        alt="Creator"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            transition: 'transform 0.3s ease',
                                        }}
                                    />
                                </Box>
                            </Fade>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box sx={{ color: 'white', maxWidth: 800 }}>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: { xs: '2.5rem', md: '3.5rem' },
                                        fontWeight: 800,
                                        lineHeight: 1.1,
                                        mb: 2,
                                        letterSpacing: '-1.5px',
                                    }}
                                >
                                    @{creatorDetails.username}
                                </Typography>
                                
                                <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
                                    <Box>
                                        <Typography variant="overline" sx={{ opacity: 0.8 }}>
                                            Total Followers
                                        </Typography>
                                        <Typography variant="h3" sx={{ fontWeight: 700 }}>
                                            {totalFollowers.toLocaleString()}
                                        </Typography>
                                    </Box>
                                    <Divider orientation="vertical" flexItem sx={{ borderColor: 'rgba(255,255,255,0.2)' }} />
                                    <Box>
                                        <Typography variant="overline" sx={{ opacity: 0.8 }}>
                                            Platform Link
                                        </Typography>
                                        <Typography variant="h6">
                                            <Link
                                                href={creatorDetails.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    color: 'inherit',
                                                    textDecoration: 'none',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                {new URL(creatorDetails.link).hostname}
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box
                component="section"
                sx={{
                    position: 'relative',
                    bgcolor: 'background.default',
                    pt: 8,
                    pb: 10,
                }}
            >
                <Box
                    sx={{
                        maxWidth: 1280,
                        mx: 'auto',
                        px: { xs: 2, md: 4 },
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            mb: 4,
                            fontWeight: 700,
                            letterSpacing: '-1px',
                            textAlign: 'center',
                        }}
                    >
                        Engagement Rates
                    </Typography>
                    
                    <Grid container spacing={4} justifyContent="center">
                        {rates.map((rate) => (
                            <Grid item xs={12} sm={6} lg={4} key={rate.platform}>
                                <RateCard label={rate.platform} rate={rate.rate} />
                            </Grid>
                        ))}
                    </Grid>

                    <Box
                        sx={{
                            textAlign: 'center',
                            mt: 8,
                            position: 'relative',
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                top: '-40px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '60%',
                                height: '1px',
                                background: 'linear-gradient(90deg, transparent, rgba(99, 102, 241, 0.4), transparent)',
                            },
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 3, color: 'text.secondary' }}>
                            Ready to collaborate?
                        </Typography>
                        <BookButton onClick={handleOnboardClick}>
                            Start Onboarding Now
                        </BookButton>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}