import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import {
  Alert,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  SpeedDial,
  SpeedDialAction,
  Fab,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Typography
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { jsPDF } from 'jspdf';          // If you need PDF generation
import html2canvas from 'html2canvas'; // If you need screenshot -> PDF

// Chat sub-components
import DeliverableCard from '../Pages/Dashboard/Campaigns/projectcomponents/smallercomps/deliverablecard';
import SendOfferDialog from '../Pages/Dashboard/Campaigns/projectcomponents/smallercomps/sendoffer';
import AttachmentBubble from './ChatComponents/AttachmentBubble';
import AlertDialog from './AlertDialog';
import ChatBubble from './ChatComponents/ChatBubble';
import ReadReceipt from './ChatComponents/ReadReceipt';
import ShippingAddressDialog from './ChatComponents/ShippingAddressDialog';
import SelectAssetDialog from './tools/selectfile';
import SelectCampaignDialog from './tools/selectcampaigndialog';
import SelectCreatorDialog from './tools/selectcreatordialog';
import CreatorUploadFileDialog from '../CreatorUser/CreatorPages/Dashboard/creatorUtils/uploadfile';
import OfferDialog from '../Pages/Dashboard/Campaigns/projectcomponents/smallercomps/offerdialog';
import PdfSigningDialog from './ChatComponents/pdfsigning';

// Hooks & Utilities
import API from '../API';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAuth from '../Hooks/use-auth';
import { useCreatorAuth } from '../Hooks/creator-use-auth';
import useAlertDialog from './useAlertDialog';
import useIsDesktop from '../Hooks/useIsDesktop';
import { getFileType, isMessageMine } from '../Utils/constants';
import CreatorInvoiceDialog from '../CreatorUser/CreatorPages/Dashboard/misc/creatorinvoicepopup';

const MAX_MESSAGES_WITHOUT_RESPONSE = 5;
const SCROLL_TIMEOUT = 300;
const SNACKBAR_DURATION = 6000;
const MAX_MESSAGE_ROWS = 3;

const getIcon = (attachmentType) => {
  switch (attachmentType) {
    case 'campaign':
      return <CampaignOutlinedIcon color='primary' />;
    case 'address':
      return <LocalShippingIcon color='primary' />;
    default:
      return undefined;
  }
};

// ----------------------------------------------
// MAIN ChatScreen
// ----------------------------------------------
export default function ChatScreen({
  children,
  conversationId,
  partnershipId,  // used for sending offers, etc.
  creatorId,
  onDeliverableClick = undefined,
  showWarning = true,
  specificReaderId = null,
  chatSpacing = 1,
  margin = 2,
  sx = {}
}) {
  // ----------------------------------------------
  // Hooks & Initial
  // ----------------------------------------------
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();
  const { creatorToken } = useCreatorAuth();
  const queryClient = useQueryClient();
  const isDesktop = useIsDesktop();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const listRef = useRef(null);
  const lastMessageId = useRef(null);

  // ----------------------------------------------
  // Local state
  // ----------------------------------------------
  const [message, setMessage] = useState('');
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [chatError, setChatError] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [showMessageCapAlert, setShowMessageCapAlert] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // Dialog toggles for attachments, invoice, etc.
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
  const [creatorDialogOpen, setCreatorDialogOpen] = useState(false);
  const [creatorUploadDialogOpen, setCreatorUploadDialogOpen] = useState(false);
  const [creatorShippingAddress, setCreatorShippingAddress] = useState(false);

  const [sendOfferOpen, setSendOfferOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [viewOfferOpen, setViewOfferOpen] = useState(false);

  // PDF signing
  const [pdfSigningDialogOpen, setPdfSigningDialogOpen] = useState(false);
  const [fileForSigning, setFileForSigning] = useState(null);

  // NEW: invoice creation
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);

  // ----------------------------------------------
  // Derived variables
  // ----------------------------------------------
  const userId =
    specificReaderId ||
    userInfo?.id ||
    creatorToken?.creator_user?.username ||
    null;
  const userCompany = userInfo?.company_name;
  const isTcc = userCompany === "TCC";
  const isCreator = !!specificReaderId || (!!creatorToken && !userInfo);

  // ----------------------------------------------
  // Data: Get conversation messages
  // ----------------------------------------------
  const {
    data: conversation,
    isLoading: messagesLoading,
    isFetching: messagesRefetching,
    refetch: messagesRefetch,
    error: messagesError
  } = useQuery(
    ["messages", conversationId],
    () => {
      return API.conversations.getMessages(
        conversationId,
        specificReaderId !== null ? { reader_id: specificReaderId } : undefined
      );
    },
    {
      enabled: !!conversationId,
      onError: (err) => {
        console.error('Messages fetch error:', err);
        setChatError('Failed to load messages');
      },
      onSuccess: () => {
        setChatError(null);
      }
    }
  );

  const messages = conversation?.messages || [];

  // ----------------------------------------------
  // Mutations: delete / send
  // ----------------------------------------------
  const { mutate: deleteMessage, isLoading: deleteMessageLoading } = useMutation(
    (msgId) => API.conversations.deleteMessage(msgId),
    {
      onSuccess: () => {
        messagesRefetch();
      },
      onError: (error) => {
        console.error('Delete message error:', error);
        openDialog(
          "Error",
          <>
            Error while deleting message: <br />
            {error?.response?.data?.error || error.message}
          </>
        );
      }
    }
  );

  const { mutate: sendMessage, isLoading: sendMessageLoading } = useMutation(
    ({ id, input }) => API.conversations.sendMessage(id, input),
    {
      onMutate: () => setIsSending(true),
      onSuccess: () => {
        queryClient.invalidateQueries(["messages", conversationId]);
        setMessage('');
        setSelectedAttachment(null);
        messagesRefetch();
      },
      onError: (error) => {
        console.error("Error sending message:", error);
        handleSendMessageError(error);
      },
      onSettled: () => setIsSending(false)
    }
  );

  // ----------------------------------------------
  // Effects & Helpers
  // ----------------------------------------------
  const userMessagesCount = useMemo(() => {
    if (!messages) return 0;
    const userMessages = messages.filter((m) => isMessageMine(userId, m));
    const otherMessages = messages.filter((m) => !isMessageMine(userId, m));
    if (otherMessages.length > 0) {
      const lastCreatorMsgTime = new Date(
        otherMessages[otherMessages.length - 1].created_at
      ).getTime();
      return userMessages.filter(
        (m) => new Date(m.created_at).getTime() > lastCreatorMsgTime
      ).length;
    }
    return userMessages.length;
  }, [messages, userId]);

  useEffect(() => {
    // Auto-scroll to bottom when new messages appear
    const timeoutId = setTimeout(() => {
      if (conversation && listRef.current && messages.length > 0) {
        const latestMessageId = messages[messages.length - 1].id;
        if (latestMessageId !== lastMessageId.current) {
          listRef.current.scrollTo({
            top: listRef.current.scrollHeight,
            behavior: "smooth"
          });
          lastMessageId.current = latestMessageId;
        }
      }
    }, SCROLL_TIMEOUT);
    return () => clearTimeout(timeoutId);
  }, [conversation, messages]);

  // Handler for deleting messages
  const onConfirmDeleteMessage = useCallback(
    (messageId) => {
      closeDialog();
      deleteMessage(messageId);
    },
    [closeDialog, deleteMessage]
  );

  const handleDeleteMessage = useCallback(
    (messageId) => {
      if (isTcc) {
        openDialog(
          "Warning",
          "Are you sure you want to delete this message?",
          () => onConfirmDeleteMessage(messageId),
          closeDialog,
          "Yes",
          "No"
        );
      }
    },
    [isTcc, openDialog, closeDialog, onConfirmDeleteMessage]
  );

  const handleSendMessageError = useCallback(
    (error) => {
      if (error?.response?.data?.error === "forbidden") {
        openDialog(
          "Error",
          "This message was flagged as containing prohibited content. Please refrain from sharing personal contact information.",
          closeDialog,
          closeDialog,
          "Ok",
          null
        );
        return;
      }
      openDialog(
        "Error",
        <>
          Error while sending message: <br />
          {error?.response?.data?.error || error.message}
        </>
      );
    },
    [openDialog, closeDialog]
  );

  const isMessageAllowed = useCallback((text) => {
    const prohibitedKeywords = [
      "text me",
      "contact me",
      "contact",
      "@gmail.com",
      "whatsapp",
      "convenient contact"
    ];
    const phoneRegex = /(?:\d[\s-]?){10,}/;
    const urlRegex = /https?:\/\/[^\s]+/g;
    const textNoUrls = text.replace(urlRegex, '');
    const lowerText = textNoUrls.toLowerCase();
    if (prohibitedKeywords.some(k => lowerText.includes(k))) {
      return false;
    }
    return !phoneRegex.test(textNoUrls);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (userMessagesCount >= MAX_MESSAGES_WITHOUT_RESPONSE && !isTcc) {
      setShowMessageCapAlert(true);
      return;
    }
    if (!isMessageAllowed(message)) {
      openDialog(
        "Error",
        "Your message contains prohibited content. Please refrain from sharing personal contact information."
      );
      return;
    }
    if ((!message || message.trim() === '') && !selectedAttachment) {
      openDialog("Error", "Can't send an empty message");
      return;
    }
    if (!conversationId) {
      openDialog(
        "Error",
        <>No valid conversation. Please contact support if this persists.</>
      );
      return;
    }
    const inputData = {
      message,
      ...(specificReaderId && { reader_id: specificReaderId }),
      ...(selectedAttachment && { attachment: selectedAttachment })
    };
    sendMessage({ id: conversationId, input: inputData });
  }, [
    message,
    selectedAttachment,
    conversationId,
    specificReaderId,
    userMessagesCount,
    isTcc,
    isMessageAllowed,
    openDialog,
    sendMessage
  ]);

  // ----------------------------------------------
  // Attachment handlers
  // ----------------------------------------------
  const handleFileSelect = useCallback((file) => {
    const isImage = getFileType(file.filename) === 'image';
    setSelectedAttachment({
      type: 'file',
      label: file.filename,
      data: file,
      preview: isImage ? file.file_url : undefined
    });
    setUploadDialogOpen(false);
    setMessage('');
  }, []);

  const handleCreatorSelect = useCallback((creator) => {
    setSelectedAttachment({
      type: 'creator',
      label: `${creator.creator} Profile`,
      preview: creator.pfphref,
      data: { creator: creator.creator }
    });
    setCreatorDialogOpen(false);
    setMessage('');
  }, []);

  const handleCampaignSelect = useCallback((campaign) => {
    setSelectedAttachment({
      type: 'campaign',
      label: campaign.name,
      data: { id: campaign.id }
    });
    setCampaignDialogOpen(false);
    setMessage('');
  }, []);

  const handleShippingSelect = useCallback((form) => {
    setCreatorShippingAddress(false);
    if (!form) return;
    setSelectedAttachment({
      type: 'address',
      label: form.address + '...',
      data: { data: form }
    });
    setMessage('');
  }, []);

  const handleCreatorFile = useCallback((file) => {
    const baseInput = specificReaderId ? { reader_id: specificReaderId } : {};
    if (file.status === 'uploaded_to_firebase') {
      sendMessage({
        id: conversationId,
        input: {
          ...baseInput,
          message: '',
          attachment: { type: 'file', data: { id: file.file_id } }
        }
      });
    } else {
      sendMessage({
        id: conversationId,
        input: {
          ...baseInput,
          message: `Large attachments folder: ${file.redirect_url}`
        }
      });
      openDialog(
        'Alert',
        "A Google Drive folder was created for this upload. Opening in a new window...",
        () => window.open(file.redirect_url, '_blank'),
        () => window.open(file.redirect_url, '_blank')
      );
    }
    setCreatorUploadDialogOpen(false);
    setMessage('');
  }, [conversationId, specificReaderId, openDialog, sendMessage]);

  const handleCreatorUploadError = useCallback((err) => {
    console.error('Creator upload error:', err);
    setCreatorUploadDialogOpen(false);
    openDialog(
      "Error",
      <>
        Error while sending message: <br />
        {err?.response?.data?.error || err.message}
      </>
    );
  }, [openDialog]);

  // ----------------------------------------------
  // "Send Offer" flow
  // ----------------------------------------------
  const handleOfferSent = () => {
    setSendOfferOpen(false);
  };

  // Handle view/edit an offer from a deliverable message
  const handleViewOffer = (offer) => {
    if (onDeliverableClick) {
      onDeliverableClick(offer);
    } else {
      setSelectedOffer(offer);
      setViewOfferOpen(true);
    }
  };

  // ----------------------------------------------
  // PDF File Editing
  // ----------------------------------------------
  const isPdfFile = (file) => {
    const fileType = file.file_type ? file.file_type.toLowerCase() : "";
    const filename = file.filename ? file.filename.toLowerCase() : "";
    return fileType === "pdf" || fileType === "contract" || filename.endsWith(".pdf");
  };

  const handleEditFile = (file) => {
    if (isPdfFile(file)) {
      setFileForSigning(file);
      setPdfSigningDialogOpen(true);
    } else {
      setFileForSigning(file);
      setPdfSigningDialogOpen(true);
    }
  };

  // ----------------------------------------------
  // INVOICE creation
  // ----------------------------------------------
  const handleInvoiceCreated = useCallback(
    async (newInvoice) => {
      // 1) Close the dialog
      setInvoiceDialogOpen(false);

      // 2) Attach the new invoice to a chat message
      const baseInput = specificReaderId ? { reader_id: specificReaderId } : {};
      try {
        await sendMessage({
          id: conversationId,
          input: {
            ...baseInput,
            message: '', // or "Please see attached invoice"
            attachment: {
              type: 'invoice',
              data: {
                // the invoice data from your back end
                invoice_id: newInvoice.id,
                invoice_data: newInvoice,
              },
            },
          },
        });
      } catch (error) {
        console.error('Error sending invoice message:', error);
        openDialog(
          "Error",
          `Failed to send invoice: ${error?.response?.data?.error || error.message}`
        );
      }
    },
    [conversationId, specificReaderId, sendMessage, openDialog]
  );

  // ----------------------------------------------
  // Render
  // ----------------------------------------------
  return (
    <>
      <Box
        id='chat-holder'
        sx={{
          ...sx,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: '100%'
        }}
      >
        {showWarning && (
          <Paper
            elevation={2}
            sx={{
              paddingBlock: isDesktop ? 2 : 0.5,
              backgroundColor: "#fff3cd",
              margin: 1,
              marginInline: margin
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#856404", textAlign: "center" }}
            >
              ⚠️{" "}
              {isDesktop
                ? "Deals and payments must happen within the Blitz platform. Going outside the platform could result in losing deals."
                : "Deals must happen within the Blitz platform."}
            </Typography>
          </Paper>
        )}

        {/* Messages Container */}
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            paddingInline: margin,
            paddingBlockStart: showWarning ? 0 : 2,
            minHeight: "300px",
            minWidth: "0px",
            width: "100%",
            overflowY: "auto",
            overflowX: "clip",
            flex: 1,
            gap: chatSpacing
          }}
          ref={listRef}
        >
          {messagesLoading && <Typography>Loading messages...</Typography>}
          {messagesError && (
            <Typography color="error">
              Error loading messages:{" "}
              {messagesError?.response?.data?.error || messagesError.message}
            </Typography>
          )}
          {chatError && <Typography color="error">{chatError}</Typography>}
          {messages.length === 0 && !messagesError && !messagesLoading && (
            <Typography
              sx={{ color: "text.secondary", textAlign: "center", mt: 2 }}
            >
              No messages yet. Start the conversation!
            </Typography>
          )}

          {messages.map((msg) => {
            const isUserMessage = isMessageMine(userId, msg);
            const justify = isUserMessage ? "flex-end" : "flex-start";
            const isSimpleMsg = !msg.deliverable && !msg.attachments;
            return (
              <Box
                key={msg.id}
                sx={{
                  display: "flex",
                  justifyContent: justify,
                  maxWidth: "100%",
                  transition: "all 0.2s"
                }}
              >
                {msg.deliverable && (
                  <Box
                    onClick={() => handleViewOffer(msg.deliverable)}
                    sx={{ cursor: "pointer" }}
                  >
                    <DeliverableCard
                      key={msg.id}
                      deliverable={msg.deliverable}
                      isUserMessage={isUserMessage}
                      onViewOffer={handleViewOffer}
                    />
                  </Box>
                )}
                {msg.attachments && (
                  <AttachmentBubble
                    msg={msg}
                    userId={userId}
                    isTcc={isTcc}
                    handleDeleteMessage={handleDeleteMessage}
                    deleteMessageLoading={deleteMessageLoading}
                    onEditFile={handleEditFile}
                  />
                )}
                {isSimpleMsg && (
                  <ChatBubble
                    msg={msg}
                    userId={userId}
                    isTcc={isTcc}
                    handleDeleteMessage={handleDeleteMessage}
                    deleteMessageLoading={deleteMessageLoading}
                    handleReadReceipts={(selectedMsg) =>
                      setCurrentMessage(selectedMsg)
                    }
                    participants={conversation.participants}
                  />
                )}
              </Box>
            );
          })}
        </Box>

        {/* Message Input Area */}
        <Box sx={{ paddingBlock: 2, paddingInline: margin }}>
          {children}
          <Box display="flex" alignItems="center" gap={1}>
            {/* Attachment SpeedDial (for brand or for creator) */}
            {!isCreator && (
              <Box sx={{ width: "56px", height: "56px", position: "relative" }}>
                <SpeedDial
                  ariaLabel="Attachment Menu"
                  icon={<AttachFileIcon />}
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                  FabProps={{
                    disabled: sendMessageLoading || messagesRefetching || !messages
                  }}
                >
                  <SpeedDialAction
                    icon={<InsertDriveFileOutlinedIcon />}
                    tooltipTitle="Attach File"
                    onClick={() => setUploadDialogOpen(true)}
                  />
                  <SpeedDialAction
                    icon={<PersonOutlinedIcon />}
                    tooltipTitle="Attach Creator"
                    onClick={() => setCreatorDialogOpen(true)}
                  />
                  <SpeedDialAction
                    icon={<CampaignOutlinedIcon />}
                    tooltipTitle="Attach Campaign"
                    onClick={() => setCampaignDialogOpen(true)}
                  />
                  <SpeedDialAction
                    icon={<LocalShippingIcon />}
                    tooltipTitle="Attach Shipping Address"
                    onClick={() => setCreatorShippingAddress(true)}
                  />
                </SpeedDial>
              </Box>
            )}
            {isCreator && (
              <Box sx={{ width: "56px", height: "56px", position: "relative" }}>
                <SpeedDial
                  ariaLabel="Attachment Menu"
                  icon={<AttachFileIcon />}
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                  FabProps={{
                    disabled: sendMessageLoading || messagesRefetching || !messages
                  }}
                >
                  <SpeedDialAction
                    icon={<InsertDriveFileOutlinedIcon />}
                    tooltipTitle="Attach File"
                    onClick={() => setCreatorUploadDialogOpen(true)}
                  />
                  <SpeedDialAction
                    icon={<LocalShippingIcon />}
                    tooltipTitle="Attach Shipping Address"
                    onClick={() => setCreatorShippingAddress(true)}
                  />
                </SpeedDial>
              </Box>
            )}

            {/* "Send Offer" button for brand */}
            {!isCreator && (
              <Box sx={{ width: "56px", height: "56px", position: "relative" }}>
                <Fab
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                  color="primary"
                  aria-label="send offer"
                  onClick={() => setSendOfferOpen(true)}
                  disabled={
                    sendMessageLoading || messagesRefetching || !messages || isSending
                  }
                >
                  <MonetizationOnIcon />
                </Fab>
              </Box>
            )}

            {/* "Request Money" button for creators */}
            {isCreator && (
              <Box sx={{ width: "56px", height: "56px", position: "relative" }}>
                <Fab
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                  color="secondary"
                  aria-label="request invoice"
                  onClick={() => setInvoiceDialogOpen(true)}
                  disabled={
                    sendMessageLoading || messagesRefetching || !messages || isSending
                  }
                >
                  <MonetizationOnIcon />
                </Fab>
              </Box>
            )}

            <TextField
              multiline
              maxRows={MAX_MESSAGE_ROWS}
              value={message}
              autoComplete='off'
              name='chat-message'
              disabled={sendMessageLoading || !messages || !!selectedAttachment}
              onChange={(e) => setMessage(e.target.value)}
              label="Type a message"
              variant="outlined"
              sx={{ flex: 1 }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              InputProps={{
                ...(selectedAttachment && {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Chip
                        icon={getIcon(selectedAttachment.type)}
                        avatar={
                          selectedAttachment.preview ? (
                            <Avatar
                              alt={selectedAttachment.label}
                              src={selectedAttachment.preview}
                            />
                          ) : undefined
                        }
                        label={selectedAttachment.label}
                        sx={{ maxWidth: '14rem' }}
                        onDelete={() => setSelectedAttachment(null)}
                      />
                    </InputAdornment>
                  )
                })
              }}
            />

            <IconButton
              disabled={sendMessageLoading || messagesRefetching || !messages || isSending}
              onClick={handleSendMessage}
              color="primary"
            >
              {sendMessageLoading || messagesRefetching || isSending ? (
                <CircularProgress size={24} />
              ) : (
                <SendIcon />
              )}
            </IconButton>
          </Box>
        </Box>

        <Snackbar
          open={showMessageCapAlert}
          autoHideDuration={SNACKBAR_DURATION}
          onClose={() => setShowMessageCapAlert(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShowMessageCapAlert(false)}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Messages since last creator response: You are capped at{" "}
            {MAX_MESSAGES_WITHOUT_RESPONSE}
          </Alert>
        </Snackbar>
      </Box>

      {/* ----------------------------------------------
          Sub-dialogs
      ---------------------------------------------- */}
      <SendOfferDialog
        open={sendOfferOpen}
        onClose={() => setSendOfferOpen(false)}
        partnershipId={partnershipId}
        creatorId={creatorId}
        conversationId={conversationId}
        onOfferSent={handleOfferSent}
      />

      {/* File selection for brand */}
      <SelectAssetDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        selectedAsset={selectedAttachment}
        onAssetClick={handleFileSelect}
      />

      {/* Creator selection */}
      <SelectCreatorDialog
        open={creatorDialogOpen}
        onClose={() => setCreatorDialogOpen(false)}
        onCreatorSelect={handleCreatorSelect}
      />

      {/* Campaign selection */}
      <SelectCampaignDialog
        open={campaignDialogOpen}
        onClose={() => setCampaignDialogOpen(false)}
        onCampaignSelect={handleCampaignSelect}
      />

      {/* Creator's own file upload */}
      <CreatorUploadFileDialog
        open={creatorUploadDialogOpen}
        onClose={() => setCreatorUploadDialogOpen(false)}
        conversationId={conversationId}
        readerId={specificReaderId}
        onFileUpload={handleCreatorFile}
        onError={handleCreatorUploadError}
      />

      {/* Creator shipping address form */}
      <ShippingAddressDialog
        open={creatorShippingAddress}
        onClose={handleShippingSelect}
      />

      {/* Read Receipts */}
      <ReadReceipt
        open={currentMessage !== null}
        handleClose={() => setCurrentMessage(null)}
        message={currentMessage}
        participants={conversation?.participants}
        currentUsername={userId}
      />

      {/* Generic confirmation/error dialog */}
      <AlertDialog alertState={dialogState} />

      {/* Offer Dialog for viewing/editing an offer */}
      <OfferDialog
        open={viewOfferOpen}
        onClose={() => setViewOfferOpen(false)}
        offer={selectedOffer}
        isCreator={isCreator}
        onOfferUpdated={(updatedOffer) => {
          setSelectedOffer(updatedOffer);
        }}
      />

      {/* PDF Signing Dialog */}
      <PdfSigningDialog
        open={pdfSigningDialogOpen}
        onClose={() => setPdfSigningDialogOpen(false)}
        fileData={fileForSigning}
        onFileSigned={(updatedFile) => {
          sendMessage({
            id: conversationId,
            input: {
              message: "",
              attachment: {
                type: "file",
                data: updatedFile
              }
            },
          });
          setPdfSigningDialogOpen(false);
        }}
      />

      {/* NEW Invoice Dialog */}
      <CreatorInvoiceDialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        onInvoiceCreated={handleInvoiceCreated}
      />
    </>
  );
}
