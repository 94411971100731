import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Badge,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";

import { useQuery } from "react-query";  // <-- React Query for unread_count
import { RateField } from "../../../../Components/RateField";
import { getFollowersByVideos, getTotalFollowersInDict } from "../../../../Utils/constants";

import client from "../../../../API"; // includes client.conversations.getUnreadCountForCampaignCreator
import API from "../../../../API"; // or wherever your `client.campaigns.creatorHistory` is accessible

/**
 * A simple helper to display each video (and any crossposts).
 */
function VideoDetails({ video, creator, onCompleteVideo, isTcc }) {

  const liveLinksFilled = !!video.liveLink && video.crossposts.every((v) => !!v.liveLink);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          minWidth: "0px",
          maxWidth: "100%",
          marginBlockEnd: 2,
          gap:1
        }}
      >
        <Stack direction="column" spacing={1} alignItems="flex-start">
          <Typography
            component='div'
            variant="subtitle2"
            color="text.secondary"
            sx={{ marginInlineEnd: 2 }}
          >
            {`Video #${String(video.id).padStart(3, "0")}`}
          </Typography>
          {isTcc && <Button
            variant={video.payoutComplete ? "outlined" : "contained"}
            color="primary"
            disabled={
              video.payoutComplete ||
              creator.skipPayout ||
              !liveLinksFilled
            }
            onClick={() => onCompleteVideo(creator.id, video.id)}
            sx={{ minWidth: 160 }}
          >
            {video.payoutComplete
              ? "Payment Completed"
              : "Pay for Video"}
          </Button>}
        </Stack>
        <Box sx={{ flex: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              value={video.liveLink ? video.liveLink : "Empty"}
              disabled
              label="Live Link"
              fullWidth
              variant="outlined"
              size="small"
            />
            <Chip color="secondary" label={video.promotionPlatform} />
            <Chip color="primary" label={video.promotionType} />
          </Stack>
          <TextField
            value={video.boostCode ? video.boostCode : "Empty"}
            disabled
            label="Boost Code"
            fullWidth
            variant="outlined"
            size="small"
            margin="dense"
          />
          <Box sx={{ marginInlineStart: "3em" }}>
            {video.crossposts.map((crosspost) => (
              <React.Fragment
                key={`video-${video.id}-crosspost-${crosspost.id}`}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  marginBlockStart={1}
                >
                  <TextField
                    value={crosspost.liveLink ? crosspost.liveLink : "Empty"}
                    disabled
                    label="Crosspost Live Link"
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                  <Chip color="secondary" label={crosspost.promotionPlatform} />
                </Stack>
                <TextField
                  value={crosspost.boostCode ? crosspost.boostCode : "Empty"}
                  disabled
                  label="Crosspost Boost Code"
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="dense"
                />
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
      <Divider variant="inset" flexItem sx={{ mb: 2 }} />
    </>
  );
}

/**
 * Dialog component for displaying this particular creator’s history.
 */
function CreatorHistoryDialog({ open, onClose, historyData, loading }) {
  /**
   * Map from JSON keys -> friendly label
   */
  const FIELD_LABELS = {
    price: "Price",
    status: "Status",
    agencyRate: "Agency Rate",
    assetsSent: "Deliverables Sent",
    assetsApproved: "Deliverables Approved",
    creatorBrief: "Creator Brief",
    postingInstructions: "Posting Instructions",
    skipPayout: "Skip Payment",
    payoutComplete: "Payment Completed",
    boostCode: "Boost Code",
    name: "Creator Name",
    submissionLink: "Submission Link",
  };

  /**
   * If you see changes like "root['creators'][3]" => [object Object],
   * it means the entire sub-object was replaced. Usually we can skip those,
   * because we only want to show *field-level* changes.
   */
  const shouldSkipEntireObjectChange = (oldVal, newVal) => {
    // If both are "[object Object]" or null => skip it
    return (
      (oldVal === "[object Object]" && newVal === "[object Object]") ||
      (oldVal === "N/A" && newVal === "N/A")
    );
  };

  /**
   * Try to extract the last bracketed key, e.g. "price" from
   * path "root['creators'][0]['price']".
   */
  const extractFieldKey = (path) => {
    const match = path.match(/\['([^']+)'\]$/);
    if (match && match[1]) {
      return match[1];
    }
    return path; // fallback
  };

  /**
   * Convert the raw key to a user-friendly label, or return `null` if unknown.
   */
  const getFieldLabel = (fieldKey) => {
    return FIELD_LABELS[fieldKey] || null;
  };

  /**
   * Convert an old/new value into a friendlier string.
   * Example: For boolean => "Yes"/"No"; for empty string => "(empty)"
   */
  const beautifyValue = (fieldKey, val) => {
    // Handle null/undefined
    if (val === null || val === undefined) return "(none)";
    if (val === "") return "(empty)";
    if (val === "[object Object]") return "[complex object]";
    if (typeof val === "boolean") return val ? "Yes" : "No";

    // For numeric fields we know
    if ((fieldKey === "price" || fieldKey === "agencyRate") && !isNaN(val)) {
      // turn "569.9905" into e.g. "$569.99"
      const floatVal = parseFloat(val);
      return `$${floatVal.toFixed(2)}`;
    }

    return val.toString();
  };

  /**
   * Example: "Price changed from $600.00 to $750.00"
   */
  const formatChangeLine = (fieldLabel, oldValue, newValue) => {
    return `${fieldLabel} changed from ${oldValue} to ${newValue}`;
  };

  /**
   * Convert DeepDiff style changes => array of lines
   */
  const gatherHumanReadableChanges = (changes) => {
    const lines = [];

    for (const [changeType, detail] of Object.entries(changes)) {
      if (!detail || typeof detail !== "object") continue;

      // detail is typically { "root['creators'][0]['price']": {old_value:..., new_value:...}, ... }
      for (const [path, diffObj] of Object.entries(detail)) {
        if (!diffObj || typeof diffObj !== "object") continue;

        let { old_value: oldVal, new_value: newVal } = diffObj;
        if (oldVal === undefined) oldVal = "N/A";
        if (newVal === undefined) newVal = "N/A";

        // If both old/new are entire objects, skip
        if (shouldSkipEntireObjectChange(oldVal, newVal)) {
          continue;
        }

        const fieldKey = extractFieldKey(path);
        const label = getFieldLabel(fieldKey);

        // If we don't recognize the label, skip or handle generically:
        if (!label) {
          // Possibly skip
          continue;
        }

        const prettyOldVal = beautifyValue(fieldKey, oldVal);
        const prettyNewVal = beautifyValue(fieldKey, newVal);

        lines.push(formatChangeLine(label, prettyOldVal, prettyNewVal));
      }
    }

    return lines;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Creator History</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : historyData.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No history entries for this creator
          </Typography>
        ) : (
          historyData.map((entry) => {
            const changes =
              typeof entry.changes === "string"
                ? JSON.parse(entry.changes || "{}")
                : entry.changes || {};

            const lines = gatherHumanReadableChanges(changes);

            return (
              <Box
                key={entry.id}
                sx={{
                  mb: 3,
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: 4,
                }}
              >
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  <strong>User:</strong> {entry.user_name || "Unknown"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Timestamp:</strong>{" "}
                  {new Date(entry.timestamp).toLocaleString()}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle2" gutterBottom>
                  Changes:
                </Typography>

                {lines.length === 0 ? (
                  <Typography variant="body2" color="text.secondary">
                    (No recognized field changes)
                  </Typography>
                ) : (
                  lines.map((line, i) => (
                    <Box key={i} sx={{ mb: 1 }}>
                      • {line}
                    </Box>
                  ))
                )}
              </Box>
            );
          })
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * The main CreatorRow component with:
 *  - "Time Machine" (History) button
 *  - React Query unread_count call for Chat button badge
 */
export default function CreatorRow({
  creator,
  campaignDetails,
  index,
  expandedCreator,
  isLaunched,
  isTcc,
  showSkipPayment,
  handleFieldChange,
  handleExpandClick,
  handleDialogOpen,
  handleResendOfferClick,
  confirmDropCreator,
  startConversation,
  completeCreator,
  completeVideo,
  setSelectedCreator,
  setNegotiationDialogOpen,
}) {
  // State for the history dialog
  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);

  // 1) Fetch unread_count with React Query
  // Only enable if launched and not "Pitched" (optional logic)
  const { data: unreadData } = useQuery(
    ["unreadCount", campaignDetails.id, creator.id],
    () =>
      client.conversations.getUnreadCountForCampaignCreator(
        campaignDetails.id,
        creator.id
      ),
    {
      enabled: isLaunched && creator.status !== "Pitched",
      refetchInterval: 60000, // poll every 60s
      refetchOnWindowFocus: false,
    }
  );
  const unreadCount = unreadData?.unread_count ?? 0;

  // Function to fetch the creator’s history
  const openCreatorHistory = async () => {
    try {
      setHistoryOpen(true);
      setLoadingHistory(true);
      const res = await API.campaigns.creatorHistory(
        campaignDetails.id,
        creator.id
      );
      setHistoryData(res);
    } catch (err) {
      console.error("Error fetching creator history:", err);
      alert("Failed to fetch history for this creator");
    } finally {
      setLoadingHistory(false);
    }
  };

  // Identify parent videos, gather crossposts
  const videoList = creator.videos
    .filter((video) => video.parentId == null)
    .map((video) => ({
      ...video,
      crossposts: creator.videos.filter((cross) => cross.parentId === video.id),
    }));

  // Check if all Live Links are filled
  const liveLinksFilled = creator.videos.every((v) => v.liveLink != null);

  return (
    <Paper
      elevation={1}
      sx={{
        mb: 2,
        borderRadius: 2,
        border: `2px solid ${creator.status === "Accepted"
          ? "#4CAF50"
          : creator.status === "Counter"
            ? "#FFEB3B"
            : creator.status === "Approved"
              ? "#2196F3"
              : "#e0e0e0"
          }`,
        overflow: "hidden",
        transition: "border-color 0.3s ease",
      }}
    >
      <ListItem sx={{ flexWrap: "wrap" }}>
        <ListItemAvatar>
          <Avatar src={creator.pfphref} alt={creator.id} />
        </ListItemAvatar>

        <ListItemText
          primary={
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="subtitle1">{creator.name}</Typography>
              <Chip
                label={
                  creator.status +
                  (creator.payoutComplete ? " + Payment Complete" : "")
                }
                size="small"
                sx={{
                  backgroundColor: (() => {
                    switch (creator.status) {
                      case "Accepted":
                        return "#4CAF50"; // green
                      case "Counter":
                        return "#FFEB3B"; // yellow
                      case "Approved":
                        return "#2196F3"; // blue
                      case "Pitched":
                      default:
                        return "#e0e0e0"; // gray
                    }
                  })(),
                  color: (() => {
                    if (creator.status === "Counter") {
                      return "rgba(0, 0, 0, 0.87)";
                    }
                    if (creator.status === "Pitched") return "black";
                    return "white";
                  })(),
                }}
              />
            </Box>
          }
          secondary={`Followers: ${getTotalFollowersInDict(getFollowersByVideos(creator.videos))
            ?.toLocaleString() || "N/A"
            }`}
        />

        {/* Right-side actions (Negotiate, Resend/Chat, History, Expand) */}
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: 1,
            marginLeft: "auto",
            [theme.breakpoints.down("sm")]: {
              flexWrap: "wrap",
            },
          })}
        >
          {creator.status === "Counter" && (
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                setSelectedCreator(creator);
                setNegotiationDialogOpen(true);
              }}
            >
              Negotiate
            </Button>
          )}

          {isLaunched && (
            <>
              {creator.status === "Pitched" ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleResendOfferClick(creator.name)}
                >
                  Resend Offer
                </Button>
              ) : (
                /* Wrap Chat button in a Badge if unread_count > 0 */
                <Badge
                  color="error"
                  badgeContent={unreadCount}
                  invisible={unreadCount <= 0}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => startConversation(creator.id, creator.name)}
                  >
                    Chat
                  </Button>
                </Badge>
              )}
            </>
          )}

          {/* "Time Machine" history button */}
          <IconButton onClick={openCreatorHistory} size="small" title="View History">
            <HistoryIcon />
          </IconButton>

          {/* Expand/Collapse icon */}
          <IconButton
            onClick={() => handleExpandClick(creator.id)}
            size="small"
          >
            {expandedCreator === creator.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </ListItem>

      {/* Collapsible section with full details */}
      <Collapse in={expandedCreator === creator.id}>
        <Box sx={{ p: 2, bgcolor: "transparent" }}>
          {/* Submission Link (if any) */}
          {creator.submissionLink && (
            <Card
              sx={{
                mb: 2,
                cursor: "pointer",
                border: "2px solid",
                borderColor: "primary.main",
              }}
              onClick={() => window.open(creator.submissionLink, "_blank")}
            >
              <CardContent>
                <Typography variant="subtitle1">
                  {creator.name}'s Submission
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Click to view submission
                </Typography>
              </CardContent>
            </Card>
          )}

          <Grid container spacing={2}>
            {/* Creator Brief */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                multiline
                minRows={3}
                label="Creator Brief"
                value={creator.creatorBrief || campaignDetails?.brief}
                onChange={(e) =>
                  handleFieldChange(creator.id, "creatorBrief", e.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* Insert file link via parent's "Select Asset Dialog" */}
                      <IconButton onClick={() => handleDialogOpen(index)} edge="end">
                        <CloudUploadIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Price / Agency Rate */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <RateField
                    label="Price"
                    value={creator.price ?? 0}
                    onChange={(value) =>
                      handleFieldChange(creator.id, "price", value)
                    }
                    fullWidth
                  />
                </Grid>
                {isTcc && (
                  <Grid item xs={12} sm={6}>
                    <RateField
                      label="Agency Rate"
                      value={creator.agencyRate ?? 0}
                      onChange={(value) =>
                        handleFieldChange(creator.id, "agencyRate", value)
                      }
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>

              {/* Deliverables Sent / Approved */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={creator.assetsSent}
                        onChange={() =>
                          handleFieldChange(
                            creator.id,
                            "assetsSent",
                            !creator.assetsSent
                          )
                        }
                      />
                    }
                    label="Deliverables Sent"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    disabled={!creator.assetsSent}
                    control={
                      <Checkbox
                        checked={creator.assetsApproved}
                        onChange={() =>
                          handleFieldChange(
                            creator.id,
                            "assetsApproved",
                            !creator.assetsApproved
                          )
                        }
                      />
                    }
                    label="Approved"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Posting Instructions */}
            <Grid item xs={12}>
              <TextField
                label="Posting Instructions"
                value={creator.postingInstructions ?? ""}
                onChange={(e) =>
                  handleFieldChange(creator.id, "postingInstructions", e.target.value)
                }
                fullWidth
                multiline
                minRows={2}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Video Details (and crossposts) */}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box>
                  {videoList.map((video) => (
                    <VideoDetails
                      isTcc={isTcc}
                      onCompleteVideo={completeVideo}
                      video={video}
                      creator={creator}
                      key={`${creator.id}-videos-${video.id}`}
                    />
                  ))}
                </Box>

                {/* "Skip Payment" / "Complete Payment" (if TCC) */}
                {isTcc && (
                  <Box sx={{ width: "100%" }}>
                    {showSkipPayment && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={creator.skipPayout}
                            disabled={creator.payoutComplete || !liveLinksFilled}
                            onChange={() =>
                              handleFieldChange(
                                creator.id,
                                "skipPayout",
                                !creator.skipPayout
                              )
                            }
                          />
                        }
                        label="Skip Payment"
                        sx={{ mr: 3 }}
                      />
                    )}
                    <Button
                      variant={creator.payoutComplete ? "outlined" : "contained"}
                      color="secondary"
                      disabled={
                        creator.payoutComplete ||
                        creator.skipPayout ||
                        !liveLinksFilled
                      }
                      onClick={() => completeCreator({ creator: creator.id })}
                      sx={{ minWidth: 160 }}
                    >
                      {creator.payoutComplete
                        ? "Payment Completed"
                        : "Complete Payment"}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Drop Creator Button */}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => confirmDropCreator(creator.id)}
            >
              Drop Creator
            </Button>
          </Box>
        </Box>
      </Collapse>

      {/* The Creator History Dialog */}
      <CreatorHistoryDialog
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        historyData={historyData}
        loading={loadingHistory}
      />
    </Paper>
  );
}
