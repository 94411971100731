import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Avatar,
  TextField,
  MenuItem,
  Link,
  useTheme,
  IconButton,
  Divider
} from '@mui/material';
import {
  Upload as UploadIcon,
  InsertDriveFile,
  PictureAsPdf,
  Image as ImageIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import client from '../../API';
import { getFileType } from '../../Utils/constants';

const renderFilePreview = (url) => {
  const fileType = getFileType(url);
  if (fileType === 'image') {
    return (
      <Avatar
        variant="rounded"
        src={url}
        sx={{
          width: 100,
          height: 100,
          boxShadow: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}
      />
    );
  }

  const Icon = {
    pdf: PictureAsPdf
  }[fileType] || InsertDriveFile;

  return (
    <Avatar
      variant="rounded"
      sx={{
        width: 100,
        height: 100,
        bgcolor: 'background.paper',
        boxShadow: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Icon sx={{ fontSize: 40 }} color="action" />
    </Avatar>
  );
};

const UploadFileDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024;

  // Hard-coded acceptable file types
  const fileTypes = ['brief', 'contract', 'image', 'video', 'file'];

  const { mutate: uploadFile, isLoading } = useMutation(
    (fileData) => client.files.post(fileData),
    {
      onSuccess: (data) => {
        setUploadedFile(data);
        setError(null);
      },
      onError: (error) => {
        setError(error.message || 'Failed to upload file');
      }
    }
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setError(null);

      if (file.size > FILE_SIZE_LIMIT) {
        setError('File size exceeds the 4.5MB limit. Please upload a smaller file.');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64File = reader.result.split(',')[1];
        uploadFile({
          file: {
            name: file.name,
            content: base64File,
            type: file.type, // MIME type
            file_type: documentType // user-selected file classification
          }
        });
      };
    },
    [uploadFile, documentType]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    disabled: isLoading
  });

  const handleClose = () => {
    if (!isLoading) onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {/* Header */}
      <DialogTitle
        sx={{
          bgcolor: theme.palette.primary.main,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Upload File
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: 'white' }} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Subtitle */}
      <Box sx={{ px: 3, pt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Supported formats: JPEG, PNG, PDF, TXT (Max size: 4.5MB)
        </Typography>
      </Box>

      {/* Content */}
      <DialogContent sx={{ px: 3, pt: 2, pb: 1 }}>
        {/* Divider to visually separate */}
        <Divider sx={{ mb: 2 }} />

        {/* Select File Type */}
        <TextField
          select
          label="File Type"
          value={documentType}
          onChange={(e) => setDocumentType(e.target.value)}
          fullWidth
          margin="normal"
        >
          {fileTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </MenuItem>
          ))}
        </TextField>

        {/* Dropzone */}
        <Box
          {...getRootProps()}
          sx={{
            border: `2px dashed ${
              isDragActive ? theme.palette.primary.main : theme.palette.divider
            }`,
            borderRadius: 2,
            p: 3,
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            bgcolor: isDragActive ? theme.palette.action.hover : 'background.default',
            '&:hover': {
              borderColor: theme.palette.primary.main,
              bgcolor: theme.palette.action.hover
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 2
          }}
        >
          <input {...getInputProps()} />
          <UploadIcon sx={{ fontSize: 40, mb: 1, color: 'text.secondary' }} />
          <Typography variant="body1">
            {isDragActive ? 'Drop file here' : 'Drag & drop or click to browse'}
          </Typography>
        </Box>

        {/* Error Display */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Loading */}
        {isLoading && (
          <Box sx={{ textAlign: 'center', p: 2 }}>
            <CircularProgress size={40} />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Uploading...
            </Typography>
          </Box>
        )}

        {/* Success Preview */}
        {uploadedFile && (
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Uploaded Successfully!
            </Typography>
            {renderFilePreview(uploadedFile.url)}
            <Box sx={{ mt: 2, maxWidth: 250, margin: '0 auto' }}>
              <Link
                href={uploadedFile.url}
                target="_blank"
                rel="noopener"
                sx={{ wordBreak: 'break-all' }}
              >
                {uploadedFile.url.split('/').pop()}
              </Link>
            </Box>
          </Box>
        )}
      </DialogContent>

      {/* Actions */}
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} variant="text" color="inherit" disabled={isLoading}>
          Cancel
        </Button>
        {uploadedFile && (
          <Button
            onClick={() => setUploadedFile(null)}
            variant="contained"
            color="primary"
            disableElevation
          >
            Upload Another
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;
