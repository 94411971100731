import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { ageGroups, ethnicity, genders, regionWithLabels, ToggleSet } from '../../../../Utils/constants';
import MinMaxInput from '../../../../Components/MinMaxInput';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DeleteIcon from '@mui/icons-material/Delete';
import iso3311a2 from 'iso-3166-1-alpha-2';

const nonScrollContainerStyle = {
    display: 'grid',
    justifyItems: 'start',
    gridTemplateColumns: 'repeat(auto-fill, minmax(max-content, 100px))',
    gridTemplateRows: 'repeat(2, auto)',
}

const priorityCountries = ['United States', 'Canada', 'United Kingdom'];
// Create the country array and sort it
const countryArray = Object.entries(iso3311a2.getData()).sort(([codeA, countryA], [codeB, countryB]) => {
const indexA = priorityCountries.indexOf(countryA);
const indexB = priorityCountries.indexOf(countryB);

if (indexA !== -1 && indexB === -1) return -1; // countryA is in priority, countryB is not
if (indexA === -1 && indexB !== -1) return 1;  // countryB is in priority, countryA is not
if (indexA !== -1 && indexB !== -1) return indexA - indexB; // Both are in priority, sort by their priority order

return countryA.localeCompare(countryB);
});

export default function AllFilters({
    allFiltersOpen,
    setAllFiltersOpen,
    minFollow,
    setMinFollow,
    maxFollow,
    setMaxFollow,
    minViews,
    setMinViews,
    maxViews,
    setMaxViews,
    creatorGender,
    setCreatorGender,
    creatorRace,
    setCreatorRace,
    creatorRegion,
    setCreatorRegion,
    publicAge,
    setPublicAge,
    publicCountry,
    setPublicCountry,
    publicGender,
    setPublicGender,
    selectedCollection,
    setSelectedCollection,
    handleResetFilters,
    collections
}) {
    const [showAllCountries, setShowAllCountries] = useState(false);

    return (
        <Dialog
            open={allFiltersOpen}
            onClose={() => setAllFiltersOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>All Filters</DialogTitle>
            <DialogContent
                dividers
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    mt: 1,
                    maxHeight: "75vh",
                    overflowY: "auto",
                    overfloX: 'clip'
                }}
            >
                {/* Followers */}
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Follower Range
                    </Typography>
                    <MinMaxInput
                        minValue={minFollow}
                        maxValue={maxFollow}
                        setMinValue={setMinFollow}
                        setMaxValue={setMaxFollow}
                        name='Followers'
                    >
                    </MinMaxInput>
                </Box>

                {/* Views */}
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Average Views Range
                    </Typography>
                    <MinMaxInput
                        minValue={minViews}
                        maxValue={maxViews}
                        setMinValue={setMinViews}
                        setMaxValue={setMaxViews}
                        name='Views'
                    >
                    </MinMaxInput>
                </Box>

                <Divider />

                {/* Creator Demographics */}
                <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Creator Demographics
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary' ><b>Creator Gender</b></Typography>
                    <Box sx={nonScrollContainerStyle}>
                        {genders.map((gender) =>
                            <FormControlLabel key={`gender-${gender}`}
                                sx={{ textTransform: 'capitalize' }}
                                checked={creatorGender.has(gender)}
                                onChange={() => ToggleSet(gender, creatorGender, setCreatorGender)}
                                label={gender}
                                control={<Checkbox></Checkbox>}></FormControlLabel>
                        )}
                    </Box>
                    <Typography variant='subtitle1' color='text.secondary' ><b>Creator Race</b></Typography>
                    <Box sx={nonScrollContainerStyle}>
                        {ethnicity.map((race) =>
                            <FormControlLabel key={`race-${race}`}
                                checked={creatorRace.has(race)}
                                onChange={() => ToggleSet(race, creatorRace, setCreatorRace)}
                                label={race}
                                control={<Checkbox></Checkbox>}></FormControlLabel>
                        )}
                    </Box>
                    <Typography variant='subtitle1' color='text.secondary' ><b>Creator Region</b></Typography>
                    <Box sx={nonScrollContainerStyle}>
                        {regionWithLabels.map((region) =>
                            <FormControlLabel key={`region-${region.value}`}
                                checked={creatorRegion.has(region.value)}
                                onChange={() => ToggleSet(region.value, creatorRegion, setCreatorRegion)}
                                label={region.label}
                                control={<Checkbox></Checkbox>}></FormControlLabel>
                        )}
                    </Box>
                </Box>

                <Divider />

                {/* Audience Demographics */}
                <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Audience Demographics
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary' ><b>Top Gender groups</b></Typography>
                    <Box sx={nonScrollContainerStyle}>
                        {['female', 'male'].map((gender) =>
                            <FormControlLabel key={`gender-${gender}`}
                                sx={{ textTransform: 'capitalize' }}
                                checked={publicGender.has(gender)}
                                onChange={() => ToggleSet(gender, publicGender, setPublicGender)}
                                label={gender}
                                control={<Checkbox></Checkbox>}></FormControlLabel>
                        )}
                    </Box>

                    <Typography variant='subtitle1' color='text.secondary' ><b>Top Age groups</b></Typography>
                    <Box sx={nonScrollContainerStyle}>
                        {ageGroups.map((age) =>
                            <FormControlLabel key={`age-${age}`}
                                checked={publicAge.has(age)}
                                onChange={() => ToggleSet(age, publicAge, setPublicAge)}
                                label={age}
                                control={<Checkbox></Checkbox>}></FormControlLabel>
                        )}
                    </Box>

                    <Typography variant='subtitle1' color='text.secondary' ><b>Top Regions</b></Typography>
                    <Box sx={{
                        display: 'grid',
                        justifyItems: 'start',
                        overflowX:'auto',
                        maxWidth:'100%',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(max-content, 1rem))',
                        gridAutoFlow: 'column', // Items will flow into columns instead of creating more rows
                        gridTemplateRows: 'repeat(4, auto)',
                    }}>
                        {countryArray.slice(0, showAllCountries ? countryArray.length : 8).map(([code, country]) =>
                            <FormControlLabel key={`region-${code}`}
                                checked={publicCountry.has(code)}
                                onChange={() => ToggleSet(code, publicCountry, setPublicCountry)}
                                label={country}
                                sx={{wordBreak:'keep-all', textWrap:'nowrap'}}
                                control={<Checkbox></Checkbox>}></FormControlLabel>
                        )}
                    </Box>
                    <Button size='small' variant={showAllCountries ? 'contained' : 'text'} onClick={() => setShowAllCountries(prevState => !prevState)}>{showAllCountries ? 'Show Less' : 'Show More'}</Button>
                </Box>

                <Divider />

                {/* Collections (Saved Searches) */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Saved Searches
                    </Typography>
                    <TextField
                        value={selectedCollection}
                        onChange={(e) => setSelectedCollection(e.target.value)}
                        label="Choose a Saved Search"
                        variant="outlined"
                        size="small"
                        select
                        fullWidth
                        SelectProps={{
                            renderValue: (selected) => {
                                const col = collections.find((c) => c.id === selected);
                                return col ? col.name : "None";
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {collections.map((collection) => (
                            <MenuItem key={collection.id} value={collection.id}>
                                <Box
                                    sx={{ display: "flex", alignItems: "center", width: "100%" }}
                                >
                                    <Box sx={{ flexGrow: 1 }}>{collection.name}</Box>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                            deleteCollection(collection.id);
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </MenuItem>
                        ))}
                        <Divider />
                        <MenuItem onClick={() => setIsAddCollectionDialogOpen(true)}>
                            Add New Filter Set
                        </MenuItem>
                    </TextField>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button
                    startIcon={<CleaningServicesIcon />}
                    onClick={handleResetFilters}
                    color="warning"
                >
                    Reset
                </Button>
                <Button onClick={() => setAllFiltersOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
