import React, { useState, useMemo } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  AppBar,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Card,
  Stack,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "../../../API"; // Adjust path as needed
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import ChatScreen from "../../../Components/ChatScreen";
import TabPanel from "../../../Components/TabPanel";
import AgencyNegotiateDialog from "./agencyNegotiate"; // Adjust path as needed
import { formatLinksInMessage } from "../../../Utils/constants";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

const AgencyCampaignPitchDialog = ({
  openDialog,
  handleCloseDialog,
  campaign,
  username,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [openNegotiateDialog, setOpenNegotiateDialog] = useState(false);
  const queryClient = useQueryClient();
  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  // Fetch conversation for this campaign and creator
  const {
    data: conversation,
    isLoading: isConversationLoading,
    refetch: refetchConversation,
  } = useQuery(
    ["conversation", campaign?.id, username],
    () =>
      client.conversations.checkCampaign({
        campaign_id: campaign?.id,
        creator_id: username,
      }),
    {
      enabled: !!campaign && !!username && openDialog,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error("Error fetching conversation:", err);
      },
    }
  );

  const creator = useMemo(() => {
    if (!campaign) return undefined;
    return campaign.creators.find((creator) => creator.id === username);
  }, [campaign, username]);

  const currentRate = useMemo(
    () => creator?.agencyRate || creator?.price || 0,
    [creator]
  );

  const videoList = creator?.videos.filter(video => video.parentId == null).map(video => ({
    ...video
  })) || [];

  const crosspostList = creator?.videos.filter(video => video.parentId != null).map(video => ({
    ...video
  })) || [];

  const { mutate: updateStatus, isLoading: isUpdatingStatus } = useMutation(
    (input) => client.agency.campaignAccept(campaign.id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["creators", username]);
        queryClient.invalidateQueries(["campaigns"]);
      },
      onError: (error) => {
        openAlert(
          "Error",
          `Error updating status: ${error.response?.data?.error || error.message}`,
          closeDialog,
          null,
          "Ok",
          null
        );
      },
    }
  );

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1 && conversation?.conversation_id) {
      queryClient.invalidateQueries(["messages", conversation.conversation_id]);
    }
  };

  const handleChangeStatus = (accepted) => {
    updateStatus(
      { status: accepted ? "Accepted" : "Declined", creator: username },
      {
        onSuccess: () => {
          const title = accepted ? "Campaign Accepted" : "Campaign Rejected";
          const message = accepted
            ? "You have successfully accepted this campaign."
            : "You have rejected this campaign.";
          openAlert(title, message, () => {
            closeDialog();
            handleCloseDialog();
          }, null, "Ok", null);
        },
      }
    );
  };

  const onClickOption = (accepted = false) => {
    const title = accepted ? "Accept Campaign" : "Reject Campaign";
    const message = accepted
      ? "By accepting this campaign, your creator will be notified of upcoming due dates and receive messages from the campaign manager. Continue?"
      : "Rejecting this campaign is irreversible and will remove it from your dashboard. Continue?";
    openAlert(title, message, () => {
      closeDialog();
      handleChangeStatus(accepted);
    }, closeDialog, "Continue", "Cancel");
  };

  if (!campaign) {
    return null;
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="xl"
        fullWidth
        scroll="paper"
        PaperProps={{
          sx: { minHeight: "90vh", display: "flex", flexDirection: "column" },
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <StyledPaper elevation={2}>
            <Typography variant="h4" gutterBottom sx={{ p: 2 }}>
              {campaign.name} - Pitch Review
            </Typography>
          </StyledPaper>
        </DialogTitle>

        <AppBar position="static" color="default" sx={{ marginBottom: 2 }}>
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="pitch campaign tabs"
          >
            <Tab label="Overview" />
            <Tab label="Chat with Brand" />
          </Tabs>
        </AppBar>

        <DialogContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {isUpdatingStatus ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              <TabPanel value={tabIndex} index={0}>
                <StyledPaper elevation={2}>
                  <Typography variant="body1">
                    <strong>Ideal Due Date:</strong>{" "}
                    {formatIdealDueDate(campaign.ideal_duedate)}
                  </Typography>
                  <Typography variant="body1"><strong>Campaign Brief:</strong></Typography>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre' }}>
                    {formatLinksInMessage(campaign.brief)}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status:</strong>{" "}
                    {creator?.status || campaign.campaign_status}
                  </Typography>
                </StyledPaper>

                {creator && (
                  <StyledPaper elevation={2}>
                    <Typography variant="h6" gutterBottom>
                      <strong>Rate:</strong>{" "}
                      {creator.agencyRate
                        ? `$${creator.agencyRate}`
                        : creator.price
                          ? `$${creator.price}`
                          : "N/A"}
                    </Typography>
                    <Stack direction='row' alignItems='center' spacing={1} marginBlockEnd={1}>
                      <Typography variant="subtitle2">Promotions (x{videoList.length} video{videoList.length == 1 ? '' : 's'}): </Typography>
                      {videoList.map(video => (<Chip key={`video-${video.id}`} color='primary' label={`${video.promotionPlatform} - ${video.promotionType}`}></Chip>))}
                    </Stack>

                    <Stack direction='row' alignItems='center' spacing={1}>
                      <Typography variant="subtitle2">Crossposts: </Typography>
                      {crosspostList.map(video => (<Chip key={`cross-${video.id}`} color='secondary' label={`${video.promotionPlatform}`}></Chip>))}
                    </Stack>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="body1" gutterBottom>
                      <strong>Creator Brief:</strong>
                    </Typography>
                    <Card variant="outlined" sx={{ padding: 1 }}>
                      <Typography variant="body1" sx={{ whiteSpace: 'pre' }}>
                        {formatLinksInMessage(creator?.creatorBrief || "")}
                      </Typography>
                    </Card>
                    <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                      <strong>Posting Instructions:</strong>
                    </Typography>
                    <TextField
                      fullWidth
                      value={
                        creator?.postingInstructions || "No instructions provided"
                      }
                      disabled
                      multiline
                      minRows={2}
                    />
                  </StyledPaper>
                )}
              </TabPanel>

              <TabPanel value={tabIndex} index={1}>
                <StyledPaper
                  elevation={2}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Chat with Brand
                  </Typography>
                  {isConversationLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : conversation?.conversation_id ? (
                    <ChatScreen
                      conversationId={conversation.conversation_id}
                      sx={{ minHeight: "480px", maxHeight: "720px", flex: 1 }}
                      specificReaderId={username}
                      margin={0}
                      showWarning={false}
                    />
                  ) : (
                    <Typography variant="body1">
                      No conversation exists for this campaign yet.
                    </Typography>
                  )}
                </StyledPaper>
              </TabPanel>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleCloseDialog} variant="text">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => onClickOption(true)}
            color="success"
            disabled={isUpdatingStatus || creator?.status === "Counter"}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenNegotiateDialog(true)}
            color="warning"
            disabled={isUpdatingStatus || creator?.status === "Counter"}
          >
            Negotiate
          </Button>
          <Button
            variant="contained"
            onClick={() => onClickOption(false)}
            color="error"
            disabled={isUpdatingStatus || creator?.status === "Counter"}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      {campaign && (
        <AgencyNegotiateDialog
          open={openNegotiateDialog}
          handleClose={() => setOpenNegotiateDialog(false)}
          campaign={campaign}
          creatorId={username}
          currentRate={currentRate}
          refreshCampaign={() => queryClient.invalidateQueries(["campaigns"])}
          onSuccess={() => handleCloseDialog()}
        />
      )}

      <AlertDialog alertState={dialogState} />
    </>
  );
};

export default AgencyCampaignPitchDialog;