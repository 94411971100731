import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";

const ConfirmInvoiceDialog = ({
  open,
  onClose,
  invoiceId,
  onSuccess,
  confirmInvoiceMutation,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [reasoning, setReasoning] = useState("");
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  // For signature capture
  const sigCanvasRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setFilePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const clearSignature = () => {
    sigCanvasRef.current?.clear();
  };

  const handleConfirm = () => {
    if (!invoiceId) {
      return;
    }

    // Build up the proof object
    const proof = {
      Reasoning: reasoning || "User-confirmed invoice",
      date_confirmed: new Date().toISOString(),
    };

    // If screenshot tab
    if (tabIndex === 0 && file) {
      proof.screenshot_url = file.name; 
      // In a real scenario, you’d upload the file somewhere
      // and store that resulting URL in "screenshot_url".
    }

    // If signature tab
    if (tabIndex === 1 && sigCanvasRef.current) {
      const signatureData = sigCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      proof.signature_url = signatureData;
    }

    confirmInvoiceMutation({
      invoice_id: invoiceId,
      approval_proof: proof,
    });
    // onSuccess is handled by parent when mutation resolves successfully
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm Your Invoice</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Provide one form of approval (upload screenshot or sign).          
          By completing this form you are indicating that the proper person in your AP or accounting team has recieved this invoice.

        </Typography>

        {/* Reasoning field */}
        <TextField
          label="Reasoning / Description"
          fullWidth
          multiline
          rows={2}
          value={reasoning}
          onChange={(e) => setReasoning(e.target.value)}
          sx={{ mt: 2 }}
        />

        {/* Tabs */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{ mt: 2 }}
        >
          <Tab label="Upload Screenshot" />
          <Tab label="Signature" />
        </Tabs>

        {tabIndex === 0 && (
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" component="label">
              Select File
              <input type="file" hidden accept="image/*" onChange={handleFileChange} />
            </Button>
            {filePreview && (
              <Box sx={{ mt: 2 }}>
                <img
                  src={filePreview}
                  alt="preview"
                  style={{ maxWidth: "100%", maxHeight: 300 }}
                />
              </Box>
            )}
          </Box>
        )}

        {tabIndex === 1 && (
          <Box sx={{ mt: 2 }}>
            <SignatureCanvas
              ref={sigCanvasRef}
              penColor="black"
              canvasProps={{ width: 500, height: 200, style: { border: "1px solid #ccc" } }}
            />
            <Box sx={{ mt: 1 }}>
              <Button variant="outlined" onClick={clearSignature}>
                Clear Signature
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmInvoiceDialog;
