import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Switch,
  Pagination,
  TextField,
  Box,
  InputLabel,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useQuery } from "react-query";

import client from "../../../../API";
import { StyledTableRow, StyledTableCell } from "../../../../Utils/styledcell";
import InvoiceDialog from "../invoicedialog";
import { invoiceStatuses } from "../../../../Utils/constants";
import { useIsMounted } from "../../../../Hooks/use-is-mounted";
import {
  StyleButtonContainter,
  StyleButton,
  StyleContentWithNavBar,
} from "../../../../Utils/ThemedStyles";

const InvoiceDetailsView = () => {
  const isMounted = useIsMounted();

  // State
  const [selectedIds, setSelectedIds] = useState([]);
  const [isInvoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const [usePagination, setUsePagination] = useState(true);

  // Fetch invoices via React Query
  const {
    data: invoices = [],
    refetch: fetchInvoices,
    error,
    isFetching,
  } = useQuery({
    queryKey: ["invoices"],
    queryFn: () => client.invoices.listAdmin(),
    refetchOnWindowFocus: false, // Keep from first snippet
  });

  useEffect(() => {
    setSelectedIds([]);
  }, [invoices]);

  useEffect(() => {
    if (error) {
      alert(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error.message
      );
      console.error("Error fetching invoices:", error);
    }
  }, [error]);

  // Utility: get difference between two dates in days (rounded)
  const getDaysBetweenDates = (start, end) => {
    if (!start || !end) return 0;
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();
    // Convert ms -> days
    return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  // Compute days late
  const getDaysLate = (invoice) => {
    if (!invoice.payment_date || !invoice.payment_term) return "N/A";
    const totalDays = getDaysBetweenDates(invoice.created_at, invoice.payment_date);
    const daysLate = totalDays - invoice.payment_term;
    // Optionally clamp to 0 if negative
    return daysLate < 0 ? 0 : daysLate;
  };

  // Compute total days to pay
  const getTotalDaysToPay = (invoice) => {
    if (!invoice.payment_date) return "N/A";
    return getDaysBetweenDates(invoice.created_at, invoice.payment_date);
  };

  // Delete selected invoices
  const onDeleteInvoices = async () => {
    if (selectedIds.length > 0) {
      try {
        await client.invoices.delete({ invoiceIds: selectedIds });
        fetchInvoices();
      } catch (error) {
        console.error("Error deleting invoices", error);
      }
    }
  };

  // Open invoice dialog
  const handleDialogOpen = (invoice) => {
    setEditingInvoice(invoice);
    setInvoiceDialogOpen(true);
  };

  // Close invoice dialog; re-fetch if there were changes
  const handleDialogClose = (changed) => {
    setInvoiceDialogOpen(false);
    setEditingInvoice(null);
    if (changed) {
      fetchInvoices();
    }
  };

  // Open public link
  const handleLink = (invoice) => {
    window.open(`https://www.useblitz.co/invoicing/${invoice.id}`, "_blank");
  };

  // Check/uncheck invoice in the table
  const handleSelectInvoice = (invoiceId) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(invoiceId)
        ? prevSelected.filter((id) => id !== invoiceId)
        : [...prevSelected, invoiceId]
    );
  };

  // Status Filter
  const handleFilterChange = (event) => {
    const newFilter = event.target.value;
    // If the user selects "all", reset
    if (newFilter === "all") {
      setStatusFilter("");
    } else {
      setStatusFilter(newFilter);
    }
    setPage(0);
  };

  // Filter invoices by status
  const filteredInvoices = useMemo(() => {
    if (!statusFilter) return invoices;
    return invoices.filter((invoice) => invoice.status === statusFilter);
  }, [invoices, statusFilter]);

  // Sorting
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    setPage(0);
  };

  const sortedInvoices = useMemo(() => {
    const sorted = [...filteredInvoices];

    // Helper to get numerical days late. If "N/A", we'll treat as 0.
    const getDaysLateNumber = (invoice) => {
      if (!invoice.payment_date || !invoice.payment_term) return 0;
      const totalDays = getDaysBetweenDates(invoice.created_at, invoice.payment_date);
      return totalDays - invoice.payment_term;
    };

    switch (sortOption) {
      case "amount_desc":
        return sorted.sort((a, b) => b.amount_due - a.amount_due);
      case "amount_asc":
        return sorted.sort((a, b) => a.amount_due - b.amount_due);

      case "days_late_desc":
        return sorted.sort((a, b) => getDaysLateNumber(b) - getDaysLateNumber(a));
      case "days_late_asc":
        return sorted.sort((a, b) => getDaysLateNumber(a) - getDaysLateNumber(b));

      case "terms_desc":
        return sorted.sort((a, b) => (b.payment_term || 0) - (a.payment_term || 0));
      case "terms_asc":
        return sorted.sort((a, b) => (a.payment_term || 0) - (b.payment_term || 0));

      case "created_desc":
        return sorted.sort(
          (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      case "created_asc":
        return sorted.sort(
          (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );

      default:
        return sorted;
    }
  }, [filteredInvoices, sortOption]);

  // Pagination
  const pagedInvoices = usePagination
    ? sortedInvoices.slice(
        page * itemsPerPage,
        Math.min(page * itemsPerPage + itemsPerPage, sortedInvoices.length)
      )
    : sortedInvoices;

  return (
    <Box sx={StyleContentWithNavBar}>
      {/* Loading Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Top controls */}
      <Box sx={StyleButtonContainter}>
        {/* Delete selected */}
        <Button
          variant="contained"
          onClick={onDeleteInvoices}
          startIcon={<DeleteIcon />}
          disabled={!selectedIds.length}
          sx={StyleButton}
        >
          Delete Selected
        </Button>

        {/* Status filter */}
        <TextField
          select
          value={statusFilter || "all"}
          onChange={handleFilterChange}
          label="Status"
          sx={{ minWidth: "10rem" }}
          SelectProps={{ sx: { textTransform: "capitalize" } }}
        >
          <MenuItem value="all">All Statuses</MenuItem>
          {invoiceStatuses.map((status) => (
            <MenuItem
              key={status}
              value={status}
              sx={{ textTransform: "capitalize" }}
            >
              {status}
            </MenuItem>
          ))}
        </TextField>

        {/* Sorting dropdown */}
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel id="sort-by-label">Sort by</InputLabel>
          <Select
            labelId="sort-by-label"
            value={sortOption}
            label="Sort by"
            onChange={handleSortChange}
          >
            <MenuItem value="">(No sorting)</MenuItem>
            <MenuItem value="amount_desc">Amount: High to Low</MenuItem>
            <MenuItem value="amount_asc">Amount: Low to High</MenuItem>
            <MenuItem value="days_late_desc">Days Late: Most to Least</MenuItem>
            <MenuItem value="days_late_asc">Days Late: Least to Most</MenuItem>
            <MenuItem value="terms_desc">Payment Terms: High to Low</MenuItem>
            <MenuItem value="terms_asc">Payment Terms: Low to High</MenuItem>
            <MenuItem value="created_desc">Date Issued: Most Recent</MenuItem>
            <MenuItem value="created_asc">Date Issued: Oldest</MenuItem>
          </Select>
        </FormControl>

        {/* Pagination switch */}
        <FormControlLabel
          label="Use pagination"
          checked={usePagination}
          onChange={() => setUsePagination((prev) => !prev)}
          control={<Switch color="primary" />}
        />
      </Box>

      {/* Invoices Table */}
      <TableContainer component={Paper} sx={(theme) => ({ marginTop: theme.spacing(2) })}>
        <Table stickyHeader aria-label="invoice-table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedIds.length > 0 &&
                    selectedIds.length < filteredInvoices.length
                  }
                  checked={
                    selectedIds.length === filteredInvoices.length &&
                    filteredInvoices.length > 0
                  }
                  onChange={(event) =>
                    setSelectedIds(
                      event.target.checked
                        ? filteredInvoices.map((inv) => inv.id)
                        : []
                    )
                  }
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Payment Name</TableCell>
              <TableCell>PO Number</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Fee on Amount (%)</TableCell>
              <TableCell>Payable Amount</TableCell>
              <TableCell>Date Issued</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Payment Term</TableCell>
              <TableCell>Total Days to Pay</TableCell>
              <TableCell>Days Late</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedInvoices.map((invoice) => {
              const payableAmount =
                invoice.amount_due * (1 - invoice.fee_percentage / 100);
              const formattedCreatedDate = new Date(invoice.created_at).toLocaleDateString();
              const daysLate = getDaysLate(invoice);
              const totalDaysToPay = getTotalDaysToPay(invoice);

              return (
                <StyledTableRow key={invoice.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={() => handleSelectInvoice(invoice.id)}
                      checked={selectedIds.includes(invoice.id)}
                    />
                  </TableCell>
                  <TableCell>{invoice.id}</TableCell>
                  <TableCell>{invoice.campaign_name}</TableCell>
                  <TableCell>{invoice.po_number}</TableCell>
                  <TableCell>
                    {invoice.amount_due.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>{invoice.fee_percentage.toFixed(0)}</TableCell>
                  <TableCell>
                    {payableAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>{formattedCreatedDate}</TableCell>
                  <TableCell>{invoice.status}</TableCell>
                  <TableCell>{invoice.invoice_type}</TableCell>
                  <TableCell>{invoice.payment_term || "N/A"}</TableCell>
                  <TableCell>{totalDaysToPay}</TableCell>
                  <TableCell>{daysLate}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDialogOpen(invoice)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleLink(invoice)}>
                      <LinkIcon />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination controls */}
      {usePagination && (
        <Box sx={{ paddingBlock: 2 }}>
          <Pagination
            sx={{ marginInline: "auto", maxWidth: "fit-content" }}
            count={Math.ceil(sortedInvoices.length / itemsPerPage)}
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
          />
        </Box>
      )}

      {/* Edit dialog */}
      {isInvoiceDialogOpen && (
        <InvoiceDialog
          open={isInvoiceDialogOpen}
          onClose={handleDialogClose}
          invoiceInfo={editingInvoice}
        />
      )}
    </Box>
  );
};

export default InvoiceDetailsView;