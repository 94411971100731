export const API_ENDPOINTS = {
  USERS_LIST: "/users",
  USERS_LOGIN: "/users/login",
  UNI_LOGIN: "/users/unilogin",
  USERS_INTERNAL_LOGIN: "/users/internal_login",
  USERS_UPDATE_GOOGLE: "/users/add_google_account",
  USERS_DASHBOARD: "/users/dashboard",
  FETCH_USER: "/users/userinfo",
  USERS_REGISTER: "/users/register",
  USERS_MYTEAM: "/users/myteam",
 // --------------------- NOTIFICATIONS ---------------------
  NOTIFICATIONS: "/users/notifications",                    // GET /notifications?unread=1 => filtered
  NOTIFICATIONS_UNREAD_COUNT: "/users/notifications/unread_count", // GET => { unread_count: number }
  POST_ALL_NOTIFICATIONS: "/users/notifications/all", 
  NOTIFICATIONS_UNREAD_COLLAB_COUNT: "/users/notifications/unread_collab_count", // New endpoint
  USERS_KPI: "/admin/users/kpi",
  USERS_DETAILS: "/users/details",
  USER_SETTINGS: "/users/user-settings",
  FETCH_SPEC_USER: (id) => `/users/userinfo/${id}`,

  USERS_FORGOT: "/users/forgotrequest",
  USERS_FORGOT_ACTION: "/users/forgotaction",

  ADMIN_CREATOR_EMAILS: 'admin/creatoremails',
  USERS_ZAPFEED: "/fetchzapfeed",
  CLIENTS_ADD: '/admin/clients/add',
  CLIENTS: '/admin/clients/fetch',
  USERS_TOP_USERS: "/topusers",
  LAUNCH_CAMPAIGN: "/campaign_handler/launch_campaign",
  CAMPAIGN_HANDLER_SPECS: "/campaign_handler/getCampaignCreatorSpecs",

  CAMPAIGNS: "/campaigns",
  CAMPAIGNS_GET: (id) => `/campaigns/${id}`,
  CAMPAIGNS_REPORT_GET: (id) => `/campaigns/${id}/report`,
  CAMPAIGNS_COPY: "/campaigns/copyCampaign",
  CAMPAIGN_UPDATE_CREATOR_STATS: "/campaigns/updateCreatorStats",
  CAMPAIGNS_DETAILS: (id) => `/campaigns/${id}/details`,
  CAMPAIGNS_SHARE: (id) => `/campaigns/${id}/share`,
  CAMPAIGNS_ADMIN: "/admin/campaigns",
  CAMPAIGNS_ADMIN_KPI: "/admin/campaigns/kpi",
  CAMPAIGNS_TIMELINE: (id) => `/campaigns/${id}/timeline`,
  CAMPAIGNS_TIMELINE_BY_CREATOR: (campaignId, creatorId) => `/campaigns/${campaignId}/creator/${creatorId}/timeline`,
  CAMPAIGNS_ADDBONUS: (id) => `/campaigns/${id}/add_bonus`,
  UPDATE_CREATOR_LIST: "/campaigns/updateCreatorList",
  UPDATE_MANAGER: "/campaigns/updateManager",
  UPDATE_CAMPAIGN: "/campaigns/updateStatus",
  DELETE_CAMPAIGN: "/campaigns/delete",
  COMPLETE_CAMPAIGN: "/campaigns/completeCampaign",
  COMPLETE_CREATOR: (campaignId) => `/campaigns/completeCreator/${campaignId}`,
  COMPLETE_VIDEO: (campaignId) => `/campaigns/completeVideo/${campaignId}`,

  EXPORT_SHEET: "/export_to_sheets",
  EXPORT_SHEET_CREATORS: "/export_creator_data",

  CREATOR_PAYOUT_READ: "/sheets/creatorPayoutInfo/read",
  CREATOR_DATA_READ: "/sheets/creatorData/read",
  CREATOR_ADD: "/creators/add",
  CREATOR_SIGNUP: "/creators/signup",
  CREATOR_UPDATE: "/creators/update",
  CREATOR_EMAILS: "/creators/emails",
  CREATOR_DELETE: "/creators/delete",
  CREATOR_SOFT_DELETE: "/creators/soft_delete",
  INVOICES: "/invoices",
  INVOICE_FETCH: (invoiceId) => `/invoice/${invoiceId}`,
  EDIT_INVOICE: "/invoices/editInvoice",
  INVOICE_CONFIRM: "/invoices/confirm",
  CREATOR_INVOICES: "/incoming_invoices",
  PENDING_INVOICES: (userId) => `/invoices/pending_total?user_id=${userId}`,
  CREATORS: "/creators",
  CREATORS_PARTNERS: "/creators/partners",
  CREATORS_SPEC: (creatorId)=>`/creators/${creatorId}`,
  CREATOR_AVERAGE_REVIEW: (creatorId) => `/creators/${creatorId}/average_review`,
  CREATOR_REVIEW: (creatorId) => `/creators/${creatorId}/reviews`,
  
  CREATORS_KPI: "/creators/kpi",
  CREATORS_MANAGER: (agencyid)=>`/creators/manager/${agencyid}`,
  COMPANY: "/company",
  USERS_COMPANY: "/company/companyusers",
  DELETE_COMPANY: "/company/delete",
  FETCH_COMPANY_DATA: "/company/companydata",
  COMPANY_INVOICES: (companyId) => `/company/${companyId}/invoices`,

  EDIT_CAMPAIGN_ADMIN: "/admin/campaign/edit",
  DELETE_CAMPAIGN_ADMIN: "/admin/campaign/delete",
  INVOICES_ADMIN: "/admin/invoices",
  CREATOR_INVOICES_ADMIN: "/admin/invoices_creator",
  INVOICE_KPI: "/admin/invoices/kpi",
  PAYOUTS_ADMIN: "/admin/payouts",
  PAYOUTS_EDIT_ADMIN: "/admin/payouts/edit",
  DELETE_PAYOUTS_ADMIN: "/admin/payouts/delete",
  PAYOUTS: "/payouts",
  PAYOUT_DELETE: "/payouts/delete",
  DELETE_INVOICE: "/invoices/delete",
  CREATE_INVOICE: "/invoices/create",
  EDIT_INVOICES_ADMIN: "/admin/invoice/edit",
  WITHDRAWALS_ADMIN: "/admin/withdrawals",
  WITHDRAWALS_SPEC_ADMIN: (id)=>`/admin/withdrawals/${id}`,
  CREATE_BATCH_PAYOUT: "/payouts/batch",

  CREATORCONNECT_LOGIN: '/creatorUsers/login',
  CREATORCONNECT_DASHBOARD: "/creatorUsers/dashboard",
  CREATORCONNECT_INTERNALLOGIN: '/creatorUsers/internal_login',
  CREATORCONNECT_ADD: '/creatorUsers/add',
  CREATORCONNECT_REAUTH: "/creatorUsers/reauth",
  creatorConnectCampaigns: "/creatorUsers/campaigns",
  CREATORCONNECT_EDIT: "/creatorUsers/edit",
  CREATORCONNECT_ONBOARD: "/creatorUsers/onboard",
  CREATORCONNECT_ONBOARD_SERVICE: "/creatorUsers/onboard_with_service",
  CREATORCONNECT_EDIT_STRIPE: "/creatorUsers/stripe",
  CREATORCONNECT_GOOGLE: "/creatorUsers/google",
  CREATORCONNECT_GET: "/creatorUsers/creator",
  CREATORCONNECT_EMAILS: "/creatorUsers/emails",
  CREATORCONNECT_PAYOUTS: "/creatorUsers/payouts",
  CREATORCONNECT_FINANCIALS: "/creatorUsers/financials",
  CREATORCONNECT_PARTNERSHIPS: "/creatorUsers/partnerships",
  CREATORCONNECT_PARTNERSHIPS_SINGLE: (partnershipid)=>`/creatorUsers/partnerships/${partnershipid}`,
  CREATORCONNECT_CONTACTS: "/crm/contacts",
  CREATORCONNECT_USERS_BY_COMPANY: "/creatorUsers/users_by_company",
  CREATORCONNECT_CREDX_CREDITS: "/credx/credits",
  CREATORCONNECT_CREDX_PITCH: "/credx/send_pitch_email",
  CREATORCONNECT_CREDX_SUBSTRACT: "/credx/subtract_credits",
  CREATORCONNECT_ZAPFEED: "/creatorUsers/fetchzapfeed",
  CREATORCONNECT_NEGOTIATE_CAMPAIGN: (campaignId) => `/creatorUsers/campaigns/${campaignId}/negotiate`,

  CREATORCONNECT_ADMIN_EMAIL: '/email_analysis',
  CREATORCONNECT_ADMIN_EMAIL_KEYWORDS: '/email_analysis/keywords',
  CREATORCONNECT_ADMIN_EMAIL_GET: (email) => `/email_analysis/${email}`,

  CREATORCONNECT_MISC: "/creatorUsers/misc_projects_by_creator",
  CREATORCONNECT_INVOICE: (id) => `/creatorUsers/creator-invoice/${id}`,
  CREATORCONNECT_INVOICE_CREATE: '/creatorUsers/create_invoice',
  CREATORCONNECT_STATUS_CAMPAIGN: (campaignId) => `/creatorUsers/campaigns/${campaignId}/accept`,

  CONVERSATIONS_CREATE_CAMPAIGN: "/conversations/campaigns",
  CONVERSATIONS_CREATE_PARTNERSHIP: "/conversations/partnerships",
  CONVERSATIONS_CHECK_CAMPAIGN: "/conversations/check/campaigns",
  CONVERSATIONS_CHECK_PARTNERSHIP: "/conversations/check/partnerships",
  CONVERSATIONS_CHECK_USER_TO_USER: '/conversations/check/user_to_user',  
  CONVERSATIONS_CHECK_USER_TO_CREATOR: '/conversations/check/user_to_creator',  
  
  CONVERSATIONS_GET_USER_TO_USER: '/conversations/user/sent_conversations',

  CONVERSATIONS_SHARE_USER_TO_USER: (conversationId) =>
    `/conversations/user_conversations/${conversationId}/share`,
  CONVERSATIONS_SHARE_BY_EMAIL_USER_TO_USER: (conversationId) =>
    `/conversations/user_conversations/${conversationId}/invite_by_email`,
  USER_CONVERSATION_MESSAGES: (conversationId) =>
    `/conversations/user_conversations/${conversationId}/messages`,
  USER_CONVERSATION_CHECK_OR_CREATE: '/conversations/user_conversations/check_or_create',
  CONVERSATIONS_GET_BY_CAMPAIGN_AND_CREATOR: (campaignId, creatorId) => `/conversations/campaigns/${campaignId}/creator/${creatorId}/conversation`,
  FETCH_CREATOR_CONVERSATIONS: '/conversations/creator/conversations',  
  CONVERSATIONS_UNREAD_COUNT_FOR_CAMPAIGN_CREATOR: (campaignId, creatorId) =>
    `/conversations/campaign/${campaignId}/creator/${creatorId}/unread_count`,
  CONVERSATIONS_UPDATE_NAME_USER_TO_USER: (conversationId) =>
    `/conversations/user_conversations/${conversationId}/update_name`,
  MESSAGES: (id)=>`/conversations/messages/${id}`,
  DELETE_MESSAGES: (id)=>`/conversations/messages/delete/${id}`,

  CREDIT_FETCH: (companyId) => `/bank/credit/ledger/${companyId}`,
  BANK_CREDIT_LEDGER_UPDATE: "/bank/credit/ledger/update",
  BANK_CREDIT_REQUEST: "/bank/credit/request",
  BANK_CREDIT_REQUEST_APPROVE: "/bank/credit/approve",

  BANK_SYSTEM_LIQUID: "/bank/system/liquidity",

  PARTNERSHIPS: "/projects/partnerships",
  PARTNERSHIPS_PUBLIC: "/projects/public/partnerships",
  PARTNERSHIPS_CREATE: "/projects/partnerships/create",
  PARTNERSHIPS_FETCH: "/projects/partnerships",
  UPDATE_PARTNERSHIP_STATUS: "/projects/partnerships", // Updated
  DELETE_PARTNERSHIP: "/projects/partnerships", // Updated
  PARTNERSHIP_DELIVERABLES: (partnershipId, creatorId) => `/deliverables/getByPartnershipAndCreator/${partnershipId}/${creatorId}`,
  COMMISSIONS: "/projects/commissions",
  DELIVERABLES: "/deliverables",
  CREATE_DELIVERABLES: "/deliverables/create",
  UPDATE_DELIVERABLES: "/deliverables/update",
  GET_DELIVERABLE_PARTNERSHIPS: "/deliverables/getByPartnership",
  MISC_PROJECTS: "/projects/misc_projects/fetch",
  MISC_PROJECTS_CREATE: "/projects/misc_projects/create",
  REQUEST_ACCESS_CRM: "/commissions/referrals/create",
  USER_ADMIN_FETCH: "/admin/users/details_with_credits",
  CREATOR_USER_ADMIN_FETCH: "/admin/creator_users/details_with_credits",
  USER_ADMIN_EDIT: "/admin/users/edit_with_credits",
  CREATOR_CREDITS_ADMIN_EDIT: "/admin/creator_users/edit_with_credits",

  ADMIN_PARTNERSHIPS: "/admin/partnerships",
  COMMISSIONS_All: "/commissions/referrals",
  FETCH_SIMILAR: "/creators",
  CRM_ADD: "/crm/add",
  CRM_UPDATE: "/crm/sendupdates",
  CRM_UPDATE_CONTACTS: "/crm/updateContacts",
  CRM_UPDATE_CONTACT_STATUS: "/crm/updateContactStatus",
  CRM_CREATOR_UPDATE_STATUS: "/crm/creator/update_status",
  CRM_CREATOR_CREATORS: "/crm/creator/creators",
  CRM_CREATOR_SPEC:  (creatorId) => `/crm/creator/${creatorId}`,
  CRM_IGDM_CREATORS: "/crm/igdm/creators",
  CRM_CONTACTS: "/crm/contacts",
  CREDX_CREDITS: "/credx/credits",
  SUBTRACT_CREDITS: "/credx/subtract_credits",
  TWILIO_SEND_CREATOR_SMS: '/sendCreatorSMS',
  FETCH_MESSAGES: '/admin/textmessages/fetch',
  DEMO_REGISTER: "/company/registerdemo",
  SHARED_WITH_ADMIN: "/admin/users/shared-emails",
  CREATE_PAYPAL: "/invoices/create_payment",
  UPDATE_CREATORS_STATS: "/campaigns/updateCreatorStats",
  POST_FILES: "/files/upload",
  FETCH_FILES: "/files",
  CREATE_ASSET: "/files/create_asset",
  CREATE_CONTRACT: "/files/create_contract", // New endpoint
  FETCH_CONTRACTS: "/files/contracts",       // New endpoint
  SPEC_FILE: (id) => `/files/${id}`,
  SPEC_FILE_UPDATE: (id) => `/files/edit/${id}`,

  SPEC_CONTRACT: (contract_id)=>`/files/contracts/${contract_id}`,

  UPDATE_CREATOR_CAMPAIGN_SPEC: "/campaign_handler/updateCampaignCreatorSpecs",
  UPDATE_META_DATA: "/campaign_handler/campaign/edit",
  CAMPAIGNS_SIMILAR: "/campaigns/createSimilar",
  CREATOR_NEGOTIATION: (campaign_id) => 
    `/campaign_handler/campaigns/${campaign_id}/negotiation/response`,
  RESEND_OFFER: (campaign_id) => 
    `/campaign_handler/campaigns/${campaign_id}/resend_offer`,
  
  CREATE_TRACKERS: "/trackers/create",
  FETCH_TRACKERS: "/trackers/fetch",
  UPDATE_TRACKERS: "/trackers/update",
  CREATOR_ADMIN_FETCH : "/admin/creators",
  post_creator_pfp :"/files/upload_onboard_pfp",

  AGENCY_CREATOR: (creatorId) => `/agency/creator/${creatorId}`,
  AGENCY_ZAPFEED: '/agency/zapfeed',
  AGENCY_DASHBOARD: '/agency/dashboard',
  AGENCY_CAMPAIGN_CREATOR_ACCEPT: (campaignId) => `/agency/campaigns/${campaignId}/accept`,
  AGENCY_CAMPAIGN_CREATOR_UPDATE_FIELDS: (campaignId) => `/agency/campaigns/${campaignId}/update_fields`,
  AGENCY_ROSTER_SETTINGS: (agencyName) => `/agency/${agencyName}/rosterSettings`,
  PUBLIC_AGENCY_ROSTER_SETTINGS: (agencyName) => `/agency/publicRosterSettings/${agencyName}`,
  AGENCY_CAMPAIGN_NEGOTIATE: (campaignId) => `/agency/campaigns/${campaignId}/negotiate`,
  UPDATE_CLIENT :(clientid)=>`/admin/clients/${clientid}`,
  EXPORT_SHEET_TRACKER: "/trackers/export_to_sheets",
  // Social API Endpoints
  SOCIAL_FOLLOW: "/social/follow",
  SOCIAL_UNFOLLOW: "/social/unfollow",
  FETCH_FOLLOWERS: "/social/followers", // To fetch followers for a specific user
  FETCH_FOLLOWING: "/social/following", // To fetch following for a specific user
  SOCIAL_IS_FOLLOWING: '/social/is_following',

  CAMPAIGNS_HISTORY: (campaignId) => `/campaign_handler/history/${campaignId}`,
  CAMPAIGNS_CREATOR_HISTORY: (campaignId, creatorId) =>  `/campaign_handler/creator_history/${campaignId}/${creatorId}`,
  CAMPAIGNS_CLIENT: (campaignId) => `/campaign_handler/campaigns/${campaignId}/client`,
  CAMPAIGNS_CLIENT_FETCH: (campaignId) => `/campaign_handler/campaigns/${campaignId}/client/fetch`,
  CAMPAIGNS_OPTOUT: (campaignId) => `/campaigns/${campaignId}/optout`,

  INTERNAL_EMAIL_CAMPAIGNS_CREATE: "/email_campaigns/create",
  INTERNAL_EMAIL_CAMPAIGNS: "/email_campaigns",
  CREATORCONNECT_COMMISSIONS: "/creatorUsers/commissions",
  BLOCKLIST: "/blocklist",
  BLOCKED_CONTACT: (email) => `/blocklist/${email}`,
  CASTING_CALLS_LIST: "/castingcalls/list",
  CASTING_CALLS_UPDATE_STATUS: "/castingcalls/updateStatus",
  CASTING_CALLS_POST_NEW: "/castingcalls/postNew",
  creatorCastingCalls: "/castingcalls/active",
  CASTING_CREATOR_APPLY: '/castingcalls/apply',
  CASTING_CALL_SPEC: (id) => `/castingcalls/${id}`,
  CASTING_CALL_INVITE: (id) => `/castingcalls/invite/${id}`,

  collections: '/collections/list',
  collections_post: '/collections/post',
  collections_delete: (id) => `/collections/${id}`,
  CREATOR_SEARCH: '/collections/search',
  collectionsPopular: '/collections/popular',

  CREATORCONNECT_UPDATE_FIELDS: (campaignId) => `/creatorUsers/campaigns/${campaignId}/update_fields`,
  CREATORCONNECT_CONFIRM_PAYOUT: "/creatorUsers/payouts/confirm",
  PARTNERSHIPS_SHARE: (id) => `/projects/partnerships/${id}/share`,
  POST_FILES_CREATOR: "/files/postCreatorFile",

  DEAL_IOS: '/deals_io', // Endpoint for listing and creating Deal IOs
  DEAL_IO_DETAIL: (dealIOId) => `/deals_io/${dealIOId}`, // Endpoint for specific Deal IO
  DEAL_IO_COPY: (dealIOId) => `/deals_io/${dealIOId}/copy`, // Endpoint for copying a Deal IO
  DEAL_IO_SHARE: (dealIOId) => `/deals_io/${dealIOId}/share`, // New endpoint for sharing a Deal IO
  CREATOR_DEAL_FETCH: '/deals_io/creators/fetch', // Endpoint for fetching deals for creators
  CREATOR_DEAL_IO_DETAIL: (dealIOId) => `/deals_io/creators/${dealIOId}`, // Endpoint for specific Deal IO
  INVOICE_ACTION: (invoiceId) => `/invoice_action/${invoiceId}`, // Endpoint for approving/declining an invoice
  CLIENT_INVOICE_ACTION: (invoiceId) => `/client_invoice_action/${invoiceId}`, // Endpoint for approving/declining an invoice
  DEVELOPER_SERVICE: '/thirdparty/services',
  DEVELOPER_KEY: '/thirdparty/apikeys',

  DEVELOPER_SERVICE_PUBLIC_SPEC: (id)=>`/apps/${id}`,
  DEVELOPER_SERVICE_PUBLIC: "/apps",
  DEVELOPER_SERVICE_SUBBED: (id)=>`/apps/${id}/subscription`,
  DEVELOPER_SERVICE_LOG: (id)=>`/apps/${id}/log`,
  DEVELOPER_SERVICE_UPDATE: (id)=>`/apps/${id}/update`,

  DEVELOPER_SERVICE_SPEC: (id)=>`/thirdparty/services/${id}`,
  DEVELOPER_KEY_SPEC: (id)=>`/thirdparty/apikeys/${id}`,
  AGENCY_PAYOUT_APPROVE: (payoutId) => `/agency/payouts/${payoutId}/approve`,
  AGENCY_PAYOUTS: '/agency/payouts',
  CREATORCONNECT_VERIFY: '/creatorUsers/creator/verify',
  // Plaid endpoints (existing)
  PLAID_CREATE_LINK_TOKEN_CREATOR: '/bank/plaid/create_link_token/creator',
  PLAID_CREATE_LINK_TOKEN_USER: '/bank/plaid/create_link_token/user',
  PLAID_LINK_CREATOR: '/bank/plaid/link/creator',
  PLAID_LINK_USER: '/bank/plaid/link/user',
  PLAID_TRANSACTIONS: '/bank/plaid/transactions',
  PLAID_SEND_TRANSFER: '/bank/plaid/send_transfer',

  // Stripe endpoints (NEW)
  STRIPE_GET_BANK_ACCOUNTS: '/bank/creator/stripe/bank_accounts',
  STRIPE_LINK: '/bank/creator/stripe/link',
  STRIPE_CREATOR_SETUP_INTENT: '/bank/creator/stripe/setup_intent',
  CREATOR_AUTHORIZED: "/admin/creator_users/sender_emails",
  CREATOR_AUTHOR_SEND: "/admin/creator_users/send_bulk_email",
  CREATOR_AUTHOR_SEND_FETCH: "/admin/creator_users/email_blasts",
  LIST_SURVEYS: '/survey',                            
  CREATE_SURVEY: '/survey',                            
  LIST_PENDING_SURVEY: (userId) => `/survey/pending/${userId}`,  
  CREATE_SURVEY_RESPONSE: '/survey/responses',       
  UPDATE_SURVEY: (surveyId) => `/survey/${surveyId}`, 
  DELETE_SURVEY: (surveyId) => `/survey/${surveyId}`,
  GET_SURVEY_RESPONSES: (surveyId) => `/survey/responses/${surveyId}`, // New endpoint

  LIST_BLITZBOLTS: '/blitzbolts',
  CREATE_BLITZBOLT: '/blitzbolts',
  LIST_BLITZBOLTS_FOR_USER: (userId) => `/blitzbolts/for_user/${userId}`,  // New endpoint
  // New endpoint to fetch a specific achievement for a given user & attainment ID
  GET_BLITZBOLT_ACHIEVEMENT: (userId, attainmentId) => `/blitzbolts/${userId}/${attainmentId}`,
  SHARE_BLITZBOLT: (userId, attainmentId) => `/blitzbolts/${userId}/${attainmentId}`, // Added this line

  BIO: "/bio/",
  // For public fetch of a bio page (no auth required)
  PUBLIC_BIO: "/bio/fetch"

};


