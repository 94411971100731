import React, { useEffect} from 'react';
import {
  Card,
} from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import BookingForm from '../../../../Components/BookingForm';
import routes from '../../../../Config/routes';

const InternalBookingPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  // The creator data passed in via router state
  const creatorData = state?.creator;

  if (!creatorData) {
    return (
      <Navigate to={routes.search} />
    );
  }

  return (
    <Card sx={{ overflow: 'clip', p: { xs: 2, md: 3 }, maxWidth: 900, mx: 'auto' }}>
      <BookingForm creator={creatorData} onClose={()=>navigate(-1)} />
    </Card>
  );
};

export default InternalBookingPage;
