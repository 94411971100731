import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Button,
  Skeleton,
  styled,
  alpha
} from '@mui/material';
import {
  Visibility as ViewIcon,
  ChatBubbleOutline as ChatIcon
} from '@mui/icons-material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import API from '../../../API';
import ChatModal from '../Users/chatmodal';
const rowCount = 5

/** 
 * Reusable styled components 
 */
const FeedItem = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: '16px',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  background: `linear-gradient(
    145deg,
    ${alpha(theme.palette.background.paper, 0.8)},
    ${alpha(theme.palette.background.default, 0.2)}
  )`,
  backdropFilter: 'blur(10px)',
}));

const FeedAvatar = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[2],
}));

/**
 * Weave the feeds in a fixed pattern, assuming we only repeat it once:
 * - 1 zap feed item
 * - 1 top user
 * - 2 zap feed items
 * - 1 top user
 * - 2 zap feed items
 */
function weaveFeeds(events = [], topUsers = [], page = 0) {
  // Define the pattern: [1 event, 1 top user, 2 events, 1 top user, 2 events]
  const pattern = [1, 'user', 2, 'user', 2];
  const result = [];
  let eventIndex = 0;
  let topUserIndex = page * 2; // Start offset for top users

  // Build the result array based on the pattern
  for (const step of pattern) {
    if (eventIndex >= events.length)
      break;
    if (step === 'user') {
      // Add a top user, looping back to the start if we run out
      const topUser = topUsers[topUserIndex % topUsers.length];
      if (topUser) {
        result.push({ type: 'user', data: topUser });
        topUserIndex++;
      }
    } else {
      // Add events according to the current step
      const eventsToAdd = events.slice(eventIndex, eventIndex + step);
      eventsToAdd.forEach(event => {
        result.push({ type: 'event', data: event });
      });
      eventIndex += step;
    }
  }
  return result;
}

/**
 * Helper to compute the "Blitz Score" for a user
 */
const computeBlitzScore = (user) => {
  const campaignWeight = 1;
  const partnershipWeight = 1.5;
  const payoutWeight = 2;
  return (
    (user.campaign_count * campaignWeight) +
    (user.partnership_count * partnershipWeight) +
    (user.payout_count * payoutWeight)
  );
};

const UnifiedUserFeed = () => {
  const navigate = useNavigate();
  const [zaapPage, setZaapPage] = useState(0);

  // Chat modal state
  const [chatOpen, setChatOpen] = useState(false);
  const [targetUserId, setTargetUserId] = useState(null);

  /**
   * 1) Query: Top Users
   */
  const {
    data: topUsers,
    isLoading: isTopUsersLoading,
    error: topUsersError,
  } = useQuery({
    queryKey: ['users', 'topUsers'],
    queryFn: API.users.topUsers,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
    initialData: [],
    initialDataUpdatedAt: 0,
  });

  const sortedTopUsers = useMemo(() => (topUsers || []).filter(u => u.total_projects > 0).sort((a, b) => {
    const scoreA = computeBlitzScore(a);
    const scoreB = computeBlitzScore(b);
    return scoreB - scoreA; // descending
  }), [topUsers])

  /**
   * 2) Query: Zap Feed (recent user activities sorted by the server in descending order)
   */
  const {
    data: zapFeed,
    isLoading: isZapFeedLoading,
    error: zapFeedError,
  } = useQuery({
    queryKey: ['user', 'zapFeed', { page: zaapPage, rows: rowCount }],
    queryFn: () => API.users.fetchZapFeed({ page: zaapPage, rows: rowCount }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
    initialData: [],
    initialDataUpdatedAt: 0,
    enabled: !!topUsers,
  });

  const wovenFeed = weaveFeeds(zapFeed?.zapfeed || [], sortedTopUsers, zaapPage);

  /**
   * Handlers
   */
  const handleViewUser = (userId) => {
    navigate(`/user/${userId}`);
  };

  const handleChatWithUser = (userId) => {
    setTargetUserId(userId);
    setChatOpen(true);
  };

  const handleShowNewer = () => {
    setZaapPage(prev => prev - 1);
  };
  const handleShowOlder = () => {
    setZaapPage(prev => prev + 1);
  };

  /**
   * Error / loading states
   */
  if (topUsersError || zapFeedError) {
    return (
      <Box sx={{ mb: 4, p: 2, border: '1px solid red', borderRadius: 2 }}>
        <Typography color="error">
          Error loading feed:
          {topUsersError && ` TopUsers - ${topUsersError?.response?.data?.error || topUsersError.message}`}
          {zapFeedError && ` ZapFeed - ${zapFeedError?.response?.data?.error || zapFeedError.message}`}
        </Typography>
      </Box>
    );
  }

  const isLoadingAny = isTopUsersLoading || isZapFeedLoading;
  const noData = !isLoadingAny && wovenFeed.length === 0;

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
        User feed
      </Typography>

      {/* Loading skeletons */}
      {isLoadingAny && (
        <List sx={{ p: 0 }}>
          {Array.from({ length: 3 }).map((_, i) => (
            <FeedItem key={i} elevation={0}>
              <Skeleton variant="rectangular" width="100%" height={80} />
            </FeedItem>
          ))}
        </List>
      )}

      {/* No data case */}
      {noData && (
        <Box sx={{ mb: 4, p: 2, textAlign: 'center' }}>
          <Typography variant="h6" color="text.secondary">
            No data found. Keep up the hustle!
          </Typography>
        </Box>
      )}

      {/* Render woven feed items */}
      <List sx={{ p: 0 }}>
        {wovenFeed.map((item, index) => {
          if (item.type === 'user') {
            // This is a "Top User"
            const user = item.data;
            const blitzScore = computeBlitzScore(user);

            return (
              <FeedItem key={`user-${user.user_id}-${index}`} elevation={0}>
                <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <FeedAvatar
                      alt={`${user.first_name} ${user.last_name}`}
                      src={user.pfphref || '/default-avatar.png'}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="subtitle2" color="text.primary">
                          {user.first_name} {user.last_name}
                        </Typography>
                        {user.company_name && (
                          <Typography variant="caption" color="text.secondary">
                            {user.company_name}
                          </Typography>
                        )}
                      </>
                    }
                    secondary={
                      <span>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          component="span"
                          sx={{ marginInlineEnd: 1 }}
                        >
                          Total Projects: {user.total_projects}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          component="span"
                        >
                          Blitz Score: {blitzScore.toFixed(1)}
                        </Typography>
                      </span>
                    }
                    sx={{ m: 0 }}
                  />
                </ListItem>

                {/* Action buttons */}
                <Box sx={{ display: 'flex', gap: 1, mt: 1.5 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ViewIcon />}
                    sx={{ borderRadius: 20 }}
                    onClick={() => handleViewUser(user.user_id)}
                  >
                    View Profile
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ChatIcon />}
                    sx={{ borderRadius: 20 }}
                    onClick={() => handleChatWithUser(user.user_id)}
                  >
                    Chat with user
                  </Button>
                </Box>
              </FeedItem>
            );
          } else {
            // This is an "Activity"
            const data = item.data;
            const date = new Date(data.activity_date).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
            });

            let description = '';
            switch (data.activity_type) {
              case 'Payout':
                description = `Sent a BlitzPay of $${data.activity_amount}`;
                break;
              case 'Campaign':
                description = `Launched "${data.activity_name}" campaign`;
                break;
              case 'Partnership':
                description = `Started a new partnership with ${data.activity_partner}`;
                break;
              default:
                description = 'Had some activity...';
            }

            return (
              <FeedItem key={`activity-${index}`} elevation={0}>
                <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <FeedAvatar alt={data.user.first_name} src={data.user.phphref} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle2" color="text.primary">
                          {`${data.user.first_name} ${data.user.last_name}`}
                        </Typography>
                        {data.user.company_name && (
                          <Typography variant="caption" color="text.secondary">
                            {data.user.company_name}
                          </Typography>
                        )}
                        <Typography variant="caption" color="text.secondary">
                          •
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {date}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Typography
                        variant="body1"
                        sx={{ mt: 0.5, color: 'text.primary', lineHeight: 1.4 }}
                      >
                        {description}
                      </Typography>
                    }
                    sx={{ m: 0 }}
                  />
                </ListItem>

                {/* Action buttons */}
                <Box sx={{ display: 'flex', gap: 1, mt: 1.5 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ViewIcon />}
                    sx={{ borderRadius: 20 }}
                    onClick={() => handleViewUser(data.user.id)}
                  >
                    View User
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ChatIcon />}
                    sx={{ borderRadius: 20 }}
                    onClick={() => handleChatWithUser(data.user.id)}
                  >
                    Chat with user
                  </Button>
                </Box>
              </FeedItem>
            );
          }
        })}
      </List>

      {/* "Load More" */}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Button
          disabled={isLoadingAny || !zapFeed || zaapPage == 0}
          onClick={handleShowNewer}
          variant="contained"
        >
          Newer
        </Button>
        <Button
          disabled={isLoadingAny || !zapFeed || zaapPage == Math.floor(zapFeed.total / rowCount)}
          onClick={handleShowOlder}
          variant="contained"
          color='secondary'
        >
          Older
        </Button>
      </Box>

      {/* Chat Modal */}
      <ChatModal
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        recipientId={targetUserId}
      />
    </Box>
  );
};

export default UnifiedUserFeed;
