import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { useQuery } from "react-query";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import html2canvas from "html2canvas";
import client from "../../../API";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Upgraded icon map with styled icons
const iconMap = {
  Star: (
    <StarIcon
      fontSize="large"
      sx={{
        color: "#FFD700",
        filter: "drop-shadow(0 0 8px rgba(255,215,0,0.5))",
      }}
    />
  ),
  EmojiEvents: (
    <EmojiEventsIcon
      fontSize="large"
      sx={{
        color: "#FFD700",
        filter: "drop-shadow(0 0 8px rgba(255,215,0,0.5))",
      }}
    />
  ),
  MilitaryTech: (
    <MilitaryTechIcon
      fontSize="large"
      sx={{
        color: "#FFD700",
        filter: "drop-shadow(0 0 8px rgba(255,215,0,0.5))",
      }}
    />
  ),
};

// Ribbon component reused from BlitzBoltAttainment
const Ribbon = ({ achieved }) => (
  <Box
    sx={{
      position: "absolute",
      top: -10,
      right: -10,
      width: 100,
      height: 100,
      overflow: "hidden",
      "&:before": {
        content: '""',
        position: "absolute",
        width: 140,
        height: 30,
        backgroundColor: achieved ? "#ffd700" : "#666",
        transform: "rotate(45deg)",
        top: 35,
        right: -35,
        boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        width: 140,
        height: 30,
        backgroundColor: achieved ? "#ffd700" : "#666",
        transform: "rotate(-45deg)",
        top: 35,
        left: -35,
        boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
      },
    }}
  >
    <Typography
      variant="caption"
      sx={{
        position: "absolute",
        zIndex: 1,
        color: achieved ? "#000" : "#fff",
        fontWeight: "bold",
        top: 22,
        left: "50%",
        transform: "translateX(-50%)",
        textShadow: achieved ? "none" : "0 1px 2px rgba(0,0,0,0.3)",
      }}
    >
      {achieved ? "ACHIEVED" : "IN PROGRESS"}
    </Typography>
  </Box>
);

const BlitzBoltsShowcase = ({ userId, limit = 4 }) => {
  const { data: blitzData, isLoading, error } = useQuery(
    ["blitzbolts-for-user", userId],
    () => client.blitzbolts.listForUser(userId),
    {
      enabled: Boolean(userId),
      refetchOnWindowFocus: false,
    }
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBolt, setSelectedBolt] = useState(null);
  const [caption, setCaption] = useState("");
  const [capturedImage, setCapturedImage] = useState(null);
  const cardRef = useRef(null);

  const visibleBolts = blitzData?.slice(0, limit) || [];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(visibleBolts.length, 3),
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 960, settings: { slidesToShow: Math.min(visibleBolts.length, 2) } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
    ],
  };

  // Function to capture the image on demand
  const captureImage = async () => {
    if (!cardRef.current) return null;
    try {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for render
      const canvas = await html2canvas(cardRef.current, {
        useCORS: true,
        scale: 2,
        backgroundColor: null,
        width: cardRef.current.offsetWidth,
        height: cardRef.current.offsetHeight,
      });
      const imageData = canvas.toDataURL("image/png");
      setCapturedImage(imageData); // Update state for preview
      return imageData;
    } catch (err) {
      console.error("Error capturing image with html2canvas:", err);
      return null;
    }
  };

  useEffect(() => {
    if (openDialog && selectedBolt && cardRef.current && !capturedImage) {
      captureImage(); // Capture image for preview when dialog opens
    }
  }, [openDialog, selectedBolt]);

  const handleCardClick = (bolt) => {
    setSelectedBolt(bolt);
    setCaption("");
    setCapturedImage(null); // Reset image for new capture
    setOpenDialog(true);
  };

  const handleShareLinkedIn = async () => {
    const attainmentId = selectedBolt?.attainment?.id;
    if (!attainmentId) {
      console.error("Missing attainment ID for sharing");
      return;
    }

    let imageData = capturedImage;
    if (!imageData) {
      imageData = await captureImage(); // Capture image if not already captured
    }

    const shareUrl = client.blitzbolts.shareUrl(userId, attainmentId);
    const captionText = encodeURIComponent(`${caption}\n\n${shareUrl}`);
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      shareUrl
    )}&title=${encodeURIComponent(selectedBolt.title)}&summary=${captionText}`;
    window.open(linkedInShareUrl, "_blank");
  };

  const handleDownloadPNG = async () => {
    let imageData = capturedImage;
    if (!imageData) {
      imageData = await captureImage(); // Capture image if not already captured
    }
    if (!imageData) return;

    const link = document.createElement("a");
    link.download = `${selectedBolt?.title || "blitzbolt"}.png`;
    link.href = imageData;
    link.click();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedBolt(null);
    setCapturedImage(null);
  };

  return (
    <>
      <Box sx={{ mt: 4, mx:4 }}>
        {isLoading && <Typography>Loading BlitzBolts...</Typography>}
        {!isLoading && error && <Typography>Error loading BlitzBolts: {error.message}</Typography>}
        {!isLoading && !error && visibleBolts.length === 0 && (
          <Typography>No BlitzBolts found</Typography>
        )}
        {!isLoading && !error && visibleBolts.length > 0 && (
          <Slider {...settings}>
            {visibleBolts.map((bolt) => {
              const achieved = bolt.attainment?.achieved;
              return (
                <Box key={bolt.id} sx={{ padding: "0 10px" }}>
                  <Box
                    sx={{
                      background: achieved
                        ? "linear-gradient(45deg, #6a11cb, #2575fc)"
                        : "linear-gradient(45deg, #616161, #424242)",
                      color: "#fff",
                      overflow: "hidden",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "translateY(-5px)", cursor: "pointer" },
                      boxShadow: achieved
                        ? "0 0 15px rgba(37, 117, 252, 0.6)"
                        : "0 0 8px rgba(0, 0, 0, 0.3)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      minHeight: 240,
                      borderRadius: 2,
                      p: 2,
                    }}
                    onClick={() => handleCardClick(bolt)}
                  >
                    <Box sx={{ mb: 1 }}>{iconMap[bolt.icon_name] || iconMap.Star}</Box>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {bolt.title}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {bolt.description}
                    </Typography>
                    {achieved ? (
                      <CheckCircleIcon sx={{ mt: 1, fontSize: 32, color: "#fff" }} />
                    ) : (
                      <DoNotDisturbIcon sx={{ mt: 1, fontSize: 32, color: "#ccc" }} />
                    )}
                  </Box>
                </Box>
              );
            })}
          </Slider>
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Share this BlitzBolt</DialogTitle>
        <DialogContent>
          {selectedBolt && (
            <Box
              ref={cardRef}
              sx={{
                background: selectedBolt.attainment?.achieved
                  ? "linear-gradient(45deg, #6a11cb, #2575fc)"
                  : "linear-gradient(45deg, #616161, #424242)",
                color: "#fff",
                p: 4,
                textAlign: "center",
                borderRadius: 4,
                width: "100%",
                minHeight: 400,
                boxShadow: "0 20px 40px rgba(0,0,0,0.3)",
                overflow: "hidden",
                display: capturedImage ? "none" : "block", // Hide after capture
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(45deg, transparent 40%, rgba(255,255,255,0.1) 50%, transparent 60%)",
                  opacity: selectedBolt.attainment?.achieved ? 0.3 : 0,
                  animation: "shimmer 3s infinite linear",
                },
                "@keyframes shimmer": {
                  "0%": { transform: "translateX(-100%)" },
                  "100%": { transform: "translateX(100%)" },
                },
              }}
            >
              <Ribbon achieved={selectedBolt.attainment?.achieved} />
              <Box sx={{ mb: 3 }}>
                {iconMap[selectedBolt.icon_name] || iconMap.Star}
              </Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: "900",
                  textShadow: "0 4px 8px rgba(0,0,0,0.3)",
                  letterSpacing: "1.5px",
                  mb: 3,
                  "&:after": {
                    content: '""',
                    display: "block",
                    width: "60px",
                    height: "3px",
                    background: "#fff",
                    mx: "auto",
                    mt: 2,
                    borderRadius: 2,
                  },
                }}
              >
                {selectedBolt.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 4,
                  fontSize: "1.1rem",
                  maxWidth: "80%",
                  mx: "auto",
                  lineHeight: 1.6,
                  textShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                {selectedBolt.description}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", gap: 4, mb: 3 }}>
                <Box
                  sx={{
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: 2,
                    p: 1.5,
                    minWidth: 100,
                    border: "2px solid rgba(255,255,255,0.1)",
                  }}
                >
                  <Typography variant="overline" sx={{ display: "block", opacity: 0.8 }}>
                    Points
                  </Typography>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {selectedBolt.points || 0}
                  </Typography>
                </Box>
              </Box>
              {selectedBolt.attainment?.achieved_at && (
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    mt: 2,
                    opacity: 0.8,
                    textShadow: "0 1px 2px rgba(0,0,0,0.2)",
                  }}
                >
                  Awarded on{" "}
                  {new Date(selectedBolt.attainment.achieved_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
              )}
            </Box>
          )}

          {capturedImage ? (
            <Box
              component="img"
              src={capturedImage}
              alt="Captured BlitzBolt"
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: 1, mb: 2 }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              Generating preview...
            </Typography>
          )}
          <TextField
            fullWidth
            label="Add a caption"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownloadPNG} variant="outlined">
            Download PNG
          </Button>
          <Button
            onClick={handleShareLinkedIn}
            variant="contained"
            color="primary"
            disabled={!selectedBolt?.attainment?.id} // Only disable if no attainment ID
          >
            Share to LinkedIn
          </Button>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BlitzBoltsShowcase;