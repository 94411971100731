import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  TextField,
  Stack,
  Chip,
  Card,
  Link
} from "@mui/material";
import client from "../../../../API";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import { useMutation, useQueryClient } from "react-query";
import useAlertDialog from "../../../../Components/useAlertDialog";
import AlertDialog from "../../../../Components/AlertDialog";
import useIsDesktop from "../../../../Hooks/useIsDesktop";
import CreatorNegotiateDialog from "./creatorcounterDialog";
import CreatorCampaignConversation from "./conversation";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

// Helper to split text and convert any URLs into clickable links.
const renderBriefWithLinks = (text) => {
  if (!text) return "";
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <Link key={index} href={part} target="_blank" rel="noopener">
          {part}
        </Link>
      );
    }
    return part;
  });
};

const CreatorCampaignPitchedDialog = ({
  openDialog,
  handleCloseDialog,
  campaignId,
}) => {
  const [campaign, setCampaign] = useState(null);
  const [openNegotiateDialog, setOpenNegotiateDialog] = useState(false);
  const [openChatPopup, setOpenChatPopup] = useState(false);
  const editedEvents = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const { creatorToken } = useCreatorAuth();
  const isDesktop = useIsDesktop();
  const queryClient = useQueryClient();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await client.campaigns.fetch(campaignId);
        if (openDialog) {
          editedEvents.current = false;
          setCampaign(data);
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
        handleCloseDialog();
      }
    };
    if (openDialog) fetchCampaignData();
    else editedEvents.current = false;
  }, [openDialog, campaignId, handleCloseDialog]);

  const creator = useMemo(() => {
    if (!campaign) return undefined;
    return campaign.creators.find(
      (c) => c.id === creatorToken.creator_user.username
    );
  }, [campaign, creatorToken.creator_user.username]);

  const currentRate = useMemo(
    () => creator?.agencyRate || creator?.price || 0,
    [creator]
  );

  const videoList =
    creator?.videos.filter((video) => video.parentId == null).map((video) => ({
      ...video,
    })) || [];

  const crosspostList =
    creator?.videos.filter((video) => video.parentId != null).map((video) => ({
      ...video,
    })) || [];

  const refreshCampaign = async () => {
    try {
      const data = await client.campaigns.fetch(campaignId);
      setCampaign(data);
    } catch (err) {
      console.error("Error refreshing campaign:", err);
    }
  };

  const { mutate: updateStatus, isLoading: updatingStatus } = useMutation(
    (input) => client.creatorConnect.campaignUpdateStatus(campaignId, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "campaigns",
          "creator",
          creatorToken.creator_user.username,
        ]);
        handleCloseDialog();
      },
    }
  );

  const handleChangeStatus = (accepted) => {
    closeDialog();
    updateStatus(
      { status: accepted ? "Accepted" : "Declined" },
      {
        onSuccess: () => {
          const alertTitle = accepted ? "Accepted Campaign" : "Rejected Campaign";
          const alertText = accepted
            ? "You have accepted to take part in this campaign. Good luck!"
            : "You have decided to reject this campaign. We hope to send campaigns more relevant to you in the future";
          openAlert(alertTitle, alertText, closeDialog, null, "Ok", null);
        },
        onError: (error) => {
          openAlert(
            "Error",
            `There was an error while changing your status: ${
              error.response?.data?.error || error.message
            }`,
            closeDialog,
            null,
            "Ok",
            null
          );
        },
      }
    );
  };

  const onClickOption = (accepted = false) => {
    const alertTitle = accepted ? "Accept Campaign" : "Reject Campaign";
    const alertText = accepted
      ? "You'll accept this campaign, be notified of upcoming due dates and receive messages from the campaign manager. Continue?"
      : "You'll reject this campaign. This action is not reversible, and the campaign will disappear from your dashboard. Continue?";

    openAlert(
      alertTitle,
      alertText,
      () => handleChangeStatus(accepted),
      closeDialog,
      "Continue",
      "Cancel"
    );
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={isLoading ? "sm" : "md"}
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
        PaperProps={{ elevation: 1, sx: { boxShadow: 24 } }}
      >
        {(isLoading || updatingStatus) && (
          <DialogContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <CircularProgress />
          </DialogContent>
        )}

        {!isLoading && !updatingStatus && (
          <>
            <Paper square elevation={2}>
              <DialogTitle>Accept this campaign?</DialogTitle>
            </Paper>

            <DialogContent dividers>
              <Typography variant="h5">
                Campaign Name: {campaign.name}
              </Typography>
              <Typography variant="h6">Campaign Brief:</Typography>
              <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                {renderBriefWithLinks(campaign.brief)}
              </Typography>
              <Typography variant="h6">
                Ideal due date: {formatIdealDueDate(campaign.ideal_duedate)}
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Box sx={{ paddingBlockEnd: 2, marginTop: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  marginBlockEnd={1}
                >
                  <Typography variant="subtitle2">
                    Promotions (x{videoList.length} video
                    {videoList.length === 1 ? "" : "s"}):
                  </Typography>
                  {videoList.map((video) => (
                    <Chip
                      key={`video-${video.id}`}
                      color="primary"
                      label={`${video.promotionPlatform} - ${video.promotionType}`}
                    />
                  ))}
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle2">Crossposts: </Typography>
                  {crosspostList.map((video) => (
                    <Chip
                      key={`cross-${video.id}`}
                      color="secondary"
                      label={`${video.promotionPlatform}`}
                    />
                  ))}
                </Stack>

                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="body1">Creator Brief</Typography>
                  <Card variant="outlined" sx={{ padding: 1 }}>
                    <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                      {renderBriefWithLinks(creator?.creatorBrief || "")}
                    </Typography>
                  </Card>
                </Box>
              </Box>
            </DialogContent>
          </>
        )}

        <Paper elevation={2} square>
          <DialogActions>
            {creator?.status !== "Counter" && (
              <Button
                variant="contained"
                onClick={() => onClickOption(true)}
                color="success"
              >
                Accept
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => setOpenChatPopup(true)}
              color="primary"
            >
              Chat with Brand
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenNegotiateDialog(true)}
              color="warning"
            >
              Negotiate
            </Button>
            <Button
              variant="contained"
              onClick={() => onClickOption(false)}
              color="error"
            >
              Reject
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>

      <CreatorNegotiateDialog
        open={openNegotiateDialog}
        handleClose={() => setOpenNegotiateDialog(false)}
        campaignId={campaignId}
        currentRate={currentRate}
        refreshCampaign={refreshCampaign}
        onSuccess={() => handleCloseDialog()}
      />

      {/* Chat with Brand Dialog */}
      <Dialog
        open={openChatPopup}
        onClose={() => setOpenChatPopup(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { height: "70vh" } }}
      >
        <DialogTitle>Chat with Brand</DialogTitle>
        <DialogContent
          dividers
          sx={{ p: 0, display: "flex", flexDirection: "column" }}
        >
          <CreatorCampaignConversation campaignId={campaignId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChatPopup(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <AlertDialog alertState={dialogState} />
    </>
  );
};

export default CreatorCampaignPitchedDialog;
