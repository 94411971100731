import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Button, TextField, FormControl, Paper, InputLabel, Select, MenuItem, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox
} from '@mui/material';
import Layout from '../layout';
import IGDMView from './igdm';
import { StyleButton, StyleContent, StyleStickyButtonContainter } from '../../../../../Utils/ThemedStyles';
import API from '../../../../../API';
import client from '../../../../../API';

const freeEmailDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'icloud.com', 'outlook.com'];

const isFreeEmail = (email) => {
  if (!email) return false;
  const domain = email.split('@')[1];
  return freeEmailDomains.includes(domain.toLowerCase());
};

const StyleActionBox = (theme) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
});

const StyleSearchBar = {
  minWidth: 120, 
};

const CreatorCRMView = () => {
  const [creatorCRMData, setCreatorCRMData] = useState([]);
  const [igdmData, setIgdmData] = useState([]);
  const [creatorDetailsData, setCreatorDetailsData] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [statusFilter, setStatusFilter] = useState('cold');
  const [newStatus, setNewStatus] = useState('EMAIL1');
  const [searchQuery, setSearchQuery] = useState('');
  // New state for follower filter
  const [minFollowers, setMinFollowers] = useState(100000);
  const [excludedEmails, setExcludedEmails] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  // *** FIX: Fetch excluded emails in the same function to ensure
  //          you filter with the fresh list, rather than waiting
  //          for state to update asynchronously.
  const fetchCreatorCrmData = async (filter) => {
    try {
      // Get excluded emails first
      const { emails: newExcludedEmails } = await API.creators.emails();
      
      let data = await API.crm.creator_creators();
      // Filter out excluded emails
      data = data.filter((creator) => !newExcludedEmails.includes(creator.email));

      if (filter !== 'all') {
        data = data.filter((creator) => {
          // If filtering for "cold", include creators with status "cold" or "coldCRM"
          const matchesStatus = filter === 'cold'
            ? (creator.status === 'cold' || creator.status === 'coldCRM')
            : (filter === '' || creator.status === filter);

          // Optional: If you only want free emails for "cold" or a specific filter, do it here.
          // If you want free emails for all statuses, move `isFreeEmail(creator.email)` outside this conditional.
          return (
            isFreeEmail(creator.email) &&
            matchesStatus &&
            parseInt(String(creator.following).replace(/,/g, ''), 10) >= minFollowers
          );
        });
      }
      
      // *** FIX: Case-insensitive search on the email
      if (searchQuery) {
        const lowerSearch = searchQuery.toLowerCase();
        data = data.filter((creator) => creator.email?.toLowerCase().includes(lowerSearch));
      }

      setCreatorCRMData(data);
      console.log('Fetched CRM data:', data);
    } catch (error) {
      console.error('Failed to fetch CRM data:', error);
    }
  };

  const fetchIgdmData = async () => {
    // Fetch your IGDM data as needed
    // Example:
    // const data = await API.crm.igdm();
    // setIgdmData(data);
  };

  const fetchCreatorDetailsData = async () => {
    console.error('Nonexisting endpoint /api/crm/creator/list');
    // If you had an endpoint, call it and setCreatorDetailsData(data).
  };

  // *** FIX: Ensure you call the right fetch functions for each tab
  useEffect(() => {
    if (tabValue === 0) {
      // "Email" tab
      fetchCreatorCrmData(statusFilter);
    } else if (tabValue === 1) {
      // "IGDM" tab
      fetchIgdmData();
    } else if (tabValue === 2) {
      // "ALL" tab => fetch all sets
      fetchCreatorCrmData('all');
      fetchIgdmData();
      fetchCreatorDetailsData();
    }
  }, [statusFilter, tabValue, searchQuery, minFollowers]);

  const handleSelect = (id) => {
    setSelectedCreators((prevState) =>
      prevState.includes(id)
        ? prevState.filter((creatorId) => creatorId !== id)
        : [...prevState, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // If you are in tabValue=0 or 1, you should select from the relevant array
      // but for simplicity, let's always select from the displayed data:
      const displayedData = tabValue === 2 
        ? [...creatorCRMData, ...igdmData, ...creatorDetailsData]
        : creatorCRMData;
      setSelectedCreators(displayedData.map((creator) => creator.id));
    } else {
      setSelectedCreators([]);
    }
  };

  const handleSendUpdate = async () => {
    // Merged data from all sources (this is needed so the Dialog matches the displayed items)
    const displayedData = tabValue === 2
      ? [...creatorCRMData, ...igdmData, ...creatorDetailsData]
      : creatorCRMData;

    const updates = selectedCreators.map((id) => {
      const creator = displayedData.find((creator) => creator.id === id);
      return {
        email: creator.email,
        subject: emailSubject.replace(/{first_name}/g, creator.username),
        body: emailBody.replace(/{first_name}/g, creator.username),
      };
    });

    try {
      const result = await client.crm.update(updates);
      console.log('Updates sent:', result);

      await client.crm.update_contacts({ updates, note: `last email - ${emailSubject}` });

      // Mark them with new status
      for (const id of selectedCreators) {
        await client.crm.creator_update_status({ id, status: 'EMAIL1' });
      }

      setDialogOpen(false);
      setSelectedCreators([]);
      setEmailSubject('');
      setEmailBody('');
    } catch (error) {
      console.error('Failed to send updates:', error);
    }
  };

  const handleChangeStatus = async () => {
    // Merged data from all sources if needed
    const displayedData = tabValue === 2
      ? [...creatorCRMData, ...igdmData, ...creatorDetailsData]
      : creatorCRMData;

    for (const id of selectedCreators) {
      await client.crm.creator_update_status({
        id,
        status: newStatus === 'REMOVE' ? 'removed' : newStatus
      });
    }
    setSelectedCreators([]);
    if (tabValue === 0) {
      fetchCreatorCrmData(statusFilter);
    }
  };

  const downloadCSV = () => {
    // Merged data from all sources if in tabValue=2
    const displayedData = tabValue === 2
      ? [...creatorCRMData, ...igdmData, ...creatorDetailsData]
      : creatorCRMData;

    const headers = [
      'ID', 'Link', 'Username', 'Following', 'Likes', 'Avg Views',
      'Date Sourced', 'Email', 'Bio Link', 'Date Added', 'Last Updated'
    ];
    const csvRows = [headers.join(',')];

    displayedData.forEach((creator) => {
      const values = [
        creator.id,
        creator.link,
        creator.username,
        creator.following,
        creator.likes,
        creator.avg_views,
        creator.date_sourced,
        creator.email,
        creator.bio_link,
        creator.date_added,
        creator.last_updated
      ];
      csvRows.push(values.map((value) => `${value}`).join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'creator_crm_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // This mergedData is used for the "ALL" tab (tabValue=2)
  const mergedData = [...creatorCRMData, ...igdmData, ...creatorDetailsData];

  return (
    <Box>
      <Box sx={StyleContent}>
        <Typography variant="h4" gutterBottom>
          Creator CRM Dashboard
        </Typography>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="email and igdm tabs">
          <Tab label="Email" />
          <Tab label="IGDM" />
          <Tab label="ALL" />
        </Tabs>

        {/* Email Tab */}
        {tabValue === 0 && (
          <>
            <Box sx={StyleStickyButtonContainter}>
              <Button variant="contained" onClick={downloadCSV} sx={StyleButton}>
                Download CSV
              </Button>
              <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} sx={StyleButton}>
                Send Updates
              </Button>
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={(event) => setStatusFilter(event.target.value)}
                  label="Status"
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="EMAIL1">EMAIL1</MenuItem>
                  <MenuItem value="EMAIL2">EMAIL2</MenuItem>
                  <MenuItem value="EMAIL3">EMAIL3</MenuItem>
                  <MenuItem value="cold">Cold</MenuItem>
                  <MenuItem value="contacted">Contacted</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
              <TextField
                label="Search by Email"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
                sx={StyleSearchBar}
              />
              <TextField
                label="Min Followers"
                variant="outlined"
                value={minFollowers}
                onChange={(event) => setMinFollowers(Number(event.target.value))}
                type="number"
                sx={StyleSearchBar}
              />
            </Box>
            <Box sx={StyleActionBox}>
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel>Set Status</InputLabel>
                <Select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  label="Set Status"
                >
                  <MenuItem value="EMAIL1">EMAIL1</MenuItem>
                  <MenuItem value="EMAIL2">EMAIL2</MenuItem>
                  <MenuItem value="EMAIL3">EMAIL3</MenuItem>
                  <MenuItem value="cold">Cold</MenuItem>
                  <MenuItem value="contacted">Contacted</MenuItem>
                  <MenuItem value="REMOVE">REMOVE</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleChangeStatus}>
                Apply Status Change
              </Button>
            </Box>

            <TableContainer component={Paper} sx={{ mb: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(event) => handleSelectAll(event)}
                        checked={selectedCreators.length === creatorCRMData.length && creatorCRMData.length > 0}
                        indeterminate={
                          selectedCreators.length > 0 &&
                          selectedCreators.length < creatorCRMData.length
                        }
                      />
                    </TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Following</TableCell>
                    <TableCell>Likes</TableCell>
                    <TableCell>Avg Views</TableCell>
                    <TableCell>Date Sourced</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Bio Link</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creatorCRMData.map((creator) => (
                    <TableRow
                      key={creator.id}
                      selected={selectedCreators.includes(creator.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedCreators.includes(creator.id)}
                          onChange={() => handleSelect(creator.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <a href={creator.link} target="_blank" rel="noopener noreferrer">
                          {creator.link}
                        </a>
                      </TableCell>
                      <TableCell>{creator.username}</TableCell>
                      <TableCell>{creator.following}</TableCell>
                      <TableCell>{creator.likes}</TableCell>
                      <TableCell>{creator.avg_views}</TableCell>
                      <TableCell>{creator.date_sourced}</TableCell>
                      <TableCell>{creator.email}</TableCell>
                      <TableCell>
                        <a href={creator.bio_link} target="_blank" rel="noopener noreferrer">
                          {creator.bio_link}
                        </a>
                      </TableCell>
                      <TableCell>{creator.last_updated}</TableCell>
                      <TableCell>{creator.status === 'coldCRM' ? 'cold' : creator.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {/* IGDM Tab */}
        {tabValue === 1 && <IGDMView />}

        {/* ALL Tab */}
        {tabValue === 2 && (
          <>
            <Box sx={StyleStickyButtonContainter}>
              <Button variant="contained" onClick={downloadCSV} sx={StyleButton}>
                Download CSV
              </Button>
              <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} sx={StyleButton}>
                Send Updates
              </Button>
              <FormControl variant="outlined" sx={StyleButton}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={(event) => setStatusFilter(event.target.value)}
                  label="Status"
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="EMAIL1">EMAIL1</MenuItem>
                  <MenuItem value="EMAIL2">EMAIL2</MenuItem>
                  <MenuItem value="EMAIL3">EMAIL3</MenuItem>
                  <MenuItem value="cold">Cold</MenuItem>
                  <MenuItem value="contacted">Contacted</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
              <TextField
                label="Search by Email"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
                sx={(theme) => ({ marginBottom: theme.spacing(2) })}
              />
              <TextField
                label="Min Followers"
                variant="outlined"
                value={minFollowers}
                onChange={(event) => setMinFollowers(Number(event.target.value))}
                type="number"
                sx={StyleSearchBar}
              />
            </Box>
            <Box sx={StyleActionBox}>
              <FormControl variant="outlined" sx={StyleSearchBar}>
                <InputLabel>Set Status</InputLabel>
                <Select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  label="Set Status"
                >
                  <MenuItem value="EMAIL1">EMAIL1</MenuItem>
                  <MenuItem value="EMAIL2">EMAIL2</MenuItem>
                  <MenuItem value="EMAIL3">EMAIL3</MenuItem>
                  <MenuItem value="cold">Cold</MenuItem>
                  <MenuItem value="contacted">Contacted</MenuItem>
                  <MenuItem value="REMOVE">REMOVE</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleChangeStatus}>
                Apply Status Change
              </Button>
            </Box>

            <TableContainer component={Paper} sx={{ mb: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(event) => handleSelectAll(event)}
                        checked={selectedCreators.length === mergedData.length && mergedData.length > 0}
                        indeterminate={
                          selectedCreators.length > 0 &&
                          selectedCreators.length < mergedData.length
                        }
                      />
                    </TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Following</TableCell>
                    <TableCell>Likes</TableCell>
                    <TableCell>Avg Views</TableCell>
                    <TableCell>Date Sourced</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Bio Link</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mergedData.map((creator) => (
                    <TableRow
                      key={creator.id}
                      selected={selectedCreators.includes(creator.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedCreators.includes(creator.id)}
                          onChange={() => handleSelect(creator.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <a href={creator.link} target="_blank" rel="noopener noreferrer">
                          {creator.link}
                        </a>
                      </TableCell>
                      <TableCell>{creator.username}</TableCell>
                      <TableCell>{creator.following}</TableCell>
                      <TableCell>{creator.likes}</TableCell>
                      <TableCell>{creator.avg_views}</TableCell>
                      <TableCell>{creator.date_sourced}</TableCell>
                      <TableCell>{creator.email}</TableCell>
                      <TableCell>
                        <a href={creator.bio_link} target="_blank" rel="noopener noreferrer">
                          {creator.bio_link}
                        </a>
                      </TableCell>
                      <TableCell>{creator.last_updated}</TableCell>
                      <TableCell>{creator.status === 'coldCRM' ? 'cold' : creator.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>

      {/* Dialog for sending email updates */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xl"
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '80vh' } }}
      >
        <DialogTitle>Send CRM</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Typography variant="subtitle1" gutterBottom>
              Selected Users to Receive Updates:
            </Typography>
            <List dense>
              {/* Make sure to display from the relevant data array(s) */}
              {(tabValue === 2
                ? mergedData.filter((creator) => selectedCreators.includes(creator.id))
                : creatorCRMData.filter((creator) => selectedCreators.includes(creator.id))
              ).map((creator) => (
                <ListItem key={creator.id}>
                  {creator.email}
                </ListItem>
              ))}
            </List>
            <TextField
              label="Email Subject"
              variant="outlined"
              fullWidth
              margin="normal"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
            />
            <TextField
              label="Email Body"
              variant="outlined"
              multiline
              rows={8}
              fullWidth
              margin="normal"
              value={emailBody}
              onChange={(e) => setEmailBody(e.target.value)}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSendUpdate} color="primary" variant="contained">Send</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreatorCRMView;
