import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";
import { globalStyles } from "../../Utils/Styles";
import { StyledDivider as Divider } from "../../Components/LightDivider";

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
            Privacy Policy
          </Typography>

          {/* Introduction */}
          <Typography variant="body1" paragraph>
            At Blitz, a product of Pangea, Inc., we are committed to protecting your privacy.
            This Privacy Policy outlines how we collect, use, disclose, and safeguard your
            information when you use our platform and services.
          </Typography>

          {/* Information We Collect */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
              We may collect personal information that you voluntarily provide to us when
              registering on the platform, such as your name, email address, social media
              profiles, and payment information. We also collect data automatically through
              cookies and similar technologies when you interact with our services.
            </Typography>
          </Box>

          <Divider />

          {/* How We Use Your Information */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use the information we collect to:
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Provide and maintain our services</li>
              <li>Process transactions and send you related information</li>
              <li>Communicate with you about promotions, updates, and other news</li>
              <li>Monitor and analyze usage and trends to improve user experience</li>
              <li>Protect our platform from fraudulent activities</li>
            </Typography>
          </Box>

          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12) !important" }} />

          {/* Sharing Your Information */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Sharing Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We may share your information with third parties in the following circumstances:
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>
                With service providers that assist us in operating our platform and conducting
                our business
              </li>
              <li>
                With business partners for collaborations, campaigns, and partnerships you
                engage in
              </li>
              <li>
                To comply with legal obligations, respond to legal requests, or protect our
                rights
              </li>
              <li>
                In connection with any merger, sale of company assets, financing, or acquisition
                of all or a portion of our business
              </li>
            </Typography>
          </Box>

          <Divider />

          {/* Security */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Security
            </Typography>
            <Typography variant="body1" paragraph>
              We implement a variety of security measures to maintain the safety of your
              personal information. However, no method of transmission over the internet or
              electronic storage is 100% secure. Therefore, we cannot guarantee its absolute
              security.
            </Typography>
          </Box>

          <Divider />

          {/* Your Rights */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Your Rights
            </Typography>
            <Typography variant="body1" paragraph>
              You have the right to access, correct, or delete your personal information held
              by us. You may also object to the processing of your data or request that we
              restrict processing of your personal information. To exercise these rights,
              please contact us using the information provided below.
            </Typography>
          </Box>

          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12) !important" }} />

          {/* Cookies and Tracking Technologies */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Cookies and Tracking Technologies
            </Typography>
            <Typography variant="body1" paragraph>
              We use cookies and similar tracking technologies to track activity on our service
              and hold certain information. You can instruct your browser to refuse all cookies
              or to indicate when a cookie is being sent.
            </Typography>
          </Box>

          <Divider />

          {/* Use of Google Workspace APIs */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Use of Google Workspace APIs
            </Typography>
            <Typography variant="body1" paragraph>
              We use Google Workspace APIs to enhance your experience on our platform.
              Specifically, we request access to certain Google APIs with the minimal scopes
              necessary for functionality.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Scope We Request:</strong>
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    fontStyle: "italic",
                    overflowX: "hidden",
                    overflowWrap: "break-word",
                  }}
                >
                  https://www.googleapis.com/auth/gmail.modify
                </Typography>{" "}
                - Allows our application to read, compose, send, and permanently delete your
                emails from Gmail. This scope enables us to provide core features such as
                managing your inbox, sending emails, and organizing messages.
              </li>
            </Typography>
            <Typography variant="body1" paragraph>
              We only request access to this scope to provide the core functionality of our
              services. We do not use your data for any other purposes.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Examples of Data Usage:</strong>
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Organizing emails by applying labels or moving them to folders based on your actions.</li>
              <li>Sending emails you compose within our platform through your Gmail account.</li>
              <li>Marking emails as read or unread when you interact with them in our app.</li>
            </Typography>
            <Typography variant="body1" paragraph>
              We do not request access to any restricted scopes beyond what is necessary for these features.
            </Typography>
          </Box>

          <Divider />

          {/* Retention and Deletion of Google User Data */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Retention and Deletion of Google User Data
            </Typography>
            <Typography variant="body1" paragraph>
              We retain Google user data only for as long as necessary to provide our services
              to you. If you choose to disconnect your Google account from our services, we
              will delete all associated Google user data from our servers within 30 days. You
              may also request deletion of your data at any time by contacting us.
            </Typography>
            <Typography variant="body1" paragraph>
              To request deletion of your Google user data, please email us at{" "}
              <Typography component="span" variant="body1" sx={{ fontStyle: "italic" }}>
                win@thecultureclub.us
              </Typography>
              . We will process your request within 30 days.
            </Typography>
          </Box>

          <Divider />

          {/* Compliance with Google API Services User Data Policy */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Compliance with Google API Services User Data Policy
            </Typography>
            <Typography variant="body1" paragraph>
              Our use of information received from Google APIs will adhere to the{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </Typography>
            <Typography variant="body1" paragraph>
              We will not use or transfer your data for serving ads, including retargeting,
              personalized, or interest-based advertising. We will not allow humans to read
              the data unless we have your affirmative agreement, or it is necessary for
              security purposes such as investigating abuse, complying with applicable law, or
              for the application's internal operations.
            </Typography>
          </Box>

          <Divider />

          {/* New Section: No Use of Google Workspace Data for AI/ML */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              No Use of Google Workspace Data for AI/ML
            </Typography>
            <Typography variant="body1" paragraph>
              We do <strong>not</strong> use any data obtained through Google Workspace APIs
              to develop, improve, or train generalized AI or machine learning models. We only
              access and use Google Workspace data for the limited purposes described in this
              Privacy Policy and in strict compliance with Google’s policies.
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions regarding our data usage practices, please reach out
              to us at{" "}
              <Typography component="span" variant="body1" sx={{ fontStyle: "italic" }}>
                win@thecultureclub.us
              </Typography>
              .
            </Typography>
          </Box>

          <Divider />

          {/* Changes to This Privacy Policy */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We may update our Privacy Policy from time to time. We will notify you of any
              changes by posting the new Privacy Policy on this page. You are advised to review
              this Privacy Policy periodically for any changes.
            </Typography>
          </Box>

          <Divider />

          {/* Contact Us */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions about this Privacy Policy, please contact us at:
            </Typography>
            <Typography variant="body1" paragraph>
              Email: win@thecultureclub.us
              <br />
              Address: 8724 Cavell Lane, Houston Texas 77055
            </Typography>
          </Box>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default PrivacyPolicy;
