import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Paper
} from "@mui/material";
import API from "../../../API";
import useAuth from "../../../Hooks/use-auth";
import { useMutation } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";

const AgencyNegotiateDialog = ({
  open,
  handleClose,
  campaign,
  creatorId,
  currentRate,
  refreshCampaign,
  onSuccess,
}) => {
  const { agencyToken } = useAuth();
  const [counterOffer, setCounterOffer] = useState('');
  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();
  console.log(typeof currentRate);

  // Calculate 20% threshold based on the current rate
  const threshold = currentRate * 1.2;
  const counterOfferValue = parseFloat(counterOffer);
  const showWarning =
    counterOffer &&
    !isNaN(counterOfferValue) &&
    counterOfferValue > threshold;

  const { mutate: submitNegotiation, isLoading: isSubmitting } = useMutation(
    (offer) =>
      API.agency.campaignNegotiate(campaign.id, {
        counterOffer: parseFloat(offer),
        creator: creatorId,
      }),
    {
      onSuccess: () => {
        openAlert(
          "Counter Offer Submitted",
          "Your counter offer has been submitted on behalf of your creator. The brand will review your proposal.",
          () => {
            refreshCampaign();
            handleClose();
            closeDialog();
            if (onSuccess) onSuccess();
          },
          null,
          "Ok",
          null
        );
      },
      onError: (error) => {
        openAlert(
          "Error",
          `Error submitting counter offer: ${error.response?.data?.error || error.message}`,
          closeDialog,
          null,
          "Ok",
          null
        );
      }
    }
  );

  const platformFee = counterOffer
    ? (parseFloat(counterOffer) * 0.05).toFixed(2)
    : 0;
  const netPrice = counterOffer
    ? (parseFloat(counterOffer) - platformFee).toFixed(2)
    : 0;

  const handleSubmit = () => {
    if (!counterOffer || isNaN(counterOffer) || parseFloat(counterOffer) <= 0) {
      openAlert(
        "Invalid Offer",
        "Please enter a valid offer amount",
        closeDialog,
        null,
        "Ok",
        null
      );
      return;
    }
    submitNegotiation(counterOffer);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Paper square elevation={3}>
          <DialogTitle>Submit Counter Offer for {creatorId}</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1" gutterBottom>
              Current Offer: ${currentRate?.toFixed(2)}
            </Typography>

            <TextField
              fullWidth
              label="Your Counter Offer ($)"
              variant="outlined"
              type="number"
              value={counterOffer}
              onChange={(e) =>
                setCounterOffer(e.target.value.replace(/[^0-9.]/g, ""))
              }
              inputProps={{ min: 0, step: 0.01 }}
              sx={{ mt: 2 }}
            />

            {showWarning && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                Counter offers above 20% of the original rate are more likely to be rejected.
              </Typography>
            )}

            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              Platform Fee (5%): ${platformFee}
            </Typography>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Creator Net Rate: ${netPrice}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Submit Offer"}
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <AlertDialog alertState={dialogState} />
    </>
  );
};

export default AgencyNegotiateDialog;