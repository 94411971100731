import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  useTheme
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../../../API";
import { getPONumber } from "../../../../../Utils/constants";

const PayoutDialog = ({ open, onClose, partnershipId, creatorId, conversationId }) => {
  const theme = useTheme();
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [poNumber, setPoNumber] = useState("");

  useEffect(() => {
    if (!poNumber) {
      setPoNumber(getPONumber('PO'));
    }
  }, [poNumber]);

  const createDeliverableMutation = useMutation(client.deliverables.create, {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      console.error("Error creating deliverable:", error);
    },
  });

  const handleSubmit = () => {
    createDeliverableMutation.mutate({
      partnership_id: partnershipId,
      creator_id: creatorId,
      conversation_id: conversationId,
      deliverable_type: "Payout",
      deliverable_link: null,
      amount,
      status: "sent",
      notes,
      due_date: null,
      platforms: [],
      po_number: poNumber,
    });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          bgcolor: 'background.paper',
          color: 'text.primary',
          typography: 'h6',
          borderBottom: `1px solid ${theme.palette.divider}`,
          py: 2,
          px: 3
        }}
      >
        Create Payout
      </DialogTitle>

      <DialogContent 
        sx={{ 
          bgcolor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          py: 3,
          px: 3,
          height: 600,
          overflowY: 'auto'
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: '$'
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={4}
          sx={{
            '& .MuiInputBase-root': {
              alignItems: 'flex-start'
            }
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="PO Number"
          value={poNumber}
          onChange={(e) => setPoNumber(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .MuiInputBase-input': {
              textTransform: 'uppercase'
            }
          }}
        />
      </DialogContent>

      <DialogActions 
        sx={{ 
          bgcolor: 'background.paper',
          px: 3,
          py: 2,
          borderTop: `1px solid ${theme.palette.divider}`
        }}
      >
        <Button 
          onClick={onClose} 
          variant="outlined"
          sx={{ 
            px: 3,
            '&:hover': { bgcolor: 'action.hover' }
          }}
        >
          Cancel
        </Button>
        
        <Button 
          onClick={handleSubmit} 
          variant="contained"
          sx={{ 
            px: 3,
            ml: 2,
            bgcolor: 'primary.main',
            '&:hover': { bgcolor: 'primary.dark' }
          }}
        >
          Create Payout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayoutDialog;