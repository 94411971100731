import React from 'react'
import { Slide } from '../ReportComponents'
import { Avatar, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'react-router-dom';
import routes from '../../../Config/routes';

// Styled components
const FeaturedInfluencers = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%'
});

const InfluencerCard = styled(Box)(({ theme }) => ({
    background: 'rgba(255,255,255,0.3)',
    borderRadius: '10px',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
    },
}));

const InfluencerDetails = styled(Box)({
    textAlign: 'left',
    flexGrow: 1,
});

const InfluencerName = styled(Typography)({
    fontWeight: 'bold',
    color: 'secondary',
    fontSize: '18px',
});

const InfluencerStats = styled(Typography)({
    fontSize: '14px',
});

const InfluencerAvatar = styled(Avatar)(({ theme }) => ({
    width: '56px',
    height: '56px',
    marginRight: theme.spacing(2),
}));

function InfluencerRow({ influencer }) {


    return (
        <InfluencerCard>
            <InfluencerAvatar
                src={influencer.pfphref}
                alt={influencer.creator}
            />
            <InfluencerDetails>
                <Link to={`/creators/${influencer.creator}`} style={{ color: 'inherit' }}>
                    <InfluencerName>{influencer.creator}</InfluencerName>
                </Link>
                <InfluencerStats color='text.secondary'>{influencer.total_following.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })} Followers
                </InfluencerStats>
            </InfluencerDetails>
        </InfluencerCard >
    )
}


export default function InfluencersSlide({ index, currentSlide, report }) {
    return (
        <Slide
            id='slide-influencers'
            index={index}
            currentIndex={currentSlide}
            sx={theme => ({
                backgroundImage: 'linear-gradient(135deg, #450af5,rgb(65, 95, 112))',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: theme.spacing(4),
                paddingBlockEnd: theme.spacing(8),
            })}>
            <Typography component={'div'} variant='h5'>Creators Highlights</Typography>
            <FeaturedInfluencers>
                {report?.report.creator_videos.slice(0, 3).map((influencer, index) => (
                    <InfluencerRow key={index} influencer={influencer} />
                ))}
            </FeaturedInfluencers>
            <Typography component={'div'} variant='subtitle2' color='text.secondary' >{report?.name}</Typography>
        </Slide>
    )
}
