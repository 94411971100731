import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  Tab,
} from "@mui/material";
import { SketchPicker } from "react-color";
import EditIcon from "@mui/icons-material/Edit";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PersonIcon from "@mui/icons-material/Person";
import PaletteIcon from "@mui/icons-material/Palette";
import ApiIcon from "@mui/icons-material/Api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import TeamIcon from "@mui/icons-material/Group"; // or another suitable icon

import MyTeam from "./Users/myteam";
// Your custom components
import StyledTabs from "../../Components/StyledTabs";
import TabPanel from "../../Components/TabPanel";
import DeveloperPanel from "./ApiServices/developerPanel";
import UploadFileDialog from "./Users/sepcupload";

// Your API client that includes creditLedger, users, companies, etc.
import client from "../../API";

// Global assets
import ppfLogo from "../../Components/globalAssets/ppfLogo.png";
import { GoogleLogin } from "@react-oauth/google";
import useAlertDialog from "../../Components/useAlertDialog";
import AlertDialog from "../../Components/AlertDialog";

const User = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = searchParams.get("tab") || "user";
  const setTabValue = (newValue) =>
    setSearchParams((params) => ({ ...params, tab: newValue }));

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // -----------------------
  //  Dialog / local states
  // -----------------------
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  // Dialog states for credit line request
  const [creditDialogOpen, setCreditDialogOpen] = useState(false);
  const [requestedCreditLine, setRequestedCreditLine] = useState("");
  const [requestedInterestRate, setRequestedInterestRate] = useState("");
  const [reason, setReason] = useState("");
  const { dialogState, openDialog } = useAlertDialog();

  // -----------------------
  //  Fetch Logged-in User
  // -----------------------
  const { data: userInfo } = useQuery(["user"], client.users.fetchUser, {
    onSuccess: (data) => {
      if (data) {
        setUserData((prev) => ({
          ...prev,
          firstName: data.first_name || "",
          lastName: data.last_name || "",
          email: data.email || "",
          username: data.username || "",
          address: data.address || "",
          phphref: data.phphref || "",
        }));
      }
    },
  });

  // -----------------------
  //  Fetch Company Data
  // -----------------------
  const {
    data: companyData,
    isLoading: companyLoading,
    error: companyError,
  } = useQuery(
    ["company", userInfo?.company_id],
    () => client.companies.listFetch({ id: userInfo?.company_id }),
    {
      enabled: !!userInfo?.company_id,
    }
  );

  // -----------------------
  //  Fetch Ledger Data
  // -----------------------
  const {
    data: ledgerEntries,
    isLoading: ledgerLoading,
    isError: ledgerError,
    error: ledgerErrorObj,
  } = useQuery(
    ["creditLedger", userInfo?.company_id],
    () => client.creditLedger.fetch(userInfo.company_id),
    {
      enabled: !!userInfo?.company_id,
    }
  );

  // -----------------------
  //  Local state for user form
  // -----------------------
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    address: "",
    phphref: "",
  });

  // -----------------------
  //  Appearance settings
  // -----------------------
  const [appearanceSettings, setAppearanceSettings] = useState({
    darkMode: null,
    fontPreference: "default",
    uiDensity: "normal",
    themeColors: {
      primary: prefersDarkMode ? "#cb534f" : "#7b2a2a",
      secondary: "#646aff",
    },
  });

  // Fetch user settings
  const { data: userSettings } = useQuery(["userSettings"], () =>
    client.users.fetchUserSettings()
  );

  // Populate appearance from userSettings
  useEffect(() => {
    if (userSettings?.id) {
      setAppearanceSettings({
        darkMode: userSettings.dark_mode,
        fontPreference: userSettings.font_preference,
        uiDensity: userSettings.ui_density,
        themeColors: userSettings.theme_colors || {
          primary: "#1976d2",
          secondary: "#9c27b0",
        },
      });
    }
  }, [userSettings]);

  // -----------------------
  //  Mutations
  // -----------------------
  // 1) Save user info
  const saveUserMutation = useMutation((updatedData) => client.users.updateUser(updatedData), {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
    onError: (error) => {
      openDialog("Error", `Google login failed: ${error?.response?.data?.error || error.message}`);
    }
  });

  const { mutate: updateGoogleLogin, isLoading: loadingGoogleLogin } = useMutation(client.users.updateGoogle, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
    onError: (error) => {
      openDialog("Error", `Google Login failed: ${error?.response?.data?.error || error.message}`);
    }
  });

  // 2) Save appearance settings
  const saveSettingsMutation = useMutation(
    (settingsData) => {
      if (userSettings?.id) {
        return client.users.updateUserSettings({
          id: userSettings.id,
          ...settingsData,
        });
      }
      // If userSettings doesn’t exist yet, create them
      return client.users.createUserSettings(settingsData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userSettings");
        queryClient.invalidateQueries("user");
      },
      onError: (error) => {
        openDialog("Error", `Operation failed: ${error?.response?.data?.error || error.message}`);
      }
    }
  );

  // 3) Post new credit ledger request
  const postCreditRequestMutation = useMutation(
    (input) => client.creditLedger.postRequest(input),
    {
      onSuccess: () => {
        // Refresh ledger data
        queryClient.invalidateQueries(["creditLedger", userInfo?.company_id]);
        // Optionally close the dialog or reset form
        setCreditDialogOpen(false);
        setReason("");
      },
      onError: (error) => {
        openDialog("Error", `Operation failed: ${error?.response?.data?.error || error.message}`);
      }
    }
  );

  // -----------------------
  //  Handlers
  // -----------------------
  const handleUserInfoChange = (e) => {
    setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSaveUserInfo = async () => {
    try {
      await saveUserMutation.mutateAsync({
        first_name: userData.firstName,
        last_name: userData.lastName,
        email: userData.email,
        username: userData.username,
        address: userData.address,
        phphref: userData.phphref,
      });
    } catch (error) {
      console.error("Error saving user info:", error);
    }
  };

  const handleSaveAppearance = async () => {
    try {
      await saveSettingsMutation.mutateAsync({
        dark_mode: appearanceSettings.darkMode,
        font_preference: appearanceSettings.fontPreference,
        ui_density: appearanceSettings.uiDensity,
        theme_colors: appearanceSettings.themeColors,
      });
    } catch (error) {
      console.error("Error saving appearance settings:", error);
    }
  };

  // -----------------------
  //  Credit request dialog
  // -----------------------
  const handleOpenCreditDialog = () => {
    // Pre-fill fields with current company data if desired
    setRequestedCreditLine(companyData?.credit_line || "");
    setRequestedInterestRate(companyData?.nominal_interest_rate || "");
    setCreditDialogOpen(true);
  };

  const handleCloseCreditDialog = () => {
    setCreditDialogOpen(false);
    setReason("");
  };

  const handleRequestCreditChange = () => {
    if (!userInfo?.company_id) return;
    postCreditRequestMutation.mutate({
      company_id: userInfo.company_id,
      requested_credit_line: Number(requestedCreditLine),
      requested_interest_rate: Number(requestedInterestRate),
      reason: reason || "User request",
    });
  };

  // Google login handler
  const handleGoogleLoginSuccess = (response) => {
    const token = response.credential;
    updateGoogleLogin({ google_token: token });
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
    openDialog("Error", `Google login failed: ${error?.response?.data?.error || error.message}`);
  };

  // -----------------------
  //  Render
  // -----------------------
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1280,
        margin: "auto",
        p: 3,
        backgroundColor: "background.default",
        minHeight: "100vh",
      }}
    >
      <AlertDialog alertState={dialogState}></AlertDialog>
      {/* ========== Company Header ========== */}
      <Card
        sx={{
          background: "linear-gradient(135deg, #2c3e50 0%, #3498db 100%)",
          color: "white",
          mb: 3,
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
              flexWrap: "wrap",
            }}
          >
            <Avatar
              src={companyData?.phphref || ppfLogo}
              sx={{
                width: 96,
                height: 96,
                border: "3px solid white",
                boxShadow: 3,
              }}
            />
            <Box sx={{ flex: 1 }}>
              <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
                {companyData?.name || "Your Company"}
              </Typography>
              <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                <Chip
                  label={`Balance: $${companyData?.balance || "0.00"}`}
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.15)",
                    color: "white",
                    fontWeight: 600,
                  }}
                />
                <Chip
                  label={`Credit Line: $${companyData?.credit_line || "0.00"}`}
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.15)",
                    color: "white",
                    fontWeight: 600,
                  }}
                />
                <Chip
                  label={`Rate: ${companyData?.nominal_interest_rate || "0.00"
                    }%`}
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.15)",
                    color: "white",
                    fontWeight: 600,
                  }}
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<RequestQuoteIcon />}
              onClick={handleOpenCreditDialog}
            >
              Adjust Credit
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* ========== Main Content Container ========== */}
      <Box
        sx={{ display: "flex", gap: 3, flexDirection: { xs: "column", lg: "row" } }}
      >
        {/* ========== Left Navigation Tabs ========== */}
        <Card sx={{ width: { lg: 240 }, flexShrink: 0, borderRadius: 3, boxShadow: 3 }}>
          <StyledTabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            orientation="vertical"
            variant="scrollable"
          >
            <Tab icon={<PersonIcon />} label="Profile" value="user" />
            <Tab icon={<PaletteIcon />} label="Appearance" value="appearance" />
            <Tab icon={<TeamIcon />} label="My Team" value="team" />

            <Tab icon={<ApiIcon />} label="Developers" value="dev" />
          </StyledTabs>
        </Card>

        {/* ========== Right Content ========== */}
        <Box sx={{ flex: 1 }}>
          {/* ========== Profile Tab ========== */}
          <TabPanel value={tabValue} index="user">
            <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
              <CardContent>
                <Box sx={{ textAlign: "center", mb: 4 }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      "&:hover .edit-overlay": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Avatar
                      src={userData.phphref || ppfLogo}
                      sx={{
                        width: 120,
                        height: 120,
                        fontSize: "2.5rem",
                        mb: 2,
                      }}
                    />
                    <IconButton
                      onClick={() => setUploadDialogOpen(true)}
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 8,
                        backgroundColor: "background.paper",
                        "&:hover": {
                          backgroundColor: "action.selected",
                        },
                        transition: "opacity 0.2s",
                        opacity: 0.8,
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Typography variant="h5" sx={{ fontWeight: 700 }}>
                    {userData.firstName} {userData.lastName}
                  </Typography>
                  <Typography color="text.secondary">@{userData.username}</Typography>
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      size="medium"
                      value={userData.firstName}
                      onChange={handleUserInfoChange}
                      name="firstName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      size="medium"
                      value={userData.lastName}
                      onChange={handleUserInfoChange}
                      name="lastName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      type="email"
                      variant="outlined"
                      size="medium"
                      value={userData.email}
                      onChange={handleUserInfoChange}
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Username"
                      variant="outlined"
                      size="medium"
                      value={userData.username}
                      onChange={handleUserInfoChange}
                      name="username"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      variant="outlined"
                      size="medium"
                      multiline
                      rows={2}
                      value={userData.address}
                      onChange={handleUserInfoChange}
                      name="address"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle" color='text.secondary'>Add Google Account as login method</Typography>
                    <Box sx={{
                      width: "min-content"
                    }}>
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        onError={handleGoogleLoginFailure}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleSaveUserInfo}
                      disabled={saveUserMutation.isLoading}
                    >
                      {saveUserMutation.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Save Changes"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabPanel>

          {/* ========== Appearance Tab ========== */}
          <TabPanel value={tabValue} index="appearance">
            <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h5" sx={{ mb: 3, fontWeight: 700 }}>
                  Appearance Settings
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      select
                      label="Theme Mode"
                      value={appearanceSettings.darkMode}
                      onChange={(e) =>
                        setAppearanceSettings((prev) => ({
                          ...prev,
                          darkMode: e.target.value,
                        }))
                      }
                      variant="outlined"
                      size="medium"
                    >
                      <MenuItem value={null}>System Default</MenuItem>
                      <MenuItem value={true}>Dark</MenuItem>
                      <MenuItem value={false}>Light</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      select
                      label="UI Density"
                      value={appearanceSettings.uiDensity}
                      onChange={(e) =>
                        setAppearanceSettings((prev) => ({
                          ...prev,
                          uiDensity: e.target.value,
                        }))
                      }
                      variant="outlined"
                      size="medium"
                    >
                      <MenuItem value="compact">Compact</MenuItem>
                      <MenuItem value="normal">Normal</MenuItem>
                      <MenuItem value="comfortable">Comfortable</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      select
                      label="Font Family"
                      value={appearanceSettings.fontPreference}
                      onChange={(e) =>
                        setAppearanceSettings((prev) => ({
                          ...prev,
                          fontPreference: e.target.value,
                        }))
                      }
                      variant="outlined"
                      size="medium"
                    >
                      <MenuItem value="default">System Default</MenuItem>
                      <MenuItem value="roboto">Roboto</MenuItem>
                      <MenuItem value="open-sans">Open Sans</MenuItem>
                      <MenuItem value="montserrat">Montserrat</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Color Customization
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                      <Box>
                        <FormHelperText sx={{ mb: 1 }}>Primary Color</FormHelperText>
                        <SketchPicker
                          color={appearanceSettings.themeColors.primary}
                          onChangeComplete={(color) =>
                            setAppearanceSettings((prev) => ({
                              ...prev,
                              themeColors: { ...prev.themeColors, primary: color.hex },
                            }))
                          }
                        />
                      </Box>
                      <Box>
                        <FormHelperText sx={{ mb: 1 }}>Secondary Color</FormHelperText>
                        <SketchPicker
                          color={appearanceSettings.themeColors.secondary}
                          onChangeComplete={(color) =>
                            setAppearanceSettings((prev) => ({
                              ...prev,
                              themeColors: { ...prev.themeColors, secondary: color.hex },
                            }))
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleSaveAppearance}
                      disabled={saveSettingsMutation.isLoading}
                    >
                      {saveSettingsMutation.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Save Preferences"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabPanel>

          {/* ========== Developers Tab ========== */}
          <TabPanel value={tabValue} index="dev">
            <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
              <CardContent>
                <DeveloperPanel />
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={tabValue} index="team">
            <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h5" sx={{ mb: 3, fontWeight: 700 }}>
                  My Team
                </Typography>
                <MyTeam />
              </CardContent>
            </Card>
          </TabPanel>
        </Box>
      </Box>

      {/* ========== Credit Ledger Dialog ========== */}
      <Dialog
        open={creditDialogOpen}
        onClose={handleCloseCreditDialog}
        PaperProps={{
          sx: {
            borderRadius: 3,
            width: "100%",
            maxWidth: 1200,
          },
        }}
      >
        <DialogTitle>Adjust Credit & Interest Rate</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Below is the historical credit ledger for your company. You can also
            request a change to your credit line and/or nominal interest rate.
          </DialogContentText>

          {/* -- Show ledger history -- */}
          {ledgerLoading && <CircularProgress size={24} sx={{ m: 2 }} />}
          {ledgerError && (
            <Typography color="error">
              Error loading ledger: {ledgerErrorObj?.message}
            </Typography>
          )}
          {ledgerEntries?.length > 0 && (
            <Box
              sx={{
                maxHeight: 200,
                overflowY: "auto",
                mb: 2,
                border: "1px solid #ccc",
                borderRadius: 1,
                p: 1,
              }}
            >
              {ledgerEntries.map((entry) => (
                <Box
                  key={entry.id}
                  sx={{
                    mb: 1,
                    p: 1,
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <Typography variant="body2">
                    <strong>{entry.operation_type.toUpperCase()}</strong>{" "}
                    on {new Date(entry.created_at).toLocaleString()}
                  </Typography>
                  <Typography variant="caption">
                    Change: {entry.change_amount} | New Credit:{" "}
                    {entry.new_credit_line} | New Rate: {entry.new_interest_rate}%
                  </Typography>
                  {entry.reason && (
                    <Typography variant="caption" display="block">
                      Reason: {entry.reason}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}

          {/* -- Request form -- */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Requested Credit Line"
                type="number"
                fullWidth
                value={requestedCreditLine}
                onChange={(e) => setRequestedCreditLine(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Requested Interest Rate"
                type="number"
                fullWidth
                value={requestedInterestRate}
                onChange={(e) => setRequestedInterestRate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Reason for Change"
                fullWidth
                multiline
                rows={2}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreditDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleRequestCreditChange}
            disabled={postCreditRequestMutation.isLoading}
          >
            {postCreditRequestMutation.isLoading ? (
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            ) : (
              "Submit Request"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* ========== Upload Dialog ========== */}
      <UploadFileDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        onSuccess={(fileData) => {
          setUserData((prev) => ({ ...prev, phphref: fileData.url }));
        }}
      />
    </Box>
  );
};

export default User;
