import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Backdrop,
    CircularProgress,
    Box,
    TextField,
    Divider,
    MenuItem
} from '@mui/material';
import { keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import routes from '../Config/routes';

import AlertDialog from '../Components/AlertDialog';
import useAlertDialog from '../Components/useAlertDialog';
import client from '../API';
import { niches, region } from '../Utils/constants';
// Remove GoogleButton if you’re not using it, or keep if needed for something else
import MuiPhoneInput from '../Components/MuiPhoneInput';
import { GoogleLogin } from '@react-oauth/google';

const CreatorOnboard = ({ conversationIdParam, inviteEmailParam, prefilledUsername = '' }) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState(prefilledUsername); // initialize from prop
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validationError, setValidationError] = useState('');
    const [creatorRegion, setCreatorRegion] = useState('');
    const [creatorNiche, setCreatorNiche] = useState([]);
    const [instagramUsername, setInstagramUsername] = useState('');
    const [tiktokUsername, setTiktokUsername] = useState('');
    const [youtubeUsername, setYoutubeUsername] = useState('');
    const [podcastUsername, setPodcastUsername] = useState('');
    const [twitchUsername, setTwitchUsername] = useState('');
    const [kickUsername, setKickUsername] = useState('');
    const [xUsername, setXUsername] = useState('');
    
    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const inputRef = useRef(null);
    const formRef = useRef(null);

    const [disclaimer, setOpenDisclaimer] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUsername(prefilledUsername);
    }, [prefilledUsername]);
    
    const moveGradient = keyframes`
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    `;

    useEffect(() => {
        setOpenDisclaimer(false);
    }, []);

    const redirectLogin = () => {
        navigate(`${routes.login}?tab=creator`);
    };

    const handleCredentialResponse = (response) => {
        SubmitCreator(response.credential);
    };

    const handleLoginFailure = (error) => {
        console.error('Failed to create account with Google:', error);
        setLoading(false);
        openDialog(
            'Error',
            <>There was an error creating your account: {error} <br />
                Your Google and payment information has been cleared. Please try again.</>,
            closeDialog,
            closeDialog,
            'Ok',
            null
        );
    };

    const SubmitCreator = async (googleCode) => {
        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }

        if (username.includes(" ")) {
            setValidationError("Creator name cannot contain spaces.");
            inputRef.current.focus();
            return;
        }

        if (phoneNumber.length < 9) {
            openDialog('Error', 'You must enter a valid phone number', closeDialog, closeDialog, 'Ok', null);
            return;
        }

        if (
            !youtubeUsername &&
            !instagramUsername &&
            !tiktokUsername &&
            !podcastUsername &&
            !twitchUsername &&
            !kickUsername &&
            !xUsername
        ) {
            openDialog(
                'Error',
                'You must enter at least one platform username.',
                closeDialog,
                closeDialog,
                'Ok',
                null
            );
            return;
        }

        setLoading(true);

        const payload = {
            username,
            is_partner: false,
            region: creatorRegion,
            niche: creatorNiche,
            youtube: youtubeUsername ? `https://www.youtube.com/@${youtubeUsername}` : null,
            instagram: instagramUsername ? `https://www.instagram.com/${instagramUsername}` : null,
            tiktok: tiktokUsername ? `https://www.tiktok.com/@${tiktokUsername}` : null,
            podcast: podcastUsername || null,
            x: xUsername ? `https://www.x.com/${xUsername}` : null,
            twitch: twitchUsername ? `https://www.twitch.tv/${twitchUsername}` : null,
            kick: kickUsername ? `https://www.kick.com/${kickUsername}` : null,
            google_code: googleCode,
            phone_number: phoneNumber,
        };

        try {
            await client.creatorConnect.onboard(payload);
            openDialog(
                'Success',
                'You have successfully created your CreatorConnect account.',
                redirectLogin,
                redirectLogin,
                'Ok',
                null
            );
        } catch (error) {
            console.error("Error during form submission:", error);
            openDialog(
                'Error',
                <>There was an error creating your account: {error.response?.data?.error || error.message}<br />Please try again.</>,
                closeDialog,
                closeDialog,
                'Ok',
                null
            );
            setLoading(false);
        }
    };

    const OnSubmit = (e) => {
        e.preventDefault();
        SubmitCreator(null);
    };

    return (
        <Box sx={{ paddingInline: 2 }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AlertDialog alertState={dialogState} />
            <Dialog open={disclaimer} onClose={() => setOpenDisclaimer(false)}>
                <DialogTitle>Signup Form Information</DialogTitle>
                <DialogContent>
                    <p>
                        This signup form is for joining Blitz's CreatorConnect. You will be available in the marketplace for companies to send campaigns directly to your email and phone number. By joining, you are joining a community of thousands of creators worldwide!
                    </p>
                    <p>
                        <strong>Why Google Login?</strong><br />
                        We use Google login to secure creator accounts with industry-leading security features, ensuring your data and login credentials remain safe.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDisclaimer(false)} color="primary" variant="text">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <form onSubmit={OnSubmit} ref={formRef} noValidate>
                <Box sx={{ marginInline: 'auto', marginBlockStart: 4, marginBlockEnd: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '30em' }}>
                    <Box sx={{ marginBlockEnd: 1, textAlign: 'center' }}>
                        <Typography variant='h3'>Account Setup</Typography>
                        <Typography variant='body1' color='text.secondary'>
                            Help us build your Media Kit!
                        </Typography>
                    </Box>
                    <TextField
                        label='Username'
                        name='onboard-name'
                        helperText={validationError || 'Use the username of your biggest social media.'}
                        required
                        value={username}
                        error={!!validationError}
                        onChange={e => setUsername(e.target.value)}
                        inputRef={inputRef}
                    />
                    <TextField
                        label='Your Region'
                        name='onboard-region'
                        select
                        required
                        value={creatorRegion}
                        onChange={e => setCreatorRegion(e.target.value)}
                    >
                        {region.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value === 'NACAUKAUS' ? 'USA, Canada, UK, Australia' : value}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label='Your Niche'
                        name='onboard-niche'
                        select
                        required
                        value={creatorNiche}
                        SelectProps={{ multiple: true }}
                        onChange={e => setCreatorNiche(e.target.value)}
                    >
                        {niches.map((market) => (
                            <MenuItem key={market} value={market}>
                                {market}
                            </MenuItem>
                        ))}
                    </TextField>
                    <MuiPhoneInput
                        country={'us'}
                        preferredCountries={['us']}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        isValid={(value) => value.length >= 10}
                        required
                    />
                    <Divider />
                    <TextField
                        label='Youtube Username (If applicable)'
                        name='onboard-youtube'
                        value={youtubeUsername}
                        onChange={e => setYoutubeUsername(e.target.value)}
                        helperText="e.g., @YourChannelName"
                    />
                    <TextField
                        label='Instagram Username (If applicable)'
                        name='onboard-instagram'
                        value={instagramUsername}
                        onChange={e => setInstagramUsername(e.target.value)}
                        helperText="e.g., yourusername"
                    />
                    <TextField
                        label='TikTok Username (If applicable)'
                        name='onboard-tiktok'
                        value={tiktokUsername}
                        onChange={e => setTiktokUsername(e.target.value)}
                        helperText="e.g., @yourusername"
                    />
                    <TextField
                        label='Podcast Name (If applicable)'
                        name='onboard-podcast'
                        value={podcastUsername}
                        onChange={e => setPodcastUsername(e.target.value)}
                        helperText="e.g., YourPodcastName"
                    />
                    <TextField
                        label='X Username (If applicable)'
                        name='onboard-x'
                        value={xUsername}
                        onChange={e => setXUsername(e.target.value)}
                        helperText="e.g., yourusername"
                    />
                    <TextField
                        label='Kick Username (If applicable)'
                        name='onboard-kick'
                        value={kickUsername}
                        onChange={e => setKickUsername(e.target.value)}
                        helperText="e.g., yourusername"
                    />
                    <TextField
                        label='Twitch Username (If applicable)'
                        name='onboard-twitch'
                        value={twitchUsername}
                        onChange={e => setTwitchUsername(e.target.value)}
                        helperText="e.g., yourusername"
                    />

                    {/* Make it clear that this final step actually creates the account */}
                    <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                        Final step: To create your CreatorConnect account, please sign up with Google below. This links your email securely and completes your registration.
                    </Typography>
                    <Box sx={{ alignSelf: 'flex-end' }}>
                        <GoogleLogin
                            size='large'
                            onSuccess={handleCredentialResponse}
                            onError={handleLoginFailure}
                            /* 
                              Many versions of react-oauth/google support a "text" prop.
                              If available, you can do: text='signup_with' or text='signup' 
                              to change the label to “Sign up with Google.”
                            */
                        />
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default CreatorOnboard;
