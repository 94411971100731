import React from "react";
import {
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  IconButton,
  MenuItem,
  Checkbox,
} from "@mui/material";
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import { getFollowersByVideos, getRelatedPromotions, getTotalFollowersInDict, platformRatesListFromCreator, platformRatesListFromSelectedKeys, promotionLabelsFromList } from "../../../Utils/constants";
import { RateField } from "../../../Components/RateField";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function VideoDetails({ creator, video, index, videoIndex, promotions, handleOptionChange, handleRemoveVideo, videoAmount, triedNext }) {
  const crossPostOptions = platformRatesListFromSelectedKeys(creator, getRelatedPromotions(video.rateKey));
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', minWidth: '0px', maxWidth: '100%' }}>
        <IconButton sx={{ mx: 2 }}
          disabled={videoAmount <= 1}
          onClick={() => handleRemoveVideo(index, videoIndex)}
        >
          <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
        </IconButton>
        <Box sx={{ flex: 1, minWidth: '0px' }}>
          <TextField
            value={video.rateKey}
            onChange={(e) =>
              handleOptionChange(index, 'videos', e.target.value, 'rateKey', videoIndex)
            }
            label="Promotion Type"
            select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 1 }}
            error={triedNext && !video.rateKey}
            helperText={
              triedNext && !video.rateKey
                ? 'Select a promotion for this video'
                : ''
            }
          >
            {promotions.map((promo) => (
              <MenuItem
                key={`${creator.creator}-${video.id}-${promo.rateKey}`}
                value={promo.rateKey}
              >
                {promo.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Crossposting"
            select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 1 }}
            SelectProps={{
              multiple: true,
              renderValue: (selected) => promotionLabelsFromList(selected).join(', ')
            }}
            value={[...video.crossPostingKeys]}
            onChange={(e) =>
              handleOptionChange(index, 'videos', e.target.value, 'crossPostingKeys', videoIndex)
            }
          >
            {crossPostOptions.map((promo) => (
              <MenuItem key={`${creator.creator}-${video.id}-cross-${promo.rateKey}`} value={promo.rateKey}>
                <Checkbox checked={video.crossPostingKeys.has(promo.rateKey)} />
                <ListItemText primaryTypographyProps={{ sx: { fontWeight: 'normal !important' } }} primary={promo.label} />
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Divider variant='inset' flexItem sx={{ mb: 2 }} ></Divider>
    </>
  )
}

const hasInvalidRateKey = (videos) => {
  return videos.some((video) => video.rateKey === null || video.rateKey === "");
};

// Custom UI component to display campaign details
const CampaignDetailsUI = ({ campaignDetails, newCreators, handleAddVideo, handleRemoveVideo, handleOptionChange, handlePriceChange, triedNext }) => {
  if (!campaignDetails) return <Typography>Loading campaign details...</Typography>;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Campaign Details</Typography>
      <Typography variant="body1">Name: {campaignDetails.name}</Typography>
      <Typography variant="body1">Brief: {campaignDetails.brief}</Typography>
      <Typography variant="body1">Proposal Date: {campaignDetails.proposal_date}</Typography>
      <Typography variant="body1">Ideal Due Date: {campaignDetails.ideal_duedate}</Typography>
      <Typography variant="body1">Campaign Sum: ${campaignDetails.campaign_sum}</Typography>
      <Divider style={{ margin: "20px 0" }} />
      <Typography variant="h6">Creators</Typography>
      <List>
        {campaignDetails.creators.map((creator, index) => (
          <ListItem key={index} sx={{ p: 1, mb: 1, borderRadius: 1 }}>
            <ListItemText primary={`${creator.name}`} secondary={`Followers: ${(getTotalFollowersInDict(getFollowersByVideos(creator.videos)))?.toLocaleString() || "N/A"}`} />
            <ListItemText primary={`Price: ${creator.price}`} secondary={
              creator.videos.filter(video => video.parentId == null).map(video => ({
                ...video,
                crossposts: creator.videos.filter(cross => cross.parentId == video.id).map(cross => ({
                  ...cross,
                }))
              })).map(video => {
                return (<React.Fragment key={`${creator.creator}-${video.id}`}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    <MovieCreationIcon fontSize='small' sx={{ verticalAlign: "middle", marginInlineEnd: '0.25em', marginBlockEnd: '0.25em' }}></MovieCreationIcon>
                    {video.promotionPlatform} - {video.promotionType}
                  </Typography>
                  <Box sx={{ marginInlineStart: '3em' }}>
                    {[...video.crossposts].map(crossPost => {
                      return (
                        <Typography
                          key={`${creator.creator}-${video.id}-cross-${crossPost.id}`}
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          Crosspost: {crossPost.promotionPlatform}
                        </Typography>
                      );
                    })}
                  </Box>
                </React.Fragment>)
              })
            } />
          </ListItem>
        ))}
      </List>
      <Divider style={{ margin: "20px 0" }} />
      <Typography variant="h6">New Creators Added</Typography>
      <List>
        {newCreators.map((creator, index) => {

          const promotions = platformRatesListFromCreator(creator);
          const hasEmptyKey = hasInvalidRateKey(creator.videos);

          return (
            <ListItem key={index}>
              <ListItemText primary={creator.creator} secondary={
                <>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                      <TextField
                        size='small'
                        disabled
                        fullWidth
                        sx={{ mb: 2, }}
                        inputProps={{ min: 1 }}
                        value={`${creator.videos.length || 0} Video${creator.videos.length == 1 ? '' : 's'}`}
                      ></TextField>
                      <Tooltip title={hasEmptyKey ? 'Fill the data of every video first' : null}>
                        <span>
                          <IconButton
                            disabled={hasEmptyKey}
                            onClick={() => !hasEmptyKey && handleAddVideo(index)}
                          >
                            <AddCircleOutlineIcon></AddCircleOutlineIcon>
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                  {creator.videos.map((video, videoIndex) => (
                    <VideoDetails
                      video={video}
                      creator={creator}
                      promotions={promotions}
                      handleOptionChange={handleOptionChange}
                      handleRemoveVideo={handleRemoveVideo}
                      triedNext={triedNext}
                      index={index}
                      videoIndex={videoIndex}
                      videoAmount={creator.videos.length}
                      key={`${creator.creator}-videos-${video.id}`}
                    ></VideoDetails>
                  ))}
                  <RateField
                    error={
                      triedNext && (!creator.basePrice || creator.basePrice === '0')
                    }
                    helperText={
                      triedNext && (!creator.basePrice || creator.basePrice === '0')
                        ? 'Enter a rate for this creator'
                        : ''
                    }
                    label="Base Price"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={creator.basePrice}
                    onChange={(value) => handlePriceChange(index, value)}
                    sx={{ mb: 2 }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={creator.usageRights}
                        onChange={(e) =>
                          handleOptionChange(index, 'usageRights', e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Usage Rights"
                    sx={{ mb: 2 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    Adjusted Price: ${creator.adjustedPrice.toFixed(2)}
                  </Typography>
                </>
              } />
            </ListItem>
          )
        })}
      </List>
    </Box>
  );
};

export default CampaignDetailsUI;
