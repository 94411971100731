import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Container,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Toolbar,
  Chip,
  keyframes,
  Stack
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HomeHeader from '../../../../Components/HomeHeader';
import HomeFooter from '../../../../Components/HomeFooter';
import { globalStyles } from '../../../../Utils/Styles';
import { lightTheme } from '../../../../Utils/baseTheme';
import { ThemeProvider } from '@mui/material/styles';
import client from '../../../../API';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatLinksInMessage } from '../../../../Utils/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const AnimatedTimeBlock = ({ value, label, isMobile }) => (
  <Box
    sx={{
      textAlign: 'center',
      mx: 1,
      color: 'text.primary'
    }}
  >
    <Box
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 1,
        minWidth: 60,
        animation: `${pulse} 1s ease-in-out`,
        boxShadow: 2
      }}
    >
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ color: 'text.primary' }}
      >
        {value.toString().padStart(2, '0')}
      </Typography>
    </Box>
    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
      {label}
    </Typography>
  </Box>
);

const CountdownTimer = ({ proposalDate }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!proposalDate) return;

    const calculateTimeLeft = () => {
      const proposalTime = new Date(proposalDate);
      const deadline = new Date(proposalTime.getTime() + 24 * 60 * 60 * 1000);
      const now = new Date();
      const difference = deadline - now;
      setTimeLeft(Math.floor(difference / 1000) * 1000);
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [proposalDate]);

  if (!proposalDate) return null;

  const isExpired = timeLeft <= 0;
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  return (
    <Box
      sx={{
        backgroundColor: isExpired ? 'warning.dark' : 'error.main',
        p: 2,
        borderRadius: 1,
        mb: 3,
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        gap: 1,
        boxShadow: 2
      }}
    >
      {isExpired ? (
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          This offer is still available, but could be removed soon, accept now to confirm!
        </Typography>
      ) : (
        <>
          <Typography variant="body1" sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
            ⏳ Time Sensitive:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <AnimatedTimeBlock
              value={hours}
              label={isMobile ? 'hrs' : 'hours'}
              isMobile={isMobile}
            />
            <AnimatedTimeBlock
              value={minutes}
              label={isMobile ? 'mins' : 'minutes'}
              isMobile={isMobile}
            />
            <AnimatedTimeBlock
              value={seconds}
              label={isMobile ? 'secs' : 'seconds'}
              isMobile={isMobile}
            />
          </Box>
          <Typography variant="body1" sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
            left to accept!
          </Typography>
        </>
      )}
    </Box>
  );
};

const BriefRenderer = ({ text, driveLink, videos }) => {

  const sections = {
    creative: [],
    crossposting: [],
    usage: '',
    instructions: []
  };

  let currentSection = null;

  text.split('\n').forEach(line => {
    if (line.startsWith('# Creative')) {
      currentSection = 'creative';
    } else if (line.startsWith('# Posting Instructions')) {
      currentSection = 'instructions';
    } else if (line.startsWith('# Additional Requirements')) {
      currentSection = 'additional';
    } else if (currentSection) {
      if (currentSection === 'additional') {
        if (line.startsWith('Crosspost(s):')) {
          sections.crossposting = line.replace('Crosspost(s):', '').trim().split(', ');
        } else if (line.startsWith('Usage:')) {
          sections.usage = line.replace('Usage:', '').trim();
        }
      } else {
        sections[currentSection].push(line.trim());
      }
    }
  });

  const videoList = videos?.filter(video => video.parentId == null).map(video => ({
    ...video
  })) || [];

  const crosspostList = videos?.filter(video => video.parentId != null).map(video => ({
    ...video
  })) || [];

  return (
    <Box sx={{ mt: 3 }}>
      {sections.creative.length > 0 && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Creative Brief
            </Typography>
            {sections.creative.map((line, index) => (
              <Typography key={`creative-${index}`} paragraph>
                {formatLinksInMessage(line)}
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}

      {sections.instructions.length > 0 && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Posting Requirements
            </Typography>
            {sections.instructions.map((line, index) => (
              <Typography key={`instructions-${index}`} paragraph>
                {formatLinksInMessage(line)}
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}

      {(videos.length > 0 || sections.usage) && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Additional Requirements
            </Typography>

            {videos.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2">Content requested:</Typography>
                <Stack direction='row' alignItems='center' spacing={1} marginBlockEnd={1}>
                  <Typography variant="subtitle2">Promotions (x{videoList.length} video{videoList.length == 1 ? '' : 's'}): </Typography>
                  {videoList.map(video => (<Chip key={`video-${video.id}`} color='primary' label={`${video.promotionPlatform} - ${video.promotionType}`}></Chip>))}
                </Stack>

                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography variant="subtitle2">Crossposts: </Typography>
                  {crosspostList.map(video => (<Chip key={`cross-${video.id}`} color='secondary' label={`${video.promotionPlatform}`}></Chip>))}
                </Stack>
              </Box>
            )}

            {sections.usage && (
              <Box>
                <Typography variant="subtitle2">Usage Rights:</Typography>
                <Typography variant="body2">{sections.usage}</Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {driveLink && (
        <Button
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          href={driveLink}
          target="_blank"
        >
          Download Campaign Assets
        </Button>
      )}
    </Box>
  );
};

const CampaignOfferPage = () => {
  const { creator, campaignId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [offerDetails, setOfferDetails] = useState(null);
  const [clientData, setClientData] = useState(null);
  // New state to store creator data from the route
  const [creatorData, setCreatorData] = useState(null);

  const fetchOfferDetails = async () => {
    try {
      setLoading(true);
      const response = await client.campaigns.getCreatorSpecs({
        creator: encodeURIComponent(creator),
        campaignId
      });

      if (response.status === 'success') {
        setOfferDetails(response.data);

        if (response.clientData && response.clientData.client_id) {
          setClientData(response.clientData);
        } else {
          setClientData(null);
        }

        if (response.creator_data) {
          setCreatorData(response.creator_data);
        } else {
          setCreatorData(null);
        }
      } else {
        setError(response.error || 'Failed to load offer details');
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOfferDetails();
    // eslint-disable-next-line
  }, [creator, campaignId]);

  const handleOfferResponse = async (accepted) => {
    try {
      setLoading(true);
      const response = await client.campaigns.updateCampaignCreatorSpecs({
        campaignId,
        creators: [
          {
            id: creator,
            status: accepted ? 'Accepted' : 'Declined'
          }
        ]
      });

      if (response.status === 'success') {
        navigate('/creatorconnect/collabs?tab=Offered');
      } else {
        setError(response.error || 'Failed to submit response');
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  const handleAuthRedirect = (action) => {
    navigate(
      `/login?tab=creator&redirect=${encodeURIComponent(
        '/creatorconnect/collabs?tab=Offered'
      )}&action=${action}`
    );
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ color: '#fff', zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundImage: 'linear-gradient(to bottom, #ffffff, #e0e0e0)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <HomeHeader />
        <Toolbar />

        <Container
          sx={{
            ...globalStyles.wholeHeightMinusToolbar,
            py: 2,
            maxWidth: { xs: 'sm', md: 'md' },
            width: '100%',
            flex: 1
          }}
        >
          {error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, sm: 4 },
                mt: { xs: 1, sm: 2 }
              }}
            >
              {offerDetails?.status === 'Pitched' && (
                <CountdownTimer proposalDate={offerDetails.proposal_date} />
              )}

              <Box sx={{ margin: 2 }}>
                <Stepper activeStep={0}>
                  <Step>
                    <StepLabel>Campaign Offer Details</StepLabel>
                  </Step>
                </Stepper>
              </Box>

              <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{ fontSize: { xs: '1.8rem', sm: '2.125rem' } }}
              >
                {offerDetails?.campaignName} for {creator}
              </Typography>

              <Card variant="outlined" sx={{ mt: 3, mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Campaign Overview
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography>Due Date:</Typography>
                    <Typography fontWeight="bold">
                      {new Date(offerDetails?.idealDueDate).toLocaleDateString(
                        'en-US',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        }
                      )}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>

              <>
                <Divider sx={{ my: 3 }} />
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Campaign Brief & Requirements
                  </Typography>
                  <BriefRenderer
                    text={offerDetails?.creatorBrief}
                    driveLink={offerDetails?.drive_link}
                    videos={offerDetails?.videos}
                  />
                </Box>
              </>

              <Divider sx={{ my: 3 }} />

              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Financial Terms
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    mb: 2
                  }}
                >
                  <Typography>Offer Value:</Typography>
                  <Typography fontWeight="bold">
                    $
                    {(
                      offerDetails?.agencyRate || offerDetails?.price || 0
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography>Payment Terms:</Typography>
                  <Typography fontWeight="bold">
                    30 days standard or instant via BlitzPay
                  </Typography>
                </Box>
              </Box>

              {clientData && clientData.client_id && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom>
                    About The Client
                  </Typography>
                  <Card sx={{ mt: 2 }}>
                    <CardContent>
                      {clientData.profilephoto && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 2
                          }}
                        >
                          <img
                            src={clientData.profilephoto}
                            alt={`${clientData.full_name}'s Profile`}
                            style={{ width: 120, height: 120, borderRadius: '50%' }}
                          />
                        </Box>
                      )}
                      <Typography variant="h6">
                        {clientData.company || 'N/A'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                        {clientData.full_name || ''}
                      </Typography>

                      {clientData.bio && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                          {clientData.bio}
                        </Typography>
                      )}

                      {clientData.location && (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Location: {clientData.location}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              )}

              {/* Bottom Buttons Logic */}
              {offerDetails?.status === 'Accepted' ? (
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    my: 4,
                    color: 'success.dark',
                    fontWeight: 'medium',
                    p: 2,
                    backgroundColor: 'rgba(46, 125, 50, 0.1)',
                    borderRadius: 2
                  }}
                >
                  You have accepted this offer
                </Typography>
              ) : offerDetails?.status === 'Counter' ? (
                <Box
                  sx={{
                    textAlign: 'center',
                    mt: 4,
                    p: 3,
                    backgroundColor: 'action.hover',
                    borderRadius: 2
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    This Deal Is Under Negotiation, Login to Blitz to see more details
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login?tab=creator')}
                  >
                    Login
                  </Button>
                </Box>
              ) : (
                // New conditional rendering based on creatorData
                creatorData && creatorData.manager !== "TCC" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => navigate('/login?tab=creator')}
                    sx={{ mt: 4 }}
                  >
                    Manage this Deal in My Agency
                  </Button>
                ) : creatorData && creatorData.manager === "TCC" && !creatorData.is_user ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => navigate(`/creatorconnect/${creator}`)}
                    sx={{ mt: 4 }}
                  >
                    Claim Blitz Account
                  </Button>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      gap: 2,
                      mt: 4
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      size="large"
                      onClick={() => handleOfferResponse(true)}
                      disabled={loading}
                      sx={{ flex: 1 }}
                    >
                      Accept Offer
                    </Button>
                    <Button
                      variant="contained"
                      color="warning"
                      size="large"
                      onClick={() => handleAuthRedirect('negotiate')}
                      sx={{ flex: 1 }}
                    >
                      Negotiate Offer
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => handleAuthRedirect('chat')}
                      sx={{ flex: 1 }}
                    >
                      Chat with Brand
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="large"
                      onClick={() => handleOfferResponse(false)}
                      disabled={loading}
                      sx={{ flex: 1 }}
                    >
                      Decline
                    </Button>
                  </Box>
                )
              )}
            </Paper>
          )}

          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={() => setError('')} severity="error">
              {error}
            </Alert>
          </Snackbar>
        </Container>

        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default CampaignOfferPage;
