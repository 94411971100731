import React, { useEffect, useState, useRef } from "react";
import {
  useParams,
  useSearchParams,
  Link as RouterLink,
  useNavigate
} from "react-router-dom";
import { styled } from "@mui/material/styles";
import client from "../../../../API";
import { useMutation, useQuery } from "react-query";
import {
  Typography,
  Box,
  Divider,
  Toolbar,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  Link,
  Avatar,
  useMediaQuery,
  CircularProgress
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
  Analytics as AnalyticsIcon,
  Download as DownloadIcon,
  Google as GoogleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";

// AUTH HOOK
import useAuth from "../../../../Hooks/use-auth.js";

import HomeHeader from "../../../../Components/HomeHeader.js";
import PageTitle from "../../../../Components/PageTitle.js";
import TabPanel from "../../../../Components/TabPanel.js";
import CampaignReportingPanel from "./campaignTabs/campaignReporting.js";
import CampaignDetailsPanel from "./campaignTabs/campaignDetails.js";
import CampaignProjectionsPanel from "./campaignTabs/campaignProjections.js";
import CRMDialog from "../../../Misc/crmComponents/crmPopup.js";
import RegisterPopup from "./campaignTabs/registerpopup.js";
import {
  formatLinksInMessage,
  parseLocalFloat
} from "../../../../Utils/constants.js";

// -- Styled components --
const GradientHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  padding: theme.spacing(3, 2),
  width: "100%",
  margin: 0,
  borderRadius: 0,
  boxShadow: theme.shadows[2],
}));

const ModernToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  borderRadius: "8px !important",
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
  },
}));

const CampaignDetailsPage = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sharedEmail = searchParams.get("shared_email");

  const [selectedTab, setSelectedTab] = useState(0);
  const [viewMode, setViewMode] = useState("list");
  const [showInstructions, setShowInstructions] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
  const [showCRMDialog, setShowCRMDialog] = useState(true);

  // If user not logged in, show a "please login" dialog
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);

  const hasShownMessage = useRef(false);

  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser(); // null if not logged in
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // 1. Fetch campaign data
  const {
    data: campaignDetails,
    isLoading,
    error,
    refetch: refetchCampaign
  } = useQuery(
    ["campaignDetails", campaignId],
    () => client.campaigns.fetch(campaignId),
    {
      onSuccess: (data) => {
        // If draft and first time, show instructions
        if (data.campaign_status === "Draft" && !hasShownMessage.current) {
          setShowInstructions(true);
          hasShownMessage.current = true;
        }
      },
      onError: (err) => {
        console.error("Failed to fetch campaign details:", err);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // 2. If the campaign has "shared_email" but user is not logged in => show registration
  useEffect(() => {
    if (sharedEmail && !userInfo) {
      setShowRegisterPopup(true);
    }
  }, [sharedEmail, userInfo]);

  // 3. Adjust default view based on device
  useEffect(() => {
    setViewMode(isDesktop ? "list" : "dashboard");
  }, [isDesktop]);

  // 5. Mutation for changing a creator’s status (Approve, Drop, etc.)
  const { mutate: updateCreatorStats } = useMutation(
    (payload) => client.campaigns.updateCreatorStats(payload),
    {
      onSuccess: () => refetchCampaign(),
      onError: (err) => {
        alert(err?.response?.data?.error || err.message);
      },
    }
  );

  const handleStatusChange = (creatorId, status) => {
    updateCreatorStats({ campaignId, creators: [{ id: creatorId, status }] });
  };

  // 6. "Negotiate" button now simply sends user to login if not logged in
  const handleNegotiateClick = () => {
    if (!userInfo) {
      navigate("/login");
      return;
    }
    // Redirect the logged-in user to the specific campaign page
    navigate(`/editcampaign/${campaignId}`);
  };
  

  // Tabs
  const handleChangeTab = (e, newValue) => setSelectedTab(newValue);

  // Toggle list/dashboard
  const handleChangeView = (event, newValue) => {
    if (newValue !== null) {
      setViewMode(newValue);
    }
  };

  // Export logic (CSV / Google Sheets)
  const downloadCSV = () => {
    if (!campaignDetails) return;
    const csvContent = convertToCSV(campaignDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `campaign_details_${campaignId}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    if (!campaignDetails) return;
    try {
      const response = await client.campaigns.exportSheet({
        campaignId,
        campaignDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      }
    } catch (err) {
      console.error("Export failed:", err?.response?.data?.error || err.message);
    }
  };

  const convertToCSV = (data) => {
    if (!data?.creators?.length) return "";
    const headers = [
      "Name",
      "Following",
      "Status",
      "Price",
      "Offer Sent",
      "Assets Sent",
      "Assets Approved",
      "Promotion Type",
      "Promotion Platform",
      "Likes",
      "Comments",
      "Total Views",
      "Post Date",
      "Platform Link",
      "Live Link",
    ];

    const rows = data.creators.flatMap((creator) =>
      creator.videos.map((video) => [
        creator.name ?? "",
        creator.following ?? "",
        creator.status ?? "",
        creator.price ?? "",
        creator.offerSent ? "Yes" : "No",
        creator.assetsSent ? "Yes" : "No",
        creator.assetsApproved ? "Yes" : "No",
        video.promotionType ?? "",
        video.promotionPlatform ?? "",
        video.likes ?? 0,
        video.comments ?? 0,
        video.totalViews ?? 0,
        video.postDate ?? "",
        video.platformLink ?? "",
        video.liveLink ?? "",
      ])
    );

    return [headers, ...rows].map((row) => row.join(",")).join("\n");
  };

  const handleExportClick = (event) => {
    // If not logged in => go to login
    if (!userInfo) {
      navigate("/login");
      return;
    }
    setExportMenuAnchor(event.currentTarget);
  };

  const handleExportClose = () => setExportMenuAnchor(null);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!campaignDetails) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">No campaign data found.</Typography>
      </Box>
    );
  }

  const isCampaignActive = !["Launched", "Completed"].includes(
    campaignDetails.campaign_status
  );

  return (
    <>
      <HomeHeader />
      <Toolbar />
      <PageTitle title={`${campaignDetails.name} - Blitz`} />

      <GradientHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {/* Left side */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 800,
                  letterSpacing: "-0.03em",
                  color: "common.white",
                }}
              >
                {campaignDetails.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{ opacity: 0.9, fontWeight: 400, color: "common.white", whiteSpace: "pre" }}
              >
                {formatLinksInMessage(campaignDetails.brief)}
              </Typography>
            </Box>

            {/* View Toggle & Export Menu */}
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleChangeView}
                sx={{ bgcolor: "background.paper", borderRadius: 2 }}
              >
                <ModernToggleButton value="list">
                  <VisibilityIcon sx={{ mr: 1 }} /> List View
                </ModernToggleButton>
                <ModernToggleButton value="dashboard">
                  <DashboardIcon sx={{ mr: 1 }} /> Dashboard
                </ModernToggleButton>
              </ToggleButtonGroup>

              <Button
                variant="contained"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleExportClick}
                sx={{
                  borderRadius: 2,
                  px: 3,
                  py: 1,
                  boxShadow: 2,
                  bgcolor: "#FFEB3B",
                  color: "#9C27B0",
                  "&:hover": {
                    boxShadow: 3,
                    bgcolor: "#FDD835",
                  },
                }}
              >
                Export
              </Button>

              <Menu
                anchorEl={exportMenuAnchor}
                open={Boolean(exportMenuAnchor)}
                onClose={handleExportClose}
              >
                <MenuItem
                  onClick={() => {
                    if (!userInfo) {
                      navigate("/login");
                      return;
                    }
                    downloadCSV();
                    handleExportClose();
                  }}
                >
                  <DownloadIcon sx={{ mr: 1 }} />
                  CSV Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (!userInfo) {
                      navigate("/login");
                      return;
                    }
                    exportToSheets();
                    handleExportClose();
                  }}
                >
                  <GoogleIcon sx={{ mr: 1 }} />
                  Google Sheets
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          {/* Right side: "Presented By" */}
          {campaignDetails.owner && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginTop: { xs: 2, md: 0 },
              }}
            >
              <Avatar
                src={campaignDetails.owner.phphref || ""}
                alt={`${campaignDetails.owner.first_name} ${campaignDetails.owner.last_name}`}
                sx={{ width: 48, height: 48 }}
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: "common.white", fontWeight: 600 }}
                >
                  Presented By
                </Typography>
                <Link
                  component={RouterLink}
                  to={`/user/${campaignDetails.owner.id}`}
                  variant="body2"
                  underline="hover"
                  sx={{ color: "common.white", fontWeight: 400 }}
                >
                  {campaignDetails.owner.first_name}{" "}
                  {campaignDetails.owner.last_name}
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </GradientHeader>

      <Box sx={{ maxWidth: 1600, margin: "0 auto", px: { xs: 2, md: 4 } }}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-indicator": {
              height: 3,
              borderRadius: 2,
            },
          }}
        >
          <Tab label="Campaign Overview" icon={<AssignmentIcon />} />
          <Tab label="Performance Projections" icon={<TrendingUpIcon />} />
          <Tab label="Creator Analytics" icon={<AnalyticsIcon />} />
        </Tabs>

        <Divider sx={{ my: 3 }} />

        <Box
          sx={{
            padding: { xs: 0, md: 3 },
            borderRadius: 4,
            bgcolor: "background.paper",
          }}
        >
          {/* TAB 1: Campaign Overview */}
          <TabPanel value={selectedTab} index={0}>
            <CampaignDetailsPanel
              campaignDetails={campaignDetails}
              viewMode={viewMode}
              isCampaignActive={isCampaignActive}
              handleStatusChange={handleStatusChange}
              onNegotiateClick={handleNegotiateClick} // <-- Removed negotiation logic
            />
          </TabPanel>

          {/* TAB 2: Projections */}
          <TabPanel value={selectedTab} index={1}>
            <CampaignProjectionsPanel campaignDetails={campaignDetails} />
          </TabPanel>

          {/* TAB 3: Creator Analytics */}
          <TabPanel value={selectedTab} index={2}>
            <CampaignReportingPanel
              viewsByDayData={
                campaignDetails.creators
                  ?.reduce((acc, creator) => {
                    const date = creator.postDate || new Date().toISOString();
                    const views = parseInt(creator.totalViews || 0, 10);
                    const existing = acc.find((e) => e.date === date);
                    if (existing) {
                      existing.views += views;
                    } else {
                      acc.push({ date, views });
                    }
                    return acc;
                  }, [])
                  .sort((a, b) => new Date(a.date) - new Date(b.date)) || []
              }
              costPerCreatorData={
                campaignDetails.creators?.map((c) => ({
                  name: c.name,
                  price: parseLocalFloat(
                    String(c.price || 0).replace(/[^0-9.-]/g, "")
                  ),
                })) || []
              }
              reachData={
                campaignDetails.creators?.map((c) => ({
                  name: c.name,
                  value: parseInt(
                    String(c.following || 0).replace(/,/g, ""),
                    10
                  ),
                })) || []
              }
              creatorIds={campaignDetails.creators?.map((c) => c.id) || []}
            />
          </TabPanel>
        </Box>
      </Box>

      {/* Register popup if shared link + no login */}
      <RegisterPopup
        open={showRegisterPopup}
        onClose={() => setShowRegisterPopup(false)}
        initialEmail={sharedEmail}
      />

      {/* CRM popup */}
      {showCRMDialog && (
        <CRMDialog
          isOpen={showCRMDialog}
          handleClose={() => setShowCRMDialog(false)}
          origin={`${campaignDetails.name} - campaign`}
        />
      )}

      {/* Intro instructions if campaign is draft */}
      <Dialog open={showInstructions} onClose={() => setShowInstructions(false)}>
        <DialogTitle>Welcome to Blitz! 🚀</DialogTitle>
        <DialogContent>
          <Typography>
            This is your campaign proposal sheet featuring hand-picked creators
            for your campaign. Each creator comes with recommended pricing and
            media kits. Manage creator statuses (Approve/Drop/Negotiate), export
            data to CSV/Sheets, and track campaign progress. Once launched, this
            becomes your real-time tracking dashboard with creator analytics and
            post performance data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInstructions(false)} color="primary">
            Let&apos;s Get Started!
          </Button>
        </DialogActions>
      </Dialog>

      {/* Not Logged-In Dialog */}
      <Dialog open={loginDialogOpen} onClose={() => setLoginDialogOpen(false)}>
        <DialogTitle>Please Login or Create an Account</DialogTitle>
        <DialogContent>
          <Typography>You must be logged in to perform this action.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/login")} color="primary">
            Login
          </Button>
          <Button onClick={() => navigate("/onboard")} color="primary">
            Create an Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignDetailsPage;
