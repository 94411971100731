// src/components/Admin/sections/NotificationsDetailsView.jsx

import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar
} from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import client from '../../../../../API';

const NotificationsDetailsView = () => {
  // State for form
  const [selectedUser, setSelectedUser] = useState('all');
  const [purpose, setPurpose] = useState('');
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const [partnershipId, setPartnershipId] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Fetch all users for the dropdown
  const {
    data: allUsers,
    isLoading: loadingUsers,
    error: usersError,
  } = useQuery(
    ['admin', 'allUsers'],
    () => client.users.userAdminFetch(),
    {
      refetchOnWindowFocus: false,
    }
  );

  // Mutation: create notification
  const createNotificationMutation = useMutation(
    (payload) => client.notifications.createNotification(payload),
    {
      onSuccess: (data) => {
        setSnackbarMessage(data?.message || 'Notification created successfully');
        setSnackbarOpen(true);
        // Clear form if you want
        setPurpose('');
        setMessage('');
        setUrl('');
        setCampaignId('');
        setPartnershipId('');
      },
      onError: (error) => {
        console.error('Error creating notification:', error);
        setSnackbarMessage('Error creating notification');
        setSnackbarOpen(true);
      },
    }
  );

  const handleSubmit = () => {
    // Build payload
    const payload = {
      user_id: selectedUser,
      purpose,
      message,
      url: url || undefined,
      campaign_id: campaignId || undefined,
      partnership_id: partnershipId || undefined,
    };
    createNotificationMutation.mutate(payload);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Admin Notifications
      </Typography>

      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Create a Notification
        </Typography>

        {loadingUsers && <CircularProgress />}

        {usersError && (
          <Typography color="error">
            Error loading users: {String(usersError)}
          </Typography>
        )}

        {!loadingUsers && allUsers && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="user-select-label">Select User</InputLabel>
            <Select
              labelId="user-select-label"
              value={selectedUser}
              label="Select User"
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <MenuItem value="all">All Users</MenuItem>
              {allUsers.map((u) => (
                <MenuItem key={u.id} value={u.id}>
                  {`${u.first_name} ${u.last_name}`} (ID: {u.id})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          label="Purpose"
          fullWidth
          margin="normal"
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
        />

        <TextField
          label="Message"
          fullWidth
          margin="normal"
          multiline
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <TextField
          label="Optional URL"
          fullWidth
          margin="normal"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />

        <TextField
          label="Campaign ID (optional)"
          fullWidth
          margin="normal"
          value={campaignId}
          onChange={(e) => setCampaignId(e.target.value)}
        />

        <TextField
          label="Partnership ID (optional)"
          fullWidth
          margin="normal"
          value={partnershipId}
          onChange={(e) => setPartnershipId(e.target.value)}
        />

        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={createNotificationMutation.isLoading}
          sx={{ mt: 2 }}
        >
          {createNotificationMutation.isLoading ? 'Creating...' : 'Create Notification'}
        </Button>
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default NotificationsDetailsView;
