// LeftPanel.js
import React from 'react';
import {
  Box,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
  IconButton,
  Badge,
  Button,
  ListItemButton
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

// Helper function to determine the display name of a conversation:
const getConvoName = (convo, username) => {
  // 1) If a display_name exists, use it:
  if (convo.display_name) return convo.display_name;

  // 2) Otherwise, fall back to these:
  if (convo.campaign_name) return convo.campaign_name;
  if (convo.partnership_name) return convo.partnership_name;

  // 3) If single user, display their name:
  const otherUser = convo.participants?.find((p) => p.id !== username);
  if (otherUser) {
    return `${otherUser.first_name} ${otherUser.last_name}`;
  }

  // 4) Fallback:
  return `Conversation #${convo.id}`;
};

export default function LeftPanel({
  isLoadingConversations,
  conversations,
  selectedConversationId,
  isLeftPanelCollapsed,
  handleSelectConversation,
  toggleLeftPanel,
  userId,
  onCreateNewChatClicked
}) {
  return (
    <Card
      sx={{
        width: isLeftPanelCollapsed ? 0 : { xs: '100%', md: 400 },
        minWidth: isLeftPanelCollapsed ? 0 : { xs: '100%', md: 400 },
        height: '100%',
        transition: 'all 0.3s ease',
        overflow: 'hidden',
        position: { xs: 'absolute', md: 'relative' },
        zIndex: 1,
        left: isLeftPanelCollapsed ? '-100%' : 0,
        display: { xs: selectedConversationId ? 'none' : 'block', md: 'block' }
      }}
    >
      <CardContent sx={{ p: 0, height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" sx={{ mr: 2 }}>
            Active Conversations
          </Typography>
          <IconButton onClick={toggleLeftPanel} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />

        {/* CREATE NEW CHAT BUTTON */}
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateNewChatClicked}
            fullWidth
          >
            Create New Chat
          </Button>
        </Box>
        <Divider />

        {/* Conversations List */}
        <List sx={{ flex: 1, overflowY: 'auto' }}>
          {isLoadingConversations ? (
            [...Array(5)].map((_, i) => (
              <Skeleton key={i} variant="rectangular" height={60} sx={{ m: 1 }} />
            ))
          ) : (
            conversations.map((convo) => (
              <ListItemButton
                key={convo.id}
                onClick={() => handleSelectConversation(convo.id)}
                selected={convo.id === selectedConversationId}
              >
                <ListItemAvatar>
                  <Avatar src={convo.participants.find((user) => user.id !== userId)?.phphref} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography variant="subtitle1" noWrap>
                        {getConvoName(convo, userId)}
                      </Typography>
                      <Badge
                        badgeContent={convo.unread_count}
                        color="primary"
                        max={999}
                        sx={{
                          '& .MuiBadge-badge': {
                            fontSize: '0.7rem',
                            minWidth: '18px',
                            height: '18px'
                          }
                        }}
                      >
                        <ChatBubbleOutlineIcon fontSize="small" />
                      </Badge>
                    </Box>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {convo.message || 'No messages yet'}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))
          )}
        </List>
      </CardContent>
    </Card>
  );
}
