import { 
  Button, 
  IconButton, 
  ListItemIcon, 
  Menu, 
  MenuItem, 
  Typography, 
  useMediaQuery 
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BlitzHeader from "./BlitzHeader";
import { Box } from "@mui/system";
import routes from "../Config/routes";

import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GroupIcon from "@mui/icons-material/Group";
import CampaignIcon from "@mui/icons-material/Campaign";
import BoltIcon from "@mui/icons-material/Bolt";
import PaidIcon from "@mui/icons-material/Paid";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessIcon from "@mui/icons-material/Business";

import useAuth from "../Hooks/use-auth";

export default function HomeHeader({ menuButton }) {
  const navigate = useNavigate();

  // State for main menu anchor
  const [anchorEl, setAnchorEl] = useState(null);

  // State for about submenu anchor
  const [aboutAnchorEl, setAboutAnchorEl] = useState(null);

  // State for creator submenu anchor
  const [creatorMenuAnchorEl, setCreatorMenuAnchorEl] = useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isAuthorized } = useAuth();

  // Handlers for Solutions submenu
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (benefit) => {
    setAnchorEl(null);
    navigate(`${routes.solutions}#${benefit}`);
  };

  // Handlers for About submenu
  const handleAboutMenuClick = (event) => {
    setAboutAnchorEl(event.currentTarget);
  };
  const handleAboutMenuClose = () => {
    setAboutAnchorEl(null);
  };

  // Handlers for Creators submenu
  const handleCreatorMenuClick = (event) => {
    setCreatorMenuAnchorEl(event.currentTarget);
  };
  const handleCreatorMenuClose = () => {
    setCreatorMenuAnchorEl(null);
  };
  const handleCreatorMenuItemClick = (benefit) => {
    setCreatorMenuAnchorEl(null);
    navigate(`${routes.forcreators}#${benefit}`);
  };

  return (
    <BlitzHeader menuButton={menuButton}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {isMobile ? (
          /* MOBILE MENU ICON */
          <IconButton color="inherit" onClick={handleMenuClick}>
            <MenuIcon />
          </IconButton>
        ) : (
          /* DESKTOP MENU */
          <Box>
            {/* ABOUT with Submenu */}
            <Button
              color="inherit"
              variant="text"
              onMouseEnter={handleAboutMenuClick}
            >
              About
            </Button>
            <Menu
              anchorEl={aboutAnchorEl}
              open={!!aboutAnchorEl}
              onClose={handleAboutMenuClose}
              onMouseLeave={handleAboutMenuClose}
              PaperProps={{
                style: {
                  display: "flex",
                  flexDirection: "column",
                  padding: "8px 0",
                },
              }}
            >
              <MenuItem onClick={() => navigate(routes.about)}>
                <Typography>About</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate(routes.releasteNotes)}>
                <Typography>Releases</Typography>
              </MenuItem>
            </Menu>

            {/* SOLUTIONS with Submenu */}
            <Button
              color="inherit"
              variant="text"
              onMouseEnter={handleMenuClick}
            >
              Solutions
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleMenuClose}
              onMouseLeave={handleMenuClose}
              PaperProps={{
                style: {
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px",
                  gap: "20px",
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("blitzPay")}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <Typography>BlitzPay - Accounts Payable</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("creatorSourcing")}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <Typography>Creator Sourcing</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("aiCampaignManagement")}>
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <Typography>AI Campaign Management</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("acceleratedCollaborations")}>
                <ListItemIcon>
                  <BoltIcon />
                </ListItemIcon>
                <Typography>Accelerated Creator Collaborations</Typography>
              </MenuItem>
            </Menu>

            {/* NEW TOP-LEVEL FOR AGENCIES BUTTON */}
            <Button
              color="inherit"
              variant="text"
              onClick={() => navigate(routes.solutionsAgencies)}
            >
              Agencies
            </Button>

            {/* FOR CREATORS with Submenu */}
            <Button
              color="inherit"
              variant="text"
              onMouseEnter={handleCreatorMenuClick}
            >
              For Creators
            </Button>
            <Menu
              anchorEl={creatorMenuAnchorEl}
              open={!!creatorMenuAnchorEl}
              onClose={handleCreatorMenuClose}
              onMouseLeave={handleCreatorMenuClose}
              PaperProps={{
                style: {
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px",
                  gap: "20px",
                },
              }}
            >
              <MenuItem onClick={() => handleCreatorMenuItemClick("getPaidFaster")}>
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <Typography>Get Paid Faster</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleCreatorMenuItemClick("manageDeals")}>
                <ListItemIcon>
                  <HandshakeIcon />
                </ListItemIcon>
                <Typography>Manage Brand Deals and Collabs</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleCreatorMenuItemClick("aiSalesManager")}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <Typography>AI Sales Manager</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleCreatorMenuItemClick("businessSuite")}>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <Typography>Creator Business Suite</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate(routes.parntersListL)}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <Typography>Our Partners</Typography>
              </MenuItem>
            </Menu>

            {/* ARTICLES BUTTON */}
            <Button
              color="inherit"
              variant="text"
              onClick={() => navigate(routes.articles)}
            >
              Articles
            </Button>
          </Box>
        )}
      </Box>

      {/* LOGIN / SIGNUP / DASHBOARD */}
      <Box
        display="flex"
        justifyContent="end"
        flex={1}
        overflow="visible"
        textAlign="right"
        whiteSpace="nowrap"
        minWidth="0"
      >
        {isAuthorized ? (
          <Button
            color="inherit"
            variant="text"
            onClick={() => navigate(routes.dashboard)}
          >
            Back to Blitz
          </Button>
        ) : (
          <>
            <Button 
              color="inherit" 
              variant="text" 
              onClick={() => navigate(routes.login)}
            >
              Login
            </Button>
            <Button 
              color="inherit" 
              variant="text" 
              onClick={() => navigate(routes.onboard)}
            >
              Signup
            </Button>
          </>
        )}
      </Box>

      {/* MOBILE DROPDOWN MENU */}
      {isMobile && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleMenuClose}
        >
          {/* ABOUT SUBMENU IN MOBILE */}
          <MenuItem onClick={() => navigate(routes.about)}>About</MenuItem>
          <MenuItem onClick={() => navigate(routes.releasteNotes)}>
            Releases
          </MenuItem>

          {/* SOLUTIONS */}
          <MenuItem onClick={() => handleMenuItemClick("blitzPay")}>
            Solutions
          </MenuItem>

          {/* FOR CREATORS */}
          <MenuItem onClick={() => navigate(routes.forcreators)}>For Creators</MenuItem>

          {/* ARTICLES */}
          <MenuItem onClick={() => navigate(routes.articles)}>Articles</MenuItem>

          {/* OUR PARTNERS */}
          <MenuItem onClick={() => navigate(routes.parntersListL)}>
            Our Partners
          </MenuItem>
          {/* FOR AGENCIES */}
          <MenuItem onClick={() => navigate(routes.solutionsAgencies)}>
            Agencies
          </MenuItem>
        </Menu>
      )}
    </BlitzHeader>
  );
}
