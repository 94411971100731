import React, { useState, useEffect, useMemo } from 'react'
import { StyledTableRow } from '../../../Utils/styledcell'
import { Avatar, Box, Divider, InputAdornment, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { SearchRounded } from '@mui/icons-material';
import { useCreator } from '../../../Hooks/agencySelectedCreatorContext';
import { Outlet, useNavigate } from 'react-router-dom';


function ConversationRow({ conversation, onClick }) {
    return (
        <StyledTableRow onClick={onClick} sx={{ cursor: 'pointer' }}>
            <TableCell align='left'>
                {conversation?.campaign?.name}{conversation?.partnership?.name}
            </TableCell>
            <TableCell align='right'>
                {conversation?.created_at?.toDateString() || 'N/A'}
            </TableCell>
        </StyledTableRow>
    )
}

//ideal_duedate
export default function AgencyCreatorConversations({ }) {
    const { creatorData } = useCreator();
    const navigate = useNavigate();
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const HandleClickRow = (conversationId) => {
        navigate(`${conversationId}`);
    }

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setCurrentSearch(searchInput);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId); //Cancels the debounce when more terms come in
    }, [searchInput]);

    const filteredConversations = useMemo(() => creatorData?.conversations.filter((conversation) => {
        const searchMatch = currentSearch === "" || Object.values(conversation).some((val) => typeof val === "string" && val.toLowerCase().includes(currentSearch.toLowerCase()));
        return searchMatch;
    }), [creatorData, currentSearch]);

    return (
        <>
            <Paper elevation={2} square sx={{ marginBlockStart: 2, width: '100%', overflowX: 'hidden' }}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={creatorData?.creator.pfphref || undefined}>
                            {!creatorData?.creator.pfphref && creatorData?.creator.creator[0]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={creatorData?.creator.creator || <>&nbsp;</>} secondary={creatorData?.creator.notes_content_style || <>&nbsp;</>} secondaryTypographyProps={{ sx: { textWrap: 'nowrap' } }} />
                </ListItem>
            </Paper>
            <Box sx={{ paddingInline: { xs: 1, md: 2 }, paddingBlock: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <TextField
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <SearchRounded>
                                    </SearchRounded>
                                </InputAdornment>)
                        }}>
                    </TextField>
                </Box>
                <Divider></Divider>
                <TableContainer component={Paper} elevation={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    Project
                                </TableCell>
                                <TableCell align='right'>
                                    Date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!filteredConversations && filteredConversations.map((conversation) => (
                                <ConversationRow
                                    conversation={conversation}
                                    key={conversation.id}
                                    onClick={() => HandleClickRow(conversation.id)}
                                >
                                </ConversationRow>
                            ))}
                            {!!filteredConversations && filteredConversations.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        No conversations found. Try changing the search filters!
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Outlet></Outlet>
        </>
    )
}
