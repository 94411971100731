import { Box, styled } from "@mui/material";

const ActiveSlideStyle = {
    zIndex: 5,
    opacity: 1
}

export const PortraitBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isLoaded',
    name: 'PortraitBox',
})(({ theme, isLoaded }) => ({
    width: '100%',
    height: '100vh',
    margin: 0,
    position: 'relative',
    overflow: 'hidden',
    maxHeight: '100vh',
    transition: 'opacity 2s ease-in-out, transform 2s ease-in-out',

    '@media (max-aspect-ratio: 3/4)': {
        width: '100%',
        height: '100vh',
        maxWidth: '100%',
        overflowY:'auto',
        opacity: isLoaded ? 1 : 0,
    },
    '@media (min-aspect-ratio: 3/4)': {
        width: 'auto',
        aspectRatio: '9/16',
        height: '95vh',
        maxWidth: '95vh',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: isLoaded ? 'translate(-50%, -50%)' : 'translate(-50%, 60%)',
        contain: 'layout',
        borderRadius: '12px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    },

}));

export const BackgroundPattern = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 0,
    background: 'repeating-linear-gradient(45deg, rgba(123, 42, 42, 0.3), rgba(123, 42, 42, 0.3) 10px, rgba(100, 30, 30, 0.3) 10px, rgba(100, 30, 30, 0.3) 20px)',
});

export const Background = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1,
    background: 'linear-gradient(180deg, rgb(123, 42, 42), #191414)',
});

export const Slide = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'currentIndex' && prop !== 'index',
    name: 'Slide',
})(({ theme, index, currentIndex }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: currentIndex === index ? '100%' : '100% !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    textAlign: 'center',
    opacity: 0,
    transition: 'opacity 0.8s ease-in-out',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    ...(currentIndex === index && ActiveSlideStyle),
}));

export const NavDots = styled(Box)({
    position: 'fixed',
    bottom: '2rem',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    zIndex: 10,
});

export const Dot = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'active',
    name: 'Dot',
})(({ theme, active }) => ({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: active ? 'rgba(100, 106, 255, 1)' : 'rgba(100, 106, 255, 0.3)',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    transform: active ? 'scale(1.2)' : 'scale(1)',
}));