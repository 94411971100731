import React, { useState } from "react";
import { Typography, Box, useTheme, useMediaQuery, Grid, Tabs, Tab, Container, Paper, Button, List, ListItem, ListItemText, IconButton, CircularProgress } from "@mui/material";
import MiniCreatorSearch from "../../../../Components/miniCreatorSearch";
import { ToggleSet } from "../../../../Utils/constants";
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "react-query";
import API from "../../../../API";

const CreatorList = ({ castingCall, invitedCreators, selectedItems, applicatedSet, handlePickCreator }) => (
  <Box sx={{ height: { xs: '75vh', md: '70vh' }, minHeight: '0' }}>
    <Paper sx={{ width: '100%' }}
      elevation={1}>
      <MiniCreatorSearch
        searchSettings={{ promotion_types: castingCall?.promotion_types }}
        selectedCreators={new Set([...invitedCreators, ...selectedItems])}
        disabledCreators={applicatedSet}
        onCreatorPick={handlePickCreator}
      ></MiniCreatorSearch>
    </Paper>
  </Box>
);

const InvitedList = ({ selectedItems, invitedCreators, handlePickCreator, postInvite, loadingInvite }) => (
  <Paper sx={{ px: 3, py: 2, height: { xs: '75vh', md: '70vh' }, display: 'flex', flexDirection: 'column', overflowY: 'auto' }} elevation={1}>
    <Typography variant="h6">New Invites</Typography>
    {selectedItems.size > 0 &&
      <List dense>
        {[...selectedItems].map((creator) => (
          <ListItem key={`invited-${creator}`} secondaryAction={
            <IconButton color="error" onClick={() => handlePickCreator({ creator })}>
              <DeleteIcon></DeleteIcon>
            </IconButton>}>
            <ListItemText primary={creator}></ListItemText>
          </ListItem>
        ))}
      </List>}
    {selectedItems.size < 1 && <Typography>No creators to invite.</Typography>}

    <Button
      color='secondary'
      disabled={selectedItems.size < 1 || loadingInvite}
      sx={{ alignSelf: 'flex-end' }}
      onClick={postInvite}
    >
      {loadingInvite ? <CircularProgress size={24} /> : "Invite"}
    </Button>

    {/* Placeholder for Invited Creators Component */}
    <Typography variant="h6">Invited Creators</Typography>
    {invitedCreators.length > 0 &&
      <List dense>
        {invitedCreators.map((creator) => (
          <ListItem key={`invited-${creator}`}>
            <ListItemText primary={creator}></ListItemText>
          </ListItem>
        ))}
      </List>}
    {invitedCreators.length < 1 && <Typography>No creators have been invited</Typography>}
  </Paper>
);

const InvitedCreators = ({ invitedCreators, castingCall, refetchData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileTab, setMobileTab] = useState(0);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const invitedSet = new Set(invitedCreators);
  const applicatedSet = new Set(castingCall.applications);

  const { mutate: postInvite, isLoading: loadingInvite } = useMutation({
    mutationFn: (input) => API.castingCalls.invite(castingCall.id, input),
    onSuccess: () => {
      refetchData();
      setSelectedItems(new Set());
    },
    onError: (error) => {
      console.error("Error uploading casting call:", error);
      alert(`An error ocurred while creating your Casting Call: ${error?.response?.data?.error || error.message}`);
    },
  });

  const HandleInvite = () => {
    postInvite({ creators: [...selectedItems] })
  }

  const handlePickCreator = (creator) => {
    if (invitedSet.has(creator.creator) || applicatedSet.has(creator.creator))
      return;

    ToggleSet(creator.creator, selectedItems, setSelectedItems);
  }

  return (
    <>
      {isMobile && <Box sx={{ width: '100%' }}>
        <Paper square elevation={2}>
          <Tabs
            value={mobileTab}
            onChange={(e, newValue) => setMobileTab(newValue)}
            variant="fullWidth"
          >
            <Tab label="Search" />
            <Tab label="Invited" />
          </Tabs>
        </Paper>
        <Box sx={{ paddingInline: 1 }}>
          {mobileTab === 0 && <CreatorList
            castingCall={castingCall}
            invitedCreators={invitedCreators}
            selectedItems={selectedItems}
            applicatedSet={applicatedSet}
            handlePickCreator={handlePickCreator}
          />}
          {mobileTab === 1 && <InvitedList
            selectedItems={selectedItems}
            invitedCreators={invitedCreators}
            handlePickCreator={handlePickCreator}
            postInvite={HandleInvite}
            loadingInvite={loadingInvite}
          />}
        </Box>
      </Box>}

      {!isMobile && <Grid container spacing={2}>
        <Grid item xs={5}>
          <CreatorList
            castingCall={castingCall}
            invitedCreators={invitedCreators}
            selectedItems={selectedItems}
            applicatedSet={applicatedSet}
            handlePickCreator={handlePickCreator}
          />
        </Grid>
        <Grid item xs={7}>
          <InvitedList
            selectedItems={selectedItems}
            invitedCreators={invitedCreators}
            handlePickCreator={handlePickCreator}
            postInvite={HandleInvite}
            loadingInvite={loadingInvite}
          />
        </Grid>
      </Grid>}
    </>
  );
};

export default InvitedCreators;
