import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BookingForm from '../../Components/BookingForm';

const BookingDialog = ({
  open,
  onClose,
  creator,
}) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Let's Chat!
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <BookingForm creator={creator} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default BookingDialog;
