import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { SearchRounded, ViewList, ViewModule } from '@mui/icons-material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useCreator } from '../../../Hooks/agencySelectedCreatorContext';
import { StyledTableRow } from '../../../Utils/styledcell';

// Status tabs across both campaigns & partnerships
const statusTabs = ["All", "Offered", "Active", "Completed"];

/** 
 * Map a campaign's creator-specific status or a partnership's status 
 * into our unified tab labels: "Offered", "Active", "Completed".
 */
function unifyStatus(item) {
  if (item.type === "campaign") {
    switch (item.originalStatus) {
      case "Pitched":
        return "Offered";
      case "Accepted":
        return "Active";
      case "Completed":
        return "Completed";
      default:
        return item.originalStatus || "";
    }
  } else {
    // Partnerships
    switch (item.originalStatus) {
      case "pending":
        return "Offered";
      case "active":
        return "Active";
      case "completed":
        return "Completed";
      default:
        return item.originalStatus || "";
    }
  }
}

export default function AgencyCreatorCollaborations() {
  const { creatorData } = useCreator();
  const { creatorId } = useParams();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);
  const [typeFilter, setTypeFilter] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [viewMode, setViewMode] = useState("grid");

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedSearch(searchValue), 400);
    return () => clearTimeout(timer);
  }, [searchValue]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const allCollaborations = useMemo(() => {
    if (!creatorData) return [];
    const { creator } = creatorData;

    // Process campaigns - only show Launched or Completed
    const campaignCollabs = (creatorData.campaigns || [])
      .filter((c) => ["Launched", "Completed"].includes(c.campaign_status))
      .map((c) => {
        const matchingCreator = c.creators?.find(
          (cr) => cr.id === creator?.creator
        );
        const originalStatus = matchingCreator?.status || "";
        const unifiedStatus = unifyStatus({
          type: "campaign",
          originalStatus,
        });
        return {
          id: c.id,
          name: c.name,
          type: "campaign",
          originalStatus,           // Creator-specific status
          campaignStatus: c.campaign_status, // Campaign-level status
          unifiedStatus,
          dueDate: c.ideal_duedate
            ? new Date(c.ideal_duedate).toDateString()
            : "N/A",
          offer: c?.price?.toFixed(2) || "",
        };
      });

    // Process partnerships
    const partnershipCollabs = (creatorData.partnerships || []).map((p) => {
      const originalStatus = p.status || "";
      const unifiedStatus = unifyStatus({
        type: "partnership",
        originalStatus,
      });
      return {
        id: p.id,
        name: p.name,
        type: "partnership",
        originalStatus,           // Partnership status
        unifiedStatus,
        dueDate: p.finish_date
          ? new Date(p.finish_date).toDateString()
          : "N/A",
        offer: p.total_sum?.toFixed(2) || "",
      };
    });

    return [...campaignCollabs, ...partnershipCollabs];
  }, [creatorData]);

  const filteredList = useMemo(() => {
    const tabLabel = statusTabs[tabIndex];
    return allCollaborations.filter((item) => {
      const matchesStatus =
        tabLabel === "All" ? true : item.unifiedStatus === tabLabel;
      const matchesType =
        typeFilter === "All" ? true : item.type === typeFilter;
      const searchMatch =
        !debouncedSearch ||
        [item.name, item.type, item.originalStatus, item.campaignStatus]
          .filter(Boolean) // Remove undefined values
          .join(" ")
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase());

      return matchesStatus && matchesType && searchMatch;
    });
  }, [allCollaborations, tabIndex, typeFilter, debouncedSearch]);

  const handleItemClick = (item) => {
    if (item.type === "campaign") {
      navigate(`campaigns/${item.id}`);
    } else if (item.type === "partnership") {
      navigate(`partnerships/${item.id}`);
    }
  };

  return (
    <>
      <Paper
        elevation={2}
        square
        sx={{ mt: 2, width: "100%", overflowX: "hidden" }}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar src={creatorData?.creator?.pfphref || ""}>
              {!creatorData?.creator?.pfphref &&
                creatorData?.creator?.creator?.[0]}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={creatorData?.creator?.creator || <> </>}
            secondary={creatorData?.creator?.notes_content_style || <> </>}
          />
        </ListItem>
      </Paper>

      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
          >
            {statusTabs.map((label) => (
              <Tab key={label} label={label} />
            ))}
          </Tabs>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Filter By</InputLabel>
            <Select
              label="Filter By"
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="campaign">Campaigns</MenuItem>
              <MenuItem value="partnership">Partnerships</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />

        <Divider />

        <Tabs
          value={viewMode}
          onChange={handleViewModeChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{ width: "fit-content" }}
        >
          <Tab
            icon={<ViewModule />}
            value="grid"
            aria-label="Grid View"
            title="Grid View"
          />
          <Tab
            icon={<ViewList />}
            value="list"
            aria-label="List View"
            title="List View"
          />
        </Tabs>

        <Divider />

        {viewMode === "grid" ? (
          <Grid container spacing={2}>
            {filteredList.length === 0 ? (
              <Grid item xs={12}>
                <Typography align="center" variant="body1">
                  No collaborations found.
                </Typography>
              </Grid>
            ) : (
              filteredList.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.type}-${item.id}`}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {item.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Type: {item.type}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Offer: {item.offer}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Status: {item.unifiedStatus} 
                        {item.type === "campaign" 
                          ? ` (Campaign: ${item.campaignStatus}, Creator: ${item.originalStatus})`
                          : ` (${item.originalStatus})`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Due Date: {item.dueDate}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => handleItemClick(item)}
                      >
                        View Deal
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        ) : (
          <TableContainer component={Paper} elevation={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="right">Offer</TableCell>
                  <TableCell align="right">Unified Status</TableCell>
                  <TableCell align="right">Original Status</TableCell>
                  <TableCell align="right">Due Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No collaborations found.
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredList.map((item) => (
                    <StyledTableRow
                      key={`${item.type}-${item.id}`}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleItemClick(item)}
                    >
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="center">{item.type}</TableCell>
                      <TableCell align="right">{item.offer}</TableCell>
                      <TableCell align="right">{item.unifiedStatus}</TableCell>
                      <TableCell align="right">
                        {item.type === "campaign"
                          ? `Campaign: ${item.campaignStatus}, Creator: ${item.originalStatus}`
                          : item.originalStatus}
                      </TableCell>
                      <TableCell align="right">{item.dueDate}</TableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Outlet />
    </>
  );
}