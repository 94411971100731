import React, { useMemo } from 'react'
import { getPlatformAndPromotion } from '../../../../Utils/constants';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Collapse, Typography } from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    Add as AddIcon,
} from '@mui/icons-material';
import CampaignTimelineEntry from './campaignTimelineEntry';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayIcon from '@mui/icons-material/Today';
import useIsDesktop from '../../../../Hooks/useIsDesktop';

export default function CampaignTimelineCard({
    creator,
    expandedCreators,
    handleExpandClick,
    handleAddCreatorEvent,
    handleDeleteCreatorEvent,
    handleEditCreatorEvent,
    setCreatorTimelines,
    masterTimeline,
    creatorTimelines }) {
    const platPromos = useMemo(() => creator.videos.map(video => ({ ...getPlatformAndPromotion(video.rateKey), video: video })), ['creator']);
    const isDesktop = useIsDesktop();
    return (
        <Card sx={{ marginBottom: 2 }}>
            <Box sx={{ display: 'flex' }}>
                <CardMedia
                    component="img"
                    sx={{ width: { xs: 80, md: 151 }, height: 151, maxHeight: 'none', maxWidth: 'none'}}
                    image={creator.pfphref || '/default_profile.png'}
                    alt={creator.creator}
                />

                <Box sx={{ flex: 1, display: 'flex', alignItems:{xs:'stretch', md:'center'}, marginInline:1, flexDirection: { xs: 'column', md: 'row' } }}>
                    <CardContent sx={{ flex: '1 0 auto', padding: { xs: 0, md: 2 }, height: '100%', display: 'flex', flexDirection: 'column', height: {xs:70,md:151} }}>
                        <Typography component="div" variant="h6">
                            {creator.creator}
                        </Typography>
                        <Box sx={{
                            marginBlockStart: 0,
                            marginBlockEnd: 0,
                            overflowY: 'auto',
                            minHeight: 0,
                            flex: 1
                        }}>
                            {platPromos.map(video => (<Typography
                                variant="subtitle1"
                                color="text.secondary"
                                key={video.video.id}
                            >
                                {video.platform} - {video.promotion}{video.video.crossPostingKeys.size > 0 ? ` + (${video.video.crossPostingKeys.size}) crossposts` : ''}
                            </Typography>))}
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={() => handleExpandClick(creator.creator)}
                            startIcon={<ExpandMoreIcon />}
                        >
                            {expandedCreators[creator.creator] ? 'Hide ' : 'Show '}{isDesktop ? 'Timeline' : <CalendarMonthIcon sx={{marginInlineStart:'0.25em'}}></CalendarMonthIcon>}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => handleAddCreatorEvent(creator.creator)}
                        >
                            {isDesktop ? 'Add Event' : <TodayIcon></TodayIcon>}
                        </Button>
                    </CardActions>

                </Box>
            </Box>
            <Collapse
                in={expandedCreators[creator.creator]}
                timeout="auto"
                unmountOnExit
            >
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                    {(creatorTimelines[creator.creator] || []).map((event, index) => (
                        <CampaignTimelineEntry
                            key={event.id}
                            event={event}
                            onEditField={(field, value) =>
                                handleEditCreatorEvent(creator.creator, index, field, value)
                            }
                            onDelete={() => handleDeleteCreatorEvent(creator.creator, index)}
                        />
                    ))}
                    {(!creatorTimelines[creator.creator] ||
                        creatorTimelines[creator.creator].length === 0) && (
                            <Box textAlign="center" marginTop={2}>
                                <Typography variant="body2" color="textSecondary">
                                    No events. You can add events manually or apply the master
                                    timeline.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setCreatorTimelines((prev) => ({
                                            ...prev,
                                            [creator.creator]: masterTimeline.map((event) => ({
                                                ...event,
                                                id: Date.now() + Math.random(),
                                            })),
                                        }));
                                    }}
                                    sx={{ marginTop: 1 }}
                                >
                                    Apply Master Timeline
                                </Button>
                            </Box>
                        )}
                </CardContent>
            </Collapse>
        </Card>
    )
}
