import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Backdrop,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';

import {
  Star as StarIcon,
  EmojiEvents as EmojiEventsIcon,
  MilitaryTech as MilitaryTechIcon
} from '@mui/icons-material';

import { useQuery, useMutation } from 'react-query';
import client from '../../../../API';  // Adjust path as needed

// 1. Create a list of icon options you want to present in the dropdown.
const iconOptions = [
  { label: 'Star', value: 'Star', icon: <StarIcon /> },
  { label: 'Trophy', value: 'EmojiEvents', icon: <EmojiEventsIcon /> },
  { label: 'Medal', value: 'MilitaryTech', icon: <MilitaryTechIcon /> },
];

// 2. Create a map from the stored value to the actual icon component for display in the table.
const iconMap = {
  Star: <StarIcon />,
  EmojiEvents: <EmojiEventsIcon />,
  MilitaryTech: <MilitaryTechIcon />
};

const BlitzBoltDetailsView = () => {
  // ---------------------------------------------------
  // 1. State for creation dialog fields
  // ---------------------------------------------------
  const [openDialog, setOpenDialog] = useState(false);

  // Basic info
  const [newBoltTitle, setNewBoltTitle] = useState('');
  const [newBoltDescription, setNewBoltDescription] = useState('');
  const [newBoltIconName, setNewBoltIconName] = useState('Star');
  const [newBoltPoints, setNewBoltPoints] = useState(0);

  // Instead of a JSON field, let’s store separate numeric states:
  const [partnershipRequirement, setPartnershipRequirement] = useState(0);
  const [campaignRequirement, setCampaignRequirement] = useState(0);
  const [payoutRequirement, setPayoutRequirement] = useState(0);

  // ---------------------------------------------------
  // 2. Query: fetch all BlitzBolts
  // ---------------------------------------------------
  const {
    data: blitzBolts,
    isLoading: isBlitzBoltsLoading,
    refetch: refetchBlitzBolts
  } = useQuery('listBlitzBolts', () => client.blitzbolts.list(), {
    refetchOnWindowFocus: false
  });

  // ---------------------------------------------------
  // 3. Mutation: create a new BlitzBolt
  // ---------------------------------------------------
  const createBlitzBoltMutation = useMutation(
    (input) => client.blitzbolts.create(input),
    {
      onSuccess: () => {
        refetchBlitzBolts();
      },
      onError: (error) => {
        console.error('Error creating BlitzBolt:', error);
        alert('Failed to create BlitzBolt');
      }
    }
  );

  const handleCreateBlitzBolt = () => {
    // Build a criteria object from the numeric fields
    const criteria = {};

    // Only include a criterion if it's > 0 (or if you always want it stored, you can remove this check)
    if (partnershipRequirement > 0) {
      criteria.partnership = Number(partnershipRequirement);
    }
    if (campaignRequirement > 0) {
      criteria.campaign = Number(campaignRequirement);
    }
    if (payoutRequirement > 0) {
      criteria.payout = Number(payoutRequirement);
    }

    const newBlitzBoltData = {
      title: newBoltTitle,
      description: newBoltDescription,
      icon_name: newBoltIconName,
      points: parseInt(newBoltPoints, 10) || 0,
      criteria
    };

    createBlitzBoltMutation.mutate(newBlitzBoltData);
    setOpenDialog(false);

    // Clear form fields
    setNewBoltTitle('');
    setNewBoltDescription('');
    setNewBoltIconName('Star');
    setNewBoltPoints(0);
    setPartnershipRequirement(0);
    setCampaignRequirement(0);
    setPayoutRequirement(0);
  };

  // ---------------------------------------------------
  // 4. Render
  // ---------------------------------------------------
  return (
    <Box sx={{ padding: 2 }}>
      {/* Loading Indicator */}
      <Backdrop
        open={isBlitzBoltsLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Header + "Add BlitzBolt" button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <h2>BlitzBolts</h2>
        <Button variant="contained" onClick={() => setOpenDialog(true)}>
          Add BlitzBolt
        </Button>
      </Box>

      {/* BlitzBolts Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Criteria (JSON)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blitzBolts && blitzBolts.map((bolt) => (
              <TableRow key={bolt.id}>
                <TableCell>{bolt.id}</TableCell>
                <TableCell>{bolt.title}</TableCell>
                <TableCell>{bolt.description}</TableCell>
                <TableCell>
                  {iconMap[bolt.icon_name] || <StarIcon />}
                </TableCell>
                <TableCell>{bolt.points}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(bolt.criteria, null, 2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog: Create New BlitzBolt */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New BlitzBolt</DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Title"
            fullWidth
            variant="outlined"
            margin="normal"
            value={newBoltTitle}
            onChange={(e) => setNewBoltTitle(e.target.value)}
          />
          <TextField
            label="Description"
            fullWidth
            variant="outlined"
            margin="normal"
            value={newBoltDescription}
            onChange={(e) => setNewBoltDescription(e.target.value)}
          />

          {/* Select for the icons */}
          <FormControl margin="normal" fullWidth>
            <InputLabel id="icon-label">Icon</InputLabel>
            <Select
              labelId="icon-label"
              value={newBoltIconName}
              label="Icon"
              onChange={(e) => setNewBoltIconName(e.target.value)}
            >
              {iconOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Box display="flex" alignItems="center">
                    {option.icon}
                    <Box ml={1}>{option.label}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Points"
            fullWidth
            variant="outlined"
            margin="normal"
            type="number"
            value={newBoltPoints}
            onChange={(e) => setNewBoltPoints(e.target.value)}
          />

          {/* 
            Instead of raw JSON, create numeric fields for Partnerships, Campaigns, Payouts
          */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Required Partnerships"
                variant="outlined"
                fullWidth
                type="number"
                value={partnershipRequirement}
                onChange={(e) => setPartnershipRequirement(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Required Campaigns"
                variant="outlined"
                fullWidth
                type="number"
                value={campaignRequirement}
                onChange={(e) => setCampaignRequirement(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Required Payouts"
                variant="outlined"
                fullWidth
                type="number"
                value={payoutRequirement}
                onChange={(e) => setPayoutRequirement(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCreateBlitzBolt}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BlitzBoltDetailsView;
