import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Toolbar,
  useMediaQuery,
  useTheme,
  Fade,
} from "@mui/material";

import frame from "../../Components/globalAssets/frame.png";
import laptopFrame from "../../Components/globalAssets/laptop-02.png";
import videoOnRight from "../../Components/globalAssets/video.mp4";
import videoOnLeft from "../../Components/globalAssets/BlitzMarketplace.mp4";
import PaymentSection from "./homeExtended";
import AIManagerSection from "./aimanager";
import CreatorBanner from "./topcreators";
import ClientsSection from "./clientSection";
import routes from "../../Config/routes";
import { globalStyles } from "../../Utils/Styles";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box
      sx={{
        width: "100vw",
        height: '100vh',
        color: "#000",
        background: "#FAF9F6",
        overflowY: "scroll",
        scrollbarColor: 'rgba(0, 0, 0, 0.38) transparent',
        colorScheme: 'light',
        scrollSnapType: 'y proximity',
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Hero Section */}
      <Fade in={true} timeout={1000}>
        <Box
          component="section"
          sx={{
            ...globalStyles.wholeHeightMinusTopPlusMargin,
            width: "100%",
            paddingInline: 4,
            //background: "radial-gradient(circle at 30% 30%, rgba(123,42,42,0.2), transparent 60%)",
          }}
        >
          <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            maxWidth: '60rem',
            height: '100%',
            marginInline: 'auto'
          }}>

            {/* Left Hero Content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "60rem",
                flex: 1,
                alignItems: { xs: "center", md: "flex-start" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
                The Financial Backbone of the Creator & Gig Economy
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 3 }}>
                Blitz is the new way to pay and get paid instantly, serving as
                the bank between companies, creators, and contractors. We provide
                the financial infrastructure that powers global gig
                work—managing payouts, currencies, and terms so you can focus on
                growth instead of financial complexity.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 3, display: { xs: 'none', md: 'inline' } }}>
                Whether you’re a brand hiring a roster of freelancers or a
                creator seeking fair, fast payments, Blitz ensures a seamless,
                transparent experience. We bridge the financial gap, enabling
                anyone, anywhere, to transact with confidence in the global
                creator economy.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#7b2a2a",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#5a1e1e",
                    transform: "scale(1.05)",
                  },
                  transition: "all 0.3s ease",
                }}
                onClick={handleAccess}
              >
                Request Access
              </Button>
            </Box>

            {/* Right Section with Frame (Desktop Only) */}
            {!isMobile && (
              <Box
                sx={{
                  position: "relative",
                  width: "30%",
                  minWidth: '250px',
                  aspectRatio: '1/2',
                  rotate: "4.96deg",
                  boxShadow: "-6px 6px 12px rgba(0,0,0,0.5)",
                  borderRadius: "16% / 8%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={frame}
                  alt="Frame"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
                <video
                  src={videoOnRight}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "88%",
                    height: "94%",
                    objectFit: "cover",
                    position: "absolute",
                    left: "6%",
                    top: "3%",
                    borderRadius: "4%",
                    zIndex: 0,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Fade>

      {/* Payment Section */}
      <Fade in={true} timeout={1000}>
        <Box
          component="section"
          sx={{
            ...globalStyles.wholeHeightMinusTopPlusMargin,
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingInline: 4,
            backgroundColor: "#ECECEB",
          }}
        >
          <PaymentSection />
        </Box>
      </Fade>

      {/* AI-Enabled Campaign Management Section */}
      <Fade in={true} timeout={1000}>
        <Box
          component="section"
          sx={{
            ...globalStyles.wholeHeightMinusTopPlusMargin,
            width: "100%",
            paddingInline: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              maxWidth: "60rem",
              textAlign: { xs: "center", md: "left" },
              marginInline: "auto",
              marginBlock: 0
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
              AI-Enabled Influencer Campaigns
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              Sourcing the right creators is tough—let AI do the heavy lifting.
              Blitz’s AI-driven campaign tools identify top-fit influencers,
              handle communication, and streamline negotiations. No
              subscriptions or middlemen, just data-driven insights that help
              you craft high-impact campaigns at scale.
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 3, display: { xs: 'none', md: 'inline-block' } }}>
              Transform your marketing strategy with automated matchmaking and
              predictive analytics. Get more value from your influencer
              partnerships and see tangible ROI without spending hours on
              manual research.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#7b2a2a",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#5a1e1e",
                  transform: "scale(1.05)",
                },
                transition: "all 0.3s ease",
              }}
              onClick={handleAccess}
            >
              Explore AI Campaigns
            </Button>
          </Box>
        </Box>
      </Fade>

      {/* Marketplace Section */}
      <Fade in={true} timeout={1000}>
        <Box
          component="section"
          sx={{
            ...globalStyles.wholeHeightMinusTopPlusMargin,
            width: "100%",
            paddingInline: 4,
            backgroundColor: "#ECECEB",
          }}
        >
          <Box sx={{
            maxWidth: '60rem',
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row-reverse" },
            alignItems: "center",
            justifyContent: { xs: 'center', md: 'space-around' },
            gap: { xs: 2, md: 8 },
            marginInline: "auto",
            marginBlock: 0,
            height: '100%'
          }}>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                flex: { xs: 'none', md: 60 },
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
                Access Our Global Creator Marketplace
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 3 }}>
                Instantly tap into a worldwide ecosystem of talent. Blitz
                seamlessly connects creators, brands, and agencies—expanding
                your reach and empowering you to scale without boundaries.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#7b2a2a",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#5a1e1e",
                    transform: "scale(1.05)",
                  },
                  transition: "all 0.3s ease",
                }}
                onClick={handleAccess}
              >
                Request Access
              </Button>
            </Box>

            <Box
              sx={{
                flex: { xs: 'none', md: 40 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: { xs: "90%", md: "100%" },
                  aspectRatio: "16/9",
                  overflow: "visible",
                }}
              >
                <Box sx={{
                  position: "absolute",
                  width: "120%",
                  top: '-10%',
                  left: '-10%',
                  zIndex: 0,
                  height: "120%",
                }}>
                  <Box
                    sx={{
                      position: "absolute",
                      width: "80%",
                      top: 0,
                      left: "10%",
                      zIndex: 0,
                      height: "100%",
                      boxShadow: "0 6px 12px rgba(0,0,0,0.5)",
                      borderRadius: "24px",
                      overflow: "hidden",
                    }}
                  >
                  </Box>
                  <img
                    src={laptopFrame}
                    alt="Laptop Frame"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 2,
                    }}
                  />
                  <video
                    src={videoOnLeft}
                    autoPlay
                    loop
                    muted
                    style={{
                      width: "72%",
                      height: "84%",
                      objectFit: "fill",
                      position: "absolute",
                      left: "14%",
                      top: "5.8%",
                      zIndex: 1,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>

      {/* Creator Banner */}
      <Fade in={true} timeout={1000}>
        <Box component="section" sx={{
          ...globalStyles.wholeHeightMinusTopPlusMargin,
          width: "100%",
          paddingInline: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#7b2a2a'
        }}>
          <CreatorBanner />
        </Box>
      </Fade>

      {/* AI Manager Section */}
      <Fade in={true} timeout={1000}>
        <Box
          component="section"
          sx={{
            ...globalStyles.wholeHeightMinusTopPlusMargin,
            width: "100%",
            paddingInline: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#ECECEB'
          }}
        >
          <AIManagerSection />
        </Box>
      </Fade>

      {/* Clients Section */}
      <Fade in={true} timeout={1000}>
        <Box
          component="section"
          sx={{
            ...globalStyles.wholeHeightMinusTopPlusMargin,
            scrollSnapAlign:'none',
            width: "100%",
            paddingInline: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#7b2a2a'
          }}
        >
          <ClientsSection />
        </Box>
      </Fade>

      {/* Footer */}
      <HomeFooter />
    </Box>
  );
};

export default Home;
