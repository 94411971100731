import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import client from '../../../API';
import { useMutation, useQuery } from 'react-query';
import routes from '../../../Config/routes';
import CampaignDetailsUI from './campaigndetailsUI';
import {
  Button,
  Divider,
  Drawer,
  Box,
  Fab,
  Badge,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { globalStyles } from '../../../Utils/Styles';
import LeftColumnSearch from '../Search/leftColumnSearch';
import { getPlatformAndPromotion, platformToFollowing, platformToLink, sortCreators } from '../../../Utils/constants';
import useIsDesktop from '../../../Hooks/useIsDesktop';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const RightDrawerWidth = '26rem';

const classes = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    overflow: "hidden",
  },
  drawer: {
    width: RightDrawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: RightDrawerWidth,
    maxWidth: '100vw',
    padding: 2,
    marginBottom: 2,
    overflowY: "auto",
    overflowX: "clip",
  },
};

const AddCreators = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [selectedCreatorsData, setSelectedCreatorsData] = useState([]);
  const isLargeScreen = useIsDesktop();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [triedNext, setTriedNext] = useState(false)

  const { data: campaignDetails, error,
  } = useQuery({
    queryKey: ['campaign', campaignId], // Add specific key/params if needed
    queryFn: () => client.campaigns.fetch(campaignId),
    enabled: !!campaignId,
    refetchOnWindowFocus: false,
  });

  const { data: creatorList, isFetching: creatorIsLoading } = useQuery({
    queryKey: ['creators'],
    queryFn: async () => sortCreators(await client.creators.list({ include_reviews: true, include_public: true })),
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const prevCreators = useMemo(() => {
    if (!campaignDetails)
      return (new Set([]));

    return (new Set(
      campaignDetails.creators.map((creator) => creator.id)
    ))
  }, [campaignDetails]);

  useEffect(() => {
    setSelectedItems((prev) => (new Set([...prev, ...prevCreators])));
  }, [prevCreators])

  // Handle errors with useEffect
  useEffect(() => {
    if (!!error) {
      const errorMessage = error.response?.data?.error || error.message;
      alert(`Error fetching campaign: ${errorMessage}`);
    }
  }, [error]);

  const handlePriceChange = (index, newPrice) => {
    setSelectedCreatorsData((prev) => {
      const updated = [...prev];
      updated[index].basePrice = parseFloat(newPrice) || 0;
      updated[index].adjustedPrice = calculateAdjustedPrice(
        updated[index].basePrice,
        updated[index].usageRights
      );
      return updated;
    });
  };

  const getNextId = (videos) => {
    const highestId = videos.reduce((max, video) => Math.max(max, video.id), 0);
    return highestId + 1;
  };

  const handleAddVideo = (index) => {
    setSelectedCreatorsData((prev) => {
      const updated = [...prev];
      const updatedCreator = { ...updated[index] };
      const nextId = getNextId(updatedCreator.videos);

      const updatedVideos = [...updatedCreator.videos, {
        id: nextId,
        rateKey: '',
        crossPostingKeys: new Set([]),
      },];

      updatedCreator.videos = updatedVideos;

      updated[index] = updatedCreator;
      return updated;
    });
  };

  const handleRemoveVideo = (index, videoIndex) => {
    setSelectedCreatorsData((prev) => {
      const updated = [...prev];
      const updatedCreator = { ...updated[index] };

      updatedCreator.videos = [...updatedCreator.videos].filter((video, iteIndex) => iteIndex != videoIndex);

      updated[index] = updatedCreator;
      return updated;
    });
  };

  const calculateBasePrice = (creator, videos = []) => {
    return videos.reduce(
      (accum, video) => accum + (creator[video.rateKey] || 0) + Array.from(video.crossPostingKeys).reduce(
        (accum, crossPost) => accum + ((creator[crossPost] || 0) / 4)
        , 0)
      , 0);
  }

  const calculateAdjustedPrice = (basePrice, usageRights) => {
    let adjustedPrice = basePrice;
    if (usageRights) {
      adjustedPrice *= 1.1;
    }
    return adjustedPrice;
  };

  const handleOptionChange = (index, option, value, videoOption = undefined, videoIndex = undefined) => {
    console.log('Changing', videoOption || option, 'to', value, 'videoIndex', videoIndex);
    setSelectedCreatorsData((prev) => {
      const updated = [...prev];
      const updatedCreator = { ...updated[index] };

      // Handle special logic for specific keys
      switch (option) {
        case 'videos':
          const updatedVideos = [...updatedCreator.videos];
          const updatedVideo = { ...updatedVideos[videoIndex] };

          switch (videoOption) {
            case 'crossPostingKeys':
              updatedVideo[videoOption] = new Set([...value]);
              break;
            case 'rateKey':
              updatedVideo['crossPostingKeys'] = new Set();
            default:
              updatedVideo[videoOption] = value;
              break;
          }

          updatedVideos[videoIndex] = updatedVideo;
          updatedCreator[option] = updatedVideos;
          break
        default:
          updatedCreator[option] = value;
          break;
      }

      // Calculate derived fields after switch logic
      updatedCreator.basePrice = calculateBasePrice(updatedCreator, updatedCreator.videos);
      updatedCreator.adjustedPrice = calculateAdjustedPrice(
        updatedCreator.basePrice,
        updatedCreator.usageRights
      );

      updated[index] = updatedCreator;
      return updated;
    });
  };

  const handleCreatorSelect = (creatorId, creatorData) => {
    if (prevCreators.has(creatorId))
      return;

    console.log('Handling creator: ', creatorData);

    const isSelected = selectedItems.has(creatorId);
    if (isSelected) {
      setSelectedItems((prev) => (prev.delete(creatorId), new Set(prev)));
    } else {
      setSelectedItems((prev) => (prev.add(creatorId), new Set(prev)));
    }
    if (!isSelected) {
      const creatorFullData = creatorList.find((creator) => creator.creator == creatorId);
      setSelectedCreatorsData((prev) => [...prev, {
        ...creatorFullData,
        videos: [
          {
            id: 1,
            rateKey: '',
            crossPostingKeys: new Set([])
          }
        ],
        basePrice: 0,
        usageRights: false,
        adjustedPrice: 0,
      }]);
    } else {
      setSelectedCreatorsData((prev) =>
        prev.filter((item) => item.id !== creatorId)
      );
    }
  };

  const { mutate: updateCreatorList, isLoading: updateLoading } = useMutation(client.campaigns.updateCreatorList, {
    onSuccess: (data) => {
      console.log('Successfully updated campaign creators:', data);

      // Optionally, navigate to another route upon success
      navigate(routes.collaborations);
    },
    onError: (error) => {
      console.error('Error updating campaign creators:', error);
      const errorMessage = error.response?.data?.error || error.message;
      alert(`Error adding creators: ${errorMessage}`);
    },
  });

  const handleConfirmCreatorChanges = async () => {
    const anyBadData = selectedCreatorsData.some((creator) => !creator.basePrice || creator.basePrice == '0' || creator.videos.length == 0 || creator.videos.some((video) => !video.rateKey));
    if (anyBadData) {
      setTriedNext(true);
      return;
    }


    const newCreators = selectedCreatorsData.map((creator) => {
      const price = parseFloat(creator.adjustedPrice);
      const getNextId = (videos) => {
        const highestId = videos.reduce((max, video) => Math.max(max, video.id), 0);
        return highestId + 1;
      };
      const nextId = getNextId(creator.videos);
      let crossPostOffset = 0; // Track the cumulative cross-post offset
      return {
        id: creator.creator,
        name: creator.creator,
        price,
        agencyRate: price * 0.95,
        pfphref: creator.pfphref,
        videos: [
          ...creator.videos.map((video) => {
            const { platform, promotion } = getPlatformAndPromotion(video.rateKey);
            return ({
              id: video.id,
              parentId: null,
              promotionPlatform: platform,
              promotionType: promotion,
              platformLink: creator[platformToLink[platform]],
              following: creator[platformToFollowing[platform]],
            })
          }),
          ...creator.videos.flatMap((video) => {
            const result = [...video.crossPostingKeys].map((crossPost, index) => {
              const { platform, promotion } = getPlatformAndPromotion(crossPost);
              return ({
                id: nextId + crossPostOffset + index,
                parentId: video.id,
                promotionPlatform: platform,
                promotionType: promotion,
                platformLink: creator[platformToLink[platform]],
                following: creator[platformToFollowing[platform]],
              })
            })
            crossPostOffset += video.crossPostingKeys.size;
            return result;
          }
          )
        ]
      };
    });

    const payload = {
      campaignId: parseInt(campaignId),
      newCreators: newCreators,
    };

    console.log(payload);

    updateCreatorList(payload);
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={updateLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        {!isLargeScreen &&
          <Box
            sx={(theme) => ({
              position: 'fixed',
              bottom: theme.spacing(2),
              right: theme.spacing(2),
              zIndex: theme.zIndex.drawer - 1,
            })}>
            <Badge badgeContent={selectedCreatorsData.length} color='primary' overlap='circular'>
              <Fab
                variant="contained"
                size='large'
                color='secondary'
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                sx={{ zIndex: 0 }}
              >
                <ShoppingCartCheckoutIcon></ShoppingCartCheckoutIcon>
              </Fab>
            </Badge>
          </Box>
        }
        <Box
          sx={{
            ...globalStyles.wholeHeightMinusToolbar,
            marginInlineEnd: { xs: 0, md: RightDrawerWidth },
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}>
          <LeftColumnSearch
            onCreatorSelect={handleCreatorSelect}
            data={creatorList}
            loading={creatorIsLoading}
            selectedItems={selectedItems}
          />
        </Box>
        <Drawer
          sx={classes.drawer}
          variant={isLargeScreen ? "persistent" : "temporary"}
          anchor="right"
          open={isLargeScreen || isDrawerOpen}
          PaperProps={{
            sx: classes.drawerPaper,
          }}
        >
          {!isLargeScreen && <IconButton sx={{ marginInlineStart: 'auto', marginBlockEnd: 2 }} onClick={() => setIsDrawerOpen(false)}>
            <ArrowRightIcon></ArrowRightIcon>
          </IconButton>}
          {!isLargeScreen && <Divider sx={{ marginBlockEnd: 2 }}></Divider>}
          <CampaignDetailsUI
            campaignDetails={campaignDetails}
            newCreators={selectedCreatorsData}
            handleAddVideo={handleAddVideo}
            handleOptionChange={handleOptionChange}
            handleRemoveVideo={handleRemoveVideo}
            handlePriceChange={handlePriceChange}
            triedNext={triedNext}

          />
          <Divider sx={{ margin: "20px 0" }} />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={selectedCreatorsData.length < 1}
            onClick={handleConfirmCreatorChanges}
          >
            Confirm Creator Changes
          </Button>
        </Drawer>
      </Box>
    </>
  );
};

export default AddCreators;
