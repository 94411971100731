import React, { useMemo } from 'react'
import { Slide } from '../ReportComponents'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { generateRainbowColors } from '../../../Utils/constants';
import { Box, Typography } from '@mui/material';

const viewsByCreator = [
    { name: 'Pewdiepie', value: 10000 },
    { name: 'MrBeast', value: 20000 },
    { name: 'Markiplier', value: 30000 },
    { name: 'Jacksepticeye', value: 400 },
]

const viewsPerPlatform = [
    { name: 'YouTube', value: 10000 },
    { name: 'Instagram', value: 20000 },
    { name: 'TikTok', value: 30000 },
    { name: 'Podcasts', value: 400 },
]

const platformColors = {
    TikTok: " #222", Instagram: "#833AB4", Youtube: "#EE0022", X: "#00acee", Kick: "#00e701", Podcasts: "#1DB954", Twitch: "#6441a5"
}

export default function ViewsChartSlide({ index, currentSlide, report }) {
    const viewsSliceColors = generateRainbowColors(report?.report.creator_videos.length || 0);

    //Order videos by views
    const creatorVideosByViews = useMemo(() => !!report ? [...report.report.creator_videos].sort(
        (a, b) => b.total_views - a.total_views) : undefined,
    [report])

    return (
        <Slide
            id='slide-influencers'
            index={index}
            currentIndex={currentSlide}
            sx={theme => ({
                backgroundImage: 'linear-gradient(135deg, #1DB954, #071a08)',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: theme.spacing(4),
                paddingBlockEnd: theme.spacing(8),
            })}
        >
            <Typography component={'div'} variant='h5'>Views Highlights</Typography>
            <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
                <Typography component={'div'} variant='h6' color='text.secondary'>Views by Creator</Typography>
                <ResponsiveContainer width="100%" aspect={1.33}>
                    <PieChart>
                        <Pie
                            data={creatorVideosByViews || [0.5, 0.5]}
                            cx="50%"
                            cy="50%"
                            dataKey="total_views"
                            nameKey="creator"
                            outerRadius="70%"
                            label
                        >
                            {creatorVideosByViews?.map((entry, index) => (
                                <Cell key={index} fill={viewsSliceColors[index]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
            <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
                <Typography component={'div'} variant='h6' color='text.secondary'>Views by Platform</Typography>
                <ResponsiveContainer width="100%" aspect={1.33}>
                    <PieChart>
                        <Pie
                            data={report?.report.video_views || [0.5, 0.5]}
                            cx="50%"
                            cy="50%"
                            dataKey="totalViews"
                            nameKey="platform"
                            outerRadius="70%"
                            label
                        >
                            {report?.report.video_views.map((entry) => (
                                <Cell key={index} fill={platformColors[entry.platform]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
            <Typography component={'div'} variant='subtitle2' color='text.secondary' >{report?.name}</Typography>
        </Slide>
    )
}
