import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useAlertDialog from './useAlertDialog';
import { getPlatformAndPromotion, parseLocalFloat, parseLocalInt, platformRatesListFromCreator } from '../Utils/constants';
import API from '../API';
import { useMutation } from 'react-query';
import { Backdrop, Box, Button, CardMedia, Checkbox, CircularProgress, Divider, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import AlertDialog from './AlertDialog';
import { RateField } from './RateField';
import defaultPfp from './globalAssets/ppfLogo.png'
import useAuth from '../Hooks/use-auth';

export default function BookingForm({ creator, onClose }) {
    const navigate = useNavigate();
    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const { getToken } = useAuth();
    const currentToken = getToken();

    //
    // DELIVERABLE (OFFER) FIELDS - shown up front
    //
    const [deliverableData, setDeliverableData] = useState({
        deliverableType: 'offer',
        amount: 50,  // Offer price
        notes: '',
        revision: 0,
        script: false,
    });

    //
    // PARTNERSHIP FIELDS - hidden behind “Advanced”
    //
    const [partnershipData, setPartnershipData] = useState({
        partnershipName: '',   // e.g. “My Awesome Collab”
        details: '',           // e.g. “We want 1 story post”
        selectedPromotion: '',
        promotionType: '',
        promotionPlatform: '',
        email: '',
    });

    // For controlling if advanced partnership fields are visible
    const [showAdvanced, setShowAdvanced] = useState(false);

    // Keep track of a warning if user enters less than 50% of recommended
    const [offerWarning, setOfferWarning] = useState('');

    // Gather the promotions from the creator
    const promotions = useMemo(
        () => platformRatesListFromCreator(creator),
        [creator]
    );

    // 1) On mount, default selected promotion if possible
    useEffect(() => {
        if (promotions.length > 0) {
            const defaultIndex = promotions[1] ? 1 : 0;
            const defaultPromo = promotions[defaultIndex];
            if (!defaultPromo) return;

            const { rateKey } = defaultPromo;
            const promoMeta = getPlatformAndPromotion(rateKey);
            const recommendedPrice = creator[rateKey] || 50;

            setPartnershipData((prev) => ({
                ...prev,
                selectedPromotion: rateKey,
                promotionType: promoMeta.promotion,
                promotionPlatform: promoMeta.platform,
            }));

            setDeliverableData((prev) => ({
                ...prev,
                amount: recommendedPrice.toFixed(2),
            }));
        }
    }, [promotions]);

    // 2) If user changes the promotion (when advanced is open), update recommended price
    const handlePromotionChange = (rateKey) => {
        const meta = getPlatformAndPromotion(rateKey);
        const recommendedPrice = creator[rateKey] || 50;

        setPartnershipData((prev) => ({
            ...prev,
            selectedPromotion: rateKey,
            promotionType: meta.promotion,
            promotionPlatform: meta.platform,
        }));

        setDeliverableData((prev) => ({
            ...prev,
            amount: recommendedPrice.toFixed(2),
        }));
    };

    // 3) Watch deliverableData.amount to warn if user sets < 50% of recommended
    useEffect(() => {
        const recPrice = creator[partnershipData.selectedPromotion];
        if (!recPrice) {
            setOfferWarning('');
            return;
        }
        const userAmount = parseFloat(deliverableData.amount) || 0;
        const half = parseFloat(recPrice) * 0.5;

        if (userAmount < half) {
            setOfferWarning(
                `Warning: This offer is below 50% of the recommended $${recPrice}.`
            );
        } else {
            setOfferWarning('');
        }
    }, [
        deliverableData,
        partnershipData,
    ]);

    // Generic handle changes for deliverable
    const handleDeliverableChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setDeliverableData((prev) => ({ ...prev, [name]: checked }));
        } else {
            setDeliverableData((prev) => ({ ...prev, [name]: value }));
        }
    };

    // Generic handle changes for advanced partnership fields
    const handlePartnershipChange = (e) => {
        const { name, value } = e.target;
        setPartnershipData((prev) => ({ ...prev, [name]: value }));
    };

    // Mutation for creating the partnership + offer
    const { mutate: postPartnership, isLoading: loadingPartnership } = useMutation(
        API.partnerships.book,
        {
            onError: (error) => {
                openDialog(
                    'Error',
                    <>
                        An error occurred while creating your partnership:
                        <br />
                        {error?.response?.data?.error || error.message}
                    </>,
                    closeDialog,
                    closeDialog,
                    'Ok'
                );
            },
            onSuccess: (data) => {
                if (data.partnership?.user_id) {
                    openDialog(
                        'Success',
                        <>Booking created successfully. View it in your Blitz dashboard.</>,
                        () => navigate(`/partnerships/${data.partnership.id}`),
                        () => navigate(`/partnerships/${data.partnership.id}`),
                        'Ok'
                    );
                } else {
                    openDialog(
                        'Success',
                        <>Booking created successfully. Check your email for details.</>,
                        closeDialog,
                        closeDialog,
                        'Ok'
                    );
                }
            },
        }
    );

    // On submit
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate that the user provided a sufficiently detailed brief/notes
        if (!deliverableData.notes || deliverableData.notes.trim().length < 10) {
            openDialog(
                'Validation Error',
                'Please provide a detailed brief/notes for your offer (at least 10 characters).',
                closeDialog,
                closeDialog,
                'Ok'
            );
            return;
        }

        const todayStr = new Date().toISOString().substring(0, 10);

        // If advanced isn't shown, we auto-fill the partnership name/description
        // from deliverable data if they're blank
        let finalName = partnershipData.partnershipName;
        if (!showAdvanced && !finalName) {
            finalName = `Offer for ${creator.creator}`;
        }

        let finalDetails = partnershipData.details;
        if (!showAdvanced && !finalDetails) {
            finalDetails = deliverableData.notes || 'No Additional Info Provided';
        }

        // Build the payload
        const dataToSubmit = {
            creator: creator.creator,
            name: finalName,
            description: finalDetails,
            proposal_date: todayStr,
            finish_date: todayStr,
            total_sum: parseLocalFloat(deliverableData.amount),
            notes: finalDetails,
            promotionType: partnershipData.promotionType,
            promotionPlatform: partnershipData.promotionPlatform,
            selectedPromotion: partnershipData.selectedPromotion,
            ...(!currentToken && { email: deliverableData.email }),

            deliverable: {
                deliverable_type: deliverableData.deliverableType,
                amount: parseLocalFloat(deliverableData.amount),
                notes: deliverableData.notes,
               
                platforms: [partnershipData.promotionPlatform],
            },
        };

        postPartnership(dataToSubmit);
    };

    return (
        <>
            <AlertDialog alertState={dialogState} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingPartnership}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* Top row: user PFP + name on the left, title on the right (optional) */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    mb: 2,
                    alignItems: { xs: 'flex-start', md: 'center' },
                }}
            >
                {/* Profile Image */}
                <Box sx={{ width: { xs: '100%', md: '30%' } }}>
                    <CardMedia
                        component="img"
                        image={creator.pfphref || defaultPfp}
                        alt={creator.creator}
                        sx={{
                            width: '100%',
                            aspectRatio: '1/1',
                            objectFit: 'cover',
                            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                        }}
                    />
                </Box>

                {/* Creator Name & subheading */}
                <Box sx={{ flex: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', textTransform: 'capitalize', mb: 0.5 }}
                    >
                        {creator.creator}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Partner with <b>{creator.creator}</b> today!
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{ mb: 3 }} />

            {/* The main form fields */}
            <Box component="form" onSubmit={handleSubmit}>
                {/* Promotion Selection */}
                {promotions?.length > 0 && (
                    <TextField
                        select
                        label="Collaboration Type"
                        fullWidth
                        name="selectedPromotion"
                        margin="dense"
                        value={partnershipData.selectedPromotion || ''}
                        onChange={(e) => handlePromotionChange(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    >
                        {promotions.map((promo) => (
                            <MenuItem key={promo.rateKey} value={promo.rateKey}>
                                {promo.label}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                {/* Offer Amount */}
                <RateField
                    label="Offer Amount"
                    fullWidth
                    name="amount"
                    type="number"
                    margin="dense"
                    required
                    value={deliverableData.amount}
                    onChange={(value)=>handleDeliverableChange({target:{name:'amount', value}})}
                    sx={{ mb: 2 }}
                    error={!!offerWarning}
                    helperText={offerWarning ? 'This offer is below 50% of the recommended price.' : ''}
                />

                {/* Notes / Brief */}
                <TextField
                    label="Offer Brief / Notes *"
                    fullWidth
                    multiline
                    rows={3}
                    name="notes"
                    value={deliverableData.notes}
                    onChange={handleDeliverableChange}
                    margin="dense"
                    required
                    sx={{ mb: 2 }}
                />
               

                {/* Email */}
                {!currentToken&&<TextField
                    label="Email"
                    fullWidth
                    name="email"
                    type="email"
                    autoComplete='email'
                    margin="dense"
                    value={deliverableData.email}
                    onChange={handleDeliverableChange}
                    sx={{ mb: 2 }}
                />}

                {/* Advanced Fields Toggle */}
                <Button
                    variant="outlined"
                    onClick={() => setShowAdvanced((prev) => !prev)}
                    sx={{ mb: 2 }}
                >
                    {showAdvanced ? 'Hide Advanced Fields' : 'Advanced Collab Info'}
                </Button>

                {/* Advanced Fields */}
                {showAdvanced && (
                    <>
                        <Divider sx={{ my: 2 }} />

                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Advanced Partnership Info
                        </Typography>

                        <TextField
                            label="Partnership Name"
                            fullWidth
                            name="partnershipName"
                            margin="dense"
                            value={partnershipData.partnershipName}
                            onChange={handlePartnershipChange}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            label="Partnership Details"
                            fullWidth
                            multiline
                            rows={3}
                            name="details"
                            margin="dense"
                            value={partnershipData.details}
                            onChange={handlePartnershipChange}
                            sx={{ mb: 2 }}
                        />

                    </>
                )}

                {/* Submit / Cancel Buttons */}
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={loadingPartnership}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </>
    );
};
