// MasterTimelinePopup.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Paper,
  Typography,
  DialogTitle
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, Add as AddIcon } from '@mui/icons-material';
import CampaignTimelineEntry from './campaignTimelineEntry';
import useIsDesktop from '../../../../Hooks/useIsDesktop';

const baseDate = new Date();
const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const DEFAULT_EVENTS = [
  { id: 'script-due', objective: 'Script Due', projected_date: addDays(baseDate, 2) },
  { id: 'draft-due', objective: 'Draft Due', projected_date: addDays(baseDate, 4) },
  { id: 'posting-date', objective: 'Posting Date', projected_date: addDays(baseDate, 6) },
];

export default function MasterTimelinePopup({
  open,
  onClose,
  masterTimeline,
  setMasterTimeline,
  onApplyToAllCreators,
}) {
  const [localTimeline, setLocalTimeline] = useState([]);

  useEffect(() => {
    if (open) {
      setLocalTimeline(masterTimeline.length > 0 ? masterTimeline : DEFAULT_EVENTS);
    }
  }, [open, masterTimeline]);

  const handleEditLocalEvent = (index, field, value) => {
    setLocalTimeline(prev => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated.sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date));
    });
  };

  const handleDeleteLocalEvent = (index) => {
    setLocalTimeline(prev => prev.filter((_, i) => i !== index));
  };

  const handleAddLocalEvent = () => {
    setLocalTimeline(prev => [
      ...prev,
      { id: Date.now(), objective: '', projected_date: new Date() }
    ].sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date)));
  };

  const handleSave = () => {
    setMasterTimeline(localTimeline);
    onClose();
  };

  const isDesktop = useIsDesktop();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" fullScreen={!isDesktop}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        Timeline Management
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {!isDesktop && <>
          {localTimeline.map((event, index) => (
            <CampaignTimelineEntry
              key={event.id}
              event={event}
              onEditField={(field, value) => handleEditLocalEvent(index, field, value)}
              onDelete={() => handleDeleteLocalEvent(index)}
            />
          ))}

          <Button
            fullWidth
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddLocalEvent}
            sx={{ mt: 1 }}
          >
            Add Milestone
          </Button>
        </>}
        {isDesktop && <Paper variant="outlined" sx={{ p: 2 }}>
          {localTimeline.map((event, index) => (
            <CampaignTimelineEntry
              key={event.id}
              event={event}
              onEditField={(field, value) => handleEditLocalEvent(index, field, value)}
              onDelete={() => handleDeleteLocalEvent(index)}
            />
          ))}

          <Button
            fullWidth
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddLocalEvent}
            sx={{ mt: 1 }}
          >
            Add Milestone
          </Button>
        </Paper>}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant='text'>Cancel</Button>
        {onApplyToAllCreators && (
          <Button onClick={() => onApplyToAllCreators(localTimeline)} color="secondary" variant='text'>
            Apply to All
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}