import { IconButton, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { forwardRef } from 'react';

const BaseBubble = forwardRef(function BaseBubble(
    { children, isUserMessage, isTcc, deleteMessageLoading, handleDeleteMessage, customColors = false, padding=2, ...props },
    ref
) {
    return (
        <Paper
            ref={ref} // Pass the ref to the Paper component
            {...props}
            variant='outlined'
            sx={{
                maxWidth: "70%",
                p: padding,
                position: "relative",
                borderRadius: 2,
                ...(!customColors && {
                    backgroundColor: isUserMessage ? "#007bff" : "#e4e6eb",
                    borderColor: isUserMessage ? "#0044ff" : "#a4a6ab",
                    color: isUserMessage ? "white" : "black"
                }),
                textAlign: isUserMessage ? "right" : "left",
                zIndex: 0,
            }}
        >
            {children}
            {
                isTcc && (
                    <IconButton
                        disabled={deleteMessageLoading}
                        onClick={() => handleDeleteMessage()}
                        sx={(theme) => ({
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: 2,
                            position: "absolute",
                            right: !isUserMessage ? "calc(0% - 30px)" : "auto",
                            left: isUserMessage ? "calc(0% - 30px)" : "auto",
                            top: "calc(50% - 20px)",
                            zIndex: 2,
                        })}
                    >
                        <DeleteIcon />
                    </IconButton>
                )
            }
        </Paper >
    );
});

export default BaseBubble;
