// ./sections/creatorinvoicedetailsview.js

import React, { useState, useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Pagination,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { useQuery } from "react-query";

import client from "../../../../API"; 
import { StyledTableRow, StyledTableCell } from "../../../../Utils/styledcell";
import { StyleContentWithNavBar, StyleButtonContainter, StyleButton } from "../../../../Utils/ThemedStyles";

const CreatorInvoiceDetailsView = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  // Use a distinct query key for creator invoices
  const { data, error, isFetching, refetch } = useQuery({
    queryKey: ["creator_invoices"],
    queryFn: () => client.invoices.listCreatorAll(),
    refetchOnWindowFocus: false,
  });

  // Since listCreatorAll returns an array directly, use data as invoices
  const invoices = data || [];

  if (error) {
    console.error("Error fetching creator invoices:", error);
  }

  // Pagination logic
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;
  const pagedInvoices = useMemo(() => invoices.slice(startIndex, endIndex), [invoices, startIndex, endIndex]);

  // Handle row selection
  const handleSelectInvoice = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
    );
  };

  // Delete selected invoices (if applicable)
  const onDeleteSelected = async () => {
    if (!selectedIds.length) return;
    try {
      await client.creatorinvoices.delete({ ids: selectedIds });
      await refetch();
      setSelectedIds([]);
    } catch (err) {
      console.error("Error deleting creator invoices:", err);
    }
  };

  return (
    <Box sx={StyleContentWithNavBar}>
      {/* Loading indicator */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Action buttons */}
      <Box sx={StyleButtonContainter}>
        <Button
          variant="contained"
          onClick={onDeleteSelected}
          sx={StyleButton}
          disabled={!selectedIds.length}
        >
          Delete Selected
        </Button>
      </Box>

      {/* Table of Creator Invoices */}
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedIds.length > 0 && selectedIds.length < invoices.length
                  }
                  checked={
                    invoices.length > 0 && selectedIds.length === invoices.length
                  }
                  onChange={(e) =>
                    setSelectedIds(
                      e.target.checked ? invoices.map((inv) => inv.id) : []
                    )
                  }
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Creator ID</TableCell>
              <TableCell>PO Number</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Payout Date</TableCell>
              <TableCell>BlitzPay</TableCell>
              <TableCell>Interest Rate</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Client Email</TableCell>
              <TableCell>Created At</TableCell>
              {/* Add more columns as needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedInvoices.map((inv) => (
              <StyledTableRow key={inv.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.includes(inv.id)}
                    onChange={() => handleSelectInvoice(inv.id)}
                  />
                </TableCell>
                <TableCell>{inv.id}</TableCell>
                <TableCell>{inv.creator_id}</TableCell>
                <TableCell>{inv.po_number}</TableCell>
                <TableCell>{inv.amount}</TableCell>
                <TableCell>{inv.status}</TableCell>
                <TableCell>
                  {inv.payout_date
                    ? new Date(inv.payout_date).toLocaleDateString()
                    : ""}
                </TableCell>
                <TableCell>{String(inv.blitzpay)}</TableCell>
                <TableCell>{inv.interest_rate}</TableCell>
                <TableCell>{inv.client_name}</TableCell>
                <TableCell>{inv.client_email}</TableCell>
                <TableCell>
                  {inv.invoice_created_at
                    ? new Date(inv.invoice_created_at).toLocaleString()
                    : ""}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(invoices.length / itemsPerPage)}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </Box>
  );
};

export default CreatorInvoiceDetailsView;
