import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";
import { globalStyles } from "../../Utils/Styles";
import { StyledDivider as Divider } from "../../Components/LightDivider";
import API from "../../API";
import ReactSignatureCanvas from "react-signature-canvas";
import { PDFDocument } from "pdf-lib";

// Use the auth hook (ensure the path is correct)
import useAuth from "../../Hooks/use-auth";

const FileViewEdit = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  // Use the correct function name as in CampaignDetailsPage
  const { getCurrrentUser } = useAuth();
  const user = getCurrrentUser();

  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const sigPad = useRef(null);

  // On mount, if user not logged in, prompt for login/register
  useEffect(() => {
    if (!user) {
      setLoginDialogOpen(true);
    }
  }, [user]);

  // Fetch file details on mount
  useEffect(() => {
    API.files.getFile(fileId)
      .then((res) => {
        setFileData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching file:", err);
        setLoading(false);
      });
  }, [fileId]);

  // Helper to check if file is a PDF or a contract
  const isPdf = () => {
    const fileType = fileData?.file_type ? fileData.file_type.toLowerCase() : "";
    const filename = fileData?.filename ? fileData.filename.toLowerCase() : "";
    const pdfByType = fileType === "pdf" || fileType === "contract";
    const pdfByFilename = filename.endsWith(".pdf");
    return pdfByType || pdfByFilename;
  };

  // Helper to check if file is an image
  const isImage = () => {
    const fileType = fileData?.file_type ? fileData.file_type.toLowerCase() : "";
    const filename = fileData?.filename ? fileData.filename.toLowerCase() : "";
    const imageByType = fileType === "image";
    const imageByFilename = /\.(jpg|jpeg|png|gif)$/.test(filename);
    return imageByType || imageByFilename;
  };

  const handleDownload = () => {
    // If not logged in, show login dialog instead of downloading
    if (!user) {
      setLoginDialogOpen(true);
      return;
    }
    // Initiate file download
    const link = document.createElement("a");
    link.href = fileData.file_url;
    link.download = fileData.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditClick = () => {
    // Require login before editing/signing
    if (!user) {
      setLoginDialogOpen(true);
      return;
    }
    setEditMode(true);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      // Get the signature as a data URL from the signature pad
      const signatureDataUrl = sigPad.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      // Fetch the existing PDF as an ArrayBuffer
      const existingPdfBytes = await fetch(fileData.file_url).then((res) =>
        res.arrayBuffer()
      );

      // Load and modify the PDF with pdf-lib
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      // Embed the signature image into the PDF
      const pngImage = await pdfDoc.embedPng(signatureDataUrl);
      const { width, height } = pngImage.scale(0.5);

      // Draw the signature image on the first page at a fixed position
      firstPage.drawImage(pngImage, {
        x: 50,
        y: 50,
        width,
        height,
      });

      // Save the updated PDF
      const pdfBytes = await pdfDoc.save();
      // Convert the binary data to a base64 string
      const pdfBase64 = btoa(String.fromCharCode(...new Uint8Array(pdfBytes)));

      const payload = {
        file: {
          name: fileData.filename,
          content: pdfBase64,
          type: "application/pdf",
        },
      };

      API.files.updateFile(fileId, payload)
        .then((res) => {
          setFileData(res);
          setEditMode(false);
          setSaving(false);
        })
        .catch((err) => {
          console.error("Error saving file:", err);
          setSaving(false);
        });
    } catch (error) {
      console.error("Error processing signature:", error);
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: "#f5f5f5", width: "100%", color: "#000" }}>
      <HomeHeader />
      <Toolbar />
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          pt: 6,
          pb: 4,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
            {isPdf() ? "Contract" : "File"}: {fileData?.filename || "Untitled"}
          </Typography>
          <Divider />

          {/* Render the file preview/editor based on its type */}
          {isPdf() ? (
            <Box sx={{ mt: 4 }}>
              {editMode ? (
                <>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Sign Contract
                  </Typography>
                  <ReactSignatureCanvas
                    ref={sigPad}
                    canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                  />
                  <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                    <Button
                      variant="outlined"
                      onClick={() => sigPad.current.clear()}
                    >
                      Clear Signature
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSave}
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "Save Signed Contract"}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <iframe
                    title="PDF Viewer"
                    src={fileData.file_url}
                    style={{
                      width: "100%",
                      height: "600px",
                      border: "none",
                    }}
                  />
                  <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                    <Button variant="contained" onClick={handleDownload}>
                      Download File
                    </Button>
                    <Button variant="contained" onClick={handleEditClick}>
                      Sign Contract
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          ) : isImage() ? (
            <Box sx={{ mt: 4 }}>
              <img
                src={fileData.file_url}
                alt={fileData.filename}
                style={{ maxWidth: "100%", maxHeight: "600px" }}
              />
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" onClick={handleDownload}>
                  Download File
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ mt: 4 }}>
              <Typography variant="body1">
                Unsupported file type for in-house editing.
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" onClick={handleDownload}>
                  Download File
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
      <HomeFooter />

      {/* Not Logged-In Dialog */}
      <Dialog
        open={loginDialogOpen}
        onClose={() => setLoginDialogOpen(false)}
      >
        <DialogTitle>Please Login or Create an Account</DialogTitle>
        <DialogContent>
          <Typography>
            You must be logged in to perform this action.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/login")} color="primary">
            Login
          </Button>
          <Button onClick={() => navigate("/onboard")} color="primary">
            Create an Account
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FileViewEdit;
