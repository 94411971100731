import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  Box,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import useIsDesktop from "../../../Hooks/useIsDesktop";
import API from "../../../API";
import PageTitle from "../../../Components/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useCreator } from "../../../Hooks/agencySelectedCreatorContext";
import ChatScreen from "../../../Components/ChatScreen";
import CreatorViewOfferDialog from "../../../Components/ChatComponents/CreatorViewOfferDialog";

const AgencyPartnershipDialog = () => {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { partnershipId } = useParams();
  const { creatorData } = useCreator();
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const { data: partnershipData, isLoading, error, refetch } = useQuery({
    queryKey: ["partnerships", partnershipId],
    queryFn: () => API.partnerships.fetchPartnership(partnershipId),
    enabled: !!partnershipId && !!creatorData,
    refetchOnWindowFocus: false,
  });

  const partnership = partnershipData?.partnership;
  const conversationId = partnership?.conversation_id || null;
  const creatorUsername = creatorData?.creator.creator; // Get the creator's username

  // Create a conversation if one doesn't exist
  useEffect(() => {
    if (partnership && !partnership.conversation_id) {
      API.agency.conversations
        .createPartnership({ partnership_id: partnershipId })
        .then(() => refetch())
        .catch((error) => {
          console.error("Error creating conversation:", error);
          alert(
            "Error creating conversation: " +
            (error.response?.data?.error || error.message)
          );
        });
    }
  }, [partnership, partnershipId, refetch]);

  // Error handling
  useEffect(() => {
    if (error) {
      openDialog(
        "Error",
        <>
          Error fetching partnership: <br />
          {error?.response?.data?.error || error.message}
        </>,
        handleCloseDialog,
        handleCloseDialog,
        "Ok",
        null
      );
      console.error("Network error:", error);
    }
  }, [error]);

  const handleCloseDialog = () => {
    navigate(".."); // Navigate back to the collabs page
  };

  // Fetch messages
  const { refetch: refetchMessages, } = useQuery(
    ["messages", conversationId, creatorUsername],
    () => API.conversations.getMessages(conversationId, { reader_id: creatorUsername }),
    {
      enabled: !!conversationId,
      refetchOnWindowFocus: false
    }
  );

  // Mutation to send message
  const sendMessageMutation = useMutation(
    ({ id, input }) => API.conversations.sendMessage(id, input),
    {
      onSuccess: () => {
        refetchMessages();
      },
      onError: (error) => {
        console.error("Error sending message:", error);
        if (error?.response?.data?.error === "forbidden") {
          alert(
            "Your message was flagged for containing prohibited content."
          );
          return;
        }
        alert(error?.response?.data?.error || error?.message);
      },
    }
  );

  // Mutation to update deliverable
  const updateDeliverableMutation = useMutation(
    ({ id, update }) => API.deliverables.update(id, update),
    {
      onSuccess: () => {
        setSelectedDeliverable(null);
        refetchMessages();
      },
      onError: (error) => {
        console.error("Error updating deliverable:", error);
        alert(error?.response?.data?.error || error?.message);
      },
    }
  );

  // Deliverable & Offer
  const handleViewOffer = (deliverable) => setSelectedDeliverable(deliverable);
  const handleUpdateDeliverable = async (deliverableId, update) => {
    return updateDeliverableMutation.mutateAsync({ id: deliverableId, update, creator_id: creatorUsername });
  };
  const handleSendStatusMessage = (statusMessage) => {
    if (!conversationId) return;
    sendMessageMutation.mutate({
      id: conversationId,
      input: { message: statusMessage, reader_id: creatorUsername },
    });
  };

  return (
    <>
      <PageTitle
        title={`Partnership: ${partnership?.name || "..."} - Agency - Blitz`}
      />
      <AlertDialog alertState={dialogState} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={!!partnership}
        onClose={handleCloseDialog}
        maxWidth="xl"
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
        PaperProps={{
          elevation: 1,
          sx: { boxShadow: 24, minWidth: "80vw", minHeight: "80vh" },
        }}
      >
        {partnership && (
          <>
            <Paper square elevation={2}>
              <DialogTitle>Partnership Info & Conversation</DialogTitle>
            </Paper>
            <DialogContent dividers>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {/* Partnership Details */}
                <Box>
                  <Typography variant="h6">
                    Partnership: {partnership.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    Ideal due date:{" "}
                    {partnership.finish_date
                      ? new Date(partnership.finish_date).toDateString()
                      : "N/A"}
                  </Typography>
                  <Typography variant="subtitle1">
                    Offer:{" "}
                    {typeof partnership.total_sum === "number"
                      ? partnership.total_sum.toFixed(2)
                      : "N/A"}
                  </Typography>
                  <Typography variant="subtitle1">
                    Brief:{" "}
                    <Typography variant="body2" component="span">
                      {partnership.description}
                    </Typography>
                  </Typography>
                </Box>
                {/* Chat Screen */}
                <Paper elevation={2} sx={{paddingInline:2}}>
                  {partnership.conversation_id ? (
                    <ChatScreen
                      conversationId={partnership.conversation_id}
                      onDeliverableClick={handleViewOffer}
                      sx={{ minHeight: "400px", maxHeight: "60vh" }}
                      specificReaderId={creatorUsername} // Pass the creator's username as the reader, matching AgencyCampaignDialog
                      margin={0}
                      showWarning={false}
                    />
                  ) : (
                    <Typography variant="body1">
                      No conversation available.
                    </Typography>
                  )}
                  {selectedDeliverable && (
                    <CreatorViewOfferDialog
                      open={!!selectedDeliverable}
                      onClose={() => setSelectedDeliverable(null)}
                      deliverable={selectedDeliverable}
                      onUpdateDeliverable={handleUpdateDeliverable}
                      onSendMessage={handleSendStatusMessage}
                    />
                  )}
                </Paper>
              </Box>
            </DialogContent>
          </>
        )}
        <Paper elevation={2} square>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseDialog}>
              Close
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

export default AgencyPartnershipDialog;