import React from "react";
import { useQuery } from "react-query";
import client from "../../../../API";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import ChatScreen from "../../../../Components/ChatScreen";
import { Alert } from "@mui/material";

const CreatorCampaignConversation = ({ campaignId }) => {
  const { creatorToken } = useCreatorAuth();

  const { data: checkResults, isLoading: isChecking, error } = useQuery({
    queryKey: ['messagesId', campaignId],
    queryFn: () => client.conversations.checkCampaign({ campaign_id: campaignId, creator_id: creatorToken.creator_user.username }),
    refetchOnWindowFocus:false,
  });

  const convId = checkResults?.exists ? checkResults.conversation_id : null;

  return (
    <>
    {!!error && <Alert severity="error">{error?.response?.data?.error || error.message}</Alert>}
    <ChatScreen conversationId={convId} sx={{flex:1, minHeight:'0px'}} showWarning={false} />
    </>
  );
};

export default CreatorCampaignConversation;
