import { Box, styled, Typography } from '@mui/material';
import React from 'react'
import { Slide } from '../ReportComponents';
import blitzLogo from '../../../Components/globalAssets/platty.png';

const SparkleOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `
        radial-gradient(rgb(123, 42, 42) 10%, transparent 11%),
        radial-gradient(rgb(123, 42, 42) 10%, transparent 11%)
    `,
    backgroundSize: '30px 30px',
    backgroundPosition: '0 0, 15px 15px',
    pointerEvents: 'none',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    paddingBlockEnd: theme.spacing(8),
}));

const Logo = styled('img')(({theme})=>({
    borderRadius: `${theme.shape.borderRadius}px`,
    width: '12.5vh',
    height: '12.5vh',
    objectFit: 'contain',
}));

export default function OpeningSlide({ index, currentSlide, report }) {
    return (
        <Slide
            id='slide-intro'
            index={index}
            currentIndex={currentSlide}
            sx={{
                background: 'linear-gradient(180deg, rgb(123, 42, 42), #191414), rgba(0,0,0,0.5)'
            }}>
            <SparkleOverlay>
                <Box id='logos-container'
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative',
                        zIndex: 10,
                    }}
                >
                    <Logo src={blitzLogo} alt='Logo' />
                    <Typography component={'p'} variant='h1' color='white' fontSize='2.5rem' fontWeight={700} textAlign='center'> X </Typography>
                    <Logo src={report?.pfp} alt='Logo' />
                </Box>
                <Typography component={'div'} variant='h3' >Your Campaign Recap</Typography>
                <Typography component={'div'} variant='h4' color='text.secondary' >{report?.name}</Typography>
                <Typography component={'div'} variant='subtitle2' color='text.secondary' >Results Report</Typography>
            </SparkleOverlay>
        </Slide>
    )
}
