import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Toolbar,
  Grid,
  Stack,
  Avatar,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import HomeHeader from '../../../../Components/HomeHeader';
import HomeFooter from '../../../../Components/HomeFooter';
import { StyledDivider as Divider } from '../../../../Components/LightDivider';
import API from '../../../../API';
import { Download, Payment, CheckCircle } from '@mui/icons-material';

const CreatorInvoicePage = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const data = await API.creatorConnect.getInvoice(id);
        setInvoice(data);
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const handleDownloadPDF = () => {
    const input = document.getElementById('invoice-content');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice_${id}.pdf`);
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!invoice) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Invoice not found.</Typography>
      </Box>
    );
  }

  const formattedDate = (date) => new Date(date).toLocaleDateString();
  const getAmountDue = (amount) => amount || 0;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HomeHeader />
      <Toolbar />
      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 6, flex: 1 }}>
        <Box id="invoice-content" sx={{ mb: 4 }}>
          <Paper
            sx={{
              p: 6,
              borderRadius: 4,
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              background: 'white',
            }}
          >
            {/* Header Section */}
            <Grid container spacing={4} sx={{ mb: 6 }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  {invoice.creator_info?.pfphref && (
                    <Avatar
                      src={invoice.creator_info.pfphref}
                      sx={{
                        width: 80,
                        height: 80,
                        boxShadow: 3,
                        border: '2px solid white',
                      }}
                    />
                  )}
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 800,
                        background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        mb: 1,
                      }}
                    >
                      CREATOR INVOICE
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'black' }}>
                      Issued by: {invoice.creator_info?.creator_id}
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'black', mb: 2 }}>
                      PANGEA OMNINATIONAL CORPORATION
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'black' }}>
                      8724 Cavell Lane, Houston, TX 77055
                    </Typography>

                    <Stack spacing={1} sx={{ mt: 3 }}>
                      <Stack direction="row" spacing={2}>
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          Date:
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          {formattedDate(invoice.invoice_created_at)}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          Invoice #:
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          {invoice.id}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{ textAlign: { xs: 'left', md: 'right' } }}
              >
                <Button
                  variant="contained"
                  startIcon={<Download />}
                  onClick={handleDownloadPDF}
                  sx={{
                    mb: 4,
                    background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                    '&:hover': { opacity: 0.9 },
                  }}
                >
                  Download PDF
                </Button>
                <Box
                  sx={{
                    p: 3,
                    background: '#f8f9fa',
                    borderRadius: 2,
                    display: 'inline-block',
                    textAlign: 'left',
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'black' }}>
                    Amount Due
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: 'black' }}>
                    $
                    {(
                      getAmountDue(invoice.amount) -
                      getAmountDue(invoice.amount_paid)
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Billed To Section */}
            <Grid container spacing={4} sx={{ mb: 6 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, color: 'black' }}>
                  Billed To
                </Typography>
                <Box
                  sx={{
                    p: 3,
                    background: '#f8f9fa',
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 500, color: 'black' }}>
                    {invoice.client_name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'black' }}>
                    {invoice.client_email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, color: 'black' }}>
                  Due Date
                </Typography>
                <Box
                  sx={{
                    p: 3,
                    background: '#f8f9fa',
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 500, color: 'black' }}>
                    {formattedDate(invoice.payout_date)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Items Table */}
            <TableContainer
              sx={{
                border: '1px solid #e9ecef',
                borderRadius: 2,
                overflow: 'hidden',
                mb: 6,
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ color: '#fff', fontWeight: 600 }}>
                      Description
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 600 }}>
                      Rate
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 600 }}>
                      Qty
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 600 }}>
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                    <TableCell sx={{ color: 'black' }}>
                      {invoice.po_number}
                    </TableCell>
                    <TableCell sx={{ color: 'black' }}>
                      ${getAmountDue(invoice.amount).toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ color: 'black' }}>1</TableCell>
                    <TableCell sx={{ fontWeight: 500, color: 'black' }}>
                      ${getAmountDue(invoice.amount).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* Summary Section */}
            <Grid container justifyContent="flex-end" sx={{ mb: 6 }}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    background: '#f8f9fa',
                    borderRadius: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body2" sx={{ color: 'black' }}>
                        Subtotal:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'black' }}>
                        ${getAmountDue(invoice.amount).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" sx={{ fontWeight: 600, color: 'black' }}>
                        Total Due:
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 600, color: 'black' }}>
                      ${getAmountDue(invoice.amount).toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>

            {/* Terms Section */}
            <Box sx={{ mb: 6 }}>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, color: 'black' }}>
                Payment Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ color: 'black' }}>
                    Account Holder: Houston Alexander Roa
                    <br />
                    Account Name: Pangea
                    <br />
                    Bank: Prosperity Bank
                    <br />
                    SWIFT: PROYUS44
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ color: 'black' }}>
                    Address: 8724 Cavell Lane, Houston, TX 77055
                    <br />
                    Account #: 217841714
                    <br />
                    Routing #: 113122655
                    <br />
                    Bank Address: 2117 N. Fry Road, Katy, TX 77449
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Payment CTA */}
            <Box
              sx={{
                p: 4,
                background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                borderRadius: 2,
                textAlign: 'center',
                color: 'white', // Keep text white in the dark/gradient section
              }}
            >
              <CheckCircle sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                Secure Payment Portal
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Pay with Credit Card, PayPal, or Bank Transfer
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                href={invoice.invoice_link}
                startIcon={<Payment />}
                sx={{
                  px: 6,
                  fontWeight: 600,
                  background: 'white',
                  color: '#2b2d42',
                  '&:hover': { background: '#f8f9fa' },
                }}
              >
                Pay Invoice Now
              </Button>
            </Box>
          </Paper>
        </Box>
      </Container>
      <HomeFooter />
    </Box>
  );
};

export default CreatorInvoicePage;
