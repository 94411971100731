import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Avatar,
    List,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Paper,
    ListItem,
    Box,
    styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const DialogFooter = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1.5),
    borderTop: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary
}));

// Main component
export default function ReadReceipt({ open, handleClose, message, participants, currentUsername }) {
    const readList = (message && participants && currentUsername) ? participants.filter(member => member.id != message.sender_id && member.id != message.creator_id).map(member => ({
        ...member,
        readDate: member.id in message.read_receipts ? new Date(message.read_receipts[member.id]) : null
    })) : null

    const readCount = readList?.filter(member => !!member.readDate).length;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 2,
                borderBottom: '1px solid',
                borderColor: 'divider'
            }}>
                <Typography variant="subtitle1" fontWeight={600}>
                    Read by {readCount} of {readList?.length} members
                </Typography>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ padding: 0 }}>
                <Paper variant='outlined' square sx={{ padding: 2, marginBlockEnd: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        {message?.message}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                        Sent: {new Date(message?.created_at).toLocaleString(undefined, {
                            dateStyle: 'medium',
                            timeStyle: 'short',
                            hour12: true
                        })}
                    </Typography>
                </Paper>

                <List sx={{ maxHeight: 200, overflow: 'auto', marginBlockEnd:2 }}>
                    {readList && readList.map((member, index) => (
                        <React.Fragment key={member.id}>
                            <ListItem dense>
                                <ListItemAvatar>
                                    <Avatar
                                        src={member.pfp}
                                        alt={member.id}
                                    >
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="body2" fontWeight={500}>
                                            {member.username}
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {!!member.readDate ? (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5
                                        }}>
                                            <CheckCircleIcon
                                                fontSize="small"
                                                color="success"
                                                sx={{ width: 18, height: 18 }}
                                            />
                                            <Typography color='text.secondary'>{new Date(message.created_at).toLocaleString(undefined, {
                                                dateStyle: 'medium',
                                                timeStyle: 'short',
                                                hour12: true
                                            })}</Typography>
                                        </Box>
                                    ) : (
                                        <Typography color='text.secondary' fontStyle='italic'>Not read yet</Typography>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            {index < readList.length - 1 && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};