// src/components/Agency/agencyCreatorTabs/AgencyCreatorPayouts.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../../API';
import { useCreator } from '../../../Hooks/agencySelectedCreatorContext';

export default function AgencyCreatorPayouts({}) {
  const { creatorData } = useCreator();
  const payouts = creatorData?.payouts || [];
  const queryClient = useQueryClient();

  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('NET30'); // Default payment method
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const confirmMutation = useMutation(
    ({ payoutId, method }) => API.agency.approvePayment(payoutId, {paymentMethod: method}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['agency', 'creatorPayouts', creatorData.id]);
        setSnackbarMessage('Payment confirmed successfully.');
        setSnackbarOpen(true);
      },
      onError: (error) => {
        setSnackbarMessage(`Error confirming payment: ${error.message}`);
        setSnackbarOpen(true);
      },
    }
  );
  

  const handleOpenActionDialog = (payout) => {
    setSelectedPayout(payout);
    setPaymentMethod('NET30'); // Reset to default when opening
    setActionDialogOpen(true);
  };

  const handleCloseActionDialog = () => {
    setSelectedPayout(null);
    setPaymentMethod('NET30');
    setActionDialogOpen(false);
  };

  const handleConfirmAction = () => {
    if (!selectedPayout) return;

    confirmMutation.mutate({
      payoutId: selectedPayout.id,
      method: paymentMethod,
    });

    handleCloseActionDialog();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (!payouts || payouts.length === 0) {
    return <Typography variant="body1">No payouts found for this creator.</Typography>;
  }

  return (
    <Box sx={{ p: 2 }}>
      {/* Payouts Table */}
      <TableContainer component={Paper} elevation={2} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>PO #</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Payout Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Blitzpay</TableCell>
              <TableCell>Interest Rate</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Campaign Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts.map((payout) => (
              <TableRow key={payout.id}>
                <TableCell>{payout.po_number}</TableCell>
                <TableCell>${payout.amount.toFixed(2)}</TableCell>
                <TableCell>
                  {payout.payout_date
                    ? new Date(payout.payout_date).toLocaleDateString()
                    : 'N/A'}
                </TableCell>
                <TableCell>{payout.status}</TableCell>
                <TableCell>{payout.blitzpay ? 'Yes' : 'No'}</TableCell>
                <TableCell>{payout.interest_rate}%</TableCell>
                <TableCell>{payout.notes || 'N/A'}</TableCell>
                <TableCell>{payout.role || 'N/A'}</TableCell>
                <TableCell>{payout.campaign_name || 'N/A'}</TableCell>
                <TableCell>
                  {payout.status.toLowerCase() === 'pending' && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleOpenActionDialog(payout)}
                    >
                      Confirm
                    </Button>
                  )}
                  {(payout.status.toLowerCase() === 'approved' ||
                    payout.status.toLowerCase() === 'declined') && (
                    <Typography variant="body2" color="textSecondary">
                      {payout.status}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Action Confirmation Dialog */}
      <Dialog
        open={actionDialogOpen}
        onClose={handleCloseActionDialog}
        aria-labelledby="action-dialog-title"
        aria-describedby="action-dialog-description"
      >
        <DialogTitle id="action-dialog-title">Confirm Payment</DialogTitle>
        <DialogContent>
          <DialogContentText id="action-dialog-description">
            Are you sure you want to confirm this payment (ID: {selectedPayout?.id})?
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="payment-method-label">Payment Method</InputLabel>
              <Select
                labelId="payment-method-label"
                value={paymentMethod}
                label="Payment Method"
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <MenuItem value="NET30">NET30</MenuItem>
                <MenuItem value="BLITZPAY">BLITZPAY</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseActionDialog}>Cancel</Button>
          <Button
            onClick={handleConfirmAction}
            color="primary"
            disabled={confirmMutation.isLoading}
          >
            {confirmMutation.isLoading ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      />
    </Box>
  );
}
