import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Paper,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import CampaignTimelineEntry from './campaignTimelineEntry';
import CampaignTimelineCard from './campaignTimelineCard';
import useAlertDialog from '../../../../Components/useAlertDialog';
import AlertDialog from '../../../../Components/AlertDialog';

// IMPORT the MasterTimelinePopup
import MasterTimelinePopup from './masterpopup';

const Timelines = ({
  campaignDetails,
  selectedCreatorsData,
  creatorTimelines,
  setCreatorTimelines,
  onNext,
  onBack,
}) => {
  const [masterTimeline, setMasterTimeline] = useState([]);
  const [expandedCreators, setExpandedCreators] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  // 1) Add state for showing/hiding the Master Timeline Popup
  //    We start with `true` so it pops up automatically on load.
  const [openMasterPopup, setOpenMasterPopup] = useState(true);

  const { dialogState, openDialog: openAlert, closeDialog: closeAlert } = useAlertDialog();

  // Existing logic for adding inline Master Timeline events
  const handleAddMasterEvent = () => {
    setMasterTimeline((prev) => [
      ...prev,
      {
        id: Date.now(),
        objective: '',
        status: 'incomplete',
        notes: '',
        manager_notes: '',
        manager_completed: false,
        projected_date: new Date(),
        will_delete: false,
      },
    ].sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date)));
  };

  const handleEditMasterEvent = (index, field, value) => {
    setMasterTimeline((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated.sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date));
    });
  };

  const handleDeleteMasterEvent = (index) => {
    setMasterTimeline((prev) => prev.filter((_, i) => i !== index));
  };

  // Apply Master Timeline events to all creators
  const applyMasterTimelineToAll = () => {
    console.log('Applying master timeline');
    const newCreatorTimelines = {};

    selectedCreatorsData.forEach((creator) => {
      // copy each event, ensuring we generate a unique ID
      newCreatorTimelines[creator.creator] = masterTimeline.map((event) => ({
        ...event,
        id: Date.now() + Math.random(),
      }));
    });

    setCreatorTimelines(newCreatorTimelines);
    setOpenDialog(false);
  };

  const handleExpandClick = (creatorId) => {
    setExpandedCreators((prev) => ({
      ...prev,
      [creatorId]: !prev[creatorId],
    }));
  };

  // Add event for a single creator
  const handleAddCreatorEvent = (creatorId) => {
    setCreatorTimelines((prev) => ({
      ...prev,
      [creatorId]: [
        ...(prev[creatorId] || []),
        {
          id: Date.now(),
          objective: '',
          status: 'incomplete',
          notes: '',
          manager_notes: '',
          manager_completed: false,
          projected_date: new Date(),
          will_delete: false,
        },
      ].sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date)),
    }));
  };

  // Edit event for a single creator
  const handleEditCreatorEvent = (creatorId, index, field, value) => {
    setCreatorTimelines((prev) => {
      const updatedEvents = [...(prev[creatorId] || [])];
      updatedEvents[index] = { ...updatedEvents[index], [field]: value };
      return {
        ...prev,
        [creatorId]: updatedEvents.sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date)),
      };
    });
  };

  // Delete event for a single creator
  const handleDeleteCreatorEvent = (creatorId, index) => {
    setCreatorTimelines((prev) => {
      const updatedEvents = [...(prev[creatorId] || [])];
      updatedEvents.splice(index, 1);
      return {
        ...prev,
        [creatorId]: updatedEvents,
      };
    });
  };

  // Validation before going to next step
  const onTryNext = () => {
    const todayDate = new Date();
    // Check if any event is due before today
    const badDates = selectedCreatorsData.some((creator) => {
      const timeline = creatorTimelines[creator.creator];
      return timeline?.some((event) => event.projected_date <= todayDate);
    });

    if (badDates) {
      openAlert(
        'Incorrect Due Dates',
        <>Events in a Timeline can't be due before today.<br />Please adjust your Timelines to continue</>,
        closeAlert,
        closeAlert,
        'Ok',
        null
      );
      return;
    }

    // Check if any timeline is empty
    const anyBadData = selectedCreatorsData.some((creator) => {
      const timeline = creatorTimelines[creator.creator];
      return !timeline || timeline.length === 0;
    });

    if (anyBadData) {
      openAlert(
        'Empty To Do',
        <>Some creators in the campaign don't have any events in their to do items. <br />
           To Do List notify creators of upcoming deadlines and notify you when they are completed. Continue?</>,
        handleNext,
        closeAlert,
        'Continue',
        'Cancel'
      );
      return;
    }

    handleNext();
  };

  const handleNext = () => {
    onNext();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
      <Box sx={{ width: '100%', maxWidth: 800 }}>

        {/* 
          2) The MasterTimelinePopup automatically opens on first load,
             allowing the user to edit the master timeline in a popup.
             They can still close it and edit inline as before.
        */}
        <MasterTimelinePopup
          open={openMasterPopup}
          onClose={() => setOpenMasterPopup(false)}
          masterTimeline={masterTimeline}
          setMasterTimeline={setMasterTimeline}
          // Pass a callback to apply master timeline to all creators
          onApplyToAllCreators={(updatedTimeline) => {
            setMasterTimeline(updatedTimeline);
            const newCreatorTimelines = {};
            selectedCreatorsData.forEach((creator) => {
              newCreatorTimelines[creator.creator] = updatedTimeline.map((event) => ({
                ...event,
                id: Date.now() + Math.random(),
              }));
            });
            setCreatorTimelines(newCreatorTimelines);

            // Optionally close the popup after applying
            setOpenMasterPopup(false);
          }}
        />

        {/* ***** Existing "inline" Master Timeline editing ***** */}
        <Typography variant="h5" gutterBottom>
          Master To Do
        </Typography>
        <Paper sx={{ padding: 2, marginBottom: 2 }}>
          {masterTimeline.map((event, index) => (
            <CampaignTimelineEntry
              key={event.id}
              event={event}
              onEditField={(field, value) => handleEditMasterEvent(index, field, value)}
              onDelete={() => handleDeleteMasterEvent(index)}
            />
          ))}

          {/* Add Master Event and Apply to All button (existing logic) */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 1 }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddMasterEvent}
              sx={{ marginRight: 2 }}
            >
              Add Master Event
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Apply Master To Do to All Creators
            </Button>
          </Box>
        </Paper>

        {/* Dialog for confirming master timeline overwrite (existing) */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="apply-master-timeline-dialog-title"
        >
          <DialogTitle id="apply-master-timeline-dialog-title">
            Apply Master To Do
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will overwrite any existing to do items for all creators. Do you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={applyMasterTimelineToAll} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <AlertDialog alertState={dialogState} />

        {/* ***** Creators Timelines ***** */}
        <Typography variant="h5" gutterBottom>
          Creators To Do Lists
        </Typography>
        {selectedCreatorsData.map((creator) => (
          <CampaignTimelineCard
            key={creator.creator}
            creator={creator}
            expandedCreators={expandedCreators}
            handleExpandClick={handleExpandClick}
            handleAddCreatorEvent={handleAddCreatorEvent}
            handleDeleteCreatorEvent={handleDeleteCreatorEvent}
            handleEditCreatorEvent={handleEditCreatorEvent}
            setCreatorTimelines={setCreatorTimelines}
            creatorTimelines={creatorTimelines}
            masterTimeline={masterTimeline}
          />
        ))}

        {/* Nav Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          <Button variant="contained" onClick={onBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={onTryNext}>
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Timelines;
