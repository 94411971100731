import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ReactSignatureCanvas from "react-signature-canvas";
import { PDFDocument } from "pdf-lib";
import client from "../../API";

/**
 * PdfSigningDialog
 * - Tries to embed signature with pdf-lib
 * - If pdf-lib fails (XFA or parse error), we STILL upload to server so a new file record is created.
 */
const PdfSigningDialog = ({ open, onClose, fileData, onFileSigned }) => {
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const sigPad = useRef(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (fileData?.file_url) {
      setPdfUrl(fileData.file_url);
    }
  }, [fileData]);

  const handleClearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  const handleStartEditing = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    setSaving(true);

    // 1) Make sure user scribbled something
    if (sigPad.current?.isEmpty()) {
      alert("Please draw a signature before saving.");
      setSaving(false);
      return;
    }

    // 2) Get signature image from canvas
    const signatureDataUrl = sigPad.current.getTrimmedCanvas().toDataURL("image/png");

    // 3) Fetch original PDF bytes
    let existingPdfBytes;
    try {
      existingPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
    } catch (fetchErr) {
      console.error("Fetching PDF failed:", fetchErr);
      alert("Could not fetch the original PDF. See console for details.");
      setSaving(false);
      return;
    }

    // 4) Attempt to parse & embed signature with pdf-lib
    let finalPdfBytes; // We'll store the final (signed or not) PDF bytes here.
    const newFilename = `SIGNED - ${fileData.filename || fileData.name || "Document"}`;

    try {
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      const pngImage = await pdfDoc.embedPng(signatureDataUrl);
      const { width, height } = pngImage.scale(0.5);

      firstPage.drawImage(pngImage, {
        x: 50,
        y: 50,
        width,
        height,
      });

      // pdf-lib parse & embed succeeded, so final bytes = signed doc
      finalPdfBytes = await pdfDoc.save();
    } catch (parseErr) {
      // Catch the pdf-lib parse error, but proceed anyway
      console.error("pdf-lib parse error:", parseErr);
      alert("pdf-lib failed to parse/modify the PDF. We'll still create a new file record on the server.");
      // If you STILL want to store the user's signature image, you can do your own fallback logic, 
      // or just pass along the original PDF (existingPdfBytes) unmodified:
      finalPdfBytes = existingPdfBytes;
    }

    // 5) Convert final PDF bytes to base64 for the server
    const pdfBase64 = btoa(
      new Uint8Array(finalPdfBytes).reduce((data, byte) => data + String.fromCharCode(byte), "")
    );

    // 6) POST to server *regardless*
    try {
      const response = await client.files.updateFile(fileData.id, {
        // We'll pass the same shape as your old code,
        // but call the new sign_contract route instead of updateFile
        signed_pdf_base64: pdfBase64,
        new_filename: newFilename,
      });

      const { file_id, file_url } = response;
      const updatedFile = {
        id: file_id,
        filename: newFilename,
        file_url: file_url,
        file_type: "contract",
      };

      // Let parent know we have a newly created "signed" file
      onFileSigned(updatedFile);
    } catch (uploadErr) {
      console.error("Uploading new signed file to server failed:", uploadErr);
      alert(`Error creating new file record: ${uploadErr.message}`);
    }

    setSaving(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Sign the Contract</DialogTitle>
      <DialogContent>
        {/** If you have a "loading" state, show spinner here */}
        {!editMode ? (
          <>
            <Typography variant="body1" gutterBottom>
              Preview:
            </Typography>
            <Box sx={{ mt: 2, overflowY: "auto", maxHeight: "80vh" }}>
              <object
                data={pdfUrl}
                type="application/pdf"
                style={{
                  width: "100%",
                  height: isSmallScreen ? "50vh" : 600,
                  border: "none",
                }}
              >
                <Typography>
                  Your browser can't display PDFs. 
                  <a href={pdfUrl} rel="noreferrer" target="_blank">
                    Download it here
                  </a>.
                </Typography>
              </object>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" onClick={handleStartEditing}>
                Sign It
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              Scribble your name:
            </Typography>
            <Box
              sx={{
                mt: 2,
                position: "relative",
                overflowY: "auto",
                maxHeight: "80vh",
              }}
            >
              <object
                data={pdfUrl}
                type="application/pdf"
                style={{
                  width: "100%",
                  height: isSmallScreen ? "50vh" : 600,
                  border: "none",
                }}
              />
              <ReactSignatureCanvas
                ref={sigPad}
                canvasProps={{
                  style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  },
                  className: "sigCanvas",
                }}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={handleClearSignature}>
                Wipe It
              </Button>
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={saving}>Cancel</Button>
        {editMode && (
          <Button onClick={handleSave} variant="contained" disabled={saving}>
            {saving ? "Saving..." : "Save It"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PdfSigningDialog;
