import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  Link,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Stack,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import client from "../../../API";
import CreatorTimeline from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/creatorTimeline";
import { useMutation, useQuery } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import { formatLinksInMessage, TimelineStatus } from "../../../Utils/constants";
import TabPanel from "../../../Components/TabPanel";
import ChatScreen from "../../../Components/ChatScreen"; // Import ChatScreen


function VideoDetails({ video, onLinkChange, onCrossLinkChange, isUpdatingFields, canEdit }) {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', minWidth: '0px', maxWidth: '100%', marginBlockEnd: 2 }}>
        <Typography variant='subtitle2' color='text.secondary' sx={{ marginInlineEnd: 2 }}>Video #{String(video.id).padStart(3, '0')}</Typography>
        <Box sx={{ flex: 1 }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <TextField
              value={video.liveLink ? video.liveLink : ''}
              onChange={(event) => onLinkChange(video.id, event.target.value, video.boostCode)}
              label="Live Link"
              fullWidth
              variant="outlined"
              size="small"
              disabled={isUpdatingFields || !canEdit}
            >
            </TextField>
            <Chip color='secondary' label={video.promotionPlatform}></Chip>
            <Chip color='primary' label={video.promotionType}></Chip>
          </Stack>
          <TextField
            value={video.boostCode ? video.boostCode : ''}
            onChange={(event) => onLinkChange(video.id, video.liveLink, event.target.value)}
            label="Boost Code"
            fullWidth
            variant="outlined"
            size="small"
            disabled={isUpdatingFields || !canEdit}
            margin='dense'
          ></TextField>
          <Box sx={{ marginInlineStart: '3em' }}>
            {video.crossposts.map(crosspost => (
              <React.Fragment key={`video-${video.id}-crosspost-${crosspost.id}`}>
                <Stack direction='row' spacing={1} alignItems='center' marginBlockStart={1}>
                  <TextField
                    value={crosspost.liveLink ? crosspost.liveLink : ''}
                    onChange={(event) => onCrossLinkChange(video.id, crosspost.id, event.target.value, crosspost.boostCode)}
                    label="Crosspost Live Link"
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={isUpdatingFields || !canEdit}
                  >
                  </TextField>
                  <Chip color='secondary' label={crosspost.promotionPlatform}></Chip>
                </Stack>
                <TextField
                  value={crosspost.boostCode ? crosspost.boostCode : ''}
                  onChange={(event) => onCrossLinkChange(video.id, crosspost.id, crosspost.liveLink, event.target.value)}
                  label="Boost code"
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin='dense'
                  disabled={isUpdatingFields || !canEdit}
                >
                </TextField>
              </React.Fragment>

            ))}
          </Box>
        </Box>
      </Box>
      <Divider variant='inset' flexItem sx={{ mb: 2 }} ></Divider>
    </>
  )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

const isValidURL = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const AgencyCampaignDialog = ({
  openDialog,
  handleCloseDialog,
  campaign,
  username,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const editedEvents = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [liveLink, setLiveLink] = useState([]);
  const [boostCode, setBoostCode] = useState("");

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  // Fetch conversation for this campaign and creator
  const { data: conversation, isLoading: isConversationLoading, refetch: refetchConversation } = useQuery(
    ["campaigns", campaign?.id, "conversation", username],
    () =>
      client.conversations.checkCampaign({ campaign_id: campaign?.id, creator_id: username }),
    {
      enabled: !!campaign && !!username && openDialog,
      refetchOnWindowFocus: false,
    }
  );

  const conversationId = conversation?.conversation_id;

  const { mutate: createConversation, isLoading: createConversationLoading } = useMutation(client.conversations.createCampaign, {
    onSuccess: (data) => {
      refetchConversation();
    },
    onError: (error) => {
      openDialog("Error", `Error creating conversation: ${error?.response?.data?.error || error.message}`);
    },
  });

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const creator = useMemo(() => {
    if (!campaign) return undefined;
    const creators = campaign.creators;
    return creators.find((creator) => creator.id === username);
  }, [campaign, username]);

  const videoList = creator?.videos.filter(video => video.parentId == null).map(video => ({
    ...video
  })) || [];

  const crosspostList = creator?.videos.filter(video => video.parentId != null).map(video => ({
    ...video
  })) || [];

  useEffect(() => {
    if (creator) {
      setLiveLink(creator.videos.filter(video => video.parentId == null).map(video => ({
        id: video.id,
        promotionType: video.promotionType,
        promotionPlatform: video.promotionPlatform,
        liveLink: video.liveLink,
        boostCode: video.boostCode,
        crossposts: creator.videos.filter(cross => cross.parentId == video.id).map(cross => ({
          id: cross.id,
          promotionType: cross.promotionType,
          promotionPlatform: cross.promotionPlatform,
          liveLink: cross.liveLink,
          boostCode: cross.boostCode,
        }))
      })));
    }
  }, [creator]);

  useEffect(() => {
    if (!openDialog || !campaign || !username) return;

    const fetchTimelineData = async () => {
      try {
        setIsLoading(true);
        const eventData = await client.campaigns.timeline_by_creator(
          campaign.id,
          username
        );
        editedEvents.current = false;
        setTimelineEvents(
          eventData.events.map((event) => ({
            ...event,
            projected_date: new Date(event.projected_date),
            ogStatus: event.status,
          }))
        );
        setIsLoading(false);
      } catch (err) {
        console.warn("Error fetching timeline data:", err);
        if (err.response && err.response.status === 404) {
          setTimelineEvents([]);
          console.warn("No timeline found for this campaign.");
        } else {
          throw err;
        }
        setIsLoading(false);
      }
    };

    fetchTimelineData();
  }, [openDialog, campaign, username]);

  useEffect(() => {
    if (creator) {
      setBoostCode(creator.boostCode || "");
    }
  }, [creator]);

  const OnEditTimelineField = (targetIndex, target, value) => {
    editedEvents.current = true;
    setTimelineEvents(
      timelineEvents.map((event, index) => {
        if (index === targetIndex) {
          return { ...event, [target]: value };
        }
        return event;
      })
    );
  };

  const { mutate: updateTimeline, isLoading: isUpdatingTimeline } = useMutation(
    ({ input }) =>
      client.creatorConnect.campaignUpdateTimeline(campaign.id, username, input),
    {
      onSuccess: () => {
        openAlert("Success", "Timeline Updated", closeDialog, null, "Ok", null);
      },
      onError: (error) => {
        const errorMsg =
          error.response && error.response.data
            ? error.response.data.error
            : error.message;
        openAlert(
          "Error",
          `Error when updating timeline: ${errorMsg}`,
          closeDialog,
          null,
          "Ok",
          null
        );
      },
    }
  );

  const onSaveChanges = () => {
    if (editedEvents.current && timelineEvents) {
      const foundSetComplete = timelineEvents.some(
        (event) =>
          event.ogStatus !== event.status && event.status === TimelineStatus[2]
      );
      if (foundSetComplete) {
        openAlert(
          "Completed Objective",
          "You're setting an objective as complete. This will notify the manager of this campaign, and won't allow you to do any further updates to this objective. Continue?",
          () => {
            closeDialog();
            updateTimeline({ input: timelineEvents });
          },
          closeDialog,
          "Continue",
          "Cancel"
        );
      } else {
        updateTimeline({ input: timelineEvents });
      }
    }
  };

  const canEdit =
    campaign?.campaign_status === "Launched" &&
    creator?.status !== undefined &&
    creator.status !== "Declined" &&
    creator.status !== "Dropped" &&
    creator.status !== "Pitched";

  const onLinkChange = (id, liveLink, boostCode) => {
    setLiveLink(prev => prev.map(video => ({
      ...video,
      ...(video.id == id && {
        liveLink: liveLink,
        boostCode: boostCode
      })
    })));
  };

  const onCrossLinkChange = (id, crossId, liveLink, boostCode) => {
    setLiveLink(prev => prev.map(video => ({
      ...video,
      ...(video.id == id && {
        crossposts: video.crossposts.map(cross => ({
          ...cross,
          ...(cross.id == crossId && {
            liveLink: liveLink,
            boostCode: boostCode
          })
        }))
      })
    })));
  };

  const { mutate: updateFields, isLoading: isUpdatingFields } = useMutation(
    (input) => client.agency.campaignUpdateFields(campaign.id, { ...input, creator: username }),
    {
      onSuccess: () => {
        openAlert("Success", "Fields updated.", closeDialog, null, "Ok", null);
      },
      onError: (error) => {
        const errorMsg =
          error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : error.message;
        openAlert(
          "Error",
          `Error when updating fields: ${errorMsg}`,
          closeDialog,
          null,
          "Ok",
          null
        );
      },
    }
  );

  const handleSaveLiveLinkAndBoostCode = () => {
    updateFields({
      liveLinks: liveLink.flatMap(video => [{
        id: video.id,
        liveLink: video.liveLink ? video.liveLink : null,
        boostCode: video.boostCode ? video.boostCode : null,
      }, ...video.crossposts.map(cross => ({
        id: cross.id,
        liveLink: cross.liveLink ? cross.liveLink : null,
        boostCode: cross.boostCode ? cross.boostCode : null,
      }))])
    });
  };

  const handleAssetsSent = () => {
    updateFields({ assetsSent: true });
  };

  if (!campaign) {
    return null;
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="xl"
        fullWidth
        scroll="paper"
        PaperProps={{ sx: { minHeight: "90vh", display: "flex", flexDirection: "column" } }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <StyledPaper elevation={2}>
            <Typography variant="h4" gutterBottom sx={{ p: 2 }}>
              {campaign.name}
            </Typography>
          </StyledPaper>
        </DialogTitle>

        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="agency campaign tabs"
          >
            <Tab label="Overview" />
            <Tab label="To Do List" />
            <Tab label="Chat with Brand" />
          </Tabs>
        </AppBar>

        <DialogContent sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              <TabPanel value={tabIndex} index={0}>
                <StyledPaper elevation={2}>
                  <Typography variant="body1">
                    <strong>Ideal Due Date:</strong>{" "}
                    {formatIdealDueDate(campaign.ideal_duedate)}
                  </Typography>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre' }}>
                    <strong>Brief:</strong><br />{formatLinksInMessage(campaign.brief)}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status:</strong> {campaign.campaign_status}
                  </Typography>
                </StyledPaper>

                {creator && (
                  <StyledPaper elevation={2}>
                    <Typography variant="h6" gutterBottom>
                      <strong>Rate:</strong>{" "}
                      {creator.agencyRate
                        ? `$${creator.agencyRate}`
                        : creator.price
                          ? `$${creator.price}`
                          : "N/A"}
                    </Typography>
                    <Grid container spacing={2}>

                      <Grid item xs={12} md={12}>
                        <Stack direction='row' alignItems='center' spacing={1} marginBlockEnd={1}>
                          <Typography variant="subtitle2">Promotions (x{videoList.length} video{videoList.length == 1 ? '' : 's'}): </Typography>
                          {videoList.map(video => (<Chip key={`video-${video.id}`} color='primary' label={`${video.promotionPlatform} - ${video.promotionType}`}></Chip>))}
                        </Stack>

                        <Stack direction='row' alignItems='center' spacing={1}>
                          <Typography variant="subtitle2">Crossposts: </Typography>
                          {crosspostList.map(video => (<Chip key={`cross-${video.id}`} color='secondary' label={`${video.promotionPlatform}`}></Chip>))}
                        </Stack>
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="body1" gutterBottom>
                      <strong>Creator Brief:</strong>
                    </Typography>
                    {isValidURL(creator.creatorBrief) ? (
                      <Link
                        href={creator.creatorBrief}
                        target="_blank"
                        rel="noopener"
                        variant="body1"
                      >
                        Open Creator Brief
                      </Link>
                    ) : (
                      <Typography variant="body1" sx={{ whiteSpace: 'pre' }}>
                        {formatLinksInMessage(creator?.creatorBrief || "")}
                      </Typography>
                    )}

                    <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                      <strong>Posting Instructions:</strong>
                    </Typography>
                    <Typography variant="body1">
                      {creator?.postingInstructions || ""}
                    </Typography>

                    {canEdit && (
                      <Box sx={{ mt: 2 }}>
                        <Box
                          sx={{
                            border: "2px dashed grey",
                            padding: 4,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (creator.submissionLink) {
                              window.open(creator.submissionLink, "_blank");
                            } else {
                              alert("No submission link available.");
                            }
                          }}
                        >
                          <Typography variant="body1">
                            Click here to upload your files
                          </Typography>
                        </Box>
                        {!creator.assetsSent && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleAssetsSent}
                            sx={{ mt: 2 }}
                            disabled={isUpdatingFields}
                          >
                            Mark Files as Sent
                          </Button>
                        )}
                        {creator.assetsSent && (
                          <Typography variant="body1" sx={{ mt: 2 }}>
                            Assets have been marked as sent.
                          </Typography>
                        )}
                      </Box>
                    )}

                    <Accordion sx={{ mt: 2 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="live-link-content"
                        id="live-link-header"
                      >
                        <Typography variant="body1">
                          <strong>Live Link and Boost Code</strong>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body1" gutterBottom>
                          <strong>{canEdit ? "Insert live link here" : "Live Link:"}</strong>
                        </Typography>
                        {liveLink.map((video) => (
                          <VideoDetails video={video}
                            key={`videos-${video.id}`}
                            onLinkChange={onLinkChange}
                            onCrossLinkChange={onCrossLinkChange}
                            isUpdatingFields={isUpdatingFields}
                            canEdit={canEdit}
                          ></VideoDetails>
                        ))}

                        {canEdit && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSaveLiveLinkAndBoostCode}
                            disabled={isUpdatingFields}
                            sx={{ mt: 2 }}
                          >
                            {isUpdatingFields ? "Saving..." : "Save Changes"}
                          </Button>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </StyledPaper>
                )}
              </TabPanel>

              <TabPanel value={tabIndex} index={1}>
                <StyledPaper elevation={2}>
                  <CreatorTimeline
                    timelineEvents={timelineEvents}
                    onEditField={OnEditTimelineField}
                    onSaveChanges={onSaveChanges}
                    isSavingChanges={isUpdatingTimeline}
                  />
                </StyledPaper>
              </TabPanel>

              <TabPanel value={tabIndex} index={2}>
                <StyledPaper elevation={2} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6" gutterBottom>
                    Chat with Brand
                  </Typography>
                  {isConversationLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : !!conversation?.conversation_id ? (
                    <ChatScreen
                      conversationId={conversation.conversation_id}
                      sx={{ minHeight: "480px", maxHeight: "720px", flex: 1 }}
                      specificReaderId={username}
                      margin={0}
                      showWarning={false}
                    />
                  ) : (
                    <>
                      <Typography variant="body1">
                        No conversation exists for this campaign yet.
                      </Typography>
                      <Button
                        sx={{ alignSelf: 'flex-start' }}

                        disabled={!!conversationId || createConversationLoading}
                        onClick={() => {
                          if (!conversationId && !createConversationLoading)
                            createConversation({
                              campaign_id: campaign?.id,
                              creator_id: username,
                            });
                        }}>Start conversation</Button>
                    </>
                  )}
                </StyledPaper>
              </TabPanel>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog alertState={dialogState} />
    </>
  );
};

export default AgencyCampaignDialog;