import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import client from '../../API';
import UploadFileDialog from '../../Components/tools/uploadfile';
import CreateAssetDialog from '../../Components/tools/createasset';
import CreateContractDialog from '../../Components/tools/createcontract';

import {
  AppBar,
  Toolbar,
  Button,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  useTheme,
  CircularProgress,
  Alert,
  TextField,
  IconButton,
  Container
} from '@mui/material';

import {
  PictureAsPdf,
  Description,
  Image,
  UploadFile,
  Add,
  Folder,
  InsertDriveFile,
  Assignment,
  Search
} from '@mui/icons-material';

const AssetsManager = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [createAssetDialogOpen, setCreateAssetDialogOpen] = useState(false);
  const [createContractDialogOpen, setCreateContractDialogOpen] = useState(false);
  const [prettyView, setPrettyView] = useState(true);
  const [activeTab, setActiveTab] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch: files
  const {
    data: files,
    isLoading: filesLoading,
    error: filesError
  } = useQuery('files', () => client.files.fetch(''), {
    initialData: [],
    initialDataUpdatedAt: 0
  });

  // Fetch: contracts
  const {
    data: contractsRes,
    isLoading: contractsLoading,
    error: contractsError
  } = useQuery('contracts', () => client.files.fetchContracts(), {
    initialData: { data: [] },
    initialDataUpdatedAt: 0
  });

  // Helper Functions
  const isImageFile = (filename) => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'];
    return imageExtensions.some((ext) => filename?.toLowerCase().endsWith(ext));
  };

  const determineFileType = (filename) => {
    if (filename?.toLowerCase().endsWith('.html') || filename?.toLowerCase().endsWith('.htm')) {
      return 'html';
    }
    if (filename?.toLowerCase().endsWith('.pdf')) {
      return 'pdf';
    }
    if (isImageFile(filename)) {
      return 'image';
    }
    return 'misc';
  };

  const isLoading = filesLoading || contractsLoading;
  const error = filesError || contractsError;

  const handleFileClick = (file) => {
    if (file.type === 'contract') {
      navigate(`/contract/${file.id}`);
    } else {
      window.open(file.file_url, '_blank');
    }
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries('files');
    queryClient.invalidateQueries('contracts');
  };

  // Dialog Handlers
  const openUploadDialog = () => setUploadDialogOpen(true);
  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
    handleSuccess();
  };

  const openCreateAssetDialog = () => setCreateAssetDialogOpen(true);
  const closeCreateAssetDialog = () => {
    setCreateAssetDialogOpen(false);
    handleSuccess();
  };

  const openCreateContractDialog = () => setCreateContractDialogOpen(true);
  const closeCreateContractDialog = () => {
    setCreateContractDialogOpen(false);
    handleSuccess();
  };

  const togglePrettyView = () => setPrettyView((prev) => !prev);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Contract formatting
  const contracts = (contractsRes?.data || []).map((contract) => ({
    ...contract,
    type: 'contract',
    filename: `${contract.name}.pdf`,
    fileType: 'contract',
    creation_date: contract.created_at
  }));

  // Format files for display
  const processedFiles = (files || []).map((file) => ({
    ...file,
    fileType: determineFileType(file.filename)
  }));

  // Merge files with contracts
  const mergedData = [...processedFiles, ...contracts];

  // Filter logic
  const filterFiles = (filesArray, filter) => {
    if (!filesArray) return [];
    switch (filter) {
      case 'contract':
        return filesArray.filter((file) => file.type === 'contract');
      case 'html':
      case 'pdf':
      case 'image':
      case 'misc':
        return filesArray.filter((file) => determineFileType(file.filename) === filter);
      default:
        return filesArray;
    }
  };

  const typeFilteredFiles = filterFiles(mergedData, activeTab);
  const filteredFiles = searchQuery
    ? typeFilteredFiles.filter((file) =>
        (file.name || file.filename).toLowerCase().includes(searchQuery.toLowerCase())
      )
    : typeFilteredFiles;

  // File Icon Rendering
  const renderFileIcon = (file) => {
    if (file.type === 'contract') return <Assignment fontSize="large" color="primary" />;
    if (file.fileType === 'image') return <Image fontSize="large" />;
    if (file.fileType === 'pdf') return <PictureAsPdf fontSize="large" />;
    return <Description fontSize="large" />;
  };

  const renderFilePreview = (file) => {
    if (file.type === 'contract') {
      return <Assignment fontSize="large" color="primary" sx={{ fontSize: 80, margin: 'auto' }} />;
    }
    if (isImageFile(file.filename)) {
      return (
        <img
          src={file.file_url}
          alt={file.filename}
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '200px',
            objectFit: 'cover',
            borderRadius: '8px'
          }}
        />
      );
    }
    return renderFileIcon(file);
  };

  // Loading & Error
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error" sx={{ margin: 2 }}>
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      {/* Top Navigation Bar */}
      <AppBar position="static" sx={{ mb: 4 }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Your Files
          </Typography>
          <Button
            variant="contained"
            onClick={openUploadDialog}
            startIcon={<UploadFile />}
            sx={{ ml: 2 }}
          >
            Upload
          </Button>
        </Toolbar>
      </AppBar>

      {/* Main Content Container */}
      <Container maxWidth="xl" sx={{ mb: 8 }}>
        {/* Tabs & Search Options */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 2,
            mb: 3
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              flexGrow: 1,
              '& .MuiTabs-indicator': {
                height: 4,
                borderRadius: 2
              }
            }}
          >
            <Tab label="All Assets" value="all" icon={<Folder />} />
            <Tab label="Contracts" value="contract" icon={<Assignment />} />
            <Tab label="HTML" value="html" icon={<Description />} />
            <Tab label="PDF" value="pdf" icon={<PictureAsPdf />} />
            <Tab label="Images" value="image" icon={<Image />} />
            <Tab label="Other" value="misc" icon={<InsertDriveFile />} />
          </Tabs>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search files..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton sx={{ p: '4px' }} disabled>
                    <Search />
                  </IconButton>
                )
              }}
            />
            <FormControlLabel
              control={<Switch checked={prettyView} onChange={togglePrettyView} />}
              label="Gallery"
            />
          </Box>
        </Box>

        {/* Main Files Section */}
        {prettyView ? (
          <Grid container spacing={3}>
            {filteredFiles?.map((file) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
                <Card
                  component={file.type === 'contract' ? Link : 'div'}
                  to={file.type === 'contract' ? `/contract/${file.id}` : undefined}
                  onClick={() => file.type !== 'contract' && handleFileClick(file)}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    textDecoration: 'none',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6,
                      cursor: 'pointer'
                    }
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 200,
                      p: 2
                    }}
                  >
                    {renderFilePreview(file)}
                  </Box>
                  <CardContent sx={{ flexGrow: 0 }}>
                    <Typography variant="subtitle1" fontWeight={600} noWrap>
                      {file.name || file.filename}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(file.created_at || file.creation_date).toLocaleDateString()}
                      {file.type === 'contract' && ' • Contract'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead
                sx={{
                  backgroundColor: theme.palette.action.hover
                }}
              >
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Created Date</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFiles?.map((file) => (
                  <TableRow
                    key={file.id}
                    hover
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {renderFileIcon(file)}
                        <Typography variant="body2" noWrap>
                          {file.name || file.filename}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          file.type === 'contract'
                            ? 'Contract'
                            : file.fileType?.toUpperCase() || 'UNKNOWN'
                        }
                        size="small"
                        color={file.type === 'contract' ? 'secondary' : 'primary'}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(file.created_at || file.creation_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleFileClick(file)}
                      >
                        {file.type === 'contract' ? 'View' : 'Preview'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>

      {/* Dialogs */}
      <UploadFileDialog open={uploadDialogOpen} onClose={closeUploadDialog} />
      <CreateAssetDialog open={createAssetDialogOpen} onClose={closeCreateAssetDialog} />
      <CreateContractDialog open={createContractDialogOpen} onClose={closeCreateContractDialog} onSuccess={handleSuccess} />
    </>
  );
};

export default AssetsManager;
