// CampaignTimelineEntry.jsx
import { Box, IconButton, TextField } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { formatDateToYYYYMMDD } from '../../../../Utils/constants';
const todayDate = new Date();

function PickerToLocal(dateObject) {
    const localDate = new Date(dateObject + "T00:00");
    return localDate;
}

export default function CampaignTimelineEntry({ event, onEditField, onDelete }) {


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            marginBlock: 1
        }}>
            <TextField
                label="Objective"
                value={event.objective}
                onChange={(e) => onEditField('objective', e.target.value)}
                sx={{ flex: 1 }}
                fullWidth
            />
            <TextField
                label="Date"
                type='date'
                value={formatDateToYYYYMMDD(event.projected_date)}
                inputProps={{ min: formatDateToYYYYMMDD(todayDate) }}
                onChange={(e) => onEditField('projected_date', PickerToLocal(e.target.value))}
                InputLabelProps={{ shrink: true }}
                sx={{ width: 160 }}
            />
            <IconButton onClick={onDelete} size="small">
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Box>
    )
}