import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Avatar,
  Divider,
  IconButton,
  useTheme,
  Box,
  Tabs,
  Tab,
  TextField
} from '@mui/material';
import {
  PictureAsPdf,
  Image as ImageIcon,
  InsertDriveFile,
  Close as CloseIcon,
  Search
} from '@mui/icons-material';

import UploadFileDialog from './uploadfile';
import { useQuery } from 'react-query';
import API from '../../API';

/** 
 * If you already have a `getFileType` or `determineFileType` 
 * utility in your codebase, you can rely on that instead. 
 * For demonstration, here's an inline version:
 */
const isImageFile = (filename = '') => {
  const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'];
  return imageExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
};

const determineFileType = (filename = '') => {
  if (filename.toLowerCase().endsWith('.pdf')) return 'pdf';
  if (isImageFile(filename)) return 'image';
  // You can add more detections (html, docx, etc.) if needed
  return 'misc';
};

const renderFileIcon = (filename = '') => {
  const iconStyle = { fontSize: 36 };
  const fileType = determineFileType(filename);

  if (fileType === 'image') {
    return (
      <Avatar
        variant="rounded"
        sx={{
          width: 60,
          height: 60,
          bgcolor: 'transparent',
          boxShadow: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}
        src={filename} // or a file URL property if needed
      >
        <ImageIcon sx={iconStyle} color="primary" />
      </Avatar>
    );
  }

  const iconProps = {
    style: iconStyle,
    color: fileType === 'pdf' ? 'error' : 'action'
  };

  return (
    <Avatar
      variant="rounded"
      sx={{
        width: 60,
        height: 60,
        bgcolor: 'background.paper',
        boxShadow: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      {fileType === 'pdf' ? <PictureAsPdf {...iconProps} /> : <InsertDriveFile {...iconProps} />}
    </Avatar>
  );
};

/**
 * Helper function for filtering by category:
 * 
 * - "pdf": show only PDFs
 * - "image": show only images
 * - "misc": everything else
 * - "all": show everything
 */
const filterFilesByCategory = (files = [], category) => {
  if (!files.length) return [];

  if (category === 'all') return files;
  return files.filter((file) => determineFileType(file.filename) === category);
};

const SelectAssetDialog = ({ open, onClose, selectedAsset, onAssetClick }) => {
  const theme = useTheme();

  // Fetch files only (no separate "contracts" in this example)
  const { data: filesResponse, refetch: refetchFiles } = useQuery(
    'files',
    () => API.files.fetch(''),
    {
      enabled: open,
      refetchOnWindowFocus: false,
      // Provide a fallback so we don't run into "undefined"
      initialData: []
    }
  );

  // Convert raw file data to a standardized format if needed:
  // e.g. add fileType for each entry
  const files = useMemo(() => {
    if (!Array.isArray(filesResponse)) return [];
    return filesResponse.map((file) => ({
      ...file,
      fileType: determineFileType(file.filename) // e.g. "pdf", "image", "misc"
    }));
  }, [filesResponse]);

  /** Dialog & UI States */
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  // For categories
  const [activeTab, setActiveTab] = useState('all');

  // For searching
  const [searchQuery, setSearchQuery] = useState('');

  /** Handlers */
  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
    refetchFiles();
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Filter by category first
  const categoryFilteredFiles = filterFilesByCategory(files, activeTab);

  // Then filter by search
  const filteredFiles = useMemo(() => {
    if (!searchQuery) return categoryFilteredFiles;
    const lowerSearch = searchQuery.toLowerCase();
    return categoryFilteredFiles.filter((file) =>
      (file.name || file.filename).toLowerCase().includes(lowerSearch)
    );
  }, [categoryFilteredFiles, searchQuery]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {/* Title Bar */}
      <DialogTitle
        sx={{
          bgcolor: theme.palette.primary.main,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Select an Asset
        </Typography>

        <IconButton onClick={handleClose} sx={{ color: 'white' }} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Filter Row (Search & Tabs) */}
      <Box sx={{ px: 2, pt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 2,
            mb: 2
          }}
        >
          {/* Tabs for categories */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              flexGrow: 1,
              '& .MuiTabs-indicator': {
                height: 3,
                borderRadius: 2
              }
            }}
          >
            <Tab label="All" value="all" />
            <Tab label="PDF" value="pdf" />
            <Tab label="Images" value="image" />
            <Tab label="Misc" value="misc" />
          </Tabs>

          {/* Search Bar */}
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search files..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: <Search sx={{ color: 'text.secondary' }} />
            }}
          />
        </Box>

        {/* Subtitle + Item Count Chip */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            pb: 1
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {activeTab === 'all' ? 'All Files' : activeTab.toUpperCase()}
          </Typography>
          <Chip
            label={`${filteredFiles.length} items`}
            size="small"
            sx={{ ml: 'auto', bgcolor: 'primary.dark', color: 'white' }}
          />
        </Box>
      </Box>

      <Divider />

      {/* Dialog Content */}
      <DialogContent sx={{ p: 3, bgcolor: 'background.default' }}>
        <Grid container spacing={3}>
          {filteredFiles.map((file) => {
            const isSelected = selectedAsset?.id === file.id;
            return (
              <Grid item xs={12} sm={6} md={4} key={file.id}>
                <Card
                  onClick={() => onAssetClick(file)}
                  sx={{
                    cursor: 'pointer',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    border: isSelected
                      ? `2px solid ${theme.palette.primary.main}`
                      : '1px solid transparent',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 4,
                      backgroundColor: 'action.hover'
                    },
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      gap: 2,
                      p: 2
                    }}
                  >
                    {renderFileIcon(file?.filename)}
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 600,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        maxHeight: '3rem'
                      }}
                    >
                      {file.filename}
                    </Typography>
                    {/* Show actual MIME type or fileType if you prefer */}
                    <Chip
                      label={(file.fileType || 'FILE').toUpperCase()}
                      size="small"
                      color="default"
                      variant="outlined"
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>

      <Divider />

      {/* Actions */}
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          onClick={() => setUploadDialogOpen(true)}
          sx={{ mr: 'auto' }}
        >
          Upload New File
        </Button>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>

      {/* Upload Dialog */}
      <UploadFileDialog open={uploadDialogOpen} onClose={handleUploadDialogClose} />
    </Dialog>
  );
};

export default SelectAssetDialog;
