import React, { useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import API from "../../../API";
import { globalStyles } from "../../../Utils/Styles";
import MarketplaceBooking from "./MarketplaceBooking";
import AlertDialog from "../../../Components/AlertDialog";
import useAlertDialog from "../../../Components/useAlertDialog";
import {
  parseLocalFloat,
  sortCreators,
  getPlatformAndPromotion,
  platformToFollowing,
  platformToLink,
} from "../../../Utils/constants";
import routes from "../../../Config/routes";

const SearchPage = () => {
  const navigate = useNavigate();
  // Alerts & backdrops
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // Fetch creators list
  const {
    data: creatorList,
    isFetching: creatorIsLoading,
  } = useQuery({
    queryKey: ["creators"],
    queryFn: async () =>
      sortCreators(await API.creators.list({ include_reviews: true, include_public: true })),
    refetchOnWindowFocus: false,
    initialData: [],
  });

  // Mutation: create multi-influencer campaign
  const { mutate: postCampaign, isLoading: loadingCampaign } = useMutation({
    mutationFn: API.campaigns.create,
    onError: (error) => {
      openDialog(
        "Error",
        <>
          An error occurred while creating your campaign: <br />
          {error?.response?.data?.error || error.message}
        </>,
        closeDialog,
        closeDialog,
        "Ok"
      );
    },
    onSuccess: (data) => {
      openDialog(
        "Campaign Builder",
        <>Your campaign has been created successfully</>,
        () => navigate(`/editcampaign/${data.id}`),
        () => navigate(`/editcampaign/${data.id}`),
        "Ok"
      );
    },
  });

  // Mutation: create single-partnership
  const { mutate: postPartnership, isLoading: loadingPartnership } = useMutation(
    API.partnerships.book,
    {
      onError: (error) => {
        openDialog(
          "Error",
          <>
            An error occurred while creating your partnership: <br />
            {error?.response?.data?.error || error.message}
          </>,
          closeDialog,
          closeDialog,
          "Ok"
        );
      },
      onSuccess: (data) => {
        openDialog(
          "Partnership Builder",
          <>Your partnership has been created successfully</>,
          () => navigate(`/partnerships/${data.data.id}`),
          () => navigate(`/partnerships/${data.data.id}`),
          "Ok"
        );
      },
    }
  );

  /**
   * Called by MarketplaceBooking on the final step for multi-creator flows
   */
  const onFinishCampaign = (campaign) => {
    const newCreators = campaign.creatorsData.map((creator) => {
      const price = parseFloat(creator.adjustedPrice);
      const getNextId = (videos) => {
        const highestId = videos.reduce((max, video) => Math.max(max, video.id), 0);
        return highestId + 1;
      };
      const nextId = getNextId(creator.videos);
      let crossPostOffset = 0; // Track the cumulative cross-post offset
      return {
        id: creator.creator,
        name: creator.creator,
        price,
        agencyRate: price * 0.95,
        pfphref: creator.pfphref,
        videos: [
          ...creator.videos.map((video) => {
            const { platform, promotion } = getPlatformAndPromotion(video.rateKey);
            return ({
              id: video.id,
              parentId: null,
              promotionPlatform: platform,
              promotionType: promotion,
              platformLink: creator[platformToLink[platform]],
              following: creator[platformToFollowing[platform]],
            })
          }),
          ...creator.videos.flatMap((video) => {
            const result = [...video.crossPostingKeys].map((crossPost, index) => {
              const { platform, promotion } = getPlatformAndPromotion(crossPost);
              return ({
                id: nextId + crossPostOffset + index,
                parentId: video.id,
                promotionPlatform: platform,
                promotionType: promotion,
                platformLink: creator[platformToLink[platform]],
                following: creator[platformToFollowing[platform]],
              })
            })
            crossPostOffset += video.crossPostingKeys.size;
            return result;
          }
          )
        ]
      };
    });

    const timelineEvents = Object.entries(campaign.timelinesData).flatMap(
      ([creatorId, events]) => events.map((event) => ({ ...event, username: creatorId }))
    );

    const campaignPayload = {
      name: campaign.campaignData.campaignName,
      brief: campaign.campaignData.asset
        ? campaign.campaignData.asset.file_url
        : campaign.campaignData.campaignBrief,
      creators: newCreators,
      timelines: timelineEvents,
      idealDueDate: campaign.campaignData.idealDueDate,
      emailRecipient: campaign.campaignData.emailRecipient,
      agency: campaign.campaignData.isAgency,
      campaign_manager: campaign.campaignData.manager,
    };

    // Normal multi-creator campaign creation
    postCampaign(campaignPayload);
  };

  /**
   * Called if user clicks "Invite to partnership" from the LeftColumnSearch (optional).
   * Or we can rely solely on the cart logic.
   */
  const handleInviteToPartnership = (creator) => {
    // Navigate to the internal booking page, passing the creator data via state
    navigate(routes.creatorBooking, { state: { creator } });
  };

  return (
    <>
      <AlertDialog alertState={dialogState} />

      {/* Show a backdrop if any of the mutations are loading */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loadingCampaign || loadingPartnership}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* The multi-step workflow container */}
      <Box
        id="search-holder"
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <MarketplaceBooking
          onInviteToPartnership={handleInviteToPartnership}
          data={creatorList}
          loading={creatorIsLoading}
          onSubmitCampaign={onFinishCampaign}
        />
      </Box>
    </>
  );
};

export default SearchPage;
