import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  CardHeader,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import CreatorDetailsCard from './creatorDetailsCard';
import {
  getRelatedPromotions,
  platformRatesListFromCreator,
  platformRatesListFromSelectedKeys,
  promotionLabelsFromList,
} from '../../../../Utils/constants';

const MIN_BASE_PRICE_MULTIPLIER = 0.7;

const CreatorDetails = ({
  selectedCreators,
  creatorsData,
  setCreatorsData,
  isBlitzFeeOnCreators,
  setIsBlitzFeeOnCreators,
  onNext,
  onBack,
  onRemove,
  data,
}) => {
  const [triedNext, setTriedNext] = useState(false);
  const [allPromotion, setAllPromotion] = useState('');
  const [allCrosspost, setAllCrosspost] = useState(new Set());

  useEffect(() => {
    setCreatorsData((prevData) => {
      let changed = false;
      const updated = prevData.map((creator) => {
        if (creator.originalBasePrice === undefined) {
          changed = true;
          return {
            ...creator,
            originalBasePrice: creator.basePrice || 0,
            usageRightsKey: creator.usageRights ? '30_days' : '',
            usageRightsOverride: undefined,
          };
        }
        return creator;
      });
      return changed ? updated : prevData;
    });
  }, [setCreatorsData]);

  useEffect(() => {
    let sharedRateKey = null;
    for (const creator of creatorsData) {
      if (creator.videos.length !== 1) {
        sharedRateKey = '';
        break;
      }
      const videoRateKey = creator.videos[0].rateKey;
      if (sharedRateKey === null) {
        sharedRateKey = videoRateKey;
      } else if (sharedRateKey !== videoRateKey) {
        sharedRateKey = '';
        break;
      }
    }
    setAllPromotion(sharedRateKey || '');
  }, [creatorsData]);

  const calculateBasePrice = (creator, videos = []) => {
    return videos.reduce((accum, video) => {
      const videoBase = creator[video.rateKey] || 0;
      const crossPostCost = Array.from(video.crossPostingKeys).reduce((sum, crossKey) => {
        const cost = creator[crossKey] || 0;
        return sum + Math.max(cost / 4, 0);
      }, 0);
      return accum + Math.max(videoBase, 0) + crossPostCost;
    }, 0);
  };

  const calculateUsageRightsCost = (basePrice, usageRightsKey, usageRightsOverride) => {
    if (!usageRightsKey) return 0;
    const recommended = basePrice * (USAGE_RIGHTS_OPTIONS[usageRightsKey]?.recommendedPercentage || 0);
    return usageRightsOverride !== undefined ? usageRightsOverride : recommended;
  };

  const handlePriceChange = (index, newPrice) => {
    setCreatorsData((prev) => {
      const updated = [...prev];
      const creator = { ...updated[index] };
      const numericPrice = Math.max(0, parseFloat(newPrice) || 0);
      creator.basePrice = numericPrice;
      
      if (creator.videos.length > 0 && creator.videos[0].rateKey) {
        const remainingCost = calculateBasePrice(creator, creator.videos.slice(1));
        creator[creator.videos[0].rateKey] = Math.max(0, numericPrice - remainingCost);
      }
      
      creator.adjustedPrice = numericPrice + calculateUsageRightsCost(
        numericPrice,
        creator.usageRightsKey,
        creator.usageRightsOverride
      );
      updated[index] = creator;
      return updated;
    });
  };

  const getNextId = (videos) => {
    const highestId = videos.reduce((max, video) => Math.max(max, video.id), 0);
    return highestId + 1;
  };

  const handleAddVideo = (index) => {
    setAllPromotion('');
    setAllCrosspost(new Set([]));
    setCreatorsData((prev) => {
      const updated = [...prev];
      const updatedCreator = { ...updated[index] };
      const nextId = getNextId(updatedCreator.videos);

      const updatedVideos = [
        ...updatedCreator.videos,
        {
          id: nextId,
          rateKey: '',
          crossPostingKeys: new Set([]),
        },
      ];

      updatedCreator.videos = updatedVideos;
      updated[index] = updatedCreator;
      return updated;
    });
  };

  const handleRemoveVideo = (index, videoIndex) => {
    setAllPromotion('');
    setCreatorsData((prev) => {
      const updated = [...prev];
      const updatedCreator = { ...updated[index] };
      updatedCreator.videos = updatedCreator.videos.filter((_, i) => i !== videoIndex);
      updated[index] = updatedCreator;
      return updated;
    });
  };

  const handleOptionChange = (index, option, value, videoOption = undefined, videoIndex = undefined) => {
    if (option === 'videos' && videoOption === 'rateKey') {
      setAllPromotion('');
      setAllCrosspost(new Set([]));
    }
    if (option === 'videos' && videoOption === 'crossPostingKeys') {
      setAllCrosspost(new Set([]));
    }

    setCreatorsData((prev) => {
      const updated = [...prev];
      const updatedCreator = { ...updated[index] };

      switch (option) {
        case 'videos': {
          const updatedVideos = [...updatedCreator.videos];
          const updatedVideo = { ...updatedVideos[videoIndex] };

          if (videoOption === 'crossPostingKeys') {
            updatedVideo[videoOption] = new Set([...value]);
          } else {
            if (videoOption === 'rateKey') {
              updatedVideo.crossPostingKeys = new Set();
            }
            updatedVideo[videoOption] = value;
          }
          updatedVideos[videoIndex] = updatedVideo;
          updatedCreator[option] = updatedVideos;
          break;
        }
        default:
          updatedCreator[option] = value;
      }

      updatedCreator.basePrice = calculateBasePrice(updatedCreator, updatedCreator.videos);
      updatedCreator.adjustedPrice = updatedCreator.basePrice + calculateUsageRightsCost(
        updatedCreator.basePrice,
        updatedCreator.usageRightsKey,
        updatedCreator.usageRightsOverride
      );
      updated[index] = updatedCreator;
      return updated;
    });
  };

  const handleGeneralPromotions = (event) => {
    if (event.target.value) {
      setAllPromotion(event.target.value);
      setAllCrosspost(new Set([]));
      setCreatorsData((prev) =>
        prev.map((creator) => {
          const newVideos = [
            {
              id: 1,
              rateKey: event.target.value,
              crossPostingKeys: new Set([]),
            },
          ];
          const newBasePrice = calculateBasePrice(creator, newVideos);
          return {
            ...creator,
            videos: newVideos,
            basePrice: newBasePrice,
            adjustedPrice: newBasePrice + calculateUsageRightsCost(
              newBasePrice,
              creator.usageRightsKey,
              creator.usageRightsOverride
            ),
          };
        })
      );
    } else {
      setAllPromotion('');
      setAllCrosspost(new Set([]));
      setCreatorsData((prev) =>
        prev.map((creator) => {
          return {
            ...creator,
            videos: [],
            basePrice: 0,
            adjustedPrice: 0,
          };
        })
      );
    }
  };

  const handleGeneralCrosspost = (event) => {
    const newCrossposts = new Set([...event.target.value]);
    setAllCrosspost(newCrossposts);
    setCreatorsData((prev) =>
      prev.map((creator) => {
        const newVideos = [
          {
            id: 1,
            rateKey: allPromotion,
            crossPostingKeys: newCrossposts,
          },
        ];
        const newBasePrice = calculateBasePrice(creator, newVideos);
        return {
          ...creator,
          videos: newVideos,
          basePrice: newBasePrice,
          adjustedPrice: newBasePrice + calculateUsageRightsCost(
            newBasePrice,
            creator.usageRightsKey,
            creator.usageRightsOverride
          ),
        };
      })
    );
  };

  const generalRates = useMemo(() => {
    if (creatorsData.length === 0) return [];
    const creatorRatesList = creatorsData.map(platformRatesListFromCreator);
    const commonRateKeys = creatorRatesList
      .map((rates) => rates.map((rate) => rate.rateKey))
      .reduce((commonKeys, rateKeys) => commonKeys.filter((key) => rateKeys.includes(key)));
    return creatorRatesList[0]
      .filter((rate) => commonRateKeys.includes(rate.rateKey))
      .map(({ rateKey, label }) => ({ label, rateKey }));
  }, [creatorsData]);

  const generalCrossposts = useMemo(() => {
    if (creatorsData.length === 0 || !allPromotion) return [];
    const relatedKeys = getRelatedPromotions(allPromotion);
    const creatorRatesList = creatorsData.map((c) =>
      platformRatesListFromSelectedKeys(c, relatedKeys)
    );
    const commonRateKeys = creatorRatesList
      .map((rates) => rates.map((r) => r.rateKey))
      .reduce((commonKeys, rateKeys) => commonKeys.filter((key) => rateKeys.includes(key)));
    return creatorRatesList[0]
      .filter((rate) => commonRateKeys.includes(rate.rateKey))
      .map(({ rateKey, label }) => ({ label, rateKey }));
  }, [creatorsData, allPromotion]);

  const projectSum = creatorsData.reduce((sum, c) => sum + c.adjustedPrice, 0);
  const blitzFee = isBlitzFeeOnCreators ? 0 : projectSum * 0.05;
  const finalCost = projectSum + blitzFee;

  const handleGoNext = () => {
    const invalid = creatorsData.some(
      (creator) =>
        !creator.basePrice ||
        creator.basePrice <= 0 ||
        creator.videos.length === 0 ||
        creator.videos.some((v) => !v.rateKey)
    );
    setTriedNext(true);
    if (!invalid) onNext();
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {creatorsData.map((creator, index) => (
            <CreatorDetailsCard
              key={creator.creator}
              creator={creator}
              index={index}
              onRemove={onRemove}
              handleOptionChange={handleOptionChange}
              handlePriceChange={handlePriceChange}
              handleAddVideo={handleAddVideo}
              handleRemoveVideo={handleRemoveVideo}
              triedNext={triedNext}
              setCreatorsData={setCreatorsData}
            />
          ))}
        </Grid>

        <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
          <Box sx={(theme) => ({ position: { xs: 'static', md: 'sticky' }, top: theme.spacing(2) })}>
            <TextField
              value={allPromotion}
              onChange={handleGeneralPromotions}
              label="Set every Promotion Type"
              select
              fullWidth
              sx={{ marginBlockEnd: 1 }}
            >
              <MenuItem value="">None</MenuItem>
              {generalRates.map((promo) => (
                <MenuItem key={`general-${promo.rateKey}`} value={promo.rateKey}>
                  {promo.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Set every Crosspost"
              select
              fullWidth
              variant="outlined"
              sx={{ marginBlockEnd: 2 }}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => promotionLabelsFromList(selected).join(', '),
              }}
              value={[...allCrosspost]}
              onChange={handleGeneralCrosspost}
            >
              {generalCrossposts.map((promo) => (
                <MenuItem key={`general-cross-${promo.rateKey}`} value={promo.rateKey}>
                  <Checkbox checked={allCrosspost.has(promo.rateKey)} />
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: 'normal !important' } }}
                    primary={promo.label}
                  />
                </MenuItem>
              ))}
            </TextField>

            <Card>
              <CardHeader title="Project Summary" />
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                  Final Cost: ${finalCost.toFixed(2)}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                  <Button variant="contained" onClick={onBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGoNext}
                    disabled={creatorsData.length === 0}
                  >
                    Next
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreatorDetails;