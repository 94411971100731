import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box, CircularProgress, Container, Typography, Paper, Button
} from '@mui/material'
import { styled } from '@mui/system'
import { useQuery } from 'react-query'
import API from '../API'  // Adjust the path as needed

/**
 * This is a minimal example of fetching a public CreatorBioPage from
 * your new endpoint, e.g. GET /@{slug} which returns:
 * {
 *   "id": 1,
 *   "username": "someUser123",
 *   "slug": "someUser123",
 *   "title": "My Link in Bio",
 *   "description": "Check out my awesome page!",
 *   "theme": {
 *       "backgroundColor": "#000000",
 *       "foregroundColor": "#ffffff",
 *       ...
 *   },
 *   "is_public": true,
 *   "items": [
 *       {
 *         "id": 1,
 *         "component_type": "LINK",
 *         "label": "My Website",
 *         "url": "https://mysite.com",
 *         ...
 *       },
 *       { "component_type": "SOCIAL", ... },
 *       ...
 *   ]
 * }
 *
 * The component uses React Query to fetch this data using the API client.
 */

// Styled container that applies the custom theme from the bio page.
const BioPageContainer = styled('div')(({ theme, customtheme }) => ({
  backgroundColor: customtheme?.backgroundColor || theme.palette.background.default,
  color: customtheme?.foregroundColor || theme.palette.text.primary,
  minHeight: '100vh',
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export default function BioPage() {
  const { slug } = useParams()

  // Use React Query to fetch the public bio page.
  const { data: bioPage, error, isLoading } = useQuery({
    queryKey: ['bioPage', slug],
    queryFn: () => API.bio.getPublic(slug),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!slug,
  })

  // Function to map each item type to its corresponding component.
  const renderItem = (item) => {
    switch (item.component_type) {
      case 'LINK':
        return <LinkItem key={item.id} item={item} />
      case 'SOCIAL':
        return <SocialItem key={item.id} item={item} />
      case 'VIDEO_EMBED':
        return <VideoEmbedItem key={item.id} item={item} />
      default:
        return <GenericItem key={item.id} item={item} />
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" color="error" textAlign="center">
          Error: {error.message}
        </Typography>
      </Container>
    )
  }

  if (!bioPage) {
    return (
      <Container>
        <Typography variant="h5" textAlign="center">
          This bio page does not exist.
        </Typography>
      </Container>
    )
  }

  return (
    <BioPageContainer customtheme={bioPage.theme}>
      <Paper elevation={0} sx={{ padding: 2, marginBottom: 2, backgroundColor: 'transparent' }}>
        <Typography variant="h3" textAlign="center" gutterBottom>
          {bioPage.title || bioPage.slug}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {bioPage.description}
        </Typography>
      </Paper>

      {/* Render each item in the order determined by order_index */}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
        maxWidth="600px"
        alignItems="center"
      >
        {bioPage.items
          .sort((a, b) => a.order_index - b.order_index)
          .map(renderItem)}
      </Box>
    </BioPageContainer>
  )
}

/**
 * Examples of small item-specific components.
 * In production, you might split these into separate files or add more robust styling.
 */

function LinkItem({ item }) {
  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <Typography variant="h6">{item.label}</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.open(item.url, '_blank')}
      >
        Visit
      </Button>
    </Paper>
  )
}

function SocialItem({ item }) {
  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <Typography variant="h6">{item.label || 'Follow me'}</Typography>
      <Button
        variant="contained"
        onClick={() => window.open(item.url, '_blank')}
      >
        {item.description || 'Go to Social'}
      </Button>
    </Paper>
  )
}

function VideoEmbedItem({ item }) {
  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <Typography variant="h6">{item.label}</Typography>
      <Box>
        <iframe
          title="videoEmbed"
          src={item.url}
          width="100%"
          height="315"
          allowFullScreen
          style={{ border: 'none' }}
        />
      </Box>
    </Paper>
  )
}

function GenericItem({ item }) {
  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <Typography variant="h6">{item.label}</Typography>
      {item.description && <Typography>{item.description}</Typography>}
      {item.url && (
        <Button variant="contained" onClick={() => window.open(item.url, '_blank')}>
          View
        </Button>
      )}
    </Paper>
  )
}
