// ColorPickerGrid.js
import React from 'react';
import { Box, Typography } from '@mui/material';

// Preset colors – feel free to customize these
const colorOptions = [
  '#FFFFFF', '#000000', '#FF5733', '#33FF57', '#3357FF',
  '#F1C40F', '#9B59B6', '#1ABC9C', '#E67E22', '#E74C3C'
];

const ColorPickerGrid = ({ selectedColor, onColorSelect }) => {
  return (
    <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={1} sx={{ marginTop: 1 }}>
      {colorOptions.map((color) => (
        <Box
          key={color}
          onClick={() => onColorSelect(color)}
          sx={{
            width: 30,
            height: 30,
            backgroundColor: color,
            border: selectedColor === color ? '2px solid black' : '1px solid #ccc',
            cursor: 'pointer',
          }}
        />
      ))}
    </Box>
  );
};

export default ColorPickerGrid;
