import React from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import { formatDateToYYYYMMDD, TimelineStatus } from "../../../../Utils/constants";
import useIsDesktop from "../../../../Hooks/useIsDesktop";

function VideoDetails({
  video,
  onLinkChange,
  onCrossLinkChange,
  isUpdatingFields,
  canEdit,
}) {

  const isDesktop = useIsDesktop();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ minWidth: "70px" }}
        >
          Video #{String(video.id).padStart(3, "0")}
        </Typography>
        <Box sx={{ flex: 1, ml: 2 }}>
          {!isDesktop && <Stack direction="row" spacing={1} alignItems="center" width='fit-content' marginLeft='auto' marginRight='0px' mb={1}><Chip
            variant="outlined"
            color="secondary"
            label={video.promotionPlatform}
          />
            <Chip
              variant="outlined"
              color="primary"
              label={video.promotionType}
            /></Stack>}
          <Stack direction="row" spacing={1} alignItems="center" mb={1}>
            <TextField
              value={video.liveLink || ""}
              onChange={(e) =>
                onLinkChange(video.id, e.target.value, video.boostCode)
              }
              label="Live Link"
              fullWidth
              variant="outlined"
              size="small"
              disabled={isUpdatingFields || !canEdit}
            />
            {isDesktop && <><Chip
              variant="outlined"
              color="secondary"
              label={video.promotionPlatform}
            />
              <Chip
                variant="outlined"
                color="primary"
                label={video.promotionType}
              /></>}
          </Stack>
          <TextField
            value={video.boostCode || ""}
            onChange={(e) =>
              onLinkChange(video.id, video.liveLink, e.target.value)
            }
            label="Boost Code"
            fullWidth
            variant="outlined"
            size="small"
            disabled={isUpdatingFields || !canEdit}
            sx={{ mb: 1 }}
          />
          {video.crossposts.map((crosspost) => (
            <Box key={crosspost.id} sx={{ ml: 3, mt: 1 }}>
              {!isDesktop && <Chip
                variant="outlined"
                color="secondary"
                sx={{
                  mb:1,
                  marginLeft:'auto',
                  marginRight:'auto'
                }}
                label={crosspost.promotionPlatform}
              />}
              <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                <TextField
                  value={crosspost.liveLink || ""}
                  onChange={(event) =>
                    onCrossLinkChange(
                      video.id,
                      crosspost.id,
                      event.target.value,
                      crosspost.boostCode
                    )
                  }
                  label="Crosspost Live Link"
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled={isUpdatingFields || !canEdit}
                />
                {isDesktop&&<Chip
                  variant="outlined"
                  color="secondary"
                  label={crosspost.promotionPlatform}
                />}
              </Stack>
              <TextField
                value={crosspost.boostCode || ""}
                onChange={(event) =>
                  onCrossLinkChange(
                    video.id,
                    crosspost.id,
                    crosspost.liveLink,
                    event.target.value
                  )
                }
                label="Boost Code"
                fullWidth
                variant="outlined"
                size="small"
                disabled={isUpdatingFields || !canEdit}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
    </>
  );
}

// Single timeline item card
const TimelineEntry = ({ event, onEditField }) => {
  const isCompleted = event.status === "complete";
  const isDisabled = event.ogStatus === "complete"; // can't modify after completed

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        borderLeft: `4px solid ${isCompleted ? "#66bb6a" : "#1976d2"
          }`, // success vs. primary
        opacity: isDisabled ? 0.6 : 1,
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textDecoration: isCompleted ? "line-through" : "none",
            color: isCompleted ? "text.secondary" : "inherit",
          }}
        >
          {event.objective}
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={event.notes || ""}
          onChange={(e) => onEditField(event.index, "notes", e.target.value)}
          placeholder="Add notes..."
          multiline
          rows={2}
          disabled={isDisabled}
          sx={{ mb: 2 }}
        />

        <Divider sx={{ my: 1 }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Chip
            label={formatDateToYYYYMMDD(event.projected_date)}
            variant="outlined"
            size="small"
          />
          <Typography variant="caption" color={isCompleted ? "success.main" : "text.secondary"}>
            {isCompleted ? "Completed" : "Pending"}
          </Typography>
        </Box>
      </CardContent>

      <CardActions sx={{ justifyContent: "flex-end", pt: 0 }}>
        <Button
          variant={isCompleted ? "contained" : "outlined"}
          color={isCompleted ? "success" : "primary"}
          onClick={() =>
            onEditField(
              event.index,
              "status",
              isCompleted ? TimelineStatus[1] : "complete"
            )
          }
          disabled={isDisabled}
          startIcon={isCompleted ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
          size="small"
        >
          {isCompleted ? "Completed" : "Mark Complete"}
        </Button>
      </CardActions>
    </Card>
  );
};

const CreatorTimeline = ({
  timelineEvents,
  onEditField,
  onSaveChanges,
  isSavingChanges,
  // Props for asset uploads & live link editing
  canEdit,
  isUpdatingFields,
  handleSaveLiveLinkAndBoostCode,
  handleAssetsSent,
  creator,
  liveLink,
  onLinkChange,
  onCrossLinkChange,
}) => {
  if (!timelineEvents) return null;

  // Sort by date
  const currentTimeline = timelineEvents
    .map((event, index) => ({ ...event, index }))
    .sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date));

  return (
    <Box>
      {/* =========================================
          FILE UPLOAD + MARK AS SENT
          ========================================= */}
      {canEdit && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            border: "1px dashed grey",
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography variant="body1" sx={{ mb: 1 }}>
            Upload Your Files
          </Typography>
          <Box
            sx={{
              border: "2px dashed grey",
              padding: 3,
              textAlign: "center",
              cursor: "pointer",
              borderRadius: 2,
            }}
            onClick={() => {
              if (creator?.submissionLink) {
                window.open(creator.submissionLink, "_blank");
              } else {
                alert("No submission link available.");
              }
            }}
          >
            <Typography variant="body2">
              Click here to upload your files
            </Typography>
          </Box>
          {!creator?.assetsSent ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAssetsSent}
              sx={{ mt: 2 }}
              disabled={isUpdatingFields}
            >
              Mark Files as Sent
            </Button>
          ) : (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Assets have been marked as sent.
            </Typography>
          )}
        </Box>
      )}

      {/* =========================================
          LIVE LINK + BOOST CODE
          ========================================= */}
      {canEdit && liveLink.length > 0 && (
        <Accordion defaultExpanded sx={{ mb: 3, borderRadius: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="live-link-content"
            id="live-link-header"
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Live Links &amp; Boost Codes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {liveLink.map((vid) => (
              <VideoDetails
                key={vid.id}
                video={vid}
                onLinkChange={onLinkChange}
                onCrossLinkChange={onCrossLinkChange}
                isUpdatingFields={isUpdatingFields}
                canEdit={canEdit}
              />
            ))}
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveLiveLinkAndBoostCode}
                disabled={isUpdatingFields}
                startIcon={
                  isUpdatingFields ? <CircularProgress size={20} /> : <SaveIcon />
                }
              >
                {isUpdatingFields ? "Saving..." : "Save Changes"}
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {/* =========================================
          THE ACTUAL "TO DO LIST"
          ========================================= */}
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        To Do List
      </Typography>
      <Grid container>
        {currentTimeline.map((event) => (
          <Grid item xs={12} key={event.id}>
            <TimelineEntry event={event} onEditField={onEditField} />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSaveChanges}
          disabled={isSavingChanges}
          startIcon={
            isSavingChanges ? <CircularProgress size={20} /> : <SaveIcon />
          }
        >
          {isSavingChanges ? "Saving..." : "Save Changes"}
        </Button>
      </Box>
    </Box>
  );
};

export default CreatorTimeline;
