import { Link } from "@mui/material";
import APIengagement from "../APIengagement";

export const formatLinksInMessage = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
        if (urlRegex.test(part)) {
            return (
                <Link key={index} href={part} target="_blank" rel="noopener noreferrer">
                    {part}
                </Link>
            );
        }
        return part;
    });
  };

export const getTotalFollowersInDict = (followersByPlatform)=>Object.values(followersByPlatform).reduce((total, followers) => total + followers, 0);

export const getFollowersByVideos = (videos)=>videos.reduce((acc, video) => {
  const platform = video.promotionPlatform;
  const followers = parseInt(video.following, 10) || 0;

  if (!acc[platform]) {
    acc[platform] = 0;
  }
  acc[platform] += followers;

  return acc;
}, {});

export const usageDurations = [
    { value: "30", label: "30 days" },
    { value: "60", label: "60 days" },
    { value: "90", label: "90 days" },
    { value: "180", label: "180 days" },
    { value: "360", label: "360 days" },
];

export const convertToBriefText = (updated) => {
  return `# Creative\n${updated.creativeBrief}\n\n# Additional Requirements\nUsage: ${updated.usage} days`;
};

export const parseCampaignBriefFromText = (text) => {
  const newRequirements = {
    creativeBrief: "",
    usage: "",
  };

  let currentSection = "";

  text.split("\n").forEach((line) => {
    if (line.startsWith("# Creative")) {
      currentSection = "creative";
    } else if (line.startsWith("# Additional Requirements")) {
      currentSection = "additional";
      console.log("switching to additional section")
    } else if (currentSection === "creative") {
      newRequirements.creativeBrief += line + "\n";
    } else if (currentSection === "additional") {
      if (line.startsWith("Usage:")) {
        const split = line.replace("Usage:", "").trim().split(" ");
        if (split.length > 1 && usageDurations.some(usageEntry => usageEntry.value == split[0])) {
          newRequirements.usage = split[0];
          console.log('Usage', newRequirements.usage);
        }
      }
    }
  });

  newRequirements.creativeBrief = newRequirements.creativeBrief.trim();
  console.log('creative brief: ', newRequirements.creativeBrief);
  return newRequirements;
};

export const userExtras = ['services'];

export function getFirstReaderDate(readers, currentUser) {
  // Filter out the current user and sort by the date
  const entries = Object.entries(readers)
    .filter(([key]) => key !== currentUser) // Exclude the current user
    .sort(([, dateA], [, dateB]) => new Date(dateA) - new Date(dateB)); // Sort by date

  // Return the first date or null if no one else has read it
  return entries.length > 0 ? new Date(entries[0][1]) : null;
}

export function isMessageMine(userId, message) {
  if (message.sender_id === userId)
    return true;
  if (message.creator_id === userId)
    return true;
  return false
}

export function getFileType(filename) {
  // Ensure the filename has an extension
  if (!filename || !filename.includes('.')) {
    return 'unknown';
  }

  // Get the file extension
  const extension = filename.split('.').pop().toLowerCase();

  // Define file type categories
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'jfif'];
  const pdfExtensions = ['pdf'];

  // Determine the file type
  if (imageExtensions.includes(extension)) {
    return 'image';
  } else if (pdfExtensions.includes(extension)) {
    return 'pdf';
  } else {
    return 'other';
  }
}

export function formatDateToYYYYMMDD(dateTime) {
  if (!dateTime)
    return '2000-01-01';
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(dateTime.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function PickerToLocal(dateObject) {
  if (!dateObject)
    return null;
  const localDate = new Date(dateObject.getUTCFullYear(), dateObject.getUTCMonth(), dateObject.getUTCDate());
  return localDate;
}

export function dateToDatetimeLocal(date) {
  // toISOString() returns a string in ISO 8601 format
  // We slice it to remove the milliseconds and 'Z' at the end
  if (!date)
    return '';
  // Adjust for local timezone offset
  const offsetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return offsetDate.toISOString().slice(0, 16);
}

export const invoiceStatuses = [
  'pending',
  'approved',
  'paid',
  'cancelled'
]

export const payoutStatuses = [
  'approved',
  'confirmed',
  'pending',
  'cancelled',
  'paid'
]

export const withdrawalManualServices = [
  'paypal',
  'stripe',
  'gusto',
]

export const withdrawalTypes = [
  'manual',
]

export const withdrawalStatuses = [
  'pending',
  'approved',
  'paid',
  'cancelled',
]

export const serviceStatuses = [
  'inactive',
  'deleted',
  'active',
]

export const serviceApprovalStatuses = [
  'n/a',
  'pending',
  'approved',
  'denied',
]

function getTimeString() {
  const now = new Date();
  return now.getUTCFullYear().toString() +
    (now.getUTCMonth() + 1).toString().padStart(2, '0') +
    now.getUTCDate().toString().padStart(2, '0') +
    now.getUTCHours().toString().padStart(2, '0') +
    now.getUTCMinutes().toString().padStart(2, '0') +
    now.getUTCSeconds().toString().padStart(2, '0');
}

export function getPONumber(letterCode = 'TCC') {
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, "0");
  return `${letterCode}-${getTimeString()}-${random}`
}

const roundToThousands = (number) => {
  return Math.round(number / 1000) * 1000;
}

export const sortCreators = (creators) => {
  return creators.toSorted((a, b) => {
    // 1. Normalize rating (0-1 scale)
    const aRating = Math.round(parseFloat(a.average_rating || 0)) / 5;
    const bRating = Math.round(parseFloat(b.average_rating || 0)) / 5;

    // 2. Normalize followers (log scale to reduce magnitude differences)
    const calculateFollowers = (creator) =>
      Math.log1p(
        (parseInt(creator.tiktok, 10) || 0) +
        (parseInt(creator.instagram, 10) || 0) +
        (parseInt(creator.youtube, 10) || 0) +
        (parseInt(creator.follow_podcast, 10) || 0) +
        (parseInt(creator.follow_kick, 10) || 0) +
        (parseInt(creator.follow_x, 10) || 0)
      );

    const aFollowers = calculateFollowers(a);
    const bFollowers = calculateFollowers(b);

    // 3. Normalize promos pitched
    const aPromosPitched = (parseInt(a.promos_pitched, 10) || 0) / 100;
    const bPromosPitched = (parseInt(b.promos_pitched, 10) || 0) / 100;

    // Default weights
    const finalWeights = {
      rating: 1,
      followers: 1,
      promosPitched: 2
    };

    // Calculate weighted score
    const aScore =
      (aRating * finalWeights.rating) +
      (aFollowers * finalWeights.followers) +
      (aPromosPitched * finalWeights.promosPitched);

    const bScore =
      (bRating * finalWeights.rating) +
      (bFollowers * finalWeights.followers) +
      (bPromosPitched * finalWeights.promosPitched);

    return bScore - aScore;
  });
}

const parseNumber = (value) => {
  if (!value || value === "N/A") return 0;
  return parseInt(value.replace(/,/g, ""), 10);
};

/**
* Maps a list of platforms to their available audience data
* @param {Array<{
  *   label: string,
  *   icon: any
  * }} platforms - List of platform names to look for
  * @param {Object} creator - Creator object containing public data array
  * @returns {Array<{
  *   handle: string,
  *   average_views: number,
  *   average_comments: number,
  *   average_likes: number,
  *   icon: any
  * }} - Array matching platforms order, with null for unavailable data
  */
export const mapPlatformsToEngagement = (platforms, creator) => {
  if (!creator?.public || !Array.isArray(creator.public)) {
    return [];
  }

  return platforms.flatMap(platform => {
    // Find matching public entry for this platform
    const engagement = getPlatformEngagement(creator, platform.label);
    if (engagement == null) return [];

    // Create separate entries for each metric
    return [
      { label: 'AVG Views', value: engagement.average_views, icon: platform.icon },
      { label: 'AVG Likes', value: engagement.average_likes, icon: platform.icon },
      { label: 'AVG Comments', value: engagement.average_comments, icon: platform.icon }
    ];
  });
};

/**
 * Get engagement metrics for a specified platform from creator data
 * @param {Object} creatorData - The creator data object
 * @param {string} platformName - The platform name ('TikTok' or 'Instagram')
 * @returns {Object} Object containing likes and comments, or null if platform not found
 */
export function getPlatformEngagement(creator, platformName) {
  if (!creator)
    return null;
  // Find the platform data in the public array
  const platformData = creator.public.find(
    platform => platform.platform.name === platformName
  );

  // If platform not found, return null
  if (!platformData) {
    return null;
  }

  // Return engagement metrics
  return {
    average_likes: platformData.average_likes,
    average_comments: platformData.average_comments,
    average_views: platformData.average_views
  };
}

/**
 * Extracts username from platform-specific URLs
 * @param {string} platform - Platform name
 * @param {string} url - Platform URL
 * @returns {string} - Extracted username or original URL if pattern not matched
 */
export const extractUsername = (platform, url) => {
  if (!url) return url;

  try {
    const urlObj = new URL(url);

    switch (platform) {
      case 'Youtube':
        // Handles youtube.com/c/username or youtube.com/@username or youtube.com/channel/username
        const youtubeMatch = urlObj.pathname.match(/(?:\/c\/|@|\/channel\/|\/)([^@\/\?]+)/);
        return youtubeMatch ? `@${youtubeMatch[1]}` : url;

      case 'TikTok':
        // Handles tiktok.com/@username
        const tiktokMatch = urlObj.pathname.match(/\/@([^\/\?]+)/);
        return tiktokMatch ? `@${tiktokMatch[1]}` : url;

      case 'Instagram':
        // Handles instagram.com/username
        const igMatch = urlObj.pathname.match(/\/([^\/\?]+)/);
        return igMatch ? `@${igMatch[1]}` : url;

      case 'Twitch':
        // Handles twitch.tv/username
        const twitchMatch = urlObj.pathname.match(/\/([^\/\?]+)/);
        return twitchMatch ? `@${twitchMatch[1]}` : url;

      case 'Kick':
      case 'X':
      case 'Twitter':
        // Handles twitter.com/username or x.com/username
        const twitterMatch = urlObj.pathname.match(/\/([^\/\?]+)/);
        return twitterMatch ? `@${twitterMatch[1]}` : url;

      default:
        return url;
    }
  } catch (e) {
    // If URL parsing fails, return original
    return url;
  }
};

/**
* Maps a list of platforms to their available audience data
* @param {Array<{
*   label: string,
*   followingKey: string,
*   linkKey: string,
*   icon: any
* }} platforms - List of platform names to look for
* @param {Object} creator - Creator object containing public data array
* @returns {Array<{
*   handle: string,
*   link: string,
*   following: number,
*   average_views: number,
*   icon: any
* }} - Array matching platforms order, with null for unavailable data
*/
export const mapPlatformsToAudience = (platforms, creator) => {
  if (!creator?.public || !Array.isArray(creator.public)) {
    return [];
  }

  return platforms.map(platform => {
    // Find matching public entry for this platform
    const following = creator[platform.followingKey];
    if (following == null)
      return null;

    const link = creator[platform.linkKey];
    if (link == null)
      return null;

    const entry = creator.public.find(
      p => p?.platform?.name === platform.label
    );

    return {
      handle: extractUsername(platform.label, link),
      link: link,
      following: parseInt(following).toLocaleString(),
      average_views: entry?.average_views && entry.average_views !== 0
        ? entry.average_views.toLocaleString()
        : 'N/A',
      icon: platform.icon
    };
  }).filter(entry => entry !== null);
};

/**
 * Transforms platform promotion rates into a labeled array for selected rateKeys
 * @param {Object} creator - Creator object containing rate values
 * @param {Array<string>} selectedRateKeys - Array of rateKeys to filter by
 * @returns {Array<{label: string, rate: number, rateKey: string}>} Array of labeled rates for selected rateKeys
 */
export const promotionLabelsFromList = (selectedRateKeys) => {
  if (!selectedRateKeys || selectedRateKeys.length === 0) return [];

  return Object.entries(platPromoToKey).flatMap(([platform, promoTypes]) =>
    Object.entries(promoTypes)
      .map(([promoType, rateKey]) => {
        // Only process if the rateKey is in the selectedRateKeys
        if (!selectedRateKeys.includes(rateKey)) return null;

        return `${platform} - ${promoType}`;
      })
      // Filter out null entries from skipped rates
      .filter(entry => entry !== null)
  );
};

/**
 * Transforms platform promotion rates into a labeled array for selected rateKeys
 * @param {Object} creator - Creator object containing rate values
 * @param {Array<string>} selectedRateKeys - Array of rateKeys to filter by
 * @returns {Array<{label: string, rate: number, rateKey: string}>} Array of labeled rates for selected rateKeys
 */
export const platformRatesListFromSelectedKeys = (creator, selectedRateKeys) => {
  if (!creator || !selectedRateKeys || selectedRateKeys.length === 0) return [];

  return Object.entries(platPromoToKey).flatMap(([platform, promoTypes]) =>
    Object.entries(promoTypes)
      .map(([promoType, rateKey]) => {
        // Only process if the rateKey is in the selectedRateKeys
        if (!selectedRateKeys.includes(rateKey)) return null;

        const rate = creator[rateKey];

        // Skip if rate is null or undefined
        if (rate == null) return null;

        return {
          label: `${platform} - ${promoType}`,
          rate,
          rateKey
        };
      })
      // Filter out null entries from skipped rates
      .filter(entry => entry !== null)
  );
};

/**
 * Transforms platform promotion rates into a labeled array
 * @param {Object} creator - Creator object containing rate values
 * @returns {Array<{label: string, rate: number, rateKey: string}>} Array of labeled rates
 */
export const platformRatesListFromCreator = (creator) => {
  if (!creator) return [];

  return Object.entries(platPromoToKey).flatMap(([platform, promoTypes]) =>
    Object.entries(promoTypes)
      .map(([promoType, rateKey]) => {
        const rate = creator[rateKey];

        // Skip if rate is null or undefined
        if (rate == null) return null;

        return {
          label: `${platform} - ${promoType}`,
          rate,
          rateKey
        };
      })
      // Filter out null entries from skipped rates
      .filter(entry => entry !== null)
  );
};

/**
 * Transforms a rate key into an object with promotion and platform
 * @param {String} rateKey - rate key
 * @returns {{platform: string, promotion: string} | null} platform and promotion or null
 */
export function getPlatformAndPromotion(rateKey) {
  for (const platform in platPromoToKey) {
    for (const promotion in platPromoToKey[platform]) {
      if (platPromoToKey[platform][promotion] === rateKey) {
        return { platform, promotion };
      }
    }
  }
  return null; // Rate key not found
}

export const CreatorCampaignStatus = [
  'Pitched',
  'Approved',
  'Counter',
  'Accepted',
  'Declined',
  'Dropped'
]


export const getOfferFromCampaign = (campaign, username) => {
  const creators = campaign.creators;
  if (!creators || creators.length === 0) return 'N/A';
  const creator = creators.find(c => c.id === username);
  if (!creator) return 'N/A';
  const agencyRate = parseFloat(creator.agencyRate);
  const price = parseFloat(creator.price);
  return agencyRate ? `$${agencyRate.toFixed(2)}` : price ? `$${price.toFixed(2)}` : 'N/A';
};

export const isValidVideoUrl = (url) => {
  return (
    url.includes('youtube.com') ||
    url.includes('youtu.be') ||
    url.includes('tiktok.com')
  );


};

export function parseLocalInt(numberString) {
  if (!numberString)
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(/,/g, '');

  const result = parseInt(normalizedString, 10);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

export function parseLocalFloat(numberString) {
  if (numberString === undefined || numberString === null || numberString.trim() == '')
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(',', '.');

  const result = parseFloat(normalizedString);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

// this function returns a promise that resolves after n milliseconds
export const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));

export const drawerWidth = "20rem";
export const docsDrawerWidth = "15rem";

const emptyCountry = { code: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyAge = { age_range: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyGender = { name: 'N/A', value: Number.NEGATIVE_INFINITY };

export const CountSets = (sets = []) => (
  sets.reduce((counter, value) => counter + value.size, 0)
)

export const ToggleSet = (value, setGetter, setSetter) => {
  if (setGetter.has(value))
    setSetter(prevSet => {
      prevSet.delete(value)
      return new Set(prevSet)
    })
  else
    setSetter(prevSet => new Set(prevSet.add(value)))
}

export const highestValueCountry = (publicData) => {
  return publicData.reduce((ctrMax, country) => {
    if (country.value > ctrMax.value)
      ctrMax = country;
    return ctrMax;
  }, emptyCountry);
}

export const higherValueAge = (publicData) => {
  return publicData.reduce((ageMax, ageGroup) => {
    if (ageGroup.value > ageMax.value)
      ageMax = ageGroup;
    return ageMax;
  }, emptyAge);
}

export const higherValueGender = (publicData) => {
  return publicData.reduce((genderMax, genderGroup) => {
    if (genderGroup.value > genderMax.value)
      genderMax = genderGroup;
    return genderMax;
  }, emptyGender);
}

export const getAgeDemos = (demo) => {
  return demo.reduce((acc, entry) => {
    const { age_range, value } = entry;
    const existingEntry = acc.find(item => item.age_range === age_range);

    if (existingEntry) {
      existingEntry.value += Math.round(value);
    } else {
      acc.push({ age_range: age_range, value: Math.round(value) });
    }

    return acc;
  }, [])
}

export const getGenderDemos = (demo) => ([
  {
    gender: 'Male',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'male') accum += Math.round(item.value);
      return accum;
    }, 0)
  },
  {
    gender: 'Female',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'female') accum += Math.round(item.value);
      return accum;
    }, 0)
  }
]);

export function generateRainbowColors(x) {
  const colors = [];
  for (let i = 0; i < x; i++) {
    const hue = Math.floor((360 / x) * i); // Calculate the hue for each color
    const color = `hsl(${hue}, 80%, 70%)`; // Use HSL to create the color
    colors.push(color);
  }
  return colors;
}

export const TimelineStatus = [
  'incomplete',
  'in progress',
  'complete'
]

export const formatCampaignSum = (sum) => {
  const numericSum = typeof sum === "number" ? sum : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const formatProposalDate = (dateString) => {
  const date = new Date(dateString);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear().toString().substr(-2)}`;
};

//As used by insightiq
export const ageGroups = ["13-17", "18-24", "25-34", "35-44", "45-64", "65+"];

export const region = ["NACAUKAUS", "Europe", "Asia", "LATAM", "Africa"];

export const regionWithLabels = [
  { label: "North America and UK", value: "NACAUKAUS" },
  { label: "Europe", value: "Europe" },
  { label: "Asia", value: "Asia" }, { label: "Latin America", value: "LATAM" },
  { label: "Africa", value: "Africa" }
];

export const ethnicity = ["Asian", "Black", "Hispanic", "White", "Other"];

export const genders = ["Male", "Female", "Non-binary", "Other"];

export const niches = [
  "Activist",
  "Artist",
  "Beauty",
  "Cars",
  "Cosplay",
  "Comedy",
  "Country",
  "Dance",
  "Educational",
  "Fashion",
  "Fitness",
  "Food",
  "Gaming",
  "Lifestyle",
  "Music",
  "Pets",
  "Reviews",
  "Sports",
  "Tech",
  "Thirst Trap",
  "Travel",
  "Couples",
  "Crypto",
  "Adult Content",
  "+21",
]

export const getHeadersFromKeys = (keys) => {
  const keyToHeaderMap = {};

  // Build a map of keys to headers
  for (const platform in platPromoToKey) {
    for (const promo in platPromoToKey[platform]) {
      keyToHeaderMap[platPromoToKey[platform][promo]] = platPromoToHead[platform][promo];
    }
  }

  // Map the list of keys to headers
  return keys.map(key => keyToHeaderMap[key]).filter(header => header !== undefined);
};

export const platforms = [
  'TikTok',
  'Instagram',
  'Youtube',
  'Podcasts',  // New Platform
  'Twitch',    // New Platform (Streaming)
  'Kick',      // New Platform (Streaming)
  'X',         // New Platform (Others)
];

export const promotionTypeArray = [
  ["Sound", "Brand"], // TikTok
  ["Sound", "Brand", "Feed Post", "Story"],  // Instagram
  ["Integration", "Shorts", "Dedicated"], // Youtube
  ["Preroll", "Midroll", "Postroll", "Full"],  // Podcasts (Shows)
  ["Product"],    // Twitch (Streaming)
  ["Product"],    // Kick (Streaming)
  ["Feed Post", "Repost"],  // X (Others)
];

export const headerToKeyMap = {
  Creator: "creator",
  TikTok: "tiktok",
  Instagram: "instagram",
  Youtube: "youtube",
  Twitch: "twitch",
  X: 'follow_x',
  Kick: 'follow_kick',
  Podcasts: 'follow_podcast',
  "Geolocation/Gender/Ethnicity": "geolocation_gender_ethnicity",
  "Primary Market": "primary_markets",
  "Content Style": "notes_content_style",
  "TikTok Sound": "rate_tt_sound",
  "TikTok Brand": "rate_tt_brand",
  "IG Reels Sound": "rate_ig_reelsound",
  "IG Reels Brand": "rate_ig_reelbrand",
  "IG Feed Post": "rate_ig_feedpost",
  "IG Story Post": "rate_ig_story",
  "Instagram Link": "instagram_link",
  "TikTok Link": "tiktok_link",
  "Youtube Link": "youtube_link",
  "AVG Views": "avg_views",
  "Integration": "rate_yt_integ",
  "shorts": "rate_yt_short",
  "Dedicated": "rate_yt_dedicated"
};

export const headers = {
  TikTok: [
    "Creator",
    "TikTok",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],
  Instagram: [
    "Creator",
    "Instagram",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "Instagram Link",
    "AVG Views",
  ],
  Youtube: [
    "Creator",
    "Youtube",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],
  Podcasts: [
    "Creator",
    "Podcast",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],  // Shows
  Twitch: [
    "Creator",
    "Twitch",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],      // Streaming
  Kick: [
    "Creator",
    "Kick",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],        // Streaming
  X: [
    "Creator",
    "X",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ], // Others
};

export const platformToKey = {
  TikTok: ["tiktok", "rate_tt_sound", "rate_tt_brand"],
  Instagram: ["instagram", "rate_ig_feedpost", "rate_ig_reelsound", "rate_ig_reelbrand", "rate_ig_story"],
  Youtube: ["youtube", "rate_yt_integ", "rate_yt_short", "rate_yt_dedicated"],
  Podcasts: ["follow_podcast", "rate_podcast_preroll", "rate_podcast_midroll", "rate_podcast_postroll", "rate_podcast_full"],
  Twitch: ["twitch", "rate_twitch_product"],
  Kick: ["follow_kick", "rate_kick_product"],
  X: ["follow_x", "rate_x_feedpost", "rate_x_repost"],
};

export const getAllPlatformLinks = (creator) => Object.entries(platformToLink)
  .filter(([platform, linkKey]) => creator[linkKey]) // Filter out null links
  .map(([platform, linkKey]) => ({
    platform,
    link: creator[linkKey],
  }));

export const platformToLink = {
  TikTok: "tiktok_link",
  Instagram: "instagram_link",
  Youtube: "youtube_link",
  Podcasts: "podcast_link",
  Twitch: "twitch_link",
  Kick: "kick_link",
  X: "x_link",
};

const parseFollowerCount = (followerVar) => {
  if (!followerVar || followerVar === "N/A") return 0;
  if (Number.isFinite(followerVar)) return followerVar;
  return parseInt(followerVar.replace(/,/g, ""), 10) || 0;
};

export function calculateTotalFollowing(creator) {
  let totalFollowing = 0;

  // Iterate through platforms in the mapping
  for (const platform in platformToFollowing) {
    const followingKey = platformToFollowing[platform];

    // Retrieve the following count from the creator's data
    const followingCount = creator[followingKey];

    // Add to total if the count is valid
    if (followingCount !== null && followingCount !== undefined) {
      totalFollowing += parseFollowerCount(followingCount);
    }
  }

  return totalFollowing;
}

export const platformToFollowing = {
  TikTok: "tiktok",
  Instagram: "instagram",
  Youtube: "youtube",
  Podcasts: "follow_podcast",
  Twitch: "twitch",
  Kick: "follow_kick",
  X: "follow_x",
};

export function calculateAverageCreatorRate(creator) {
  let total = 0; // Sum of all valid rates
  let count = 0; // Count of valid rates

  // Iterate through platforms
  for (const platform in platPromoToKey) {
    const promotions = platPromoToKey[platform];

    // Iterate through promotion types for each platform
    for (const promoType in promotions) {
      const rateKey = promotions[promoType];

      // Retrieve the rate from the creator's data
      const rate = creator[rateKey];

      // Only include valid, non-null rates
      if (rate !== null && rate !== undefined) {
        total += rate;
        count++;
      }
    }
  }

  // Avoid division by zero
  return count > 0 ? total / count : 0;
}


// Crossposteable keys
const soundPromoKeys = [
  "rate_tt_sound",      // TikTok Sound
  "rate_ig_reelsound",   // Instagram Reel Sound
];

const brandPromoKeys = [
  "rate_tt_brand",      // TikTok Brand
  "rate_ig_reelbrand",   // Instagram Reel Brand
  "rate_yt_short"
];

// Array of related key groups
export const platPromoCross = [soundPromoKeys, brandPromoKeys];

/**
 * Get related promotion types for crossposting, excluding the selected key.
 * @param {string} selectedKey - The key for the currently selected promotion.
 * @returns {string[]} - An array of related promotion keys.
 */
export function getRelatedPromotions(selectedKey) {
  // Find the group containing the selected key
  const relatedGroup = platPromoCross.find(group => group.includes(selectedKey));

  if (!relatedGroup) {
    // If the selected key doesn't belong to any group, return an empty array
    return [];
  }

  // Exclude the selected key from the related group
  return relatedGroup.filter(key => key !== selectedKey);
}

export const platPromoToKey = {
  // Existing platforms
  TikTok: { "Sound": "rate_tt_sound", "Brand": "rate_tt_brand" },
  Instagram: { "Feed Post": "rate_ig_feedpost", "Sound": "rate_ig_reelsound", "Brand": "rate_ig_reelbrand", "Story": "rate_ig_story" },
  Youtube: { "Integration": "rate_yt_integ", "Shorts": "rate_yt_short", "Dedicated": "rate_yt_dedicated" },

  // New platforms and promotions
  Podcasts: {
    "Preroll": "rate_podcast_preroll",
    "Midroll": "rate_podcast_midroll",
    "Postroll": "rate_podcast_postroll",
    "Full": "rate_podcast_full"
  },  // Shows category
  Twitch: { "Product": "rate_twitch_product" },  // Streaming category
  Kick: { "Product": "rate_kick_product" },  // Streaming category
  X: { "Feed Post": "rate_x_feedpost", "Repost": "rate_x_repost" },  // Others category
};

export const platPromoToHead = {
  TikTok: {
    "Sound": "TikTok Sound",
    "Brand": "TikTok Brand"
  },
  Instagram: {
    "Sound": "IG Reels Sound",
    "Brand": "IG Reels Brand",
    "Feed Post": "IG Feed Post",
    "Story": "IG Story Post"
  },
  Youtube: {
    "Integration": "YT Integrated Promotion",
    "Shorts": "YT Short",
    "Dedicated": "YT Dedicated Video"
  },
  Podcasts: {
    "Preroll": "Podcast Pre-roll ad",
    "Midroll": "Podcast Mid-roll ad",
    "Postroll": "Podcast Post-roll ad",
    "Full": "Podcast Full episode Sponsorship"
  },
  Twitch: {
    "Product": "Twitch Product Placement"
  },
  Kick: {
    "Product": "Kick Product Placement"
  },
  X: {
    "Feed Post": "X Feed Post",
    "Repost": "X Repost"
  }
};

// Updated indexToPromoArray to include new platform entries
export const indexToPromoArray = [
  [0, 0], // TikTok Sound
  [0, 1], // TikTok Brand
  [1, 0], // Instagram Sound
  [1, 1], // Instagram Brand
  [1, 2], // Instagram Feed Post
  [1, 3], // Instagram Story Post
  [2, 0], // Youtube Integration
  [2, 1], // Youtube Shorts
  [2, 2], // Youtube Dedicated
  [3, 0], // Podcasts pre
  [3, 1], // Podcasts mid
  [3, 2], // Podcasts post
  [3, 3], // Podcasts full
  [4, 0], // Twitch placement
  [5, 0], // Kick placement
  [6, 0], // X Feed Post
  [6, 1], // X Repost
];

// Function to map index to platform and promotion type
export const indexToPromoFilter = (index) => {
  if (index === -1) {
    return {
      platform: null,
      promo: null
    }
  }
  return {
    platform: platforms[indexToPromoArray[index][0]],
    promo: promotionTypeArray[indexToPromoArray[index][0]][indexToPromoArray[index][1]]
  };
};
