import React, { useState, useEffect, useMemo, memo } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Pagination,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Autocomplete,
  Badge,
} from "@mui/material";

import {
  Search as SearchIcon,
  ViewList,
  GridView,
} from "@mui/icons-material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // For "recent"
import StarIcon from "@mui/icons-material/Star";            // For "popular"

import { useMutation, useQuery } from "react-query";
import client from "../../../API";
import CreatorContainers from "./creatorcontainersearch";
import PromoPlatFilter from "./Filters/PromoPlatFilter";
import NichesFilter from "./Filters/NichesFilters"; // nicheSet

import {
  headerToKeyMap,
  indexToPromoFilter,
  platformToKey,
  platPromoToKey,
  platPromoToHead,
  headers,
  calculateTotalFollowing,
  calculateAverageCreatorRate,
} from "../../../Utils/constants";
import { StyledTableRow } from "../../../Utils/styledcell";
import AllFilters from "./Filters/allFilters";
import MinMaxFilter from "./Filters/MinMaxFilter";
import ChatModalCreator from "../../../Components/ChatComponents/ChatModalCreators";

const LeftColumnSearch = memo(function LeftColumnSearch({
  onCreatorSelect,
  selectedItems,
  data,
  loading,
  onInviteToPartnership = null,
  itemsPerPage = 15,
  onChangePromo,
  initialFilters = null,
  initialCollectionId = "",
}) {
  // -------------------------
  // 1) Basic filter states
  // -------------------------
  const [promoIndex, setPromoIndex] = useState(1);
  const [nicheSet, setNicheSet] = useState(new Set([]));
  const [creatorGender, setCreatorGender] = useState(new Set([]));
  const [creatorRace, setCreatorRace] = useState(new Set([]));
  const [creatorRegion, setCreatorRegion] = useState(new Set([]));
  const [publicGender, setPublicGender] = useState(new Set([]));
  const [publicAge, setPublicAge] = useState(new Set([]));
  const [publicCountry, setPublicCountry] = useState(new Set([]));

  // Range filters (rate, followers, views)
  const [minRate, setMinRate] = useState(null);
  const [maxRate, setMaxRate] = useState(null);
  const [minFollow, setMinFollow] = useState(null);
  const [maxFollow, setMaxFollow] = useState(null);
  const [minViews, setMinViews] = useState(null);
  const [maxViews, setMaxViews] = useState(null);

  // UI states
  const [page, setPage] = useState(0);
  const [viewMode, setViewMode] = useState("grid"); // "grid" or "list"
  const [allFiltersOpen, setAllFiltersOpen] = useState(false);
 // New state for Chat Modal
  const [chatCreatorId, setChatCreatorId] = useState(null);
  const [chatOpen, setChatOpen] = useState(false);

  // This function will be passed into CreatorContainers
  const handleChatWithCreator = (creatorId) => {
    setChatCreatorId(creatorId);
    setChatOpen(true);
  };
    // ----------------------------------------
  // 2) Collections + popular/recent searches
  // ----------------------------------------
  // 2a) The user's own collections
  const { data: collections = [], refetch: refetchCollections } = useQuery(
    "collections",
    () => client.collections.list()
  );

  // 2b) Popular + recent searches
  const {
    data: allSearches, // shape: { popular: [...], recent: [...] }
    isLoading: allSearchesLoading,
  } = useQuery("allSearches", () => client.collections.popular(), {
    staleTime: 60_000,
  });

  // Merge popular + recent into a single array for our Autocomplete
  const [autoOptions, setAutoOptions] = useState([]);
  useEffect(() => {
    if (!allSearches) return;
    const { popular = [], recent = [] } = allSearches;

    const recentOpts = recent.map((r) => ({
      label: r.name,
      type: "recent",
      collection: r,
    }));
    const popularOpts = popular.map((p) => ({
      label: p.name,
      type: "popular",
    }));

    setAutoOptions([...recentOpts, ...popularOpts]);
  }, [allSearches]);

  // 2c) Currently selected "saved search" / collection
  const [selectedCollection, setSelectedCollection] = useState("");

  // 2d) Creating a new collection
  const [isAddCollectionDialogOpen, setIsAddCollectionDialogOpen] =
    useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");

  // 2e) Mutations
  const { mutate: createCollection } = useMutation(client.collections.create, {
    onSuccess: (newColl) => {
      refetchCollections();
      setNewCollectionName("");
      setSelectedCollection(newColl.id);
    },
  });
  const { mutate: deleteCollection } = useMutation(client.collections.delete, {
    onSuccess: (data) => {
      refetchCollections();
      setSelectedCollection("");
    },
  });

  // -------------------------------------------------
  // 3) AI Search: triggered on "Enter" or on "popular"
  // -------------------------------------------------
  const { mutate: aiSearchMutation, isLoading: aiSearching } = useMutation(
    async (queryText) => {
      // returns something like { collection_id, ... }
      const res = await client.creators.search({ query: queryText });
      return res;
    },
    {
      onError: (err) => console.error("AI search error:", err),
      onSuccess: (searchResult) => {
        refetchCollections();
        setSelectedCollection(searchResult.collection_id);
      },
    }
  );

  // ----------------------------------------
  // 4) Single local search text (freeSolo)
  // ----------------------------------------
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter" && searchQuery.trim().length > 0) {
      aiSearchMutation(searchQuery);
      // Clear local search text so we don't keep filtering
      setSearchQuery("");
    }
  };

  // Called when user selects an option from the dropdown
  const handleAutoCompleteChange = (event, newVal) => {
    if (!newVal) return;
    // If user typed some text not in the list, do nothing here
    if (typeof newVal === "string") {
      // This means freeSolo text was chosen as-is
      return;
    }
    // "type" can be "popular" or "recent"
    if (newVal.type === "popular") {
      aiSearchMutation(newVal.label); // run AI search
      setSearchQuery("");
    } else if (newVal.type === "recent") {
      // load filter from that saved collection
      setSelectedCollection(newVal.collection.id);
      setSearchQuery("");
    }
  };

  // ---------------------------------------------------
  // 5) If there's an initial collection, apply it once
  // ---------------------------------------------------
  useEffect(() => {
    if (initialCollectionId) {
      setSelectedCollection(initialCollectionId);
    }
  }, [initialCollectionId]);

  // ----------------------------------------------------
  // 6) If there are initial filters, apply them once
  // ----------------------------------------------------
  useEffect(() => {
    if (!initialFilters) return;

    const {
      promoIndex: colPromoIndex,
      nicheSet: colNicheSet,
      creatorGender: colCreatorGender,
      creatorRace: colCreatorRace,
      creatorRegion: colCreatorRegion,
      publicGender: colPublicGender,
      publicAge: colPublicAge,
      publicCountry: colPublicCountry,
      minPrice: colMinPrice,
      maxPrice: colMaxPrice,
      minFollow: colMinFollow,
      maxFollow: colMaxFollow,
      minViews: colMinViews,
      maxViews: colMaxViews,
    } = initialFilters;

    setPromoIndex(colPromoIndex ?? -1);
    onChangePromo?.(colPromoIndex ?? -1);

    setNicheSet(new Set(colNicheSet ?? []));
    setCreatorGender(new Set(colCreatorGender ?? []));
    setCreatorRace(new Set(colCreatorRace ?? []));
    setCreatorRegion(new Set(colCreatorRegion ?? []));
    setPublicGender(new Set(colPublicGender ?? []));
    setPublicAge(new Set(colPublicAge ?? []));
    setPublicCountry(new Set(colPublicCountry ?? []));
    setMinRate(colMinPrice ?? null);
    setMaxRate(colMaxPrice ?? null);
    setMinFollow(colMinFollow ?? null);
    setMaxFollow(colMaxFollow ?? null);
    setMinViews(colMinViews ?? null);
    setMaxViews(colMaxViews ?? null);
  }, [initialFilters, onChangePromo]);

  // --------------------------------------------------------------------
  // 7) Whenever `selectedCollection` changes, load those collection filters
  //    + clear local query so we "push" the new search
  // --------------------------------------------------------------------
  useEffect(() => {
    if (!selectedCollection) return;
    const coll = collections.find((c) => c.id === selectedCollection);
    if (!coll || !coll.filters) return;

    const {
      promoIndex: colPromoIndex,
      nicheSet: colNicheSet,
      creatorGender: colCreatorGender,
      creatorRace: colCreatorRace,
      creatorRegion: colCreatorRegion,
      publicGender: colPublicGender,
      publicAge: colPublicAge,
      publicCountry: colPublicCountry,
      minPrice: colMinPrice,
      maxPrice: colMaxPrice,
      minFollow: colMinFollow,
      maxFollow: colMaxFollow,
      minViews: colMinViews,
      maxViews: colMaxViews,
    } = coll.filters;

    setPromoIndex(colPromoIndex ?? -1);
    onChangePromo?.(colPromoIndex ?? -1);

    setNicheSet(new Set(colNicheSet ?? []));
    setCreatorGender(new Set(colCreatorGender ?? []));
    setCreatorRace(new Set(colCreatorRace ?? []));
    setCreatorRegion(new Set(colCreatorRegion ?? []));
    setPublicGender(new Set(colPublicGender ?? []));
    setPublicAge(new Set(colPublicAge ?? []));
    setPublicCountry(new Set(colPublicCountry ?? []));
    setMinRate(colMinPrice ?? null);
    setMaxRate(colMaxPrice ?? null);
    setMinFollow(colMinFollow ?? null);
    setMaxFollow(colMaxFollow ?? null);
    setMinViews(colMinViews ?? null);
    setMaxViews(colMaxViews ?? null);

    // Clear the current manual search text so we see *only* the collection's filters
    setSearchQuery("");

    setPage(0);
  }, [selectedCollection, collections, onChangePromo]);

  // ----------------------------------------
  // 8) Reset page when any filters change
  // ----------------------------------------
  useEffect(() => {
    setPage(0);
  }, [
    promoIndex,
    searchQuery,
    nicheSet,
    minRate,
    maxRate,
    minFollow,
    maxFollow,
    minViews,
    maxViews,
    creatorGender,
    creatorRace,
    creatorRegion,
    publicGender,
    publicAge,
    publicCountry,
  ]);

  const activeFiltersCount = (()=>{
    let count = 0;
    
    if(minFollow!==null && maxFollow !==null)
      count++;
    if(minViews!==null && maxViews !==null)
      count++;
    if(creatorGender.size > 0)
      count++;
    if(creatorRace.size > 0)
      count++;
    if(creatorRegion.size > 0)
      count++;
    if(publicGender.size > 0)
      count++;
    if(publicCountry.size > 0)
      count++;
    if(publicAge.size > 0)
      count++;

    return count;
  })();

  // ----------------------------------------
  // 9) Reset all filters
  // ----------------------------------------
  const handleResetFilters = () => {
    setPromoIndex(-1);
    onChangePromo?.(-1);
    setNicheSet(new Set([]));
    setCreatorGender(new Set([]));
    setCreatorRace(new Set([]));
    setCreatorRegion(new Set([]));
    setPublicGender(new Set([]));
    setPublicAge(new Set([]));
    setPublicCountry(new Set([]));
    setMinRate(null);
    setMaxRate(null);
    setMinFollow(null);
    setMaxFollow(null);
    setMinViews(null);
    setMaxViews(null);
    setSearchQuery("");
    setSelectedCollection("");
    setPage(0);
  };

  // ------------------------------------------------
  // 10) Save current filters into a new collection
  // ------------------------------------------------
  const handleSaveCollection = () => {
    const filters = {
      promoIndex,
      nicheSet: Array.from(nicheSet),
      creatorGender: Array.from(creatorGender),
      creatorRace: Array.from(creatorRace),
      creatorRegion: Array.from(creatorRegion),
      publicGender: Array.from(publicGender),
      publicAge: Array.from(publicAge),
      publicCountry: Array.from(publicCountry),
      minPrice: minRate,
      maxPrice: maxRate,
      minFollow,
      maxFollow,
      minViews,
      maxViews,
    };
    createCollection({ name: newCollectionName, filters });
  };

  const handleMinMaxApply = (value, minSetter, maxSetter) => {
    minSetter(value.min);
    maxSetter(value.max);
  };

  // --------------------------
  // 11) Filter logic
  // --------------------------
  const parseFollowerCount = (val) => {
    if (!val || val === "N/A") return 0;
    if (Number.isFinite(val)) return val;
    return parseInt(val.replace(/,/g, ""), 10) || 0;
  };
  const parseViews = (v) => parseInt((v || "").replace(/,/g, ""), 10) || 0;
  const priceRangeMatch = (p) => p >= (minRate ?? p) && p <= (maxRate ?? p);
  const followerRangeMatch = (fc) =>
    fc >= (minFollow ?? fc) && fc <= (maxFollow ?? fc);

  // Audience demographics checks
  const emptyCountry = { code: "N/A", value: Number.NEGATIVE_INFINITY };
  const emptyAge = { age_range: "N/A", value: Number.NEGATIVE_INFINITY };
  const emptyGender = { name: "N/A", value: Number.NEGATIVE_INFINITY };

  function highestValueCountry(publicData) {
    return publicData.reduce((max, demo) => {
      const localMax = demo.country_data?.reduce(
        (ctrMax, c) => (c.value > ctrMax.value ? c : ctrMax),
        emptyCountry
      );
      return localMax?.value > max.value ? localMax : max;
    }, emptyCountry);
  }

  function higherValueAge(publicData) {
    const sumsPerDemo = publicData.map((demo) =>
      demo.audience_data?.reduce((acc, entry) => {
        const { age_range, value } = entry;
        const existing = acc.find((x) => x.age_range === age_range);
        if (existing) {
          existing.value += Math.round(value);
        } else {
          acc.push({ age_range, value: Math.round(value) });
        }
        return acc;
      }, [])
    );
    return sumsPerDemo.reduce((globalMax, singleDemo) => {
      if (!singleDemo) return globalMax;
      const localMax = singleDemo.reduce(
        (ageMax, group) => (group.value > ageMax.value ? group : ageMax),
        emptyAge
      );
      return localMax.value > globalMax.value ? localMax : globalMax;
    }, emptyAge);
  }

  function higherValueGender(publicData) {
    const sumsPerDemo = publicData.map((demo) => {
      if (!demo.audience_data) return [];
      return [
        {
          name: "male",
          value: demo.audience_data.reduce(
            (acc, i) =>
              i.gender?.toLowerCase() === "male"
                ? acc + Math.round(i.value)
                : acc,
            0
          ),
        },
        {
          name: "female",
          value: demo.audience_data.reduce(
            (acc, i) =>
              i.gender?.toLowerCase() === "female"
                ? acc + Math.round(i.value)
                : acc,
            0
          ),
        },
      ];
    });
    return sumsPerDemo.reduce((globalMax, singleDemo) => {
      const localMax = singleDemo.reduce(
        (gMax, g) => (g.value > gMax.value ? g : gMax),
        emptyGender
      );
      return localMax.value > globalMax.value ? localMax : globalMax;
    }, emptyGender);
  }

  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) return [];

    const { platform, promo } = indexToPromoFilter(promoIndex) || {};

    // If not "All" but invalid platform/promo
    if (
      promoIndex !== -1 &&
      (!platformToKey[platform] || !platPromoToKey[platform])
    ) {
      return [];
    }

    return data.filter((creator) => {
      // A) Check platform presence unless "All"
      if (promoIndex !== -1) {
        const platformKeys = platformToKey[platform];
        const hasPlatformData = platformKeys.some(
          (k) => creator[k] && creator[k] !== "N/A"
        );
        if (!hasPlatformData) return false;
      }

      // B) If not "All," ensure there's a valid price for that promo
      let promoKey = null;
      if (promoIndex !== -1) {
        promoKey = platPromoToKey[platform][promo];
        if (!creator[promoKey]) {
          return false;
        }
      }

      // C) Niche
      const passesNiche =
        nicheSet.size > 0
          ? creator.primary_markets?.some((m) => nicheSet.has(m))
          : true;

      // D) Creator demographics
      const passesRace =
        creatorRace.size > 0
          ? Array.from(creatorRace).some((r) => creator.ethnicity?.includes(r))
          : true;
      const passesGender =
        creatorGender.size > 0
          ? Array.from(creatorGender).some((g) =>
            (creator.gender || []).includes(g)
          )
          : true;
      const passesRegion =
        creatorRegion.size > 0 ? creatorRegion.has(creator.region) : true;

      // E) Rate
      let rawRate = null;
      if (promoIndex === -1) {
        rawRate = calculateAverageCreatorRate(creator);
      } else {
        rawRate = creator[promoKey];
      }
      const passesRate =
        minRate != null && maxRate != null ? priceRangeMatch(rawRate) : true;

      // F) Followers
      let followerCount;
      if (promoIndex === -1) {
        followerCount = parseFollowerCount(calculateTotalFollowing(creator));
      } else {
        const keyForFollowers = platformToKey[platform][0];
        followerCount = parseFollowerCount(creator[keyForFollowers]);
      }
      const passesFollowers =
        minFollow != null && maxFollow != null
          ? followerRangeMatch(followerCount)
          : true;

      // G) Avg Views
      let passesViews = true;
      if (minViews != null && maxViews != null) {
        const val = parseViews(creator.avg_views);
        passesViews = val >= minViews && val <= maxViews;
      }

      // H) Audience demos
      let passDemoCountry = true,
        passDemoAge = true,
        passDemoGender = true;
      if (Array.isArray(creator.public)) {
        if (publicCountry.size > 0) {
          const topCountry = highestValueCountry(creator.public);
          passDemoCountry = publicCountry.has(topCountry.code);
        }
        if (publicAge.size > 0) {
          const topAge = higherValueAge(creator.public);
          passDemoAge = publicAge.has(topAge.age_range.toLowerCase());
        }
        if (publicGender.size > 0) {
          const topG = higherValueGender(creator.public);
          passDemoGender = publicGender.has(topG.name);
        }
      }

      // I) Local text filter (keyword)
      let passesText = true;
      if (searchQuery.trim().length > 0) {
        const low = searchQuery.toLowerCase();
        passesText = Object.values(creator).some(
          (val) => typeof val === "string" && val.toLowerCase().includes(low)
        );
      }

      return (
        passesNiche &&
        passesRace &&
        passesGender &&
        passesRegion &&
        passesRate &&
        passesFollowers &&
        passesViews &&
        passDemoCountry &&
        passDemoAge &&
        passDemoGender &&
        passesText
      );
    });
  }, [
    data,
    promoIndex,
    nicheSet,
    minRate,
    maxRate,
    minFollow,
    maxFollow,
    minViews,
    maxViews,
    creatorGender,
    creatorRace,
    creatorRegion,
    publicGender,
    publicAge,
    publicCountry,
    searchQuery,
  ]);

  // Show spinner if user typed something, but 0 local matches
  const showLocalSpinner =
    searchQuery.trim().length > 0 &&
    filteredData.length === 0 &&
    !aiSearching &&
    !loading;

  // --------------------------------------
  // 12) Table headers
  // --------------------------------------
  const genericHeaders = [
    "Creator",
    "Primary Market",
    "Followers",
    "Avg. Views",
    "Price",
    "CPM",
  ];
  let displayHeaders = genericHeaders;
  let platform = null;
  let promotionType = null;

  if (promoIndex !== -1) {
    const { platform: pf, promo } = indexToPromoFilter(promoIndex) || {};
    platform = pf;
    promotionType = promo;
    if (platform && headers[platform]) {
      displayHeaders = [...headers[platform]];
      const promoHeader = platPromoToHead[platform]?.[promotionType];
      if (promoHeader) {
        if (displayHeaders.length >= 2) {
          displayHeaders.splice(-2, 0, promoHeader);
        } else {
          displayHeaders.push(promoHeader);
        }
      }
    }
  }

  // ----------------------------------------
  // 13) Select / Invite
  // ----------------------------------------
  const handleSelectItem = (creatorId) => {
    const sel = filteredData.find((c) => c.creator === creatorId);
    if (!sel) return;

    if (promoIndex === -1) {
      // "All" mode
      const price = sel.computedPrice || null;
      const followingCount = sel.followers || 0;
      const relevantData = {
        id: sel.creator,
        name: sel.creator,
        price,
        following: followingCount,
        promotionPlatform: "",
        promotionType: "",
        platformLink: null,
        pfphref: sel.pfphref,
      };
      onCreatorSelect?.(creatorId, relevantData);
    } else {
      const { platform: pf, promo } = indexToPromoFilter(promoIndex) || {};
      if (!pf || !promo) return;

      const priceKey = platPromoToKey[pf]?.[promo];
      if (!priceKey) return;

      const rawPrice = sel[priceKey];
      const price = rawPrice != null ? rawPrice : null;

      const platformLinkKey = `${pf} Link`;
      const platformLink = sel[headerToKeyMap[platformLinkKey]];
      const followingCount = sel[platformToKey[pf][0]];

      const relevantData = {
        id: sel.creator,
        name: sel.creator,
        price,
        following: followingCount,
        promotionPlatform: pf,
        promotionType: promo,
        platformLink,
        pfphref: sel.pfphref,
      };
      onCreatorSelect?.(creatorId, relevantData);
    }
  };

  const handleInviteToPartnership = (creator) => {
    if (!onInviteToPartnership) return;
    const relevantData = {
      ...creator,
      id: creator.creator,
      name: creator.creator,
    };
    onInviteToPartnership?.(relevantData);
  };

  // ----------------------------------------
  // RENDER
  // ----------------------------------------
  return (
    <>
      {/* TOP BAR with filters + (centered) search + icons */}
      <Paper
        sx={{
          mb: 3,
          p: 1,
          paddingBlock: 2,
          display: "flex",
          alignItems: "stretch",
          flexWrap: "wrap",
          gap: 1,
        }}
        elevation={2}
        square
      >
        <Autocomplete
          sx={{
            flex: 1,
            minWidth: 200
          }}
          freeSolo
          openOnFocus
          fullWidth
          options={autoOptions}
          inputValue={searchQuery}
          onInputChange={(event, newInputValue) => setSearchQuery(newInputValue)}
          onKeyDown={handleSearchKeyDown}
          onChange={handleAutoCompleteChange}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.label
          }
          renderOption={(props, option) => {
            // If user typed freeSolo text
            if (typeof option === "string") {
              return <li {...props}>{option}</li>;
            }
            // Otherwise it's "recent" or "popular"
            return (
              <li {...props}>
                {option.type === "recent" ? (
                  <AccessTimeIcon sx={{ mr: 1, color: "gray" }} />
                ) : (
                  <StarIcon sx={{ mr: 1, color: "#F7B519" }} />
                )}
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              placeholder="Type a keyword or pick from recent/popular"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading || aiSearching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <SearchIcon sx={{ color: "action.active" }} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <PromoPlatFilter
          value={promoIndex}
          onChangeValue={(val) => {
            setPromoIndex(val);
            onChangePromo?.(val);
          }}
        />
        <NichesFilter value={nicheSet} onChangeValue={setNicheSet} />
        <MinMaxFilter
          minValue={minRate}
          maxValue={maxRate}
          width="6rem"
          name="Rate"
          onChangeValue={(value) =>
            handleMinMaxApply(value, setMinRate, setMaxRate)
          }
        />
        <Badge badgeContent={activeFiltersCount} color="secondary">
          <Button
            variant="outlined"
            onClick={() => setAllFiltersOpen(true)}
            sx={{ whiteSpace: "nowrap", maxHeight: 56 }}
          >
            All Filters
          </Button>
        </Badge>
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', maxWidth: { xs: '72px', md: 'none' } }}>
          <IconButton
            onClick={() => setViewMode("grid")}
            color={viewMode === "grid" ? "secondary" : "disabled"}
            size="small"
          >
            <GridView />
          </IconButton>
          <IconButton
            onClick={() => setViewMode("list")}
            color={viewMode === "list" ? "secondary" : "disabled"}
            size="small"
          >
            <ViewList />
          </IconButton>
          <IconButton onClick={handleResetFilters} color="warning" size="small">
            <CleaningServicesIcon />
          </IconButton>
        </Box>
      </Paper>

      {/* MAIN CONTENT: spinner, no-results, or list/grid */}
      {loading || aiSearching ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <CircularProgress />
          <Typography variant="body2" sx={{ mt: 1 }}>
            {aiSearching ? "Running AI search..." : "Loading creators..."}
          </Typography>
        </Box>
      ) : showLocalSpinner ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <CircularProgress />
          <Typography variant="body2" sx={{ mt: 1 }}>
            No local matches found. Press Enter to run AI search.
          </Typography>
        </Box>
      ) : filteredData.length === 0 ? (
        <Box sx={{ textAlign: "center", p: 4, flexGrow: 1 }}>
          <Typography variant="h6">
            No creators match these filters. Try adjusting them.
          </Typography>
        </Box>
      ) : viewMode === "grid" ? (
        <Box sx={{ px: { xs: 2, md: 1 }, mb: 1, flexGrow: 1 }}>
          <CreatorContainers
            creators={filteredData.slice(
              page * itemsPerPage,
              Math.min(page * itemsPerPage + itemsPerPage, filteredData.length)
            )}
            platform={platform}
            promotion={promotionType}
            selectedItems={selectedItems}
            onAddToCampaign={handleSelectItem}
            onInviteToPartnership={
              onInviteToPartnership ? handleInviteToPartnership : null
            }
            onChatWithCreator={handleChatWithCreator} // <--- New prop
          />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Paper elevation={3} sx={{ overflowX: "auto", mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">Select</TableCell>
                  {displayHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(
                    page * itemsPerPage,
                    Math.min(page * itemsPerPage + itemsPerPage, filteredData.length)
                  )
                  .map((creator) => (
                    <StyledTableRow
                      key={creator.creator}
                      selected={selectedItems.has(creator.creator)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedItems.has(creator.creator)}
                          onChange={() => handleSelectItem(creator.creator)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{creator.creator}</TableCell>
                      <TableCell>
                        {creator.primary_markets?.join(", ") || "N/A"}
                      </TableCell>
                      <TableCell>{creator.followers}</TableCell>
                      <TableCell>{creator.avg_views}</TableCell>
                      {promoIndex === -1 ? (
                        <TableCell>{creator.computedPrice ?? "N/A"}</TableCell>
                      ) : (
                        <TableCell>
                          {(() => {
                            const { platform: pf, promo } =
                              indexToPromoFilter(promoIndex) || {};
                            if (!pf || !promo) return "N/A";
                            const key = platPromoToKey[pf][promo];
                            return creator[key] ?? "N/A";
                          })()}
                        </TableCell>
                      )}
                      <TableCell>
                        {/* Example placeholder for CPM or any additional calc */}
                        N/A
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      )}

      {/* PAGINATION */}
      <Paper elevation={1} sx={{ p: 2, mt: 3, textAlign: "center" }} square>
        <Pagination
          count={Math.ceil(filteredData.length / itemsPerPage)}
          page={page + 1}
          onChange={(e, value) => setPage(value - 1)}
          color="primary"
          sx={{ marginInline: "auto", maxWidth: "fit-content" }}
        />
      </Paper>

      <AllFilters
        allFiltersOpen={allFiltersOpen}
        setAllFiltersOpen={setAllFiltersOpen}
        minFollow={minFollow}
        setMinFollow={setMinFollow}
        maxFollow={maxFollow}
        setMaxFollow={setMaxFollow}
        minViews={minViews}
        setMinViews={setMinViews}
        maxViews={maxViews}
        setMaxViews={setMaxViews}
        creatorGender={creatorGender}
        setCreatorGender={setCreatorGender}
        creatorRace={creatorRace}
        setCreatorRace={setCreatorRace}
        creatorRegion={creatorRegion}
        setCreatorRegion={setCreatorRegion}
        publicAge={publicAge}
        setPublicAge={setPublicAge}
        publicCountry={publicCountry}
        setPublicCountry={setPublicCountry}
        publicGender={publicGender}
        setPublicGender={setPublicGender}
        selectedCollection={selectedCollection}
        setSelectedCollection={setSelectedCollection}
        handleResetFilters={handleResetFilters}
        collections={collections}
      />

      {/* DIALOG: "All Filters" */}


      {/* DIALOG: Add New Collection */}
      <Dialog
        open={isAddCollectionDialogOpen}
        onClose={() => setIsAddCollectionDialogOpen(false)}
      >
        <DialogTitle>Add New Collection</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Collection Name"
            fullWidth
            value={newCollectionName}
            onChange={(e) => setNewCollectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddCollectionDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveCollection();
              setIsAddCollectionDialogOpen(false);
            }}
            disabled={!newCollectionName}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* Example usage of your existing ChatModalCreator */}
      <ChatModalCreator
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        creatorId={chatCreatorId}
      />
    </>
  );
});

export default LeftColumnSearch;
