import { Box, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { convertToBriefText, parseCampaignBriefFromText, usageDurations } from '../Utils/constants';

// Update the StructuredBriefEditor component
export default function StructuredBriefEditor({ brief, onBriefChange }) {
    const [requirements, setRequirements] = useState({
        creativeBrief: "",
        usage: "",
    });

    useEffect(() => {
        if (brief) {
            const parsed = parseCampaignBriefFromText(brief);
            setRequirements(parsed);
        }
    }, [brief]);

    const handleCreativeChange = (e) => {
        const updated = {
            ...requirements,
            creativeBrief: e.target.value,
        };
        setRequirements(updated);
        onBriefChange(convertToBriefText(updated));
    };

    const handleUsageChange = (event) => {
        const updated = {
            ...requirements,
            usage: event.target.value,
        };
        setRequirements(updated);
        onBriefChange(convertToBriefText(updated));
    };

    return (
        <Box sx={{ mt: 2 }}>
            <TextField
                fullWidth
                multiline
                minRows={3}
                label="Creative Brief"
                value={requirements.creativeBrief}
                onChange={handleCreativeChange}
                sx={{ mb: 3 }}
            />

            <Typography variant="subtitle1" gutterBottom>
                Usage Duration
            </Typography>
            <TextField
                select
                fullWidth
                value={requirements.usage}
                onChange={handleUsageChange}
                label="Usage Duration"
                sx={{ mb: 3 }}
            >
                {usageDurations.map((duration) => (
                    <MenuItem key={duration.value} value={duration.value}>
                        {duration.label}
                    </MenuItem>
                ))}
            </TextField>

            <Typography variant="subtitle1" gutterBottom>
                Crossposting Platforms
            </Typography>
        </Box>
    );
};