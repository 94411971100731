import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";


// Dialog to view an Offer or Deliverable
export default function CreatorViewOfferDialog({
    open,
    onClose,
    deliverable,
    onUpdateDeliverable,
    onSendMessage,
}) {
    const [status, setStatus] = useState(deliverable.status || '');
    const [amount, setAmount] = useState(deliverable.amount);
    const [revision, setRevision] = useState(deliverable.revision);
    const [isNegotiating, setIsNegotiating] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (status === "NEGOTIATE") {
            setIsNegotiating(true);
        } else {
            setIsNegotiating(false);
        }
    }, [status]);

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        setStatus(newStatus);
        if (newStatus === "NEGOTIATE") {
            setIsNegotiating(true);
        } else {
            setIsNegotiating(false);
        }
    };

    const handleAmountChange = (e) => setAmount(e.target.value);
    const handleRevisionChange = (e) => setRevision(e.target.value);

    const handleSubmitAction = async () =>{
        await onUpdateDeliverable(deliverable.id, {
            status,
            amount,
            revision: isNegotiating ? revision : deliverable.revision,
        });
        onSendMessage(`Your offer was ${status.toLowerCase()}d.`);
        setLoading(false);
        onClose();
    }

    const handleSubmit = () => {
        if (!loading){
            setLoading(true);
            handleSubmitAction();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>View Offer</DialogTitle>
            <DialogContent>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Deliverable Type: {deliverable.deliverable_type}
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Notes: {deliverable.notes}
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Due Date: {deliverable.due_date}
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Revision Amount: {deliverable.revision}
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Platforms: {deliverable.platforms.join(", ")}
                </Typography>

                <TextField
                    select
                    fullWidth
                    margin="normal"
                    value={status}
                    onChange={handleStatusChange}
                    label="Status"
                >
                    <MenuItem value="accept">Accept</MenuItem>
                    <MenuItem value="decline">Decline</MenuItem>
                    <MenuItem value="negotiate">Negotiate</MenuItem>
                </TextField>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Amount"
                    value={amount}
                    onChange={handleAmountChange}
                    type="number"
                    disabled={loading}
                />
                <Typography variant="caption" color="textPrimary">
                    * Blitz takes a 5% fee on all payments when they are issued.
                </Typography>

                {isNegotiating && (
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Revision Amount"
                        value={revision}
                        onChange={handleRevisionChange}
                        type="number"
                        disabled={loading}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
