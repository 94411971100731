import React, { useState, useMemo, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import client from '../../../../API';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  List,
  ListItem,
  Box,
  FormControlLabel,
  Card,
  CardContent,
  Grid,
  Backdrop,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Pagination,
  Switch
} from '@mui/material';
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CreatorDialog from "../creatorupdate";
import AlertDialog from '../../../../Components/AlertDialog';
import useAlertDialog from '../../../../Components/useAlertDialog';

const StyleTableHeader = (theme) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
});

const fixedMenuItemStyle = {
  width: '200px',
  textAlign: 'center'
};

const CreatorDetailsView = () => {
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [isCreatorDialogOpen, setCreatorDialogOpen] = useState(false);
  const [editingCreator, setEditingCreator] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [verificationFilter, setVerificationFilter] = useState('');
  const [refreshTokenFilter, setRefreshTokenFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState(false);
  const [invalidFilter, setInvalidFilter] = useState(false);
  const [avgViewsSort, setAvgViewsSort] = useState('');
  const [tiktokFollowersSort, setTiktokFollowersSort] = useState('');
  const [instagramFollowersSort, setInstagramFollowersSort] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [smsMessage, setSmsMessage] = useState('');
  const [sendMethod, setSendMethod] = useState('email');

  const [page, setPage] = useState(0);
  const itemsPerPage = 20;
  const [usePagination, setUsePagination] = useState(true);

  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const {
    data: creators,
    isFetching: creatorsLoading,
    error: creatorsError,
    refetch: fetchData
  } = useQuery({
    queryKey: ['creators'],
    queryFn: () => client.creators.adminFetch({ is_vendor: true }),
    refetchOnWindowFocus: false,
    initialData: []
  });

  useEffect(() => {
    if (creatorsError) {
      alert(
        creatorsError?.response?.data?.error ||
          creatorsError?.response?.data?.message ||
          creatorsError.message
      );
    }
  }, [creatorsError]);

  const totalUnverifiedCreators = useMemo(
    () => creators.filter((creator) => creator.user_status === 'unverified').length,
    [creators]
  );
  const totalVerifiedCreators = useMemo(
    () => creators.filter((creator) => creator.user_status === 'verified').length,
    [creators]
  );
  const totalUnconnectedCreators = useMemo(
    () => creators.filter((creator) => !creator.refresh_token_status).length,
    [creators]
  );

  const handleDialogOpen = (creator = null) => {
    setEditingCreator(creator);
    setCreatorDialogOpen(true);
  };

  const handleDialogClose = (refresh = false) => {
    setCreatorDialogOpen(false);
    setEditingCreator(null);
    if (refresh) fetchData();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleVerificationFilterChange = (event) => {
    setVerificationFilter(event.target.value);
  };

  const handleRefreshTokenFilterChange = (event) => {
    setRefreshTokenFilter(event.target.value);
  };

  const handlePhoneFilterChange = () => {
    setPhoneFilter(!phoneFilter);
  };

  const handleInvalidFilterChange = () => {
    setInvalidFilter(!invalidFilter);
  };

  const handleAvgViewsSortChange = (event) => {
    setAvgViewsSort(event.target.value);
  };

  const handleTiktokFollowersSortChange = (event) => {
    setTiktokFollowersSort(event.target.value);
  };

  const handleInstagramFollowersSortChange = (event) => {
    setInstagramFollowersSort(event.target.value);
  };

  const handleSelectCreator = (creatorId) => {
    setSelectedCreators((prevSelected) =>
      prevSelected.includes(creatorId)
        ? prevSelected.filter((id) => id !== creatorId)
        : [...prevSelected, creatorId]
    );
  };

  const handleSendUpdates = async () => {
    if (sendMethod === 'email') {
      const updates = selectedCreators.map((id) => {
        const creator = creators.find((creator) => creator.creator === id);
        return {
          email: creator.email,
          subject: emailSubject.replace(/{first_name}/g, creator.creator),
          body: emailBody.replace(/{first_name}/g, creator.creator),
        };
      });

      try {
        const result = await client.crm.update(updates);
        console.log('Email Updates sent:', result);
        setDialogOpen(false);
        setSelectedCreators([]);
        setEmailSubject('');
        setEmailBody('');
      } catch (error) {
        console.error('Failed to send email updates:', error);
      }
    } else if (sendMethod === 'sms') {
      const updates = selectedCreators.map((id) => {
        const creator = creators.find((creator) => creator.creator === id);
        return {
          phone_number: creator.phone_number,
          message: smsMessage.replace(/{first_name}/g, creator.creator),
        };
      });

      try {
        const result = await client.twilio.sendCreatorSms({ updates });
        console.log('SMS Updates sent:', result);
        setDialogOpen(false);
        setSelectedCreators([]);
        setSmsMessage('');
      } catch (error) {
        console.error('Failed to send SMS updates:', error);
      }
    }
  };

  const { mutate: softDeleteCreator, isLoading: softDeleteLoading } = useMutation(
    (creatorId) => client.creators.softDelete({ creator_id: creatorId }),
    {
      onSuccess: () => {
        openDialog(
          'Success',
          'Creator deleted succesfully',
          closeDialog,
          closeDialog,
          'Ok',
          null
        );
        fetchData();
      },
      onError: (error) => {
        console.error('Failed to delete creator:', error);
        openDialog(
          'Error',
          <>
            Error while deleting creator: <br />
            {error?.response?.data?.error || error.message}
          </>,
          closeDialog,
          closeDialog,
          'Ok',
          null
        );
      },
    }
  );

  const { mutateAsync: deleteCreator, isLoading: deleteLoading } = useMutation(
    (creatorId) => client.creators.delete({ creator_id: creatorId }),
    {
      onError: (error, variables) => {
        console.log('Variables: ', variables);
        if (error?.response?.data?.error === 'only_soft_delete') {
          openDialog(
            'Error',
            <>
              This creator has dependencies (Partnerships, Campaigns, CreatorConnect) and can't be
              deleted permanently
              <br />
              Want to soft delete?
            </>,
            () => softDeleteCreator(variables),
            closeDialog,
            'Yes',
            'Cancel'
          );
          return;
        }
        console.error('Failed to delete creator:', error);
        openDialog(
          'Error',
          <>
            Error while deleting creator {variables}: <br />
            {error?.response?.data?.error || error.message}
          </>,
          closeDialog,
          closeDialog,
          'Ok',
          null
        );
        return;
      },
    }
  );

  const handleDeleteSelected = async () => {
    try {
      for (const creatorId of selectedCreators) {
        await deleteCreator(creatorId);
      }
      openDialog(
        'Success',
        'Creator deleted succesfully',
        closeDialog,
        closeDialog,
        'Ok',
        null
      );
      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedCreators([]);
    }
  };

  const handleIncludeCreatorConnectLink = () => {
    const updatedBody = selectedCreators.reduce((body, id) => {
      const creator = creators.find((c) => c.creator === id);
      return `${body}\n\nCreator Connect: blitzpay.pro/creatorconnect/${creator.creator}`;
    }, emailBody);
    setEmailBody(updatedBody);
  };

  const handleIncludeCreatorMediaKit = () => {
    const updatedBody = selectedCreators.reduce((body, id) => {
      const creator = creators.find((c) => c.creator === id);
      return `${body}\n\nCreator Media Kit: blitzpay.pro/creators/${creator.creator}`;
    }, emailBody);
    setEmailBody(updatedBody);
  };

  const handleSendMethodChange = (event, newMethod) => {
    if (newMethod !== null) {
      setSendMethod(newMethod);
    }
  };

  // =======================
  // Filter & Sort
  // =======================
  const filteredCreators = creators
    .filter((creator) => {
      // 1) Status Filter
      if (statusFilter === 'Other' && (creator.status === 'Partner' || creator.status === 'Associate')) {
        return false;
      } else if (statusFilter !== '' && statusFilter !== 'Other' && creator.status !== statusFilter) {
        return false;
      }

      // 2) Invalid data filter
      if (invalidFilter && !creator.invalid_geogenderrace) {
        return false;
      }

      // 3) Phone filter
      if (phoneFilter) {
        if (creator.phone_number && !['NA', 'N/A'].includes(creator.phone_number.toUpperCase())) {
          return false;
        }
      }

      // 4) Verification filter
      if (verificationFilter !== '') {
        if (verificationFilter === 'verified' && creator.user_status !== 'verified') return false;
        if (verificationFilter === 'unverified' && creator.user_status !== 'unverified') return false;
      }

      // 5) Refresh token filter
      if (refreshTokenFilter !== '') {
        if (refreshTokenFilter === 'connected' && !creator.refresh_token_status) return false;
        if (refreshTokenFilter === 'not_connected' && creator.refresh_token_status) return false;
      }

      return true;
    })
    .filter((creator) => {
      // 6) Search by name, email, manager, agency, etc.
      const query = searchQuery.toLowerCase();

      const inCreatorName =
        (creator.creator || '').toLowerCase().includes(query);
      const inEmail = (creator.email || '').toLowerCase().includes(query);
      const inManager = (creator.manager || '').toLowerCase().includes(query);
      // If you store "agency" in a different field, adjust here
      const inAgency = (creator.agency || '').toLowerCase().includes(query);

      // existing fields
      const inTikTokRate = (creator.rate_tt_brand?.toString() || '').includes(query);
      const inIGFeedRate = (creator.rate_ig_feedpost?.toString() || '').includes(query);
      const inGeo = (creator.geolocation || '').toLowerCase().includes(query);
      const inGender = (creator.gender || '').toLowerCase().includes(query);
      const inEthnicity = (creator.ethnicity || '').toLowerCase().includes(query);
      const inAvgViews = (creator.avg_views || '').toString().includes(query);
      const inNotesContentStyle = (creator.notes_content_style || '').toLowerCase().includes(query);

      return (
        inCreatorName ||
        inEmail ||
        inManager ||
        inAgency ||
        inTikTokRate ||
        inIGFeedRate ||
        inGeo ||
        inGender ||
        inEthnicity ||
        inAvgViews ||
        inNotesContentStyle
      );
    });

  // Sort
  if (avgViewsSort === 'highest') {
    filteredCreators.sort((a, b) => b.avg_views - a.avg_views);
  } else if (avgViewsSort === 'lowest') {
    filteredCreators.sort((a, b) => a.avg_views - b.avg_views);
  }
  if (tiktokFollowersSort === 'highest') {
    filteredCreators.sort((a, b) => b.tiktok - a.tiktok);
  } else if (tiktokFollowersSort === 'lowest') {
    filteredCreators.sort((a, b) => a.tiktok - b.tiktok);
  }
  if (instagramFollowersSort === 'highest') {
    filteredCreators.sort((a, b) => b.instagram - a.instagram);
  } else if (instagramFollowersSort === 'lowest') {
    filteredCreators.sort((a, b) => a.instagram - b.instagram);
  }

  // Pagination
  const pagedCreators = usePagination
    ? filteredCreators.slice(page * itemsPerPage, Math.min(page * itemsPerPage + itemsPerPage, filteredCreators.length))
    : filteredCreators;

  return (
    <Box sx={{ padding: 2 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={creatorsLoading || deleteLoading || softDeleteLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertDialog alertState={dialogState} />

      {/* Summary Cards */}
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Total Unverified Creators</Typography>
              <Typography variant="h4">{totalUnverifiedCreators}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Total Unconnected Creators</Typography>
              <Typography variant="h4">{totalUnconnectedCreators}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Total Verified Creators</Typography>
              <Typography variant="h4">{totalVerifiedCreators}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Filters & Settings */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap', marginBlockEnd: 2 }}>
        <TextField label="Search" variant="outlined" value={searchQuery} onChange={handleSearchChange} />
        <FormControl variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Status"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="Partner" sx={fixedMenuItemStyle}>
              Partner
            </MenuItem>
            <MenuItem value="Associate" sx={fixedMenuItemStyle}>
              Associate
            </MenuItem>
            <MenuItem value="Other" sx={fixedMenuItemStyle}>
              Other
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Verification Status</InputLabel>
          <Select
            value={verificationFilter}
            onChange={handleVerificationFilterChange}
            label="Verification Status"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="verified" sx={fixedMenuItemStyle}>
              Verified
            </MenuItem>
            <MenuItem value="unverified" sx={fixedMenuItemStyle}>
              Unverified
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Connected Email Status</InputLabel>
          <Select
            value={refreshTokenFilter}
            onChange={handleRefreshTokenFilterChange}
            label="Connected Email Status"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="connected" sx={fixedMenuItemStyle}>
              Connected
            </MenuItem>
            <MenuItem value="not_connected" sx={fixedMenuItemStyle}>
              Not Connected
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Avg Views</InputLabel>
          <Select
            value={avgViewsSort}
            onChange={handleAvgViewsSortChange}
            label="Avg Views"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="highest" sx={fixedMenuItemStyle}>
              Highest to Lowest
            </MenuItem>
            <MenuItem value="lowest" sx={fixedMenuItemStyle}>
              Lowest to Highest
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>TikTok Followers</InputLabel>
          <Select
            value={tiktokFollowersSort}
            onChange={handleTiktokFollowersSortChange}
            label="TikTok Followers"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="highest" sx={fixedMenuItemStyle}>
              Highest to Lowest
            </MenuItem>
            <MenuItem value="lowest" sx={fixedMenuItemStyle}>
              Lowest to Highest
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Instagram Followers</InputLabel>
          <Select
            value={instagramFollowersSort}
            onChange={handleInstagramFollowersSortChange}
            label="Instagram Followers"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="highest" sx={fixedMenuItemStyle}>
              Highest to Lowest
            </MenuItem>
            <MenuItem value="lowest" sx={fixedMenuItemStyle}>
              Lowest to Highest
            </MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          label="Show only creators without valid phone numbers"
          checked={phoneFilter}
          onChange={handlePhoneFilterChange}
          color="primary"
          control={<Checkbox />}
        />
        <FormControlLabel
          label="Find invalid location/gender/race data"
          checked={invalidFilter}
          onChange={handleInvalidFilterChange}
          color="primary"
          control={<Checkbox />}
        />
        <FormControlLabel
          label="Use pagination"
          checked={usePagination}
          onChange={() => setUsePagination((prev) => !prev)}
          color="primary"
          control={<Switch />}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteSelected}
          disabled={selectedCreators.length === 0}
        >
          Delete Selected
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setDialogOpen(true)}
          disabled={selectedCreators.length === 0}
        >
          Send Updates
        </Button>
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedCreators.length > 0 &&
                    selectedCreators.length < filteredCreators.length
                  }
                  checked={
                    filteredCreators.length > 0 &&
                    selectedCreators.length === filteredCreators.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedCreators(filteredCreators.map((c) => c.creator));
                    } else {
                      setSelectedCreators([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Creator Name
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                TikTok Following
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Instagram Following
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Geolocation
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Average Views
              </TableCell>
              <TableCell sx={StyleTableHeader}>Verified Status</TableCell>
              <TableCell sx={StyleTableHeader}>Connected Email Status</TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Supervisor
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Manager
              </TableCell>
              {/* Example agency column */}
              <TableCell sx={StyleTableHeader} align="center">
                Agency
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Status
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Email
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Phone Number
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedCreators.map((creator) => (
              <TableRow key={creator.creator}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCreators.includes(creator.creator)}
                    onChange={() => handleSelectCreator(creator.creator)}
                  />
                </TableCell>
                <TableCell align="right">{creator.creator || 'N/A'}</TableCell>
                <TableCell align="right">{creator.tiktok || 'N/A'}</TableCell>
                <TableCell align="right">{creator.instagram || 'N/A'}</TableCell>
                <TableCell align="right">
                  {`${creator.geolocation} / ${creator.gender} / ${creator.ethnicity}`}
                </TableCell>
                <TableCell align="right">{creator.avg_views || 'N/A'}</TableCell>
                <TableCell>{creator.user_status || 'N/A'}</TableCell>
                <TableCell>{creator.refresh_token_status ? 'Yes' : 'No'}</TableCell>
                <TableCell align="right">{creator.superviser || 'N/A'}</TableCell>
                <TableCell align="right">{creator.manager || 'N/A'}</TableCell>
                {/* Render the new agency field if it exists on the creator object */}
                <TableCell align="right">{creator.agency || 'N/A'}</TableCell>
                <TableCell align="right">{creator.status || 'N/A'}</TableCell>
                <TableCell align="right">{creator.email || 'N/A'}</TableCell>
                <TableCell align="right">{creator.phone_number || 'N/A'}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleDialogOpen(creator)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {usePagination && (
        <Box sx={{ paddingBlock: 2 }}>
          <Pagination
            sx={{ marginInline: 'auto', maxWidth: 'fit-content' }}
            count={Math.ceil(filteredCreators.length / itemsPerPage)}
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
          />
        </Box>
      )}

      {isCreatorDialogOpen && (
        <CreatorDialog
          open={isCreatorDialogOpen}
          onClose={handleDialogClose}
          creatorInfo={editingCreator}
        />
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xl"
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '80vh' } }}
      >
        <DialogTitle>Send Updates</DialogTitle>
        <DialogContent>
          <ToggleButtonGroup
            value={sendMethod}
            exclusive
            onChange={handleSendMethodChange}
            aria-label="send method"
          >
            <ToggleButton value="email" aria-label="email">
              Email
            </ToggleButton>
            <ToggleButton value="sms" aria-label="sms">
              SMS
            </ToggleButton>
          </ToggleButtonGroup>
          <FormGroup>
            <Typography variant="subtitle1" gutterBottom>
              Selected Users to Receive Updates:
            </Typography>
            <List dense>
              {selectedCreators.map((id) => {
                const creator = creators.find((c) => c.creator === id);
                return (
                  <ListItem key={creator.creator}>
                    {sendMethod === 'email' ? creator.email : creator.phone_number}
                  </ListItem>
                );
              })}
            </List>
            {sendMethod === 'email' ? (
              <>
                <TextField
                  label="Email Subject"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
                <TextField
                  label="Email Body"
                  variant="outlined"
                  multiline
                  rows={8}
                  fullWidth
                  margin="normal"
                  value={emailBody}
                  onChange={(e) => setEmailBody(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleIncludeCreatorConnectLink}
                  style={{ marginTop: 16 }}
                >
                  Include CreatorConnect Link
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleIncludeCreatorMediaKit}
                  style={{ marginTop: 16, marginLeft: 8 }}
                >
                  Include Creator Media Kit
                </Button>
              </>
            ) : (
              <TextField
                label="SMS Message"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                margin="normal"
                value={smsMessage}
                onChange={(e) => setSmsMessage(e.target.value)}
              />
            )}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSendUpdates} color="primary" variant="contained">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreatorDetailsView;
