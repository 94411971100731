import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'

export default function MinMaxInput({ minValue, maxValue, setMinValue, setMaxValue, name }) {
    const [internalMin, setInternalMin] = useState(null);
    const [internalMax, setInternalMax] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setInternalMin(minValue!==null ? minValue : null);
    }, [minValue]);

    useEffect(() => {
        setInternalMax(maxValue!==null ? maxValue : null);
    }, [maxValue]);

    const setNum = (event, setter) => {
        let currValue = setter === setInternalMin ? internalMin : internalMax;

        if (event.target.value === '' || event.target.value === null) {
            setter(null);
            currValue = null;
        }

        if (!Number.isNaN(event.target.valueAsNumber)) {
            setter(event.target.valueAsNumber);
            currValue = event.target.valueAsNumber;
        }

        const currMin = setter === setInternalMin ? currValue : internalMin;
        const currMax = setter === setInternalMax ? currValue : internalMax;

        if (currMin != currMax && (currMin === null || currMax === null)) {
            setError('Please enter both minimum and maximum values.');
            return;
        }
        if (currMin > currMax) {
            setError('The minimum can\'t be more than the maximum');
            return;
        }

        if (currMin == currMax && (currMin !==null)) {
            setError('The minimum and maximum cannot be the same');
            return;
        }

        setError(null);
        setMinValue(currMin);
        setMaxValue(currMax);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1, alignItems: 'baseline', marginBlockStart: 1, width: 'min-content' }}>
            <TextField value={internalMin !==null ? internalMin : ''} onChange={(event) => setNum(event, setInternalMin)} error={!!error} helperText={error} variant='outlined' label={`Min. ${name}`} sx={{ width: '10rem' }} size='small' type='number' InputProps={{ inputProps: { min: 0 } }}></TextField>
            And
            <TextField value={internalMax !==null ? internalMax : ''} onChange={(event) => setNum(event, setInternalMax)} error={!!error} variant='outlined' label={`Max. ${name}`} sx={{ width: '10rem' }} size='small' type='number' InputProps={{ inputProps: { min: 0 } }}></TextField>
        </Box>
    )
}
