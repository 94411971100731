import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MuiPhoneInput from '../MuiPhoneInput';

export default function ShippingAddressDialog({ open, onClose }) {
    const [formData, setFormData] = useState({
        recipient: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        instructions: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (open) {
            setFormData({
                recipient: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                phone: '',
                instructions: ''
            });
            setErrors({});
        }
    }, [open])

    // Handle form input changes
    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    const handlePhone = (value) => {
        setFormData(prevData => ({
            ...prevData,
            'phone': value
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.recipient) newErrors.recipient = "Recipient name is required.";
        if (!formData.address) newErrors.address = "Address is required.";
        if (!formData.city) newErrors.city = "City is required.";
        if (!formData.state) newErrors.state = "City is required.";
        if (!formData.zip) newErrors.zip = "ZIP code is required.";
        if (!formData.country) newErrors.country = "Country is required.";
        if (!formData.phone || formData.phone.length < 9) {
            newErrors.phone = "Phone number must be at least 10 digits.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = () => {
        if (validateForm()) {
            console.log('Address data to be attached:', formData);
            onClose(formData);
        }
    };

    // Handle cancel button
    const handleCancel = () => {
        onClose(null);
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="address-dialog-title"
        >
            <DialogTitle id="address-dialog-title">
                Add Delivery Address
                <IconButton aria-label="close" onClick={handleCancel} sx={{ position: 'absolute', right: 12, top: 12 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Alert severity='info' square icon={<LocalShippingIcon></LocalShippingIcon>}>
                This address will be shared as an attachment to your message
            </Alert>

            <DialogContent dividers>
                <Box component="form" noValidate>
                    <TextField
                        margin="dense"
                        id="recipient"
                        label="Recipient Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        placeholder="Full name"
                        value={formData.recipient}
                        onChange={handleChange}
                        error={!!errors.recipient}
                        helperText={errors.recipient}
                        autoComplete="name"
                    />

                    <TextField
                        margin="dense"
                        id="address"
                        label="Street Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        placeholder="Street address, building, apt #"
                        value={formData.address}
                        onChange={handleChange}
                        error={!!errors.address}
                        helperText={errors.address}
                        autoComplete="shipping street-address"
                    />

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="city"
                                label="City"
                                type="text"
                                fullWidth
                                variant="outlined"
                                placeholder="City"
                                value={formData.city}
                                onChange={handleChange}
                                error={!!errors.city}
                                helperText={errors.city}
                                autoComplete="shipping address-level2"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="state"
                                label="State/Province"
                                type="text"
                                fullWidth
                                variant="outlined"
                                placeholder="State"
                                value={formData.state}
                                onChange={handleChange}
                                error={!!errors.state}
                                helperText={errors.state}
                                autoComplete="shipping address-level1"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="zip"
                                label="ZIP/Postal Code"
                                type="text"
                                fullWidth
                                variant="outlined"
                                placeholder="ZIP/Postal code"
                                value={formData.zip}
                                onChange={handleChange}
                                error={!!errors.zip}
                                helperText={errors.zip}
                                autoComplete="shipping postal-code"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="country"
                                label="Country"
                                type="text"
                                fullWidth
                                variant="outlined"
                                placeholder="Country"
                                value={formData.country}
                                onChange={handleChange}
                                error={!!errors.country}
                                helperText={errors.country}
                                autoComplete="country-name"
                            />
                        </Grid>
                    </Grid>

                    <MuiPhoneInput
                        country={'us'}
                        preferredCountries={['us']}
                        value={formData.phone}
                        onChange={handlePhone}
                        isValid={(value) => value.length >= 10}
                        error={errors.phone}
                        required
                    />

                    <TextField
                        margin="dense"
                        id="instructions"
                        label="Delivery Instructions (Optional)"
                        type="text"
                        fullWidth
                        variant="outlined"
                        placeholder="Gate code, landmarks, etc."
                        value={formData.instructions}
                        onChange={handleChange}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Attach
                </Button>
            </DialogActions>
        </Dialog>
    )
}
