import { styled } from "@mui/system";
import { Tabs } from "@mui/material";

const StyledTabs = styled(Tabs)({
   "& .MuiTabs-indicator": {
      backgroundColor: "#3498db",
   },
});

export default StyledTabs;
