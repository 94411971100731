import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom"; // added useSearchParams
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Container,
  AppBar,
  Backdrop,
  Toolbar,
  styled,
} from "@mui/material";
import client from "../../../../API";
import CreatorCampaignConversation from "./conversation";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import CreatorTimeline from "./creatorTimeline";
import { useMutation } from "react-query";
import useAlertDialog from "../../../../Components/useAlertDialog";
import AlertDialog from "../../../../Components/AlertDialog";
import {
  formatLinksInMessage,
  TimelineStatus,
} from "../../../../Utils/constants";
import PageTitle from "../../../../Components/PageTitle";
import TabPanel from "../../../../Components/TabPanel";

// Utility function to format the due date
function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  paddingInline: theme.spacing(1),
  paddingBlock: theme.spacing(2),
  marginBottom: theme.spacing(4),
  overflowX:'hidden',
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)"
  },
  [theme.breakpoints.up("md")]: {
    paddingInline: theme.spacing(2), // Larger padding on medium and up
  },

}));

const CreatorSpecificCampaign = () => {
  const { campaignId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams(); // added
  // Set initial tab from URL parameter "tab" (default to 0 if not present)
  const initialTab = parseInt(searchParams.get("tab") || "0", 10);
  const [tabIndex, setTabIndex] = useState(initialTab);
  const [campaign, setCampaign] = useState(null);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const editedEvents = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const { creatorToken } = useCreatorAuth();
  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();
  const [liveLink, setLiveLink] = useState([]);

  // Handle tab switching and update URL parameter
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
    setSearchParams({ tab: newValue });
  };

  // Fetch campaign data on mount
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await client.campaigns.fetch(campaignId);
        let eventData = { events: [] };
        try {
          eventData = await client.campaigns.timeline_by_creator(
            campaignId,
            creatorToken.creator_user.username
          );
        } catch (err) {
          if (err.response && err.response.status === 404) {
            console.warn("No timeline found for this campaign.");
          } else {
            throw err;
          }
        }
        editedEvents.current = false;
        setCampaign(data);
        setTimelineEvents(
          eventData.events.map((event) => ({
            ...event,
            projected_date: new Date(event.projected_date),
            ogStatus: event.status,
          }))
        );
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching campaign data:", err);
      }
    };
    fetchCampaignData();
  }, [campaignId, creatorToken.creator_user.username]);

  // Timeline editing logic
  const OnEditTimelineField = (targetIndex, target, value) => {
    editedEvents.current = true;
    setTimelineEvents((prev) =>
      prev.map((event, i) =>
        i === targetIndex ? { ...event, [target]: value } : event
      )
    );
  };

  const { mutate: updateTimeline, isLoading: isUpdatingTimeline } = useMutation(
    ({ input }) =>
      client.creatorConnect.campaignUpdateTimeline(
        campaignId,
        creatorToken.creator_user.username,
        input
      ),
    {
      onSuccess: () =>
        openAlert("Success", "Timeline Updated", closeDialog, null, "Ok", null),
      onError: (error) =>
        openAlert(
          "Error",
          `Error when updating timeline: ${
            error.response?.data?.error || error.message
          }`,
          closeDialog,
          null,
          "Ok",
          null
        ),
    }
  );

  const onSaveChanges = () => {
    if (editedEvents.current) {
      const foundSetComplete = timelineEvents.some(
        (event) =>
          event.ogStatus !== event.status && event.status === TimelineStatus[2]
      );
      if (foundSetComplete) {
        openAlert(
          "Completed Objective",
          "You’re setting an objective as complete. This will notify the brand and lock further changes for that item. Continue?",
          () => {
            closeDialog();
            updateTimeline({ input: timelineEvents });
          },
          closeDialog,
          "Continue",
          "Cancel"
        );
      } else {
        updateTimeline({ input: timelineEvents });
      }
    }
  };

  // Compute creator data
  const creator = useMemo(() => {
    if (!campaign) return undefined;
    return campaign.creators.find(
      (c) => c.id === creatorToken.creator_user.username
    );
  }, [campaign, creatorToken.creator_user.username]);

  // Update live links when creator changes
  useEffect(() => {
    if (creator) {
      setLiveLink(
        creator.videos
          .filter((video) => video.parentId == null)
          .map((video) => ({
            id: video.id,
            promotionType: video.promotionType,
            promotionPlatform: video.promotionPlatform,
            liveLink: video.liveLink,
            boostCode: video.boostCode,
            crossposts: creator.videos
              .filter((cross) => cross.parentId === video.id)
              .map((cross) => ({
                id: cross.id,
                promotionType: cross.promotionType,
                promotionPlatform: cross.promotionPlatform,
                liveLink: cross.liveLink,
                boostCode: cross.boostCode,
              })),
          }))
      );
    }
  }, [creator]);

  const canEdit =
    campaign?.campaign_status === "Launched" &&
    creator &&
    !["Declined", "Dropped", "Pitched"].includes(creator.status);

  // Field update mutation
  const { mutate: updateFields, isLoading: isUpdatingFields } = useMutation(
    (input) => client.creatorConnect.updateCreatorFields(campaignId, input),
    {
      onSuccess: () =>
        openAlert("Success", "Fields updated.", closeDialog, null, "Ok", null),
      onError: (error) =>
        openAlert(
          "Error",
          `Error when updating fields: ${
            error.response?.data?.error || error.message
          }`,
          closeDialog,
          null,
          "Ok",
          null
        ),
    }
  );

  // Handle live link and boost code changes
  const onLinkChange = (videoId, newLiveLink, newBoostCode) => {
    setLiveLink((prev) =>
      prev.map((vid) =>
        vid.id === videoId
          ? { ...vid, liveLink: newLiveLink, boostCode: newBoostCode }
          : vid
      )
    );
  };

  const onCrossLinkChange = (videoId, crossId, newLiveLink, newBoostCode) => {
    setLiveLink((prev) =>
      prev.map((vid) =>
        vid.id === videoId
          ? {
              ...vid,
              crossposts: vid.crossposts.map((c) =>
                c.id === crossId
                  ? { ...c, liveLink: newLiveLink, boostCode: newBoostCode }
                  : c
              ),
            }
          : vid
      )
    );
  };

  const handleSaveLiveLinkAndBoostCode = () => {
    updateFields({
      liveLinks: liveLink.flatMap((video) => [
        {
          id: video.id,
          liveLink: video.liveLink || null,
          boostCode: video.boostCode || null,
        },
        ...video.crossposts.map((cross) => ({
          id: cross.id,
          liveLink: cross.liveLink || null,
          boostCode: cross.boostCode || null,
        })),
      ]),
    });
  };

  const handleAssetsSent = () => updateFields({ assetsSent: true });

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <Container maxWidth="lg" sx={{minHeight: "100vh", paddingInline:{xs:0, md:2} }}>
        
        <PageTitle title={`Campaign - ${campaign?.name} - Blitz`} />
        <AppBar
          position="static"
          color="default"
          sx={{
            mb: 3,
            borderBottom: 1,
            borderColor: "divider",
            boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
            zIndex: (theme)=>theme.zIndex.appBar-1,
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{ backgroundColor: "background.paper" }}
          >
            <Tab
              label="Overview"
            />
            <Tab
              label="Chat with Brand"
            />
          </Tabs>
        </AppBar>

        {/* Overview Tab */}
        <TabPanel value={tabIndex} index={0}>
          <StyledPaper elevation={2}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontWeight: "bold", color: "text.primary" }}
            >
              {campaign?.name}
            </Typography>
            {creator && (
              <Box
                sx={{
                  mt: 2,
                  p: {xs:1, md:2},
                  backgroundColor: "primary.light",
                  borderRadius: 1,
                  display: "inline-block",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "primary.contrastText" }}
                >
                  Offer:{" "}
                  {creator.agencyRate
                    ? `$${creator.agencyRate}`
                    : creator.price
                    ? `$${creator.price}`
                    : "N/A"}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 2,
                mb: 3,
              }}
            >
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                <strong>Status:</strong> {campaign?.campaign_status}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{ whiteSpace: "pre-wrap", mb: 3, color: "text.secondary" }}
            >
              <strong>Brief:</strong>
              <br />
              {formatLinksInMessage(campaign?.brief || "")}
            </Typography>
          </StyledPaper>

          <StyledPaper elevation={2}>
            <CreatorTimeline
              timelineEvents={timelineEvents}
              onEditField={OnEditTimelineField}
              onSaveChanges={onSaveChanges}
              isSavingChanges={isUpdatingTimeline}
              canEdit={canEdit}
              isUpdatingFields={isUpdatingFields}
              handleSaveLiveLinkAndBoostCode={handleSaveLiveLinkAndBoostCode}
              handleAssetsSent={handleAssetsSent}
              creator={creator}
              liveLink={liveLink}
              onLinkChange={onLinkChange}
              onCrossLinkChange={onCrossLinkChange}
            />
            {canEdit && (
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveChanges}
                disabled={isUpdatingTimeline || !editedEvents.current}
                sx={{ mt: 2, borderRadius: 2, px: 4 }}
              >
                {isUpdatingTimeline ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save Changes"
                )}
              </Button>
            )}
          </StyledPaper>
        </TabPanel>

        {/* Chat Tab */}
        <TabPanel value={tabIndex} index={1}>
          <StyledPaper sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Chat with Brand
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <CreatorCampaignConversation campaignId={campaign.id} />
            </Box>
          </StyledPaper>
        </TabPanel>
      </Container>
      <AlertDialog alertState={dialogState} />
    </>
  );
};

export default CreatorSpecificCampaign;
