import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  ThemeProvider,
  CssBaseline,
  Container,
  Toolbar,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Link as MuiLink
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { GoogleLogin } from "@react-oauth/google";
import client from "../../API";
import useAuth from "../../Hooks/use-auth";
import { validateEmail, validatePassword } from "../../Utils";
import routes from "../../Config/routes";
import { lightTheme } from "../../Utils/baseTheme";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";
import useAlertDialog from "../../Components/useAlertDialog";
import AlertDialog from "../../Components/AlertDialog";
import { useCreatorAuth } from "../../Hooks/creator-use-auth";

const UnifiedLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [oauthAsk, setOauthAsk] = useState(false)

  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const { authorize, setCurrentUser } = useAuth();
  const { login: creatorLogin } = useCreatorAuth(); // For creator authentication

  // Check if the 'redirect' query parameter is set to 'edit'
  const redirect = searchParams.get('redirect');
  const service_id = searchParams.get('service_id');
  const state = searchParams.get('state');

  useEffect(() => {
    if (service_id) {
      setOauthAsk(true);
    }
  }, [searchParams])

  const GoToCreatorDesiredPage = (loginRedirect) => {
    // Handle path-based redirects first
    if (redirect?.startsWith('/')) {
      navigate(redirect);
      return;
    }

    //Handle login response redirects
    switch (loginRedirect) {
      case 'edit':
        if (redirect != 'oauth') {
          navigate(routes.creatorConnectEdit);
          return;
        }
        break;
    }

    // Handle query redirects
    switch (redirect) {
      case 'edit':
        const newQueryParams = new URLSearchParams();
        newQueryParams.set('high', 'platforms');
        navigate(`${routes.creatorConnectEdit}?${newQueryParams.toString()}`);
        break;
      case 'oauth':
        const redirectQuery = new URLSearchParams();
        if (!service_id) {
          setSnackbarMessage("An error occurred while processing this redirect.");
          setSnackbarSeverity("warning");
          setOpenSnackbar(true);
          return;
        }
        redirectQuery.set('service_id', service_id);
        if (state) redirectQuery.set('state', searchParams.get('state'));
        navigate(`${routes.creatorConnectOauth}?${redirectQuery.toString()}`);
        break;
      default:
        navigate(routes.creatorConnectStart);
    }
  };


  // Unified login mutation
  const { mutate: unifiedLogin, isLoading } = useMutation(client.users.unilogin, {
    onSuccess: (data) => {
      if (data.user.global_user_type === "creator") {
        creatorLogin({
          token: data.token,
          creator_user: data.user
        }); // Use the creator login method for creators
        openDialog("Success", "You have logged in successfully",
          () => GoToCreatorDesiredPage(data.redirect),
          () => GoToCreatorDesiredPage(loginRedirect), "Ok", null);
      }
      else {
        authorize(data.token);
        setCurrentUser(data.user);
        openDialog("Success", "You have logged in successfully",
          () => navigate(routes.dashboard),
          () => navigate(routes.dashboard), "Ok", null);

      }
    },
    onError: (error) => {
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network busy. Try again in a few seconds.";
      } else if (error.response?.data) {
        errorMessage = error.response.data.error || errorMessage;
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    },
  });

  // Close the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  // Email/password login
  const handleEmailLogin = (e) => {
    e.preventDefault();
    if (!email) {
      setSnackbarMessage("Email field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }
    if (!validateEmail(email)) {
      setSnackbarMessage("Invalid email address!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }
    if (!password) {
      setSnackbarMessage("Password field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }
    if (!validatePassword(password)) {
      setSnackbarMessage("Password should have 6 or more characters!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    unifiedLogin({
      email,
      password
    });
  };

  // Google login handler
  const handleGoogleLoginSuccess = (response) => {
    setIsGoogleLoading(true);
    try {
      const token = response.credential;
      // IMPORTANT: Use "token" field so backend sees data.get("token")
      unifiedLogin({
        token
      });
    } catch (error) {
      setSnackbarMessage(`Login failed: ${error?.response?.data?.error || error.message}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
    setSnackbarMessage("Google login failed, please try again.");
    setSnackbarSeverity("error");
    setOpenSnackbar(true);
  };

  const onboardLink = routes.creatorConnectOnboardApp + '?service_id=' + service_id + (!!state ? '&state=' + state : '')

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Dialog id='alert-creator-oauth' open={oauthAsk}>
        <DialogTitle>Connecting to an App with Blitz</DialogTitle>
        <DialogContent>
          <DialogContentText>To use this App, you'll need a CreatorConnect Account</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" component={Link} to={onboardLink}>I'm new to CreatorConnect</Button>
          <Button variant="text" onClick={() => setOauthAsk(false)}>I have a CreatorConnect account</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", width: "100%" }}>
        <HomeHeader />
        <Toolbar />
        {/* Taller main section here */}
        <Container maxWidth="sm" sx={{ my: 6 }}>
          <Paper elevation={3} sx={{ p: 4, minHeight: 600 }}>
            <Typography variant="h4" align="center" sx={{ fontWeight: "bold", mb: 2 }}>
              Welcome Back
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary" sx={{ mb: 3 }}>
              Sign in to continue with Blitz
            </Typography>

            {/* Email/Password Login Form */}
            <form onSubmit={handleEmailLogin}>
              <TextField
                disabled={isLoading}
                autoComplete="email"
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                required
                sx={{ mb: 2 }}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                disabled={isLoading}
                required
                autoComplete="current-password"
                id="password"
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                sx={{ mb: 2 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                type="submit"
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
                sx={{ mb: 2 }}
              >
                Login with Email
              </Button>
            </form>
 {/* Forgot Password Link */}
 <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <MuiLink
                  component={Link}
                  to={routes.passwordForgot}
                  variant="body2"
                  color="secondary"
                >
                  Forgot your password?
                </MuiLink>
              </Box>
            {/* Google Auth Login */}
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Typography variant="body1" color="textSecondary" sx={{ mb: 1 }}>
                OR
              </Typography>
              <Box sx={{ textAlign: "center", mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onError={handleGoogleLoginFailure}
                  disabled={isGoogleLoading || isLoading}
                />
              </Box>
            </Box>
          </Paper>

          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Typography variant="body2">Don't have an account?</Typography>
            <Button color="primary" onClick={() => navigate(routes.requestAccess)} sx={{ mt: 1 }}>
              Contact Us
            </Button>
          </Box>

          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default UnifiedLogin;
