import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TableBody,
  TableContainer,
  Snackbar,
  Button,
  Tabs,
  Tab,
  Paper,
  Link,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Badge,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Menu,  // <-- Added missing import
  MenuItem,
  TextField,  // <-- Added missing import
} from "@mui/material";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { StyledTableRow } from "../../Utils/styledcell";
import client from "../../API"; // your HttpClient queries
import { useIsMounted } from "../../Hooks/use-is-mounted";
import InvoiceDialog from "../Dashboard/invoicing/invoicedialog";
import InvoiceEdit from "../Dashboard/invoicing/editInvoice";
import VendorDialog from "./vendorintake";
import InvoiceActionDialog from "./invoiceaction";
import ClientInvoiceActionDialog from "./clientinvoiceaction";
import ConfirmInvoiceDialog from "./approve-invoice"; // (See #2 below)
import blitzLogo from "../../Components/globalAssets/platty.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// ... other imports
import useAuth from "../../Hooks/use-auth"; // import your auth hook

const ActionCardGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

const StyledCard = styled(Card)`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const BlitzPay = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  // --------------- State ---------------
  const [statusFilter, setStatusFilter] = useState("");
  const [creatorFilter, setCreatorFilter] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 365))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 365))
      .toISOString()
      .split("T")[0]
  );
  const [invoices, setInvoices] = useState([]);
  const [incomingInvoices, setIncomingInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [campaignNames, setCampaignNames] = useState({});
  const [creatorNames, setCreatorNames] = useState({});
  const [allCreatorIds, setAllCreatorIds] = useState([]);
  const [accountBalance, setAccountBalance] = useState("Loading...");
  const [creditline, setCreditline] = useState("Loading...");
  const [open, setOpen] = useState(false); // create invoice dialog
  const [editOpen, setEditOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [plaidLinkToken, setPlaidLinkToken] = useState("");

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Tab index
  const [tabIndex, setTabIndex] = useState(0);

  // Payout states
  const [payouts, setPayouts] = useState([]);
  const [isPayoutsLoading, setIsPayoutsLoading] = useState(true);

  // Vendor Dialog
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);

  // Approve/Decline (Creator)
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  // Approve/Decline (Client)
  const [clientActionDialogOpen, setClientActionDialogOpen] = useState(false);
  const [selectedClientInvoiceId, setSelectedClientInvoiceId] = useState(null);

  // Confirm Invoice (New)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmInvoiceId, setConfirmInvoiceId] = useState(null);

  // NEW: Additional state to handle “Multiple Pending Invoices” popup
  const [showPendingWarning, setShowPendingWarning] = useState(false);
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();

  // --------------- Handlers ---------------
  const handleOpenVendorDialog = () => setVendorDialogOpen(true);
  const handleCloseVendorDialog = () => setVendorDialogOpen(false);

  const handleCloseInvoiceDialog = () => {
    setOpen(false);
    fetchInvoices();
  };

  const handleEditDialogOpen = (invoice) => {
    setCurrentInvoice(invoice);
    setEditOpen(true);
  };
  const handleEditDialogClose = (refresh = false) => {
    setEditOpen(false);
    setCurrentInvoice(null);
    if (refresh) {
      fetchInvoices();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  // Approve/Decline (Creator)
  const handleOpenActionDialog = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    setActionDialogOpen(true);
  };
  const handleCloseActionDialog = () => {
    setActionDialogOpen(false);
    setSelectedInvoiceId(null);
  };

  // Approve/Decline (Client)
  const handleOpenClientActionDialog = (invoiceId) => {
    setSelectedClientInvoiceId(invoiceId);
    setClientActionDialogOpen(true);
  };
  const handleCloseClientActionDialog = () => {
    setClientActionDialogOpen(false);
    setSelectedClientInvoiceId(null);
  };

  // Confirm Invoice (New)
  const handleOpenConfirmDialog = (invoiceId) => {
    setConfirmInvoiceId(invoiceId);
    setConfirmDialogOpen(true);
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setConfirmInvoiceId(null);
  };

  // Common success handler
  const handleActionSuccess = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    fetchIncomingInvoices(); // refresh
    fetchInvoices(); // also refresh standard invoices
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    // Construct the vendor signup URL using the current user's company name
    const companyName = currentUser?.company_name || "";
    const vendorSignupUrl = `/vendor_signup/${encodeURIComponent(companyName)}`;
    const fullUrl = window.location.origin + vendorSignupUrl;

    navigator.clipboard.writeText(fullUrl)
      .then(() => {
        setSnackbarMessage("Vendor Signup Link copied to clipboard!");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
    handleMenuClose();
  };
  // --------------- Helpers ---------------
  const formatAmount = (amount) => {
    const num = parseFloat(amount || 0.0);
    return `$${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const getPaymentName = (invoice) => {
    if (invoice.campaign_id) {
      return campaignNames[invoice.campaign_id] || "Unknown Campaign";
    } else if (invoice.campaign_name) {
      return invoice.campaign_name;
    }
    return "Unknown Payment";
  };

  // --------------- Queries & Mutations ---------------
  const { mutate: fetchCompanyData } = useMutation(client.companies.listFetch, {
    onSuccess: (data) => {
      if (data && data.balance !== undefined && data.credit_line !== undefined) {
        setAccountBalance(`$${parseFloat(data.balance).toFixed(2)}`);
        setCreditline(
          data.credit_line
            ? `$${parseFloat(data.credit_line).toFixed(2)}`
            : "No Credit Line Established"
        );
      } else {
        setAccountBalance("Data format error");
        setCreditline("Data format error");
      }
    },
    onError: (error) => {
      console.error("Error fetching company data:", error);
      setAccountBalance("Error loading balance");
      setCreditline("Error loading credit line");
    },
  });

  const { mutate: fetchInvoices } = useMutation(client.invoices.list, {
    onSuccess: (data) => {
      setInvoices(data.invoices || []);
      const creatorIdsFromInvoices = (data.invoices || [])
        .filter((inv) => inv.creator_id)
        .map((inv) => inv.creator_id);
      setAllCreatorIds((prev) =>
        Array.from(new Set([...prev, ...creatorIdsFromInvoices]))
      );
    },
    onError: (error) => {
      console.error("Error fetching invoices:", error);
    },
  });

  const { mutate: fetchCampaigns } = useMutation(client.campaigns.list, {
    onSuccess: (data) => {
      const map = {};
      data.forEach((c) => {
        if (c) map[c.id] = c.name;
      });
      setCampaignNames(map);
    },
    onError: (err) => console.error("Error fetching campaigns:", err),
  });

  const { mutate: fetchCreators } = useMutation(client.creators.list, {
    onSuccess: (data) => {
      const map = {};
      data.forEach((creator) => {
        if (creator) map[creator.id] = creator.name;
      });
      setCreatorNames(map);
    },
    onError: (err) => console.error("Error fetching creators:", err),
  });

  const { mutate: fetchAllCreatorIds } = useMutation(client.payouts.list, {
    onSuccess: (data) => {
      const uniqueIds = Array.from(
        new Set(data.payouts.map((p) => p.creator_id))
      );
      setAllCreatorIds((prev) => Array.from(new Set([...prev, ...uniqueIds])));
    },
    onError: (err) => console.error("Error fetching creator IDs:", err),
  });

  const { mutate: fetchIncomingInvoices } = useMutation(
    client.invoices.creatorList,
    {
      onSuccess: (data) => {
        setIncomingInvoices(data.incoming_invoices || []);
      },
      onError: (error) => {
        console.error("Error fetching incoming invoices:", error);
      },
    }
  );

  const { mutate: fetchPayouts } = useMutation(client.payouts.list, {
    onSuccess: (data) => {
      setPayouts(data.payouts || []);
    },
    onError: (error) => {
      console.error("Error fetching payouts:", error);
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network is disconnected!";
      } else if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
    onSettled: () => {
      setIsPayoutsLoading(false);
    },
  });

  // For Approve/Decline (Creator) invoice
  const { mutate: invoiceAction } = useMutation(client.invoices.action, {
    onSuccess: (data) => {
      setSnackbarMessage(data.message || "Action completed successfully.");
      setSnackbarOpen(true);
      fetchIncomingInvoices();
    },
    onError: (error) => {
      const errMsg =
        error.response?.data?.error || "Error performing invoice action.";
      setSnackbarMessage(errMsg);
      setSnackbarOpen(true);
    },
  });

  // For Approve/Decline (Client) invoice
  const { mutate: clientInvoiceAction } = useMutation(
    ({ invoiceId, action }) => client.invoices.clientAction({ invoiceId, action }),
    {
      onSuccess: (data) => {
        setSnackbarMessage(data.message || "Client invoice action completed.");
        setSnackbarOpen(true);
        fetchIncomingInvoices();
      },
      onError: (error) => {
        const errMsg =
          error.response?.data?.error ||
          "Error performing client invoice action.";
        setSnackbarMessage(errMsg);
        setSnackbarOpen(true);
      },
    }
  );

  // Confirm Invoice
  const { mutate: confirmInvoice } = useMutation(client.invoices.confirmInvoice, {
    onSuccess: (data) => {
      handleActionSuccess(data.message || "Invoice confirmed successfully.");
    },
    onError: (error) => {
      const errMsg =
        error.response?.data?.error || "Error confirming the invoice.";
      setSnackbarMessage(errMsg);
      setSnackbarOpen(true);
    },
  });

  // --------------- Effects ---------------
  useEffect(() => {
    if (!isMounted) return;
    fetchCompanyData();
    fetchInvoices();
    fetchCampaigns();
    fetchCreators();
    fetchAllCreatorIds();
    fetchIncomingInvoices();
    fetchPayouts();
  }, [isMounted]);

  useEffect(() => {
    setFilteredInvoices(
      invoices.filter(
        (invoice) =>
          (statusFilter === "" || invoice.status === statusFilter) &&
          (creatorFilter === "" || invoice.creator_id === creatorFilter) &&
          new Date(invoice.created_at) >= new Date(startDate) &&
          new Date(invoice.created_at) <= new Date(endDate)
      )
    );
  }, [invoices, statusFilter, creatorFilter, startDate, endDate]);

  // Example Plaid Link usage
  useEffect(() => {
    const fetchLinkTokenUser = async () => {
      try {
        const resp = await client.bank.getLinkTokenUser();
        setPlaidLinkToken(resp.link_token);
      } catch (err) {
        console.error("Error fetching user link token:", err);
      }
    };
    fetchLinkTokenUser();
  }, []);

  // NEW: Watch for 2 or more pending invoices => show popup
  useEffect(() => {
    // Combine normal and incoming if you want to block any "pending" invoice
    // OR just check the main "invoices" array. Adjust as needed.
    const totalPending = invoices.filter((inv) => inv.status === "pending").length;

    // If you also want to include incomingInvoices in that count, then:
    // const totalPending =
    //   invoices.filter((inv) => inv.status === "pending").length +
    //   incomingInvoices.filter((inv) => inv.invoice_status === "pending").length;

    if (totalPending >= 2) {
      setShowPendingWarning(true);
    } else {
      setShowPendingWarning(false);
    }
  }, [invoices, incomingInvoices]);

  // --------------- Plaid Bank Link (example) ---------------
  const handleLinkBankAccount = async () => {
    const userId = 123; // example ID

    if (!plaidLinkToken) {
      alert("No link token found. Please try again later.");
      return;
    }
    if (!window.Plaid) {
      alert(
        "Plaid script not loaded. Ensure <script src='https://cdn.plaid.com/link/v2/stable/link-initialize.js'/> is present."
      );
      return;
    }

    const plaidHandler = window.Plaid.create({
      token: plaidLinkToken,
      onSuccess: async (public_token) => {
        try {
          await client.bank.userLink({
            public_token,
            entity_type: "user",
            entity_id: userId,
          });
          setSnackbarMessage("Bank account linked successfully!");
          setSnackbarOpen(true);
        } catch (err) {
          console.error("Error linking bank account:", err);
          alert(`Error linking bank account: ${err.message}`);
        }
      },
      onExit: (err) => {
        if (err) {
          console.error("Plaid Link exited with error:", err);
        } else {
          console.log("Plaid Link exited by user");
        }
      },
    });

    plaidHandler.open();
  };

  // --------------- Summaries ---------------
  const totalInvoices = invoices.length;
  const invoicesByStatus = invoices.reduce((acc, invoice) => {
    acc[invoice.status] = (acc[invoice.status] || 0) + 1;
    return acc;
  }, {});

  const creatorsPaid = payouts.length;
  const amountInEscrow = payouts.reduce((acc, curr) => {
    if (["pending", "queued"].includes((curr.status || "").toLowerCase())) {
      return acc + curr.amount;
    } else {
      return acc;
    }
  }, 0);
  const paymentsThisMonth = payouts.reduce((acc, curr) => {
    const payoutDate = new Date(curr.payout_date);
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    if (
      payoutDate.getMonth() === currentMonth &&
      payoutDate.getFullYear() === currentYear
    ) {
      return acc + curr.amount;
    }
    return acc;
  }, 0);
  const averagePaymentAmount =
    payouts.length > 0
      ? payouts.reduce((acc, curr) => acc + curr.amount, 0) / payouts.length
      : 0;

  // --------------- PDF Download ---------------
  const downloadPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();

    // Header background
    pdf.setFillColor(0, 0, 0);
    pdf.rect(0, 0, pdfWidth, 30, "F");

    // Logo
    pdf.addImage(blitzLogo, "PNG", 10, 5, 50, 20);

    // Title
    pdf.setFontSize(18);
    pdf.setTextColor(255, 255, 255);
    pdf.setFont("helvetica", "bold");
    pdf.text("Financial Statement", pdfWidth / 2, 20, { align: "center" });

    // Divider
    pdf.setDrawColor(255, 255, 255);
    pdf.line(10, 30, pdfWidth - 10, 30);

    // Timestamp
    const timestamp = new Date().toLocaleString();
    pdf.setFontSize(12);
    pdf.setTextColor(255, 255, 255);
    pdf.text(`Report generated at: ${timestamp}`, 10, 35);

    pdf.setTextColor(0, 0, 0);

    pdf.text(`Account Balance: ${accountBalance}`, 10, 50);
    pdf.text(`Line of Credit: ${creditline}`, 10, 60);

    pdf.autoTable({
      startY: 70,
      head: [["Payment Name", "PO Number", "Amount Due", "Status", "Created At", "Notes"]],
      body: filteredInvoices.map((invoice) => [
        getPaymentName(invoice),
        invoice.po_number || "N/A",
        formatAmount(invoice.amount_due),
        invoice.status,
        new Date(invoice.created_at).toLocaleDateString(),
        invoice.notes || "",
      ]),
      theme: "striped",
      headStyles: { fillColor: [66, 66, 66], textColor: [255, 255, 255] },
      bodyStyles: { fillColor: [230, 230, 230] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
    });

    pdf.save("financial_statement.pdf");
  };

  // --------------- Combine Activity (All Activity tab) ---------------
  // Filter out CANCELLED invoices from the All Activity list
  const invoiceActivityForAllTab = filteredInvoices
    .filter((inv) => inv.status !== "cancelled") // <-- hides cancelled
    .map((inv) => ({
      type: "Invoice",
      name: getPaymentName(inv),
      po_number: inv.po_number || "N/A",
      amount: inv.amount_due,
      status: inv.status,
      date: inv.created_at,
      notes: inv.notes,
      link: `https://www.useblitz.co/invoicing/${inv.id}`,
      // we’ll let them edit if invoice != "Paid"
      editable: inv.status !== "Paid",
      // new: if invoice is pending, we can show an “Approve Invoice” button
      pending: inv.status === "pending",
      invoiceId: inv.id,
    }));

  const payoutActivityForAllTab = payouts
    .filter((p) => {
      const dateCheck =
        new Date(p.payout_date) >= new Date(startDate) &&
        new Date(p.payout_date) <= new Date(endDate);
      const statusCheck = !statusFilter || p.status === statusFilter;
      return dateCheck && statusCheck;
    })
    .map((p) => ({
      type: "Payout",
      name: p.campaignname || "Creator Payout",
      po_number: "N/A",
      amount: p.amount,
      status: p.status,
      date: p.payout_date,
      notes: p.notes,
      link: "#",
      editable: false,
      pending: false,
      invoiceId: null,
    }));

  const combinedActivity = [
    ...invoiceActivityForAllTab,
    ...payoutActivityForAllTab,
  ].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Show # of incoming invoices
  const totalIncomingAmount = incomingInvoices.length;

  // --------------- Render ---------------
  return (
    <>
      <Paper
        elevation={2}
        square={true}
        sx={{ width: "100%", paddingBlockStart: 2, marginBlockEnd: 2 }}
      >
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          sx={{ textAlign: "center", position: "sticky", left: 0 }}
        >
          Your BlitzPay Activity
        </Typography>

        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            paddingInline: { xs: 2, md: 4 },
            marginBlockEnd: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              paddingBlock: 1,
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/client_invoice")}
            >
              Create Invoice
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/pay")}
            >
              Pay a Creator
            </Button>

             {/* Replace simple "Add a Vendor" button with a split button */}
             <ButtonGroup variant="contained" color="primary">
              <Button onClick={handleOpenVendorDialog}>Add a Vendor</Button>
              <Button
                size="small"
                aria-controls={menuOpen ? "split-button-menu" : undefined}
                aria-expanded={menuOpen ? "true" : undefined}
                aria-label="select action"
                aria-haspopup="menu"
                onClick={handleMenuOpen}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Menu
              id="split-button-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleCopyLink}>Copy Link</MenuItem>
            </Menu>

            <InvoiceDialog
              open={open}
              onClose={handleCloseInvoiceDialog}
              invoiceInfo={undefined}
            />

            <TextField
              size="small"
              label="Start Date"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: 240 }}
            />
            <TextField
              size="small"
              label="End Date"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: 240 }}
            />

            <Button
              variant="contained"
              color="secondary"
              onClick={downloadPDF}
              sx={{ minWidth: 240 }}
            >
              Download Financial Statement
            </Button>
            {/* 
            If you want to re-enable linking bank accounts:
            <Button
              variant="contained"
              color="primary"
              onClick={handleLinkBankAccount}
              sx={{ minWidth: 240 }}
            >
              Link Bank Account
            </Button>
            */}
          </Box>
        </Box>

        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="All Activity" />
          <Tab label="Invoices" />
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                Incoming Invoices
                <Badge
                  badgeContent={totalIncomingAmount}
                  color="secondary"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <NotificationsIcon />
                </Badge>
              </Box>
            }
          />
          <Tab label="Payouts" />
        </Tabs>
      </Paper>

      {/* SUMMARY CARDS */}
      <ActionCardGrid sx={{ marginInline: 2 }}>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Invoice Summary</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Account Balance: {accountBalance}</Typography>
            <Typography variant="body1">Line of Credit: {creditline}</Typography>
            <Typography variant="body1">Total Invoices: {totalInvoices}</Typography>
            {Object.entries(invoicesByStatus).map(([status, count]) => (
              <Typography key={status}>
                {status}: {count}
              </Typography>
            ))}
          </CardContent>
        </StyledCard>

        <StyledCard>
          <CardContent>
            <Typography variant="h6">Payout Summary</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Creators Paid: {creatorsPaid}</Typography>
            <Typography variant="body1">
              Amount in Escrow: ${amountInEscrow}
            </Typography>
            <Typography variant="body1">
              Payments This Month: ${paymentsThisMonth.toFixed(2)}
            </Typography>
            <Typography variant="body1">
              Avg Payment: ${averagePaymentAmount.toFixed(2)}
            </Typography>
          </CardContent>
        </StyledCard>
      </ActionCardGrid>

      {/* TAB 0: All Activity */}
      {tabIndex === 0 && (
        <TableContainer
          sx={{ width: "80vw", marginInline: "auto", marginBlockEnd: 2 }}
          component={Paper}
          elevation={1}
        >
          <Table
            sx={{ minWidth: 500 }}
            aria-label="all activity table"
            width={"100%"}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">PO Number</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Link</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedActivity.map((item, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="center">{item.type}</TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.po_number}</TableCell>
                  <TableCell align="center">{formatAmount(item.amount)}</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
                  <TableCell align="center">
                    {new Date(item.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">{item.notes}</TableCell>
                  <TableCell align="center">
                    {item.link !== "#" ? (
                      <Link
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </Link>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {/* If it's a pending invoice, show Approve Invoice button */}
                    {item.type === "Invoice" && item.pending && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenConfirmDialog(item.invoiceId)}
                      >
                        Approve Invoice
                      </Button>
                    )}
                    {/* Or if it’s not paid, maybe show Edit button */}
                    {item.editable && (
                      <Button
                        onClick={() =>
                          handleEditDialogOpen({ id: item.invoiceId })
                        }
                        startIcon={<EditIcon />}
                        sx={{ ml: 1 }}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* TAB 1: Invoices */}
      {tabIndex === 1 && (
        <TableContainer
          sx={{ width: "80vw", marginInline: "auto", marginBlockEnd: 2 }}
          component={Paper}
          elevation={1}
        >
          <Table
            sx={{ minWidth: 500 }}
            aria-label="invoices table"
            width={"100%"}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Payment Name</TableCell>
                <TableCell align="center">PO Number</TableCell>
                <TableCell align="center">Amount Due</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Invoice Link</TableCell>
                <TableCell align="center">Edit / Confirm</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInvoices.map((invoice) => (
                <StyledTableRow key={invoice.id}>
                  <TableCell align="center">
                    {getPaymentName(invoice)}
                  </TableCell>
                  <TableCell align="center">
                    {invoice.po_number || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {formatAmount(invoice.amount_due)}
                  </TableCell>
                  <TableCell align="center">{invoice.status}</TableCell>
                  <TableCell align="center">
                    {new Date(invoice.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">{invoice.notes}</TableCell>
                  <TableCell align="center">
                    <Link
                      href={`https://www.useblitz.co/invoicing/${invoice.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Invoice
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {/* If it's pending, show "Confirm Your Invoice" button */}
                    {invoice.status === "pending" && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleOpenConfirmDialog(invoice.id)}
                      >
                        Confirm Your Invoice
                      </Button>
                    )}
                    {/* For editing (if not Paid) */}
                    {invoice.status !== "Paid" && (
                      <Button
                        onClick={() => handleEditDialogOpen(invoice)}
                        startIcon={<EditIcon />}
                        sx={{ ml: 1 }}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* TAB 2: Incoming Invoices */}
      {tabIndex === 2 && (
        <TableContainer
          sx={{ width: "80vw", marginInline: "auto", marginBlockEnd: 2 }}
          component={Paper}
          elevation={1}
        >
          <Table
            sx={{ minWidth: 700 }}
            aria-label="incoming invoices table"
            width={"100%"}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Billed To</TableCell>
                <TableCell align="center">Billed From</TableCell>
                <TableCell align="center">PO Number</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Payout Date</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Invoice Link</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incomingInvoices.map((invoice) => {
                // Distinguish client vs. creator invoice:
                const isClientInvoice = invoice.hasOwnProperty("amount_due");
                // Build link:
                const linkUrl = isClientInvoice
                  ? `https://www.useblitz.co/invoicing/${invoice.id}`
                  : `https://www.useblitz.co/creatorinvoice/${invoice.id}`;

                return (
                  <StyledTableRow key={invoice.id}>
                    <TableCell align="center">
                      {invoice.client_name}
                    </TableCell>
                    <TableCell align="center">
                      @{invoice.creator_id || "N/A"}
                    </TableCell>
                    <TableCell align="center">
                      {invoice.po_number || "N/A"}
                    </TableCell>
                    <TableCell align="center">
                      {formatAmount(invoice.amount)}
                    </TableCell>
                    <TableCell align="center">
                      {invoice.invoice_status}
                    </TableCell>
                    <TableCell align="center">
                      {invoice.payout_date
                        ? new Date(invoice.payout_date).toLocaleDateString()
                        : ""}
                    </TableCell>
                    <TableCell align="center">{invoice.notes}</TableCell>
                    <TableCell align="center">
                      <Link
                        href={linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Invoice
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {/* if status === pending, show Approve/Decline */}
                      {invoice.invoice_status === "pending" &&
                        (isClientInvoice ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleOpenClientActionDialog(invoice.id)
                            }
                          >
                            Approve/Decline Invoice
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpenActionDialog(invoice.id)}
                          >
                            Approve/Decline Invoice
                          </Button>
                        ))}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* TAB 3: Payouts */}
      {tabIndex === 3 && (
        <TableContainer
          sx={{
            width: "80vw",
            marginInline: "auto",
            overflowX: "auto",
            marginBlockEnd: 2,
          }}
          component={Paper}
        >
          {isPayoutsLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table aria-label="payouts table">
              <TableHead>
                <TableRow>
                  <TableCell>Creator ID</TableCell>
                  <TableCell>Campaign Name</TableCell>
                  <TableCell>Payout Date</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>BlitzPay</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payouts
                  .filter((p) => {
                    const dateCheck =
                      new Date(p.payout_date) >= new Date(startDate) &&
                      new Date(p.payout_date) <= new Date(endDate);
                    const statusCheck =
                      !statusFilter || p.status === statusFilter;
                    return dateCheck && statusCheck;
                  })
                  .map((payout) => (
                    <StyledTableRow key={payout.payout_id || "N/A"}>
                      <TableCell>{payout.creator_id}</TableCell>
                      <TableCell>
                        {payout.campaignname || "Creator Payout"}
                      </TableCell>
                      <TableCell>
                        {new Date(payout.payout_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{payout.status}</TableCell>
                      <TableCell>
                        {payout.blitzpay ? "true" : "false"}
                      </TableCell>
                      <TableCell>{formatAmount(payout.amount)}</TableCell>
                      <TableCell>{payout.notes}</TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {/* Invoice Edit Dialog */}
      {currentInvoice && (
        <InvoiceEdit
          open={editOpen}
          onClose={handleEditDialogClose}
          invoiceInfo={currentInvoice}
        />
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      />

      {/* Vendor Dialog */}
      <VendorDialog open={vendorDialogOpen} onClose={handleCloseVendorDialog} />

      {/* Approve/Decline Creator Invoice Dialog */}
      <InvoiceActionDialog
        open={actionDialogOpen}
        onClose={handleCloseActionDialog}
        invoiceId={selectedInvoiceId}
        onSuccess={handleActionSuccess}
        invoiceActionMutation={invoiceAction}
      />

      {/* Approve/Decline Client Invoice Dialog */}
      <ClientInvoiceActionDialog
        open={clientActionDialogOpen}
        onClose={handleCloseClientActionDialog}
        invoiceId={selectedClientInvoiceId}
        onSuccess={handleActionSuccess}
        clientInvoiceActionMutation={clientInvoiceAction}
      />

      {/* NEW: Confirm Invoice Dialog (for screenshot/signature) */}
      <ConfirmInvoiceDialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        invoiceId={confirmInvoiceId}
        onSuccess={handleActionSuccess}
        confirmInvoiceMutation={confirmInvoice}
      />

      {/* NEW: Popup if 2 or more pending invoices */}
      <Dialog open={showPendingWarning} onClose={() => setShowPendingWarning(false)}>
        <DialogContent>
          <Typography>
            Please confirm your other invoices before continuing to use Blitz.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPendingWarning(false)} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BlitzPay;
