import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import client from "../../../../API";
import ChatScreen from "../../../../Components/ChatScreen";

/**
 * ViewOfferDialog displays offer details and allows the creator to update the offer status.
 * It now accepts an optional onOfferUpdated callback so that after an update the parent
 * can take appropriate action (such as updating conversation messages).
 */
const ViewOfferDialog = ({
  open,
  onClose,
  deliverable,
  onUpdateDeliverable,
  onSendMessage,
  onOfferUpdated, // NEW: optional callback for when the offer is updated
}) => {
  // We'll consider "negotiate" as the only negotiation status on the creator side
  const isNegotiationStatus = deliverable.status.toLowerCase() === "negotiate";
  const lockedStatuses = ["accept", "completed", "decline"];
  const canChangeStatus = !lockedStatuses.includes(
    deliverable.status.toLowerCase()
  );

  const [status, setStatus] = useState(deliverable.status);
  const [amount, setAmount] = useState(deliverable.amount);
  const [revision, setRevision] = useState(deliverable.revision);
  const [loading, setLoading] = useState(false);

  // If user chooses "negotiate", we let them propose a new amount
  const [isNegotiating, setIsNegotiating] = useState(
    deliverable.status.toLowerCase() === "negotiate"
  );

  useEffect(() => {
    setIsNegotiating(status.toLowerCase() === "negotiate");
  }, [status]);

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  };

  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleRevisionChange = (e) => setRevision(e.target.value);

  const handleSubmit = () => {
    setLoading(true);

    let messageText = "";
    switch (status.toLowerCase()) {
      case "accept":
        messageText = "accepted";
        break;
      case "decline":
        messageText = "declined";
        break;
      case "negotiate":
        messageText = "proposed a new offer";
        break;
      default:
        messageText = status.toLowerCase();
        break;
    }

    // Simulate a network delay
    setTimeout(() => {
      const updateData = {
        status,
        amount,
        revision: isNegotiating ? revision : deliverable.revision,
      };
      // Call the update callback provided by the parent
      onUpdateDeliverable(deliverable.id, updateData);
      // NEW: If an additional callback is provided, call it
      if (onOfferUpdated) {
        onOfferUpdated(updateData);
      }
      onSendMessage(
        `Creator ${messageText} at $${amount} with ${revision} revisions.`
      );
      setLoading(false);
      onClose();
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontWeight: "bold", pb: 1 }}>Offer Details</DialogTitle>
      <DialogContent sx={{ pt: 0 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">
            <strong>Deliverable Type:</strong> {deliverable.deliverable_type}
          </Typography>
          <Typography variant="body2">
            <strong>Notes:</strong> {deliverable.notes}
          </Typography>
          <Typography variant="body2">
            <strong>Due Date:</strong> {deliverable.due_date}
          </Typography>
          <Typography variant="body2">
            <strong>Revision Amount:</strong> {deliverable.revision}
          </Typography>
          <Typography variant="body2">
            <strong>Platforms:</strong>{" "}
            {deliverable.platforms ? deliverable.platforms.join(", ") : ""}
          </Typography>
        </Box>

        {!canChangeStatus ? (
          <Typography color="text.secondary" sx={{ mb: 2 }}>
            This deliverable is currently <strong>{deliverable.status}</strong>; no further changes allowed.
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Set Offer Status
            </Typography>
            <ToggleButtonGroup
              value={status}
              exclusive
              onChange={handleStatusChange}
              aria-label="Offer Status"
              sx={{ mb: 3 }}
            >
              <ToggleButton
                value="accept"
                aria-label="accept"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "green",
                    color: "white",
                  },
                }}
              >
                Accept
              </ToggleButton>
              <ToggleButton
                value="decline"
                aria-label="decline"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "red",
                    color: "white",
                  },
                }}
              >
                Decline
              </ToggleButton>
              <ToggleButton
                value="negotiate"
                aria-label="negotiate"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "yellow",
                    color: "black",
                  },
                }}
              >
                Negotiate
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        )}

        {canChangeStatus && isNegotiating && (
          <>
            <TextField
              fullWidth
              margin="dense"
              label="Proposed Amount"
              value={amount}
              onChange={handleAmountChange}
              type="number"
              disabled={loading}
            />
            <Typography variant="caption" color="textSecondary">
              * Blitz takes a 5% fee on all payments when they are issued.
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              label="Number of Revisions"
              value={revision}
              onChange={handleRevisionChange}
              type="number"
              disabled={loading}
              sx={{ mt: 2 }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} color="inherit" disabled={loading}>
          Cancel
        </Button>
        {canChangeStatus && (
          <Button onClick={handleSubmit} variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const CreatorConversation = ({ partnershipId }) => {
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);

  const {
    data: partnershipFetch,
    isLoading: loadingPartnership,
    error: partnershipError,
    refetch: partnershipRefetch,
  } = useQuery(
    ["partnerships", partnershipId],
    () => client.partnerships.fetchPartnership(partnershipId),
    {
      enabled: !!partnershipId,
      refetchOnWindowFocus: false,
    }
  );

  const partnership = partnershipFetch?.partnership;
  const conversationId = partnership?.conversation_id || null;

  useEffect(() => {
    if (!conversationId && partnershipFetch) {
      client.conversations
        .createPartnership({
          partnership_id: partnershipId,
        })
        .then(() => partnershipRefetch())
        .catch((error) => {
          alert(
            `Error creating conversation: ${
              error?.response?.data?.error || error.message
            }`
          );
        });
    }
  }, [conversationId, partnershipFetch, partnershipRefetch, partnershipId]);

  const { refetch: refetchMessages } = useQuery(
    ["messages", conversationId],
    () => client.conversations.getMessages(conversationId),
    {
      enabled: !!conversationId,
      refetchOnWindowFocus: false,
    }
  );

  const sendMessageMutation = useMutation(
    ({ id, input }) => client.conversations.sendMessage(id, input),
    {
      onSuccess: () => {
        refetchMessages();
      },
      onError: (error) => {
        console.error("Error sending message:", error);
        if (error?.response?.data?.error === "forbidden") {
          alert("Your message was flagged for containing prohibited content.");
          return;
        }
        alert(error?.response?.data?.error || error?.message);
      },
    }
  );

  const updateDeliverableMutation = useMutation(
    ({ id, update }) => client.deliverables.update(id, update),
    {
      onSuccess: () => {
        setSelectedDeliverable(null);
        refetchMessages();
      },
      onError: (error) => {
        console.error("Error updating deliverable:", error);
        alert(error?.response?.data?.error || error?.message);
      },
    }
  );

  const handleViewOffer = (deliverable) => setSelectedDeliverable(deliverable);
  const handleUpdateDeliverable = (deliverableId, update) => {
    updateDeliverableMutation.mutate({ id: deliverableId, update });
  };
  const handleSendStatusMessage = (statusMessage) => {
    if (!conversationId) return;
    sendMessageMutation.mutate({
      id: conversationId,
      input: { message: statusMessage },
    });
  };

  if (loadingPartnership) {
    return (
      <Typography sx={{ padding: 2 }}>Loading partnership...</Typography>
    );
  }

  if (partnershipError) {
    return (
      <Typography color="error" sx={{ padding: 2 }}>
        Error loading partnership.
      </Typography>
    );
  }

  if (!partnership) {
    return <Typography sx={{ padding: 2 }}>No partnership found.</Typography>;
  }

  return (
    <>
      <ChatScreen
        conversationId={conversationId}
        onDeliverableClick={handleViewOffer}
        sx={{ flex: 1, minHeight: "0px" }}
      />
      {selectedDeliverable && (
        <ViewOfferDialog
          open={!!selectedDeliverable}
          onClose={() => setSelectedDeliverable(null)}
          deliverable={selectedDeliverable}
          onUpdateDeliverable={handleUpdateDeliverable}
          onSendMessage={handleSendStatusMessage}
          // NEW: Optionally handle additional changes after the offer is updated
          onOfferUpdated={(updatedOffer) => {
            console.log("Offer updated:", updatedOffer);
            // You can update local state or trigger a re-fetch here if needed.
          }}
        />
      )}
    </>
  );
};

export default CreatorConversation;
