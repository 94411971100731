import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Button,
  Skeleton,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../../API';
const rowCount = 5;

const ActivitySummary = ({ activity }) => {
  const { creator, activity_type, activity_date, activity_name } = activity;
  const formattedDate = new Date(activity_date).toLocaleDateString();

  const creatorLink = (
    <Link
      href={`https://www.useblitz.co/creators/${creator.creator}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {creator.creator}
    </Link>
  );

  switch (activity_type) {
    case 'Payout':
      return (
        <>
          {creatorLink} got paid on {formattedDate}
        </>
      );
    case 'Campaign':
      return (
        <>
          {creatorLink} started working on the {activity_name} campaign on {formattedDate}
        </>
      );
    case 'Partnership':
      return (
        <>
          Partnership for {creatorLink} started on {formattedDate}
        </>
      );
    case 'Pitch':
      return (
        <>
          {creatorLink} got pitched for {activity_name} on {formattedDate}
        </>
      );
    default:
      return null;
  }
};

const ZapFeed = () => {
  const canPitch = true;
  const navigate = useNavigate();
  const [zaapPage, setZaapPage] = useState(0);

  const {
    data: feed,
    isLoading: isZapFeedLoading,
    error: zapFeedError
  } = useQuery({
    queryKey: ['creator', 'zapfeed', { page: zaapPage, rows: rowCount }],
    queryFn: () => API.creatorConnect.getZapFeed({ page: zaapPage, rows: rowCount }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });

  const handlePitchClick = (activity) => {
    const id = activity?.activity_id;
    if (!id) {
      console.error("Activity details are missing or id is undefined", activity);
      return; // Optionally, you can notify the user or handle the error gracefully.
    }
    
    if (activity.activity_type === 'Campaign' || activity.activity_type === 'Pitch') {
      navigate(`/creatorconnect/pitch/campaign/${id}`);
    } else if (activity.activity_type === 'Partnership') {
      navigate(`/creatorconnect/pitch/partnership/${id}`);
    }
  };
  
  const handleShowNewer = () => {
    setZaapPage(prev => prev - 1);
  };
  const handleShowOlder = () => {
    setZaapPage(prev => prev + 1);
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="h6" gutterBottom align="left">
        ZapFeed - See what other creators are doing
      </Typography>

      <List
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0 }}
      >
        {feed?.zapfeed.map((activity, index) => (
          <Paper
            key={`${activity.activity_type}-${activity.activity_id}-${activity.creator.creator}`}
            elevation={1}
            sx={{
              width: '100%',
              maxWidth: '40rem',
              marginBottom: 1,
              padding: 1,
              backgroundColor: 'background.paper',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 6,
              },
            }}
          >
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={activity.creator.creator} src={activity.creator.pfphref} />
              </ListItemAvatar>
              <Box sx={{ textAlign: 'left', color: 'text.primary', flex: 1 }}>
                <Typography><ActivitySummary activity={activity}></ActivitySummary></Typography>
                {canPitch && ['Campaign', 'Partnership', 'Pitch'].includes(activity.activity_type) && (
                  <Button
                    onClick={() => handlePitchClick(activity)}
                    variant="contained"
                    size="small"
                    sx={{
                      marginTop: 1,
                      transition: 'background-color 0.2s ease, transform 0.2s ease',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    Pitch to this deal
                  </Button>
                )}
              </Box>
            </ListItem>
          </Paper>
        ))}
        {isZapFeedLoading && (
          <Paper elevation={1} sx={{ width: '100%', marginBottom: 1, padding: 1 }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </ListItemAvatar>
              <Box sx={{ textAlign: 'left', flex: 1 }}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
              </Box>
            </ListItem>
          </Paper>
        )}
      </List>
      {/* "Load More" */}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Button
          disabled={isZapFeedLoading || !feed || zaapPage == 0}
          onClick={handleShowNewer}
          variant="contained"
        >
          Newer
        </Button>
        <Button
          disabled={isZapFeedLoading || !feed || zaapPage == Math.floor(feed.total / rowCount)}
          onClick={handleShowOlder}
          variant="contained"
          color='secondary'
        >
          Older
        </Button>
      </Box>
    </Box>
  );
};

export default ZapFeed;
