import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Box,
  Divider,
  Paper,
  Grid,
  Stack
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../API"; // your API client

/**
 * Helper to show a fallback if date is missing.
 * This doesn't do advanced date calculations or offset your payment terms;
 * it's just for display in the preview. Adjust as needed.
 */
const formatPreviewDate = (dateStr) => {
  if (!dateStr) return "N/A";
  // Try to parse as date
  const parsed = new Date(dateStr);
  if (isNaN(parsed.getTime())) return dateStr; // If invalid date, just show raw
  return parsed.toLocaleDateString();
};

const InvoiceEdit = ({ open, onClose, invoiceInfo = {} }) => {
  // ----------------------------------
  // State: Existing invoice fields
  // ----------------------------------
  const [poNumber, setPONumber] = useState("");
  const [notes, setNotes] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientNotes, setClientNotes] = useState("");

  // ----------------------------------
  // State: Custom invoice fields (JSON)
  // ----------------------------------
  const [billedTo, setBilledTo] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [dueDateOverride, setDueDateOverride] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  // ----------------------------------
  // On component mount or invoiceInfo change, populate from props
  // ----------------------------------
  useEffect(() => {
    setPONumber(invoiceInfo.po_number || "");
    setNotes(invoiceInfo.notes || "");
    setClientName(invoiceInfo.client_name || "");
    setClientEmail(invoiceInfo.client_email || "");
    setClientNotes(invoiceInfo.client_notes || "");

    if (invoiceInfo.custom_details) {
      const {
        billed_to,
        date_of_issue,
        due_date_override,
        invoice_number
      } = invoiceInfo.custom_details;
      setBilledTo(billed_to || "");
      setDateOfIssue(date_of_issue || "");
      setDueDateOverride(due_date_override || "");
      setInvoiceNumber(invoice_number || "");
    }
  }, [invoiceInfo]);

  // ----------------------------------
  // Submit the updated invoice
  // ----------------------------------
  const { mutate: editInvoice } = useMutation(client.invoices.editInvoice, {
    onSuccess: () => onClose(true),
    onError: (error) => {
      console.error("Error updating invoice:", error);
    },
  });

  const handleSubmit = () => {
    const submissionData = {
      id: invoiceInfo.id,
      po_number: poNumber,
      notes: notes,
      client_name: clientName,
      client_email: clientEmail,
      client_notes: clientNotes,
      custom_details: {
        billed_to: billedTo,
        date_of_issue: dateOfIssue,
        due_date_override: dueDateOverride,
        invoice_number: invoiceNumber,
      },
    };
    editInvoice(submissionData);
  };

  // ----------------------------------
  // Derived preview fields
  // ----------------------------------
  const effectiveInvoiceNumber = invoiceNumber || poNumber || "N/A";
  const effectiveDateOfIssue = formatPreviewDate(dateOfIssue);
  const effectiveDueDate = formatPreviewDate(dueDateOverride);
  const effectiveBilledTo = billedTo || clientName || "N/A";

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="lg"
      fullWidth
      aria-labelledby="edit-invoice-dialog"
    >
      <DialogTitle id="edit-invoice-dialog">Edit Invoice</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 4, alignItems: "flex-start" }}>
          {/* --------------------------------------
              LEFT SIDE: Editable form fields
          -------------------------------------- */}
          <Box sx={{ flex: 1, minWidth: "300px" }}>
            <Typography variant="h6" gutterBottom>
              Invoice Fields
            </Typography>

            {/* Standard fields */}
            <TextField
              label="PO Number"
              fullWidth
              margin="dense"
              value={poNumber}
              onChange={(e) => setPONumber(e.target.value)}
            />
            <TextField
              label="Invoice Notes"
              fullWidth
              margin="dense"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              rows={2}
            />
            <TextField
              label="Client Name"
              fullWidth
              margin="dense"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
            <TextField
              label="Client Email"
              fullWidth
              margin="dense"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
            />
            <TextField
              label="Client Notes"
              fullWidth
              margin="dense"
              value={clientNotes}
              onChange={(e) => setClientNotes(e.target.value)}
              multiline
              rows={2}
            />

            <Divider sx={{ my: 2 }} />

            {/* Custom JSON fields */}
            <Typography variant="h6" gutterBottom>
              Custom Details
            </Typography>
            <TextField
              label="Billed To"
              fullWidth
              margin="dense"
              value={billedTo}
              onChange={(e) => setBilledTo(e.target.value)}
            />
            <TextField
              label="Date of Issue (YYYY-MM-DD)"
              fullWidth
              margin="dense"
              value={dateOfIssue}
              onChange={(e) => setDateOfIssue(e.target.value)}
            />
            <TextField
              label="Due Date Override (YYYY-MM-DD)"
              fullWidth
              margin="dense"
              value={dueDateOverride}
              onChange={(e) => setDueDateOverride(e.target.value)}
            />
            <TextField
              label="Invoice Number"
              fullWidth
              margin="dense"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
            />
          </Box>

          {/* --------------------------------------
              RIGHT SIDE: Live-Preview of Invoice
          -------------------------------------- */}
          <Box
            sx={{
              flex: 1,
              borderLeft: "1px solid #ccc",
              pl: 3,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Live Preview
            </Typography>

            {/* Example “Invoice” layout in a smaller scale */}
            <Paper
              elevation={2}
              sx={{
                p: 4,
                maxWidth: "700px",
                background: "white",
                margin: "0 auto",
                fontFamily: "Arial, sans-serif",
              }}
            >
              {/* Header Section */}
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <Grid item xs={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#4a4e69",
                        mb: 1,
                      }}
                    >
                      INVOICE
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#4a4e69" }}>
                      (Preview)
                    </Typography>
                  </Box>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" color="textSecondary">
                        Date of Issue:
                      </Typography>
                      <Typography variant="body2">
                        {effectiveDateOfIssue}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" color="textSecondary">
                        Invoice #:
                      </Typography>
                      <Typography variant="body2">
                        {effectiveInvoiceNumber}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" color="textSecondary">
                        Due Date:
                      </Typography>
                      <Typography variant="body2">
                        {effectiveDueDate}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Box
                    sx={{
                      border: "1px solid #ddd",
                      backgroundColor: "#f8f9fa",
                      p: 2,
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Total Amount Due
                    </Typography>
                    {/* In a real invoice, you'd recalc the actual amount. 
                        Here we just show a placeholder. */}
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      $0.00
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Billed To Section */}
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                    Billed To
                  </Typography>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 2,
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <Typography variant="body2">
                      {effectiveBilledTo}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {clientEmail || "N/A"}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              {/* Invoice Notes as if it's a line item description */}
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Notes:</strong> {notes || "No notes entered"}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Client Notes:</strong> {clientNotes || "No client notes"}
              </Typography>

              <Divider sx={{ my: 2 }} />

              {/* Payment Section (Placeholder) */}
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  background: "linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                  (Preview) Payment Options
                </Typography>
                <Typography variant="body2">
                  In a real invoice, you'd see your “Pay Now” button here.
                </Typography>
              </Paper>
            </Paper>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceEdit;
